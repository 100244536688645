import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import AttendanceSheetForm from "../index"; // Update the import path

import PaperComponent from "../../../../../components/Modal/PaperComponent";
import { Subtitle } from "../../../../../components/Titles/FormTitles";
import { CloseIcon } from "../../../../../components/Icon";
import ConfirmationPopup from "../../../../../components/Popup";

const AttendanceDialog = ({
  open,
  setOpen,
  employeeLoading,
  locationLoading,
  workTypeLoading,
  employee_list,
  location_list,
  workType_list,
  attendanceSheetGuid,
  handleChangeDayLogGuid,
  dayLogGuid,
  editMode,
  refetchData,
  handleEditMode,
  status,
  title,
}) => {
  const [openDialog, setOpenDialog] = useState(open);

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    if(handleChangeDayLogGuid) handleChangeDayLogGuid(null);
    setOpenDialog(false);
    setOpen(false);
  };

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <Dialog
        onClose={() => {
          if (JSON.parse(sessionStorage.getItem("unsavedChanges"))) {
            setOpenDialog(true);
          } else {
            if(handleChangeDayLogGuid) handleChangeDayLogGuid(null);
            setOpen(false);
          }
        }}
        PaperComponent={PaperComponent}
        open={open}
      >
        <DialogTitle
          style={{
            cursor: "move",
            borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
          }}
          id="draggable-dialog-title"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Subtitle
              style={{
                textAlign: "left",
                fontFamily: "poppins !important",
                fontSize: "16px",
                paddingLeft: "35px",
              }}
            >
              {title}
            </Subtitle>
            <IconButton
              onClick={() => {
                if (JSON.parse(sessionStorage.getItem("unsavedChanges"))) {
                  setOpenDialog(true);
                } else {
                  if(handleChangeDayLogGuid) handleChangeDayLogGuid(null);
                  setOpen(false);
                }
              }}
            >
              <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
            </IconButton>
          </div>
        </DialogTitle>
        <AttendanceSheetForm
          employeeLoading={employeeLoading}
          locationLoading={locationLoading}
          workTypeLoading={workTypeLoading}
          employee_list={employee_list}
          location_list={location_list}
          workType_list={workType_list}
          attendanceSheetGuid={attendanceSheetGuid}
          dayLogGuid={dayLogGuid}
          editMode={editMode}
          refetchData={refetchData}
          handleEditMode={handleEditMode}
          status={status}
          handleChangeDayLogGuid={handleChangeDayLogGuid}
        />
      </Dialog>
    </>
  );
};

export default AttendanceDialog;
