import { gql } from "@apollo/client";

export const GET_ALL_SKILLS = gql`
query ($model: GetAllSkillsRequestModelInput!) {
    GetAllSkills(model: $model) {
      skillsList {
        skills {
          skillGuid
          name
          tenantID
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_SKILL_BY_ID = gql`
query ($id: UUID!) {
    GetSkill(id: $id) {
      skill {
        skillGuid
        name
        tenantID
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_SKILL = gql `
mutation ($model: CreateEditSkillRequestModelInput!) {
    CreateSkill(model: $model) {
      skill {
        skillGuid
        name
        tenantID
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_SKILL = gql `
mutation ($model: CreateEditSkillRequestModelInput!) {
    UpdateSkill(model: $model) {
      skill {
        skillGuid
        name
        tenantID
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_SKILL = gql `
mutation ($id: UUID!) {
    DeleteSkill (id: $id) {
      crudSkill
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_SKILLS_LIST = gql `
mutation ($model: DeleteSkillsRequestModelInput!) {
    DeleteSkillsList (model: $model) {
      crudSkill
      errorCode
      errorMessage
      errorVariables
    }
  }
`;