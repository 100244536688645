  

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";

//   React components

// Custom styles for the MDAlert
import { Alert } from "@mui/material";

function CustomAlert({ color, children, closeAlert, severity, ...rest }) {
  const [alertStatus, setAlertStatus] = useState("mount");




  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <Alert
      {...rest}
        severity={severity!==""? severity : color}
        color={color}
        onClose={closeAlert ? () => closeAlert("") : null}
        sx={{ display: "flex", alignItems: "center", py: 0, minWidth:"14rem !important", fontFamily:"Poppins" }}
      >
        {children}
      </Alert>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Setting default values for the props of CustomAlert
CustomAlert.defaultProps = {
  color: "info",
  dismissible: false,
  closeAlert: null,
  severity : ""
};

// Typechecking props of the CustomAlert
CustomAlert.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  closeAlert: PropTypes.func,
  severity: PropTypes.oneOf([    "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "light",
  "dark",
""])
};

export default CustomAlert;
