import { gql } from "@apollo/client";

export const GET_PTO_ASSIGN_DATES = gql`
  query {
    GetPTOAssignDates {
      assignDates {
        ptoAssignDateGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_PTO_STATUSES = gql`
  query {
    GetPTOStatuses {
      statuses {
        ptoStatusGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_PTO_LIST = gql`
  query ($model: GetPTOsListModelInput!) {
    GetPTOsList(model: $model) {
      ptOs {
        ptOs {
          ptoGuid
          description
          numberOfUnits
          accumulated
          paid
          ptoAssignDate {
            description
            mnemonic
          }
          ptoStatus {
            description
            mnemonic
          }
          dateUnit {
            dateUnitGuid
            description
            mnemonic
          }
          employmentType {
            name
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_PTOS = gql`
  mutation ($model: DeletePTOsRequestModelInput!) {
    DeletePTOs(model: $model) {
      deletePTOs
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_PTO_BY_ID = gql`
  query ($id: UUID!) {
    GetPTOByID(ptoID: $id) {
      ptoView {
        pto {
          ptoGuid
          description
          paid
          accrued
          accumulated
          numberOfUnits
          dateUnitGuid
          dateUnit {
            dateUnitGuid
            description
            mnemonic
          }
          employmentType {
            employmentTypeGuid
            name
          }
          ptoAssignDate {
            ptoAssignDateGuid
            mnemonic
            description
          }
          ptoStatus {
            ptoStatusGuid
            description
            mnemonic
          }
          seniorities {
            seniorityGuid
            from
            to
            numberOfUnits
          }
        }
        editable
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_PTO = gql`
  mutation ($model: PTOCreateRequestInput!) {
    CreatePTO(model: $model) {
      pto {
        ptoGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_PTO = gql`
  mutation ($model: PTOUpdateRequestInput!) {
    UpdatePTO(model: $model) {
      pto {
        ptoGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CHANGE_PTO_STATUS = gql`
  mutation ($model: PTOStatusUpdateRequestInput!) {
    UpdatePTOStatus(model: $model) {
      pto {
        ptoGuid
        ptoStatus {
          ptoStatusGuid
          description
          mnemonic
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_DATE_UNITS = gql`
  query {
    GetDateUnitsList {
      dateUnitsList {
        dateUnitGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
    }
  }
`;

export const GET_ALL_EMPLOYEE_PTOS = gql`
query ($model: GetEmployeePTOListModelInput!) {
  GetEmployeePTOsList(model: $model) {
    employeePTOsListModel {
      employeePTOs {
        employeePTOGuid
        numberOfDays
        daysUsed
        employee {
          firstName
        }
        pto {
          ptoGuid
          description
          dateUnit {
            description
            mnemonic
          }
          employmentType {
            name
          }
        }
        ptoStatus {
          description
          mnemonic
        }
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const ASSIGN_PTOS_TO_EMPLOYEES = gql`
  mutation ($model: AssignPtoToEmployeeRequestInput!) {
    AssignPtoToEmployee(model: $model) {
      assignPtoToEmployeeResponse
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const CHANGE_EMPLOYEE_PTO_STATUS = gql`
  mutation ($model: EmployeePTOStatusUpdateRequestInput!) {
    UpdateEmployeePTOStatus(model: $model) {
      employeePTOStatusUpdateResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const EMPLOYEE_PTO_LIST = gql `
query ($id: UUID!){
  GetEmployeePTOSummary(employeeID: $id){
    employeePTOs{
      ptoGuid
      description
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_TO_ENTITLEMENT_LIST = gql`
query ($model: TOEntitlementListRequestInput!) {
  GetTOEntitlementList(model: $model) {
    toEntitlementListResponse {
      toEntitlements {
        toEntitlementGuid
        description
        numberOfDays
        date
        employeePTO {
          employee {
            employeeGuid
            firstName
            lastName
            email
          }
          pto {
            description
            dateUnit {
              description
            }
          }
          ptoStatus {
            description
            mnemonic
          }
        }
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_TO_ENTITLEMENT_BY_ID = gql`
query ($id: UUID!) {
  GetTOEntitlementByID(toEntitlementGuid: $id) {
    toEntitlement {
      toEntitlementGuid
      employeePTO {
        employee {
          employeeGuid
          firstName
          lastName
          email
        }
        pto {
          description
        }
        ptoStatus {
          description
          mnemonic
        }
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const DELETE_TO_ENTITLEMENT = gql`
mutation ($model:TOEntitlementDeleteRequestInput!){
  DeleteTOEntitlement (model: $model) {
    toEntitlementDeleteResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const CREATE_TO_ENTITLEMENT = gql`
mutation ($model:TOEntitlementCreateRequestInput!){
  CreateTOEntitlement (model: $model) {
    toEntitlement {
      toEntitlementGuid
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const EDIT_TO_ENTITLEMENT = gql`
mutation ($model:TOEntitlementEditRequestInput!){
  EditTOEntitlement(model: $model) {
    toEntitlement {
      toEntitlementGuid
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;