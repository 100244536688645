import checkout from "./form";

const {
  formField: {
    name,
    description,
    manageSystemConfigurations,
    manageTimeEntry,
    manageUsers,
    manageAttendance,
    manageParameters
  },
} = checkout;

const initialValues = {
  [name.name]:"",
  [description.name]:"",
  [manageSystemConfigurations.name]:null,
  [manageUsers.name]:null,
  [manageTimeEntry.name]:null,
  [manageAttendance.name]:null,
  [manageParameters.name]:null
};

export default initialValues;