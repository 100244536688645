import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UTC2Time } from "../../../UTC2Local";
import No_Image_Found from "../../../assets/images/no-user-image.png";

const data = (t) => {
  return {
  title: <></>,

  headers: [t("dashboard.card.employee"), t("dashboard.card.reason"), t("dashboard.card.time"),],
  render: [
    (row) => {
      return (
        <div className="data-card-employee-name-image">
          <span>
            {(row?.employeePTO?.employee?.profilePictureURL && (
              <img
                src={row.employeePTO?.employee.profilePictureURL }
                alt="Not Found"
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src=No_Image_Found;
                }}
              />
            )) || <AccountCircleIcon />}
          </span>
          <p>{row?.employeePTO?.employee?.firstName + " " + row?.employeePTO?.employee?.lastName}</p>
        </div>
      );
    },
    (row) => {
      return <p>{row?.toRequest?.description}</p>;
    },
    (row, t) => {
      let result = null;

      if (!row.toRequest?.timeFrom && !row.toRequest?.timeTo) result = t("timeDiff.fullDay");
      else result = UTC2Time(row.toRequest?.timeFrom, false) + " - " + UTC2Time(row.toRequest?.timeTo, false);

      return <p>{result}</p>;
    },
  ],
}
};

export default data;
