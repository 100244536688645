import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UTC2Local from "../../../UTC2Local";
import No_Image_Found from "../../../assets/images/no-user-image.png";

const data = (t) => {
  return {
  title: <></>,

  headers: [t("dashboard.card.employee"), t("dashboard.card.requestDate"), t("dashboard.card.type")],
  render: [
    (row) => {
      return (
        <div className="data-card-employee-name-image">
          <span>
            {(row?.employee?.profilePictureURL && (
              <img
                src={row.employee.profilePictureURL }
                alt={"Not Found"}
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src=No_Image_Found;
                }}
              />
            )) || <AccountCircleIcon />}
          </span>
          <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>
        </div>
      );
    },
    (row) => {
      return <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>;
    },
    (row) => {
      return <p>{UTC2Local(row?.requestDate, true)}</p>;
    },
    (row) => {
      return <p>{row?.requestType?.description}</p>;
    },
  ],
  // headers: ["Employee", "Request Date", "Type", "Date", "Time"],
  // render: [
  //   (row) => {
  //     return <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>;
  //   },
  //   (row) => {
  //     return <p>{UTC2Local(row?.requestDate, true)}</p>;
  //   },
  //   (row) => {
  //     return <p>{row?.requestType?.description}</p>;
  //   },
  //   (row) => {
  //     return (
  //       <p>
  //         {row.dateFrom? UTC2Local(row?.dateFrom, true) +
  //           (row.dateTo ? " till " + UTC2Local(row?.dateTo, true) : "") :""}
  //       </p>
  //     );
  //   },
  //   (row) => {
  //     return (
  //       <p>
  //         {row.dateTo
  //           ? "Full Day"
  //           : row.timeFrom? UTC2Time(row?.timeFrom)+" - "+ UTC2Time(row?.timeTo) : ""}
  //       </p>
  //     );
  //   },
  // ],
}
};

export default data;
