import React from "react";

//components
import {
  Grid, Box, IconButton, InputAdornment
} from "@mui/material";
import { Title } from "../../../../components/Titles/FormTitles";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//translation
import { useTranslation } from "react-i18next";
import TextField from "../../../../components/TextFields/TextField";




const Main = ({ formData }) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    
    readOnly,
   
    setWhatField
  } = formData;

  const {
    newPassword, confirmPassword
  } = formField;

  const {
    newPassword: newPasswordV,
    confirmPassword: confirmPasswordV,
  } = values;

  const handleClickShowPassword = (value) => value === 1 ? setShowPassword((show) => !show) : setShowConfirmPassword((show) => !show);



  return (
    <Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("profileForm.changePassword")}
          </Title>

        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                autoFocus
                fullWidth
                value={newPasswordV}
                onChange={(e) => {
                  setFieldValue(newPassword.name, e.target.value);
                }}
                label={t(newPassword.label)}
                name={newPassword.name}
                formData={formData}
                required
                disabled={readOnly}
                type={showPassword ? 'text' : 'password'}
                onFocus={() => {
                  setWhatField("Profile-Pleasefollowthisguideforastrongpassword");
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword(1)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>

                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                fullWidth
                value={confirmPasswordV}
                onChange={(e) => {
                  setFieldValue(confirmPassword.name, e.target.value);
                }}
                label={t(confirmPassword.label)}
                name={confirmPassword.name}
                formData={formData}
                required
                disabled={readOnly}
                type={showConfirmPassword ? 'text' : 'password'}
                onFocus={() => {
                  setWhatField("Profile-Pleasefollowthisguideforastrongpassword");
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword(0)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>

                }}
              />
            </Grid>


            
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
