import PTOList from "../../../PTOs/PTOs-List";

import { Modal, Grid, Typography, IconButton } from "@mui/material";

import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";

import ContainerCard from "../../../../../components/Cards/ContainerCard";

import { useTranslation } from "react-i18next";

const PTOTable = ({
  display = false,
  setDisplay = () => {},
  tenant = null,
  handleClick = () => {},
  initialVal = [],
  refetchList = false,
}) => {

  const { t } = useTranslation();

  return (
    <Modal
      open={display}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ContainerCard sx={{ padding: "0 20px", width: "70%" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontFamily: "poppins",
              }}
            >
              {t("ptoTable.AddPTO")}
            </Typography>
            <IconButton onClick={() => setDisplay(false)}>
              <ClearOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <PTOList fromEmployee initialVal={initialVal} tenant={tenant} handleClick={handleClick} refetchList={refetchList}/>
          </Grid>
        </Grid>
      </ContainerCard>
    </Modal>
  );
};

export default PTOTable;
