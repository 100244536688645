import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {  status,
    description,
    deviceID,
    fingerprint,
    location,
    workType,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [status.name]:Yup.object().required(status.requiredMessage),
    [description.name]:Yup.string().required(description.requiredMessage),
    [deviceID.name]:Yup.string().required(deviceID.requiredMessage),
    [fingerprint.name]:Yup.string().required(fingerprint.requiredMessage),
    [location.name]:Yup.object().required(location.requiredMessage),
    [workType.name]:Yup.object().required(workType.requiredMessage),
  }),
];

export default validation;