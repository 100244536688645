import React from "react";

//external components
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TaskForm from "../../Tasks-form";
import TimeEntries from "../../../TimeEntries/TimeEntries-list";

//components

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0, pb: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TasksTabRoutes({ value, fromTenant, fromProject }) {
  return (
    <>
      <TabPanel value={value} index={0}>
       <TaskForm fromTenant={fromTenant} fromProject={fromProject}/>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
       <TimeEntries fromProject={fromProject}/>
      </TabPanel> */}
    </>
  );
}
