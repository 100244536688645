import React, { useState, useEffect, useCallback } from "react";

//react router
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import { DELETE_ONBOARDING_TASKS, GET_ALL_ONBOARDING_TASKS_LIST } from "../../../../graphql/onBoardingTasks";

import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { removeNullProps } from "../../../../utils/removeNullProps";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";

const OnBoardingTasksList = ({ fromTenant = false, fromEmployee = false, handleClick = () => { },
  initialVal = [], eID }) => {
  const { obName, obID, tID } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const {
    onBoarding_task_form,
    new_onBoarding_task,
    onBoarding_tasks
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState(fromEmployee ? initialVal : []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");




  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["OnBoarding_Tasks"],
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {

        let col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
        col = decodeUnicodeString(col);
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const customButtons = [
    {
      label: "employeePtoForm.assignonboardingtaskss",
      handleClick: () => handleClick(selected),
      condition: selected && selected.length > 0 && fromEmployee,
    },
  ];


  const { mfunction: getAllOnBoardingTasksData, loading } = useLazyQueryAuth(
    GET_ALL_ONBOARDING_TASKS_LIST,
    "GetOnBoardingTasksList"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllOnBoardingTasksData(
        {
          variables: {
            model: {

              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              tenantGuid: fromTenant ? tID : storeUserTenant,
              mnemonic: Mnemonic.Tables["OnBoarding_tasks"],
              unassigned: fromEmployee ? true : null ,
              userID: fromEmployee? eID : null,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
      data.GetOnBoardingTasksList &&
      data.GetOnBoardingTasksList.onBoardingTasksListResponse &&
      data.GetOnBoardingTasksList.onBoardingTasksListResponse.onBoardingTasksList
      ? data.GetOnBoardingTasksList.onBoardingTasksListResponse.onBoardingTasksList
      : [];
  const numberOfRecords =
    data &&
      data.GetOnBoardingTasksList &&
      data.GetOnBoardingTasksList.onBoardingTasksListResponse &&
      data.GetOnBoardingTasksList.onBoardingTasksListResponse.totalNumberOfRecords
      ? data.GetOnBoardingTasksList.onBoardingTasksListResponse
        .totalNumberOfRecords
      : 0;

  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_ONBOARDING_TASKS,
    "DeleteOnBoardingTasks"
  );

  const handleDelete = () => {
    deleteRecord(
      {
        variables: {
          model: {
            onBoardingTaskGuids: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("officialHoliday.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };


  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    customButtons,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "officialHoliday.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables["OnBoarding_Tasks"],
    removeFilters: true,
    generateRoute: (row) => fromEmployee ? false :  onBoarding_task_form.replace(
      "/:obName/:obID",
      `/${encodeURIComponent(row?.description)}/${encodeURIComponent(row?.onBoardingTaskGuid)}`
    ),
    newText: fromEmployee ? false : "officialHoliday.list.new.label",
    newLink: fromEmployee ? false : new_onBoarding_task,
    deleteText: fromEmployee ? false : "officialHoliday.list.delete.label",
    handleDelete: fromEmployee ? false : handleDelete,
    deleteLoading: fromEmployee ? false : true,
    hideEditColumns: true,
    readOnly: manageSystemConfigurations !== "M",
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"onBoardingTaskGuid"}
          hasCheckbox
        />
      )}
    </div>
  );
};

export default OnBoardingTasksList;
