import React from "react";

import PropTypes from "prop-types";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { UTC2Time } from "../../../UTC2Local";

import "./user-info.css";
import Mnemonics from "../../../Mnemonics.json";

import { getWorkType } from "../../../components/Icon";

import No_Image_Found from "../../../assets/images/no-user-image.png";

function getTimeDifference(time2, time1) {
  if (!time2 || !time1) return null;

  let later = new Date(time2);
  let sooner = new Date(time1);

  let difference = later.getTime() - sooner.getTime();

  let totalMinutes = difference / (1000 * 60);

  let hours = Math.floor(totalMinutes / 60);
  let minutes = Math.floor(totalMinutes % 60);

  let result = hours + minutes / 60;

  return result.toFixed(2);
}
const UserInfo = ({ data = null, t }) => {
  const startHour = data?.dayLogs[0]?.workShiftDay?.startHour;
  const endHour = data?.dayLogs[0]?.workShiftDay?.endHour;

  return (
    data && (
      <div className="user-info-card">
        <div className="profile">
          <span>
            {(data?.employee?.profilePictureURL && (
              <img
                src={data.employee.profilePictureURL }
                alt="Not Found"
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src=No_Image_Found;
                }}
              />
            )) || <AccountCircleIcon />}
          </span>
          <div className="intro">
            <h5>
              {data?.employee?.firstName + " " + data?.employee?.lastName}
            </h5>
            <p>
              {data?.employee?.position},{<br></br>}
              {data?.employee?.department?.name}
            </p>
          </div>
        </div>
        {/* <div>
          <h5>Work Type:</h5>
          <p>{data?.employee?.workType?.description}</p>
        </div> */}
        <div>
          <h5>Work Shift Time:</h5>
          <p>
            {UTC2Time(startHour?.toLocaleString(), false)} -{" "}
            {UTC2Time(endHour?.toLocaleString(), false)}
          </p>
        </div>

        <div>
          <div>
            {data &&
              data.dayLogs &&
              data.dayLogs.map((log, index) => {
                return (
                  <div key={index}>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginBottom: "0.7rem",
                      }}
                    >
                      {getWorkType(log?.workType?.mnemonic)}
                      {UTC2Time(log.checkInTime) || "..."} -{" "}
                      {UTC2Time(log.checkOutTime) || "..."}
                      <span style={{ color: "#033584", fontStyle: "italic" }}>
                        {" "}
                        {getTimeDifference(
                          log.checkOutTime,
                          log.checkInTime
                        ) != null &&
                          "(" +
                            getTimeDifference(
                              log.checkOutTime,
                              log.checkInTime
                            ) +
                            ")"}{" "}
                      </span>{" "}
                      {"| "}
                      {/* {log.location
                        ? log.location.projects &&
                          log.location.projects.length > 0
                          ? "| " +
                            log.location.projects[0].description +
                            " - " +
                            log.location.name 
                          : "| " + log.location.name 
                        : null} */}
                      {log ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-map-pin"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            strokeWidth="1"
                            stroke="#2c3e50"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                            <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                          </svg>{" "}
                          {log.workType.mnemonic ===
                          Mnemonics.CheckInLocations.HOME
                            ? t("workType.fromHome")
                            : log.location.name}{" "}
                        </>
                      ) : null}
                      <span>
                        {" "}
                        {log.location && log.project ? " - " : null}{" "}
                      </span>
                      <span>{log.project ? log.project.title : null}</span>
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
        {data?.location?.projects && (
          <div>
            <h5>Project:</h5>
            <p>
              {data?.location?.projects.length > 0 &&
                data?.location?.projects[0].description}
            </p>
          </div>
        )}
      </div>
    )
  );
};

UserInfo.propTypes = {
  data: PropTypes.object,
};

UserInfo.defaultProps = {
  data: null,
};

export default UserInfo;
