import checkout from "./form";

const {
  formField: { employee, task, date, duration, description , minutes, hours, project, billable},
} = checkout;

const initialValues = {
  [employee.name]: null,
  [task.name]: null,
  [date.name]: null,
  [duration.name]:0,
  [description.name]:"",
  [minutes.name]:0,
  [hours.name]:0,
  [project.name]:null,
  [billable.name]:true,
};

export default initialValues;