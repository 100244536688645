import checkout from "./form";

const {
  formField: { title, description },
} = checkout;

const initialValues = {
  [title.name]: "",
  [description.name]: "",
};

export default initialValues;