import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYEE_SKILLS = gql`
  query ($model: GetAllEmployeeSkillsRequestModelInput!) {
    GetAllEmployeeSkills(model: $model) {
      employeeSkillsList {
        employeeSkills {
          employeeSkillGuid
          skills {
            skillGuid
            name
            tenantID
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_EMPLOYEE_SKILL_BY_ID = gql`
  query ($id: UUID!) {
    GetEmployeeSkill(id: $id) {
      employeeSkill {
        skills {
          skillGuid
          name
          tenantID
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_EMPLOYEE_SKILL = gql`
  mutation ($model: CreateEditEmployeeSkillRequestModelInput!) {
    CreateEmployeeSkill(model: $model) {
      crudEmployeeSkill
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_EMPLOYEE_SKILL = gql`
  mutation ($model: CreateEditEmployeeSkillRequestModelInput!) {
    UpdateEmployeeSkill(model: $model) {
      crudEmployeeSkill
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_EMPLOYEE_SKILL = gql`
  mutation ($id: UUID!) {
    DeleteEmployeeSkill(id: $id) {
      crudEmployeeSkill
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_EMPLOYEE_SKILLS_LIST = gql`
  mutation ($model: DeleteEmployeeSkillsRequestModelInput!) {
    DeleteEmployeeSkillsList(model: $model) {
      crudEmployeeSkill
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
