import {
    Paper,
  } from "@mui/material";
  import Draggable from "react-draggable";

export default function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[className*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }