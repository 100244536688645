import componentDisplay from "../../../../../componentDisplay";

export const getTable = () => {
    return {
      columns: [
        {
          id: "Project Name",
          label: "exportExcel.list.column.projectName",
          align: "left",
          visible: true,
          width:"20vw",
          renderCell: (row) => {
            return row?.projectName;
          },
        },
        {
          id: "taskTitle",
          label: "exportExcel.list.column.taskTitle",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            return row?.task;
          },
        },
        {
          id: "description",
          label: "exportExcel.list.column.description",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            return row?.description;
          },
        },
        {
          id: "date",
          label: "exportExcel.list.column.date",
          align: "center",
          visible: true,
          renderCell: (row) => {
            return row?.date;
          },
        },
        {
          id: "time",
          label: "exportExcel.list.column.time",
          align: "right",
          visible: true,
          renderCell: (row) => {
            return row?.time;
          },
        },
       
      ],
      defaultSort: "projectName",
      defaultSortDirection: "asc",
    };
  };
