export const getDates = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

  // Create an array to store the months
  const months = [];

  // Loop through the next 4 months (current + 3 more)
  for (let i = 0; i < 3; i++) {
    const nextMonth = (currentMonth + i) % 12; // Ensure the month stays within 0-11
    months.push(monthNames[nextMonth]);
  }

  const dates =
    monthNames.indexOf(months[0]) < monthNames.indexOf(months[2])
      ? {
          start: new Date(
            new Date().getFullYear(),
            monthNames.indexOf(months[0])
          ).toLocaleDateString(),
          end: getLastDayOfMonth(
            new Date().getFullYear(),
            monthNames.indexOf(months[2])
          ),
        }
      : {
          start: new Date(
            new Date().getFullYear(),
            monthNames.indexOf(months[0])
          ).toLocaleDateString(),
          end: getLastDayOfMonth(
            new Date().getFullYear() + 1,
            monthNames.indexOf(months[2])
          ),
        };
  return dates;
};

const getLastDayOfMonth = (year, monthIndex) => {
  return new Date(year, monthIndex + 1, 0).toLocaleDateString();
};
