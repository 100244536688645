// @flow
import React, { useCallback, useEffect, useRef, useState } from "react";

// @asseinfo/react-kanban components
import Board, { addColumn } from "@asseinfo/react-kanban";

import { Box, CircularProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import AddCircleRounded from "@mui/icons-material/AddCircleRounded";

import DataTable from "../../../../components/Tables/DataTable";
import KanbanCard from "../../../../components/Cards/KanbanCard";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

import colors from "../../../../assets/theme/light/colors";

import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import Mnemonic from "../../../../Mnemonics.json";

import { getFiltersObject } from "./filtersConfig";
import { enqueueSnackbar } from "notistack";

import useAuth from "../../../../hooks/useAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded"
import { GET_ALL_AREAS } from "../../../../graphql/areas";
import { GET_ALL_TEAMS } from "../../../../graphql/team";
import { GET_ALL_TASK_STATES } from "../../../../graphql/taskSate";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_PARENT_TASKS, GET_TASK_BOARD_DATA, UPDATE_TASK_QUICK } from "../../../../graphql/task";
import { CREATE_TASK_STATUS, GET_ALL_TASK_STATUSES, GET_STATUS_COLOR } from "../../../../graphql/taskStatus";
import { BacklogIcon, getOptionPriorityIcon, SubtaskIcon } from "../../../../components/Icon";
import { Stringify } from "../../../../JSON.editor";
import TasksList from ".";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import { useParams } from "react-router-dom";

export function TaskBoard(
  {
    defaultFilters,
    setDefaultFilters,
    handleSave,
    fromTicket,
    fromSubtask,
    fromProjectTasks,
    handleSaveUserTableFilters,
    openSavedFilters,
    setOpenSavedFilters,
    selectedFilterGuid,
    handleCloseModal,
    parentGuid,
    refetchStatus,
    doing,
    fromTenant,
    setDoing,
    parentName,
    chipFilters,
    setChipFilters,
  }) {
  const { isEmployee, storeUserGuid, storeUserTenant, isManager, userRoles, storeUserTeams } = useAuth();
  const { isAdmin, manageSystemConfigurations, manageTimeEntry } = userRoles;
  const { t } = useTranslation();
  const count = useRef(0);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const { pID } = useParams();
  const [selectedProject, setSelectedProject] = useState([]);
  const addNewSubtask = useRef(false);
  const [fetchProjects, setFetchProjects] = useState(false);
  const [fetchTasks, setFetchTasks] = useState(false);
  const [fetchAreas, setFetchAreas] = useState(false);
  // const [fetchEmployees, setFetchEmployees] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskName, setSelectedTaskName] = useState("");
  const [newColumnFlag, setNewColumnFlag] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const [selected, setSelected] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [rowGuid, setRowGuid] = useState("");
  const [taskID, setTaskID] = useState(null);
  const [projectID, setProjectID] = useState();
  const [taskName, setTaskName] = useState(null);
  const [projectName, setProjectName] = useState();
  const [type, setType] = useState(null);
  const [parent, setParent] = useState(null);
  const [showQuickFilters, setShowQuickFilters] = useState(() => {
    const savedState = localStorage.getItem("showQuickFilters");
    return savedState === "true";
  });
  const [data, setData] = useState({ columns: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const prioritiesList = [
    {
      label: Mnemonic.Priority.Low,
      value: Mnemonic.Priority.Low,
    },
    {
      label: Mnemonic.Priority.Medium,
      value: Mnemonic.Priority.Medium,
    },
    {
      label: Mnemonic.Priority.High,
      value: Mnemonic.Priority.High,
    },
  ];

  const handleOpenModal = (row) => {
    const rowID = Object.keys(rowGuid)[0];
    if (fromSubtask) {
      handleCloseModal();
    }
    setSelectedTask(fromSubtask && !row ? null : row?.task?.taskGuid);
    setSelectedProject(
      fromSubtask ? projectID : row?.task?.ticket?.project?.projectGuid
    );
    setSelectedTaskName(fromSubtask && !row ? null : row?.task?.title);
    setProjectName(
      fromSubtask
        ? projectName
        : row?.task?.ticket?.project?.title
          ? row?.task?.ticket?.project?.title
          : data?.map((x) => x.task).filter((x) => x.guid == rowID)[0]
            ?.projectName
            ? data?.map((x) => x.task).filter((x) => x.guid == rowID)[0]
              ?.projectName
            : null
    );
    setType(fromSubtask ? "subtask" : "task");
    addNewSubtask.current = fromSubtask;
    setParent({
      taskGuid: parentGuid,
      title: parentName,
    });
    setTimeout(() => {
      setOpenModal(true);
    }, 500);
  };



  const findProjectDetails = (data, rowID) => {
    for (let item of data) {
      if (item?.task?.guid === rowID) {
        return {
          projectGuid: item.task.projectGuid,
          projectName: item.task.projectName,
          title: item.task.title,
        };
      }

      if (item.subtasks && item.subtasks.length > 0) {
        for (let subtask of item.subtasks) {
          if (subtask?.task?.guid === rowID) {
            return {
              projectGuid: subtask.task.projectGuid,
              projectName: subtask.task.projectName,
              title: subtask.task.title,
            };
          }

          // Recursive search in deeper subtasks
          const result = findProjectDetails(subtask?.subtasks || [], rowID);
          if (result) {
            return result;
          }
        }
      }
    }

    return null;
  };

  const handleAddNew = (type) => {
    if (type === "subtask") {
      setSelectedTask(null);
      setSelectedProject(null);
      setSelectedTaskName(null);
      setProjectName(null);
      setType(type);
      setParent(null);
      setTimeout(() => {
        setOpenModal(true);
      }, 500);
    } else {
      setSelectedTask(null);
      setSelectedProject(null);
      setSelectedTaskName(null);
      setProjectName(null);
      setType(type);
      setParent(null);
      setTimeout(() => {
        setOpenModal(true);
      }, 500);
    }
  };

  const customButtons = [
    {
      label: "table.board",
      condition: true,
      icon: true,
      iconButton: <BacklogIcon color="#000000" />,
      handleClick: () => handleSave(),
    },
    {
      label: "dashboard.subtask.addNew",
      handleClick: () => {
        handleAddNew("subtask");
      },
      condition:
        isEmployee !== Mnemonic.ResourceTypes.External,
      icon: true,
      iconButton: <SubtaskIcon color={colors.light_logo_blue} />,
    },
    {
      label: "dashboard.task.addNew",
      handleClick: () => {
        handleAddNew("task");
      },
      condition:
        isEmployee !== Mnemonic.ResourceTypes.External &&
        manageTimeEntry === "M" &&
        !fromSubtask,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: colors.light_logo_blue,
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const handleChipClick = (newChipFilters) => {

    let updatedDefaultFilters = { ...defaultFilters };
    let updatedFilterState = [...filterState];

    if (newChipFilters.assignedToMe) {
      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        employeeIDs: [storeUserGuid],
      };

      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value = [storeUserGuid];
      } else {
        updatedFilterState.push({
          value: [storeUserGuid],
          property: "employeeIDs",
        });
      }

      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        updatedFilterState[teamIndex].value =
          updatedFilterState[teamIndex]?.value || [];
      } else {
        updatedFilterState.push({ value: null, property: "teamsIDs" });
      }
    } else {
      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value = updatedFilterState[
          employeeIndex
        ].value?.filter((id) => id !== storeUserGuid);

        if (updatedFilterState[employeeIndex].value?.length === 0) {
          updatedFilterState.splice(employeeIndex, 1);
        }
      }

      delete updatedDefaultFilters.employeeIDs;
    }

    if (newChipFilters.assignedToMyTeam) {
      const filteredTeams = storeUserTeams?.map((x) => x.team);
      const filteredTeamsGuids = filteredTeams.map((t) => t.teamGuid);

      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        teamsIDs: filteredTeamsGuids,
      };

      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        updatedFilterState[teamIndex].value = filteredTeamsGuids;
      } else {
        updatedFilterState.push({
          value: filteredTeamsGuids,
          property: "teamsIDs",
        });
      }

      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value =
          updatedFilterState[employeeIndex].value?.map(
            (x) => x?.employeeGuid
          ) || [];
      } else {
        updatedFilterState.push({ value: null, property: "employeeIDs" });
      }
    } else {
      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        const existingTeamIDs = updatedFilterState[teamIndex].value || [];
        const filteredTeams = storeUserTeams?.map((x) => x.team);
        const filteredTeamsGuids = filteredTeams.map((t) => t.teamGuid);
        updatedFilterState[teamIndex].value = existingTeamIDs.filter(
          (id) => !filteredTeamsGuids.includes(id)
        );

        if (updatedFilterState[teamIndex].value?.length === 0) {
          updatedFilterState.splice(teamIndex, 1);
        }
      }

      delete updatedDefaultFilters.teamsIDs;
    }

    if (newChipFilters.myProjectTasks) {
      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        employeeIDs: null,
        teamsIDs: null,
      };

      const teamIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "teamsIDs"
      );
      if (teamIndex >= 0) {
        updatedFilterState[teamIndex].value = null;
      } else {
        updatedFilterState.push({ value: null, property: "teamsIDs" });
      }

      const employeeIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );
      if (employeeIndex >= 0) {
        updatedFilterState[employeeIndex].value = null;
      } else {
        updatedFilterState.push({ value: null, property: "employeeIDs" });
      }
    }

    if (newChipFilters.highPriority) {
      updatedDefaultFilters = {
        ...updatedDefaultFilters,
        priority: null,
      };

      const priorityIndex = updatedFilterState.findIndex(
        (obj) => obj.property === "priority"
      );
      if (priorityIndex >= 0) {
        updatedFilterState[priorityIndex].value = null;
      } else {
        updatedFilterState.push({ value: null, property: "priority" });
      }
    }

    setDefaultFilters(updatedDefaultFilters);
    setFilterState(updatedFilterState);
    // setChipFiltersClicked(!chipFiltersClicked);
  };

  const chips = [
    {
      label: t("ts.AssignedToMe"),
      tooltip: t("ts.assignedtometooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        const newAssignedToMe = !chipFilters.assignedToMe;
        const newChipFilters = {
          ...chipFilters,
          assignedToMe: newAssignedToMe,
          myProjectTasks:
            chipFilters.assignedToMyTeam || newAssignedToMe ? false : true,
        };
        setChipFilters(newChipFilters);
        handleChipClick(newChipFilters);
      },
      color: chipFilters.assignedToMe ? "primary" : "default",
      variant: chipFilters.assignedToMe ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.assignedToMe
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: t("ts.AssignedToMyTeam"),
      tooltip: t("ts.assignedtomeyteamtooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        const newAssignedToMyTeam = !chipFilters.assignedToMyTeam;
        const newChipFilters = {
          ...chipFilters,
          assignedToMyTeam: newAssignedToMyTeam,
          myProjectTasks:
            chipFilters.assignedToMe || newAssignedToMyTeam ? false : true,
        };
        setChipFilters(newChipFilters);
        handleChipClick(newChipFilters);
      },
      color: chipFilters.assignedToMyTeam ? "primary" : "default",
      variant: chipFilters.assignedToMyTeam ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.assignedToMyTeam
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: t("ts.OnlyTasks"),
      tooltip: t("ts.taskstooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        setChipFilters((prevFilters) => {
          const newOnlyTasks = !prevFilters.onlyTasks;
          const newOnlySubtasks = prevFilters.onlySubtasks;

          if (!newOnlyTasks && !newOnlySubtasks) {
            return {
              ...prevFilters,
              onlyTasks: false,
              onlySubtasks: true,
            };
          }

          return {
            ...prevFilters,
            onlyTasks: newOnlyTasks,
          };
        });
      },
      color: chipFilters.onlyTasks ? "primary" : "default",
      variant: chipFilters.onlyTasks ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.onlyTasks
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: t("ts.OnlySubtasks"),
      tooltip: t("ts.subtaskstooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        setChipFilters((prevFilters) => {
          const newOnlySubtasks = !prevFilters.onlySubtasks;
          const newOnlyTasks = prevFilters.onlyTasks;

          if (!newOnlySubtasks && !newOnlyTasks) {
            return {
              ...prevFilters,
              onlySubtasks: false,
              onlyTasks: true,
            };
          }

          return {
            ...prevFilters,
            onlySubtasks: newOnlySubtasks,
          };
        });
      },
      color: chipFilters.onlySubtasks ? "primary" : "default",
      variant: chipFilters.onlySubtasks ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.onlySubtasks
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: t("ts.myprojectTasks"),
      tooltip: t("ts.myprojectstooltip"),
      visible: true,
      onClick: () => {
        count.current = count.current + 1;
        const newMyProjectTasks = !chipFilters.myProjectTasks;
        const newChipFilters = {
          ...chipFilters,
          myProjectTasks: newMyProjectTasks,
          assignedToMe: newMyProjectTasks ? false : chipFilters.assignedToMe,
          assignedToMyTeam: newMyProjectTasks
            ? false
            : chipFilters.assignedToMyTeam,
        };
        setChipFilters(newChipFilters);
        if (
          !newMyProjectTasks &&
          !chipFilters.assignedToMe &&
          !chipFilters.assignedToMyTeam && !isAdmin
        ) {
          newChipFilters.assignedToMe = true;
        }
        handleChipClick(newChipFilters);
      },
      color: chipFilters.myProjectTasks ? "primary" : "default",
      variant: chipFilters.myProjectTasks ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: chipFilters.myProjectTasks
            ? "white"
            : `${colors.chip_label} !important`,
        },
      },
    },

    {
      tooltip: t("ts.prioritytooltip"),
      icon: getOptionPriorityIcon("High", { marginRight: "-17px" }),
      visible: true,

      onClick: () => {
        count.current = count.current + 1;
        const newChipFilters = {
          ...chipFilters,
          highPriority: true,
        };
        // handleChips({
        //   ...chipFilters,
        //   doing: !chipFilters.doing,
        // })

        handleChipClick(newChipFilters);
        setDoing(!doing);
        setChipFilters((prevFilters) => ({
          ...prevFilters,
          doing: !prevFilters.doing,
        }));
        // refetchData();
      },
      color: doing ? "primary" : "default",
      variant: doing ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: doing ? "white" : `${colors.chip_label} !important`,
        },
      },
    },
    {
      label: openSavedFilters
        ? t("ts.hideSavedFilters")
        : t("ts.showSavedFilters"),
      onClick: () => {
        setOpenSavedFilters((oldValue) => !oldValue);
      },
      variant: openSavedFilters ? "filled" : "outlined",
      sx: {
        ".MuiButtonBase-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "&.MuiChip-root": {
          border: `1px solid ${colors.chip_border} !important`,
        },
        "& .MuiChip-label": {
          color: openSavedFilters
            ? `${colors.icon_button_grey} !important`
            : `${colors.chip_label} !important`,
        },
      },
      visible: true,
    },


  ]

  const { data: taskStates, tokenChange: taskStatesWait } = useQueryAuth(
    GET_ALL_TASK_STATES,
    "GetAllTaskStates",
    // {
    //   skip: !newColumnFlag,
    //   isWait: newColumnFlag,
    // }
  );

  const taskStatesList =
    taskStates &&
      taskStates.GetAllTaskStates &&
      taskStates.GetAllTaskStates.tasksState
      ? taskStates.GetAllTaskStates.tasksState
      : [];

  const { data: statusColor } = useQueryAuth(
    GET_STATUS_COLOR,
    "GetStatusColors",
    // {
    //   skip: !newColumnFlag || taskStatesWait,
    //   isWait: (newColumnFlag && !taskStatesWait),
    // }
  );

  const statusColorsList =
    statusColor &&
      statusColor.GetStatusColors &&
      statusColor.GetStatusColors.statusColors
      ? statusColor.GetStatusColors.statusColors
      : [];

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
        showAll: true,
      },
    },
    // skip: !fetchEmployees,
    // isWait: fetchEmployees,
  });
  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];


  const {
    data: statusData,
    loading: statusLoading,
    tokenChange: statusWait,
    refetch: statusRefresh
  } = useQueryAuth(GET_ALL_TASK_STATUSES, "GetTaskStatuses", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
      },
    },
    skip: employeeWait,
    isWait: true,
  });
  const taskStateList = statusData?.GetTaskStatuses?.tasksStatus || [];
  const {
    data: teamsData,
    loading: teamsLoading,
    tokenChange: teamsWait,
  } = useQueryAuth(GET_ALL_TEAMS, "GetTeamsList", {
    variables: {
      model: {
        tenantGuid: storeUserTenant,
        userID:
          isEmployee === Mnemonic.ResourceTypes.Employee ? null : storeUserGuid,
      },
    },
    skip: employeeWait || statusWait,
    isWait: true,
  });
  const teams_list = teamsData?.GetTeamsList?.teamsList?.teams || [];

  const {
    data: projectData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !fetchProjects,
    isWait: fetchProjects,
  });
  useEffect(() => {
    if (projectData)
      setProjectsList(
        projectData?.GetAllProjects?.projectsList?.projectViews || []
      );
  }, [projectData]);

  const {
    data: areasList,
    loading: areaLoading,
    tokenChange: areaWait,
  } = useQueryAuth(GET_ALL_AREAS, "GetAreasList", {
    variables: {
      model: {
        enableFilters: false,
        projectGuid: selectedProject?.projectGuid,
      },
    },
    skip: !(fetchAreas && (selectedProject !== null)),
    isWait: fetchAreas && (selectedProject !== null),
  });

  useEffect(() => {
    if (areasList)
      setAreaList(
        areasList &&
          areasList.GetAreasList &&
          areasList.GetAreasList.areasList &&
          areasList.GetAreasList.areasList.areas
          ? areasList.GetAreasList.areasList.areas
          : []
      );
  }, [areasList]);

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_PARENT_TASKS, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,

        userID: storeUserGuid,
        projectID: selectedProject?.projectGuid,
      },
    },
    skip: !(fetchTasks && selectedProject !== null),
    isWait: fetchTasks && selectedProject !== null,
  });

  useEffect(() => {
    if (tasksList)
      setTaskList(
        tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
          ? tasksList.GetTasks.tasks
          : []
      );
  }, [tasksList]);

  const { mfunction: getAllTasksList, loading: tasksListLoading, refetch: refetchTasks } =
    useLazyQueryAuth(GET_TASK_BOARD_DATA, "GetTaskStatusesWithTasks");

  const { cfunction: updateTaskQuickData } = useMutationAuth(
    UPDATE_TASK_QUICK,
    "UpdateTaskQuick"
  );

  const updateTaskQuick = (taskGuid, statusGuid) => {
    updateTaskQuickData(
      {
        variables: {
          model: {
            taskGuid: taskGuid,
            userID: storeUserGuid,
            statusGuid: statusGuid,
            key: uuidv4(),
          },
        },
      },
      (response) => {
        if (
          response["UpdateTaskQuick"].errorCode !== null &&
          response["UpdateTaskQuick"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateTaskQuick"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          refetchTasks();
        } else {
          enqueueSnackbar(t("board.savedSuccessfully"), {
            variant: "success",
          });
        }
      },
      (error) => {
        refetchTasks();
      }
    );
  };

  const fetchBoard = useCallback(
    (
      page = 0,
      pageSize = 20,
      order = "",
      orderBy = "",
      search = "",
      filters = null,
      chipFilters = null
    ) => {
      getAllTasksList(
        {
          variables: {
            model: {
              tenantGuid: storeUserTenant,
              keyWord: search,
              filters: filters,
              userID: storeUserGuid,
              onlyTasks: chipFilters?.onlyTasks,
              onlySubtasks: chipFilters?.onlySubtasks,
              highPriority: chipFilters?.doing,
              myProjectsTasks: chipFilters?.myProjectTasks,
              projectGuid: fromTicket ? pID : fromProjectTasks ? pID : null,
              myTasks: chipFilters?.assignedToMe,
              myTeamsTasks: chipFilters?.assignedToMyTeam,
            },
          },
        },
        (response) => {
          const taskStatuses = response?.GetTaskStatusesWithTasks?.tasksStatusesWithTasksList?.taskStatuses || [];
          let columns = [];

          taskStatuses.forEach((taskStatus) => {
            const { taskStatusGuid, description, statusColor, tasks } = taskStatus;
            let tasksArr = [];


            tasks.forEach((task) => {
              const { guid, id, title, ticket, actualTime, estimatedEndDate, parentTaskGuid, priority, assignedTo } = task;
              let taskObj = {
                id: guid,
                template: (
                  <KanbanCard
                    task={title}
                    taskId={id}
                    taskGuid={guid}
                    type={parentTaskGuid ? "subtask" : "task"}
                    project={ticket?.project?.title}
                    dueDate={estimatedEndDate}
                    priority={priority}
                    actualTime={actualTime}
                    assignedTo={assignedTo}
                    updateTaskQuick={updateTaskQuick}
                    storeUserGuid={storeUserGuid}
                    refetch={refetchTasks}
                  />
                ),
              };
              tasksArr.push(taskObj);
            });

            let statusObj = {
              id: taskStatusGuid,
              title: description,
              color: statusColor?.colorCode,
              cards: tasksArr,
            };
            columns.push(statusObj);
          });

          setData({ columns: columns });
        }
      );
    },
    []
  );


  useEffect(() => {
    fetchBoard(0, 20, '', '', search, defaultFilters, chipFilters);
  }, [chipFilters]);

  const filtersObject = getFiltersObject(
    true,
    projectsList,
    employeesList,
    taskStateList,
    teams_list,
    teamsLoading,
    projectLoading,
    employeeLoading,
    statusLoading,
    isAdmin,
    isManager,
    defaultFilters,
    null,
    areaList,
    areaLoading,
    taskList,
    taskLoading,
    setFetchProjects,
    setFetchTasks,
    setFetchAreas,
    setSelectedProject,
    selectedProject,
    prioritiesList
  );

  const tablePagination = {
    defaultFilters,
    setDefaultFilters,
    loading: tasksListLoading,
    search,
    customButtons,
    readOnly: isEmployee === Mnemonic.ResourceTypes.External,
    setSearch,
    handleRefresh: refetchTasks,
    disableAddBtn: true,
    searchText: fromTenant
    ? "task.list.search.label.fromTenant"
    : "task.list.search.label",
    refetch: refetchTasks,
    filtersObject,
    treeTable: true,
    handleOpenModal,
    hideEditColumns: true,
    fetchTableData: fetchBoard,
  };
  useEffect(() => {
    let dynamicHeight = 'calc(100vh - 190px)';
    let dynamicChildColumn = 'calc(100vh - 260px)';

    if (pID) {
      if (showQuickFilters) {
        dynamicHeight = 'calc(100vh - 330px)';
        dynamicChildColumn = 'calc(100vh - 378px)';
      } else {
        dynamicHeight = 'calc(100vh - 263px)';
        dynamicChildColumn = 'calc(100vh - 310px)';
      }
    } else {
      if (showQuickFilters) {
        dynamicHeight = 'calc(100vh - 250px)';
        dynamicChildColumn = 'calc(100vh - 300px)';
      } else {
        dynamicHeight = 'calc(100vh - 190px)';
        dynamicChildColumn = 'calc(100vh - 240px)';
      }
    }

    document.documentElement.style.setProperty('--dynamic-height', dynamicHeight);
    document.documentElement.style.setProperty('--dynamic-child-column', dynamicChildColumn);
  }, [showQuickFilters, pID]);

  const ColumnAdder = ({ addColumn }) => {
    const [newTaskStatus, setNewTaskStatus] = useState("");
    const [newTaskStatusTitle, setNewTaskStatusTitle] = useState("");
    const [newTaskStatusColor, setNewTaskStatusColor] = useState("");


    const handleOnChangeTitle = (e) => { setNewTaskStatusTitle(e.target.value); }

    const { cfunction: createTaskStatusData } = useMutationAuth(
      CREATE_TASK_STATUS,
      "CreateTaskStatus"
    );

    function CreateTaskStatus() {
      setIsSubmitting(true);
      createTaskStatusData(
        {
          variables: {
            model: {
              description: newTaskStatusTitle,
              taskStateGuid: newTaskStatus || taskStatesList[0]?.taskStateGuid,
              tenantId: storeUserTenant,
              statusColorGuid: newTaskStatusColor || statusColorsList[0]?.statusColorGuid,
            },
          },
        },
        (response) => {
          if (
            response["CreateTaskStatus"].errorCode !== null &&
            response["CreateTaskStatus"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateTaskStatus"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
            setTimeout(() => {
              setIsSubmitting(false);
            }, 500);
          } else {
            enqueueSnackbar(t("taskStatusForm.createdSuccessfully"), {
              variant: "success",
            });
            const taskStatus = response["CreateTaskStatus"].taskStatus;
            setNewTaskStatus("")
            setNewTaskStatusTitle("")
            setNewTaskStatusColor("")
            addColumn({
              id: taskStatus?.taskStatusGuid,
              title: taskStatus?.description,
              color: taskStatus?.statusColor?.colorCode,
              cards: [],
            });
            setNewColumnFlag(false);
            refetchTasks();
            statusRefresh();
            refetchStatus()
          }
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        },
        (error) => {
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        }
      );
    }

    return (
      manageSystemConfigurations === "M" ?
        (newColumnFlag ?
          <div
            style={{
              minWidth: "270px",
              boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 4px",
              padding: "18px 10px",
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              marginRight: "20px",
              borderRadius: "5px",
            }}
          >
            <form style={{ padding: 0 }}>
              <TextField
                value={newTaskStatusTitle}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": {
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                    textAlign: "left",
                    fontSize: "14px !important",
                    minHeight: "32px !important",
                  },
                  "& .MuiInputBase-root": {
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                  },
                }}
                onChange={handleOnChangeTitle}
                required
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "5px", }}>
                <div style={{ width: "45%", }}>
                  <Select
                    value={newTaskStatus || taskStatesList[0]?.taskStateGuid}
                    onChange={(event) => setNewTaskStatus(event.target.value)}
                    sx={{
                      width: "100%",
                      "& .MuiSelect-select": {
                        padding: "5px 14px !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        marginLeft: "0 !important",
                        fontSize: "14px !important",
                      }
                    }}
                  >
                    {taskStatesList.map((stateObj) => (
                      <MenuItem key={stateObj?.taskStateGuid} value={stateObj?.taskStateGuid}>
                        {stateObj.description}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div style={{ width: "45%", }}>
                  <Select
                    value={newTaskStatusColor || statusColorsList[0]?.statusColorGuid}
                    onChange={(event) => setNewTaskStatusColor(event.target.value)}
                    sx={{
                      width: "100%",
                      "& .MuiSelect-select": {
                        padding: "5px 14px !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "0 !important",
                      }
                    }}
                  >
                    {statusColorsList.map((colorObj) => (
                      <MenuItem
                        key={colorObj[0]?.statusColorGuid}
                        value={colorObj?.statusColorGuid}
                        sx={{ justifyContent: "center" }}
                      >
                        <div
                          style={{
                            backgroundColor: colorObj.colorCode,
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        ></div>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "20px", marginLeft: "5px", }}>
                <span
                  style={{
                    textDecoration: "underline",
                    fontSize: "14px",
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                  onClick={() =>
                    setNewColumnFlag(false)
                  }
                >
                  {t("board.cancel")}
                </span>
                <IALoadingButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  label={t("board.save")}
                  onClick={CreateTaskStatus}
                />
              </div>
            </form>
          </div>
          : <div
            onClick={() => setNewColumnFlag(true)
            }
            style={{
              minWidth: "270px",
              boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 4px",
              padding: "18px 10px",
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            <AddCircleRounded
              sx={{
                color: colors.light_logo_blue,
                fontSize: "26px"
              }} /> <span style={{ marginLeft: "10px", fontSize: "16px" }}>{t("add.new.column")}</span>
          </div>) : <></>
    );
  };

  useEffect(() => {
    if (!isSubmitting) {
      fetchBoard(0, 20, '', '', search, defaultFilters, chipFilters);
    }
  }, [chipFilters, isSubmitting])


  function onColumnNew(newCol) {
    const newColumn = { id: uuidv4(), ...newCol };
    return newColumn;
  }
  const handleCardClick = (cardId, cardTitle) => {
    setSelectedTask(cardId);
    setSelectedTaskName(cardTitle);
    setOpenModal(true);
  };


  return (
    <div>

      <>
        <DataTable
          defaultChipFilters={{
            showParents: pID ? true : false,
            assignedToMe: pID ? false : true,
            assignedToMyTeam: pID ? false : true,
            onlySubtasks: true,
            onlyTasks: true,
            myProjectTasks: pID ? true : false,
            doing: false,
          }}
          handleResetChipFilter={() =>
            setChipFilters({
              showParents: pID ? true : false,
              assignedToMe: pID ? false : true,
              assignedToMyTeam: pID ? false : true,
              onlySubtasks: true,
              onlyTasks: true,
              myProjectTasks: pID ? true : false,
              doing: false,
            })
          }
          handleDefaultFilters={(f) => setFilters(f)}
          quickFilters
          savedFilterFlag={true}
          showUpdateFilterButton={
            selectedFilterGuid !== null &&
            selectedFilterGuid !== undefined &&
            selectedFilterGuid !== ""
          }
          handleUpdateFilter={handleSaveUserTableFilters}
          handleSaveFilter={handleSaveUserTableFilters}
          chips={chips}
          chipFilters={chipFilters}
          handleShowQuickFilters={(t) => setShowQuickFilters(t)}
          {...tablePagination}
        />
        {tasksListLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", margin: "40px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Board
            initialBoard={data}
            allowAddCard
            allowAddColumn
            disableColumnDrag
            onCardNew={console.log}
            renderColumnHeader={({ id, title, color }) => {
              const status = data.columns.find(column => column.id === id);
              const taskCount = status ? status.cards.length : 0;
              return (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={0}
                    sx={{
                      borderTop: `5px solid ${color}`,
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      backgroundColor: '#F0F0F0',
                      position: "sticky",
                    }}
                  >
                    <Typography ml={1.875} style={{
                      textTransform: 'uppercase',
                      fontWeight: "bold",
                      paddingBottom: "10px",
                      fontSize: "12px",
                      paddingTop: "10px",
                      flexGrow: 1,
                      marginRight: "8px",
                    }}>
                      {title}
                      <span style={{
                        fontWeight: "400",
                        marginLeft: "8px",
                      }}>
                        {taskCount}
                      </span>
                    </Typography>
                  </Box>
                </>
              );
            }}
            renderCard={({ id, template, task }, { dragging }) => {
              return (
                <Box
                  key={id}
                  className="board-box"
                  onClick={() => handleCardClick(id, task?.title || 'Unknown Task')}
                  dragging={dragging}
                  display="block"
                  bgColor={"white"}
                  color="text"
                  borderRadius="xl"
                  pt={2.5}
                  py={0}
                  pl={0}
                  mr={0}
                  ml={0}
                  lineHeight={1.5}
                  sx={{
                    backgroundColor: "burlywood",
                    fontSize: "15px",
                    borderRadius: "5px",
                  }}
                >
                  {typeof template === "string" ? ReactHtmlParser(template) : template}
                </Box>
              );
            }}
            onCardDragEnd={(board, card, source, destination) => {
              updateTaskQuick(card?.id, destination?.toColumnId);
              setData(prevData => {
                const updatedColumns = prevData.columns.map(column => {
                  if (column.id === source.fromColumnId) {
                    return {
                      ...column,
                      cards: column.cards.filter(c => c.id !== card.id),
                    };
                  }
                  if (column.id === destination.toColumnId) {
                    return {
                      ...column,
                      cards: [...column.cards, card],
                    };
                  }
                  return column;
                });
                return { ...prevData, columns: updatedColumns };
              });
            }}

            renderColumnAdder={({ addColumn }) => (
              <ColumnAdder addColumn={addColumn} />
            )}
            onColumnNew={(v) => onColumnNew(v)}
          />
        )}
      </>

      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading || statusLoading}
        taskID={selectedTask}
        taskName={selectedTaskName}
        handleChangeTask={(v) => setSelectedTask(v)}
        handleChangeProject={(v) => setSelectedProject(v)}
        projectID={selectedProject}
        fromProject={true}
        refetch={() => { fetchBoard(0, 20, '', '', search, defaultFilters, chipFilters) }}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
        type={type}
        setType={(v) => setType(v)}
        parent={parent}
        taskStatusList={taskStateList}
        data={data}
        updateData={(v) => setData(v)}
        totalFilters={chipFilters}
        // addNewSubtask={addNewSubtask.current}
        editMode={!addNewSubtask.current}
      />
    </div>
  );
}

