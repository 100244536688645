const form = {
  formID: "Roles",
  formField: {
    type: {
      name: "type",
      label: "onboardingForm.type.label",
      requiredMessage: "onboardingForm.type.requiredMessage",
    },
    description: {
      name: "description",
      label: "onboardingForm.description.label",
      requiredMessage: "onboardingForm.description.requiredMessage",

    },
    name: {
      name: "name",
      label: "onboardingForm.name.label",
      requiredMessage: "onboardingForm.name.requiredMessage",

    },
    emailBody: {
      name: "emailBody",
      label: "onboardingForm.emailBody.label",
      requiredMessage: "onboardingForm.emailBody.requiredMessage",

    },
    emailTitle: {
      name: "emailTitle",
      label: "onboardingForm.emailTitle.label",
      requiredMessage: "onboardingForm.emailTitle.requiredMessage",

    },
    attachment: {
      name:"attachment",
      label: "requestForm.attachment.label"
    },
    status: {
      name: "status",
      label: "projectForm.status.label",
      requiredMessage: "projectForm.status.requiredMessage",
    },
  },
};

export default form;