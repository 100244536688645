import { gql } from "@apollo/client";

export const GET_ALL_KIOS_DEVICES = gql`
query ($model: GetKioskDevicesListRequestInput!) {
  GetAllKioskDevices(model: $model) {
    kioskDeviceList {
      devices {
        kioskDeviceGuid
        deviceID
        description
        fingerPrint
        lastHeartBeat
        creationDate
        modificationDate
        createdBy {
          firstName
          lastName
        }
        modifiedBy {
          firstName
          lastName
        }
        status {
          description
        }
        location {
          address
        }
        workType {
          description
        }
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
 
`

export const GET_ALL_KIOSK_DEVICES_STATUSES = gql`
query {
    GetAllKioskDeviceStatus {
      kioskDeviceStatusList {
        description
        kioskDeviceStatusGuid
        kioskDeviceStatusID
      }
      errorVariables
      errorMessage
      errorCode
    }
  }

`

export const GET_KIOSK_DEVICE_BY_ID = gql`
query($deviceGuid: UUID!){
    GetKioskDeviceByID(kioskDeviceGuid: $deviceGuid) {
      kioskDevice {
        description
        deviceID
        fingerPrint
        locationGuid
        modificationDate
        modifiedByGuid
        statusGuid
        workTypeGuid
        location {
          address
          isOnSite
          latitude
          longitude
          name
          locationGuid
        }
        status {
          description
          kioskDeviceStatusGuid
          kioskDeviceStatusID
        }
        workType {
          description
          mnemonic
          workTypeGuid
          workTypeID
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }

`

export const UPDATE_KIOSK_DEVICE = gql`
mutation($model: UpdateKioskDeviceRequestModelInput!) {
  EditKioskDevice(model: $model) {
    kioskDevice {
      kioskDeviceGuid
    }
    errorMessage
    errorCode
    errorVariables
  }
  }
`

export const DELETE_KIOSK_DEVICE = gql`
mutation($deviceGuid: UUID!) {
    DeleteKioskDevice(deviceGuid: $deviceGuid) {
      kioskDeviceDeleted
      errorCode
      errorMessage
      errorVariables
    }
  }
`