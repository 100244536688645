import { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import NoUserImage from "../../assets/images/no-user-image.png";
import fileImage from "../../assets/images/file.png";
import UTC2Local from "../../UTC2Local";
import { DeleteIcon } from "../../components/Icon";
import { Link } from "react-router-dom";

function FileCard({ key, file, handleDelete }) {
  const { taskAttachmentGuid, fileName, uploadedDate, displayTitle } = file;
  const [blurred, setBlurred] = useState(false);

  return (
    <>
      <Card
        key={key}
        sx={{
          width: 150,
          position: "relative",
          "& .MuiPaper-root": {
            borderRadius: "5px",
            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"
          },
        }}
      >
        {/* <CardMedia
          sx={{ height: 140 }}
          image={fileName}
          title={displayTitle}
        /> */}
        <div className="mediaCard">
          <img
            width={150}
            height={100}
            className="mediaImg"
            src={
              fileName
                ? fileName.includes(".png") ||
                  fileName.includes(".jpg") ||
                  fileName.includes(".jpeg")
                  ? fileName
                  : fileImage
                : NoUserImage
            }
            alt="Not Found"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NoUserImage;
            }}
            onMouseOut={() => setBlurred(false)}
            onMouseOver={() => setBlurred(true)}
          />
          {true && (
            <IconButton
              className={`my-button-delete`}
              onClick={() => handleDelete(taskAttachmentGuid)}
            >
              <DeleteIcon color={"#000"} />
            </IconButton>
          )}
        </div>
        <CardContent sx={{ padding: "0 0 10px 10px !important" }}>
          <Typography
            variant="h6"
            onClick={() => window.open(fileName)}
            sx={{
              fontSize: "14px !important",
              cursor: "pointer",
              ":hover": { textDecoration: "underline" },
            }}
          >
            {displayTitle}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "12px !important" }}
          >
            {UTC2Local(uploadedDate)}
          </Typography>
        </CardContent>
      </Card>{" "}
    </>
  );
}

export default FileCard;
