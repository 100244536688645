const form = {
  formID: "WorkShifts",
  formField: {
    description: {
      name: "description",
      label: "workShift.description.label",
      requiredMessage: "workShift.description.requiredMessage",
    },
    totalWeeklyHours:{
      name:"totalWeeklyHours"
    },
    daysOfWeek: {
      dayName: {
        name: "dayName",
      },
      startHour: {
        name: "startHour",
        label: "workShift.startHour.label",
        requiredMessage: "workShift.startHour.requiredMessage",
        invalidMsg:"workShift.startHour.invalidDateFormat"
      },
      endHour: {
        name: "endHour",
        label: "workShift.endHour.label",
        requiredMessage: "workShift.endHour.requiredMessage",
        invalidMsg:"workShift.endHour.invalidDateFormat",
        dateDifference: "workShift.endHour.invalidDateDifference"
      },
      breakTime: {
        name: "breakTime",
        label: "workShift.breakTime.label",
        requiredMessage: "workShift.breakTime.requiredMessage",
        invalidMsg:"workShift.breakTime.invalidFormat",
      },
      isWorkDay: {
        name: "isWorkDay",
        label: "workShift.isWorkDay.label",
      },
      StartOfWeek: {
        name:"startOfWeek",
        label:"workShift.StartOfWeek.label"
      }
    },
  },
};

export default form;