import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";

import { Outlet } from "react-router-dom";
import { Backdrop,Grid, CircularProgress } from "@mui/material";
import ContainerCard from "../../../../../components/Cards/ContainerCard";
import AutoComplete from "../../../../../components/AutoComplete";
import FileInput from "../../../../../utils/importExcelFile";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../../graphql/employee";
import { CREATE_TIME_ENTRIES_FROM_EXCEL } from "../../../../../graphql/timeEntry";
import { useSnackbar } from "notistack";
import { getTable } from "./_table";
import colors from "../../../../../assets/theme/light/colors";
import DataTable from "../../../../../components/Tables/DataTable";
import FieldModal from "../../../../../components/Modal/FieldModal";
import PaperComponent from "../../../../../components/Modal/PaperComponent";
import CustomButton from "../../../../../components/CustomButton";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import {
  DeleteIcon,
  EyeIcon,
  HelpIcon,
  PDFIcon,
  UploadIcon,
  WarningIcon,
} from "../../../../../components/Icon";
import * as FileSaver from "file-saver";
import { CHECK_AND_CREATE_TIME_ENTRIES_FROM_EXCEL } from "../../../../../graphql/timeEntry";
import axios from "axios";
import { LOGOUT_USER } from "../../../../../graphql/user";
import ReactFileReader from "react-file-reader";
import { base64StringtoFile } from "../../../../../utils/base64StringtoFile";
import * as XLSX from "xlsx";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";
const backendURL = process.env.REACT_APP_REST_URL;
function ExcelBCFormat() {
  const { storeUserTenant, userRoles, user,  jwtToken, logout, storeUserGuid, timeEntryEnabled, isTimeEntryDescriptionMandatory } = useAuth();
  const { dark_logo_blue } = colors;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];
  const { enqueueSnackbar } = useSnackbar();
  const { manageTimeEntry } = userRoles;
  const [loading, setLoading] = useState(false);
  const [Applyloading, setApplyLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [includeSubtasks, setIncludeSubtasks] = useState(false);
  const [columns, setColumns] = useState([]);
  const [employeeValue, setEmployeeValue] = useState(
    user && timeEntryEnabled ? user.user : null
  );
  const [projectsValue, setProjectsValue] = useState([]);
  const [employeeID, setEmployeeID] = useState();
  const [data, setData] = useState([]);
  const [numOfRecords, setNumOfRecords] = useState(0);
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();
      if (col) {
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  

  const { cfunction: CheckAndImportData } = useMutationAuth(
    CHECK_AND_CREATE_TIME_ENTRIES_FROM_EXCEL,
    "CreateTimeEntriesFromBCExcelFormat"
  );

 async function CheckAndImportDataFunc(values) {
  const parsedValues = values.map(entry => ({
    ...entry,
    billable: entry.billable === 'true' || entry.billable === true||entry.billable === 'TRUE'
  }));
    CheckAndImportData(
      {
        variables: {
          model: {
            timeEntries: parsedValues,
            userGuid:storeUserGuid
          },
        },
      },
      (response) => {
        setApplyLoading(false)
        if (
          response["CreateTimeEntriesFromBCExcelFormat"].errorCode !== null &&
          response["CreateTimeEntriesFromBCExcelFormat"].errorCode !== ""
        ) {
          enqueueSnackbar(
            t(response["CreateTimeEntriesFromBCExcelFormat"].errorMessage),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
        } else {
          if(response["CreateTimeEntriesFromBCExcelFormat"].createTimeEntriesResult.createdTimeEntries)
         { enqueueSnackbar(t("timeEntryForm.createdSuccessfully"), {
            variant: "success",
          });
        setData([])
        }
          else{
            setData(response["CreateTimeEntriesFromBCExcelFormat"].createTimeEntriesResult.result?.map((row, index) => {
              const rowData = {
                resourceName: row["resourceName"],
                projectName:row["projectName"],
                task: row["taskName"],
                description: row["description"],
                date: row["date"],
                time: row["time"],
                billable:row["billable"],
                error:row["error"],

              };
              return { id: index, ...rowData };
            }));
            enqueueSnackbar(t("excel.errorImportDataFromExcel.message"), {
              variant: "error",
              autoHideDuration: 5000,
            })
          }
        }
      },
      (error) => {
        setApplyLoading(false)

        console.log(error);
      }
    );
  }

  const handleClose = () => {
    setIncludeSubtasks(false);
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleClose();
  };
  const sendData = () => {
    setApplyLoading(true)
    if ( data !== null && data.length > 0) {
      var dataToSend = data.map((row, index) => {
        const rowData = {
          resourceName: row["resourceName"],
          projectName:row["projectName"],
          taskName: row["task"],
          description: row["description"],
          date: new Date(row["date"]).toDateString(),
          time: parseFloat(row["time"]),
          billable:row["billable"]
        };  
        return { ...rowData };
      });
      CheckAndImportDataFunc(dataToSend);
    } else {
      setApplyLoading(false)

      enqueueSnackbar(t("excel.importWithouAppload.message") ,{
        variant: "error",
        autoHideDuration: 5000,
      },
      )
    }
  };

  const handleFile = (event) => {
    const file = event.fileList[0];
    if (!file) {
     
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const resourceGuid = jsonData[0][1];
      if (jsonData.length > 1) {
       // const headerRow = jsonData[1]; // Extract the second row as the header
        const dataRows = jsonData.slice(1); // Extract data starting from the third row

        // Filter out rows where the project name (assuming it's in the first column) is null or empty
        const filteredDataRows = dataRows.filter((row) => {
          return row[0] !== null && row[0] !== "" && row[0] != undefined;
        });

        handleFileLoaded(filteredDataRows, resourceGuid);
      } else {
        console.error(
          "The file does not contain enough rows for headers and data."
        );
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileLoaded = ( dataRows, resourceGuid) => {
    setEmployeeID(resourceGuid)
    setData(dataRows?.map((row, index) => {
      const rowData = {
        "resourceName":row[0],
        "projectName": row[1],
        "task":row[2],
        "date":row[3],
        "description":row[4],
        "time":row[5],
        "billable":row[6]
      };
      return { id: index, ...rowData };
    }));
    setNumOfRecords(dataRows?.length??0);
  };

  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
   }  

  async function handleExport() {
    setLoading(true);

    const res = await apiCall(
      backendURL + `/ExcelBCFormat/exportTemplateBCFormat`,
      "GET",
      null,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t,
      null,
      'blob'
    );

    if (res) {
      let fileName = "template.xlsx"; // Default file name

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blob = new Blob([res.data], { type: fileType });

      FileSaver.saveAs(blob, fileName);
      enqueueSnackbar(t("report.exportSuccessfully"), { variant: "success" });
      setFetched(false);
      setLoading(false);
    }else{
      setLoading(false);

    }
  }
  const pagination = {
    rows: data,
    columns,
    numberRecords: numOfRecords,
    loading: false,
    hideEditColumns: true,
    removeFilters: true,
    removeSelectAll: true,
    hideSearchBar: true,
    removeRefresh: true,
    removePagination: true,
    removeSorting: true,
  };
  return (
    <>
      <FieldModal
        PaperComponent={PaperComponent}
        CustomButton={CustomButton}
        loading={false}
        openDialog={openDialog}
        handleClose={handleClose}
        handleSave={handleSave}
        title={t("dialog.file.name")}
        label={t("task.list.exportToExcel.fileName")}
        t={t}
      />
      {Applyloading ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
             <div
              style={{
                width: "100%",
                padding: "13px 40px 0 10px",
                marginLeft: "17px",
              }}
            >
              <Outlet />
            </div>)}
      <Grid item xs={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
          }}
        >
          <Grid container spacing={1} display="flex" alignItems="center">
            
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6} display="flex">
              {/* <FileInput onFileLoaded={handleFileLoaded} /> */}
              <IALoadingButton
                 tooltipLabel={t("excel.tooltip.export")}
                onClick={() => handleExport()}
                loading={loading}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginRight: "10px",
                }}
                label={t("exportExcelTemplate")}
                disabled={Applyloading}
              />
              <ReactFileReader
                fileTypes={[".xlsx"]}
                base64={true}
                handleFiles={(e) => handleFile(e)}
              >
                <IALoadingButton
                disabled={Applyloading}

                  sx={{
                    ".MuiLoadingButton-root": {
                      BackgroundColor: "inherit !important",
                    },
                  }}
                  tooltipLabel={t("excel.tooltip.apply")}
                >
                  <UploadIcon width="25" height="25" color="#ffffff" />
                </IALoadingButton>
              </ReactFileReader>
              <IALoadingButton
              tooltipLabel={t("excel.tooltip.import")}
                onClick={sendData}
                loading={Applyloading}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginLeft: "10px",
                }}
                disabled={loading ||data===null ||data.length<1}
                label={t("importExcelBcFormat")}
              />
               
            </Grid>
          </Grid>
          {columns.length === 0 ? (
            <ContainerCard
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </ContainerCard>
          ) : (
            <>
              <DataTable {...pagination} height={"calc(100vh - 370px)"}  minWidth={500} />
            </>
          )}
        </ContainerCard>
      </Grid>
    </>
  );
}

export default ExcelBCFormat;
