export default {
    /**Tenant */
    "tenant.name.title" : "Tenant Name",
    "tenant.name.text" : "Represents the Name of the Tenant",
    "tenant.numberFullLicense.title":"Number of Full Licenses",
    "tenant.numberFullLicense.text":"Number of Full Licenses purchased.",
    "tenant.numberSelfService.title":"Number of Self Service Licenses",
    "tenant.numberSelfService.text":"Number of Self Service Licenses purchased.",
    "tenant.projectRadius.title":"Project Radius",
    "tenant.projectRadius.text": "Radius used for auto clock in on site.",
    "ptoForm.helpText":"Indicates the User Time Off allowed for the year",
    "ptoForm.helpTitle":"Help",
    "role.name.title":"Name",
    "role.name.text":"Represents the name of the Role",
    "role.description.title":"Description",
    "role.description.text":"Represents the description of the Role",
    "role.manageSystemConfigurations.title":"Manage System Configurations",
    "role.manageSystemConfigurations.text":"Role responsible for managing Departments, Locations, Workshifts, Official Holidays, Timeoff, Task and Phase Statuses, Skills",
    "changePassword.Pleasefollowthisguideforastrongpassword":"Follow this guide for a strong password",
    "changePassword.first": "One special character ",
    "changePassword.second": " Min 6 characters ",
    "changePassword.third": " One number (2 are recommended) ",
    "changePassword.fourth": " Change it often ",
   
    "role.manageUsers.title":"Manage Users",
    "role.manageUsers.text": "Role responsible for managing Employees",
    "role.manageTimeEntry.title":"Manage Time Entries",
    "role.manageTimeEntry.text":"Role responsible for managing Projects, Timesheets and Employees avalability",
    "role.manageAttendance.title":"Manage Attendance",
    "role.manageAttendance.text":"Role responsible for managing Employees attendance",
    "role.manageParameters.title":"Manage System Parameters",
    "role.manageParameters.text":"Role responsible for managing Tenants, Roles and Companies",

}