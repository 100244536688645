import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import { GET_ALL_TENANTS } from "../../../../graphql/tenant";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const TenantList = () => {

  const { t } = useTranslation();

  const { tenant_form, new_tenant } = urls;

  const { storeUserGuid } = useAuth();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const STATUS_VALUES = [
    { value: true, description: "Active" },
    { value: false, description: "Inactive" },
  ];

  const filtersObject = [
    {
      type: "text",
      label: "tenant.list.filter.name",
      name: "name",
    },
    {
      label: "tenant.list.filter.status",
      name: "isActive",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: STATUS_VALUES,
      access: "value",
    },
    {
      type: "check",
      label: "tenant.list.filter.trial",
      name: "trial",
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.Tenants,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllTenants, loading } = useLazyQueryAuth(
    GET_ALL_TENANTS,
    "GetAllTenants"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTenants(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              userID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllTenants &&
    data.GetAllTenants.tenantsList &&
    data.GetAllTenants.tenantsList.rows
      ? data.GetAllTenants.tenantsList.rows
      : [];
  const numberOfRecords =
    data &&
    data.GetAllTenants &&
    data.GetAllTenants.tenantsList &&
    data.GetAllTenants.tenantsList.numberOfRecords
      ? data.GetAllTenants.tenantsList.numberOfRecords
      : 0;

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "tenant.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.Tenants,
    filtersObject,
    generateRoute: (row) =>
      tenant_form.replace(
        "/:tName/:tID",
        `/${encodeURIComponent(row?.tenant?.name)}/${encodeURIComponent(
          row?.tenant?.tenantGuid
        )}`
      ),
    newText: "tenant.list.new.label",
    newLink: new_tenant,
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"tenant.tenantGuid"}
          hasCheckbox
        />
      )}
    </div>
  );
};

export default TenantList;
