import React, { useState, useEffect, useCallback, useRef } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AutoComplete from "../../../../components/AutoComplete";
//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import Tooltip from "../../../../components/Tooltip";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";

//graphql
import {
  CREATE_APPROVED_TIMESHEET,
  CREATE_TIMESHEET,
  GET_TIMESHEETS_LIST,
  REMOVE_TIMEENTRIES,
  REOPEN_APPROVED_TIMESHEET,
} from "../../../../graphql/timesheet";
import { GET_TIME_ENTRIES_APPROVAL } from "../../../../graphql/timeEntry";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TASKS_WITH_PROJECT } from "../../../../graphql/task";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import urls from "../../../../urls";

import { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";

//external components
import { CircularProgress, Grid, Box, Autocomplete } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import EditRecord from "../Timesheets-Form/components/EditRecord";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import TimeEntryDrawer from "../Timesheets-Form/components/TimeEntryDrawer";
import CustomButton from "../../../../components/CustomButton";
import {
  NextIcon,
  PreviousIcon,
  RefreshIcon,
  RemoveIcon,
  ReopenIcon,
  ExportExcelIcon
} from "../../../../components/Icon";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import { handleValidateDates } from "../../../../utils/functions";
import {
  DATE_FILTER_DATA,
  handleDateBackward,
  handleDateForward,
  handleTimeBetween,
} from "../../../../utils/period";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import colors from "../../../../assets/theme/light/colors";
import apiCall from "../../../../hooks/useRest";
import { useDispatch } from "react-redux";
import * as FileSaver from "file-saver";
import { LOGOUT_USER } from "../../../../graphql/user";
import { saveToken } from "../../../../app/reducers/tokenSlice";

import { v4 as uuidv4 } from "uuid";
import { removeNullProps } from "../../../../utils/removeNullProps";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { DataUsageTwoTone } from "@mui/icons-material";

const backendURL = process.env.REACT_APP_REST_URL;

const NewTimesheet = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { tID } = useParams();

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const { my_approved_timesheets } = urls;

  const { storeUserGuid, storeUserTenant, userRoles, isManager, logout } =
    useAuth();

  const { manageTimeEntry } = userRoles;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [linkCell, setLinkCell] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [excelRows, setExcelRows] = useState([]);

  const [numberRecords, setNumberRecords] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [clicked, setClicked] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [timeEntry, setTimeEntry] = React.useState(null);
  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [reopenSheet, setReopenSheet] = useState(false);
  const [deletedRecords, setDeletedRecords] = useState([]);
  const fetchedFromLocation = useRef(true);
  const locationDateFrom = useRef(null);
  const locationDateTo = useRef(null);
  const type = useRef("task");
  const { dark_logo_blue } = colors;

  const [isQueryEnabled, setIsQueryEnabled] = useState(false);

  const [re, setRe] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };
  const nowDate = new Date();

  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [open, setOpen] = useState(false);

  const [timer, setTimer] = useState(null);

  const [projectValue, setProjectValue] = useState(null);
  const [dateFromSheet, setDateFromSheet] = useState("");
  const [dateToSheet, setDateToSheet] = useState("");
  const [projectNameSheet, setProjectNameSheet] = useState("");

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );

  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    reopenSheet ? handleApprove() : handleReopenTimesheet();
    handleClose();
  };

  const handleOpenApproveConfirmationModal = (flag) => {
    setReopenSheet(flag);
    setOpenDialog(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { mfunction: approveTimesheet } = useMutationAuth(
    CREATE_APPROVED_TIMESHEET,
    "CreateApprovedTimeSheet"
  );

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        fetchTime: true,
      },
    },
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  useEffect(() => {
    if (location?.state) {
      var project = location?.state?.project;
      var start = location?.state?.startDate;
      var end = location?.state?.endDate;

      locationDateFrom.current = new Date(start);
      locationDateTo.current = new Date(end);
      if (project) setProjectValue(project);
      fetchedFromLocation.current = false;
      navigate(location.pathname, {});
    } else {
      fetchedFromLocation.current = false;
    }
  }, [location]);

  useEffect(() => {
    if (projectValue) {
      var start = projectValue?.oldestTimeEntry?.startTime
        ? projectValue?.oldestTimeEntry?.startTime
        : locationDateFrom.current
          ? locationDateFrom.current
          : new Date();
      var end = projectValue?.latestTimeEntry?.endTime
        ? projectValue?.latestTimeEntry?.endTime
        : locationDateTo.current
          ? locationDateTo.current
          : new Date();

      // fetchTableData(
      //   page,
      //   rowsPerPage,
      //   order,
      //   orderBy,
      //   search,
      //   defaultFilters,
      //   projectValue,
      //   new Date(start),
      //   new Date(end),
      //   tID
      // );

      setDateFrom(new Date(start));
      setDateTo(new Date(end));
      setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
    }
  }, [ fetchedFromLocation.current]);

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: taskWait,
  } = useQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: projectsWait,
    isWait: true,
  });

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        isManagerUsersList: isManager === true ? true : null,
        userID: storeUserGuid,
        timeEntryDisabled: true,
      },
    },
    skip: projectsWait || taskWait,
    isWait: true,
  });
  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: tableColumns,
    loading: getColumnsLoading,
    refetch: refetchColumns,
    tokenChange: columnsWait,
  } = useQueryAuth(GET_USER_TABLE_PREFERENCES, "GetUserTablePreferences", {
    variables: {
      model: {
        table: Mnemonic.Tables.TimesheetList,
        userID: storeUserGuid,
      },
    },
    skip: projectsWait || taskWait || employeeWait,
    isWait: true,
  });

  useEffect(() => {
    if (tableColumns) {
      let col = tableColumns.GetUserTablePreferences.columns;

      const actions = ({ compProps }) => {
        let props = compProps;
        return (
          <EditRecord
            {...props}
            handleClick={(v) => setClicked(v)}
            handleRowSelect={(t) => setTimeEntry(t)}
            handleEditMode={(v) => setEditMode(v)}
            disabled={manageTimeEntry !== "M"}
          />
        );
      };

      if (col) {
        col = decodeUnicodeString(col);

        //we parse the json string to a json object
        const parsed = JSON.parse(col, (key, value) =>
          Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, null, formatDateTimeToString)
        );

        if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

        if (parsed.defaultSortDirection) setOrder(parsed.defaultSortDirection);

        const colmns = parsed.columns;
        if (colmns) {
          colmns.forEach((col) => {
            col = removeNullProps(col)
            if (col.id === "date")
              return (col.renderCell = (row) => {
                return formatDateTimeToString(row?.startTime, false);
              });
            if (tID && col.id === "actions") return (col.visible = false);
          });
          setLinkCell(colmns.some((col) => col.link === true));
          setColumns(colmns);
        }
      }
    }
  }, [tableColumns, tID]);

  const closedByList = [
    { label: t("table.filters.all"), value: "none" },
    { label: "Closed By System", value: 1 },
    { label: "Closed By User", value: 2 },
  ];

  const filtersObject = [
    {
      label: "timesheet.list.filters.employees",
      name: "employee",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeeList,
      access: "employeeGuid",
      loading: employeeLoading,
    },
    {
      label: "timesheet.list.filters.projects",
      name: "project",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: projectsList,
      access: "projectGuid",
      loading: projectLoading,
    },
    {
      label: "timesheet.list.filters.tasks",
      name: "task",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: task_list,
      access: "taskGuid",
      loading: taskLoading,
      group: true,
      groupBy: (option) => option.ticket?.project?.title,
    },
    {
      type: "date",
      label: "timesheet.list.filters.date",
      name: "date",
    },
    {
      label: "timesheet.list.filters.automaticallyClosed",
      name: "automaticallyClosed",
      type: "radio-group",
      options: closedByList,
    },
  ];

  const { mfunction: reopenTimesheetRecord } = useMutationAuth(
    REOPEN_APPROVED_TIMESHEET,
    "ReopenApprovedTimeSheet"
  );

  const handleReopenTimesheet = () => {
    reopenTimesheetRecord(
      {
        variables: {
          id: tID,
        },
      },
      (response) => {
        enqueueSnackbar(t("timesheet.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);

        navigate(urls.my_created_timesheets, {
          state: response?.ReopenApprovedTimeSheet?.timeSheetView,
        });
      }
    );
  };

  const { mfunction: removeTimeEntry, loading: deleteLoading } =
    useMutationAuth(REMOVE_TIMEENTRIES, "RemoveTimeEntries");

  const handleDelete = () => {
    if (tID)
      removeTimeEntry(
        {
          variables: {
            model: {
              timeEntriesIDs: selected,
            },
          },
        },
        (response) => {
          enqueueSnackbar(t("timesheet.list.delete.success"), {
            variant: "success",
          });
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        }
      );
  };
  async function exportData() {

    if (!excelTimeSheetLoading && !excelTimeEntriesForApprovalLoading ) {
      const res = await apiCall(
        backendURL + `/ExportExcel/exportTimeSheet`,
        "POST",
        {
          "items": excelRows,
          "projectName": projectNameSheet,
          "dateFrom": dateFromSheet,
          "dateTo": dateToSheet
        },
        logsOutUser,
        logout,
        updateToken,
        enqueueSnackbar,
        t,
        null,
        "blob"
      );

      if (res) {
        let fileName = "ApprovedTimeSheet" + Date.now() + ".xlsx"; // Default file name

        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const blob = new Blob([res.data], { type: fileType });

        FileSaver.saveAs(blob, fileName);
        enqueueSnackbar(t("report.exportSuccessfully"), { variant: "success" });
      }
    }
  }

  const {
    data: timeEntriesForApprovalForExcel,
    loading: excelTimeEntriesForApprovalLoading,
    tokenChange: timeEntriesForApprovalWait,
  } = useQueryAuth(GET_TIME_ENTRIES_APPROVAL, "GetTimeEntriesForApproval", {
    variables: {
      model: {

        filters: defaultFilters,
        keyword: search,
        orderColumn: orderBy,
        orderDirection: order,
        dateFrom: dateFrom.toDateString(),
        dateTo: dateTo.toDateString(),
        userID: storeUserGuid,
        projectGuid: projectValue?.projectGuid,
      },
    },
    skip: projectValue?.projectGuid == null,
    isWait: true,
    enabled:projectValue?.projectGuid != null
  });


  const {
    mfunction: getTimeEntries,
    loading,
    refetch: refetchTimeEntries,
  } = useLazyQueryAuth(GET_TIME_ENTRIES_APPROVAL, "GetTimeEntriesForApproval");

  const {
    mfunction: getAllTimesheets,
    loading: timeEntriesLoading,
    refetch: refetchTimesheet,
  } = useLazyQueryAuth(GET_TIMESHEETS_LIST, "GetTimeSheetTimeEntries");


  const {
    data: TimesheetsForExcel,
    loading: excelTimeSheetLoading,
    tokenChange: timesheetsWait,
  } = useQueryAuth(GET_TIMESHEETS_LIST, "GetTimeSheetTimeEntries", {
    variables: {
      model: {
        enableFilters: true,

        filters: defaultFilters,
        keyword: search,
        orderColumn: orderBy,
        orderDirection: order,
        timesheetGuid: tID,
        userID: storeUserGuid,
      },
    },
    
    skip:!isQueryEnabled || tID == null,
    isWait: true,
    enabled:isQueryEnabled && tID != null
  });


  useEffect(() => {
    tID != null ? setExcelRows(TimesheetsForExcel?.GetTimeSheetTimeEntries?.timeSheetTimeEntries
      ?.timeEntries) : setExcelRows(timeEntriesForApprovalForExcel?.GetTimeEntriesForApproval?.timeSheetTimeEntries
        ?.timeEntries)
  }, [tID, timeEntriesForApprovalForExcel, TimesheetsForExcel])



  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
      // project = null,
      // start = null,
      // end = null,
      // timesheetGuid = null
    ) => {
     
      tID
        ? getAllTimesheets(
          {
            variables: {
              model: {
                enableFilters: true,
                keys: {
                  page: page + 1,
                  pageSize: pageSize,
                },
                filters: filters,
                keyword: search,
                orderColumn: orderBy,
                orderDirection: order,
                timesheetGuid: tID,
                userID: storeUserGuid,
              },
            },
          },
          (response) => {
            setRows(
              response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries
                ?.timeEntries
            );
         setIsQueryEnabled(true)

            // setExcelRows(response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries
            //   ?.timeEntries);
            setNumberRecords(
              response.GetTimeSheetTimeEntries.timeSheetTimeEntries
                .totalNumberOfRecords
            );
            setTotalDuration(
              response.GetTimeSheetTimeEntries.timeSheetTimeEntries
                .totalDuration
            );
            setDateFromSheet(UTC2Local(response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries.dateFrom))
            setDateToSheet(UTC2Local(response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries.dateTo))
            setProjectNameSheet(response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries.projectName)

          }
        )
        : projectValue && getTimeEntries(
          {
            variables: {
              model: {
                keys: {
                  page: page + 1,
                  pageSize: pageSize,
                },
                filters: filters,
                keyword: search,
                orderColumn: orderBy,
                orderDirection: order,
                dateFrom: dateFrom.toDateString(),
                dateTo: dateTo.toDateString(),
                userID: storeUserGuid,
                projectGuid: projectValue?.projectGuid,
              },
            },
          },
          (response) => {
            setRows(
              response?.GetTimeEntriesForApproval?.timeSheetTimeEntries
                ?.timeEntries
            );
         setIsQueryEnabled(true)

            // setExcelRows(response?.GetTimeEntriesForApproval?.timeSheetTimeEntries
            //   ?.timeEntries);
            setNumberRecords(
              response.GetTimeEntriesForApproval.timeSheetTimeEntries
                .totalNumberOfRecords
            );
            setTotalDuration(
              response.GetTimeEntriesForApproval.timeSheetTimeEntries
                .totalDuration
            );
            setDateFromSheet(dateFrom.toDateString())
            setDateToSheet(dateTo.toDateString())
            setProjectNameSheet(projectValue.title)

          }
        );
    },
    [projectValue, dateFrom, dateTo]
  );



  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);

  }, [columnsWait, re]);

  useEffect(() => {
    if (!columnsWait && tID) {
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);

    }
  }, [columnsWait]);

  const handleApprove = () => {
    approveTimesheet(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            projectGuid: projectValue?.projectGuid,
            startDate: dateFrom.toDateString(),
            endDate: dateTo.toDateString(),
            deletedRecords: deletedRecords,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("timesheet.approval.success"), {
          variant: "success",
        });
        navigate(my_approved_timesheets);
      }
    );
  };

  const handleRefresh = () => {
    fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);

  };

  const handleDisregardTimeEntries = () => {
    enqueueSnackbar(t("timesheet.disregarded.success"), {
      variant: "success",
    });
    setDeletedRecords([...deletedRecords, ...selected]);
    setSelected([]);
    setPage(0);
  };


  const { mfunction: CreateOpenTimesheet } = useMutationAuth(
    CREATE_TIMESHEET,
    "CreateOpenTimesheet"
  );

  

  const handleSaveTimesheet = () => {
    CreateOpenTimesheet(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            projectGuid: projectValue?.projectGuid,
            startDate: dateFrom.toDateString(),
            endDate: dateTo.toDateString(),
            deletedRecords: deletedRecords,
            tenantID: storeUserTenant,
           
            description: "",

          }
        },
      },
      (response) => {
        enqueueSnackbar(t("timesheet.list.save.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);

      }
    );
  };


  const customButtons = [
    {
      label: "timesheet.btn.refresh.label",
      handleClick: () => handleRefresh(),
      condition: true,
      icon: true,
      iconButton: <RefreshIcon color="#000000" />,
    },

    {
      label: "timesheet.btn.exportExcel.label",
      handleClick: () => exportData(),
      condition: true,
      icon: true,

      iconButton: <ExportExcelIcon color="#000000" />,
      disabled: !rows || exportLoading || rows?.length < 1
    },
    {
      label: "timesheet.btn.reopen.label",
      handleClick: () => handleOpenApproveConfirmationModal(false),
      icon: true,
      iconButton: <ReopenIcon color="#000000" width="24" height="24" />,
      condition:
        tID &&
        numberRecords > 0 &&
        manageTimeEntry === "M" &&
        !rows?.some((row) => row.synced === true),
    },
    {
      label: "timesheet.btn.approve.label",
      handleClick: () => handleOpenApproveConfirmationModal(true),
      icon: false,
      condition:
        (!selected || selected.length === 0) && manageTimeEntry === "M" && !tID,
      disabled:
        rows?.some((row) => row.automaticallyClosed === true) ||
        rows?.some((row) => row.synced === true) ||
        numberRecords === 0,
    },
   
    {
      label: "timesheet.btn.remove.label",
      handleClick: () => handleDisregardTimeEntries(),
      icon: true,
      condition: selected && selected.length > 0 && !tID,
      iconButton: (
        <RemoveIcon color={colors.icon_button_grey} width="24" height="24" />
      ),
    },
  ];

  const handleOpenModal = (row) => {
    setTaskID(row?.taskGuid);
    setProjectID(row?.projectGuid);
    setTaskName(row?.task);
    setProjectName(row?.project);
    setOpenModal(true);
  };

  const showCustomDiv = () => {
    return (
      <span style={{ fontSize: "13px", width: "10vw", display: "flex" }}>
        {t("timesheet.total.duration")} {totalDuration.toFixed(2)}
      </span>
    );
  };


  const pagination = {
    rows,
    setRows,
    columns,
    filtersObject,
    defaultFilters,
    setDefaultFilters,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    search,
    refetch: refetchColumns,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    deleteText: "timesheet.list.delete.label",
    hideRefreshButton: true,
    hideAddButton: true,
    fetchTableData,
    loading:
      loading ||
      getColumnsLoading ||
      timeEntriesLoading ||
      projectLoading ||
      taskLoading,
    tableMnemonic: Mnemonic.Tables["TimesheetList"],
    searchText: "timesheet.list.search.label",
    deleteText: tID ? "timesheet.list.delete.label" : "",
    readOnly: false || manageTimeEntry !== "M",
    handleDelete: tID ? handleDelete : null,
    deleteLoading,
    // hideEditColumns: true,
    removeRefresh: true,
    customButtons,
    keepFilters: true,
    linkCell,
    handleOpenModal,
    deletedRecords,
    showCustomDiv,
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  return (
    <div>
      <ConfirmationModal
        contentText={
          reopenSheet
            ? t("dialog.user.sheet.approve.confirmation")
            : t("dialog.user.reopen.sheet.confirmation")
        }
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />

      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        taskName={taskName}
        refetch={tID ? refetchTimesheet : refetchTimeEntries}
        fromProject={true}
        editMode={true}
        setType={(v) => type.current = v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />

      <TimeEntryDrawer
        PaperComponent={PaperComponent}
        openTimeEntryModal={clicked}
        setOpenTimeEntryModal={(v) => setClicked(setClicked)}
        timeEntryGuid={timeEntry?.timeEntryGuid}
        description={timeEntry?.description}
        synced={manageTimeEntry !== "M" ? true : timeEntry?.synced}
        refetchData={refetchTimeEntries}
        editMode={editMode}
        handleEditMode={(v) => setEditMode(v)}
        employees={employeeList}
        fromTimesheet={true}
        tasks={task_list}
        group={true}
        t={t}
      />

      <Box
        sx={{
          marginTop: {
            xs: "10px !important",
            sm: "10px !important",
            md: "10px !important",
            lg: "10px !important",
            xl: "10px !important",
          },
        }}
      >
        {!tID && (
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              sx={{ marginBottom: "10px !important" }}
            >
              <AutoComplete
                loading={projectLoading}
                options={projectsList}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"projectGuid"}
                size="small"
                value={projectValue}
                onChange={(event, value) => {
                  setProjectValue(value);
                  fetchedFromLocation.current = false;
                  setRows([]);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                }}
                label={t("reports.label.projects")}
                roundedTextField
                required
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateFrom")}
                value={dateFrom}
                disableFuture
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  fetchedFromLocation.current = false;
                  setDateVal(
                    data_filter.find((x) => x.mnemonic === "custom")
                  );
                }}
                onClose={() => setOpen(true)}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateTo")}
                value={dateTo}
                // disableFuture
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  fetchedFromLocation.current = false;
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(
                    data_filter.find((x) => x.mnemonic === "custom")
                  );
                }}
              // renderInput={(params) => (
              //   <RoundedTextField removeMinWidth {...params} />
              // )}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <PreviousIcon
                  color={
                    dateVal.mnemonic === "custom" ? "#73717380" : "#737173"
                  }
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={4} md={2} lg={1.5} xl={1.5}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {t(option.name)}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  fetchedFromLocation.current = false;
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{ ".MuiInputBase-input": { fontSize: "14px !important" } }}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <NextIcon
                  color={
                    dateVal.mnemonic === "custom" ? "#73717380" : "#737173"
                  }
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <IALoadingButton
                onClick={() => {
                  setRe((val) => !val);
                  setDeletedRecords([]);
                }}
                style={{
                  width: "150px",
                  backgroundColor: colors.dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                }}
                label={t("timesheet.create.Search")}
                disabled={!projectValue}
              />
            </Grid>
          </Grid>
        )}
        {getColumnsLoading ? (
          <ContainerCard
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <CircularProgress />
          </ContainerCard>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DataTable
              {...pagination}
              identifier="timeEntryGuid"
              fromTimesheets
              rows={rows}
              heightClassName="table-monthly-sheet-height"
              isFixed
              hasCheckbox={
                rows?.filter((row) => row.synced === true).length != rows?.length
              }
              checkForCheckbox={(row) => row.synced !== true}
            />
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default NewTimesheet;
