import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//external components

//graphql
import {
  GET_ALL_EMPLOYEE_PTOS,
  CHANGE_EMPLOYEE_PTO_STATUS,
} from "../../../../graphql/pto";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

import urls from "../../../../urls";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import { ASSIGN_PTOS_TO_EMPLOYEES } from "../../../../graphql/pto";

//external components
import { CircularProgress, Button } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import useMutationAuth from "../../../../hooks/useMutationAuth";

import PTOTable from "../Employee-form/components/PTOTable";

import { getEmployeeFromStore } from "../../../../app/reducers/employeeSlice";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import { HelpIcon } from "../../../../components/Icon";
import CustomButton from "../../../../components/CustomButton";

import colors from "../../../../assets/theme/light/colors";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const EmployeePTOList = ({
  wait = false,
  refetchData = false,
  fromProfile = false,
  fromTenant = false,
}) => {
  const { eID, eName, tID, tName, profileID, profileName } = useParams();
  const employee = useSelector(getEmployeeFromStore);
  
  const {
    employee_form_days_used,
    my_employee_form_days_used,
    my_profile_employee_form_days_used,
    admin_profile_form_days_used,
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isEntelligence } = userRoles;

  const [selectedStatus, setSelectedStatus] = useState("");

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const PTOs = [];

  const [displayTable, setDisplayTable] = useState(false);

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [refetchList, setRefetchList] = useState(false);
  const [pTOArray, setPTOArray] = useState([]);

  const filtersObject = [
    {
      type: "text",
      label: "employeePTOs.list.filters.description",
      name: "description",
    },
    {
      type: "number",
      label: "employeePTOs.list.filters.minDaysUsed",
      name: "minDaysUsed",
    },
    {
      type: "number",
      label: "employeePTOs.list.filters.maxDaysUsed",
      name: "maxDaysUsed",
    },
    {
      type: "number",
      label: "employeePTOs.list.filters.minNumberOfDays",
      name: "minNumberOfDays",
    },
    {
      type: "number",
      label: "employeePTOs.list.filters.maxNumberOfDays",
      name: "maxNumberOfDays",
    },

    {
      type: "number",
      label: "employeePTOs.list.filters.minRemainingDays",
      name: "minRemainingDays",
    },
    {
      type: "number",
      label: "employeePTOs.list.filters.maxRemainingDays",
      name: "maxRemainingDays",
    },
  ];

  const { mfunction: assingPTOtoEmployee } = useMutationAuth(
    ASSIGN_PTOS_TO_EMPLOYEES,
    "AssignPtoToEmployee"
  );

  const { mfunction: changeStatus } = useMutationAuth(
    CHANGE_EMPLOYEE_PTO_STATUS,
    "UpdateEmployeePTOStatus"
  );

  function changePTOStatus(ptos, active) {
    changeStatus(
      {
        variables: {
          model: {
            active: active,
            employeePTOGuid: ptos,
          },
        },
      },
      () => {
        enqueueSnackbar(t("employeePtoForm.statusChangedSuccessfully"), {
          variant: "success",
        });
        setSelected([]);
        setRe((val) => !val);
      }
    );
  }

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.EmployeePTOs,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllEmployeePTOs, loading } = useLazyQueryAuth(
    GET_ALL_EMPLOYEE_PTOS,
    "GetEmployeePTOsList"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployeePTOs(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              userID: storeUserGuid,
              filters: filters,
              mnemonic: Mnemonic.Tables.EmployeePTOs,
              employeeGuid: eID ? eID : profileID,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait && employee?.resourceType === Mnemonic.ResourceTypes.Employee)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [refetchData, columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetEmployeePTOsList &&
    data.GetEmployeePTOsList.employeePTOsListModel &&
    data.GetEmployeePTOsList.employeePTOsListModel.employeePTOs
      ? data.GetEmployeePTOsList.employeePTOsListModel.employeePTOs
      : [];
  const numberOfRecords =
    data &&
    data.GetEmployeePTOsList &&
    data.GetEmployeePTOsList.employeePTOsListModel &&
    data.GetEmployeePTOsList.employeePTOsListModel.totalNumberOfRecords
      ? data.GetEmployeePTOsList.employeePTOsListModel.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "employeePtoForm.addPTO",
      handleClick: () => setDisplayTable(true),
      condition: (!selected || selected.length === 0) && manageUsers === "M" && employee?.resourceType === Mnemonic.ResourceTypes.Employee,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
        sx={{
         color:"var(--color--light-logo-blue)",
         fontSize: "28px !important"
        }}
      />
      ),
    },
    {
      label: "employeePtoForm.activateEmployeePTO",
      handleClick: () => {
        let active;

        if (selectedStatus === Mnemonic.PTOStatuses.Active) active = false;
        else active = true;
        changePTOStatus(selected, active);
      },
      condition:
        selected &&
        selected.length > 0 &&
        selectedStatus === Mnemonic.PTOStatuses.Inactive,
    },
    {
      label: "employeePtoForm.deactivateEmployeePTO",
      error: true,
      handleClick: () => {
        let active;

        if (selectedStatus === Mnemonic.PTOStatuses.Active) active = false;
        else active = true;
        changePTOStatus(selected, active);
      },
      condition:
        selected &&
        selected.length > 0 &&
        selectedStatus === Mnemonic.PTOStatuses.Active,
    },
  ];

  useEffect(() => {
    if (selected && selected.length === 0) setSelectedStatus("");
  }, [selected]);

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "employeePTOs.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.EmployeePTOs,
    filtersObject,
    hideEditColumns: false,
    readOnly: manageUsers !== "M",
    customButtons: eID ? customButtons : !customButtons,
    generateRoute: (row) =>
      fromProfile
        ? fromTenant
          ? admin_profile_form_days_used
              .replace(
                "/:profileName/:profileID",
                `/${encodeURIComponent(profileName)}/${encodeURIComponent(
                  profileID
                )}`
              )
              .replace(
                "/:duName/:duID",
                `/${encodeURIComponent(
                  row?.pto?.description
                )}/${encodeURIComponent(row?.pto?.ptoGuid)}`
              )
          : my_profile_employee_form_days_used
              .replace(
                "/:profileName/:profileID",
                `/${encodeURIComponent(profileName)}/${encodeURIComponent(
                  profileID
                )}`
              )
              .replace(
                "/:duName/:duID",
                `/${encodeURIComponent(
                  row?.pto?.description
                )}/${encodeURIComponent(row?.pto?.ptoGuid)}`
              )
        : fromTenant
        ? employee_form_days_used
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            )
            .replace(
              "/:duName/:duID",
              `/${encodeURIComponent(
                row?.pto?.description
              )}/${encodeURIComponent(row?.pto?.ptoGuid)}`
            )
        : my_employee_form_days_used
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            )
            .replace(
              "/:duName/:duID",
              `/${encodeURIComponent(
                row?.pto?.description
              )}/${encodeURIComponent(row?.pto?.ptoGuid)}`
            ),
    onCheckbox: (value, row, selected) => {
      if (value === true && selected.length === 0) {
        setSelectedStatus(row?.ptoStatus?.mnemonic || "");
      }
    },
    removeSelectAll: !selected || selected.length === 0,
    checkboxDisable: (row) => {
      const status = row?.ptoStatus?.mnemonic || "";

      return selectedStatus && status !== selectedStatus;
    },
  };

  function handleOpenDialog(array) {
    setPTOArray(array);
    array.forEach(element => {
      if(element.accumulated) setOpenDialog(true);
      else handleAddPTO(false, array)
    });
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function handleAddPTO(assignPreviousDates, array) {
    handleCloseDialog();
    const arr = array || pTOArray;
    const ptoGUIDs = arr
      ? arr.map((val, index) => {
          return val.ptoGuid;
        })
      : [];

    assingPTOtoEmployee(
      {
        variables: {
          model: {
            ptoID: ptoGUIDs[0],
            employeeID: eID,
            assignPreviousDates: assignPreviousDates,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("assignPtoToEmployee.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        setRefetchList(!refetchList);
        // setDisplayTable(false);
      }
    );
  }

  const customModalButtons = () => (
    <>
      <CustomButton onClick={() => handleAddPTO(true)} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={() => handleAddPTO(false)} label={t("dialog.no")} />
      </div>
    </>
  );

  return (
    <div>
    <ConfirmationModal
      contentText={t("dialog.add.days.balance")}
      title={t("dialog.confirm")}
      icon={<HelpIcon color={colors.light_logo_blue}/>}
      openDialog={openDialog}
      handleClose={handleCloseDialog}
      customButtons={customModalButtons}
      t={t}
    />
      {displayTable ? (
        <PTOTable
          display={displayTable}
          tenant={isEntelligence ? tID : storeUserTenant}
          initialVal={PTOs}
          handleClick={handleOpenDialog}
          refetchList={refetchList}
          setDisplay={setDisplayTable}
        />
      ) : null}
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"employeePTOGuid"}
          hasCheckbox={eID ? true : false}
          fromEmployee
        />
      )}
    </div>
  );
};

export default EmployeePTOList;
