export default {
  formID: "ticket",
  formField: {
    title: {
      name: "title",
      label: "ticketForm.name.label",
      requiredMessage: "ticketForm.name.requiredMessage",
    },
    description: {
      name: "description",
      label: "ticketForm.description.label",
      requiredMessage: "TenantForm.description.requiredMessage",
    },
    status: {
      name: "status",
      label: "ticketForm.status.label",
      requiredMessage: "TenantForm.status.requiredMessage",
    },
    area: {
      name: "area",
      label: "ticketForm.area.label",
      requiredMessage: "TenantForm.area.requiredMessage",
    },
    EstimatedStartDate:{
      name: "EstimatedStartDate",
      label: "ticketForm.EstimatedStartDate.label",
      requiredMessage: "TenantForm.EstimatedStartDate.requiredMessage",
    },
    EstimatedEndDate:{
      name: "EstimatedEndDate",
      label: "ticketForm.EstimatedEndDate.label",
      requiredMessage: "TenantForm.EstimatedEndDate.requiredMessage",
    },
    EstimatedTime:{
      name: "EstimatedTime",
      label: "ticketForm.EstimatedTime.label",
      requiredMessage: "TenantForm.EstimatedTime.requiredMessage",
    },
    ActualStartDate:{
      name: "ActualStartDate",
      label: "ticketForm.ActualStartDate.label",
      requiredMessage: "TenantForm.ActualStartDate.requiredMessage",
    },
    ActualEndDate:{
      name: "ActualEndDate",
      label: "ticketForm.ActualEndDate.label",
      requiredMessage: "TenantForm.ActualEndDate.requiredMessage",
    },
    ActualTime:{
      name: "ActualTime",
      label: "ticketForm.ActualTime.label",
      requiredMessage: "TenantForm.ActualTime.requiredMessage",
    },
    synced:{
      name: "synced",
      label: "ticketForm.synced.label",
      requiredMessage: "TenantForm.synced.requiredMessage",
    },
    externalReference:{
      name: "externalReference",
      label: "ticketForm.externalReference.label",
      requiredMessage: "TenantForm.externalReference.requiredMessage",
    }
  },
};
