import { gql } from "@apollo/client";

export const GET_ALL_AREAS=gql`
query ($model: GetAllAreasRequestInput!) {
    GetAreasList(model: $model) {
      areasList {
        areas {
          areaGuid
          description
          projectGuid
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`

export const GET_AREA_BY_ID=gql`
query ($id: UUID!) {
    GetAreaByID(areaGuid: $id) {
      area {
        areaGuid
        description
        projectGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const CREATE_AREA=gql`
  mutation($model:AreaCreateRequestInput!){
    CreateArea(model:$model){
      area{
        areaGuid
        description
        projectGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const UPDATE_AREA=gql`
  mutation($model:AreaEditRequestInput!){
    UpdateArea (model: $model) {
      area {
        areaGuid
        description
        projectGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const DELETE_AREA=gql
  `mutation($model:AreasListDeleteRequestInput!){
    DeleteAreas (model : $model){
      deleteAreaResponse
      errorCode
      errorMessage
      errorVariables
    }
  }`