import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

//components
import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";
import { Title, Subtitle } from "../../../../../components/Titles/FormTitles";
import {
  Grid,
  Box,
  TextField,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";

import AutoComplete from "../../../../../components/AutoComplete";
import { useSelector } from "react-redux";

//translation
import { useTranslation } from "react-i18next";

import "./style.css";

import colors from "../../../../../assets/theme/light/colors";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { day_month_year } from "../../../../../utils/timezones";
import { getEmployeeFromStore } from "../../../../../app/reducers/employeeSlice";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData, pTOs, employees, resetFields, numberOfDaysError, setNumberOfDaysError, focusField }) => {
  const { t } = useTranslation();
  const { duID, duName, eID, eName } = useParams();
  const employeeData = useSelector(getEmployeeFromStore);
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    handleClose,
  } = formData;

  const {
    employee,
    pTO,
    description,
    date,
    numberOfDays,
    numberOfHours,
    entitlement,
  } = formField;

  const {
    employee: employeeV,
    pTO: pTOV,
    description: descriptionV,
    date: dateV,
    entitlement: entitlementV,
    numberOfDays: numberOfDaysV,
    numberOfHours: numberOfHoursV,
  } = values;

  useEffect(() => {
    if (eID && eName) {
      const employeeObj = employees?.filter((x) => x.employeeGuid === eID)[0];

      setFieldValue(employee.name, {
        employeeGuid: employeeObj?.employeeGuid,
        firstName: employeeObj?.firstName,
        lastName: employeeObj?.lastName,
      });
    }
    if (duID && duName) {
      const selectedPTO = pTOs?.filter((x) => x.ptoGuid === duID)[0];
      setFieldValue(pTO.name, selectedPTO);
      // setFieldValue(pTO.name, { ptoGuid: duID, description: duName });
    }

    setFieldValue(entitlement.name, 0);
  }, [eID, eName, duID, duName]);

  useEffect(() => {
    setFieldValue(description.name, "");
    setFieldValue(date.name, null);
    setFieldValue(entitlement.name, 0);
    setFieldValue(numberOfDays.name, 0);
    setFieldValue(numberOfHours.name, 0);
    sessionStorage.removeItem("unsavedChanges");
  }, [resetFields])
  
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title style={{ padding: "10px 0" }}>
            {t("dayUsedForm.addTitle")}
          </Title>
          <IconButton onClick={() => handleClose()}>
            <ClearOutlinedIcon />
          </IconButton>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AutoComplete
            options={employees}
            getOptionLabel={(option) =>
              option ? option.firstName + " " + option.lastName : ""
            }
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.firstName + " " + option.lastName}
              </li>
            )}
            identifier={"employeeGuid"}
            value={employeeV}
            onChange={(e, value) => {
              setFieldValue(employee.name, value);
            }}
            label={t(employee.label)}
            name={employee.name}
            formData={formData}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AutoComplete
            options={pTOs}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"ptoGuid"}
            value={pTOV}
            onChange={(e, value) => {
              setFieldValue(pTO.name, value);
            }}
            label={t(pTO.label)}
            name={pTO.name}
            formData={formData}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl fullWidth size="small">
            <RadioGroup
              row
              aria-labelledby={entitlement.name}
              name={entitlement.name}
              value={entitlementV}
              onChange={(event, value) => {
                if(parseInt(value) === 1 || parseInt(value) === 2) setNumberOfDaysError(null);
                setFieldValue(entitlement.name, parseInt(value));
              }}
              sx={{
                ".MuiTypography-root": { fontSize: "14px !important" },
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={t("addDayUsed.usage.label")}
                key={0}
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={t(entitlement.label)}
                key={1}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid
          className="timesheet-date"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              inputRef={focusField}
              autoFocus
              fullWidth
              format={
                day_month_year.includes(userTimezone) === true
                  ? "dd-MM-yyyy"
                  : "MM-dd-yyyy"
              }
              label={t(date.label)}
              value={dateV}
              onChange={(newValue) => {
                setFieldValue(date.name, newValue);
              }}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                dialog: {
                  disablePortal: true,
                  style: { zIndex: 1000000 },
                },
                textField: {
                  required: true,
                  size: "medium",
                  variant: "standard",
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },

                  error: touched[date.name] && errors[date.name],
                  helperText:
                    touched[date.name] &&
                    errors[date.name] &&
                    t(errors[date.name]),

                  onBlur: (e) =>
                    setTouched({
                      ...touched,
                      [date.name]: true,
                    }),
                },
              }}
              sx={{
                width: "100% !important",
                fontFamily: "poppins",
                "& .MuiInputBase-input": {
                  fontSize: "14px !important",
                },
                "& .MuiInputAdornment-root .MuiButtonBase-root": {
                  fontSize: "14px !important",
                  marginRight: "-5px !important",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <TextField
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              ".Mui-disabled": { color: "#636563" },
            }}
            variant="standard"
            fullWidth
            required
            type="number"
            label={t(numberOfDays.label)}
            value={numberOfDaysV}
            onBlur={(e) => {
              setTouched({ ...touched, [numberOfDays.name]: true });
              setFieldValue(numberOfDays.name, parseFloat(e.target.value));
            }}
            onChange={(e) => {
              const enteredValue = e.target.value;
              if (entitlementV == 0) {
                if (enteredValue > 1){
                  setNumberOfDaysError(t("numberOfDays.one.max"));}
                else setNumberOfDaysError(null);
              } else {
                setNumberOfDaysError(null);
              }
              const numericValue = parseInt(enteredValue, 10);
              setFieldValue(numberOfDays.name, parseFloat(enteredValue));
            }}
            error={errors[numberOfDays.name] || numberOfDaysError}
            helperText={
              (errors[numberOfDays.name] && t(errors[numberOfDays.name])) || numberOfDaysError
            }
            InputProps={{
              inputProps: entitlementV === 0 ? { min: 0, max: 1, step: "any" } : {},
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2} display="flex" alignItems="end" sx={{paddingLeft: "6px !important"}}>
          <Typography>{pTOV?.dateUnit?.description}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <TextField
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              ".Mui-disabled": { color: "#636563" },
            }}
            variant="standard"
            fullWidth
            type="number"
            label={t(numberOfHours.label)}
            disabled={entitlementV !== 0 ? true : false}
            value={numberOfHoursV}
            onBlur={(e) => {
              setTouched({ ...touched, [numberOfHours.name]: true });
            }}
            onChange={(e) => {
              const enteredValue = e.target.value;
              setFieldValue(numberOfHours.name, parseFloat(enteredValue));
            }}
            error={errors[numberOfHours.name]}
            helperText={
              errors[numberOfHours.name] && t(errors[numberOfHours.name])
            }
            InputProps={{ inputProps: { min: 0, max: 8 } }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={3}>
          <TextField
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              ".Mui-disabled": {
                color: "#636563",
                WebkitTextFillColor: "#636563",
              },
            }}
            required={false}
            variant="standard"
            fullWidth
            label={t(description.label)}
            placeholder={t(description.label)}
            value={descriptionV}
            multiline
            onBlur={(e) => setTouched({ ...touched, [description.name]: true })}
            onChange={(e) => {
              setFieldValue(description.name, e.target.value);
            }}
            error={touched[description.name] && errors[description.name]}
            helperText={
              touched[description.name] &&
              errors[description.name] &&
              t(errors[description.name])
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
