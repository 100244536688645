import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const CheckStatus = (props) => {
    const { jobEnabled } = props;
    const { checkStatusColors } = colorsLight;

    return (
        <span
            style={{
                padding: "5px 20px",
                borderRadius: "5px",
                fontFamily: "poppins  !important",
                ...checkStatusColors[jobEnabled],
            }}
        >
            {jobEnabled === true ? 
                "Enabled" : "Disabled"}
        </span>
    );
};

export default CheckStatus;
