const form = {
  formID: "Suggestion",
  formField: {
    suggestionStatus: {
      name: "suggestionStatus",
      label: "roleForm.suggestionStatus.label"
    },
    description: {
      name: "description",
      label: "roleForm.description.label",

    },

  },
};

export default form;