import { createSlice } from "@reduxjs/toolkit";

const initialState = { employee: {} };

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    getEmployee: (state, { payload }) => {
      let { employee } = state;
      employee = { employee: payload };
     return employee;
    },
  },
});

export const { getEmployee } = employeeSlice.actions;

export const getEmployeeFromStore = (state) => state.employee.employee;
export default employeeSlice.reducer;
