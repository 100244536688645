import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const QuantityUsed = (props) => {
    const { quantity, remainingQuantity } = props;
    
    const difference = quantity - remainingQuantity 

    if (!quantity) return null;
    return (

        <div  style={{ fontSize: "0.875rem" }}>
           
            {difference ? difference : 0}
        </div>
    );

};

export default QuantityUsed;