import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
//components
import { CircularProgress } from "@mui/material";
import DataTable from "../../../../components/Tables/DataTable";
import SkillTable from "../Employee-form/components/SkillsTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  GET_ALL_EMPLOYEE_SKILLS,
  CREATE_EMPLOYEE_SKILL,
  DELETE_EMPLOYEE_SKILLS_LIST,
} from "../../../../graphql/employeeSkill";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//snackbar
import { useSnackbar } from "notistack";

//translation
import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const EmployeeSkillsList = ({ refetchData = false, fromEmployee = false }) => {
  const skills = [];

  const { eID, tID, profileID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isEntelligence } = userRoles;

  const [selectedStatus, setSelectedStatus] = useState("");

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [displayTable, setDisplayTable] = useState(false);

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const filtersObject = [
    {
      type: "text",
      label: "employeeSkills.list.filters.description",
      name: "description",
    },
  ];

  const { mfunction: assignSkilltoEmployee } = useMutationAuth(
    CREATE_EMPLOYEE_SKILL,
    "CreateEmployeeSkill"
  );

  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_EMPLOYEE_SKILLS_LIST,
    "DeleteEmployeeSkillsList"
  );

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.EmployeeSkills,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllEmployeeSkills, loading } = useLazyQueryAuth(
    GET_ALL_EMPLOYEE_SKILLS,
    "GetAllEmployeeSkills"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployeeSkills(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyWord: search,
              employeeGuid: eID ? eID : profileID,
              orderColumn: orderBy,
              orderDirection: order,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [refetchData, columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllEmployeeSkills &&
    data.GetAllEmployeeSkills.employeeSkillsList &&
    data.GetAllEmployeeSkills.employeeSkillsList.employeeSkills
      ? data.GetAllEmployeeSkills.employeeSkillsList.employeeSkills
      : [];
  const numberOfRecords =
    data &&
    data.GetAllEmployeeSkills &&
    data.GetAllEmployeeSkills.employeeSkillsList &&
    data.GetAllEmployeeSkills.employeeSkillsList.totalNumberOfRecords
      ? data.GetAllEmployeeSkills.employeeSkillsList.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "employeeSkillForm.addSkill",
      handleClick: () => setDisplayTable(true),
      condition: (!selected || selected.length === 0) && manageUsers === "M",
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
        sx={{
         color:"var(--color--light-logo-blue)",
         fontSize: "28px !important"
        }}
      />
      ),
    },
  ];

  useEffect(() => {
    if (selected && selected.length === 0) setSelectedStatus("");
  }, [selected]);

  function handleDelete() {
    deleteRecord(
      {
        variables: {
          model: {
            employeeSkillIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("skills.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  }

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "employeeSkills.list.search.label",
    tableMnemonic: Mnemonic.Tables.EmployeeSkills,
    filtersObject,
    hideEditColumns: true,
    readOnly: manageUsers !== "M",
    customButtons: eID ? customButtons : !customButtons,
    removeFilters: true,
    onCheckbox: (value, row, selected) => {
      if (value === true && selected.length === 0) {
        setSelectedStatus(row?.employeeSkillGuid?.mnemonic || "");
      }
    },
    removeSelectAll: !selected || selected.length === 0,
    checkboxDisable: (row) => {
      const status = row?.employeeSkillGuid?.mnemonic || "";

      return selectedStatus && status !== selectedStatus;
    },
    deleteText: "skills.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
  };

  function handleAddSkill(array) {
    const skillGUIDs = array
      ? array.map((val, index) => {
          return val.skillGuid;
        })
      : [];

    assignSkilltoEmployee(
      {
        variables: {
          model: {
            skillGuids: skillGUIDs,
            employeeGuid: eID,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("assignSkillToEmployee.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        setDisplayTable(false);
      }
    );
  }

  return (
    <div>
      {displayTable ? (
        <SkillTable
          display={displayTable}
          tenant={isEntelligence ? tID : storeUserTenant}
          initialVal={skills}
          handleClick={handleAddSkill}
          setDisplay={setDisplayTable}
        />
      ) : null}
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier="employeeSkillGuid"
          fromEmployee
          hasCheckbox={eID ? true : false}
        />
      )}
    </div>
  );
};

export default EmployeeSkillsList;
