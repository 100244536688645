import { gql } from "@apollo/client";

export const GET_ALL_TO_REQUESTS = gql`
  query ($model: TORequestsGetListRequestInput!) {
    GetAllTORequestsList(model: $model) {
      toRequestsList {
        toRequests {
          toRequestGuid
          rejectionReason
          requestDate
          dateFrom
          dateTo
          timeFrom
          timeTo
          numberOfDays
          receiver {
            firstName
            lastName
          }
          cancellationDate
          cancelledBy {
            firstName
            lastName
          }
          rejectionDate
          rejectionReason
          rejectedBy {
            firstName
            lastName
          }
          approvalDate
          approvedBy {
            firstName
            lastName
          }
          employeePTO {
            employee {
              firstName
              lastName
            }
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TO_REQUEST_BY_ID = gql`
  query ($id: UUID!) {
    GetTORequestByID(requestGuid: $id) {
      getTORequestByID {
        toRequest {
          toRequestGuid
          rejectionReason
          requestDate
          description
          attachment
          dateFrom
          dateTo
          timeFrom
          timeTo
          numberOfDays
          receiver {
            employeeGuid
            firstName
            lastName
          }
          cancellationDate
          cancelledBy {
            firstName
            lastName
            email
          }
          rejectionDate
          rejectionReason
          rejectedBy {
            firstName
            lastName
            email
          }
          approvalDate
          approvedBy {
            firstName
            lastName
            email
          }
          employeePTO {
            numberOfDays

            daysUsed
            employee {
              employeeGuid
              firstName
              lastName
            }
            pto {
              ptoGuid
              description
              dateUnit {
                description
              }
              paid
            }
          }
          requestStatus {
            description
            mnemonic
          }
        }
        attachmentName
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TO_REQUEST = gql`
  mutation ($model: TORequestCreateRequestInput!) {
    CreateTORequest(model: $model) {
      toRequest {
        toRequestGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TO_REQUEST = gql`
  mutation ($model: TORequestStatusUpdateRequestInput!) {
    UpdateTORequestStatus(model: $model) {
      toRequestStatusUpdate
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CANCEL_TO_REQUEST = gql`
  mutation ($model: TORequestCancelRequestInput!) {
    CancelTORequest(model: $model) {
      toRequestCancellation
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
