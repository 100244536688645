import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UTC2Time } from "../../../UTC2Local";
import No_Image_Found from "../../../assets/images/no-user-image.png";

const data = (t) => {
  return {
  title: <></>,

  headers: [
    t("dashboard.card.employee"),
    { align: "center", label: t("dashboard.card.workShiftClockIn") },
  ],
  render: [
    (row) => {
      return (
        <div className="data-card-employee-name-image">
          <span>
            {row?.employee?.profilePictureURL &&
              ((
                <img
                  src={row.employee.profilePictureURL}
                  alt="Not Found"
                  className="userImage"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = No_Image_Found;
                  }}
                />
              ) || <AccountCircleIcon />)}
          </span>
          <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>
        </div>
      );
    },
    (row) => {
      return (
        <p style={{ textAlign: "center" }}>
          {row?.workShiftRecord?.startHour &&
            UTC2Time(row.workShiftRecord.startHour.toLocaleString(), false)}
        </p>
      );
    },
  ],
}
};

export default data;
