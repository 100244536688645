import React from "react";

//external component
import { Autocomplete, TextField } from "@mui/material";
import AutoCompletePaper from "../Paper/AutoCompletePaper";
import RoundedTextField from "../TextFields/RoundedTextField";
import { useTranslation } from "react-i18next";
import { AddIcon, ShowDetailsIcon } from "../Icon";
import colors from "../../assets/theme/light/colors";
import Tooltip from "../Tooltip";
import { useDispatch } from "react-redux";

const AutoComplete = (props) => {
  const {
    identifier,
    required,
    name,
    roundedTextField,
    label,
    variant,
    removePadding,
    removeLabel,
    formData = {},
    showDisplayView = false,
    disabledViewIcon,
    handleClickView,
    showDisplayAdd = false,
    alignCenter,
    handleClickAdd,
    textFieldLabel,
    disableFormChanged = false,
    removeSX = false,
    autoFocus = false,
    sx,
  } = props;
  const { touched, errors, setTouched, setFieldValue } = formData;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Autocomplete
      {...props}
      isOptionEqualToValue={(option, value) =>
        value === "" || value ? option[identifier] === value[identifier] : ""
      }
      disableClearable={false}
      onChange={(event, value) => {
        props.onChange(event, value);
        if (Object.keys(formData).length > 0 && !disableFormChanged)
          sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
      }}
      PaperComponent={(props) => <AutoCompletePaper {...props} />}
      renderInput={(params) => (
        <div
          style={{
            display: "flex",
            alignItems:
              (errors && errors[name] && touched && touched[name]) ||
              alignCenter
                ? "center"
                : "end",
          }}
        >
          {Object.keys(formData).length > 0 ? (
            <TextField
              {...params}
              autoFocus={autoFocus}
              required={required}
              error={touched[name] && errors[name]}
              helperText={touched[name] && errors[name] && t(errors[name])}
              onBlur={() =>
                setTouched({
                  ...touched,
                  [name]: true,
                })
              }
              label={
                removeLabel ? null : textFieldLabel ? textFieldLabel : label
              }
              variant={variant ? variant : "standard"}
              sx={
                removeSX
                  ? sx
                    ? sx
                    : {}
                  : removePadding === true
                  ? {
                      ".MuiOutlinedInput-root": { padding: "0 !important" },
                      "& .MuiFormLabel-root": {
                        fontSize: "14px !important",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px !important",
                      },
                    }
                  : {
                      "& .MuiFormLabel-root": {
                        fontSize: "14px !important",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px !important",
                      },
                    }
              }
              inputProps={{
                ...params.inputProps,
                style:
                  removePadding === true
                    ? {
                        width: "100%",
                        padding: "0 !important",
                      }
                    : {
                        width: "100%",
                      },
              }}
            />
          ) : roundedTextField ? (
            <RoundedTextField
              autoFocus={autoFocus}
              required={required}
              label={removeLabel ? null : label}
              sx={
                removeSX
                  ? {}
                  : removePadding === true
                  ? {
                      ".MuiOutlinedInput-root": { padding: "0 !important" },
                      "& .MuiFormLabel-root": {
                        fontSize: "14px !important",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px !important",
                      },
                    }
                  : {
                      "& .MuiFormLabel-root": {
                        fontSize: "14px !important",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px !important",
                      },
                    }
              }
              size="small"
              removeMinWidth
              inputProps={{
                ...params.inputProps,
                style:
                  removePadding === true
                    ? {
                        width: "100%",
                        padding: "0 !important",
                      }
                    : {
                        width: "100%",
                      },
              }}
              {...params}
            />
          ) : (
            <TextField
              {...params}
              autoFocus={autoFocus}
              required={required}
              label={
                removeLabel ? null : textFieldLabel ? textFieldLabel : label
              }
              variant={variant ? variant : "standard"}
              sx={
                removeSX
                  ? {}
                  : removePadding === true
                  ? {
                      ".MuiOutlinedInput-root": { padding: "0 !important" },
                      "& .MuiFormLabel-root": {
                        fontSize: "14px !important",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px !important",
                      },
                    }
                  : {
                      "& .MuiFormLabel-root": {
                        fontSize: "14px !important",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "14px !important",
                      },
                    }
              }
              inputProps={{
                ...params.inputProps,
                style:
                  removePadding === true
                    ? {
                        width: "100%",
                        padding: "0 !important",
                      }
                    : {
                        width: "100%",
                      },
              }}
            />
          )}
          {showDisplayAdd && (
            <span style={{ marginBottom: !alignCenter ? "5px" : "initial" }}>
              <Tooltip title={t("tooltip.add.new")}>
                {" "}
                <AddIcon
                  color={colors.color_fields}
                  handleClick={handleClickAdd}
                />
              </Tooltip>
            </span>
          )}
          {showDisplayView && (
            <Tooltip title={t("tooltip.show.detail")}>
              {" "}
              <ShowDetailsIcon
                color={colors.color_fields}
                disabled={disabledViewIcon}
                handleClick={handleClickView}
              />
            </Tooltip>
          )}
        </div>
      )}
    />
  );
};

export default AutoComplete;
