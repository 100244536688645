import { gql } from "@apollo/client";

export const GET_ALL_NEWS=gql`
query($model: NewsListGetRequestInput!){
    GetAllNewsList(model:$model){
      newsListResponse{
        totalNumberOfRecords
        newsList{
          newsGuid
          newsID
          description
          creationDate
          createdBy {
            employeeGuid
            firstName
            lastName
            email
            
          }
          tenant {
            tenantGuid
            name
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const CREATE_NEWS=gql`
  mutation($model: NewsCreateRequestInput!){
    CreateNews(model: $model) {
      news{
        newsGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const UPDATE_NEWS=gql`
  mutation($model: NewsUpdateRequestInput!){
    UpdateNews (model: $model) {
      news{
        newsGuid
        description
      }
      errorCode
      errorMessage
      errorVariables
    }}
  `

  export const GET_NEWS_BY_ID=gql`
  query($newsGuid: UUID!){
    GetNewsByID(newsGuid:$newsGuid ) {
      news{
        newsGuid
        newsID
        creationDate
        description
        createdBy {
          employeeGuid
          firstName
          lastName
          email
        }
        tenant {
          tenantGuid
          name
        }
      }errorCode
      errorMessage
      errorVariables
    }
  }
  `

  export const DELETE_NEWS=gql`
  mutation($model: NewsDeleteRequestInput!){
    DeleteNews (model: $model) {
      newsDeleteResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
  `