import React from "react";

//external component
import { TextField } from "@mui/material";

const RoundedTextField = (props) => {
  return (
    <TextField
      className="textField"
      variant="outlined"
      sx={{
        '& .MuiFormLabel-root': {
          fontSize: '14px',
        },}}
      {...props}
      InputProps={{
        sx: {
          borderRadius: "50px",
          fontFamily: 'poppins',
          height: 'auto',
          minWidth:!props.removeMinWidth && "250px",
        },
        ...props.InputProps,
      }}
    />
  );
};

export default RoundedTextField;
