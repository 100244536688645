import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import No_Image_Found from "../../../../assets/images/no-user-image.png";
import UTC2Local from "../../../../UTC2Local";

const data = {
  title: <></>,

  headers: [
    "Voter",
    { align: "left", label: "Email" },
    { label: "left", label: "Tenant" },

    { align: "left", label: "Voted On" },
  ],
  render: [
    (row) => {
      return (
        <div className="data-card-employee-name-image">
          <span>
            {(row?.employee?.profilePictureURL && (
              <img
                src={row.employee?.profilePictureURL}
                alt="Not Found"
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src=No_Image_Found;
                }}
              />
            )) || <AccountCircleIcon />}
          </span>
          <p>{(row?.employee?.firstName + " " + row?.employee?.lastName) || row?.reference} </p>
        </div>
      );
    },
    (row) => {
      return <p style={{ textAlign: "left" }}>{(row?.employee?.email) || row?.reference} </p>;
    },
    (row) => {
      return <p style={{ textAlign: "left" }}>{(row?.employee?.tenant?.name)} </p>;
    },
    (row) => {
      return <p style={{ textAlign: "left" }}>{UTC2Local(row?.creationDate)}</p>;
    },
  ],
};

export default data;
