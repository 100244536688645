export const getDataTableData = (t) => {
    return {
      columns: [
        {
          id: "e1ebde41-c7e5-41a6-b969-7cdd916cb643",
          field: "name",
          align: "left",
          headerName: t("contractors.list.column.name"),
          label: "contractors.list.column.name",
          flex: 1,
          editable: true,
        },
      ],
    };
  };
  