import React, { useState, useEffect, useCallback } from "react";

//routes
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  DELETE_WORKSHIFTS,
  GET_ALL_WORKSHIFTS,
} from "../../../../graphql/workshifts";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";
const WorkShiftList = ({ myWorkShifts = false }) => {
  const { tName, tID } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const {
    tenant_workshift_form,
    new_tenant_workshift,
    my_workshifts_form,
    my_new_workshift,
  } = urls;

  const { storeUserGuid, userRoles , storeUserTenant} = useAuth();

  const {manageSystemConfigurations} = userRoles

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const filtersObject = [
    {
      type: "text",
      label: "workshift.filters.label.description",
      name: "description",
    },
    {
      type: "number",
      label: "workshift.filters.label.minTotalWeeklyHours",
      name: "minTotalWeeklyHours",
      properties: { min: 0, step: 1 },
    },
    {
      type: "number",
      label: "workshift.filters.label.maxTotalWeeklyHours",
      name: "maxTotalWeeklyHours",
      properties: { min: 0, step: 1 },
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.WorkShifts,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllWorkShifts, loading } = useLazyQueryAuth(
    GET_ALL_WORKSHIFTS,
    "GetAllWorkShifts"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllWorkShifts(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              userID: storeUserGuid,
              filters: filters,
              tenantID: tID && !myWorkShifts? tID: storeUserTenant
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllWorkShifts &&
    data.GetAllWorkShifts.workShiftsList &&
    data.GetAllWorkShifts.workShiftsList.workShifts
      ? data.GetAllWorkShifts.workShiftsList.workShifts
      : [];
  const numberOfRecords =
    data &&
    data.GetAllWorkShifts &&
    data.GetAllWorkShifts.workShiftsList &&
    data.GetAllWorkShifts.workShiftsList.totalNumberOfRecords
      ? data.GetAllWorkShifts.workShiftsList.totalNumberOfRecords
      : 0;

  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_WORKSHIFTS,
    "DeleteWorkShiftList"
  );

  const handleDelete = () => {
    deleteRecord(
      {
        variables: {
          model: {
            workShifts: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("workshifts.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0)
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "workshifts.list.search.label",
    refetch,
    removeFilters:true,
    tableMnemonic: Mnemonic.Tables.WorkShifts,
    filtersObject,
    generateRoute: (row) =>
      myWorkShifts
        ? my_workshifts_form.replace(
            "/:wsName/:wsID",
            `/${encodeURIComponent(row?.description)}/${encodeURIComponent(row?.workShiftGuid)}`
          )
        : tenant_workshift_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:wsName/:wsID",
              `/${encodeURIComponent(row?.description)}/${encodeURIComponent(row?.workShiftGuid)}`
            ),
    newText: "workshifts.list.new.label",
    newLink: myWorkShifts
      ? my_new_workshift
      : new_tenant_workshift.replace(
          "/:tName/:tID",
          `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
        ),
    deleteText: "workshifts.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== "M"
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"workShiftGuid"} hasCheckbox />
      )}
    </div>
  );
};

export default WorkShiftList;
