import { useMutation, useApolloClient } from "@apollo/client";
import useAuth from "./useAuth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { LOGOUT_USER } from "../graphql/user";
import { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from "@mui/material";
import { AlertIcon, CloseIcon } from "../components/Icon";
import { saveToken } from "../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";
import { store } from "../app/store";

const useMutationAuth = (QUERY, objAccess = "", params) => {
  // Snackbar hook
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const [descriptionState, setDescription] = useState(false);

  const closeModalHandler = () => {
    setDescription(false);
  };

  // defines mutation function
  const [call_function, { loading, error }] = useMutation(QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    ...params,
  });

  const [logsOutUser] = useMutation(LOGOUT_USER, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  function userLogout() {
    logsOutUser({
      variables: {
        model: {
          jwtToken: store?.getState()?.token?.token
        },
      },
      onCompleted: () => {
        logout();
      },
    });
  }

  const mfunction = (
    params,
    onCompletedFunc = undefined,
    onErrorFunc = undefined
  ) => {
    // function that handles the return of errors from within
    call_function({
      ...params,
      onCompleted: (data) => {
        if (data[objAccess].errorMessage === "IXIA_ACTIV_UNAUTHORIZED") {
          sessionStorage.removeItem("unsavedChanges");
          userLogout();
          enqueueSnackbar(data[objAccess].errorMessage, {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        } else if (data[objAccess].errorMessage === "IXIA_ACTIV_NEW_TOKEN") {
          sessionStorage.removeItem("unsavedChanges");
          mfunction(
            {
              ...params,
            },
            onCompletedFunc,
            onErrorFunc
          );
          dispatch(saveToken(data[objAccess].errorVariables.trim()));
        } else if (
          data[objAccess].errorMessage === "IXIA_ACTIV_DESCRIPTION_REQUIRED" &&
          data[objAccess].errorCode === "err217"
        ) {
          setDescription(true);
        } else if (
          data[objAccess].errorMessage ===
            "IXIA_ACTIV_ATTENDANCE_RECORD_NOT_SAVED" &&
          data[objAccess].errorCode === "err239"
        ) {
          localStorage.setItem("checkedIn", false);
        } else if (
          data[objAccess].errorCode !== null &&
          data[objAccess].errorCode !== ""
        ) {
          enqueueSnackbar(t(data[objAccess].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else if (onCompletedFunc !== undefined) onCompletedFunc(data);
      },
      onError: (error) => {
        enqueueSnackbar(t("systemError"), {
          variant: "error",
          autoHideDuration: 5000
        });
        if (onErrorFunc !== undefined) onErrorFunc(error);
      },
    });
  };

  const cfunction = (
    params,
    onCompletedFunc = undefined,
    onErrorFunc = undefined
  ) => {
    // function that allows the function call to handle the error (in case of forms...)
    call_function({
      ...params,
      onCompleted: (data) => {
        if (data[objAccess].errorMessage === "IXIA_ACTIV_UNAUTHORIZED") {
          sessionStorage.removeItem("unsavedChanges");
          logout();
        } else if (data[objAccess].errorMessage === "IXIA_ACTIV_NEW_TOKEN") {
          sessionStorage.removeItem("unsavedChanges");
          cfunction(
            {
              ...params,
              context: {
                headers: {
                  authorization: `${
                    data[objAccess].errorVariables.trim()
                      ? "Bearer " + data[objAccess].errorVariables.trim()
                      : ""
                  }`,
                },
              },
            },
            onCompletedFunc,
            onErrorFunc
          );
          dispatch(saveToken(data[objAccess].errorVariables.trim()));
        } else if (onCompletedFunc !== undefined) onCompletedFunc(data);
      },
      onError: (error) => {
        enqueueSnackbar(t("systemError"), {
          variant: "error",
          autoHideDuration: 5000
        });
        if (onErrorFunc !== undefined) onErrorFunc(error);
      },
    });
  };

  return {
    mfunction,
    cfunction,
    loading,
    error,
    descriptionState,
    closeModalHandler,
    DialogComponent: (
      <>
        {descriptionState && (
          <Dialog
            open={descriptionState}
            onClose={closeModalHandler}
            sx={{
              "& .MuiPaper-root": {
                overflow: "hidden",
                paddingBottom: "5px",
                fontWeight: "450px",
                maxWidth: "400px",
              },
            }}
          >
            <Paper style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "1rem",
                }}
              >
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={closeModalHandler}
                  aria-label="close"
                >
                  <CloseIcon color={"#000000"} width={'20'} stroke={'1'} />
                </IconButton>
              </div>
              <DialogContent id="description-dialog" sx={{ paddingTop: "0" }}>
                <div style={{ marginBottom: "4px" }}>
                  <AlertIcon color="#ff2825" width="30" height="30"/>
                </div>
                {t("useMutation.descriptionRequired")}
              </DialogContent>
            </Paper>
          </Dialog>
        )}
      </>
    ),
  };
};

export default useMutationAuth;

useMutationAuth.defaultProps = {
  QUERY: null,
  objAccess: "",
  params: {},
  statusFunctions: {},
  descriptionMutation: false,
};
