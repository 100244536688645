import React, { useState, useCallback, useEffect } from "react";

//translation
import { useTranslation } from "react-i18next";

//component
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useAuth from "../../../../hooks/useAuth";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";
import {
  GET_ALL_SYSTEM_VARIABLES,
  EDIT_SYSTEM_VARIABLE,
} from "../../../../graphql/systemVariables";
import GridTable from "../../../../components/Tables/GridTable";
import { DataTableData } from "./_table";

function SystemVariablesList() {
  const DEFAULT_SYST = {
    systemVariablesGuid: "",
    value: "",
    additionalInfo: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const { userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const [rows, setRows] = useState([]);
  const [newObj, setNewObj] = useState({
    systemVariablesGuid: "",
    value: "",
    additionalInfo: "",
  });
  const requiredFields=["systemVariablesGuid", "value"];

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);

  const { mfunction: GetSystemVariablesList, loading: getColumnsLoading } =
    useLazyQueryAuth(GET_ALL_SYSTEM_VARIABLES, "GetSystemVariablesList");

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    GetSystemVariablesList(
      {
        variables: {
          model: {
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
          },
        },
      },
      (response) => {
        setData(response);
        const systemVariables =
          response?.GetSystemVariablesList?.systemVariables?.systemVariables ||
          [];
        setRows(Array.isArray(systemVariables) ? systemVariables : []);
      }
    );
  }, []);

  useEffect(() => {
    fetchTableData(page, rowsPerPage, search);
  }, [fetchTableData, rows.length]);

  const numberRecords =
    data &&
    data.GetSystemVariablesList &&
    data.GetSystemVariablesList.systemVariables?.totalNumberOfRecords
      ? data.GetSystemVariablesList.systemVariables?.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (setCreate.current === false) fetchTableData(page, rowsPerPage, search);
  }, [fetchTableData, search]);

  const { cfunction: UpdateSystemVariable } = useMutationAuth(
    EDIT_SYSTEM_VARIABLE,
    "UpdateSystemVariable"
  );

  function EditSystemVariable(updatedRow) {
    UpdateSystemVariable(
      {
        variables: {
          model: {
            systemVariableGuid: updatedRow?.systemVariablesGuid,
            value: updatedRow?.value,
            additionalInfo: updatedRow?.additionalInfo,
          },
        },
      },
      (response) => {
        if (
          response["UpdateSystemVariable"].errorCode !== null &&
          response["UpdateSystemVariable"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateSystemVariable"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_SYST);
        } else {
          enqueueSnackbar(t("departmentForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_SYST);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_SYST);
      }
    );
  }

  const pagination = {
    rows,
    columns: DataTableData.columns,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    selected,
    hideAddButton: true,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    removeFilters:true,
    fetchTableData,
    loading: getColumnsLoading,
    hideRefreshButton: true,
    hideSearchBar: true,
    removeSelectAll: true,
    removeRefresh: true,
    readOnly: manageSystemConfigurations !== "M",
    fetchedBool: true,
    removeDeleteButton: true,
    hidePagination: true,
    requiredFields,
    enqueueSnackbar,
  };
  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        DataTableData.columns &&
        DataTableData?.columns.length > 0 && (
          <div className="systemVariables">
            <GridTable
              handleUpdate={EditSystemVariable}
              {...pagination}
              identifier={"systemVariablesGuid"}
            />
          </div>
        )
      )}
    </div>
  );
}

export default SystemVariablesList;
