import colorsLight from "../../../assets/theme/light/colors";

export function item(theme, ownerState) {
  const { transitions } = theme;
  const { active, open, collapse } = ownerState;
  const { pure_white, dark_logo_blue, light_logo_blue, menu_hover_barely_black, grey_background } = colorsLight;

  return {
    padding: "0px 0px 0px 0px",
    mt: 0.375,
    mb: 0,
    width: "auto",
    borderRadius: "0px",
    height: collapse ? "30px" : "40px",
    cursor: "pointer",
    fontWeight: open ? "500" : "",
    color: () => {
      if (collapse && active) return light_logo_blue;
      if (open) return pure_white;
      if (active) return dark_logo_blue;
      return pure_white;
    },
    fontFamily: 'poppins',
    backgroundColor: () => {
      let backgroundValue = null;
      if (!collapse && active) backgroundValue = grey_background;
      if (!collapse && open) backgroundValue = menu_hover_barely_black;
      return backgroundValue;
    },
    transition: transitions.create("background-color", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.sharp,
    }),
    "&:hover, &:focus": {
      backgroundColor: (!active && !open && !collapse) ? menu_hover_barely_black : "",
      color: !active && !open && collapse ? light_logo_blue : ""
    },
  };
}

export function itemContent(theme, ownerState) {
  const { transitions } = theme;
  const { active } = ownerState;
  const { menu_hover_pure_black } = colorsLight;

  return {
    display: "flex",
    alignItems: "center",
    fontFamily: 'poppins',
    width: "100%",
    borderRadius: "2px",
    height: "40px",
    userSelect: "none",
    padding: "2px 4px",
    "& span": {
      fontWeight: active ? 400 : 300,
      fontSize: "1rem",
      opacity: 1,
      transition: transitions.create(["opacity", "color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    "&:hover, &:focus": {
      backgroundColor: menu_hover_pure_black,
    },
  };
}
