import React, { useState, useEffect, useCallback } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";

//components
import DataTable from "../../../../components/Tables/DataTable";
import EditRecord from "../AttendanceSheets-form/components/EditRecord";
import componentDisplay from "../../../../componentDisplay";
import AttendanceDialog from "../AttendanceSheets-form/components/AttendanceDialog";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import AutoComplete from "../../../../components/AutoComplete";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

import {
  GET_ATTENDANCE_SHEET_LIST,
  GET_ATTENDANCE_SHEET_DAY_LOGS,
} from "../../../../graphql/attendanceSheet";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../graphql/location";
import { GET_ALL_WORK_TYPES } from "../../../../graphql/workType";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

import Mnemonic from "../../../../Mnemonics.json";
import { useTranslation } from "react-i18next";
import { Parse } from "../../../../JSON.editor";
import UTC2Local, { UTC2Time } from "../../../../UTC2Local";
import { RefreshIcon } from "../../../../components/Icon";
import { getWorkingHours } from "../../../../utils/getWorkingHours";

import { v4 as uuidv4 } from "uuid";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

function AttendanceSheetsListPending() {
  const { storeUserGuid, storeUserTenant, isManager, userRoles } = useAuth();
  const { manageAttendance, isAdmin } = userRoles;

  const { t } = useTranslation();

  const [attendanceSheetObj, setAttendanceSheetObj] = useState(null);
  const [dayLogGuid, setDayLogGuid] = useState(null);
  const attendanceSheetGuid = React.useRef(null);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [numberRecords, setNumberRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleSetDayLogGuid = (id) => {
    setEditMode(true);
    setOpenModal(true);
    setDayLogGuid(id);
  };

  const {
    loading: getColumnsLoading,
    refetch: refetchColumns,
    tokenChange: columnsWait,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.AttendanceSheetApproved,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        const actions = ({ compProps }) => {
          let props = compProps;

          return (
            <EditRecord
              {...props}
              dayLogGuid={props.dayLogGuid}
              handleSetDayLogGuid={handleSetDayLogGuid}
              disabled={(row) => {
                return (
                  row?.workType?.mnemonic === Mnemonic.WorkTypes.timeoff ||
                  (!isManager && manageAttendance !== "M" && !isAdmin)
                );
              }}
            />
          );
        };

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, getWorkingHours)
          );
          // if (parsed.columns)
          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }
        }
      },
    }
  );

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        workshiftGuid: attendanceSheetObj?.workShift?.workShiftGuid,
        isManagerUsersList: isManager === true ? true : null,
        userID: storeUserGuid,
        attendanceDisabled: true,
      },
    },
    skip: attendanceSheetObj == null || columnsWait,
    isWait: true,
  });

  const {
    data: workTypesList,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes", {
    variables: {
      showAll: true,
    },
    skip: employeeWait || columnsWait,
    isWait: true,
  });

  const {
    data: locationsList,
    loading: locationLoading,
    tokenChange: locationWait,
  } = useQueryAuth(GET_ALL_LOCATIONS_DROPDOWN, "GetAllLocations", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
      },
    },
    skip: employeeWait || workTypesWait || columnsWait,
    isWait: true,
  });

  const location_list =
    locationsList &&
    locationsList.GetAllLocations &&
    locationsList.GetAllLocations.locationsList &&
    locationsList.GetAllLocations.locationsList.locations
      ? locationsList.GetAllLocations.locationsList.locations
      : [];

  const workType_list =
    workTypesList &&
    workTypesList.GetAllWorkTypes &&
    workTypesList.GetAllWorkTypes.workTypes
      ? workTypesList.GetAllWorkTypes.workTypes
      : [];

  const employee_List =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const filtersObject = [
    {
      label: "attendanceSheet.list.filters.employees",
      name: "employee",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employee_List,
      access: "employeeGuid",
      loading: employeeLoading,
    },
    {
      label: "attendanceSheet.list.filters.workType",
      name: "workType",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: workType_list,
      access: "workTypeGuid",
      loading: workTypeLoading,
    },
    {
      label: "attendanceSheet.list.filters.locations",
      name: "location",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: location_list,
      access: "locationGuid",
      loading: locationLoading,
    },
    {
      type: "date",
      label: "attendanceSheet.list.filters.date",
      name: "date",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.isLate",
      name: "isLate",
      access: "isLate",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.isEarly",
      name: "isEarly",
      access: "isEarly",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.isAutomaticRecord",
      name: "isAutomaticRecord",
      access: "isAutomaticRecord",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.fromWeb",
      name: "fromWeb",
      access: "fromWeb",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.fromKiosk",
      name: "fromKiosk",
      access: "fromKiosk",
    },
  ];

  const {
    data: attendanceSheetListData,
    loading: attendanceSheetListLoading,
    tokenChange: attendanceSheetListWait,
  } = useQueryAuth(GET_ATTENDANCE_SHEET_LIST, "GetAttendanceSheetList", {
    variables: {
      model: {
        tenantGuid: storeUserTenant,
        pending: false,
      },
    },
  });
  const attendanceSheets_list =
    attendanceSheetListData?.GetAttendanceSheetList?.attendanceSheetsList
      ?.attendanceSheets || [];

  const {
    mfunction: getAttendanceSheet,
    loading,
    refetch: refetchAttendanceSheet,
  } = useLazyQueryAuth(
    GET_ATTENDANCE_SHEET_DAY_LOGS,
    "GetAttendanceSheetDayLogs"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAttendanceSheet(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              filters: filters,
              orderColumn: orderBy,
              orderDirection: order,
              userID: storeUserGuid,
              attendanceSheetGuid: attendanceSheetGuid.current,
            },
          },
          skip: attendanceSheetGuid.current == null,
          isWait: true,
        },
        (response) => {
          setRows(
            response?.GetAttendanceSheetDayLogs?.attendanceSheetView?.dayLogs
          );
          setNumberRecords(
            response?.GetAttendanceSheetDayLogs?.attendanceSheetView
              ?.totalNumberOfRecords
          );
        }
      );
    },
    []
  );

  useEffect(() => {
    if (
      attendanceSheetGuid.current != null &&
      !attendanceSheetListWait &&
      !employeeWait &&
      !workTypesWait &&
      !locationWait
    )
      fetchTableData(page, rowsPerPage, order, orderBy, "", defaultFilters);
  }, [
    attendanceSheetGuid.current,
    attendanceSheetListWait,
    employeeWait,
    workTypesWait,
    locationWait,
  ]);

  const handleRefresh = () => {
    refetchAttendanceSheet();
  };

  const customButtons = [
    {
      label: "attendanceSheet.btn.refresh.label",
      handleClick: () => handleRefresh(),
      condition: true,
      icon: true,
      iconButton: (
        <RefreshIcon color="#000000" />
      ),
    },
  ];

  const pagination = {
    rows: rows === null ? [] : rows,
    columns,
    numberRecords,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    search,
    setSearch,
    defaultFilters,
    setDefaultFilters,
    customButtons,
    hideSearchBar: true,
    hideRefreshButton: true,
    hideAddButton: true,
    fetchTableData,
    loading: loading,
    refetch: refetchColumns,
    tableMnemonic: Mnemonic.Tables["AttendanceSheetApproved"],
    // hideEditColumns: true,
    removeRefresh: true,
    filtersObject,
  };

  return (
    <>
      <AttendanceDialog
        open={openModal}
        setOpen={setOpenModal}
        employeeLoading={employeeLoading}
        locationLoading={locationLoading}
        workTypeLoading={workTypeLoading}
        employee_list={employee_List}
        location_list={location_list}
        workType_list={workType_list}
        attendanceSheetGuid={attendanceSheetGuid.current}
        dayLogGuid={dayLogGuid}
        editMode={editMode}
        refetchData={refetchAttendanceSheet}
        handleEditMode={(v) => setEditMode(v)}
        status={attendanceSheetObj?.status}
      />
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ paddingTop: "10px", display: "flex", alignItems: "end" }}
      >
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <AutoComplete
            options={attendanceSheets_list}
            getOptionLabel={(option) =>
              (option
                ? option.workShift.description +
                  "  ( " +
                  UTC2Local(option.startDate, true) +
                  "   —   " +
                  UTC2Local(option.endDate, true)
                : "") + "  ) "
            }
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.workShift.description +
                  "  ( " +
                  UTC2Local(option.startDate, true) +
                  "   —   " +
                  UTC2Local(option.endDate, true)}
              </li>
            )}
            identifier={"attendanceSheetGuid"}
            value={attendanceSheetObj}
            onChange={(e, value) => {
              setAttendanceSheetObj(value);
              attendanceSheetGuid.current = value?.attendanceSheetGuid;
              setPage(0);
              if (value === null) {
                setRows([]);
              }
            }}
            label={t("attendanceSheet.autoComplete.label")}
            loading={attendanceSheetListLoading}
          />
        </Grid>
      </Grid>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <Box
          sx={{
            marginTop: {
              xs: "0px !important",
              sm: "0px !important",
              md: "-45px !important",
              lg: "-45px !important",
              xl: "-45px !important",
            },
          }}
        >
          <DataTable
            {...pagination}
            identifier="dayLogGuid"
            heightClassName="table-sheet-height"
            isFixed
            checkForCheckbox={(row) => {
              return (
                (((row?.employee?.directManagerGuid === storeUserGuid ||
                  row?.employee?.employeeGuid === storeUserGuid) &&
                  isManager &&
                  manageAttendance === "M") ||
                  isAdmin)
              );
            }}
          />
        </Box>
      )}
    </>
  );
}

export default AttendanceSheetsListPending;
