import React from "react";
import { useTranslation } from "react-i18next";

import colorsLight from "../../assets/theme/light/colors";
import Tooltip from "../Tooltip";

const RequestStatus = (props) => {
  const { t } = useTranslation();
  const { approvedBy, cancelledBy, rejectedBy } = props;
  const { requestStatusColors } = colorsLight;

  const title = rejectedBy !== null ? `${t("request.status.rejectedBy")}: ${rejectedBy?.firstName} ${rejectedBy?.lastName}` :
  cancelledBy !== null ? `${t("request.status.cancelledBy")}: ${cancelledBy?.firstName} ${cancelledBy?.lastName}` :
  approvedBy !== null ? `${t("request.status.approvedBy")}: ${approvedBy?.firstName} ${approvedBy?.lastName}` :
  "";
  
  return <Tooltip title={title}>
    {
        rejectedBy !== null ? (
            <span
              style={{
                padding: "5px 20px",
                borderRadius: "5px",
                fontFamily: "poppins  !important",
                margin: "auto",
                ...requestStatusColors.rejected,
              }}
            >
              {t("request.status.rejected")}
            </span>
          ) : cancelledBy !== null ? (
            <span
              style={{
                padding: "5px 20px",
                borderRadius: "5px",
                fontFamily: "poppins  !important",
                margin: "auto",
                ...requestStatusColors.cancelled,
              }}
            >
              {t("request.status.cancelled")}
            </span>
          ) : approvedBy !== null ? (
            <span
              style={{
                padding: "5px 20px",
                borderRadius: "5px",
                fontFamily: "poppins  !important",
                margin: "auto",
                ...requestStatusColors.approved,
              }}
            >
              {t("request.status.approved")}
            </span>
          ) : (
            <span
              style={{
                padding: "5px 20px",
                borderRadius: "5px",
                fontFamily: "poppins  !important",
                margin: "auto",
                ...requestStatusColors.sentForApproval,
              }}
            >
              {t("request.status.sentForApproval")}
            </span>
          )
    }
  </Tooltip>
};

export default RequestStatus;
