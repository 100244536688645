import UTC2Local from "../../../UTC2Local";

export const getTable = () => {
    return {
        columns: [
            {
                id: "postingDate",
                label: "appliedInvoices.list.column.postingDate",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    const date = row?.creditNote == null ? row?.timeEntry?.startTime : row?.creditNote?.postingDate;
                    return UTC2Local(date, true); 
                },
            },
            {
                id: "Type",
                label: "appliedInvoices.list.column.Type",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    const type = row?.creditNote !== null ? "Credit Note" : "Time Entry";
                    return type ? type : "";  
                },
            },
            {
                id: "DocumentNumber",
                label: "appliedInvoices.list.column.DocumentNumber",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    return row?.projectInvoice?.documentNo || "" ;
                },
            },
            {
                id: "Task",
                label: "appliedInvoices.list.column.Task",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    return row?.projectInvoice?.task?.title || "" ;
                },
            },
            {
                id: "Description",
                label: "appliedInvoices.list.column.Description",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    return  row?.timeEntry?.description || "" ;
                },

            },
            {
                id: "ResourceName",
                label: "appliedInvoices.list.column.ResourceName",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    const firstName = row?.timeEntry?.employee?.firstName;
                    const lastName = row?.timeEntry?.employee?.lastName;
                    return firstName && lastName ? `${firstName} ${lastName}` : "";
                },
            },
            {
                id: "QuantityApplied",
                label: "appliedInvoices.list.column.QuantityApplied",
                align: "left",
                visible: true,
                renderCell: (row) => {
                    return row?.quantity || "" ;
                },
            },
        ],
        defaultSort: "postingDate",
        defaultSortDirection: "asc",
    };
};
