import React, { useState, useEffect } from "react";

//components
import { Field } from "formik";
import { Grid, Box, InputAdornment } from "@mui/material";
import { FormControlLabel, Switch, IconButton } from "@mui/material";
import Tooltip from "../../../../../components/Tooltip";
import { Title, Labeltitle } from "../../../../../components/Titles/FormTitles";
import TextField from "../../../../../components/TextFields/TextField";
import RoundedTextField from "../../../../../components/TextFields/RoundedTextField";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import colors from "../../../../../assets/theme/light/colors"
//translation
import { useTranslation } from "react-i18next";
import "../../../../../styles.css"
import { useSnackbar } from "notistack";
import { HourGlassIcon } from "../../../../../components/Icon";

const Main = ({ formData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { light_logo_blue } = colors;
  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    readOnly,
    notEditable,
  } = formData;

  const {
    description,
    totalWeeklyHours,
    daysOfWeek: {
      startHour,
      endHour,
      isWorkDay,
      breakTime,
      StartOfWeek,
    },
  } = formField;

  const {
    description: descriptionV,
    totalWeeklyHours: totalWeeklyHoursV,
    daysOfWeek: daysOfWeekV,
  } = values;

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    let h = Math.floor(parseInt(totalWeeklyHoursV));
    let m = Math.round((parseFloat(totalWeeklyHoursV) - h) * 60);

    if (isNaN(h) || isNaN(m)) return;

    if (m === 60) {
      h += 1;
      m = 0;
    }

    setHours(h);
    setMinutes(m);
  }, [totalWeeklyHoursV]);

  const handleIsWorkDay = (index, value, event) => {
    if (notEditable === true) {
      event.preventDefault();
      return;
    }
    let tmp = structuredClone(daysOfWeekV);

    tmp[index].isWorkDay = !value;
    tmp[index].startOfWeek = false;

    if (value === true) {
      if (
        tmp[index].startHour !== null &&
        tmp[index].endHour !== null &&
        tmp[index].startHour !== "" &&
        tmp[index].endHour !== ""
      ) {
        let endDate = new Date(tmp[index].endHour);
        let startDate = new Date(tmp[index].startHour);

        let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

        let breakV = 0;

        if (tmp[index].breakTime) {
          breakV = parseFloat(tmp[index].breakTime) * 60 * 60 * 1000;
        }

        let diff = endDate - startDate - breakV;

        currentTime = currentTime - diff;

        currentTime = parseFloat(currentTime / (1000 * 60 * 60));
        if (!isNaN(currentTime))
          setFieldValue(totalWeeklyHours.name, currentTime);
      }

      tmp[index].startHour = null;
      tmp[index].endHour = null;
    }

    setFieldValue("daysOfWeek", tmp);
  };

  const handleStartHourChange = (index, value) => {
    if (notEditable === true) return;
    let tmp = structuredClone(daysOfWeekV);

    let nowDate = new Date().toISOString();

    nowDate =
      nowDate.split("T")[0] + "T" + nowDate.split("T")[1].replace(/[0-9]/g, 0);

    if (!value) {
      let endDate = new Date(tmp[index].endHour);
      let startDate = new Date(tmp[index].startHour);

      let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

      let breakV = 0;

      if (tmp[index].breakTime) {
        breakV = parseFloat(tmp[index].breakTime) * 60 * 60 * 1000;
      }

      let oldDiff = endDate - startDate - breakV;

      currentTime = currentTime - oldDiff;

      currentTime = parseFloat(currentTime / (1000 * 60 * 60));
      if (!isNaN(currentTime))
        setFieldValue(totalWeeklyHours.name, currentTime);
    } else if (tmp[index].endHour && value + "" !== "Invalid Date") {
      let date = new Date(value);
      let endDate = new Date(tmp[index].endHour);
      let startDate = new Date(tmp[index].startHour);

      let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

      let breakV = 0;

      if (tmp[index].breakTime) {
        breakV = parseFloat(tmp[index].breakTime) * 60 * 60 * 1000;
      }

      let oldDiff = endDate - startDate - breakV;
      let newDiff = endDate - date - breakV;

      if (oldDiff && !isNaN(oldDiff)) currentTime -= oldDiff;

      if (newDiff && !isNaN(newDiff)) currentTime += newDiff;

      currentTime = parseFloat(currentTime / (1000 * 60 * 60));
      if (!isNaN(currentTime))
        setFieldValue(totalWeeklyHours.name, currentTime);
    }

    tmp[index].startHour = value;
    tmp[index].changeTime = nowDate;

    setFieldValue("daysOfWeek", tmp);
  };

  const handleEndHourChange = (index, value) => {
    if (notEditable === true) return;
    let tmp = structuredClone(daysOfWeekV);
    // tmp[index].endHour = value;

    let nowDate = new Date().toISOString();

    nowDate =
      nowDate.split("T")[0] + "T" + nowDate.split("T")[1].replace(/[0-9]/g, 0);

    if (!value) {
      let endDate = new Date(tmp[index].endHour);
      let startDate = new Date(tmp[index].startHour);

      let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

      let breakV = 0;

      if (tmp[index].breakTime) {
        breakV = parseFloat(tmp[index].breakTime) * 60 * 60 * 1000;
      }

      let oldDiff = endDate - startDate - breakV;

      currentTime = currentTime - oldDiff;

      currentTime = parseFloat(currentTime / (1000 * 60 * 60));
      if (!isNaN(currentTime))
        setFieldValue(totalWeeklyHours.name, currentTime);
    } else if (tmp[index].startHour && value + "" !== "Invalid Date") {
      let date = new Date(value);
      let endDate = new Date(tmp[index].endHour);
      let startDate = new Date(tmp[index].startHour);

      let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

      let breakV = 0;

      if (tmp[index].breakTime) {
        breakV = parseFloat(tmp[index].breakTime) * 60 * 60 * 1000;
      }

      let oldDiff = endDate - startDate - breakV;
      let newDiff = date - startDate - breakV;

      if (oldDiff && !isNaN(oldDiff) && oldDiff > 0) currentTime -= oldDiff;

      if (newDiff && !isNaN(newDiff)) currentTime += newDiff;

      currentTime = parseFloat(currentTime / (1000 * 60 * 60));
      if (!isNaN(currentTime))
        setFieldValue(totalWeeklyHours.name, currentTime);
    }

    tmp[index].endHour = value;
    tmp[index].changeTime = nowDate;

    setFieldValue("daysOfWeek", tmp);
  };

  const handleBreakTimeChange = (index, value) => {
    if (notEditable === true) return;
    let tmp = structuredClone(daysOfWeekV);

    if (tmp[index].breakTime) {
      let old = parseFloat(tmp[index].breakTime) * 60 * 60 * 1000;
      let newV = parseFloat(value) * 60 * 60 * 1000;

      let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

      currentTime = currentTime + old - newV; //update break time

      currentTime = parseFloat(currentTime / (1000 * 60 * 60));
      if (!isNaN(currentTime))
        setFieldValue(totalWeeklyHours.name, currentTime);
    } else {
      let newV = parseFloat(value) * 60 * 60 * 1000;

      let currentTime = parseFloat(totalWeeklyHoursV) * 60 * 60 * 1000; //convert hours to milliseconds

      currentTime = currentTime - newV;

      currentTime = parseFloat(currentTime / (1000 * 60 * 60));
      if (!isNaN(currentTime))
        setFieldValue(totalWeeklyHours.name, currentTime);
    }

    tmp[index].breakTime = value;

    setFieldValue("daysOfWeek", tmp);
  };

  const handleStartOfWeekChange = (index, value, event) => {
    if (notEditable === true) {
      event.preventDefault();
      return;
    }
    let tmp = structuredClone(daysOfWeekV);

    if (value === true) {
      const findIndex = daysOfWeekV.findIndex((x) => x.startOfWeek === true);
      if (findIndex !== -1) tmp[findIndex].startOfWeek = false;
    }
    tmp[index].startOfWeek = value;

    setFieldValue("daysOfWeek", tmp);
  };

  const copyFromFirstDay = () => {
    if (notEditable === true) return;
    setFieldValue(totalWeeklyHours.name, 0);
    const index = daysOfWeekV.findIndex((x) => x.startOfWeek === true);

    if (index === -1) return;

    let newArray = structuredClone(daysOfWeekV);

    const initialValue = newArray[index];

    let time2Add = 0; //convert hours to milliseconds

    for (let i = 0; i < newArray.length; i++) {
      //copy from first day to others
      // if(newArray[i].startOfWeek === true)
      //   continue;
      if (newArray[i].isWorkDay === true) {
        newArray[i].startHour = initialValue.startHour;
        newArray[i].endHour = initialValue.endHour;
        newArray[i].breakTime = initialValue.breakTime;

        if (initialValue.endHour && initialValue.startHour) {
          let endDate = new Date(initialValue.endHour);
          let startDate = new Date(initialValue.startHour);

          let currentTime = time2Add;

          let breakV = 0;

          if (initialValue.breakTime) {
            breakV = parseFloat(initialValue.breakTime) * 60 * 60 * 1000;
          }

          let newDiff = endDate - startDate - breakV; //we find time difference and exclude break time

          if (newDiff && !isNaN(newDiff)) currentTime += newDiff; //if difference is a valid value we add it to the weekly hours

          time2Add = currentTime;
        }
      }
    }
    if (!isNaN(time2Add))
      setFieldValue(
        totalWeeklyHours.name,
        parseFloat(time2Add / (1000 * 60 * 60))
      );
    setFieldValue("daysOfWeek", newArray);
  };

  const handleErrorEdit = () => {
    if (notEditable === true && !readOnly)
      enqueueSnackbar(t("workShift.edit.errorMessage"), {
        variant: "error",
        autoHideDuration: 5000
      });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("workShiftForm.workShiftInformation")}
          </Title>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={7} xl={7}>
              <TextField
                autoFocus
                fullWidth
                value={descriptionV}
                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                label={t(description.label)}
                name={description.name}
                formData={formData}
                required
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "12px",
                }}
              >
                <h4>
                  {t("workShift.totalWeeklyHours", {
                    hours: hours,
                    minutes: minutes,
                  })}
                </h4>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ overflowX: "auto" }}
        >
          <div
            style={{
              minWidth: "600px",
              gap: "10px",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th align="left">{t(isWorkDay.label)}</th>
                  <th align="center" style={{ paddingRight: "15px" }}>
                    {t(startHour.label)}
                  </th>
                  <th align="center" style={{ paddingRight: "15px" }}>
                    {t(endHour.label)}
                  </th>
                  <th align="center" style={{ paddingRight: "15px" }}>
                    {t(breakTime.label)}
                  </th>
                  <th align="center" style={{ paddingRight: "15px" }}>
                    {t(StartOfWeek.label)}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {daysOfWeekV &&
                  daysOfWeekV.map((weekday, index) => {
                    return (
                      <tr key={index}>
                        <td align="left">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "15px",
                            }}
                            onClick={handleErrorEdit}
                          >
                            <FormControlLabel
                              sx={{
                                ".MuiInputBase-input": {
                                  fontSize: "14px !important",
                                },
                              }}
                              control={
                                <Switch
                                  size="small"
                                  checked={weekday.isWorkDay}
                                  disabled={readOnly}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: light_logo_blue,
                                    },
                                  }}
                                />
                              }
                              onChange={(event) =>
                                handleIsWorkDay(index, weekday.isWorkDay, event)
                              }
                            />

                            <Labeltitle>{weekday.dayName}</Labeltitle>
                          </div>
                        </td>
                        <td align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "15px",
                            }}
                            onClick={handleErrorEdit}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                sx={{
                                  ".MuiInputBase-input": {
                                    fontSize: "14px !important",
                                  },
                                }}
                                value={weekday.startHour}
                                disabled={
                                  weekday.isWorkDay === false ||
                                  readOnly ||
                                  notEditable
                                }
                                className="date_picker_styles"
                                ampm={false}
                                slotProps={{
                                  desktopPaper: {
                                    sx: { boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,}
                                  },
                                  textField: {
                                    required: weekday.isWorkDay,
                                    sx:
                                      notEditable === true
                                        ? {
                                          maxWidth: "150px",
                                          ".Mui-disabled": {
                                            color: "currentColor !important",
                                            WebkitTextFillColor:
                                              "currentColor !important",
                                          },
                                        }
                                        : { maxWidth: "150px" },
                                    size: "small",
                                    onBlur: () => {
                                      if (isNaN(weekday.startHour))
                                        handleStartHourChange(index, null);
                                    },
                                  },
                                }}
                                onChange={(newValue) => {
                                  handleStartHourChange(index, newValue);
                                }}
                              />
                            </LocalizationProvider>
                          </div>
                        </td>
                        <td align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "15px",
                              fontSize: "14px",
                            }}
                            onClick={handleErrorEdit}
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                value={weekday.endHour}
                                disabled={
                                  readOnly ||
                                  weekday.isWorkDay === false ||
                                  weekday.startHour === null ||
                                  weekday.startHour === "" ||
                                  notEditable
                                }
                                className="date_picker_styles"
                                ampm={false}
                                minTime={weekday.startHour}
                                slotProps={{
                                  desktopPaper: {
                                    sx: { boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,}
                                  },
                                  textField: {
                                    required: weekday.isWorkDay,
                                    size: "small",
                                    sx:
                                      notEditable === true
                                        ? {
                                          maxWidth: "150px",
                                          ".Mui-disabled": {
                                            color: "currentColor !important",
                                            WebkitTextFillColor:
                                              "currentColor !important",
                                          },
                                        }
                                        : { maxWidth: "150px" },
                                    onBlur: () => {
                                      if (isNaN(weekday.endHour))
                                        handleEndHourChange(index, null);
                                    },
                                  },
                                }}
                                onChange={(newValue) =>
                                  handleEndHourChange(index, newValue)
                                }
                              />
                            </LocalizationProvider>
                          </div>
                        </td>
                        <td align="center">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingRight: "15px",
                            }}
                            onClick={handleErrorEdit}
                          >
                            <RoundedTextField
                              removeMinWidth
                              size="small"
                              disabled={!weekday.isWorkDay || notEditable}
                              value={weekday.breakTime}
                              onError={(e) => {
                                if (e === "invalidDate")
                                  handleBreakTimeChange(index, null);
                              }}
                              onChange={(e) => {
                                handleBreakTimeChange(index, e.target.value);
                              }}
                              sx={
                                notEditable === true
                                  ? {
                                    maxWidth: "100px",
                                    ".Mui-disabled": {
                                      color: "currentColor !important",
                                      WebkitTextFillColor:
                                        "currentColor !important",
                                    },
                                  }
                                  : { maxWidth: "100px" }
                              }
                              inputProps={{
                                style: {
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment sx={{ mr: 1 }} position="end">
                                    <HourGlassIcon color="#707070" />
                                  </InputAdornment>
                                ),
                                style: {
                                  backgroundColor: "white",
                                  paddingRight: "0",
                                  opacity: 1,
                                },
                              }}
                            />
                          </div>
                        </td>
                        <td align="center">
                          <div onClick={handleErrorEdit}>
                            <Field
                              as={FormControlLabel}
                              type={StartOfWeek.type}
                              name={StartOfWeek.name}
                              control={
                                <Switch
                                  size="small"
                                  checked={weekday.startOfWeek}
                                  disabled={readOnly || !weekday.isWorkDay}
                                  onBlur={() =>
                                    setTouched({
                                      ...touched,
                                      [StartOfWeek.name]: true,
                                    })
                                  }
                                  onChange={(e) => {
                                    handleStartOfWeekChange(
                                      index,
                                      e.target.checked,
                                      e
                                    );
                                  }}
                                  error={
                                    touched[StartOfWeek.name] &&
                                    errors[StartOfWeek.name]
                                  }
                                />
                              }
                            />
                          </div>
                        </td>
                        <td align="center">
                          {weekday.startOfWeek && (
                            <Tooltip title={t("workShift.copyFromFirstDay")}>
                              <IconButton
                                onClick={copyFromFirstDay}
                                disabled={notEditable === true}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon icon-tabler icon-tabler-copy"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1"
                                  stroke="#000000"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
