const form = {
  formID: "Roles",
  formField: {
    status: {
      name: "status",
      label: "kioskForm.status.label",
      requiredMessage: "kioskForm.status.requiredMessage",
    },
    description: {
      name: "description",
      label: "kioskForm.description.label",
      
    },
    deviceID: {
      name: "deviceID",
      label: "kioskForm.deviceID.label",
      requiredMessage: "kioskForm.deviceID.requiredMessage",
    },
    fingerprint: {
      name: "fingerprint",
      label: "kioskForm.fingerprint.label",
      requiredMessage:"kioskForm.fingerprint.requiredMessage"
    },
    location: {
      name: "location",
      label: "kioskForm.location.label",
      requiredMessage:"kioskForm.location.requiredMessage"
    },
    workType: {
      name: "workType",
      label: "kioskForm.workType.label",
      requiredMessage:"kioskForm.workType.requiredMessage"
    },
 
  },
};

export default form;