import checkout from "./form";

const {
  formField: {
    title,
    description,
    status,
    project,
    ticket,
    startDate,
    endDate,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
    ActualBillableTime,
    ActualNonBillableTime,
    externalReference,
    synced,
    area,
    priority,
    team,
    task,
    allowCreateTimeEntry,
    billable,
  },
} = checkout;

const initialValues = {
  [EstimatedEndDate.name]: null,
  [priority.name]:"",
  [EstimatedStartDate.name]: null,
  [EstimatedTime.name]: null,
  [ActualEndDate.name]: null,
  [ActualStartDate.name]: null,
  [ActualTime.name]: null,
  [ActualBillableTime.name]: "",
  [ActualNonBillableTime.name]: "",
  [title.name]: "",
  [externalReference.name]: "",
  [description.name]: "",
  [status.name]: null,
  [project.name]: null,
  [ticket.name]: null,
  [startDate.name]: null,
  [endDate.name]: null,
  [synced.name]: false,
  [area.name]: null,
  [team.name]: null,
  [task.name]: null,
  [allowCreateTimeEntry.name]: true,
  [billable.name]: true,
};

export default initialValues;