const form = {
  formID: "Roles",
  formField: {
    name: {
      name: "name",
      label: "roleForm.name.label",
      requiredMessage: "roleForm.name.requiredMessage",
    },
    description: {
      name: "description",
      label: "roleForm.description.label",
      
    },
    manageSystemConfigurations: {
      name: "manageSystemConfigurations",
      label: "roleForm.manageSystemConfigurations.label",
      requiredMessage: "roleForm.manageSystemConfigurations.requiredMessage",
    },
    manageTimeEntry: {
      name: "manageTimeEntry",
      label: "roleForm.manageTimeEntry.label",
      requiredMessage:"roleForm.manageTimeEntry.requiredMessage"
    },
    manageUsers: {
      name: "manageUsers",
      label: "roleForm.manageUsers.label",
      requiredMessage:"roleForm.manageUsers.requiredMessage"
    },
    manageAttendance: {
      name: "manageAttendance",
      label: "roleForm.manageAttendance.label",
      requiredMessage:"roleForm.manageAttendance.requiredMessage"
    },
    manageParameters: {
      name: "manageParameters",
      label: "roleForm.manageParameters.label",
      requiredMessage:"roleForm.manageParameters.requiredMessage"
    }
  },
};

export default form;