import { gql } from "@apollo/client";

export const GET_ALL_SYSTEM_VARIABLES =gql`
query ($model: GetAllSystemVariablesRequestModelInput!) {
    GetSystemVariablesList(model: $model) {
      systemVariables {
        systemVariables {
          systemVariablesGuid
          description
          value
          additionalInfo
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
   `

  export const EDIT_SYSTEM_VARIABLE=gql`
  mutation ($model: UpdateSystemVariableRequestInput!) {
    UpdateSystemVariable(model: $model) {
      updateSystemVariableResponse
      errorCode
      errorMessage
      errorVariables
    }
  }`

export const GET_ALL_BUSINESS_CENTRAL_VERSIONS = gql`
query{
  GetBusinessCentralVersionsList {
    businessCentralVersions{
      businessCentralVersionGuid
      description
      mnemonic
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;