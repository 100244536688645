import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_PTO_ASSIGN_DATES,
  GET_PTO_STATUSES,
  GET_PTO_LIST,
  DELETE_PTOS,
} from "../../../../graphql/pto";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const PTOList = ({
  fromTenant = false,
  fromEmployee = false,
  tenant = null,
  handleClick = () => {},
  initialVal = [],
  refetchList = false,
}) => {
  const { tID, tName, eID } = useParams();

  const { tenant_ptos_form, new_tenant_pto, my_ptos_form, new_my_pto } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState(fromEmployee ? initialVal : []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const [fetchPTOAssignDates, setFetchPTOAssignDates] = useState(false);
  const [ptoAssignDateList, setPtoAssignDatesList] = useState([]);
  const [fetchPTOStatuses, setFetchPTOStatuses] = useState(false);
  const [ptoStatusList, setPtoStatusList] = useState([]);

  const {
    data: ptoAssignDateData,
    loading: ptoAssignDateLoading,
    tokenChange: ptoAssignDateWait,
  } = useQueryAuth(GET_PTO_ASSIGN_DATES, "GetPTOAssignDates", {
    skip: !fetchPTOAssignDates,
    isWait: fetchPTOAssignDates,
  });

  useEffect(() => {
    if(ptoAssignDateData) setPtoAssignDatesList(ptoAssignDateData?.GetPTOAssignDates?.assignDates || []);
  }, [ptoAssignDateData])

  const {
    data: ptoStatusData,
    loading: ptoStatusLoading,
    tokenChange: ptoStatusWait,
  } = useQueryAuth(GET_PTO_STATUSES, "GetPTOStatuses", {
    skip: !fetchPTOStatuses,
    isWait: fetchPTOStatuses,
  });

  useEffect(() => {
    if(ptoStatusData) setPtoStatusList(ptoStatusData?.GetPTOStatuses?.statuses || []);
  }, [ptoStatusData])

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_PTOS,
    "DeletePTOs"
  );

  const filtersObject = [
    {
      type: "text",
      label: "ptos.list.filters.description",
      name: "description",
    },
    
    {
      label: "ptos.list.filters.paid",
      name: "paidDays",
      type: "multiple",
      getOptionLabel: (option) =>
        option && option.value ? "Paid" : "Non-Paid",
      options: [{ value: true }, { value: false }],
      access: "value",
    },
    {
      label: "ptos.list.filters.assignDates",
      name: "assignDates",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: ptoAssignDateList,
      access: "ptoAssignDateGuid",
      loading: ptoAssignDateLoading,
      fetchData: setFetchPTOAssignDates,
    },
    {
      label: "ptos.list.filters.statuses",
      name: "statuses",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: ptoStatusList,
      access: "ptoStatusGuid",
      loading: ptoStatusLoading,
      fetchData: setFetchPTOStatuses,
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.PTOs,
          userID: storeUserGuid,
        },
      },
      skip: ptoStatusWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllPTOs, loading } = useLazyQueryAuth(
    GET_PTO_LIST,
    "GetPTOsList"
  );
  
  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllPTOs(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantID: fromEmployee
                ? tenant
                : tID && fromTenant
                ? tID
                : storeUserTenant,
              userID: storeUserGuid,
              filters: filters,
              mnemonic: Mnemonic.Tables.PTOs,
              unassignedPTOs: fromEmployee === true,
              employeeID: fromEmployee && eID ? eID : undefined,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    setRe(!re); 
    setSelected([]);
  }, [refetchList])

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetPTOsList &&
    data.GetPTOsList.ptOs &&
    data.GetPTOsList.ptOs.ptOs
      ? data.GetPTOsList.ptOs.ptOs
      : [];
  const numberOfRecords =
    data &&
    data.GetPTOsList &&
    data.GetPTOsList.ptOs &&
    data.GetPTOsList.ptOs.totalNumberOfRecords
      ? data.GetPTOsList.ptOs.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            ptOs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("ptos.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const customButtons = [
    {
      label: "employeeForm.ptoList.addPTO",
      handleClick: () => handleClick(selected),
      condition: selected && selected.length > 0 && fromEmployee,
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "ptos.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.PTOs,
    // filtersObject,
    ...(!fromEmployee && {
      generateRoute: (row) =>
        fromTenant
          ? tenant_ptos_form
              .replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
              .replace(
                "/:ptoName/:ptoID",
                `/${encodeURIComponent(row?.description)}/${encodeURIComponent(
                  row?.ptoGuid
                )}`
              )
          : my_ptos_form.replace(
              "/:ptoName/:ptoID",
              `/${encodeURIComponent(row?.description)}/${encodeURIComponent(
                row?.ptoGuid
              )}`
            ),
      newText: "ptos.list.new.label",
      newLink: fromTenant
        ? new_tenant_pto.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
        : new_my_pto,
      deleteText: "ptos.list.delete.label",
      handleDelete: handleDelete,
      deleteLoading,
    }),
    hideEditColumns: fromEmployee,
    readOnly: !fromEmployee && manageSystemConfigurations !== "M",
    customButtons,
    singleSelect: fromEmployee,
    removeFilters: true,
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"ptoGuid"}
          hasCheckbox
          fullObject={fromEmployee}
        />
      )}
    </div>
  );
};

export default PTOList;
