import React from "react";

//components
import { Grid, Box, } from "@mui/material";

import EmployeeForm from "../../admin-configuration/Employees/Employee-form";
import useAuth from "../../../hooks/useAuth";

const ProfileForm = () => {
  const { userRoles } = useAuth();
  const { isEntelligence } = userRoles;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={0}>
          <EmployeeForm myEmployees={!isEntelligence} fromProfile={true}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileForm;
