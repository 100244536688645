import React from "react";

import { useTranslation } from "react-i18next";

import { IconButton } from "@mui/material";

import Tooltip from "../../../../../components/Tooltip";

import { Delete as DeleteIcon } from "@mui/icons-material";

const EmployeePTOTable = ({ data = [], setData = () => {} }) => {
  const { t } = useTranslation();

  const handleRemove = (index) => {
    const tmp = structuredClone(data);
    tmp.splice(index, 1);
    if (setData) setData(tmp);
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table style={{ width: "100%", overflowX: "auto", textAlign: "left" }}>
        <thead>
          <tr>
            <th>{t("employeeForm.create.ptoList.Description")}</th>
            <th>{t("employeeForm.create.ptoList.NumberOfDays")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((val, index) => (
              <tr key={index}>
                <td>{val?.description}</td>
                <td>{val?.numberOfDays}</td>
                <td>
                  <Tooltip
                    key={index}
                    title={t("employeeForm.create.ptoList.remove")}
                  >
                    <IconButton onClick={() => handleRemove(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeePTOTable;
