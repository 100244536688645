import React, { useState, useEffect } from "react";

//routing
import { Link, useNavigate } from "react-router-dom";

import useMutationAuth from "../../hooks/useMutationAuth";

//external components
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Grid,
  Typography,
  Stack,
  Pagination,
  Button,
  Dialog
} from "@mui/material";

import Tooltip from "../Tooltip";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
//filters
import { COPY_ITEMS, CLEAR_FILTERS } from "../../types/filterTypes";

//icons
import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";


//translation
import { useTranslation } from "react-i18next";

//components
import IALoadingButton from "../IAButtons/IALoadingButton";
import No_Image_Found from "../../assets/images/no-user-image.png"
//hooks
import useFilters from "../../hooks/useFilters";
import FilterField from "../FilterField";
import ContainerCard from "./ContainerCard";
import RoundedTextField from "../TextFields/RoundedTextField";
import { DELETE_EMPLOYEES } from "../../graphql/employee";
import { useSnackbar } from "notistack";
import EmployeeStatus from "../StatusCell/EmployeeStatus";
import ConfirmationModal from "../Modal/ConfirmationModal";
import CustomButton from "../CustomButton";
import { ActivateUserIcon, DeactivateUserIcon, DeleteIcon, EmailIcon, AppliedFiltersIcon, FilterIcon, RefreshIcon } from "../Icon";
import PaperComponent from "../Modal/PaperComponent";

//wait before search function call
const DELAY_BEFORE_SEARCH = 1000;


export default function InfoCard(props) {
  const {
    rows,
    order,
    orderBy,
    selected,
    page,
    setPage,
    rowsPerPage,
    fetchTableData,
    numberRecords,
    search,
    setSearch,
    loading,
    searchText,
    filtersObject,
    generateRoute,
    newText,
    newLink,
    defaultFilters,
    setDefaultFilters,
    removeFilters,
    readOnly, // prop that indicates readOnly so no selecting or buttons
    hideSearchBar,
    customButtons, // props if user wants to enter custom buttons for specific tables other than new and delete
    removeRefresh,
    activateUser,
    deactivateUser,
    handleResendActivationEmail,
    refetchData,
    disableAction
    
  } = props;

  const navigate = useNavigate();

  //get object to send to filters
  const { state: filtersState, dispatch: handleFilters } = useFilters();

  //to open filters
  const [openFilters, setOpenFilters] = useState(false);
  const quillRef = React.useRef();

  //timer for search functionality
  const [timer, setTimer] = useState(null);
  const [inactiveState, setInactiveState] = useState(null);
  const [newState, setNewState] = useState(null);
  const [activeState, setActiveState] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState(null);
  const [totalNumOfPages, setTotalNumOfPages] = useState(1);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleDelete(selectedRowID);
    handleClose();
  };

  const handleOpenDeleteConfirmationModal = (id) => {
    setSelectedRowID(id);
    setOpenDialog(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setSelectedRowID(null);
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    setTotalNumOfPages(Math.ceil(numberRecords / rowsPerPage));
  }, [numberRecords]);

  const { mfunction: deleteRecords } = useMutationAuth(
    DELETE_EMPLOYEES,
    "DeleteUsers"
  );
  const handleDelete = (id) => {
    deleteRecords(
      {
        variables: {
          model: {
            users: id,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("employee.list.delete.success"), {
          variant: "success",
        });
        refetchData();
      }
    );
  };

  const handleSearch = (value) => {
    if (timer) {
      //if timer is set, we empty it so it's not called and wait another delay
      clearTimeout(timer);
      setTimer(null);
    }

    //set timer for after 2 seconds of wait
    setTimer(
      setTimeout(() => {
        fetchTableData(
          page,
          rowsPerPage,
          order,
          orderBy,
          value,
          defaultFilters
        );
      }, DELAY_BEFORE_SEARCH)
    );
  };



  const handleShowCards = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {rows &&
          rows.map((row, index) => (
            <Link
              key={index}
              to={generateRoute(row)}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div
                key={row?.employeeGuid}
                className="square-card"
                style={{
                  display: "flex",
                  width: "390px",
                  height:  "130px",
                  margin: "4px 15px 9px 0px",
                  borderRadius: "8px",
                  overflow: "hidden",
                  backgroundColor: "white",
                  cursor: "pointer",
                }}
                onMouseEnter={() => {
                  if (row.employeeStatus?.mnemonic === "inactive") {
                    setInactiveState(index);
                  } else if (row.employeeStatus?.mnemonic === "active") {
                    setActiveState(index);
                  } else if (row.employeeStatus?.mnemonic === "new") {
                    setNewState(index);
                  }
                }}
                onMouseLeave={() => {
                  setInactiveState(null);
                  setNewState(null);
                  setActiveState(null);
                }}
              >
                <div
                  style={{
                    flex: "0 0 100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Image */}
                  <img
                    src={row.profilePictureURL ? row.profilePictureURL : No_Image_Found}
                    className="userImage"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = No_Image_Found;
                    }}
                    style={{
                      width: "92%",
                      height: "72%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    onClick={() => navigate(generateRoute(row))}
                  />
                </div>
                <div
                  style={{
                    flex: "0 0 280px",
                    padding: "8px 0px 8px 8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onClick={() => navigate(generateRoute(row))}
                >
                  {/* Employee Information */}
                  <Grid container spacing={2} >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ marginRight: "5px" }}>
                        {row.firstName + " " + row.lastName}
                      </h5>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                        >
                          <EmployeeStatus row={row} tile />

                        </span>
                      </div>
                    </Grid>
                  </Grid>

                  <div style={{ marginBottom: "1rem" }}>
                    <p className="squareInfo">{row.position}</p>
                    <p className="squareInfo">{row.email}</p>
                    <p className="squareInfo">{row.phoneNumber}</p>
                  </div>
                </div>
                {inactiveState === index &&
                  row.employeeStatus?.mnemonic === "inactive" && !disableAction(row) && (
                    <div
                      className="icon-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "0.7rem",
                      }}
                    >
                      <Tooltip title={t("activate.user.button")}>
                        <button
                          className="functionButton"
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); activateUser(row?.employeeGuid) }}
                        >
                          <ActivateUserIcon color="#000000" />{" "}
                        </button>{" "}
                      </Tooltip>{" "}
                      <Tooltip title={t("email.user.button")}>
                        <button
                          className="functionButton"
                          onClick={(e) => {
                            e.preventDefault(); e.stopPropagation();
                            handleResendActivationEmail(row?.email)
                          }
                          }
                          style={{ marginTop: "0.3rem" }}
                        >
                          <EmailIcon color="#000000" />{" "}
                        </button>{" "}
                      </Tooltip>
                    </div>
                  )}
                {newState === index &&
                  row.employeeStatus?.mnemonic === "new" && !disableAction(row) && (
                    <div
                      className="icon-container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "0.3rem",
                      }}
                    >
                      <Tooltip
                        fromDelete
                        placement="right"
                        title={t("delete.user.button")}
                      >
                        <button
                          className="functionButton"
                          
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleOpenDeleteConfirmationModal(row?.employeeGuid) }}
                        >
                          <DeleteIcon color="#000000" width="20" />{" "}
                        </button>{" "}
                      </Tooltip>
                      <Tooltip title={t("email.user.button")}>
                        <button
                          className="functionButton"
                          onClick={(e) => {
                            e.preventDefault(); e.stopPropagation();
                            handleResendActivationEmail(row?.email)
                          }
                          }
                          style={{ marginTop: "0.3rem" }}
                        >
                          <EmailIcon color="#000000" />{" "}{" "}
                        </button>{" "}
                      </Tooltip>{" "}
                    </div>
                  )}
                {activeState === index &&
                  row.employeeStatus?.mnemonic === "active" && !disableAction(row) && (
                    <div className="icon-container">
                      <Tooltip title={t("deactivate.user.button")}>
                        <button
                          className="functionButton"
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); deactivateUser(row?.employeeGuid) }}
                        >
                          <DeactivateUserIcon color="#000000" />{" "}
                        </button>{" "}
                      </Tooltip>{" "}
                    </div>
                  )}
              </div>
            </Link>
          ))}
      </div>
    );
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  return (
    <>
      <ConfirmationModal
        contentText={t("dialog.user.delete.record.confirmation")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <Dialog
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <ContainerCard sx={{ padding: "20px", width: "100%", overflowY: "auto" }} id="draggable-dialog-title">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: "poppins",
                }}
              >
                {t("table.FilterTitle")}
              </Typography>
              <IconButton onClick={() => setOpenFilters(false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </Grid>
            {filtersObject &&
              filtersObject.map((filter, index) => {
                if (filter.skip) return null;
                return (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <FilterField
                      key={index}
                      {...filter}
                      filtersState={filtersState}
                      handleFilters={handleFilters}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <div className="filter-buttons">
            <IALoadingButton
              label={t("table.filters.clear")}
              onClick={() => {
                handleFilters({
                  type: CLEAR_FILTERS,
                });
                setDefaultFilters({});
                fetchTableData(page, rowsPerPage, order, orderBy, search, {});
              }}
            />
            <IALoadingButton
              label={t("table.filters.apply")}
              onClick={() => {
                setDefaultFilters(filtersState);
                fetchTableData(
                  page,
                  rowsPerPage,
                  order,
                  orderBy,
                  search,
                  filtersState
                );
                setOpenFilters(false);
              }}
            />
          </div>
        </ContainerCard>
      </Dialog>
      <Box>
        <div className="table-bar">
          {!hideSearchBar && (
            <RoundedTextField
              label={searchText ? t(searchText) : ""}
              fullWidth
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                handleSearch(e.target.value);
              }}
              inputProps={{
                style: {
                  backgroundColor: "white",
                  borderRadius: "5px",
                  height: "15px",
                  fontSize: "14px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setSearch("");
                      handleSearch("");
                    }}
                  >
                    <ClearOutlinedIcon />
                  </IconButton>
                ),
                style: {
                  backgroundColor: "white",
                  paddingRight: "0",
                  opacity: 0.7,
                  height: "33px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px", // Adjust the font size as needed
                  lineHeight: 1.1,
                },
              }}
            />
          )}

          {!removeRefresh && (
            <Tooltip title={t("table.RefreshBtn")}>
              <IconButton
                onClick={() =>
                  fetchTableData(
                    page,
                    rowsPerPage,
                    order,
                    orderBy,
                    search,
                    defaultFilters
                  )
                }
              >
                <RefreshIcon color="#000000" />
              </IconButton>
            </Tooltip>
          )}

          {!removeFilters &&
            (JSON.stringify(defaultFilters) === "{}" ? (
              <Tooltip title={t("table.FilterTitle")}>
                <IconButton
                  onClick={() => {
                    handleFilters({
                      type: COPY_ITEMS,
                      payload: { value: defaultFilters },
                    });
                    setOpenFilters(true);
                  }}
                >
                  <FilterIcon color="#000000" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("table.FilterTitleApplied")}>
                <IconButton
                  onClick={() => {
                    handleFilters({
                      type: COPY_ITEMS,
                      payload: { value: defaultFilters },
                    });
                    setOpenFilters(true);
                  }}
                >
                  <AppliedFiltersIcon color="var(--color--green-color)" />
                </IconButton>
              </Tooltip>
            ))}
          {!readOnly && newText && (!selected || selected.length === 0) ? (
            <Tooltip title={t("table.AddNew")}>
              <IconButton
                onClick={() => {
                  navigate(newLink);
                }}
              >
                <AddCircleRoundedIcon
                  sx={{
                    color: "var(--color--light-logo-blue)",
                    fontSize: "28px !important"
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {customButtons &&
            customButtons.map((button, index) => {
              if (button.condition)
                if (button.icon)
                  return (
                    <Tooltip key={index} title={t("table.squares")}>
                      <IconButton onClick={() => button.handleClick()}>
                        {button.iconButton}
                      </IconButton>
                    </Tooltip>
                  );
                else
                  return (
                    <IALoadingButton
                      key={index}
                      label={t(button.label)}
                      className="new-record-btn"
                      dark
                      error={button.error}
                      startIcon={button.startIcon}
                      loading={button.loading}
                      disabled={button.disabled}
                      onClick={() => {
                        button.handleClick();
                      }}
                    />
                  );
              else return null;
            })}
        </div>

        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", justifyContent: "center",
             }}
            >
              {loading ? <CircularProgress /> : handleShowCards()}
            </Grid>
          </Grid>
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <Stack spacing={2}>
            <Pagination
              count={totalNumOfPages}
              onChange={(e, page) => {
                if(setPage) setPage(page - 1);
                fetchTableData(
                  page - 1,
                  rowsPerPage,
                  order,
                  orderBy,
                  search,
                  defaultFilters
                );
              }}
              page={page + 1}
              defaultPage={1}
              hideNextButton={page == totalNumOfPages}
            />
          </Stack>
        </div>
      </Box>
    </>
  );
}
