import React from "react";

//external components
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//components
import AttendanceSheetsListPending from "../AttendanceSheetsListPending";
import AttendanceSheetsListApproved from "../AttendanceSheetsListApproved";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0.5, pb: 0.5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AttendanceSheetsListTabRoutes({ value }) {

  return (
    <>
      <TabPanel value={value} index={0}>
        <AttendanceSheetsListPending />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AttendanceSheetsListApproved />
      </TabPanel>
    </>
  );
}
