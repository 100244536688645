import { gql } from "@apollo/client";

export const GET_ALL_REQUESTS = gql`
  query ($model: RequestsListGetModelInput!) {
    GetAllRequestsList(model: $model) {
      requestsList {
        requests {
          requestGuid
          type
          description
          attachment
          rejectionReason
          requestDate
          dateFrom
          dateTo
          timeFrom
          timeTo
          numberOfDays
          requestStatus {
            description
            mnemonic
          }
          receiver {
            firstName
            lastName
          }
          cancellationDate
          cancelledBy {
            firstName
            lastName
          }
          rejectionDate
          rejectionReason
          rejectedBy {
            firstName
            lastName
          }
          approvalDate
          approvedBy {
            firstName
            lastName
          }
          employeePTO {
            employee {
              firstName
              lastName
            }
          }
          dayLog {
            dayLogGuid
            employee {
              firstName
              lastName
            }
          }
          employee {
            firstName
            lastName
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_REQUEST_TYPES = gql`
  query {
    GetAllRequestTypes {
      requestTypes {
        requestTypeGuid
        description
        mnemonic
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const GET_ALL_REQUEST_STATUSES = gql`
  query {
    GetAllRequestStatuses {
      requestStatus {
        requestStatusGuid
        description
        mnemonic
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const GET_ALL_PTO_REQUESTS = gql`
  query ($model: GetAllRequestsRequestModelInput!) {
    GetAllPTORequests(model: $model) {
      requestsListModel {
        requests {
          requestGuid
          requestStatus {
            requestStatusGuid
            description
            mnemonic
          }
          requestType {
            requestTypeGuid
            description
            mnemonic
          }
          employee {
            employeeGuid
            firstName
            lastName
            email
          }
          manager {
            firstName
            lastName
          }
          requestDate
          timeFrom
          timeTo
          dateFrom
          dateTo
          clockIn
        }
        totalNumberOfRecords
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const GET_REQUEST_BY_ID = gql`
  query ($id: UUID!) {
    GetRequestByID(request: $id) {
      request {
        requestGuid
        attachmentURL
        clockIn
        employee {
          employeeGuid
          firstName
          lastName
          email
        }
        manager {
          employeeGuid
          firstName
          lastName
        }
        timeFrom
        timeTo
        dateFrom
        dateTo
        note
        requestDate
        requestStatus {
          requestStatusGuid
          description
          mnemonic
        }
        requestType {
          mnemonic
          description
        }
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const CHANGE_REQUEST_STATUS = gql`
  mutation ($model: RequestStatusUpdateRequestInput!) {
    UpdateRequestStatus(model: $model) {
      request {
        requestGuid
        requestStatus {
          requestStatusGuid
          description
          mnemonic
        }
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const GET_OFFLINE_REQUESTS = gql`
  query ($model: GetOfflineRequestsListModelInput!) {
    GetOfflineRequestsWithFilters(model: $model) {
      offlineRequestsList {
        offlineRequests {
          requestSetGuid
          clockInRequest {
            requestGuid
            requestStatus {
              requestStatusGuid
              description
              mnemonic
            }
            requestType {
              requestTypeGuid
              description
              mnemonic
            }
            requestDate
            timeFrom
            timeTo
            dateFrom
            dateTo
            clockIn
          }
          clockOutRequest {
            requestGuid
            requestStatus {
              requestStatusGuid
              description
              mnemonic
            }
            requestType {
              requestTypeGuid
              description
              mnemonic
            }
            requestDate
            timeFrom
            timeTo
            dateFrom
            dateTo
            clockIn
          }
          dayLog {
            dayLogGuid
            checkInTime
            checkOutTime
            workType {
              description
              mnemonic
            }
            location {
              locationGuid
              name
            }
          }
          employee {
            firstName
            lastName
          }
          requestDate
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_OFFLINE_REQUEST_BY_ID = gql`
  query ($model: GetOfflineRequestModelInput!) {
    GetOfflineRequestByID(model: $model) {
      offlineRequest {
        requestSetGuid
        clockOutRequest {
          requestGuid
          attachmentURL
          note
          requestDate
        }
        clockInRequest {
          requestGuid
          attachmentURL
          note
          requestDate
        }
        dayLog {
          dayLogGuid
          checkInTime
          workType {
            workTypeGuid
            description
            mnemonic
          }
          location {
            locationGuid
            name
          }
        }
        requestType {
          requestTypeGuid
          description
          mnemonic
        }
        requestStatus {
          requestStatusGuid
          description
          mnemonic
        }
        employee {
          employeeGuid
          firstName
          lastName
        }
        requestDate
        clockIn
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const HANDLE_CLOCKING_REQUEST = gql`
  mutation ($model: RequestApprovalInput!) {
    AdminClockingRequestHandle(model: $model) {
      request {
        requestGuid
        requestStatus {
          requestStatusGuid
          description
          mnemonic
        }
      }
      errorMessage
      errorVariables
      errorCode
    }
  }
`;

export const GET_ALL_DAYS_USED_FROM_PTO = gql`
  query ($model: GetDaysUsedFromVacationForEmployeeRequestModelInput!) {
    GetDaysUsedForUser(model: $model) {
      requestsListModel {
        requestsOutput {
          requestGuid
          manager {
            firstName
            lastName
          }
          requestDate
          timeFrom
          timeTo
          dateFrom
          dateTo
          days
          date
          reason
        }
        ptoGuid
        ptoType
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const FILL_REQUEST_MANUALLY = gql`
  mutation ($model: FillRequestManuallyRequestModelInput!) {
    FillRequestManually(model: $model) {
      request {
        requestGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
