 /*Convert base64String (image long text) to file (blob)*/
export const base64StringtoFile = (base64String, filename) => {
  if (base64String !== "" && base64String !== null) {
    var arr = base64String.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } else return null;
};