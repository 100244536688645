import React from "react";

//components
import { Grid, Box } from "@mui/material";
import { Title, Subtitle } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";

//translation
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../../components/Tooltip";
//hooks
import { useSnackbar } from "notistack";
import TextField from "../../../../../components/TextFields/TextField";

import { v4 as uuidv4 } from "uuid";

const ROLE_VALUES = [
  {
    text: "roleForm.role.value.manage",
    code: "M",
  },
  { text: "roleForm.role.value.read", code: "R" },
  { text: "roleForm.role.value.noaccess", code: "N" },
];

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    whatField,
    setWhatField,
  } = formData;

  const {
    name,
    description,
    manageSystemConfigurations,
    manageTimeEntry,
    manageUsers,
    manageAttendance,
    manageParameters,
  } = formField;

  const {
    name: nameV,
    description: descriptionV,
    manageSystemConfigurations: manageSystemConfigurationsV,
    manageTimeEntry: manageTimeEntryV,
    manageUsers: manageUsersV,
    manageAttendance: manageAttendanceV,
    manageParameters: manageParametersV,
  } = values;

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("roleForm.roleInformation")}
          </Title>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                autoFocus
                fullWidth
                value={nameV}
                onChange={(e) => {
                  setFieldValue(name.name, e.target.value);
                }}
                label={t(name.label)}
                name={name.name}
                formData={formData}
                required
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                value={descriptionV}
                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                label={t(description.label)}
                name={description.name}
                formData={formData}
                required
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
          <Subtitle>{t("roleForm.rolePermissions")}</Subtitle>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Tooltip title={t("role.tooltip.manageSystemConfigurations")}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={ROLE_VALUES}
                  getOptionLabel={(option) => (option ? t(option.text) : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {t(option.text)}
                    </li>
                  )}
                  identifier={"code"}
                  value={manageSystemConfigurationsV}
                  disabled={readOnly}
                  onChange={(e, value) => {
                    setFieldValue(manageSystemConfigurations.name, value);
                  }}
                  label={t(manageSystemConfigurations.label)}
                  name={manageSystemConfigurations.name}
                  formData={formData}
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("role.tooltip.manageSystemConfigurations")}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={ROLE_VALUES}
                  getOptionLabel={(option) => (option ? t(option.text) : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {t(option.text)}
                    </li>
                  )}
                  identifier={"code"}
                  value={manageUsersV}
                  disabled={readOnly}
                  onChange={(e, value) => {
                    setFieldValue(manageUsers.name, value);
                  }}
                  label={t(manageUsers.label)}
                  name={manageUsers.name}
                  formData={formData}
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("role.tooltip.manageParameters")}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={ROLE_VALUES}
                  disabled={readOnly}
                  getOptionLabel={(option) => (option ? t(option.text) : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {t(option.text)}
                    </li>
                  )}
                  identifier={"code"}
                  value={manageParametersV}
                  onChange={(e, value) => {
                    setFieldValue(manageParameters.name, value);
                  }}
                  label={t(manageParameters.label)}
                  name={manageParameters.name}
                  formData={formData}
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("role.tooltip.manageTimeEntry")}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={ROLE_VALUES}
                  getOptionLabel={(option) => (option ? t(option.text) : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {t(option.text)}
                    </li>
                  )}
                  identifier={"code"}
                  value={manageTimeEntryV}
                  disabled={readOnly}
                  onChange={(e, value) => {
                    setFieldValue(manageTimeEntry.name, value);
                  }}
                  label={t(manageTimeEntry.label)}
                  name={manageTimeEntry.name}
                  formData={formData}
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("role.tooltip.manageAttendance")}>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={ROLE_VALUES}
                  getOptionLabel={(option) => (option ? t(option.text) : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {t(option.text)}
                    </li>
                  )}
                  identifier={"code"}
                  value={manageAttendanceV}
                  disabled={readOnly}
                  onChange={(e, value) => {
                    setFieldValue(manageAttendance.name, value);
                  }}
                  label={t(manageAttendance.label)}
                  name={manageAttendance.name}
                  formData={formData}
                />
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
