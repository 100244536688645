import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import UTC2Local from "../../UTC2Local";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Dialog, IconButton } from "@mui/material";
import "./style.css";
import NoUserImage from "../../assets/images/no-user-image.png";
import darkLogo from "../../assets/images/dark-LOGO.png";
import colors from "../../assets/theme/light/colors";
import ReactQuill from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css';
import { CloseIcon, NewsIcon, VerticalActionsIcon } from "../Icon";
import { Title } from "../Titles/FormTitles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import urls from "../../urls";
import useAuth from "../../hooks/useAuth";
import Mnemonics from "../../Mnemonics.json";

export default function AlignItemsList({
  notifsList,
  handleRead,
  handleDelete,
  handleCloseDrawer,
  handleTask,
  handleOpenNewDialog,
}) {
  const { isManager, userRoles } = useAuth();
  const { isAdmin } = userRoles;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [expanded, setExpanded] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifGuid, setNotifGuid] = useState(null);
  const { light_logo_blue, grey_background } = colors;
  const quillRef = React.useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { my_requests_form, my_attendance_requests_form, project_backlog_tasks } = urls;

  const handleModalOpen = (text) => {
    setModalText(text);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    if (isExpanded) {
      handleRead(panel);
    }
  };

  const openMenu = (event, notif) => {
    setNotifGuid(notif.notificationGuid);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (option, notification) => {
    switch (option) {
      case "Mark as read":
        handleRead(notification);
        break;
      case "Delete":
        handleDelete(notification);
        break;
      default:
        break;
    }
    closeMenu();
  };

  function stripHtmlTags(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }

  return (
    <div>
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        sx={{
          ".MuiDialog-paper": {
            minWidth: "1150px !important",
          },
          zIndex: 1600,
        }}
        className="quillDialog"
      >
        <div style={{ padding: "20px", position: "relative" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Icon */}
            <NewsIcon color="#000000" />

            {/* Title */}
            <div style={{ marginLeft: "10px" }}>
              <Title>{t("News.news")}</Title>
            </div>

            {/* Close Icon */}
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: "absolute",
                right: "10px",
                top: "8px",
                zIndex: 9999,
              }}
            >
              <CloseIcon color="#9e9e9e" width={24} stroke={1.5} />
            </IconButton>
          </div>

          {/* ReactQuill Component */}
          <div className="quillNotif">
            <ReactQuill
              ref={quillRef}
              name="body"
              value={
                modalText?.newsGuid === null
                  ? modalText?.notificationText
                  : modalText?.news?.description
              }
              readOnly={true}
              style={{
                marginTop: "20px",
                minHeight: "500px !important",
                marginBottom: "15px",
              }}
            />
          </div>
        </div>
      </Dialog>

      {notifsList?.notifications?.map((notification) => (
        <React.Fragment key={notification.notificationGuid}>
          <Accordion
            expanded={expanded === notification.notificationGuid}
            onChange={handleChange(notification.notificationGuid)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
              sx={{
                height: "60px",
                backgroundColor: notification?.isRead
                  ? "transparent"
                  : grey_background,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Align items vertically
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {notification.senderID ? (
                  <img
                    src={notification?.sender?.profilePictureURL || NoUserImage}
                    className="userImage"
                    alt="Not Found"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NoUserImage;
                    }}
                    style={{
                      width: "45px",
                      height: "42px",
                      borderRadius: "50%",
                      marginRight: "8px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={darkLogo}
                    alt="Not Found"
                    style={{
                      width: "45px",
                      height: "42px",
                      borderRadius: "50%",
                      marginRight: "8px",
                      objectFit: "cover",
                    }}
                  />
                )}

                <Typography
                  sx={{
                    fontWeight: notification.isRead ? "bold" : "bold",
                    display: "inline",
                    margin: 0, // Reset margin
                  }}
                  component="span"
                  variant="body3"
                  color="text.primary"
                >
                  {notification?.notificationTitle}
                </Typography>
              </div>

              <div style={{ flex: 1 }}></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {notification?.isRead ? null : (
                  <CircleIcon
                    style={{
                      color: light_logo_blue,
                      width: "8px",
                      height: "8px",
                      marginRight: "4px",
                      marginBottom: "1px",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                  component="span"
                  variant="body3"
                  color="text.secondary"
                >
                  {notification?.dateSent
                    ? UTC2Local(notification.dateSent)
                    : null}
                </Typography>
                <div
                  onClick={(event) => openMenu(event, notification)}
                  style={{
                    cursor: "pointer",
                    transition: "opacity 0.3s",
                    "&:hover": { opacity: 0 },
                    margin: "3px 5px 0 5px",
                  }}
                >
                  <VerticalActionsIcon color="#9e9e9e"/>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {notification?.newsGuid !== null ? (
                <Typography
                  sx={{
                    fontWeight: "normal",
                    display: "inline",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                  component="span"
                  variant="body3"
                  color="text.primary"
                  onClick={() => handleModalOpen(notification)}
                >
                  {notification?.news?.description?.length > 250
                    ? stripHtmlTags(notification?.news?.description).slice(
                        0,
                        250
                      ) + "..."
                    : stripHtmlTags(notification?.news?.description)}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: "normal",
                    display: "inline",
                    marginTop: "7px",
                    cursor: notification?.type === "TO-R" || notification?.type === "REQ" || notification?.type === "Task" ? "pointer" : "initial",
                  }}
                  component="span"
                  variant="body3"
                  color="text.primary"
                  onClick={() => {
                    if (notification?.type === "TO-R" || notification?.type === "REQ") {
                      navigate(
                        notification?.toRequest ?
                        my_requests_form.replace(
                          "/:reqName/:reqID",
                          `/${encodeURIComponent(
                            notification?.toRequest?.employeePTO?.employee
                              ?.firstName +
                              " " +
                              notification?.toRequest?.employeePTO?.employee
                                ?.lastName
                          )}/${encodeURIComponent(
                            notification?.toRequest?.toRequestGuid
                          )}${(isAdmin || isManager) ? "?received" : notification?.toRequest?.requestStatus?.mnemonic === Mnemonics.RequestStatuses.SentForApproval ? "?received"  : ""}`
                        ) 
                        : 
                        my_attendance_requests_form.replace(
                          "/:reqName/:reqID",
                          `/${encodeURIComponent(
                            notification?.attendanceRequest?.employee
                              ?.firstName +
                              " " +
                              notification?.attendanceRequest?.employee
                                ?.lastName
                          )}/${encodeURIComponent(
                            notification?.attendanceRequest?.attendanceRequestGuid
                          )}${(isAdmin || isManager) ? "?received" : notification?.attendanceRequest?.requestStatus?.mnemonic === Mnemonics.RequestStatuses.SentForApproval ? "?received" : ""}`
                        )
                      );
                      if (handleCloseDrawer) handleCloseDrawer();
                    } else if (notification?.type === "Task") {
                      handleTask(notification?.task);
                      handleOpenNewDialog();
                      // navigate(
                      //   project_backlog_tasks.replace(
                      //     "/:tskName/:tskID",
                      //     `/${encodeURIComponent(
                      //       notification?.task?.title
                      //     )}/${encodeURIComponent(notification?.task?.taskGuid)}`
                      //   )
                      // );
                      if (handleCloseDrawer) handleCloseDrawer();
                    }
                  }}
                >
                  {notification.notificationText.length > 250
                    ? stripHtmlTags(notification?.notificationText).slice(
                        0,
                        250
                      ) + "..."
                    : stripHtmlTags(notification?.notificationText)}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiMenuItem-root": {
                  fontSize: "12px", // Adjust the font size as needed
                },
                "& .MuiPaper-root": {
                  width: "140px", // Adjust the width of the Menu as needed
                  boxShadow:
                    "rgba(0, 0, 0, 0.02) 1.95px 1.95px 2.6px !important",
                },
              }}
            >
              <MenuItem
                onClick={() => handleMenuClick("Mark as read", notifGuid)}
              >
                {t("notif.markAsRead")}
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick("Delete", notifGuid)}>
                {t("delete")}
              </MenuItem>
            </Menu>{" "}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
