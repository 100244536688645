import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    title,
    description,
    status,
    company,
    location,
    client ,
    synced,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [synced.name]: Yup.bool().nullable(),
    [title.name]:Yup.string().required(title.requiredMessage),
    [description.name]:Yup.string(description.requiredMessage),
    [status.name]:Yup.object().required(status.requiredMessage),
    [company.name]:Yup.object().required(company.requiredMessage),
    [EstimatedEndDate.name]: Yup.date().nullable(),
    [EstimatedStartDate.name]: Yup.date().nullable(),
    // .typeError(EstimatedStartDate.invalidMessage),

    [EstimatedTime.name]: Yup.string().nullable(),
    // .typeError(EstimatedTime.invalidMessage),
    [ActualEndDate.name]: Yup.date().nullable(),
    // .typeError(ActualEndDate.invalidMessage),
    [ActualStartDate.name]: Yup.date().nullable(),
    // .typeError(ActualStartDate.invalidMessage),
    [ActualTime.name]: Yup.string().nullable(),
  }),
];

export default validation;