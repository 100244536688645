import React from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, Typography, Grid } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";
import BadgeDot from "../../../../../components/StatusCell/BadgeDot";

const SideInfo = ({ data, status }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box lineHeight={0} className="flex_space-between_center">
        <Title>{t("tenantForm.sideInfo")}</Title>
        {status && <BadgeDot color={status.color} text={status.text} />}
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {data.map((info) => (
            <Grid item xs={12} key={info[0]}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={5}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography variant="caption">{t(info[0] || "")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Typography variant="caption" fontWeight="bold">
                    {info[1] || ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SideInfo;
