import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const TaskStatus = (props) => {
  const { status } = props;
  const { taskStateColors } = colorsLight;

  if (!status) return null;
  return (
    <span
      style={{
        padding: "5px 20px",
        borderRadius: "5px",
        fontFamily: "poppins  !important",
        display: "inline-block",
        width: "100%",
        ...taskStateColors[status?.taskState?.mnemonic],
      }}
    >
      {status?.description}
    </span>
  );
 
};

export default TaskStatus;