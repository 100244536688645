import React, { useEffect, useState } from "react";

//routing
import { Outlet } from "react-router-dom";

//components
import SideNav from "../components/SideNav";
import BoxContainer from "../components/BoxContainer";
import Navbar from "../components/NavBar";

//stylings
import "./styles.css";

//routes for list
import privateRoutes from "../private.routes";
import useAuth from "../hooks/useAuth";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import useLazyQueryAuth from "../hooks/useLazyQueryAuth";
import { CHECK_VERSION } from "../graphql/user";
import { useDispatch } from "react-redux";
import { updateUserView } from "../app/reducers/userSlice";

const DefaultLayout = () => {
  const dispatch = useDispatch();
  const [miniSidenav, setMiniSidenav] = useState(false);
  const { isTrial, storeUserGuid, version } = useAuth();

  const { mfunction: CheckToken, loading: checkingLoading, tokenChange: versionWait } = useLazyQueryAuth(
    CHECK_VERSION,
    "CheckVersion"
  );

  useEffect(() => {
    CheckToken(
      {
        variables: {
          model: {
            userId: storeUserGuid,
            version: version,
          },
        },
      },
      (response) => {
        const userView = response?.CheckVersion?.userView
        if(userView !== null) {
          dispatch(updateUserView({userView: userView.user, version: userView.version}));
        }
      }
    );
  }, []);
  return (
    <div
      className="default-layout"
      style={{ height: isTrial ? "calc(100vh - 20px)" : "100%" }}
    >
      <div>
        <div className="side-navbar">
          <SideNav
            setMiniSidenav={setMiniSidenav}
            miniSidenav={miniSidenav}
            list={privateRoutes}
          />
        </div>
        <BoxContainer
          ownerState={{ miniSidenav }}
          style={{ overflowX: "hidden", marginTop: 0, marginRight: 0 }}
        >
          <Navbar open={miniSidenav} setOpen={setMiniSidenav} />
          {checkingLoading || versionWait ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
             <div
              style={{
                width: "100%",
                padding: "13px 40px 0 10px",
                marginLeft: "17px",
              }}
            >
              <Outlet />
            </div>)}
          {/* )} */}
        </BoxContainer>
      </div>
    </div>
  );
};

export default DefaultLayout;
