import { gql } from "@apollo/client";


export const SYSTEM_ERRORS_LIST=gql`
query ($model: BackendErrorLogsGetRequestInput!) {
    GetBackendErrorLogs(model: $model) {
      backendErrorLogsListResponse {
        errorLogs {
          errorLogGuid
          errorCode
          errorMessage
          createdAt
          functionName
          model
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`