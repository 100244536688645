import React, { useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

//from components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import "./style.css";
import ConfirmationPopup from "../Popup";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ TabHeaders, dashboard, removeTabHeader, t, ...rest }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [newValueState, setNewValueState] = useState(null);

  const location = useLocation();

  const navigate = useNavigate();

  const { pathname } = location;

  //used to have the Tab value dynamic at all times
  const value = TabHeaders.findIndex((x) => x.Link === pathname);

  const handleChange = (event, newValue) => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges"))) {
      setNewValueState(newValue);
      setOpenDialog(true);
    }
    //we take the user to the page defined by the Tab
    else navigate(TabHeaders[newValue].Link);
  };

  //styles apply to tabs component
  const TABS_STYLES = {};

  //styles to apply to tab component
  const TAB_STYLES = {
    fontFamily: "poppins",
    textTransform: "none",
  };

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    navigate(TabHeaders[newValueState].Link);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      {dashboard ? (
        <Typography sx={{ mt: "5px", fontSize: "26px", fontWeight: "600" }}>
          {t("dashboardTabs.dashboard")}
        </Typography>
      ) : removeTabHeader ? <></> : (
        <Tabs
          value={value}
          onChange={handleChange}
          variant={dashboard ? "standard" : "scrollable"}
          orientation="horizontal"
          TabIndicatorProps={{ style: TABS_STYLES }}
        >
          {TabHeaders.map((tab, index) => {
            return (
              <Tab
                key={tab.key}
                label={tab.Header}
                {...a11yProps({ index })}
                style={
                  tab.display
                    ? { ...TAB_STYLES, display: tab.display }
                    : TAB_STYLES
                }
              />
            );
          })}
        </Tabs>
      )}
      <div style={{ height: removeTabHeader ? "initial" : "calc(100vh - 165px)" }}>
        <Outlet {...rest} />
      </div>
    </Box>
  );
}

BasicTabs.defaultProps = {
  TabHeaders: [],
};
