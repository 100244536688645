import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

import { DELETE_SKILLS_LIST, GET_ALL_SKILLS } from "../../../../graphql/skill";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const EmployeeSkillsList = ({
  fromTenant = false,
  fromEmployee = false,
  handleClick = () => {},
  initialVal = [],
}) => {
  const { tID, tName, eID } = useParams();

  const {
    tenant_skills_form,
    new_tenant_skill,
    my_skills_form,
    new_my_skill,
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isEntelligence } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState(fromEmployee ? initialVal : []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_SKILLS_LIST,
    "DeleteSkillsList"
  );

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.SkillsList,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllSkills, loading } = useLazyQueryAuth(
    GET_ALL_SKILLS,
    "GetAllSkills"
  );

  const fetchTableData = useCallback(
    (page = 0, pageSize = 0, order = "", orderBy = "", search = "") => {
      getAllSkills(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyWord: search,
              tenantGuid: isEntelligence ? tID : storeUserTenant,
              employeeID: eID,
              unassignedSkills: true
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait) fetchTableData(page, rowsPerPage, order, orderBy, search);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllSkills &&
    data.GetAllSkills.skillsList &&
    data.GetAllSkills.skillsList.skills
      ? data.GetAllSkills.skillsList.skills
      : [];
  const numberOfRecords =
    data &&
    data.GetAllSkills &&
    data.GetAllSkills.skillsList &&
    data.GetAllSkills.skillsList.totalNumberOfRecords
      ? data.GetAllSkills.skillsList.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            skills: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("skills.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const customButtons = [
    {
      label: "employeeForm.skillList.addSkill",
      handleClick: () => handleClick(selected),
      condition: selected && selected.length > 0 && fromEmployee && manageUsers === "M",
    }
  ];
  
  const pagination = {
    rows,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    removeFilters: true,
    loading: loading || columnsWait,
    searchText: "skill.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.SkillsList,
    ...(!fromEmployee && {
      generateRoute: (row) =>
        fromTenant
          ? tenant_skills_form
              .replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
              .replace(
                "/:ptoName/:ptoID",
                `/${encodeURIComponent(row?.description)}/${encodeURIComponent(
                  row?.ptoGuid
                )}`
              )
          : my_skills_form.replace(
              "/:ptoName/:ptoID",
              `/${encodeURIComponent(row?.description)}/${encodeURIComponent(
                row?.ptoGuid
              )}`
            ),
      newText: "ptos.list.new.label",
      newLink: fromTenant
        ? new_tenant_skill.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
        : new_my_skill,
      deleteText: "skills.list.delete.label",
      handleDelete: handleDelete,
      deleteLoading,
    }),
    hideEditColumns: true,
    readOnly: !fromEmployee && manageUsers !== "M",
    customButtons,
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"skillGuid"}
          hasCheckbox
          fullObject={fromEmployee}
        />
      )}
    </div>
  );
};

export default EmployeeSkillsList;
