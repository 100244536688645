import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
//components
import { CircularProgress, Modal, IconButton } from "@mui/material";
import DataTable from "../../../../components/Tables/DataTable";
import TeamTable from "../Employee-form/components/TeamsTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useAuth from "../../../../hooks/useAuth";

import TeamEmployeesList from "../../Teams/Team-Employees-list";

//graphql
import {
  GET_EMPLOYEE_TEAMS,
  ASSIGN_TEAMS_TO_EMPLOYEE,
  REMOVE_TEAM,
} from "../../../../graphql/team";

//mnemonic values
import { getTable } from "./_table";

//snackbar
import { useSnackbar } from "notistack";

//translation
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../../components/Icon";

const EmployeeTeamsList = ({ refetchData = false, fromProfile = false }) => {
  const teams = [];

  const { eID, tID, profileID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isEntelligence } = userRoles;

  const [selectedStatus, setSelectedStatus] = useState("");

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [displayTable, setDisplayTable] = useState(false);

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [displayTeam, setDisplayTeam] = useState(false);
  const [teamGuid, setTeamGuid] = useState(null);

  const filtersObject = [
    {
      type: "text",
      label: "employeeTeams.list.filters.description",
      name: "description",
    },
  ];

  const { mfunction: assignTeamstoEmployee } = useMutationAuth(
    ASSIGN_TEAMS_TO_EMPLOYEE,
    "AssignEmployeeToTeams"
  );

  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    REMOVE_TEAM,
    "RemoveAssignedTeamsForEmployee"
  );

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();

      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const { mfunction: getAllEmployeeTeams, loading } = useLazyQueryAuth(
    GET_EMPLOYEE_TEAMS,
    "GetAllEmployeeTeams"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployeeTeams(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyword: search,
              employeeGuid: !fromProfile ? eID : profileID,
              orderColumn: orderBy,
              orderDirection: order,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if(columns.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [refetchData, columns.length, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllEmployeeTeams &&
    data.GetAllEmployeeTeams.teamEmployeesList &&
    data.GetAllEmployeeTeams.teamEmployeesList.teams
      ? data.GetAllEmployeeTeams.teamEmployeesList.teams
      : [];
  const numberOfRecords =
    data &&
    data.GetAllEmployeeTeams &&
    data.GetAllEmployeeTeams.teamEmployeesList &&
    data.GetAllEmployeeTeams.teamEmployeesList.totalNumberOfRecords
      ? data.GetAllEmployeeTeams.teamEmployeesList.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "employeeTeamForm.addTeam",
      handleClick: () => setDisplayTable(true),
      condition: (!selected || selected.length === 0) && manageUsers === "M",
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important"
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (selected && selected.length === 0) setSelectedStatus("");
  }, [selected]);

  function handleDelete() {
    deleteRecord(
      {
        variables: {
          model: {
            employeeGuid: eID,
            teamsIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("teams.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  }

  const handleOpenModal = (row) => {
    setTeamGuid(row?.team?.teamGuid);
    setDisplayTeam(true);
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "employeeTeams.list.search.label",
    filtersObject,
    hideEditColumns: true,
    readOnly: manageUsers !== "M",
    customButtons: eID ? customButtons : !customButtons,
    removeFilters: true,
    generateRoute: () => {},
    onCheckbox: (value, row, selected) => {
      if (value === true && selected.length === 0) {
        setSelectedStatus(row?.employeeTeamGuid?.mnemonic || "");
      }
    },
    removeSelectAll: !selected || selected.length === 0,
    checkboxDisable: (row) => {
      const status = row?.employeeTeamGuid?.mnemonic || "";

      return selectedStatus && status !== selectedStatus;
    },
    deleteText: "teams.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    handleOpenModal: (row) => handleOpenModal(row),
  };

  function handleAddTeam(array) {
    const teamsGUIDs = array
      ? array.map((val, index) => {
          return val.teamGuid;
        })
      : [];

    assignTeamstoEmployee(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            teamsIDs: teamsGUIDs,
            employeeGuid: eID,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("assignTeamsToEmployee.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        setDisplayTable(false);
      }
    );
  }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setDisplayTeam(false);
        setTeamGuid(null);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  return (
    <div>
      {displayTable ? (
        <TeamTable
          display={displayTable}
          tenant={isEntelligence ? tID : storeUserTenant}
          initialVal={teams}
          handleClick={handleAddTeam}
          setDisplay={setDisplayTable}
        />
      ) : null}
      <Modal
        open={displayTeam}
        onClose={() => setDisplayTeam(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ContainerCard
          sx={{ padding: "10px 20px 20px", width: "70%", height: "fitContent" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "1rem",
              width: "100%",
            }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setDisplayTeam(false)}
              aria-label="close"
            >
            <CloseIcon color={"#000000"} width={'20'} stroke={'1'} />
            </IconButton>
          </div>
          <TeamEmployeesList teamGuid={teamGuid} fromProfile />
        </ContainerCard>
      </Modal>
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier="team.teamGuid"
          hasCheckbox={eID ? true : false}
          fromEmployee
        />
      )}
    </div>
  );
};

export default EmployeeTeamsList;
