import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";

//Firebase Config values imported from .env file
const firebaseConfig = {
    apiKey: "AIzaSyBQg8Lido5BBlp1wOmPi99dilHeTjxmAU4",
    authDomain: "activ-7b264.firebaseapp.com",
    projectId: "activ-7b264",
    storageBucket: "activ-7b264.appspot.com",
    messagingSenderId: "37431347657",
    appId: "1:37431347657:web:6a332187f05834f8798018",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);