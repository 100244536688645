import StatusCell from "./components/StatusCell";
import IconBadge from "./components/StatusCell/IconBadge";
import MarkBadge from "./components/StatusCell/MarkBadge";
import CustomStatus from "./components/StatusCell/CustomStatus";
import EmployeeStatus from "./components/StatusCell/EmployeeStatus";
import TaskState from "./components/StatusCell/TaskState";
import TicketStatus from "./components/StatusCell/TicketStatus";
import TaskStatus from "./components/StatusCell/TaskStatus";
import SuggestionStatus from "./components/StatusCell/SuggestionStatus"
import Status from "./components/StatusCell/Status";
import RequestDateTimeHandler from "./utils/_request.datetime";
import LongText from "./components/Text/LongText";
import FloatNumber from "./components/Text/FloatNumber";
import ReactParseHtml from "./components/Text/ReactParseHtml";
import EditRecord from "./pages/admin-configuration/Timesheets/Timesheets-Form/components/EditRecord";
import CheckStatus from "./components/StatusCell/CheckStatus";
import FlagBadge from "./components/FlagBadge";
import ProgressBar from "./components/ProgressBar";
import TimeStatus from "./components/TimeStatus";
import QuantityUsed from "./components/QuantityUsed";
import ProjectStatus from "./components/StatusCell/projectStatus";
import BackgroundStatus from "./components/StatusCell/backgroundStatus";
import RequestStatus from "./components/StatusCell/RequestStatus";
import AnonymousUser from "./components/TableCell/AnonymousUser";
import AnonymousEmail from "./components/TableCell/AnonymousEmail";
import StripHtmlTags from "./components/Text/StripHtmlTags";
import ResponsiveBackgroundStatus from "./components/StatusCell/ResponsiveBackgroundStatus";
import DateTime from "./components/DateTime";

import TimesheetStatus from "./components/StatusCell/TimesheetStatus";

const componentDisplay = ({ compName, compProps, length }) => {
    let ComponentName;
    let props = null;

    switch (compName) {
        case "StatusCell":
            props = compProps;
            ComponentName = StatusCell;
            break;
        case "IconBadge":
            props = compProps;
            ComponentName = IconBadge;
            break;
        case "DateTime":
            props = compProps;
            ComponentName = DateTime;
            break;
        case "MarkBadge":
            props = compProps;
            ComponentName = MarkBadge;
            break;
        case "CustomStatus":
            props = compProps;
            ComponentName = CustomStatus;
            break;
        case "ProjectStatus":
            props = compProps;
            ComponentName = ProjectStatus;
            break;
        case "TimesheetStatus":
            props = compProps;
            ComponentName = TimesheetStatus;
            break;
        case "RequestDateTimeHandler":
            props = compProps;
            ComponentName = RequestDateTimeHandler;
            break;
        case "EmployeeStatus":
            props = compProps;
            ComponentName = EmployeeStatus;
            break;
        case "TaskState":
            props = compProps;
            ComponentName = TaskState;
            break;
        case "BackgroundStatus":
            props = compProps;
            ComponentName = BackgroundStatus;
            break;

        case "ResponsiveBackgroundStatus":
            props = compProps;
            ComponentName = ResponsiveBackgroundStatus;
            break;
        case "TicketStatus":
            props = compProps;
            ComponentName = TicketStatus;
            break;
        case "RequestStatus":
            props = compProps;
            ComponentName = RequestStatus;
            break;
        case "TicketStatus":
            props = compProps;
            ComponentName = TicketStatus;
            break;
        case "TaskStatus":
            props = compProps;
            ComponentName = TaskStatus;
            break;
        case "SuggestionStatus":
            props = compProps;
            ComponentName = SuggestionStatus;
            break;
        case "StripHtmlTags":
            props = { text: compProps, length };
            ComponentName = StripHtmlTags;
            break;
        case "AnonymousUser":
            props = compProps;
            ComponentName = AnonymousUser;
            break;
        case "AnonymousEmail":
            props = compProps;
            ComponentName = AnonymousEmail;
            break;
        case "Status":
            props = compProps;
            ComponentName = Status;
            break;
        case "LongText":
            props = { text: compProps, length };
            ComponentName = LongText;
            break;
        case "ReactParseHtml":
            props = { text: compProps };
            ComponentName = ReactParseHtml;
            break;
        case "FloatNumber":
            props = { number: compProps };
            ComponentName = FloatNumber;
            break;
        case "EditRecord":
            props = compProps;
            ComponentName = EditRecord;
            break;
        case "CheckStatus":
            props = compProps;
            ComponentName = CheckStatus;
            break;
        case "FlagBadge":
            props = compProps;
            ComponentName = FlagBadge;
            break;
        case "ProgressBar":
            props = { total: compProps };
            ComponentName = ProgressBar;
            break;
        case "TimeStatus":
            props = { timeStatus: compProps };
            ComponentName = TimeStatus;
            break;
        case "QuantityUsed":
            props = compProps;
            ComponentName = QuantityUsed;
            break;
        default:
            ComponentName = "p";
            break;
    }

    return <ComponentName {...props} />;
};
export default componentDisplay;
