import React, { useState, useEffect, useMemo } from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import { Grid } from "@mui/material";

//components
import DataCard from "../../../../components/Cards/DataCard";
//import UserInfo from "./user-info";

//data
import _tableData from "./_votersList";
import InfoDrawer from "../../../../components/InfoDrawer";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

import { useTranslation } from "react-i18next";

const VotersList = ({ getByID, getDataByIDLoading }) => {
  const [loading, setLoading] = useState(false); //set true when we wish to start lazy loading
  const { t } = useTranslation();
  const { title, headers, render } = _tableData; //holds card title, table headers and the display of data

  const { userRoles, storeUserTenant } = useAuth();
  const { isEntelligence } = userRoles;

  const [votes, setVotes] = useState(0);
  const [voters, setVoters] = useState([]);


   useEffect(()=>{
    if(getDataByIDLoading){
  setLoading(true)
    }else{
      setVoters(getByID?.suggestion?.suggestionUpvotes || []); // Access suggestionUpvotes array, or use an empty array if it's not available
    setVotes(getByID?.totalVotes || 0); 
    }
   },[getDataByIDLoading])

  useEffect(() => {
    if (voters && voters.length > 0) setLoading(false);
  }, [voters]);

  function fetchData() {
    //function that gets records on lazy load

    if (getDataByIDLoading) return; //data is already being fetched, we wait for it to at least be fetched

    if (voters.length >= votes) return; //if data has reached its max

  }

  const stopLazyLoad = voters.length >= votes; //if all records have been fetched we no longer wish to lazy load



  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DataCard
          id="dashboard-clockins-modal"
          title={title}
           stopLazyLoad={stopLazyLoad}
           onLazyLoad={fetchData}
          tableHeaders={headers}
          render={render}
          data={voters}
          initialLoad={!(getDataByIDLoading && votes === 0)}
          loading={loading}
          setLoading={setLoading}
          // additionalFunctionality={extraFunction}
          // clickable
          giveMax
        />
      </Grid>

    </Grid>
  );
};

VotersList.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch: PropTypes.bool,
};

VotersList.defaultProps = {
  initialLoad: false,
  data: {},
  next: () => { },
  waitBatch: false,
};

export default VotersList;




