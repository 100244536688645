import React from "react";

//external component
import { TextField as MuiTextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const TextField = (props) => {
  const {
    name,
    label,
    variant,
    disabled,
    required,
    removePadding,
    removeLabel,
    formData = {},
    textFieldLabel,
    Tooltip,
    tootlipTitle,
  } = props;
  const { touched, errors, setTouched } = formData;
  const { t } = useTranslation();

  return Tooltip != null && Tooltip !== undefined ? (
    <Tooltip title={tootlipTitle}>
      <MuiTextField
        required={required}
        disabled={disabled}
        error={(touched[name] && errors[name]) || false}
        helperText={(touched[name] && errors[name] && t(errors[name])) || null}
        onBlur={() =>
          setTouched({
            ...touched,
            [name]: true,
          })
        }
        label={removeLabel ? null : textFieldLabel ? textFieldLabel : label}
        variant={variant ? variant : "standard"}
        sx={
          removePadding === true
            ? {
                ".MuiOutlinedInput-root": { padding: "0 !important" },
                "& .MuiFormLabel-root": {
                  fontSize: "14px !important",
                },
                "& .MuiInputBase-input": {
                  fontSize: "14px !important",
                },
              }
            : {
                "& .MuiFormLabel-root": {
                  fontSize: "14px !important",
                },
                "& .MuiInputBase-input": {
                  fontSize: "14px !important",
                },
              }
        }
        {...props}
      />
    </Tooltip>
  ) : (
    <MuiTextField
      required={required}
      disabled={disabled}
      error={(touched[name] && errors[name]) || false}
      helperText={(touched[name] && errors[name] && t(errors[name])) || null}
      onBlur={() =>
        setTouched({
          ...touched,
          [name]: true,
        })
      }
      label={removeLabel ? null : textFieldLabel ? textFieldLabel : label}
      variant={variant ? variant : "standard"}
      sx={
        removePadding === true
          ? {
              ".MuiOutlinedInput-root": { padding: "0 !important" },
              "& .MuiFormLabel-root": {
                fontSize: "14px !important",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px !important",
              },
            }
          : {
              "& .MuiFormLabel-root": {
                fontSize: "14px !important",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px !important",
              },
            }
      }
      {...props}
    />
  );
};

export default TextField;
