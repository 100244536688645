import componentDisplay from "../../../../../componentDisplay";

export const getTable = () => {
    return {
      columns: [
        {
          id: "projectName",
          label: "exportExcel.list.column.projectName",
          align: "left",
          width:"30vw",
          visible: true,
          renderCell: (row) => {
            return row?.task?.ticket?.project?.title;
          },
        },
        {
          id: "taskTitle",
          label: "exportExcel.list.column.taskTitle",
          width:"30vw",
          align: "left",
          visible: true,
          renderCell: (row) => {
            return row?.task?.title;
          },
        },
       
      ],
      defaultSort: "employee",
      defaultSortDirection: "asc",
    };
  };
