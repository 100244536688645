import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";
import {  Navigate } from "react-router-dom";

import urls from "../../../../urls";

import useAuth from "../../../../hooks/useAuth";

function ReportsTabs() {
  const { userRoles, timeEntryEnabled, attendanceEnabled } = useAuth();

  const { manageTimeEntry, manageAttendance } = userRoles;

  const { t } = useTranslation();

  const {
    reports_employee_attendance_sheet,
    reports_attendance_by_employee,
    reports_time_by_contractor,
    reports_employee_timesheet,
    reports_time_by_employee,
    reports_time_by_external,
    reports_time_by_project,
    reports_excel,
  } = urls;

  const TabLinks = [
    // ...(timeEntryEnabled === true
    //   ? [
          {
            key: 1,
            Header: t("reportTabs.employeeTimesheet"),
            Link: reports_employee_timesheet,
          },
    ...(manageTimeEntry !== "N"
      ? [
          {
            key: 4,
            Header: t("reportTabs.timeByEmployee"),
            Link: reports_time_by_employee,
          },
          {
            key: 5,
            Header: t("reportTabs.timeByProject"),
            Link: reports_time_by_project,
          },
          {
            key: 6,
            Header: t("reportTabs.timeByContractor"),
            Link: reports_time_by_contractor,
          },
          {
            key: 7,
            Header: t("reportTabs.timeByExternal"),
            Link: reports_time_by_external,
          },
          {
            key: 8,
            Header: t("reportTabs.exportToExcel"),
            Link: reports_excel,
          },
          //   ]
          // : []),
        // ...(attendanceEnabled === true
        //   ? [
          ...(attendanceEnabled === true
          ? [
              {
                key: 2,
                Header: t("reportTabs.employeeAttendanceSheet"),
                Link: reports_employee_attendance_sheet,
              },
            ]
          : []),
          //   ]
          // : []),
        ...(manageAttendance !== "N"
          ? [
              {
                key: 3,
                Header: t("reportTabs.attendanceByEmployee"),
                Link: reports_attendance_by_employee,
              },
            ]
          : []),
        ]
      : []),
  ];

  if (window.location.href.split("/")[6] == undefined)
    return timeEntryEnabled === true ? (
      <Navigate to={reports_employee_timesheet} replace />
    ) : (
      <Navigate to={reports_employee_attendance_sheet} replace />
    );

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default ReportsTabs;
