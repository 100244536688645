import axios from "axios";
import { store } from "../app/store";

const apiCall = async (
  url,
  method,
  data,
  logsOutUser,
  logout,
  saveToken,
  enqueueSnackbar,
  t,
  token = null,
  responseType
) => {
  const jwtToken = store.getState()?.token?.token;

  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        Authorization: `${
          token ? "Bearer " + token : jwtToken ? "Bearer " + jwtToken : ""
        }`,
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
      responseType: responseType,
    });
    return response;
  } catch (error) {
    console.log(error);
    const errorCode = error?.response?.status || "";
    if (errorCode === 401) {
      //if Unauthorized, we logout the user
      logsOutUser(
        {
          variables: {
            model: {
              jwtToken: jwtToken,
            },
          },
        },
        () => {
          logout();
        }
      );
    } else if (errorCode === 400) {
      const desc = error?.response?.data || {};

      if (desc.error?.error === "IXIA_ACTIV_NEW_TOKEN") {
        sessionStorage.removeItem("unsavedChanges");
        saveToken(desc.error.errorVariable.trim());
        return apiCall(
          url,
          method,
          data,
          logsOutUser,
          logout,
          saveToken,
          enqueueSnackbar,
          t,
          desc.error.errorVariable.trim()
        );
      } else if (desc.error?.error === "IXIA_ACTIV_UNAUTHORIZED") {
        sessionStorage.removeItem("unsavedChanges");
        enqueueSnackbar(t(error?.response?.data?.message || ""), {
          variant: "error",
          autoHideDuration: 5000,
        });
        logsOutUser(
          {
            variables: {
              model: {
                jwtToken: jwtToken,
              },
            },
          },
          () => {
            logout();
          }
        );
      } else {
        enqueueSnackbar(t(error?.response?.data?.message || ""), {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    } else {
      enqueueSnackbar(t(error?.response?.data?.message || ""), {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
    return null;
  }
};

export default apiCall;
