import checkout from "./form";

const {
  formField: { name, tenant, version, externalReference, connectionString, clientId, secretId, tenantId },
} = checkout;

const initialValues = {
  [name.name]: "",
  [tenant.name]:null,
  [externalReference.name]:"",
  [connectionString.name]:"",
  [version.name]:null,
  [clientId.name]:"",
  [secretId.name]:"",
  [tenantId.name]:""
};

export default initialValues;