import { useState, useEffect } from "react";

import "./card.css";

import PropTypes from "prop-types";

import { Modal, IconButton, Skeleton } from "@mui/material";

import ContainerCard from "./ContainerCard";
import colors from "../../assets/theme/light/colors";

import { CloseIcon } from "../Icon";

const QuickInfoCard = ({
  icon,
  amount,
  label,
  icon2,
  color,
  loading,
  skeleton,
  table,
  noIcon,
  isData,
  buttonRef,
  component,
  title,
  upcoming,
}) => {
  const [open, setOpen] = useState(false);
const {grey_background}=colors;
  const handleOpen = () => {
    if (!loading) setOpen(true) 
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (isData) {
    return (
      <>
        <Modal
          open={open}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ContainerCard
            sx={{ padding: "20px" , backgroundColor:grey_background, 
            maxHeight: "80vh !important"}}
            className="quick-info-card-container-2"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding : "0px 10px 0px 18px",

              }}
            >
              <span style={{ color:  color}} className="modal-header">
               { open ? icon2 : icon}
                <h4 style={{paddingLeft: "10px" }}>{title || label}</h4>
              </span>
              <IconButton onClick={handleClose}>
                <CloseIcon color={"#000000"} width={'20'} stroke={'1'}/>
              </IconButton>
            </div>
            <div style={{ padding: "20px" }}>{table}</div>
          </ContainerCard>
        </Modal>
        {loading ? skeleton ? Array.from({ length: skeleton }, (_, index) => (
          <Skeleton key={index} variant="text" sx={{ fontSize: "1rem" }} /> )
        ) : (
          <div>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </div>
        ) : (
          <div>
            <button style={{display:"none"}} onClick={handleOpen} ref={buttonRef}></button>
            {component}
          </div>
        )}
      </>
    );
  }

  if (noIcon) {
    return (
      <>
        <Modal
          open={open}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

          }}
        >
          <ContainerCard
            sx={{ padding: "20px", backgroundColor:grey_background }}
            className="quick-info-card-container-2"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding : "0px 10px 0px 18px",

              }}
            >
              <span style={{color:  color}} className="modal-header">
              { open ? icon2 : icon}
                <h4 style={{paddingLeft: "10px" }}>{title || label}</h4>
              </span>
              <IconButton onClick={handleClose}>
                <CloseIcon color={"#000000"} width={'20'} stroke={'1'}/>
              </IconButton>
            </div>
            <div style={{ padding: "20px" }}>{table}</div>
          </ContainerCard>
        </Modal>
        {loading ? null : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: upcoming ? "10px" : "15px",
            }}
            className="quick-info-card-line"
          >
            <p className="quick-info-link" onClick={handleOpen}>
              {label}
            </p>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Modal
        open={open}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ContainerCard
          sx={{ padding: "20px" , backgroundColor:grey_background}}
          className="quick-info-card-container-2"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding : "0px 10px 0px 18px",
            }}
          >
            <span style={{ color:  color}} className="modal-header">
            { open ? icon2 : icon}
              <h4 style={{paddingLeft: "10px" }}>{title || label}</h4>
            </span>
            <IconButton onClick={handleClose}>
                <CloseIcon color={"#000000"} width={'20'} stroke={'1'}/>
            </IconButton>
          </div>
          <div style={{ padding: "20px" }}>{table}</div>
        </ContainerCard>
      </Modal>
      {loading ? skeleton ? Array.from({ length: skeleton }, (_, index) => (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} /> )
      ) : (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            marginTop: upcoming ? "8px" : "15px",
          }}
          className="quick-info-card-line"
          onClick={handleOpen}
        >
          {icon}

          <p className="quick-info-text">
            {amount} {label}
          </p>
        </div>
      )}
    </>
  );
};

export default QuickInfoCard;

QuickInfoCard.propTypes = {
  icon: PropTypes.node,
  icon2: PropTypes.node,
  amount: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  clicked: PropTypes.bool,
  open: PropTypes.func,
  table: PropTypes.node,
  noIcon: PropTypes.bool,
  isData: PropTypes.bool,
  component: PropTypes.node,
  title: PropTypes.string
};

QuickInfoCard.defaultProps = {
  icon: <></>,
  icon2: <></>,
  amount: 0,
  label: "",
  color: "",
  loading: false,
  clicked: false,
  open: () => {},
  table: <></>,
  noIcon: false,
  isData: false,
  component: <></>,
  title: "",
};
