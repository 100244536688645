import LongText from "../../../../components/Text/LongText";
import UTC2Local from "../../../../UTC2Local";

export const getDataTableData = (t) => {

    return {
      columns: [
        {
            id: "Resource",
            label: "projectPermission.list.column.resource",
            align: "left",
            visible: true,
            renderCell: (row) => {
              return (row?.resource?.firstName || "") + " " + (row?.resource?.lastName || "")
            },
          },
      ],
    };
  };
  