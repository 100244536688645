import React, { useState, useCallback, useEffect } from "react";

//routing

//translation
import { useTranslation } from "react-i18next";

//component
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { getDataTableData } from "./_table";

import DataTable from "../../../../components/Tables/DataTable";
import { SYSTEM_ERRORS_LIST } from "../../../../graphql/systemErrors";

function Users() {

  const {  userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;


  const { t } = useTranslation();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newObj, setNewObj] = useState({
    errorCode: "",
    errorMessage: "",
    date: "",
   
  });
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const [data, setData] = useState(null);

  
  const { mfunction: getAllErrors, loading } = useLazyQueryAuth(
SYSTEM_ERRORS_LIST,
    "GetBackendErrorLogs"
  );

  useEffect(() => {
    const col = getDataTableData(t);
    if (col) {
      setColumns(col.columns);
      setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
    }
  }, [columns?.length]);

 

  const fetchTableData = useCallback((page = 0,
    pageSize = 0,
    order = "",
    orderBy = "",
    search = "",
    filters = null) => {
    getAllErrors(
      {
        variables: {
            model: {
                orderColumn: orderBy,
                orderDirection: order,
                keys:{
                  page: page + 1,
                  pageSize: pageSize,
                },
              }
        },
       
      },
      (response) => {
        setData(response);
        setRows(response?.GetBackendErrorLogs?.backendErrorLogsListResponse?.errorLogs);
      }
    );
  }, []);

  
  const records =
    data &&
    data.GetBackendErrorLogs &&
    data.GetBackendErrorLogs.backendErrorLogsListResponse?.errorLogs ?
    data.GetBackendErrorLogs?.backendErrorLogsListResponse?.errorLogs
      : [];

  const numberRecords =
    data &&
    data.GetBackendErrorLogs &&
    data.GetBackendErrorLogs?.backendErrorLogsListResponse &&
    data.GetBackendErrorLogs?.backendErrorLogsListResponse.totalNumberOfRecords
      ? data.GetBackendErrorLogs?.backendErrorLogsListResponse.totalNumberOfRecords
      : 0;

useEffect(() => {
  if(columns.length > 0) 
  fetchTableData(
    page,
    rowsPerPage,
    order,
    orderBy,
    search,
    {}
  );
}, [columns.length, fetchTableData])

  const pagination = {
    rows: rows?.length == 0 ? records : rows,
    setRows,
    newObj,
    setNewObj,
    columns,
    selected,
    setSelected,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    removeFilters: true,
    hideEditColumns: true,
    hideSearchBar: true,

    tableMnemonic: Mnemonic.Tables["errors"],
 
    readOnly: manageSystemConfigurations !== "M",
  
   
  };

  return (
    <div>
   
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && columns.length > 0 && (
          <DataTable
            {...pagination}
            identifier="errorLogGuid"
            
          />
        )
      )}
    </div>
  );
}

export default Users;
