import React from "react";
import colors from "../../assets/theme/light/colors";
import LongText from "../Text/LongText";

const BackgroundStatus = (props) => {

  const { size, row, length, widthAuto, tree } = props;
  if (!props) return null;

  let colorDesc, colorCo; 

  if (tree !== null) {
    const mainTaskStatus = row?.task?.status;
    // const subtaskStatus = row?.subtasks?.map(subtask => subtask.task?.status) || [];
    // const cellValue = [mainTaskStatus, ...subtaskStatus?.map(() => '')];

    colorDesc = mainTaskStatus?.description;
    colorCo = mainTaskStatus?.statusColor?.colorCode;
  } else {
    return;
  }

  const getColorAndBackground = (mnemonic) => {
    if (
      colors.projectColors[mnemonic] ||
      colors.EmployeeOnBoardingTasks[mnemonic]
    ) {
      return {
        color:
          colors.projectColors[mnemonic]?.color ||
          colors.EmployeeOnBoardingTasks[mnemonic]?.color,
        backgroundColor:
          colors.projectColors[mnemonic]?.backgroundColor ||
          colors.EmployeeOnBoardingTasks[mnemonic]?.backgroundColor,
      };
    } else if (row?.status === null || row?.status?.mnemonic == null) {
      return {
        color:
          colors.projectColors.new.color ||
          colors.EmployeeOnBoardingTasks.new.color,
        backgroundColor:
          colors.projectColors.new?.backgroundColor ||
          colors.EmployeeOnBoardingTasks.new?.backgroundColor,
      };
    }
  };

  const code =
    props?.status?.statusColor?.colorCode || colorCo ||
    props?.row?.status?.statusColor?.colorCode || props?.color;

  const colorCode =
    code || colorDesc ||
    getColorAndBackground(props?.row?.status?.mnemonic || props?.mnemonic)
      ?.color;

  const backgroundColor = code
    ? `${code}40`
    : getColorAndBackground(props?.row?.status?.mnemonic || props?.mnemonic)
      ?.backgroundColor;

  return (
    <div
      style={{
        padding: "3px 3px",
        width: widthAuto ? "fit-content" : "100px",
        fontSize: size ? size : "14px",
        borderRadius: "5px",
        fontFamily: "poppins !important",
        margin: "auto",
        color: colorCode,
        backgroundColor: backgroundColor,
        display: "flex",
        justifyContent: "center"
      }}
    >
      {props?.status?.description && props?.status?.description?.length < 10 ? (
        props?.status?.description
      ) : row?.status?.description && row?.status?.description?.length < 10 ? (
        row?.status?.description
      ) : props?.row?.status?.description &&
        props?.row?.status?.description?.length < 10 ? (
        props?.row?.status?.description
      ) : props?.description && props?.description.length < 10 ? (
        props?.description
      ) : colorDesc && colorDesc.length < 10 ? (
        colorDesc
      ) : (
        <LongText
          text={`${row?.status?.description ||
            props?.status?.description ||
            props?.row?.status?.description ||
            props?.description || colorDesc
            }`}
          length={length ? length : 10}
        />
      )}
    </div>
  );
};

export default BackgroundStatus;
