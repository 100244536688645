const form = {
  formID: "official-holidays",
  formField: {
    description: {
      name: "description",
      label: "officialHolidayForm.description.label",
      requiredMessage: "officialHolidayForm.description.requiredMessage",
    },
    holidays: {
      name:"holidays",
      label:"officialHolidayForm.holiday.label",
    }
  },
};

export default form;