import React from "react";

import { Link } from "react-router-dom";

//Exterior Components
import { Input } from "antd";
import { Typography } from "@mui/material";

//colors
import colorLight from "../../assets/theme/light/colors";

//links
import urls from "../../urls";

//translations
import { useTranslation } from "react-i18next";

//hooks
import useForms from "../../hooks/useForms";
import useMutationAuth from "../../hooks/useMutationAuth";

//graphql
import { FORGOT_PASSWORD } from "../../graphql/user";

//snackbar
import { useSnackbar } from "notistack";
import IALoadingButton from "../../components/IAButtons/IALoadingButton";

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { pure_white, light_logo_blue, error_light } = colorLight; //colors

  const { login } = urls; //urls

  const { values, errors, touched, setValues, setTouched } = useForms();

  const { mfunction: forgotPassword, loading } = useMutationAuth(
    FORGOT_PASSWORD,
    "ForgotPassword"
  );

  const { t } = useTranslation();

  const handleForgotPassword = (event) => {
    event.preventDefault();

    if (!values.email || errors.email) {
      setValues("email", "email", "");
      setTouched("email");

      return;
    }

    forgotPassword(
      {
        variables: {
          model: {
            email: values.email,
          },
        },
      },
      () => {
        enqueueSnackbar(t("user.sentSuccessfully"), {
          variant: "success",
        });
      }
    );
  };

  return (
    <form onSubmit={handleForgotPassword}>
      <div className="field-input">
        <div className="field-title" style={{ color: `${pure_white}` }}>
          <Typography variant="h6" component="h6">
            {t("forgotPassword.title")}
          </Typography>
          <Typography variant="caption" component="caption">
            {t("forgotPassword.text")}
          </Typography>
        </div>
      </div>
      <div className="field-input">
        <Input
          allowClear
          size="large"
          type="email"
          placeholder={t("forgotPassword.email")}
          //prefix={<UserOutlined />}
          onFocus={() => setTouched("email")}
          onChange={(e) => {
            setValues("email", "email", e.target.value);
          }}
          status={touched.email && errors.email ? "error" : ""}
        />
        <Typography
          variant="caption"
          component="caption"
          className="error-text"
          sx={{ color: `${error_light}` }}
        >
          {touched.email && t(errors.email)}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <IALoadingButton
          sx={{
            borderRadius: "50px !important",
            backgroundColor: light_logo_blue,
            boxShadow: "none !important",
            fontWeight: "bold !important",
          }}
          dark
          fullWidth
          type="submit"
          loading={loading}
          label={t("forgotPassword.button")}
        />

        <Typography variant="caption" component="caption">
          <Link to={`${login}`} style={{ color: `${pure_white}` }}>
            {t("forgotPassword.login")}
          </Link>
        </Typography>
      </div>
    </form>
  );
};

export default ForgotPassword;
