import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: '' };

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    saveToken: (state, { payload }) => {
      let { token } = state;
      token = { token: payload };
      return token;
    },
  },
});

export const { saveToken } = tokenSlice.actions;

export const getTokenFromStore = (state) => state.token.token;
export default tokenSlice.reducer;
