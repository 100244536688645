import { gql } from "@apollo/client";

export const GET_ALL_ONBOARDING_TASKS_LIST = gql`
query ($model: OnBoardingTaskGetRequestInput!) {
    GetOnBoardingTasksList(model: $model) {
      onBoardingTasksListResponse {
        onBoardingTasksList {
          onBoardingTaskGuid
          onBoardingTaskID
          description
          tenant {
            tenantGuid
          }
          onBoardingType {
            onBoardingTypeGuid
            onBoardingTypeID
            description
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`

export const GET_ONBOARDING_TASK_BY_ID=gql`
query ($id: UUID!) {
    GetOnBoardingTaskByID(id: $id) {
      onBoardingTaskByID {
        onBoardingTask {
          onBoardingTaskGuid
          onBoardingTaskID
          description
          emailBody
          emailTitle
          attachment
          onBoardingType {
            onBoardingTypeGuid
            onBoardingTypeID
            description
          }
        }
        attachmentName
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
  
`

export const GET_ALL_ONBOARDING_TASK_TYPES = gql`
query {
  GetAllOnBoardingTypes  {
    onBoardingTypesList {
        onBoardingTypeGuid
        description
        
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;


export const GET_ALL_ONBOARDING_TASK_STATUSES = gql`
query {
  GetAllOnBoardingTaskStatuses  {
    onBoardingTaskStatusesList {
      onBoardingTaskStatusGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_ONBOARDING_TASKS=gql`
mutation($model:OnBoardingTaskDeleteRequestInput!){
  DeleteOnBoardingTasks (model: $model) {
    deleteOnBoardingTasksResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const DELETE_EMPLOYEE_ONBOARDING_TASKS=gql`
mutation($model:DeleteEmployeeOnboardingTasksRequestInput!){
  DeleteEmployeeOnBoardingTasks(model : $model) {
     deleteOnBoardingTasksResponse
     errorCode
     errorMessage
     errorVariables
  }
 
 }
`

export const ASSIGN_EMPLOYEE_ONBOARDING_TASKS=gql`
mutation ($model: EmployeeOnBoardingTasksCreateRequestInput!) {
  AssignEmployeeOnBoardingTasks(model: $model) {
    assignEmployeeOnBoardingTasksResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const GET_ALL_EMPLOYEE_ONBOARDING_TASKS=gql`
query($model:EmployeeOnboardingTasksGetRequestInput!){
  GetAllEmployeeOnboardingTasks (model: $model) {
    employeeOnBoardingTasksListResponse {
      employeeOnBoardingTasks{
        employeeOnBoardingTaskGuid
        description
        employee {
          firstName
          lastName
        }
        onBoardingTask {
          description
          onBoardingType {
            description
          }
        }
        onBoardingTaskStatus {
          description
          mnemonic
        }
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const UPDATE_EMPLOYEE_ONBOARDING_TASK=gql`
mutation($model:EmployeeOnBoardingTaskUpdateRequestInput!){
  UpdateEmployeeOnBoardingTask (model: $model) {
    updateEmployeeOnBoardingTasksResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const GET_EMPLOYEE_ONBOARDING_TASK_BY_ID=gql`
query($id:UUID!){
  GetEmployeeOnBoardingTaskByID (id: $id) {
    employeeOnBoardingTask {
      employeeOnBoardingTaskGuid
      description
      onBoardingTaskStatus {
        description
        onBoardingTaskStatusGuid
      }
      employee {
        firstName
        lastName
      }
      onBoardingTask {
        description
        onBoardingType {
          description
          onBoardingTypeGuid
        }
        attachment
        emailTitle
        emailBody
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`