import React, { useState, useCallback, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

//component
import GridTable from "../../../components/Tables/GridTable";
import ContainerCard from "../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../hooks/useLazyQueryAuth";
import useAuth from "../../../hooks/useAuth";
import useMutationAuth from "../../../hooks/useMutationAuth";

//gql
import {
  GET_ALL_AREAS,
  UPDATE_AREA,
  CREATE_AREA,
  DELETE_AREA,
} from "../../../graphql/areas";

//mnemonic values
import Mnemonic from "../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";
import { getDataTableData } from "./_table";

function Areas({ fromTenant = false, fromModal = false, handleCloseModal, guid = null}) {
  const { tID, pID } = useParams();

  const { storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  
  const { t } = useTranslation();

  const DataTableData = getDataTableData(t);
  const { columns } = DataTableData;

  const DEFAULT_AREA = {
    areaGuid: "",
    description: "",
    tenantID: fromTenant ? tID : storeUserTenant,
  };

  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);
  const [newObj, setNewObj] = useState({
    areaGuid: "",
    description: "",
    tenantID: fromTenant ? tID : storeUserTenant,
  });
  const requiredFields=["areaGuid", "description"];

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_AREA,
    "DeleteAreas"
  );

  const { mfunction: GetAreasList, loading: getDataLoading } = useLazyQueryAuth(
    GET_ALL_AREAS,
    "GetAreasList"
  );

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    GetAreasList(
      {
        variables: {
          model: {
            enableFilters: true,
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            keyWord: search,
            projectGuid: pID || guid,
          },
        },
      },
      (response) => {
        setData(response);
        const areasList = response?.GetAreasList?.areasList?.areas || [];
        setRows(Array.isArray(areasList) ? areasList : []);
      }
    );
  }, []);

  const numberRecords =
    data &&
    data.GetAreasList &&
    data.GetAreasList.areasList &&
    data.GetAreasList.areasList.areas &&
    data.GetAreasList.areasList.totalNumberOfRecords
      ? data.GetAreasList.areasList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (setCreate.current === false) fetchTableData(page, rowsPerPage, search);
  }, [fetchTableData, rows.length]);

  const handleDelete = (selectedItems) => {
    deleteRecords(
      {
        variables: {
          model: {
            areasGuids: selectedItems,
          },
        },
      },
      (response) => {
        if (response["DeleteAreas"].deleteAreaResponse === true) {
          enqueueSnackbar(t("areas.list.delete.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setSelected([]);
          setPage(0);
        } else {
        }
      }
    );
  };

  const { cfunction: CreateArea } = useMutationAuth(CREATE_AREA, "CreateArea");

  const { cfunction: UpdateArea } = useMutationAuth(UPDATE_AREA, "UpdateArea");

  function CreateAreaData(newRow) {
    setIsSubmitting(true);
    if (newRow != null && newRow.areaGuid != null) {
      CreateArea(
        {
          variables: {
            model: {
              description: newRow.description,
              projectGuid: pID || guid,
            },
          },
        },
        (response) => {
          if (
            response["CreateArea"].errorCode !== null &&
            response["CreateArea"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateArea"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            setRows(rows.filter((r) => r.areaGuid !== newObj.areaGuid));
          } else {
            enqueueSnackbar(t("areaForm.createdSuccessfully"), {
              variant: "success",
            });
            fetchTableData(page, rowsPerPage, search);
            if(fromModal === true && handleCloseModal !== undefined) handleCloseModal(true);
          }
          setNewObj(DEFAULT_AREA);
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        },
        (error) => {
          setRows(rows.filter((r) => r.areaGuid !== newObj.areaGuid));
          setNewObj(DEFAULT_AREA);
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        }
      );
    }
  }

  function UpdateAreaData(updatedRow) {
    UpdateArea(
      {
        variables: {
          model: {
            areaGuid: updatedRow.areaGuid,
            description: updatedRow.description,
          },
        },
      },
      (response) => {
        if (
          response["UpdateArea"].errorCode !== null &&
          response["UpdateArea"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateArea"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_AREA);
        } else {
          enqueueSnackbar(t("areaForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_AREA);
          if(fromModal === true && handleCloseModal !== undefined) handleCloseModal(true);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_AREA);
      }
    );
  }

  const pagination = {
    rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    removeFilters:true,
    fetchTableData,
    loading: getDataLoading,
    searchText: "area.list.search.label",
    // refetch,
    tableMnemonic: Mnemonic.Tables["Areas"],
    deleteText: "area.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    fetchedBool: true,
    readOnly: manageSystemConfigurations !== "M",
    isSubmitting,
    requiredFields,
    enqueueSnackbar
  };

  return (
    <div>
      {getDataLoading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns &&
        columns.length > 0 && (
          // <div className="systemVariables"></>
          <GridTable
            {...pagination}
            identifier="areaGuid"
            hasCheckbox={false}
            handleCreate={(area) => CreateAreaData(area)}
            handleUpdate={(area) => UpdateAreaData(area)}
            handleDelete={(areas) => handleDelete(areas)}
          />
        )
      )}
    </div>
  );
}

export default Areas;
