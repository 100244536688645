const form = {
  formID: "News",
  formField: {

    description: {
      name: "description",
      label: "roleForm.description.label",

    },
    releaseDate: {
      name: "releaseDate",
      label: "label.releaseDate"
    },
    version: {
      name: "version",
      label: "label.version"
    }
  },
};

export default form;