import React from "react";

//colors
import colorLight from "../../assets/theme/light/colors";

//external components
import { LoadingButton } from "@mui/lab";
import Tooltip from "../Tooltip";
import { rest } from "lodash-es";

const IALoadingButton = (props) => {
  const { label, error, secondary, tooltipLabel, lightBlue , children, ...rest } = props;

  const {
    dark_logo_blue,
    pure_white,
    error_light,
    white_smoke,
    secondaryColor,
    light_logo_blue
  } = colorLight;

  //css stylings
  const buttonStyle = {
    backgroundColor: error ? error_light : secondary ? secondaryColor : lightBlue ? light_logo_blue :  dark_logo_blue,
    color: error ? pure_white : white_smoke,
    fontSize: "13px",
    fontWeight: "bold",
    borderRadius: "5px",
    minWidth: "auto",
    display: "flex",
    alignItems: "center",
    fontFamily: "poppins  !important",
    "&:hover": {
      backgroundColor: error ? error_light : secondary ? secondaryColor : lightBlue ? light_logo_blue : dark_logo_blue,
      color: error ? pure_white : white_smoke,
    },
  };

  return (
   
    <Tooltip title={tooltipLabel}>
    <LoadingButton
      variant="contained"
      sx={buttonStyle}
      loadingPosition={"center"}
      {...rest} 
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {children} 
        <span>{label}</span>
      </div>
    </LoadingButton>
  </Tooltip>
  );
};

export default IALoadingButton;
