import { gql } from "@apollo/client";

export const GET_ALL_TENANTS = gql`
  query ($model: GetAllTenantsRequestModelInput!) {
    GetAllTenants(model: $model) {
      tenantsList {
        rows {
            tenant {
              tenantGuid
              name
              creationDate
              isActive
              projectRadiusInMobileApp
              trial
              trialExpirationDate
            }
          numberOfCompanies
          numberOfUsedLicenses
        }
        numberOfRecords
        tenants {
          name
          tenantGuid
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TENANT_BY_ID = gql`
  query ($tenantID: UUID!) {
    GetTenant(id: $tenantID) {
      tenantView {
        tenant {
          tenantGuid
          name
          monthlyTimesheet
          numberOfMonthlyWorkingHours
          creationDate
          isActive
          projectRadiusInMobileApp
          allowTimesheetReopening
          mandatoryTimeEntryDescription
          allowAttendanceSheetReopening
          monthlyAttendanceSheet
          postJournal
          postJournalDescription
          trial
          trialExpirationDate
          subscribedFrom
          licenseType
          tenantMiddlewareInfo {
            attendance
              job
              task
              company{
                companyGuid
                name
              }
            timeEntry
            syncTimeEntry
            logCleanRuntime
            retentionDays
            connector {
              connectorGuid
              name
              description
            }
            taskStatus {
              taskStatusGuid
              
              description
            }
            ticketStatus {
              ticketStatusGuid
              
              description
            }
            tenantMiddlewareJobs {
              tenantMiddlewareJobGuid
              middlewareJob {
                middlewareJobGuid
                name
              }
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TENANT = gql`
  mutation ($model: EditTenantRequestInput!) {
    EditTenant(model: $model) {
      tenantView {
        tenant{
          tenantGuid
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TENANT = gql`
  mutation ($model: CreateTenantRequestInput!) {
    CreateTenant(model: $model) {
      tenantView {
        tenant {
          tenantGuid
          name
          creationDate
          tenantMiddlewareInfo {
            tenantMiddlewareInfoGuid
            ticketStatusGuid
            timeEntry
            syncTimeEntry
            taskStatusGuid
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TENANTS = gql`
  mutation ($model: DeleteTenantsListRequestInput!) {
    DeleteListOfTenants(model: $model) {
      crudTenant
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CHANGE_TENANT_STATUS = gql`
  mutation ($model: EditActiveStatusModelInput!) {
    EditTenantActiveStatus(model: $model) {
      crudTenant
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_CONNECTOR_LIST = gql`
query {
  GetConnectorsList {
    connectorsList {
      connectorGuid
      name
      description
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`
export const RESET_TENANT = gql`
mutation($id:UUID!){
  ResetTenant (id: $id){
    resetTenant
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_TENANT_LICENSES = gql`
  query ($tenantID: UUID!) {
    GetTenant(id: $tenantID) {
      tenantView {
        tenant {
          tenantGuid
          numberOfLicenses
          licenseType
        }
        numberOfUsedLicenses
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const EDIT_LICENSES = gql`
mutation($model: CreateLicensesRequestInput!) {
  EditLicences(model: $model) {
    tenantView {
      tenant {
        tenantGuid
        licenseType
        numberOfLicenses
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;