import checkout from "./form";

const {
  formField: {
    description,
    totalWeeklyHours,
    daysOfWeek: { startHour, endHour, dayName, isWorkDay, breakTime, StartOfWeek },
  },
} = checkout;

const initialValues = {
  [description.name]: "",
  [totalWeeklyHours.name]:0,
  "daysOfWeek": [
    {
      [dayName.name]: "Monday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
    {
      [dayName.name]: "Tuesday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
    {
      [dayName.name]: "Wednesday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
    {
      [dayName.name]: "Thursday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
    {
      [dayName.name]: "Friday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
    {
      [dayName.name]: "Saturday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
    {
      [dayName.name]: "Sunday",
      [isWorkDay.name]: false,
      [startHour.name]: null,
      [endHour.name]: null,
      [breakTime.name]: "0",
      changeTime: null,
      [StartOfWeek.name]: false
    },
  ],
};

export default initialValues;