import React, {useState} from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import DataCard from "../../../components/Cards/DataCard";

//data
import _tableData from "./_pending-requests";

const PendingRequests = ({ initialLoad, data, removeBorders }) => {

  const [loading, setLoading] = useState(false); //set true when we wish to start lazy loading

  // const _records = data?.records || []; //holds data
  // const _maxRecords = data?.totalNumberOfRecords || 0; //holds total number of records in database that satisfy the query

  // useEffect(() => {
  //   if (_records && _records.length > 0) setLoading(false);
  // }, [_records]);

  // function fetchData() {
  //   //function that gets records on lazy load

  //   if (waitBatch) return; //data is already being fetched, we wait for it to at least be fetched

  //   if (_records.length >= _maxRecords) return; //if data has reached its max

  //   next(); //get next batch
  // }

  const { title, headers, render } = _tableData; //holds card title, table headers and the display of data

  // const stopLazyLoad = _records.length >= _maxRecords; //if all records have been fetched we no longer wish to lazy load

 
  return (
    <DataCard
      title={title}
      // stopLazyLoad={stopLazyLoad}
      // onLazyLoad={fetchData}
      tableHeaders={headers}
      render={render}
      data={data}
      initialLoad={initialLoad}
      loading={loading}
      setLoading={setLoading}
      removeBorders={removeBorders}
      maxHeight={"120px"}
    />
  );
};

PendingRequests.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch:PropTypes.bool,
  removeBorders: PropTypes.bool
};

PendingRequests.defaultProps = {
  initialLoad: false,
  data: {},
  next: ()=>{},
  waitBatch:false,
  removeBorders: false
};

export default PendingRequests;
