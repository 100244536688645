import checkout from "./form";

const {
  formField: { title, description, status, area
    ,EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,synced, externalReference },
} = checkout;

export default {
  [EstimatedEndDate.name]: null,
  [EstimatedStartDate.name]: null,
  [EstimatedTime.name]: "",
  [ActualEndDate.name]: null,
  [ActualStartDate.name]: null,
  [ActualTime.name]: "",
[externalReference.name]:"",
  [title.name]: "",
  [description.name]: "",
  [status.name]: null,
  [synced.name]: false,
  [area.name]: null
};
