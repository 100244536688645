import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import DataTable from "../../../../components/Tables/DataTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { getTable } from "./_table";
import { ASSIGN_EMPLOYEES_TO_ROLE, GET_EMPLOYEES_BY_ROLE } from "../../../../graphql/employee";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import { enqueueSnackbar } from "notistack";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import UnassignedEmployeeTable from "../Unassigned-employees";

export const RolesResources = ({ roleGuid = null }) => {
  const { rID } = useParams();
  const { t } = useTranslation();
  const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const { manageUsers } = userRoles;
  const [re, setRe] = useState(false);
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [defaultFilters, setDefaultFilters] = useState({});
  const [assignedData, setAssignedData] = useState(null);
  const [displayTable, setDisplayTable] = useState(false);

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();
      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const { mfunction: getAllEmployees, loading } = useLazyQueryAuth(
    GET_EMPLOYEES_BY_ROLE,
    "GetCompanyUsersList"
  );

  const { mfunction: assignEmployeesToRole } = useMutationAuth(
    ASSIGN_EMPLOYEES_TO_ROLE,
    "EditUsersRole"
  );

  function handleAddEmployee(array) {
    assignEmployeesToRole(
      {
        variables: {
          model: {
            userGuid: storeUserGuid,
            roleGuid: rID,
            userIDs: array,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("assignEmployeesToRole.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        setDisplayTable(false);
      }
    );
  }

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployees(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: "FirstName",
              orderDirection: order,
              keyWord: search,
              roleGuid: rID || roleGuid,
              tenantID: storeUserTenant,
              userID: storeUserGuid,
              assignNewRole: false
            },
          },
        },
        (response) => {
          setAssignedData(response);
        }
      );
    },
    []
  );


  useEffect(() => {
    if (columns.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columns.length, fetchTableData, re]);

  const rows =
    assignedData &&
      assignedData.GetCompanyUsersList &&
      assignedData.GetCompanyUsersList &&
      assignedData.GetCompanyUsersList.usersList.employees
      ? assignedData.GetCompanyUsersList.usersList.employees
      : [];
  const numberOfRecords =
    assignedData &&
      assignedData.GetCompanyUsersList &&
      assignedData.GetCompanyUsersList.usersList.totalNumberOfRecords
      ? assignedData.GetCompanyUsersList.usersList.totalNumberOfRecords
      : [];


  const customButtons = [
    {
      label: "employeeList.addEmployee",
      handleClick: () => setDisplayTable(true),
      condition: manageUsers === "M",
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important"
          }}
        />
      ),
    },
  ];
  
  const pagination = {
    columns,
    order,
    setOrder,
    rows,
    orderBy,
    setOrderBy,
    numberRecords: numberOfRecords,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    fetchTableData,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    page,
    customButtons,
    rowsPerPage,
    loading: loading,
    searchText: "employee.list.search.label",
    readOnly: manageUsers !== "M",
    removeFilters: true,
    hideEditColumns: true,
};

  return (
    <div>
      {displayTable ? (
        <UnassignedEmployeeTable
          display={displayTable}
          tenant={storeUserTenant}
          initialVal={[]}
          handleClick={handleAddEmployee}
          setDisplay={setDisplayTable}
        />
      ) : null}
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"employeeGuid"} hasCheckbox={false} />
      )}
    </div>
  );
};
