const colors = {
  //////NEW COLORS
  gradient_light_blue:"var(--color--gradient-light-blue)",
  light_logo_blue: "var(--color--light-logo-blue)",
  dark_logo_blue: "var(--color--dark-logo-blue)",
  open_menu_blue: "var(--color--open-menu-blue)",
  grey_background: "var(--color--grey-background)",
  red_color: "var(--color--red-color)",
  green_color: "var(--color--green-color)",
  orange_color: "var(--color--orange-color)",
  disabled_text_grey: "var(--color--disabled-text-grey)",
  disabled_background_grey: "var(--color--disabled-background-grey)",
  pure_white: "var(--color-pure-white)",
  menu_hover_barely_black: "var(--color-barely-pure-black)",
  menu_hover_pure_black: "var(--color-pure-black)",
  light_divider_color: "var(--color--light-divider-color)",
  icon_button_grey: "var(--color--icon-button-grey)",
  light_background_color: "var(--color--light-bg-color)",
  light_table_background_color: "var(--color--light-table-bg-color)",
  box_shadow_color: "var(--color--box-shadow)",
  color_fields: "var(--color-fields)",
  light_pill_border_color: "var(--color-light-pill-border-color)",
  secondaryColor: "var(--color-secondary-color)",
  disabled_button_color: "var(--color-button-disabled)",
  table_border_color: "var(--color-table-border)",
  chip_border :"var(--color-chip-border)",
  chip_label: "var(--color-chip-label)",

  /////OLD COLORS
  dark_blue: "var(--color-dark-blue)",
  light_dark_blue: "var(--color-light-dark-blue)",
  light_blue: "var(--color-light-blue)",
  lighter_blue: "var(--color-lighter-blue)",
  lighter_light_blue: "var(--color-lighter-light-blue)",
  lighter_light_blue_hover: "var(--color-lighter-light-blue-hover)",
  standard_blue: "var(--color-standard-blue)",
  classic_blue: "var(--color-classic-blue)",
  white_smoke: "var(--color-white)",
  error: "var(--color-error)",
  error_light: "var(--color-error-light)",
  backgroundColor: "var(--color-background-color)",
  list_item_color: "var(--color-list-item-color)",
  gradients: {
    dark: {
      main: "var(--color-gradients-dark-main)",
      state: "var(--color-gradients-dark-state)",
    },
    blue: {
      main: "var(--color-gradients-blue-main)",
      state: "var(--color-gradients-blue-state)",
    },
  },
  statuses: {
    success: {
      color: "var(--color-statuses-success-color)",
      bgColor: "var(--color-statuses-success-bgColor)",
    },
    error: {
      color: "var(--color-statuses-error-color)",
      bgColor: "var(--color-statuses-error-bgColor)",
    },
  },
  rgba: {
    light_white: "var(--color-rgba-light-white)",
    light_dark_blue: "var(--color-rgba-light-dark-blue)",
    light_blue: "var(--color-rgba-light-blue)",
    pure_white: "var(--color-pure-white)",
  },
  timesheetStatusColors: {
    Pending: {
      color: "var(--color-timesheetStatusColors-pending-color)",
      backgroundColor:
        "var(--color-timesheetStatusColors-pending-backgroundColor)",
    },
    Approved: {
      color: "var(--color-timesheetStatusColors-approved-color)",
      backgroundColor:
        "var(--color-timesheetStatusColors-approved-backgroundColor)",
    },
  },
  requestStatusColors: {
    new: {
      color: "var(--color-requestStatusColors-new-color)",
      backgroundColor: "var(--color-requestStatusColors-new-backgroundColor)",
    },
    sentForApproval: {
      color: "var(--color-requestStatusColors-sentForApproval-color)",
      backgroundColor:
        "var(--color-requestStatusColors-sentForApproval-backgroundColor)",
    },
    rejected: {
      color: "var(--color-requestStatusColors-rejected-color)",
      backgroundColor:
        "var(--color-requestStatusColors-rejected-backgroundColor)",
    },
    approved: {
      color: "var(--color-requestStatusColors-approved-color)",
      backgroundColor:
        "var(--color-requestStatusColors-approved-backgroundColor)",
    },
    canceled: {
      color: "var(--color-requestStatusColors-cancelled-color)",
      backgroundColor:
        "var(--color-requestStatusColors-cancelled-backgroundColor)",
    },
  },
  employeeStatusColors: {
    new: {
      color: "var(--color-employeeStatusColors-new-color)",
      backgroundColor: "var(--color-employeeStatusColors-new-backgroundColor)",
    },
    active: {
      color: "var(--color-employeeStatusColors-active-color)",
      backgroundColor:
        "var(--color-employeeStatusColors-active-backgroundColor)",
    },
    inactive: {
      color: "var(--color-employeeStatusColors-inactive-color)",
      backgroundColor:
        "var(--color-employeeStatusColors-inactive-backgroundColor)",
    },
    closed: {
      color: "var(--color-employeeStatusColors-inactive-color)",
      backgroundColor:
        "var(--color-employeeStatusColors-inactive-backgroundColor)",
    },
  },
  taskStateColors: {
    new: {
      color: "var(--color-taskStateColors-new-color)",
      backgroundColor: "var(--color-taskStateColors-new-backgroundColor)",
    },
    doing: {
      color: "var(--color-taskStateColors-doing-color)",
      backgroundColor: "var(--color-taskStateColors-doing-backgroundColor)",
    },
    done: {
      color: "var(--color-taskStateColors-done-color)",
      backgroundColor: "var(--color-taskStateColors-done-backgroundColor)",
    },
  },

  projectColors: {
    new:  {
      color: "var(--color-requestStatusColors-sentForApproval-color)",
      backgroundColor:
        "var(--color-requestStatusColors-sentForApproval-backgroundColor)",
    },
    active: {
      color: "var(--color-requestStatusColors-approved-color)",
      backgroundColor:
        "var(--color-requestStatusColors-approved-backgroundColor)",
    },
    closed:{
      color: "var(--color-requestStatusColors-rejected-color)",
      backgroundColor:
        "var(--color-requestStatusColors-rejected-backgroundColor)",
    },
  },

  EmployeeOnBoardingTasks: {
    new: {
      color: "var(--color-requestStatusColors-new-color)",
      backgroundColor: "var(--color-requestStatusColors-new-backgroundColor)",
    },
    inProcess: {
      color: "var(--color-taskStateColors-inProcess-color)",
      backgroundColor: "var(--color-taskStateColors-inProcess-backgroundColor)",
    },
    done: {
      color: "var(--color-requestStatusColors-rejected-color)",
      backgroundColor:
        "var(--color-requestStatusColors-rejected-backgroundColor)",
    },
    emailSent: {
      color: "var(--color-requestStatusColors-approved-color)",
      backgroundColor:
        "var(--color-requestStatusColors-approved-backgroundColor)",
    },
    onHold: {
      color: "var(--color-requestStatusColors-cancelled-color)",
      backgroundColor:
        "var(--color-requestStatusColors-cancelled-backgroundColor)",
    },
  },

  
  checkStatusColors: {
    true: {
      color: "var(--color-enabled-color)",
      backgroundColor: "var(--color-enabled-backgroundColor)",
    },
    false: {
      color: "var(--color-disabled-color)",
      backgroundColor: "var(--color-disabled-backgroundColor)",
    },
  },
  suggestionStatusColors: {
    suggested: {
      color: "var(--color-employeeStatusColors-new-color)",
      backgroundColor: "var(--color-employeeStatusColors-new-backgroundColor)",
    },
    upcoming: {
      color: "var(--color-employeeStatusColors-active-color)",
      backgroundColor:
        "var(--color-employeeStatusColors-active-backgroundColor)",
    },
    deployed: {
      color: "var(--color-employeeStatusColors-inactive-color)",
      backgroundColor:
        "var(--color-employeeStatusColors-inactive-backgroundColor)",
    },
   
  },
};

export default colors;
