const form = {
  formID: "News",
  formField: {
  
    description: {
      name: "description",
      label: "roleForm.description.label",

    },

  },
};

export default form;