import { createSlice } from "@reduxjs/toolkit";

const initialState = { language: "en" };

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, { payload }) => {
      let { language } = state;
      language = { language: payload };
      return language;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;

export const getLanguageFromStore = (state) => state.language.language;
export default languageSlice.reducer;
