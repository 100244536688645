import React, { useState, useEffect, useCallback } from "react";

//react router
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_ALL_OFFICIAL_HOLIDAYS,
  DELETE_OFFICIAL_HOLIDAYS,
} from "../../../../graphql/officialHolidays";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const OfficialHolidaysList = ({ fromTenant = false }) => {
  const { tName, tID } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const {
    new_tenant_official_holidays,
    tenant_official_holidays_form,
    my_official_holidays_form,
    new_my_official_holidays,
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const filtersObject = [
    {
      type: "text",
      label: "Description",
      name: "description",
    }
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["Tenant_Official_Holidays"],
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {

        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllOfficialHolidays, loading } = useLazyQueryAuth(
    GET_ALL_OFFICIAL_HOLIDAYS,
    "GetOfficialHolidaysList"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllOfficialHolidays(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              userID: storeUserGuid,
              filters: filters,
              tenantGuid: fromTenant ? tID : storeUserTenant,
              mnemonic: Mnemonic.Tables["Tenant_Official_Holidays"],
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetOfficialHolidaysList &&
    data.GetOfficialHolidaysList.officialHolidayListModel &&
    data.GetOfficialHolidaysList.officialHolidayListModel.officialHolidays
      ? data.GetOfficialHolidaysList.officialHolidayListModel.officialHolidays
      : [];
  const numberOfRecords =
    data &&
    data.GetOfficialHolidaysList &&
    data.GetOfficialHolidaysList.officialHolidayListModel &&
    data.GetOfficialHolidaysList.officialHolidayListModel.totalNumberOfRecords
      ? data.GetOfficialHolidaysList.officialHolidayListModel
          .totalNumberOfRecords
      : 0;

  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_OFFICIAL_HOLIDAYS,
    "DeleteOfficialHolidayList"
  );

  const handleDelete = () => {
    deleteRecord(
      {
        variables: {
          model: {
            officialHolidays: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("officialHoliday.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "officialHoliday.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables["Tenant_Official_Holidays"],
    filtersObject,
    removeFilters:true,
    generateRoute: (row) =>
      fromTenant
        ? tenant_official_holidays_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:ohName/:ohID",
              `/${encodeURIComponent(row?.description)}/${encodeURIComponent(row?.officialHolidayGuid)}`
            )
        : my_official_holidays_form.replace(
            "/:ohName/:ohID",
            `/${encodeURIComponent(row?.description)}/${encodeURIComponent(row?.officialHolidayGuid)}`
          ),
    newText: "officialHoliday.list.new.label",
    newLink: fromTenant
      ? new_tenant_official_holidays.replace(
          "/:tName/:tID",
          `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
        )
      : new_my_official_holidays,
    deleteText: "officialHoliday.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    hideEditColumns: true,
    readOnly: manageSystemConfigurations !== "M",
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"officialHolidayGuid"}
          hasCheckbox
        />
      )}
    </div>
  );
};

export default OfficialHolidaysList;
