import React, { useState } from "react";

//styles
import "./style.css";

//components
import Root from "./root";

//logo
import HorizontalLogo from "../../assets/images/activ_horizontal.png";
import MiniLogo from "../../assets/images/white_verti_logo.png";

import { Close } from "@mui/icons-material";

//components
import List from "./list";

const SideNav = ({ miniSidenav, setMiniSidenav, list = [] }) => {
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const handleMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(false);
    //   localStorage.setItem("miniSidenav", false);
    //   setOnMouseEnter(true);
    // }
  };

  const handleMouseLeave = () => {
    // if (onMouseEnter) {
    //   setMiniSidenav(true);           
    //   localStorage.setItem("miniSidenav", true);
    //   setOnMouseEnter(false);
    // }
  };

  return (
    <Root
      open={miniSidenav}
      variant="permanent"
      ownerState={{ miniSidenav }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={miniSidenav ?"sidenav-header-mini":"sidenav-header"}>
        <span
          className="close-button"
          onClick={() => {
            setMiniSidenav((val) => !val);
            localStorage.setItem("miniSidenav", !miniSidenav);
          }}
        >
          <Close />
        </span>
       {miniSidenav ? <img src={MiniLogo} alt="Activ365" style={{ margin: "10px 0" }}/> : <img src={HorizontalLogo} alt="Activ365" style={{ margin: "10px 0" }}/>}
      </div>
      <List list={list} miniSideNav={miniSidenav}  />
    </Root>
  );
};

export default SideNav;
