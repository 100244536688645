import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";
import UTC2Local from "../../../../UTC2Local"
// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";

//graphql
import { CREATE_TO_ENTITLEMENT } from "../../../../graphql/pto";

import ContainerCard from "../../../../components/Cards/ContainerCard";

const AddDayUsedForm = ({ handleClose = () => {} , onSuccess=()=>{}, pTOs = [], employees = []}) => {
  const { eID, duID } = useParams();

  const { t } = useTranslation();

  const { formField } = form;

  const [requestStatus, setRequestStatus] = useState(null);

  const [numberOfDaysError, setNumberOfDaysError] = useState(null);

  const [resetFields, setResetFields] = useState(null);

  const { employee, pTO, description, date, numberOfDays, numberOfHours, entitlement } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);
  const focusField = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  const { cfunction: createTOEntitlementData } = useMutationAuth(
    CREATE_TO_ENTITLEMENT,
    "CreateTOEntitlement"
  );

  function createRequest(values, actions) {

    const days = values[numberOfDays.name] + (values[numberOfHours.name] / 24);
    if(values[entitlement.name] == 0 && Math.abs(parseFloat(days.toFixed(2))) > 1) {setNumberOfDaysError(t("numberOfDays.one.max")); actions.setSubmitting(false)}
    // else if(Math.abs(parseFloat(days.toFixed(2))) === 0) {setNumberOfDaysError(t("numberOfDays.one.min")); actions.setSubmitting(false)}
    else
    createTOEntitlementData(
      {
        variables: {
          model: {
            employeeGuid: values[employee.name]?.employeeGuid,
            ptoGuid: values[pTO.name]?.ptoGuid,
            description: values[description.name],
            entitlement: values[entitlement.name] == 1 ? true : false,
            date: UTC2Local(values[date.name], true),
            numberOfDays: values[numberOfDays.name] ? values[numberOfDays.name] : 0,
            numberOfHours: values[numberOfHours.name] ? values[numberOfHours.name] : 0,
          },
        },
      },
      (response) => {
        if (
          response["CreateTOEntitlement"].errorCode !== null &&
          response["CreateTOEntitlement"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateTOEntitlement"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("dayUsedForm.sucessfullyCreated"), {
            variant: "success",
          });
          focusField.current.focus();
          onSuccess();
          // handleClose();
          setResetFields(!resetFields);
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    createRequest(values, actions);
  };

  return (
    <>
      <Box mb={5}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          sx={{ height: "100%", mt: 2, maxWidth:"700px", mr:1, ml:1 }}

        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ContainerCard>
              <Formik
                innerRef={formikRef}
                key={`${form.formID}-form-${"edit"}`}
                initialValues={formData}
                validationSchema={validation[0]}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  setTouched,
                }) =>  {
                  formikValuesRef.current = values;
                  return (
                  <Form id={form.formID} autoComplete="off">
                    <Box p={2}>
                      <Box>
                        <Main
                          formData={{
                            values,
                            touched,
                            formField: form.formField,
                            errors,
                            setFieldValue,
                            setTouched,
                            requestStatus,
                            setRequestStatus,
                            handleClose,
                          }}
                          pTOs={pTOs}
                          employees={employees}
                          resetFields={resetFields}
                          numberOfDaysError={numberOfDaysError}
                          setNumberOfDaysError={setNumberOfDaysError}
                          focusField={focusField}
                        />
                      </Box>
                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <IALoadingButton
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          label={t("requestForm.save")}
                        />
                      </Box>
                    </Box>
                  </Form>
                )}}
              </Formik>
            </ContainerCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddDayUsedForm;
