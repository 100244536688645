import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";

//graphql
import { GET_TENANT_LICENSES, EDIT_LICENSES } from "../../../../graphql/tenant";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

const TenantLicenses = ({ fromTenant = false }) => {
  const { tName, tID } = useParams();

  const createMode = !tName && !tID;

  const { tenant_form } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [refetchTable, setRefetchTable] = useState(false);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { licenseType, numberOfLicenses, numberOfUsedLicenses } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);
  const lincenseTypeList = [
    { description: "Basic", value: "BASIC", id: 1 },
    { description: "Professional", value: "PROFESSIONAL", id: 2 },
  ];

  const { loading: getDataByIDLoading, tokenChange: wait } = useQueryAuth(
    GET_TENANT_LICENSES,
    "GetTenant",
    {
      variables: { tenantID: tID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response.GetTenant.tenantView.tenant || {};
        
        setFormData({
          [licenseType.name]: data.licenseType || "",
          [numberOfLicenses.name]: data.numberOfLicenses || 0,
          [numberOfUsedLicenses.name]:
            response.GetTenant.tenantView.numberOfUsedLicenses || 0,
        });
      },
    }
  );

  const { cfunction: editTenantData } = useMutationAuth(
    EDIT_LICENSES,
    "EditLicences"
  );

  function editTenant(values, actions) {
    editTenantData(
      {
        variables: {
          model: {
            tenantGuid: tID,
            licenseType: values[licenseType.name],
            numberOfLicenses: values[numberOfLicenses.name],
          },
        },
      },
      (response) => {
        if (
          response["EditLicences"].errorCode !== null &&
          response["EditLicences"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditLicences"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("tenantForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false));
          setRefetchTable(!refetchTable);
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    editTenant(values, actions);
  };
  
  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={createMode ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                      <Form
                        id={form.formID}
                        autoComplete="off"
                        onChange={() => {
                          if (touched)
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                        }}
                      >
                        <Box p={2}>
                          <Box>
                            <Main
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                                setFieldValue,
                                setTouched,
                                fromTenant,
                                lincenseTypeList,
                              }}
                            />
                          </Box>
                          {fromTenant && (
                            <Box
                              mt={2}
                              width="100%"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <IALoadingButton
                                // disabled={values[connector.name]? false: true}
                                loading={isSubmitting}
                                type="submit"
                                label={t("tenantForm.save")}
                              />
                            </Box>
                          )}
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TenantLicenses;
