

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './clockinout.css';
import { formatDateTimeZone } from '../../../../UTC2Local';
import {  changeTimer } from '../../../../app/reducers/timerSlice';

const MyTime = ({clockInTime, checkedIn, currentTime}) => {
  const dispatch = useDispatch();
  const [timeSpent, setTimeSpent] = useState('00:00:00');
  const percentage = React.useRef(0);
  const valueToAdd = React.useRef(0);

  useEffect(() => {
    if (!checkedIn) {
      setTimeSpent('00:00:00');
    } else {
      setTimeSpent(handleTimer());
    }
  }, [checkedIn]);

  const handleTimer = () => {
  
   
    let startDate = new Date(formatDateTimeZone(clockInTime));
    let currentDate = new Date(formatDateTimeZone(currentTime));
    let timeDifference = currentDate - startDate + valueToAdd.current;

    valueToAdd.current += 1000;
    dispatch(changeTimer(valueToAdd.current));
  
    // Calculate hours and minutes
    const hours =
      timeDifference < 0 ? Math.ceil(timeDifference / 3600000) : Math.floor(timeDifference / 3600000);
    const minutes =
      timeDifference < 0
        ? Math.ceil((timeDifference % 3600000) / 60000)
        : Math.floor((timeDifference % 3600000) / 60000);
    const seconds =
      timeDifference < 0 ? Math.ceil((timeDifference % 60000) / 1000) : Math.floor((timeDifference % 60000) / 1000);
  
    // Format hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
  
    percentage.current = formattedSeconds;
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  
  useEffect(() => {
    if (clockInTime != null) setTimeSpent(handleTimer());
  }, [clockInTime, currentTime]);
  useEffect(() => {
    const startTimer = setInterval(() => {
      if (clockInTime != null) {
        setTimeSpent(handleTimer());
      } else {
        clearInterval(startTimer);
        setTimeSpent(null);
      }
    }, 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(startTimer);
    };
  }, [clockInTime]); 
  

  return (
      <div>
        {timeSpent && (
          <div>
            {timeSpent}
          </div>
        )}
      </div>
  );
};

export default MyTime;

