import { gql } from "@apollo/client";

export const GET_ALL_WORK_TYPES = gql`
query($showAll : Boolean){
  GetAllWorkTypes (showAll: $showAll) {
    workTypes {
      description
      workTypeGuid
      mnemonic
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

