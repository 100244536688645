const form = {
  formID: "ptos",
  formField: {
    description: {
      name: "description",
      label: "ptoForm.description.label",
      requiredMessage: "ptoForm.description.requiredMessage",
    },
    paid: {
      name: "paid",
      label: "ptoForm.paid.label",
    },
    accrued: {
      name: "accrued",
      label: "ptoForm.accrued.label",
    },
    accumulated: {
      name: "accumulated",
      label: "ptoForm.accumulated.label",
    },
    numberOfDays: {
      name: "numberOfDays",
      label: "ptoForm.numberOfDays.label",
      requiredMessage: "ptoForm.numberOfDays.requiredMessage",
    },
    employmentType: {
      name: "employmentType",
      label: "ptoForm.employmentType.label",
      requiredMessage: "ptoForm.employmentType.requiredMessage",
    },
    assignDate: {
      name: "assignDate",
      label: "ptoForm.assignDate.label",
      requiredMessage: "ptoForm.assignDate.requiredMessage",
    },
    status: {
      name: "status",
      label: "ptoForm.status.label",
      requiredMessage: "ptoForm.status.requiredMessage",
    },
    seniorities: {
      name: "seniorities",
      label: "ptoForm.seniorities.label",
    },
    From: {
      name: "From",
      label: "ptoForm.From.label",
      requiredMessage: "ptoForm.From.requiredMessage",
    },
    To: {
      name: "To",
      label: "ptoForm.To.label",
      requiredMessage: "ptoForm.To.requiredMessage",
    },
    NewNumberOfDays: {
      name: "NewNumberOfDays",
      label: "ptoForm.NewNumberOfDays.label",
      requiredMessage: "ptoForm.NewNumberOfDays.requiredMessage",
    },
    dateUnit: {
      name: "dateUnit",
      label: "ptoForm.dateUnit.label",
      requiredMessage: "ptoForm.dateUnit.requiredMessage",
    },
  },
};

export default form;