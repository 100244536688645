import React, { useState, useEffect } from "react";

import {
  Paper,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Tooltip,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

//uuid generator
import { v4 as uuidv4 } from "uuid";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UTC2Locale, { UTC2Time } from "../../../../UTC2Local";
import { getWorkType, TableCollapseIcon, TableExpandIcon } from "../../../../components/Icon";
import Mnemonics from "../../../../Mnemonics.json";
import MarkBadge from "../../../../components/StatusCell/MarkBadge";

import colors from "../../../../assets/theme/light/colors";
import { getWorkingHours } from "../../../../utils/getWorkingHours";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "10px !important",
          borderRadius: "2px !important",
          backgroundColor: "white !important",
          boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
          color: "black",
        },
      },
    },
  },
});

export function AttendanceByEmployeeTable({ t, data }) {
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedIDs, setExpandedIDs] = useState([]);
  const [expandedDay, setExpandedDay] = useState(false);
  const [expandedDayIDs, setExpandedDayIDs] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    function addAnotherIdToProjects(dataArray) {
      dataArray.forEach((row) => {
        row.attendanceGroups.forEach((attendance) => {
          attendance.id = uuidv4();
          attendance.dayLogs.forEach((dayLog) => {
            dayLog.dayLog = true;
          });
        });
      });

      return dataArray;
    }

    // Example usage
    setRows(addAnotherIdToProjects(data));
  }, []);

  function handleExpand(id) {
    setExpanded(true);
    if (!expandedIDs.includes(id)) setExpandedIDs([...expandedIDs, id]);
  }

  function handleCollapse(id) {
    const updatedExpandedIDs = expandedIDs.filter(
      (expandedID) => expandedID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpanded(false);
    setExpandedIDs(updatedExpandedIDs);
  }

  function handleExpandProject(id) {
    setExpandedDay(true);
    if (!expandedDayIDs.includes(id))
      setExpandedDayIDs([...expandedDayIDs, id]);
  }

  function handleCollapseProject(id) {
    const updatedExpandedIDs = expandedDayIDs.filter(
      (expandedDayID) => expandedDayID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpandedDay(false);
    setExpandedDayIDs(updatedExpandedIDs);
  }

  function handleExpandAll() {
    const attendanceArr = [];
    const emplArr = [];
    rows.forEach((row) => {
      const { attendanceGroups } = row;
      setExpanded(true);
      emplArr.push(row.employeeGuid);
      attendanceGroups.forEach((attendance) => {
        setExpandedDay(true);
        attendanceArr.push(attendance.id);
      });
    });
    setExpandAll(true);
    setExpandedIDs(emplArr);
    setExpandedDayIDs(attendanceArr);
  }

  function handleCollapseAll() {
    setExpandAll(false);
    setExpanded(false);
    setExpandedIDs([]);
    setExpandedDayIDs([]);
  }

  function displayIsLate(isLate) {
    return isLate === true ? (
      <ThemeProvider theme={theme}>
        <Tooltip title={t("late")}>
          <span style={{ paddingRight: "10px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-clock-exclamation"
              width="18"
              height="20"
              viewBox="0 -4 20 25"
              strokeWidth="1.5"
              stroke="#F15B40"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M20.986 12.502a9 9 0 1 0 -5.973 7.98" />
              <path d="M12 7v5l3 3" />
              <path d="M19 16v3" />
              <path d="M19 22v.01" />
            </svg>
          </span>
        </Tooltip>
      </ThemeProvider>
    ) : (
      <></>
    );
  }

  function displayIsEarly(isEarly) {
    return isEarly === true ? (
      <ThemeProvider theme={theme}>
        <Tooltip title={t("early")}>
          <span style={{ paddingRight: "10px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-clock-exclamation"
              width="18"
              height="20"
              viewBox="0 -4 20 25"
              strokeWidth="1.5"
              stroke="#E5DB40"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M20.986 12.502a9 9 0 1 0 -5.973 7.98" />
              <path d="M12 7v5l3 3" />
              <path d="M19 16v3" />
              <path d="M19 22v.01" />
            </svg>
          </span>
        </Tooltip>
      </ThemeProvider>
    ) : (
      <></>
    );
  }

  // function displayIsAutomaticallyClockedOut(isAutomaticallyClockedOut) {
  //   return isAutomaticallyClockedOut === true ? (
  //     <ThemeProvider theme={theme}>
  //       <Tooltip title={t("needs.review")}>
  //         <span style={{ paddingRight: "10px" }}>
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="icon icon-tabler icon-tabler-clock-exclamation"
  //             width="18"
  //             height="20"
  //             viewBox="0 -4 20 25"
  //             strokeWidth="1.5"
  //             stroke="#E5DB40"
  //             fill="none"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           >
  //             <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  //             <path d="M20.986 12.502a9 9 0 1 0 -5.973 7.98" />
  //             <path d="M12 7v5l3 3" />
  //             <path d="M19 16v3" />
  //             <path d="M19 22v.01" />
  //           </svg>
  //         </span>
  //       </Tooltip>
  //     </ThemeProvider>
  //   ) : (
  //     <></>
  //   );
  // }

  function Row(props) {
    const { row, collapsible } = props;
    const { attendanceGroups } = row;

    return (
      <React.Fragment>
        {row?.dayLog == true ? (
          <TableRow>
            <TableCell sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : expanded && expandedIDs.includes(row?.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row?.id)}
                    />
                  )}
                </IconButton>
              )}
              {!collapsible && (
                <IconButton aria-label="expand row" size="small">
                  <KeyboardArrowUpIcon style={{ color: "white" }} />
                </IconButton>
              )}
            </TableCell>
            <TableCell sx={{ width: "40%", padding: "0 16px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
                align="left"
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      paddingLeft: "50px",
                    }}
                  >
                    {getWorkType(row?.workType?.mnemonic)}{" "}
                    <span style={{ paddingLeft: "10px", fontSize: "13px" }}>
                      {row?.workType?.mnemonic ===
                      Mnemonics.CheckInLocations?.HOME
                        ? t("workType.fromHome")
                        : row?.location
                        ? row?.location?.name
                        : row?.workType?.description}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                          paddingLeft: "10px",
                        }}
                      >
                        {row?.toEntitlement
                          ? `(${row?.toEntitlement?.employeePTO?.pto?.description})`
                          : ""}
                      </span>
                    </span>
                  </span>
                )}
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
              }}
            >
              {UTC2Time(row?.checkInTime)}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
              }}
            >
              {UTC2Time(row?.checkOutTime)}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "10%",
              }}
            >
              {getWorkingHours(row?.workTimeHour, row?.workTimeMinute)}
              {/* {`${row?.workTimeHour ? row?.workTimeHour : "0"}.${
                row?.workTimeMinute
                  ? row?.workTimeMinute > 9
                    ? row?.workTimeMinute
                    : row?.workTimeMinute + "0"
                  : "00"
              }`} */}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.employeeGuid)}
                    />
                  ) : expanded && expandedIDs.includes(row?.employeeGuid) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.employeeGuid)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row?.employeeGuid)}
                    />
                  )}
                </IconButton>
              )}
            </TableCell>
            <TableCell
              sx={{ width: "40%", padding: "0 16px", fontWeight: "bold" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                {`${row?.employeeName}`}
                {/* <span style={{fontWeight: "bold", fontStyle: "italic", paddingLeft: "10px"}}>
                  {`(${row.totalWorkHours} hours)`} 
                </span> */}
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
              }}
            />
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
              }}
            />
            <TableCell
              align="center"
              sx={{
                width: "10%",
                fontWeight: "bold",
              }}
            >
              {row?.totalTime.toFixed(2)}
              {`/${row?.totalWorkHours}h`}
            </TableCell>
          </TableRow>
        )}

        {collapsible &&
          expanded &&
          expandedIDs.length != 0 &&
          expandedIDs.includes(row?.employeeGuid) && (
            <>
              {attendanceGroups &&
                attendanceGroups.map((attendance) => {
                  return (
                    <React.Fragment key={attendance?.id}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "5%",
                            textAlign: "end",
                            padding: "0 16px",
                          }}
                        >
                          {collapsible && (
                            <IconButton aria-label="expand row" size="small">
                              {expandedDay &&
                              expandedDayIDs.includes(attendance?.id) ? (
                                <KeyboardArrowUpIcon
                                  onClick={() =>
                                    handleCollapseProject(attendance?.id)
                                  }
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  onClick={() =>
                                    handleExpandProject(attendance?.id)
                                  }
                                />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "40%",
                            padding: "0 16px 0 26px",
                            fontWeight: "500",
                          }}
                        >
                          {UTC2Locale(attendance?.date, true)}{" "}
                          <span
                            style={{
                              color: "#033584",
                              fontStyle: "italic",
                              paddingLeft: "10px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            {UTC2Time(
                              attendance?.workshiftStartHour?.toLocaleString(),
                              false
                            )}{" "}
                            —{" "}
                            {UTC2Time(
                              attendance?.workshiftEndHour?.toLocaleString(),
                              false
                            )}
                            {/* {getTime(attendance?.workshiftStartHour)}{" "}—{" "}
                            {getTime(attendance?.workshiftEndHour)} */}
                          </span>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            width: "20%",
                            padding: "0 53px 0 16px",
                            fontWeight: "500",
                          }}
                        >
                          {displayIsLate(attendance?.isLate)}
                          {UTC2Time(attendance?.clockInTime)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            width: "20%",
                            padding: "0 49px 0 16px",
                          }}
                        >
                          <MarkBadge
                            success={attendance?.automaticallyClockedOut}
                            height="auto"
                            viewBox="0 0 24 20"
                          />
                          {displayIsEarly(attendance?.leftEarly)}
                          {UTC2Time(attendance?.clockOutTime)}{" "}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "10%",
                            fontWeight: "500",
                          }}
                        >
                          {attendance?.totalTime.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {collapsible && (
                        <TableRow
                          sx={{
                            display:
                              expandedIDs.length != 0 &&
                              expandedIDs.includes(row.employeeGuid) &&
                              expandedDay &&
                              expandedDayIDs.length != 0 &&
                              expandedDayIDs.includes(attendance?.id)
                                ? "table-row"
                                : "none",
                          }}
                        >
                          <TableCell
                            colSpan={5}
                            sx={{
                              padding: 0,
                              borderBottom: "none",
                            }}
                          >
                            <Collapse
                              in={expandedDay}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table size="small">
                                <TableBody>
                                  {attendance?.dayLogs &&
                                    attendance?.dayLogs.map((dayLog) => {
                                      return (
                                        <Row
                                          key={dayLog.id}
                                          row={dayLog}
                                          collapsible={false}
                                        />
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            </>
          )}
      </React.Fragment>
    );
  }

  return (
    <>
      {rows.length > 0 ? (
        <TableContainer component={Paper} className={"table-timesheet-height"}>
          <Table size="small" sx={{ overflowX: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "5%",
                    padding: "0 30px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {!expandAll ? (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t("table.ExpandAll")}>
                        <IconButton onClick={() => handleExpandAll()}>
                          <TableExpandIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t("table.CollapseAll")}>
                        <IconButton onClick={() => handleCollapseAll()}>
                          <TableCollapseIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  )}
                </TableCell>
                {/* <TableCell sx={{ width: "5%" }}>ID</TableCell> */}
                <TableCell
                  sx={{
                    width: "40%",
                    padding: "0",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {t("attendanceByEmployee.name")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "20%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {t("attendanceByEmployee.checkIn")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "20%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {t("attendanceByEmployee.checkOut")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "10%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {t("attendanceByEmployee.total")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} collapsible={true} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
