import React, { useState } from "react";
import { Drawer } from "antd";
import ContainerCard from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ConfirmationPopup from "../Popup";
import { v4 as uuidv4 } from "uuid";
import { IconButton } from "@mui/material";
import { CloseIcon } from "../Icon";

const FormModal = ({
  showModal,
  setShowModal,
  loading,
  refetch,
  open = false,
  modifyFormFlag,
  Child,
  disabledFields,
  object,
  guid = null,
}) => {
  const [openDialog, setOpenDialog] = useState(open);
  const [resetFields, setResetFields] = useState(false);

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setResetFields(true);
    setOpenDialog(false);
    setShowModal(false);
  };

  const miniSidenav = JSON.parse(localStorage.getItem("miniSidenav"));

  return (
    <>
      <ConfirmationPopup
        openDialog={showModal && openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <div style={{ marginLeft: miniSidenav === true ? "76px" : "256px" }}>
        <Drawer
          title=""
          className="project-drawer"
          placement={"right"}
          width={
            miniSidenav === true ? "calc(100vw - 76px)" : "calc(100vw - 256px)"
          }
          onClose={() => {
            if (open === true) {
              setOpenDialog(true);
            } else {
              setResetFields(true);
              setShowModal(false);
              if(!disabledFields) refetch();
            }
            setResetFields(false)
          }}
          headerStyle={{ display: "none" }}
          bodyStyle={{ padding: 0, marginTop: "5px" }}
          open={showModal}
          zIndex={1400}
          keyboard={true}
          push={false}
        >
          {loading ? (
            <ContainerCard
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </ContainerCard>
          ) : (
            <Paper
              sx={{
                padding: "0px 50px 0px 50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                overflowY: "auto",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display: "flex", justifyContent: "end"}}>
                  <IconButton
                    onClick={() => {
                      if (open === true) {
                        setOpenDialog(true);
                      } else {
                        setResetFields(true);
                        setShowModal(false);
                        if(!disabledFields) refetch();
                      }
                      setResetFields(false)
                    }}
                  >
                    <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{paddintTop: '0 !important', marginTop: '-30px !important'}}>
                  <Child
                    refetchData={refetch}
                    fromModal
                    resetFields={resetFields}
                    disabledFields={disabledFields}
                    object={object}
                    guid={guid}
                    handleCloseModal={(formFlag) => {
                      if (
                        open === true &&
                        (formFlag === null || formFlag === undefined)
                      ) {
                        setOpenDialog(true);
                      } else {
                        setResetFields(true);
                        setShowModal(false);
                        if(!disabledFields) refetch();
                      }
                      setResetFields(false)
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Drawer>
      </div>
    </>
  );
};

export default FormModal;
