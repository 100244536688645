const form = {
  formID: "task",
  formField: {
    title: {
      name: "title",
      label: "taskForm.title.label",
      requiredMessage: "taskForm.title.requiredMessage",
    },
    description: {
      name: "description",
      label: "taskForm.description.label",
      requiredMessage: "taskForm.description.requiredMessage",
    },
    employee: {
      name: "employee",
      label: "taskForm.employee.label",
      requiredMessage: "taskForm.employee.requiredMessage",
    },
    team: {
      name: "team",
      label: "taskForm.team.label",
    },
    task: {
      name: "task",
      label: "taskForm.task.label",
      requiredMessage: "taskForm.task.requiredMessage",
    },
    status: {
      name: "status",
      label: "taskForm.status.label",
      requiredMessage: "taskForm.status.requiredMessage",
    },
    project: {
      name: "project",
      label: "taskForm.project.label",
      requiredMessage: "taskForm.project.requiredMessage",
    },
    ticket: {
      name: "ticket",
      label: "taskForm.ticket.label",
      requiredMessage: "taskForm.ticket.requiredMessage",
    },
    startDate: {
      name: "startDate",
      label: "taskForm.startDate.label",
      requiredMessage: "taskForm.startDate.requiredMessage",
    },
    endDate: {
      name: "endDate",
      label: "taskForm.endDate.label",
      requiredMessage: "taskForm.endDate.requiredMessage",
    },
    EstimatedStartDate:{
      name: "EstimatedStartDate",
      label: "taskForm.EstimatedStartDate.label",
      requiredMessage: "taskForm.EstimatedStartDate.requiredMessage",
    },
    EstimatedEndDate:{
      name: "EstimatedEndDate",
      label: "taskForm.EstimatedEndDate.label",
      requiredMessage: "taskForm.EstimatedEndDate.requiredMessage",
    },
    EstimatedTime:{
      name: "EstimatedTime",
      label: "taskForm.EstimatedTime.label",
      requiredMessage: "taskForm.EstimatedTime.requiredMessage",
    },
    ActualStartDate:{
      name: "ActualStartDate",
      label: "taskForm.ActualStartDate.label",
      requiredMessage: "taskForm.ActualStartDate.requiredMessage",
    },
    ActualEndDate:{
      name: "ActualEndDate",
      label: "taskForm.ActualEndDate.label",
      requiredMessage: "taskForm.ActualEndDate.requiredMessage",
    },
    ActualTime:{
      name: "ActualTime",
      label: "taskForm.ActualTime.label",
      requiredMessage: "taskForm.ActualTime.requiredMessage",
    },
    ActualBillableTime:{
      name: "ActualBillableTime",
      label: "taskForm.ActualBillableTime.label",
      requiredMessage: "taskForm.ActualBillableTime.requiredMessage",
    },
    ActualNonBillableTime:{
      name: "ActualNonBillableTime",
      label: "taskForm.ActualNonBillableTime.label",
      requiredMessage: "taskForm.ActualNonBillableTime.requiredMessage",
    },
    synced:{
      name: "synced",
      label: "taskForm.synced.label",
      requiredMessage: "taskForm.synced.requiredMessage",
    },
    externalReference:{
      name: "externalReference",
      label: "taskForm.externalReference.label",
      requiredMessage: "taskForm.externalReference.requiredMessage",
    },
    area:{
      name: "area",
      label: "taskForm.area.label",
      requiredMessage: "taskForm.area.requiredMessage",
    },
    priority:{
      name: "priority",
      label: "taskForm.priority.label",
      requiredMessage: "taskForm.priority.requiredMessage",
    },
    allowCreateTimeEntry:{
      name: "allowCreateTimeEntry",
      label: "taskForm.allowCreateTimeEntry.label",
      requiredMessage: "taskForm.allowCreateTimeEntry.requiredMessage",
    },
    billable:{
      name: "billable",
      label: "taskForm.billable.label",
      requiredMessage: "taskForm.billable.requiredMessage",
    }
  },
};

export default form;