import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function RoleTabs({  myRoles = false }) {
  const { rName, rID, tName, tID } = useParams();

  const { tenant_role_form, my_roles_form, my_roles_resources, tenant_role_resources } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !rName ||
    !rID ||
    rName === "undefined" ||
    rName === "null" ||
    rName === "" ||
    rID === "null" ||
    rID === "undefined" ||
    rID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(rName);
  const encodedID = encodeURIComponent(rID);

  const TabLinks = [
    {
      key: 1,
      Header: t("roleTabs.form"),
      Link: myRoles
        ? my_roles_form.replace("/:rName/:rID", `/${encodedName}/${encodedID}`)
        : tenant_role_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:rName/:rID", `/${encodedName}/${encodedID}`),
    },
    {
      key: 2,
      Header: t("roleTabs.roleResources"),
      Link: myRoles
      ? my_roles_resources.replace("/:rName/:rID", `/${encodedName}/${encodedID}`)
      : tenant_role_resources
          .replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
          .replace("/:rName/:rID", `/${encodedName}/${encodedID}`),
    }
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default RoleTabs;
