import * as Yup from "yup";
import checkout from "./form";

import Mnemonics from "../../../../../Mnemonics.json";

const {
  formField: {
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    employmentType,
    workType,
    workShift,
    isAutoClockIn,
    defaultWorkCompany,
    department,
    employmentDate,
    preferEmail,
    position,
    isManager,
    employeeStatuses,
    probation,
    officialHoliday,
    resignationDate,
    timeZone,
    resourceType,
    contractor,
    canCreateKiosk,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.requiredMessage),
    [lastName.name]: Yup.string().required(lastName.requiredMessage),
    [email.name]: Yup.string().required(email.requiredMessage),
    [phoneNumber.name]: Yup.string().required(phoneNumber.requiredMessage),
    [role.name]: Yup.object().required(role.requiredMessage),
    [employmentType.name]: Yup.object().required(
      employmentType.requiredMessage
    ),
    [workType.name]: Yup.object().required(workType.requiredMessage),
    [workShift.name]: Yup.object().required(workShift.requiredMessage),
    [isAutoClockIn.name]: Yup.boolean().required(isAutoClockIn.requiredMessage),
    [preferEmail.name]: Yup.boolean().required(preferEmail.requiredMessage),

    [preferEmail.name]: Yup.boolean().required(preferEmail.requiredMessage),

    [defaultWorkCompany.name]: Yup.object().required(
      defaultWorkCompany.requiredMessage
    ),
    [department.name]: Yup.object().required(department.requiredMessage),
    [employmentDate.name]: Yup.date()
      .typeError(employmentDate.invalidMessage)
      .required(employmentDate.requiredMessage),
    [position.name]: Yup.string().required(position.requiredMessage),
    [officialHoliday.name]: Yup.object().required(
      officialHoliday.requiredMessage
    ),
    [resignationDate.name]: Yup.date()
      .nullable()
      .when(employeeStatuses.name, {
        is: (status) =>
          status && status.mnemonic === Mnemonics.EmploymentStatuses.Resigned,
        then: () => Yup.date().required(resignationDate.requiredMessage),
      }),
    [timeZone.name]: Yup.object().required(timeZone.requiredMessage),
    [resourceType.name]: Yup.string().required(resourceType.requiredMessage),
    [contractor.name]: Yup.object().nullable(),
    [canCreateKiosk.name]: Yup.bool().nullable(),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.requiredMessage),
    [lastName.name]: Yup.string().required(lastName.requiredMessage),
    [email.name]: Yup.string().nullable(),
    [phoneNumber.name]: Yup.string().required(phoneNumber.requiredMessage),
    [resourceType.name]: Yup.string().required(resourceType.requiredMessage),
    [employmentType.name]: Yup.object()
      .nullable()
      .when(resourceType.name, {
        is: (value) => 
          value && value.name === Mnemonics.ResourceTypes.Employee,
        then: Yup.object().required(employmentType.requiredMessage),
      }),
    [position.name]: Yup.string()
      .nullable()
      .when(resourceType.name, {
        is: (value) => 
          value && value.name === Mnemonics.ResourceTypes.Employee,
        then: Yup.object().required(position.requiredMessage),
      }),
    [department.name]: Yup.string()
      .nullable()
      .when(resourceType.name, {
        is: (value) => 
          value && value.name === Mnemonics.ResourceTypes.Employee,
        then: Yup.object().required(department.requiredMessage),
      }),
    [officialHoliday.name]: Yup.string()
      .nullable()
      .when(resourceType.name, {
        is: (value) => 
          value && value.name === Mnemonics.ResourceTypes.Employee,
        then: Yup.object().required(officialHoliday.requiredMessage),
      }),
    [workType.name]: Yup.object()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.object().required(workType.requiredMessage),
    }),
    [workShift.name]: Yup.object()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.object().required(workShift.requiredMessage),
    }),
    [isAutoClockIn.name]: Yup.boolean().required(isAutoClockIn.requiredMessage),
    [defaultWorkCompany.name]: Yup.object()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.object().required(defaultWorkCompany.requiredMessage),
    }),
    [department.name]: Yup.object()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.object().required(department.requiredMessage),
    }),
    [employmentDate.name]: Yup.date()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.date().required(employmentDate.requiredMessage),
    }),
    [position.name]: Yup.string()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.string().required(position.requiredMessage),
    }),
    [isManager.name]: Yup.boolean().required(isManager.requiredMessage),
    [officialHoliday.name]: Yup.object()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.object().required(officialHoliday.requiredMessage),
    }),
    [employeeStatuses.name]: Yup.object()
    .nullable()
    .when(resourceType.name, {
      is: (value) => 
        value && value.name === Mnemonics.ResourceTypes.Employee,
      then: Yup.object().required(employeeStatuses.requiredMessage),
    }),
    [probation.name]: Yup.number()
      .nullable()
      .when(employeeStatuses.name, {
        is: (status) =>
          status && status.mnemonic === Mnemonics.EmploymentStatuses.Probation,
        then: () => Yup.number().required(probation.requiredMessage),
      }),

    [resignationDate.name]: Yup.date()
      .nullable()
      .when(employeeStatuses.name, {
        is: (status) =>
          status && status.mnemonic === Mnemonics.EmploymentStatuses.Resigned,
        then: () => Yup.date().required(resignationDate.requiredMessage),
      }),
    [contractor.name]: Yup.object()
      .nullable()
      .when(resourceType.name, {
        is: (value) => 
          value && value.name === Mnemonics.ResourceTypes.Employee,
        then: () => Yup.object().required(contractor.requiredMessage),
      }),
    [timeZone.name]: Yup.object().required(timeZone.requiredMessage),
    [canCreateKiosk.name]: Yup.bool().nullable(),
  }),
];

export default validation;