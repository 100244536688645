import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileCard from "../../../../../components/Cards/FileCard";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import { AddIcon } from "../../../../../components/Icon";
import { base64StringtoFile } from "../../../../../utils/base64StringtoFile";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { LOGOUT_USER } from "../../../../../graphql/user";
import { DELETE_TASK_ATTACHMENT } from "../../../../../graphql/task";
import { parseJwt } from "../../../../../utils/parseJwt";
import useAuth from "../../../../../hooks/useAuth";
import ReactFileReader from "react-file-reader";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useState } from "react";
import ConfirmationModal from "../../../../../components/Modal/ConfirmationModal";
import CustomButton from "../../../../../components/CustomButton";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

const backendURL = process.env.REACT_APP_REST_URL;

function Attachment({ attachments, reload, taskGuid }) {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [loadingImage, setLoadingImage] = useState(false);

  const dispatch = useDispatch();

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  const { cfunction: deleteTaskAttachment } = useMutationAuth(
    DELETE_TASK_ATTACHMENT,
    "DeleteTaskAttachment"
  );

  const [taskAttachmentState, setTaskAttachmentState] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    logout,
    jwtToken,
    storeUserTenant,
    storeUserGuid,
    storeUserTenantName,
  } = useAuth();

  const handleFiles = async (files) => {
    const file = base64StringtoFile(files.base64, files.fileList[0].name);
    var formData = new FormData();
    formData.append("userID", storeUserGuid);
    formData.append("image", file);
    formData.append("tenantID", storeUserTenant);
    if (taskGuid) formData.append("itemID", taskGuid);

    uploadImage(formData);
  };

  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
  } 

  async function uploadImage(formData) {
    setLoadingImage(true);
    const res = await apiCall(
      backendURL + `/Task/UploadTaskAttachment`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );
  
    if (res) {
      enqueueSnackbar(t("attachment.uploadedSuccessfully"), {
        variant: "success",
      });
      reload('3');
      sessionStorage.removeItem("unsavedChanges");
    }
    setLoadingImage(false);
  }

  const handleDelete = (taskAttachmentGuid) => {
    setTaskAttachmentState(taskAttachmentGuid);
    setOpenDialog(true);
  };

  const handleSave = () => {
    setLoadingImage(true);
    deleteTaskAttachment(
      {
        variables: {
          model: {
            taskGuid: taskGuid.toString(),
            tenantName: storeUserTenantName,
            taskAttachmentGuid: taskAttachmentState,
          },
        },
      },
      (response) => {
        if (
          response["DeleteTaskAttachment"].errorCode !== null &&
          response["DeleteTaskAttachment"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["DeleteTaskAttachment"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        }
        else {
          enqueueSnackbar(t("attachment.deletedSuccessfully"), {
            variant: "success",
          });
          reload('3');
        }
        handleClose();
        setLoadingImage(false);
      }
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  return (
    <div style={{ marginTop: "30px", position: "relative" }}>
      <ConfirmationModal
        contentText={t("dialog.user.delete.attachment.confirmation")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <Backdrop
        sx={{ color: "#fff", position: "absolute !important", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingImage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!attachments || attachments?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px !important",
            }}
          >
            {t("taskForm.no.attachments")}
          </Typography>
          <ReactFileReader
            fileTypes={[".*"]}
            base64={true}
            handleFiles={handleFiles}
            multipleFiles={false}
          >
            <IALoadingButton
              label={t("taskForm.add.attachment")}
              sx={{
                textTransform: "capitalize !important",
                fontFamily: "Poppins !important",
                fontSize: "13px !important",
                margin: "10px 0",
              }}
            />
          </ReactFileReader>
          {/* <IALoadingButton
            label={t("taskForm.add.attachment")}
            sx={{
              textTransform: "capitalize !important",
              fontFamily: "Poppins !important",
              fontSize: "13px !important",
              marginTop: "10px",
            }}
          /> */}
        </Box>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginBottom: "10px",
            }}
          >
            <ReactFileReader
              fileTypes={[".*"]}
              base64={true}
              handleFiles={handleFiles}
              multipleFiles={false}
            >
              <AddIcon color={"#000"} width={"22"} height={"22"} />
            </ReactFileReader>
          </div>
          <div
            className="fileCard"
            style={{
              display: "flex",
              flexWrap: "wrap",
              columnGap: "20px",
              flexWrap: "wrap",
              maxHeight: "calc(100vh - 450px)",
              overflowY: "scroll",
              rowGap: "20px",
              paddingBottom: "20px",
            }}
          >
            {attachments?.map((attachment) => (
              <FileCard
                key={attachment.url}
                file={attachment}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Attachment;
