import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import urls from "../../../../urls";
import { CREATE_RELEASE_NOTE, GET_RELEASE_NOTE_BY_ID, UPDATE_RELEASE_NOTE } from "../../../../graphql/releaseNotes";
import { formatDateTimeZone } from "../../../../UTC2Local";
import hasEmptyTags from "../../../../utils/hasEmptyTags";

const ReleaseNotesForm = ({ handleCloseModal, fromDetails, fromNav }) => {
  const { rName, rID } = useParams();

  const createMode = (!rName && !rID) || fromNav;

  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const { manageParameters: manageParametersRole, isEntelligence, isAdmin } = userRoles;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const {
    description,
    releaseDate,
    version
  } = formField;

  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState(initialValues);

  const [ mediaList, setMediaList ] = useState([]);

  const { releaseNotes_details, releaseNotes_list } = urls;
  const [whatField, setWhatField] = useState(null);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_RELEASE_NOTE_BY_ID,
    "GetReleaseNotesByID",
    {
      variables: { id: rID },
      skip: createMode,
      isWait: !createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetReleaseNotesByID?.releaseNotes || {};

        setFormData({
          [description.name]: data?.description || "",
          [version.name]:data?.version || "",
          [releaseDate.name]:data.releaseDate == null
          ? null
          : new Date(formatDateTimeZone(data.releaseDate)),
        });
      },
    }
  );
  const { cfunction: editReleaseNote } = useMutationAuth(UPDATE_RELEASE_NOTE, "UpdateReleaseNotes");

  const { cfunction: CreateReleaseNotesData } = useMutationAuth(
    CREATE_RELEASE_NOTE,
    "CreateReleaseNotes"
  );

  function CreateReleaseNotes(values, actions) {

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(/<p>/g, '<p style="margin:0px">');
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    CreateReleaseNotesData(
      {
        isWait: releaseDate,
        variables: {
          model: {
            description: updatedDescription,
            version : values[version.name],
            releaseDate: values[releaseDate.name]
            ? new Date(values[releaseDate.name])
            : null,
            media: mediaList
          },
        },
      },
      (response) => {
        if (
          response["CreateReleaseNotes"].errorCode !== null &&
          response["CreateReleaseNotes"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateReleaseNotes"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("releases.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(releaseNotes_list)
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editReleaseNoteFun(values, actions) {

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(/<p>/g, '<p style="margin:0px">');
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    editReleaseNote(
      {
        isWait: releaseDate,

        variables: {
          model: {
            releaseGuid: rID,
            description: updatedDescription,
            version: values[version.name] ||  "",  
            releaseDate: values[releaseDate.name]
            ? new Date(values[releaseDate.name])
            : null,


          },
        },
      },
      (response) => {
        if (
          response["UpdateReleaseNotes"].errorCode !== null &&
          response["UpdateReleaseNotes"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateReleaseNotes"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("news.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(releaseNotes_list)

        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    if (createMode) {
      CreateReleaseNotes(values, actions);
    } else {
      editReleaseNoteFun(values, actions);
    }
  };

  const handleChangeFlag = (touched) => {
    if (touched) sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%" }}
            spacing={2}

          >
            <Grid item xs={12} sm={12} md={fromDetails ? 10 : 12} lg={fromDetails ? 10 : 12}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${createMode ? "creation" : "edit"
                    }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form id={form.formID} autoComplete="off"
                      onChange={() => { handleChangeFlag(touched)}}>
                      <Box p={fromDetails ? 3 : 0}>
                        <Box>
                          <Main

                            getDataByIDLoading={getDataByIDLoading}
                            formData={{
                              values,
                              fromDetails,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              handleCloseModal,
                              whatField,
                              setWhatField,
                              readOnly:
                                manageParametersRole === "R"

                            }}
                            handleChangeFlag={handleChangeFlag}
                            setMediaList={setMediaList}
                            mediaList={mediaList}
                            releaseNoteID={rID}
                          />
                        </Box>
                        {(isEntelligence || isAdmin) && <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton

                            loading={isSubmitting}
                            type="submit"
                            label={t("sugg.submit")}
                          />
                        </Box>}
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>

          </Grid>
        </Box>
      )}
    </>
  );
};

export default ReleaseNotesForm;
