import checkout from "./form";

const {
  formField: {
    description,
    paid,
    accrued,
    accumulated,
    numberOfDays,
    employmentType,
    assignDate,
    seniorities,
    dateUnit,
  },
} = checkout;

const initialValues = {
  [description.name]: "",
  [paid.name]: true,
  [accrued.name]: false,
  [accumulated.name]: false,
  [numberOfDays.name]: 0,
  [employmentType.name]: null,
  [assignDate.name]: null,
  [dateUnit.name]: null,
  [seniorities.name]: [],
};

export default initialValues;