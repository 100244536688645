import React, { useEffect, useRef, useState } from "react";

//components
import { Grid, Box, Backdrop, CircularProgress } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";

//translation
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import colors from "../../../../../assets/theme/light/colors";
import "./style.css";
import { useSnackbar } from "notistack";
import useAuth from "../../../../../hooks/useAuth";
import axios from "axios";
import { LOGOUT_USER } from "../../../../../graphql/user";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { parseJwt } from "../../../../../utils/parseJwt";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

const Main = ({
  formData,
  newsID = null,
  handleChangeFlag,
  mediaList,
  setMediaList,
}) => {
  const { t } = useTranslation();

  const backendURL = process.env.REACT_APP_REST_URL;

  const { enqueueSnackbar } = useSnackbar();

  const { values, touched, formField, errors, setFieldValue, setTouched } =
    formData;

  const { description } = formField;
  const { description: descriptionV } = values;

  const { storeUserTenant, jwtToken, logout } = useAuth();

  const dispatch = useDispatch();

  const [loadingImage, setLoadingImage] = useState(false);

  const quillRef = useRef();
  const imageUrl = useRef("");

  const handleQuillChange = (html) => {
    if (touched[description.name]) handleChangeFlag(touched[description.name]);
    setFieldValue(description.name, html);
  };

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  const removeImg = () => {
    const images = document.querySelectorAll("img");
    // Loop through each image element
    images.forEach((img) => {
      // Check if the src attribute contains "data:image/png;base64"
      if (img.src.startsWith("data:image/")) {
        // Remove the image element from the DOM
        img.remove();
      }
    });
  };

  async function uploadImage(formData, newImage) {
    setLoadingImage(true);
    const quill = quillRef.current;
    let range =
      quill.getEditorSelection() || quill?.editor?.selection?.savedRange;

    const res = await apiCall(
      backendURL + `/News/UploadNewsImage`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      let tempMediaList = mediaList;
      tempMediaList.push(res.data.fileName);
      setMediaList(tempMediaList);
      imageUrl.current = res.data.url;
      if (newImage === true) {
        removeImg();
        quill
          .getEditor()
          .insertEmbed(range.index, "image", `${imageUrl.current}`);
      }
      setLoadingImage(false);
    } else {
      setLoadingImage(false);
      removeImg();
    }

    return imageUrl.current;
  }

  function imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      // var file = input && input.files ? input.files[0] : null;
      var file = input.files[0];
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var formDataObj = new FormData();
        formDataObj.append("image", file, file.name);
        formDataObj.append("tenantID", storeUserTenant);
        if (newsID) formDataObj.append("itemID", newsID);
      }
      uploadImage(formDataObj);
    };
  }

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [({ direction: "" }, { direction: "rtl" })],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          // ["hight"],
          ["clean"],
          ["image"],
          ["link"],
        ],
        handlers: {
          image: imageHandler,
        },
        clipboard: {
          matchVisual: false,
        },
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
      },
    }),
    []
  );

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
  ];

  function extractTypeFromDataURL(dataURL) {
    const base64String = dataURL.split(",")[0].split(";")[0];
    const type = base64String.substring(5);
    return type;
  }

  function base64ToBlob(base64Data, contentType = "image/png") {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function base64ToFile(base64Data, filename, contentType) {
    const blob = base64ToBlob(base64Data, contentType);
    return new File([blob], filename, { type: contentType });
  }

  async function replaceAndUploadImage(htmlText, filenameTemplate) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");

    const images = doc.querySelectorAll("img");
    let replacements = [];

    for (let img of images) {
      if (img.src.startsWith("data:image/")) {
        const base64String = img.src.split(",")[1]; // Extract base64 without the prefix
        const contentType = extractTypeFromDataURL(img.src);
        const ext = contentType.split("/")[1];
        const filename = `${generateRandomName()}.${ext}`;

        const file = base64ToFile(base64String, filename, contentType);

        // Prepare form data for the upload
        let formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (newsID) formData.append("itemID", newsID);

        // Upload the image and replace the src with the new URL
        const newUrl = await uploadImage(formData, false);
        img.src = newUrl; // Update the src in the DOM

        replacements.push(newUrl);
      }
    }
    return doc.body.innerHTML;
  }

  function generateRandomName(length = 12) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  useEffect(() => {
    const editor = document.querySelector(".ql-editor");

    if (editor) {
      editor?.addEventListener("paste", async (event) => {
        let data = event.clipboardData || window.clipboardData;
        let html = event.explicitOriginalTarget;
        if (data.files?.length > 0) {
          event.preventDefault();
          var file = data.files[0];
          var imageType = /image.*/;

          if (file.type.match(imageType)) {
            var formData = new FormData();
            formData.append("image", file, file.name);
            formData.append("tenantID", storeUserTenant);
            if (newsID) formData.append("itemID", newsID);
          }
          await uploadImage(formData, true);
        } else if (html?.innerHTML) {
          const innerHTML = await replaceAndUploadImage(
            html?.innerHTML,
            generateRandomName()
          );
          setFieldValue(description.name, innerHTML);
        }
      });
    }
  }, []);

  useEffect(() => {
    quillRef.current?.focus();
  }, [quillRef.current]);

  return (
    <Box>
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          sx={{
            maxWidth: "400px !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="flex_space-between_center"
        >
          <Title style={{ fontSize: "13px !important", lineHeight: "2rem" }}>
            {t("news.form.content")}
          </Title>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mt={3}
              mb={3}
              className="news-textArea"
            >
              <div
                style={{ position: "relative" }}
                onFocus={() =>
                  setTouched({ ...touched, [description.name]: true })
                }
              >
                <Backdrop
                  sx={{
                    color: "#fff",
                    position: "absolute !important",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loadingImage}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <ReactQuill
                  ref={quillRef}
                  name="body"
                  value={descriptionV}
                  onChange={handleQuillChange}
                  modules={modules}
                  formats={formats}
                  placeholder="Description"
                />
              </div>
              <div
                className="tooltip-link"
                style={{
                  fontFamily: "poppins",
                  lineHeight: "21px",
                  backgroundColor: "#ffffff",
                  color: "#000",
                  borderRadius: "2px",
                  opacity: "0.8",
                  boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                  padding: "13px !important",
                }}
              >
                {t("tooltip.link")}
              </div>
              <div
                className="tooltip-clean"
                style={{
                  fontFamily: "poppins",
                  lineHeight: "21px",
                  backgroundColor: "#ffffff",
                  color: "#000",
                  borderRadius: "2px",
                  opacity: "0.8",
                  boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                  padding: "13px !important",
                }}
              >
                {t("tooltip.clean")}
              </div>
              {/* <TextArea
                variant="standard"
                fullWidth
                placeHolder={t("sugg.desc")}
                multiline
               
                value={descriptionV}
                required
                onBlur={(e) =>
                  setTouched({ ...touched, [description.name]: true })
                }
                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                error={touched[description.name] && errors[description.name]}
                helperText={
                  touched[description.name] &&
                  errors[description.name] &&
                  t(errors[description.name])
                }
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
