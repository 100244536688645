import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function CompanyTabs({ fromTenant = false, myCompany = false }) {
  const { cName, cID, tName, tID } = useParams();

  const {
    company_form,
    tenant_company_form,
    my_company_form,
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !cName ||
    !cID ||
    cName === "undefined" ||
    cName === "null" ||
    cName === "" ||
    cID === "null" ||
    cID === "undefined" ||
    cID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(cName);
  const encodedID = encodeURIComponent(cID);

  const TabLinks = [
    {
      key: 1,
      Header: t("companyTabs.form"),
      Link: fromTenant
        ? tenant_company_form
            .replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
            .replace(
              "/:cName/:cID",
              `/${encodeURIComponent(cName)}/${encodeURIComponent(cID)}`
            )
        : myCompany
        ? my_company_form.replace(
            "/:cName/:cID",
            `/${encodedName}/${encodedID}`
          )
        : company_form.replace("/:cName/:cID", `/${encodedName}/${encodedID}`),
    }
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default CompanyTabs;
