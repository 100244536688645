import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { DueCalendarIcon, getOptionPriorityIcon, HourGlassIcon } from "../Icon";
import colors from "../../assets/theme/light/colors";
import { formatDateTimeToString } from "../../UTC2Local";
import { useState, useEffect } from "react";
import Mnemonics from "../../Mnemonics.json";
import { Button } from "antd";
import './KanBanCard.css'
import { TaskIcon } from '../../components/Icon';
import useMutationAuth from "../../hooks/useMutationAuth";
import { UPDATE_TASK_QUICK } from "../../graphql/task";
import { enqueueSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

const GetCard = (taskGuid, props, t) => {
  const { task, project, dueDate, priority, actualTime, taskId, type, assignedTo, refetch, storeUserGuid } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const { cfunction: updateTaskQuickData } = useMutationAuth(
    UPDATE_TASK_QUICK,
    "UpdateTaskQuick"
  );

  const updateTaskQuick = (taskGuid, priority) => {
    updateTaskQuickData(
      {
        variables: {
          model: {
            taskGuid: taskGuid,
            priority: priority,
            userID: storeUserGuid,
            key: uuidv4(),
          },
        },
      },
      (response) => {
        if (
          response["UpdateTaskQuick"].errorCode !== null &&
          response["UpdateTaskQuick"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateTaskQuick"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });

        } else {
          enqueueSnackbar(t("board.savedSuccessfully"), {
            variant: "success",
          });
        }
      },
      (error) => {
      }
    );
  };
  const handleMenuItemClick = (event, value) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (updateTaskQuick && !sessionStorage.getItem("unsavedChanges")) {
      updateTaskQuick(taskGuid, value);
    }
  };


  const priorityStatus = () => {
    return (
        <div>
            <Tooltip title={priority}>
                <Button
                    style={{
                        padding: '2px',
                        border: 0,
                        marginRight: '7px',
                        marginTop: '3px',
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    }}
                    onMouseOver={({ currentTarget }) => currentTarget.style.backgroundColor = 'transparent'}
                    onMouseOut={({ currentTarget }) => currentTarget.style.backgroundColor = 'transparent'}
                >
                    {getOptionPriorityIcon(priority)}
                </Button>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.values(Mnemonics?.PriorityStatuses)
                    .filter(option => option !== Mnemonics.PriorityStatuses.None)
                    .map(option => (
                        <MenuItem
                            key={option}
                            onClick={(event) => handleMenuItemClick(event, option)}
                        >
                            <Box component="span" sx={{ "& > svg": { mr: 2, flexShrink: 0 } }}>
                                {getOptionPriorityIcon(option)}
                            </Box>
                            {option}
                        </MenuItem>
                    ))}
            </Menu>
        </div>
    );
}



  const nameToColor = (name) => {
    const colors = ['#e57373', '#64b5f6', '#81c784', '#ffb74d', '#ba68c8', '#ff8a65', '#4db6ac', '#f06292', '#7986cb', '#a1887f'];
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
  };

  const renderInitialCircle = (name) => {
    if (!name) return null;
    const initial = name.charAt(0).toUpperCase();
    const backgroundColor = nameToColor(name);
    return (
      <Box sx={{
        width: 21,
        height: 21,
        backgroundColor: backgroundColor,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "14px",
        marginRight: "8px",
      }}>
        {initial}
      </Box>
    );
  };

  const TaskTypeInfo = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
      {type === "subtask" ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-forward" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1" stroke={colors.light_logo_blue} fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M15 11l4 4l-4 4m4 -4h-11a4 4 0 0 1 0 -8h1" />
        </svg>
      ) : (
        <TaskIcon />
      )}
      <Typography sx={{ ml: 0.5, fontSize: "12px", fontWeight: "bold", color: "gray" }}>
        {taskId}
      </Typography>
    </Box>
  );



  return (
    <>
      <CardContent className="kanban-card" sx={{ padding: "8px", maxWidth: "250px", "&:last-child": { paddingBottom: "0.5px" } }}>
        <Typography gutterBottom variant="body1" sx={{ fontSize: '13px', flexGrow: 1, overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto', marginBottom: "8px" }}>
          {task}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1, marginBottom: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={assignedTo}>
              {renderInitialCircle(assignedTo)}
            </Tooltip>
            <Tooltip title={type === "subtask" ? t("taskForm.subtaskInformation") : t("taskForm.taskInformation")}>
              {TaskTypeInfo()}
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            {priorityStatus()}
            <Tooltip title='Actual Time'>
              <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                <HourGlassIcon color={colors.icon_button_grey} width="16" height="16" />
                {actualTime !== null && (
                  <Typography sx={{ ml: 0.5, fontSize: "11px" }}>
                    {actualTime.toFixed(2)}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </CardContent>
    </>
  );
};

export default function KanbanCard({ onClick, taskGuid, ...props }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ minWidth: 250, marginBottom: "4px" }} onClick={onClick} >
      <Card variant="outlined">
        {GetCard(taskGuid, props, t)}
      </Card>
    </Box>
  );
}

