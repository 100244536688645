import React, { useState, useEffect } from "react";

import { IconButton, Button } from "@mui/material";
import Tooltip from "../../../../../components/Tooltip";
import ConfirmationModal from "../../../../../components/Modal/ConfirmationModal";
import CustomButton from "../../../../../components/CustomButton";

//translation
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "../../../../../components/Icon";

const DeleteRecord = (props) => {
  const { ticketGuid, taskGuid, handleClick } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState(null);

  let redValue = "var(--color-error)";

  const { t } = useTranslation();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleClick(selectedRowID);
    handleClose();
  };

  const handleOpenDeleteConfirmationModal = (id) => {
    setSelectedRowID(id);
    setOpenDialog(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setSelectedRowID(null);
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  if (!ticketGuid && !taskGuid) return null;
  return (
    <>
      <ConfirmationModal
        contentText={t("dialog.user.delete.record.confirmation")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <Tooltip title={t("table.Delete")}>
        <IconButton
          style={{ paddingRight: "5px" }}
          onClick={() => {
            props.tasks != null
              ? handleOpenDeleteConfirmationModal(props.ticketGuid)
              : handleOpenDeleteConfirmationModal(props.taskGuid);
          }}
        >
          <DeleteIcon color={redValue} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DeleteRecord;
