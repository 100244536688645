import React, { useState, useCallback, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";



//translation
import { useTranslation } from "react-i18next";

//component
import GridTable from "../../../../components/Tables/GridTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//gql
import {
  GET_ALL_CONTRACTORS,
  DELETE_CONTRACTORS,
  CREATE_CONTRACTOR,
  UPDATE_CONTRACTOR,
} from "../../../../graphql/contractor";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";

import { getDataTableData } from "./_table";

function ContractorsList({ fromTenant = false, fromModal = false, handleCloseModal  }) {
  const { tID } = useParams();

  const { storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const DEFAULT_CONTRACTOR = {
    contractorGuid: "",
    name: "",
    tenantID: tID ? tID : storeUserTenant,
  };

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [re, setRe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newObj, setNewObj] = useState({
    contractorGuid: "",
    name: "",
    tenantID: tID ? tID : storeUserTenant,
  });
  const requiredFields=["contractorGuid", "name"];
  
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);
 
  useEffect(() => {
    const col = getDataTableData(t);
    if (col) {
      setColumns(col.columns);
    }
  }, [columns.length]);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_CONTRACTORS,
    "DeleteContractor"
  );

  const { mfunction: getAllContractors, loading } = useLazyQueryAuth(
    GET_ALL_CONTRACTORS,
    "GetContractorsList"
  );

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    getAllContractors(
      {
        variables: {
          model: {
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            keyword: search,
            tenantGuid: tID ? tID : storeUserTenant,
          },
        },
      },
      (response) => {
        setData(response);
        setRows(response?.GetContractorsList?.contractorsList?.contractors);
      }
    );
  }, []);

  const records =
    data &&
    data.GetContractorsList &&
    data.GetContractorsList.contractorsList &&
    data.GetContractorsList.contractorsList.contractors
      ? data.GetContractorsList.contractorsList.contractors
      : [];
  const numberRecords =
    data &&
    data.GetContractorsList &&
    data.GetContractorsList.contractorsList &&
    data.GetContractorsList.contractorsList.contractors &&
    data.GetContractorsList.contractorsList.totalNumberOfRecords
      ? data.GetContractorsList.contractorsList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (setCreate.current === false) fetchTableData(page, rowsPerPage, search);
  }, [fetchTableData, re]);

  const handleDelete = (selectedItem) => {
    deleteRecords(
      {
        variables: {
          model: {
            contractorIDs: selectedItem,
          },
        },
      },
      (response) => {
        if (response["DeleteContractor"].deleteContractorResponse == true) {
          enqueueSnackbar(t("contractor.list.delete.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        } else {
        }
      }
    );
  };

  const { cfunction: createData } = useMutationAuth(
    CREATE_CONTRACTOR,
    "CreateContractor"
  );

  const { cfunction: updateData } = useMutationAuth(
    UPDATE_CONTRACTOR,
    "UpdateContractor"
  );

  function CreateData(newRow) {
    setIsSubmitting(true);
    if (newRow != null && newRow.contractorGuid != null) {
      createData(
        {
          variables: {
            model: {
              name: newRow.name,
              tenantGuid: newRow.tenantID,
            },
          },
        },
        (response) => {
          if (
            response["CreateContractor"].errorCode !== null &&
            response["CreateContractor"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateContractor"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            setRows(rows.filter((r) => r.contractorGuid !== newObj.contractorGuid));
            setNewObj(DEFAULT_CONTRACTOR);
          } else {
            enqueueSnackbar(t("contractorForm.createdSuccessfully"), {
              variant: "success",
            });
            fetchTableData(page, rowsPerPage, search);
            setNewObj(DEFAULT_CONTRACTOR);
            if(fromModal === true && handleCloseModal !== undefined) handleCloseModal(true);
          }
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        },
        (error) => {
          setRows(rows.filter((r) => r.contractorGuid !== newObj.contractorGuid));
          setNewObj(DEFAULT_CONTRACTOR);
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        }
      );
    }
  }

  function UpdateData(updatedRow) {
    updateData(
      {
        variables: {
          model: {
            contractorGuid: updatedRow.contractorGuid,
            name: updatedRow.name,
          },
        },
      },
      (response) => {
        if (
          response["UpdateContractor"].errorCode !== null &&
          response["UpdateContractor"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateContractor"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_CONTRACTOR);
        } else {
          enqueueSnackbar(t("contractorForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_CONTRACTOR);
          if(fromModal === true && handleCloseModal !== undefined) handleCloseModal(true);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_CONTRACTOR);
      }
    );
  }

  const pagination = {
    rows: rows.length == 0 ? records : rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    removeFilters:true,
    fetchTableData,
    loading: loading,
    searchText: "contractor.list.search.label",
    deleteText: "contractor.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== 'M',
    isSubmitting,
    fetchedBool: true,
    requiredFields,
    enqueueSnackbar
  };

  return (
    <div>
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns &&
        columns.length > 0 && (
          <GridTable
            {...pagination}
            identifier="contractorGuid"
            hasCheckbox={false}
            handleCreate={(contractor) => CreateData(contractor)}
            handleUpdate={(contractor) => UpdateData(contractor)}
            handleDelete={(contractors) => handleDelete(contractors)}
          />
        )
      )}
    </div>
  );
}

export default ContractorsList;
