import PropTypes from "prop-types";

const FloatNumber = ({ number }) => {
  if (!number) return null;

  return (
    parseFloat(number && number?.value !== null && number?.value !== undefined ? number?.value : number).toFixed(2) + " " +
    `${number?.unit ? number.unit : ""}`
  );
};

export default FloatNumber;

FloatNumber.propTypes = {
  number: PropTypes.number,
};

FloatNumber.defaultProps = {
  number: 0,
};
