//hooks
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import useAuth from "./useAuth";

//translation
import { useTranslation } from "react-i18next";

// import { LOGOUT_USER } from "graphql/user";
import useMutationAuth from "./useMutationAuth";

import { LOGOUT_USER } from "../graphql/user"
import { saveToken } from "../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";
import { store } from "../app/store";

function useLazyQueryAuth(QUERY, objAccess, params, statusFunctions) {
  const { t } = useTranslation();

  // Snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { logout } = useAuth();

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  //defines mutation function
  const [call_function, { data, loading, error, refetch }] = useLazyQuery(
    QUERY,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      ...params,
    }
  );

  const mfunction = async (
    params,
    onCompletedFunc = undefined,
    onErrorFunc = undefined
  ) => {
    await call_function({
      ...params,
      onCompleted: (data) => {
        if (data[objAccess].errorMessage === "IXIA_ACTIV_UNAUTHORIZED") {
          logsOutUser(
            {
              variables: {
                model: {
                  jwtToken: store?.getState()?.token?.token
                },
              },
            },
            () => {
              logout();
            }
          );
        } else if (data[objAccess].errorMessage === "IXIA_ACTIV_NEW_TOKEN") {
          sessionStorage.removeItem("unsavedChanges");
          mfunction(
            {
              ...params,
              context: {
                headers: {
                  authorization: `${
                    data[objAccess].errorVariables.trim()
                      ? "Bearer " + data[objAccess].errorVariables.trim()
                      : ""
                  }`,
                },
              },
            },
            onCompletedFunc,
            onErrorFunc
          );
          dispatch(saveToken(data[objAccess].errorVariables.trim()));
        } else if (
          data[objAccess].errorCode !== null &&
          data[objAccess].errorCode !== ""
        ) {
          enqueueSnackbar(t(data[objAccess].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        } else if (onCompletedFunc !== undefined) onCompletedFunc(data);
      },
      onError: () => {
        enqueueSnackbar(t("systemError"), {
          variant: "error",
          autoHideDuration: 5000
        });
        if (onErrorFunc !== undefined) onErrorFunc(error);
      },
    });
  };

  const cfunction = async (
    params,
    onCompletedFunc = undefined,
    onErrorFunc = undefined
  ) => {
    await call_function({
      ...params,
      onCompleted: (data) => {
        if (data[objAccess].errorMessage === "IXIA_ACTIV_UNAUTHORIZED") {
        } else if (data[objAccess].errorMessage === "IXIA_ACTIV_UNAUTHORIZED") {
        } else if (onCompletedFunc !== undefined) onCompletedFunc(data);
      },
      onError: (error) => {
        enqueueSnackbar(t("systemError"), {
          variant: "error",
          autoHideDuration: 5000
        });
        if (onErrorFunc !== undefined) onErrorFunc(error);
      },
    });
  };

  return {
    mfunction,
    loading,
    error,
    refetch,
    call_function,
    cfunction,
    data,
  };
}

export default useLazyQueryAuth;

useLazyQueryAuth.defaultProps = {
  QUERY: null,
  objAccess: "",
  params: {},
  statusFunctions: {},
};
