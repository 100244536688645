import checkout from "./form";

const {
  formField: {
    title,
    description,
    status,
    company,
    location,
    client,
    tenant,
    synced,
    externalReference,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
  },
} = checkout;

const initialValues = {
  [title.name]:"",
  [description.name]:"",
  [status.name]:null,
  [company.name]:null,
  [tenant.name]:null,
  [location.name]:null,
  [client.name]:null,
  [externalReference.name]:"",
  [synced.name]: false,
  [EstimatedEndDate.name]: null,
  [EstimatedStartDate.name]: null,
  [EstimatedTime.name]: "",
  [ActualEndDate.name]: null,
  [ActualStartDate.name]: null,
  [ActualTime.name]: "",

};

export default initialValues;