export const getTimeDifferenceHours = (time1, time2) => {
  if (!time1 || !time2) return;

  //converts to actual datetime format
  let FirstTime = new Date(time1);
  let SecondTime = new Date(time2);
  //calculates the time difference in milliseconds
  let timeDifference = Math.abs(FirstTime - SecondTime);

  // Convert time difference to hours
  let hours = Math.floor(timeDifference / (1000 * 60 * 60));

  hours = hours % 24; //since date is taken into consideration when calculating the difference we normaliza it to 24 hours

  if (hours > 0) return hours;

  return null;
};

export const getTimeDifferenceMinutes = (time1, time2) => {
  if (!time1 || !time2) return;

  //converts to actual datetime format
  let FirstTime = new Date(time1);
  let SecondTime = new Date(time2);

  //calculates the time difference in milliseconds
  let timeDifference = Math.abs(FirstTime - SecondTime);
  if(timeDifference === 0)
    return { hours: 0, minutes: 0 };

  // Convert time difference to minutes
  let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (minutes > 0) return minutes;

  return null;
};

export const getTimeDifference = (time1, time2) => {
  if (!time1 || !time2) return;

  const referenceDate = new Date("1970-01-01");
  const referenceDate2 = new Date("1970-01-01");

  // Parse time strings and set them on the reference date
  let firstTimeParts = time1.split(":");
  let secondTimeParts = time2.split(":");

  referenceDate.setHours(parseInt(firstTimeParts[0]));
  referenceDate.setMinutes(parseInt(firstTimeParts[1]));

  let firstTime = referenceDate.getTime();

  referenceDate2.setHours(parseInt(secondTimeParts[0]));
  referenceDate2.setMinutes(parseInt(secondTimeParts[1]));

  let secondTime = referenceDate2.getTime();

  // Calculate the time difference in milliseconds
  let timeDifference = Math.abs(firstTime - secondTime);

  // Convert time difference to hours
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));

  // Convert remaining milliseconds to minutes
  const remainingMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (hours > 0 || remainingMinutes > 0) {
    return { hours, minutes: remainingMinutes };
  }

  return null;
};

export const handleValidateDates = (timer, setTimer, dateFrom, value, setDateTo, enqueueSnackbar, t) => {
  if (timer) {
    //if timer is set, we empty it so it's not called and wait another delay
    clearTimeout(timer);
    setTimer(null);
  }

  //set timer for after 2 seconds of wait
  setTimer(
    setTimeout(() => {
      const oneDayBefore = new Date(dateFrom);
      oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
      if (value < oneDayBefore)
        enqueueSnackbar(t("employeeTimesheet.invalid.date"), {
          variant: "error",
          autoHideDuration: 5000,
        });
      else setDateTo(value);
    }, 500)
  );
};

function stripHTML(htmlString) {
  // Create a new div element
  var tempDiv = document.createElement("div");
  // Set the HTML content with the given string
  tempDiv.innerHTML = htmlString;
  // Retrieve the text property of the element (removes all HTML tags)
  return tempDiv.textContent || tempDiv.innerText || "";
}

export const compareStrings = (htmlString1, htmlString2) => {
  // Strip HTML from both strings
  var text1 = stripHTML(htmlString1);
  var text2 = stripHTML(htmlString2);
  // Normalize whitespace and compare
  return text1.trim() === text2.trim();
}