//external component
import { Box, styled } from "@mui/material";

export default styled(Box)(({ theme, ownerState }) => {
  const { breakpoints } = theme;

  const { miniSidenav } = ownerState;

  return {
    width: "100%",
    margin: "7px",
    [breakpoints.up("lg")]: {
      marginLeft: miniSidenav ? "76px" : "256px",
    },
  };
});
