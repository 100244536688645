import React from "react";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ConfirmationPopup = ({ handleOpenDialog, handleAgree, openDialog, title }) => {
  const { t } = useTranslation();
  return (
    <div className="userConfirmationDialog">
      <Dialog
        open={JSON.parse(sessionStorage.getItem("unsavedChanges")) === true && openDialog === true}
        onClose={() => handleOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title ? title : t("dialog.title.first")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("dialog.user.confirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenDialog(false)}>{t("dialog.cancel")}</Button>
          <Button onClick={handleAgree}>{title ? t("dialog.continue") : t("dialog.leave")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationPopup;
