import { React, useState } from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
//react router dom
import { useParams } from "react-router-dom";

//components
import { Grid, Box } from "@mui/material";
import { Subtitle, Title } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";
import TextField from "../../../../../components/TextFields/TextField";

//graphql
import { GET_ALL_TENANTS } from "../../../../../graphql/tenant";
import { GET_ALL_BUSINESS_CENTRAL_VERSIONS } from "../../../../../graphql/systemVariables";

import Mnemonics from "../../../../../Mnemonics.json";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";

//translation
import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const { tID, tName } = useParams();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    wait,
    readOnly,
  } = formData;

  const { storeUserGuid } = useAuth();

  const {
    name,
    tenant,
    version,
    externalReference,
    connectionString,
    userName,
    password,
    clientId,
    secretId,
    tenantId,
  } = formField;
  const [showPassword, setShowPassword] = useState(false);

  const {
    name: nameV,
    tenant: tenantV,
    version: versionV,
    externalReference: externalReferenceV,
    connectionString: connectionStringV,
    userName: userNameV,
    password: passwordV,
    clientId: clientIdV,
    secretId: secretIdV,
    tenantId: tenantIdV,
  } = values;

  // const { data: tenantsList, loading: tenantLoading, tokenChange: tenantWait } = useQueryAuth(
  //   GET_ALL_TENANTS,
  //   "GetAllTenants",
  //   {
  //     variables: {
  //       model: {
  //         enableFilters: false,
  //         userID: storeUserGuid,
  //       },
  //     },
  //     skip: wait || !createMode || myCompany,
  //     wait: myCompany ? false : true
  //   }
  // );

  // const tenant_list =
  //   tenantsList &&
  //   tenantsList.GetAllTenants &&
  //   tenantsList.GetAllTenants.tenantsList &&
  //   tenantsList.GetAllTenants.tenantsList.tenants
  //     ? tenantsList.GetAllTenants.tenantsList.tenants
  //     : [];

  const { data: versionsList, loading: versionLoading } = useQueryAuth(
    GET_ALL_BUSINESS_CENTRAL_VERSIONS,
    "GetBusinessCentralVersionsList",
    {
      skip: wait,
      wait: true,
    }
  );

  const businessCentralVersions =
    versionsList &&
    versionsList.GetBusinessCentralVersionsList &&
    versionsList.GetBusinessCentralVersionsList.businessCentralVersions
      ? versionsList.GetBusinessCentralVersionsList.businessCentralVersions
      : [];

  // const businessCentralVersions = [
  //   { value: "On Prem", label: "On Prem" },
  //   { value: "On Cloud", label: "On Cloud" },
  //   { value: "N/A", label: "N/A" },
  // ];

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title style={{ padding: "10px 0" }}>
            {t("companyForm.companyInformation.title")}
          </Title>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            autoFocus
            fullWidth
            value={nameV}
            onChange={(e) => {
              setFieldValue(name.name, e.target.value);
            }}
            label={t(name.label)}
            name={name.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>

        {/* {!tID && !tName && !myCompany && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AutoComplete
                options={tenant_list}
                getOptionLabel={(option) => (option ? option.name : "")}
                identifier={"tenantGuid"}
                disabled={createMode === false || readOnly}
                value={tenantV}
                onChange={(e, value) => {
                  setFieldValue(tenant.name, value);
                }}
                loading={tenantLoading}
                name={tenant.name}
                label={t(tenant.label)}
                formData={formData}
                required
                fullWidth
              />
            </Grid>
          </>
        )} */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AutoComplete
            options={businessCentralVersions}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"businessCentralVersionGuid"}
            value={versionV}
            onChange={(e, value) => {
              setFieldValue(version.name, value);
            }}
            loading={versionLoading}
            required
            name={version.name}
            fullWidth
            label={t(version.label)}
            formData={formData}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TextField
            fullWidth
            value={externalReferenceV}
            onChange={(e) => {
              setFieldValue(externalReference.name, e.target.value);
            }}
            label={t(externalReference.label)}
            name={externalReference.name}
            formData={formData}
            required={
              versionV &&
              versionV.mnemonic &&
              versionV.mnemonic !== Mnemonics.BusinessCentralVersions.NA
            }
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            value={connectionStringV}
            onChange={(e) => {
              setFieldValue(connectionString.name, e.target.value);
            }}
            label={t(connectionString.label)}
            name={connectionString.name}
            formData={formData}
            required={
              versionV &&
              versionV.mnemonic &&
              versionV.mnemonic !== Mnemonics.BusinessCentralVersions.NA
            }
            disabled={readOnly}
          />
        </Grid>
        {versionV &&
          versionV.mnemonic === Mnemonics.BusinessCentralVersions.OnPrem && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
                <Subtitle>{t("version.on.prem")}</Subtitle>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  value={userNameV}
                  onChange={(e) => {
                    setFieldValue(userName.name, e.target.value);
                  }}
                  label={t(userName.label)}
                  name={userName.name}
                  formData={formData}
                  required={
                    versionV &&
                    versionV.mnemonic &&
                    versionV.mnemonic !==
                      Mnemonics.BusinessCentralVersions.OnPrem
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  value={passwordV}
                  onChange={(e) => {
                    setFieldValue(password.name, e.target.value);
                  }}
                  label={t(password.label)}
                  name={password.name}
                  formData={formData}
                  required={
                    versionV &&
                    versionV.mnemonic &&
                    versionV.mnemonic !==
                      Mnemonics.BusinessCentralVersions.OnPrem
                  }
                  disabled={readOnly}
                  type={showPassword ? "text" : "password"} // Toggle between text and password type
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        {versionV &&
          versionV.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
                <Subtitle>{t("version.on.cloud")}</Subtitle>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  value={clientIdV}
                  onChange={(e) => {
                    setFieldValue(clientId.name, e.target.value);
                  }}
                  label={t(clientId.label)}
                  name={clientId.name}
                  formData={formData}
                  required={
                    versionV &&
                    versionV.mnemonic &&
                    versionV.mnemonic !==
                      Mnemonics.BusinessCentralVersions.OnCloud
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  value={secretIdV}
                  onChange={(e) => {
                    setFieldValue(secretId.name, e.target.value);
                  }}
                  label={t(secretId.label)}
                  name={secretId.name}
                  formData={formData}
                  required={
                    versionV &&
                    versionV.mnemonic &&
                    versionV.mnemonic !==
                      Mnemonics.BusinessCentralVersions.OnCloud
                  }
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  value={tenantIdV}
                  onChange={(e) => {
                    setFieldValue(tenantId.name, e.target.value);
                  }}
                  label={t(tenantId.label)}
                  name={tenantId.name}
                  formData={formData}
                  required={
                    versionV &&
                    versionV.mnemonic &&
                    versionV.mnemonic !==
                      Mnemonics.BusinessCentralVersions.OnCloud
                  }
                  disabled={readOnly}
                />
              </Grid>
            </>
          )}
      </Grid>
    </Box>
  );
};

export default Main;
