import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { employee, pTO, description, date, numberOfDays, numberOfHours, entitlement },
} = checkout;

export default [
  Yup.object().shape({
   
    [employee.name]: Yup.object().required(employee.requiredMessage),
    [pTO.name]: Yup.object().required(pTO.requiredMessage),
    [description.name]: Yup.string().nullable(),
    [date.name]: Yup.date().required(date.requiredMessage),
    [numberOfDays.name]: Yup.number().required(numberOfDays.requiredMessage).typeError(numberOfDays.typeError),
    [numberOfHours.name]: Yup.number().nullable().typeError(numberOfHours.typeError),
    [entitlement.name]: Yup.string().required(entitlement.requiredMessage),
  }),
];
