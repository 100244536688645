import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_ALL_TICKETS,
  DELETE_TICKETS,
} from "../../../../graphql/ticket";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_TICKET_STATUSES } from "../../../../graphql/ticketStatus";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const TicketsList = ({ fromTenant = false, myCompany = false }) => {
  const { tID, tName, pName, pID } = useParams();

  const {
    tenant_project_tickets_form,
    my_project_tickets_form,
    tenant_new_project_ticket,
    my_new_project_ticket,
  } = urls;

  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const { manageTimeEntry } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");


  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TICKETS,
    "DeleteListOfTickets"
  );

  const { data: statusesList, loading: statusLoading } = useQueryAuth(
    GET_ALL_TICKET_STATUSES,
    "GetTicketsStatus",
    {
      variables: {
        model: {
          enableFilters: false,
          userID: storeUserGuid,
          tenantID: fromTenant? tID : storeUserTenant,
          projectID: pID,
        }
      }
    }
  );

  const ticket_status_list =
    statusesList &&
    statusesList.GetTicketsStatus &&
    statusesList.GetTicketsStatus.ticketStatusesList.ticketStatuses &&
    statusesList.GetTicketsStatus.ticketStatusesList.ticketStatuses
      ? statusesList.GetTicketsStatus.ticketStatusesList.ticketStatuses
      : [];


  const filtersObject = [
    {
      type: "text",
      label: "ticket.filters.label.name",
      name: "name",
    },
    {
      type: "text",
      label: "ticket.filters.label.description",
      name: "description",
    },
    {
      type: "text",
      label: "ticket.filters.label.company",
      name: "company",
      skip: fromTenant || myCompany,
    },
    {
      label: "ticket.filters.label.status",
      name: "statusIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.description : "",
      options: ticket_status_list,
      access: "ticketStatusGuid",
      loading: statusLoading,
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table:
            Mnemonic.Tables.Tickets,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllTickets, loading } = useLazyQueryAuth(
    GET_ALL_TICKETS,
    "GetAllTickets"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      
      getAllTickets(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantID: fromTenant ? null : storeUserTenant,
              userID: storeUserGuid,
              filters: filters,
              projectID: pID
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllTickets &&
    data.GetAllTickets.ticketsList &&
    data.GetAllTickets.ticketsList.tickets
      ? data.GetAllTickets.ticketsList.tickets
      : [];
  const numberOfRecords =
    data &&
    data.GetAllTickets &&
    data.GetAllTickets.ticketsList &&
    data.GetAllTickets.ticketsList.totalNumberOfRecords
      ? data.GetAllTickets.ticketsList.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            list: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("ticket.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText:
      fromTenant
        ? "ticket.list.search.label.fromTenant"
        : "ticket.list.search.label",
    refetch,
    tableMnemonic:
      Mnemonic.Tables["Tickets"],
    filtersObject,
    generateRoute: (row) =>
      fromTenant
        ? tenant_project_tickets_form
            .replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
            .replace("/:pName/:pID", `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`)
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                row?.ticketGuid
              )}`
            )
        : my_project_tickets_form
            .replace("/:pName/:pID", `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`)
            .replace(
            "/:tkName/:tkID",
            `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
              row?.ticketGuid
            )}`
          ),
    newText: "ticket.list.new.label",
    newLink: fromTenant
      ? tenant_new_project_ticket.replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`).replace("/:pName/:pID", `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`)
      : my_new_project_ticket.replace("/:pName/:pID", `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`),
    deleteText: "ticket.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageTimeEntry !== "M",
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"ticketGuid"} hasCheckbox plan />
      )}
    </div>
  );
};

export default TicketsList;
