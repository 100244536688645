import { gql } from "@apollo/client";


export const GET_ALL_RELEASE_NOTES_LIST=gql`
query ($model: ReleaseNotesListGetRequestInput!) {
    GetAllReleaseNotesList(model: $model) {
      releaseNotesListResponse {
        totalNumberOfRecords
        releaseNotes {
          version
          releaseGuid
          releaseID
          description
          releaseDate
        }
      }
  
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const GET_RELEASE_NOTE_BY_ID=gql`
  query($id:UUID!){
    GetReleaseNotesByID (releaseGuid: $id) {
      releaseNotes {
        version
        releaseGuid
        description
        releaseDate
      }
      errorCode
      errorMessage
      errorVariables
    }}
  `

  export const CREATE_RELEASE_NOTE=gql`
  mutation($model : ReleaseNotesCreateRequestInput!){
    CreateReleaseNotes(model: $model){
       releaseNotes {
         releaseGuid
       }
       errorCode
       errorMessage
       errorVariables
    }
  }
  `

  export const UPDATE_RELEASE_NOTE=gql`
  mutation($model : ReleaseNotesUpdateRequestInput!){
    UpdateReleaseNotes(model: $model){
     releaseNotes {
        releaseGuid
     }
       errorCode
       errorMessage
       errorVariables
    }
  }
  `


  export const DELETE_RELEASE_NOTE=gql`
  mutation($model : ReleaseNotesDeleteRequestInput!){
    DeletReleaseNotes(model: $model){
      releaseNotesDeleteResponse
       errorCode
       errorMessage
       errorVariables
    }
  }
  `

  export const STOP_SHOWING_RELEASE_NOTE=gql`
  mutation($id:UUID!){
    StopShowingThisReleaseNote (employeeGuid: $id) {
      stopShowingThisReleaseNoteResponse
      errorCode
      errorMessage
      errorVariables
    }
  }`