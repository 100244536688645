import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
  mutation ($model: UserAuthenticateRequestInput!) {
    Authenticate(model: $model) {
      userView {
        version
        releaseNotes {
          releaseGuid
          description
        }
        user {
          employeeGuid
          employeeMicroID
          profilePictureURL
          creationDate
          createdBy
          isOffice365User
          email
          timeEntry
          workShift {
            workShiftDays  {
              dayName
              startHour
              endHour
              isWorkDay
            }
          }
          attendance
          preferEmail
          preferSMS
          isManager
          resourceType
          employeesTeams {
            team {
              teamGuid
              description
            }
          }
          tenant{
            tenantGuid
            name
            monthlyTimesheet
            tenantMiddlewareInfo {
              attendance
              timeEntry
              syncTimeEntry
            }
            mandatoryTimeEntryDescription
            allowAttendanceSheetReopening
            allowTimesheetReopening
            trial
            trialExpirationDate
          }
          role {
            roleGuid
            name
            isEntelligence
            isAdmin
            isAppUser
            description
            name
            manageAttendance
            manageTimeEntry
            manageParameters
            manageSystemConfigurations
            manageUsers
            tenant {
              tenantGuid
              name
            }
          }
          workType {
            workTypeGuid
            description
            mnemonic
          }
          employmentType {
            employmentTypeGuid
            name
            description
            mnemonic
          }
          location {
            locationGuid
            unitOfMeasure {
              unitOfMeasureGuid
              description
              mnemonic
            }
            country {
              countryGuid
              name
            }
            name
            latitude
            longitude
            address
            radius
            isOnSite
          }
          manager {
            employeeGuid
            email
            firstName
            lastName
          }
          firstName
          lastName
          phoneNumber
          projectPermissions {
            projectGuid
          }
        }
        jwtToken
        requestOTPCode
        deviceToken
        notifications {
          url
          logoURL
          notifications{
            senderID
            sender{
              employeeGuid
              profilePictureURL
            }
             notificationGuid
             dateSent
             notificationTitle
             notificationText
             isRead
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const USER_OTP_CODE = gql`
  mutation ($model: VerifyOTPModelInput!) {
    VerifyOTPSMS(model: $model) {
      otpSuccess
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const RESEND_OTP_CODE = gql`
  mutation ($model: RequestSMSOTPRequestInput!) {
    ResendOTPCodeSMS(model: $model) {
      resentOTPCode
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($model: ForgotPasswordRequestInput!) {
    ForgotPassword(model: $model) {
      forgotPasswordSent
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($model: ResetPasswordRequestInput!) {
    ResetPassword(model: $model) {
      resetPasswordResponse {
        message
        userID
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const SAVE_USER_DEVICE = gql`
  mutation ($model: SaveUserDeviceRequestInput!) {
    SaveUserDevice(model: $model) {
      userDeviceResponse
      errorMessage
      errorCode
      errorVariables
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($model: ChangePasswordRequestInput!) {
    ChangePassword(model: $model) {
      changePasswordResponse {
        message
        result
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
export const LOGOUT_USER = gql`
  mutation ($model: LogoutModelInput!) {
    LogoutUser(model: $model) {
      userLogout
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const SUBSCRIBE_TO_ACTIV=  gql`
mutation($model:SubscribeToActivRequestInput!){
  SubscribeToActiv (model: $model){
    subscribeToActivResponse
    errorCode
    errorMessage
    errorVariables
  }
}`;


export const CHECK_VERSION =gql`
query ($model: CheckVersionRequestModelInput!) {
    CheckVersion(model: $model) {
      userView {
        version
        user {
          employeeGuid
          employeeMicroID
          profilePictureURL
          creationDate
          createdBy
          isOffice365User
          email
          timeEntry
          workShift {
            workShiftDays  {
              dayName
              startHour
              endHour
              isWorkDay
            }
          }
          attendance
          preferEmail
          preferSMS
          isManager
          resourceType
          employeesTeams {
            team {
              teamGuid
              description
            }
          }
          tenant{
            tenantGuid
            name
            monthlyTimesheet
            tenantMiddlewareInfo {
              attendance
              timeEntry
              syncTimeEntry
            }
            mandatoryTimeEntryDescription
            allowAttendanceSheetReopening
            allowTimesheetReopening
            trial
            trialExpirationDate
          }
          role {
            roleGuid
            name
            isEntelligence
            isAdmin
            isAppUser
            description
            name
            manageAttendance
            manageTimeEntry
            manageParameters
            manageSystemConfigurations
            manageUsers
            tenant {
              tenantGuid
              name
            }
          }
          workType {
            workTypeGuid
            description
            mnemonic
          }
          employmentType {
            employmentTypeGuid
            name
            description
            mnemonic
          }
          location {
            locationGuid
            unitOfMeasure {
              unitOfMeasureGuid
              description
              mnemonic
            }
            country {
              countryGuid
              name
            }
            name
            latitude
            longitude
            address
            radius
            isOnSite
          }
          manager {
            employeeGuid
            email
            firstName
            lastName
          }
          firstName
          lastName
          phoneNumber
          projectPermissions {
            projectGuid
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
   `