import React, { useEffect, useRef, useState } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";
import SideInfo from "./components/sideInfo";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useAuth from "../../../../hooks/useAuth";
import { useSnackbar } from "notistack";

//graphql
import { GET_TO_REQUEST_BY_ID } from "../../../../graphql/toRequest";
import { GET_ATTENDANCE_REQUEST_BY_ID } from "../../../../graphql/attendanceRequests";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import Mnemonic from "../../../../Mnemonics.json";

import { LOGOUT_USER } from "../../../../graphql/user";
import axios from "axios";
import componentDisplay from "../../../../componentDisplay";
import UTC2Local, { formatDateTimeZone } from "../../../../UTC2Local";
import { parseJwt } from "../../../../utils/parseJwt";
import apiCall from "../../../../hooks/useRest";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

const backendURL = process.env.REACT_APP_REST_URL;

const RequestForm = ({
  newRequest = false,
  attendance = false,
  dayLog = null,
  dayLogGuid = null,
  fullScreen = false,
}) => {
  const { reqName, reqID } = useParams();

  const { my_requests } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [sideInfoData, setSideInfoData] = useState({});
  const [dateError, setDateError] = useState(null);
  const [showSideInfo, setShowSideInfo] = useState(false);

  // const [requestStatus, setRequestStatus] = useState(null);

  const {
    employee,
    receiver,
    requestStatus,
    requestType,
    requestDate,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    attachment,
    description,
    rejectionReason,
    approvedBy,
    approvalDate,
    rejectedBy,
    rejectionDate,
    cancelledBy,
    cancellationDate,
    workType,
    location,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const attendanceRequest =
    window.location.href.includes("attendance") || attendance;

  const [formData, setFormData] = useState(initialValues);
  const [paid, setPaid] = useState(true);

  const [attachmentName, setAttachmentName] = useState("");

  const dataObj = useRef(null);
  const requested = useRef("");
  const remaining = useRef("");
  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { storeUserGuid, storeUserManagerGuid, jwtToken, logout } = useAuth();

  const dispatch = useDispatch();

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  useEffect(() => {
    if (dayLog)
      setFormData({
        [workType.name]: dayLog?.workType || null,
        [location.name]: dayLog?.location || null,
        [requestDate.name]: new Date(),
        [dateFrom.name]:
          dayLog?.checkInTime !== null
            ? new Date(formatDateTimeZone(dayLog?.checkInTime))
            : null,
        [timeFrom.name]:
          dayLog?.checkInTime !== null
            ? new Date(formatDateTimeZone(dayLog?.checkInTime))
            : null,
        [timeTo.name]:
          dayLog?.checkOutTime !== null
            ? new Date(formatDateTimeZone(dayLog?.checkOutTime))
            : null,
      });
  }, [dayLog]);

  const handleSideInfoData = (employeePTO, daysUsed, d) => {
    const data = dataObj.current || d;

    let sideInfoDataObj = {};

    if (!attendanceRequest) {
      if (reqID) {
        sideInfoDataObj["requestForm.requestedDays"] = data?.numberOfDays
          ? data?.numberOfDays?.toFixed(2).toString() +
            " " +
            employeePTO?.pto?.dateUnit?.description
          : "0" + " " + employeePTO?.pto?.dateUnit?.description;
      }

      if (employeePTO?.numberOfDays - daysUsed > 0) {
        sideInfoDataObj["requestForm.remaining"] =
          (employeePTO?.numberOfDays - daysUsed)?.toFixed(2).toString() +
          " " +
          employeePTO?.pto?.dateUnit?.description;
      } else {
        sideInfoDataObj["requestForm.overusage"] =
          (employeePTO?.numberOfDays - daysUsed)?.toFixed(2).toString() +
          " " +
          employeePTO?.pto?.dateUnit?.description;
      }

      sideInfoDataObj["requestForm.daysUsed"] =
        daysUsed?.toFixed(2).toString() +
        " " +
        employeePTO?.pto?.dateUnit?.description;
      sideInfoDataObj["requestForm.numberOfDays"] =
        employeePTO?.numberOfDays?.toFixed(2).toString() +
        " " +
        employeePTO?.pto?.dateUnit?.description;
    }
    sideInfoDataObj["requestForm.status"] = componentDisplay({
      compName: "CustomStatus",
      compProps: data,
    });

    if (data?.requestStatus?.mnemonic === Mnemonic.RequestStatuses.Approved) {
      sideInfoDataObj["requestForm.ApprovedBy"] = data.approvedBy?.email || "";
      sideInfoDataObj["requestForm.ApprovalDate"] =
        UTC2Local(data.approvalDate) || "";
    } else if (
      data?.requestStatus?.mnemonic === Mnemonic.RequestStatuses.Rejected
    ) {
      sideInfoDataObj["requestForm.RejectedBy"] = data.rejectedBy?.email || "";
      sideInfoDataObj["requestForm.RejectionDate"] =
        UTC2Local(data.rejectionDate) || "";
    } else if (
      data?.requestStatus?.mnemonic === Mnemonic.RequestStatuses.Canceled
    ) {
      if (data?.approvedBy) {
        sideInfoDataObj["requestForm.ApprovedBy"] =
          data.approvedBy?.email || "";
        sideInfoDataObj["requestForm.ApprovalDate"] =
          UTC2Local(data.approvalDate) || "";
        sideInfoDataObj["requestForm.CanceledBy"] =
          data.cancelledBy?.email || "";
        sideInfoDataObj["requestForm.CancellationDate"] =
          UTC2Local(data.cancellationDate) || "";
      } else {
        sideInfoDataObj["requestForm.CanceledBy"] =
          data.cancelledBy?.email || "";
        sideInfoDataObj["requestForm.CancellationDate"] =
          UTC2Local(data.cancellationDate) || "";
      }
    }

    setSideInfoData(sideInfoDataObj);
  };

  const { loading: getDataByIDLoading, refetch } = useQueryAuth(
    GET_TO_REQUEST_BY_ID,
    "GetTORequestByID",
    {
      variables: { id: reqID },
      skip: newRequest || attendanceRequest,
      isWait: !newRequest,
    },
    {
      onCompleted: (response) => {
        const data =
          response?.GetTORequestByID?.getTORequestByID?.toRequest || {};
        dataObj.current = data;

        remaining.current =
          data?.employeePTO?.numberOfDays - data?.employeePTO?.daysUsed;
        requested.current = data?.numberOfDays;
        setFormData({
          [employee.name]: data?.employeePTO?.employee || null,
          [receiver.name]: data?.receiver || null,
          [requestType.name]: data?.employeePTO || "",
          [requestStatus.name]: data?.requestStatus || null,
          [requestDate.name]: data?.requestDate || "",
          [dateFrom.name]:
            data?.dateFrom !== null ? new Date(data?.dateFrom) : null,
          [dateTo.name]: data?.dateTo !== null ? new Date(data?.dateTo) : null,
          [timeFrom.name]:
            data?.timeFrom !== null
              ? data?.timeFrom?.includes("Z")
                ? new Date(
                    data?.timeFrom?.substring(0, data?.timeFrom?.length - 1)
                  )
                : new Date(data?.timeFrom)
              : null,
          [timeTo.name]:
            data?.timeTo !== null
              ? data?.timeTo?.includes("Z")
                ? new Date(data?.timeTo?.substring(0, data?.timeTo?.length - 1))
                : new Date(data?.timeTo)
              : null,
          [description.name]: data?.description || "",
          [attachment.name]: data?.attachment || null,
          [approvedBy.name]: data?.approvedBy || null,
          [approvalDate.name]: data?.approvalDate || null,
          [rejectionReason.name]: data?.rejectionReason || "",
          [rejectedBy.name]: data?.rejectedBy || null,
          [rejectionDate.name]: data?.rejectionDate || null,
          [cancelledBy.name]: data?.cancelledBy || null,
          [cancellationDate.name]: data?.cancellationDate || null,
        });
        setPaid(data?.employeePTO?.pto?.paid);
        handleSideInfoData(
          data?.employeePTO,
          data?.employeePTO?.daysUsed,
          data
        );
        setAttachmentName(
          response?.GetTORequestByID?.getTORequestByID?.attachmentName
        );
      },
    }
  );

  const { loading: getAttendanceDataByIDLoading, refetch: refetchAttendance } =
    useQueryAuth(
      GET_ATTENDANCE_REQUEST_BY_ID,
      "GetAttendanceRequestByID",
      {
        variables: { id: reqID },
        skip: newRequest || !attendanceRequest,
        isWait: !newRequest,
      },
      {
        onCompleted: (response) => {
          const data =
            response?.GetAttendanceRequestByID?.attendanceRequest || {};
          dataObj.current = data;
          setFormData({
            [employee.name]: data?.employee || null,
            [receiver.name]: data?.receiver || null,
            [requestStatus.name]: data?.requestStatus || null,
            [requestDate.name]: data?.requestDate || "",
            [dateFrom.name]:
              data?.dateFrom !== null ? new Date(data?.date) : null,
            [timeFrom.name]:
              data?.timeFrom !== null
                ? data?.timeFrom?.includes("Z")
                  ? new Date(
                      data?.timeFrom?.substring(0, data?.timeFrom?.length - 1)
                    )
                  : new Date(data?.timeFrom)
                : null,
            [timeTo.name]:
              data?.timeTo !== null
                ? data?.timeTo?.includes("Z")
                  ? new Date(
                      data?.timeTo?.substring(0, data?.timeTo?.length - 1)
                    )
                  : new Date(data?.timeTo)
                : null,
            [description.name]: data?.description || "",
            [workType.name]: data?.workType || null,
            [location.name]: data?.location || null,
            [approvedBy.name]: data?.approvedBy || null,
            [approvalDate.name]: data?.approvalDate || null,
            [rejectionReason.name]: data?.rejectionReason || "",
            [rejectedBy.name]: data?.rejectedBy || null,
            [rejectionDate.name]: data?.rejectionDate || null,
            [cancelledBy.name]: data?.cancelledBy || null,
            [cancellationDate.name]: data?.cancellationDate || null,
          });
          handleSideInfoData(null, null, data);
        },
      }
    );

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  async function handleCreateRequest(values, actions) {
    if (!dateError) {
      var formData = new FormData();
      formData.append("EmployeeGuid", storeUserGuid);
      formData.append("PTOGuid", values[requestType.name]?.pto?.ptoGuid);
      formData.append("Description", values[description.name]);
      if (values[attachment.name])
        formData.append("Attachment", values[attachment.name]);
      if (values[attachment.name])
        formData.append(
          "IsImage",
          values[attachment.name].type.includes("image")
        );
      formData.append("DateFrom", values[dateFrom.name].toDateString());
      formData.append(
        "ReceiverGuid",
        values[receiver.name]?.employeeGuid || storeUserManagerGuid
      );
      if (values[timeFrom.name])
        formData.append(
          "TimeFrom",
          values[timeFrom.name].toTimeString().substring(0, 5)
        );
      if (values[dateTo.name])
        formData.append("DateTo", values[dateTo.name].toDateString());
      if (values[timeTo.name])
        formData.append(
          "TimeTo",
          values[timeTo.name].toTimeString().substring(0, 5)
        );

      const res = await apiCall(
        backendURL + `/TORequest/CreateTORequest`,
        "POST",
        formData,
        logsOutUser,
        logout,
        updateToken,
        enqueueSnackbar,
        t
      );

      if (res) {
        sessionStorage.removeItem("unsavedChanges");
        navigate(my_requests);
      }
      actions.setSubmitting(false);
    }
  }

  async function handleCreateAttendanceRequest(values, actions) {
    if (!dateError) {
      var formData = new FormData();
      formData.append("EmployeeGuid", storeUserGuid);
      formData.append("WorkTypeGuid", values[workType.name]?.workTypeGuid);
      formData.append("Description", values[description.name]);
      if (values[location.name])
        formData.append("LocationGuid", values[location.name]?.locationGuid);
      formData.append("Date", values[dateFrom.name].toDateString());
      formData.append(
        "ReceiverGuid",
        values[receiver.name]?.employeeGuid || storeUserManagerGuid
      );
      if (values[timeFrom.name])
        formData.append(
          "TimeFrom",
          values[timeFrom.name].toTimeString().substring(0, 5)
        );
      if (values[timeTo.name])
        formData.append(
          "TimeTo",
          values[timeTo.name].toTimeString().substring(0, 5)
        );

      const res = await apiCall(
        backendURL + `/AttendanceRequest/CreateAttendanceRequest`,
        "POST",
        formData,
        logsOutUser,
        logout,
        updateToken,
        enqueueSnackbar,
        t
      );

      if (res) {
        sessionStorage.removeItem("unsavedChanges");
        navigate(my_requests);
      }
      actions.setSubmitting(false);
    }
  }

  async function handleRequestEditAttendance(values, actions) {
    if (!dateError) {
      var formData = new FormData();
      formData.append("DayLogGuid", dayLog?.dayLogGuid);
      formData.append("EmployeeGuid", storeUserGuid);
      formData.append("WorkTypeGuid", values[workType.name]?.workTypeGuid);
      formData.append("Description", values[description.name]);
      if (values[location.name])
        formData.append("LocationGuid", values[location.name]?.locationGuid);
      formData.append("Date", values[dateFrom.name].toDateString());
      formData.append(
        "ReceiverGuid",
        values[receiver.name]?.employeeGuid || storeUserManagerGuid
      );
      if (values[timeFrom.name])
        formData.append(
          "TimeFrom",
          values[timeFrom.name].toTimeString().substring(0, 5)
        );
      if (values[timeTo.name])
        formData.append(
          "TimeTo",
          values[timeTo.name].toTimeString().substring(0, 5)
        );

      const res = await apiCall(
        backendURL + `/AttendanceRequest/RequestEditAttendance`,
        "POST",
        formData,
        logsOutUser,
        logout,
        updateToken,
        enqueueSnackbar,
        t
      );

      if (res) {
        sessionStorage.removeItem("unsavedChanges");
        navigate(my_requests);
      }
      actions.setSubmitting(false);
    }
  }

  const handleSubmit = (values, actions) => {
    if (
      values[description.name].trim() === "" ||
      values[description.name].trim() === null ||
      values[description.name].trim() === undefined
    ) {
      actions.setErrors({
        [description.name]: description.requiredMessage,
      });
      actions.setTouched({
        [description.name]: true,
      });
      actions.setSubmitting(false);
      enqueueSnackbar(t(description.requiredMessage), {
        variant: "error",
        autoHideDuration: 5000,
      });
    } else
      attendanceRequest === true
        ? dayLog
          ? handleRequestEditAttendance(values, actions)
          : handleCreateAttendanceRequest(values, actions)
        : handleCreateRequest(values, actions);
  };

  return (
    <>
      {getDataByIDLoading || getAttendanceDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={fullScreen ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${"edit"}`}
                  initialValues={formData}
                  validationSchema={
                    attendanceRequest ? validation[1] : validation[0]
                  }
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setSubmitting,
                    setFieldValue,
                    setTouched,
                    setErrors,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                      <Form id={form.formID} autoComplete="off">
                        <Box p={2}>
                          <Box>
                            <Main
                              formData={{
                                values,
                                touched,
                                formField: form.formField,
                                errors,
                                setErrors,
                                setFieldValue,
                                setTouched,
                                requestStatus,
                                isSubmitting,
                                setSubmitting,
                                refetch,
                                refetchAttendance,
                                disableFields: dayLog,
                              }}
                              newRequest={newRequest}
                              setDateError={setDateError}
                              dateError={dateError}
                              setShowSideInfo={setShowSideInfo}
                              remaining={remaining.current}
                              requested={requested.current}
                              setSideInfoData={setSideInfoData}
                              setRemaining={(v) => (remaining.current = v)}
                              handleSideInfoData={handleSideInfoData}
                              paid={paid}
                              attachmentName={attachmentName}
                              setAttachmentName={setAttachmentName}
                              attendanceRequest={attendanceRequest}
                              dayLog={dayLog}
                              fullScreen={fullScreen}
                            />
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </ContainerCard>
            </Grid>
            {reqID || showSideInfo ? (
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <ContainerCard
                  sx={{ boxShadow: "0px 0px 10px rgba(24,117,214,255)" }}
                >
                  <Box p={3}>
                    <SideInfo data={Object.entries(sideInfoData)} />
                  </Box>
                </ContainerCard>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default RequestForm;
