import React, { useState, useCallback, useEffect } from "react";

//translation
import { useTranslation } from "react-i18next";

//component
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";
import { getDataTableData } from "./_table";

import {
  LOCKED_USERS_LIST,
  UNLOCK_USERS,
} from "../../../../graphql/lockedUsers";
import DataTable from "../../../../components/Tables/DataTable";

function Users() {
  const { storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newObj, setNewObj] = useState({
    userGuid: "",
    firstName: "",
    lastName: "",
    locked: false,
    tenantGuid: storeUserTenant,
  });
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const [data, setData] = useState(null);

  const { mfunction: getAllUsers, loading } = useLazyQueryAuth(
    LOCKED_USERS_LIST,
    "GetLockedUsers"
  );

  useEffect(() => {
    const col = getDataTableData(t);
    if (col) {
      setColumns(col.columns);
      setOrderBy(col.defaultSort);
      setOrder(col.defaultSortDirection);
    }
  }, [columns?.length]);

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllUsers(
        {
          variables: {
            model: {
              tenantGuid: storeUserTenant,
              orderColumn: orderBy,
              orderDirection: order,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyword: search,
            },
          },
        },
        (response) => {
          setData(response);
          const lockedUsers = response?.GetLockedUsers?.lockedUsersList?.lockedUsers;
          setRows(lockedUsers ? lockedUsers : [] );
        }
      );
    },
    []
  );

  // const records =
  //   data &&
  //   data.GetLockedUsers &&
  //   data.GetLockedUsers.lockedUsersList?.lockedUsers
  //     ? data.GetLockedUsers?.lockedUsersList?.lockedUsers
  //     : [];

  const numberRecords =
    data &&
    data.GetLockedUsers &&
    data.GetLockedUsers?.lockedUsersList &&
    data.GetLockedUsers?.lockedUsersList.totalNumberOfRecords
      ? data.GetLockedUsers?.lockedUsersList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if(columns?.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, null);
  }, [columns?.length])

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    UNLOCK_USERS,
    "UnlockUsers"
  );

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            userIDs: selected,
          },
        },
      },
      (response) => {
        if (response["UnlockUsers"].unlockUsersResponse === true) {
          enqueueSnackbar(t("users.list.unlock.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setSelected([]);
          setPage(0);
        } else {
          enqueueSnackbar(response["UnlockUsers"].errorMessage, {
            variant: "error",
            autoHideDuration: 5000
          });
        }
      }
    );
  };

  const pagination = {
    rows,
    setRows,
    newObj,
    setNewObj,
    columns,
    selected,
    setSelected,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    removeFilters: true,
    hideEditColumns: true,
    searchText: "users.list.search.label",
    // refetch,
    tableMnemonic: Mnemonic.Tables["users"],
    deleteText: "users.list.delete.label",
    handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== "M",
    // fetchedBool: true,
    // customAction,
  };

  return (
    <div>
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns &&
        columns.length > 0 && (
          <DataTable
            {...pagination}
            identifier="userGuid"
            hasCheckbox={true}
            users
          />
        )
      )}
    </div>
  );
}

export default Users;
