import React from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";

const Help = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box lineHeight={0} className="flex_space-between_center">
        <Title style={{padding: "10px 0"}}>{t("ptoForm.helpTitle")}</Title>
      </Box>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <p>{t("ptoForm.helpText")}</p>
      </Box>
    </Box>
  );
};

export default Help;
