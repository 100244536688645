import { gql } from "@apollo/client";

export const GET_ALL_TICKETS = gql`
query ($model: GetAllTicketsRequestModelInput!) {
    GetAllTickets(model: $model) {
      ticketsList {
        tickets {
          id
          percentageComplete
          ticketGuid
          title
          description
          area {
            areaGuid
            description
          }
          project {
            projectGuid
            title
            company {
              companyGuid
              name
            }
          }
          billableTimePercentage
          tasks {
            taskGuid
            percentageComplete
            title
            priority
            id
            team {
              description
            }
            employee {
              firstName
              lastName
            }
            status {
              taskStatusGuid
              description
              taskState {
                mnemonic
              }
              statusColor {
                colorCode
              }
            }
            parentTaskGuid
            estimatedEndDate
            estimatedStartDate
            estimatedTime
            actualEndDate
            actualStartDate
            actualTime
            behindSchedule
            actualBillableTime
            billableTimePercentage
            createdBy {
              email
            }
            createdAt
            modifiedBy {
              email
            }
            modifiedAt
          }
          status {
            ticketStatusGuid
            description
            taskState {
              mnemonic
            }
            statusColor {
              colorCode
            }
          }
          estimatedEndDate
          estimatedStartDate
          estimatedTime
          actualEndDate
          actualStartDate
          actualTime
          behindSchedule
          createdBy {
            email
          }
          createdAt
          modifiedBy {
            email
          }
          modifiedAt
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_TICKETS_DROPDOWN = gql`
query ($model: GetAllTicketsRequestModelInput!) {
    GetAllTickets(model: $model) {
      ticketsList {
        tickets {
          ticketGuid
          title
          area {
            areaGuid
            description
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TICKET_BY_ID = gql`
    query ($id: UUID!) {
        GetTicket(id: $id) {
        ticket {
          id
            ticketGuid
            synced
            externalReference
            title
            description
            estimatedEndDate
            estimatedStartDate
            estimatedTime
            actualEndDate
            actualStartDate
            actualTime
            area {
              areaGuid
              description
            }
            project {
                projectGuid
                title
                company {
                companyGuid
                name
                }
            }
            status {
                ticketStatusGuid
                description
                taskState {
                  mnemonic
                }
                statusColor {
                  colorCode
                }
              }
            }
        errorCode
        errorMessage
        errorVariables
        }
    }
`;

export const UPDATE_TICKET = gql`
  mutation ($model: EditTicketRequestInput!) {
    EditTicket(model: $model) {
      ticket {
        ticketGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation ($model: CreateTicketRequestInput!) {
    CreateTicket(model: $model) {
      ticket {
        ticketGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TICKETS = gql`
  mutation ($model: DeleteTicketListRequestInput!) {
    DeleteListOfTickets(model: $model) {
        crudTicket
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TICKET = gql`
  mutation ($id: UUID!) {
    DeleteTicket(id: $id) {
      crudTicket
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TICKET_QUICK = gql`
  mutation($model:UpdateTicketQuickRequestInput!){
    UpdateTicketQuick (model: $model){
      updateTicketQuickResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
