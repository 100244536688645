import { gql } from "@apollo/client";

export const GET_USER_TABLE_FILTERS = gql`
  query ($model: UserTableFiltersRequestModelInput!) {
    GetUserTableFilters(model: $model) {
      userTableFiltersList {
        createdAt
        employeeGuid
        filter
        table
        title
        userTableFiltersGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const SAVE_USER_TABLE_FILTERS = gql`
  mutation ($model: UserTableFiltersSaveRequestModelInput!) {
    SaveUserTableFilters(model: $model) {
      userTableFilters {
        createdAt
        employeeGuid
        filter
        table
        title
        userTableFiltersGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const REMOVE_USER_TABLE_FILTERS = gql`
  mutation ($model: UserTableFiltersRemoveRequestModelInput!) {
    RemoveUserTableFilters(model: $model) {
      removedTableFilters
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
