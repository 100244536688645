import React from "react";

//colors
import colorsLight from "../../assets/theme/light/colors";

import { useTranslation } from "react-i18next";

const StatusCell = ({ color = "", label = "" }) => {
  const { t } = useTranslation();

  const { statuses } = colorsLight;

  let colorValues = {};

  if (color === "success") {
    colorValues = statuses["success"];
  }

  if (color === "error") {
    colorValues = statuses["error"];
  }

  return (
    <span
      style={{
        backgroundColor: colorValues?.bgColor,
        color: colorValues?.color,
        fontFamily: "poppins  !important",
        borderRadius: "5px",
        padding: "5px 20px",
        textAlign: "center",
      }}
    >
      {t(label)}
    </span>
  );
};

export default StatusCell;
