import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

import useAuth from "../../../../hooks/useAuth";
function TenantTabs({ myTenant = false }) {
  const { tName, tID } = useParams();

  const { userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;
  const {
    tenant_form,
    my_tenant,
    tenant_companies,
    employees_list,
    tenant_management,
    tenant_licenses,
    my_tenant_licenses,
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !tName ||
    !tID ||
    tName === "undefined" ||
    tName === "null" ||
    tName === "" ||
    tID === "null" ||
    tID === "undefined" ||
    tID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(tName);
  const encodedID = encodeURIComponent(tID);

  const TabLinks = [
    {
      key: 1,
      Header: t("tenantTabs.form"),
      Link:  myTenant ? my_tenant.replace("/:tName/:tID", `/${encodedName}/${encodedID}`) : tenant_form.replace("/:tName/:tID", `/${encodedName}/${encodedID}`),
    },
    {
      key: 2,
      Header: t("tenantTabs.licenses"),
      Link: myTenant ? my_tenant_licenses.replace( "/:tName/:tID",`/${encodedName}/${encodedID}`) : tenant_licenses.replace( "/:tName/:tID",`/${encodedName}/${encodedID}`),
    },
    ...(!(
      manageSystemConfigurations === "N" ||
      manageSystemConfigurations === null ||
      manageSystemConfigurations === undefined
    ) && !myTenant
      ? [
          {
            key: 3,
            Header: t("tenantTabs.companies"),
            Link: tenant_companies.replace(
              "/:tName/:tID",
              `/${encodedName}/${encodedID}`
            ),
          },
        ]
      : []),
    ...(!(
      manageSystemConfigurations === "N" ||
      manageSystemConfigurations === null ||
      manageSystemConfigurations === undefined
    ) && !myTenant
      ? [
          {
            key: 4,
            Header: t("tenantTabs.employees"),
            Link: employees_list.replace(
              "/:tName/:tID",
              `/${encodedName}/${encodedID}`
            ),
          },
        ]
      : []),
    ...(!myTenant
      ? [
          {
            key: 5,
            Header: t("tenant.management.tab"),
            Link: tenant_management.replace(
              "/:tName/:tID",
              `/${encodedName}/${encodedID}`
            ),
          },
        ]
      : []),
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default TenantTabs;
