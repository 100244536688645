import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ATTENDANCES, DELETE_DAY_LOG } from "../../../../graphql/dayLogs";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../graphql/location";
import { GET_ALL_WORK_TYPES } from "../../../../graphql/workType";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local, { UTC2Time } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import EditRecord from "../AttendanceSheets-form/components/EditRecord";
import AttendanceDialog from "../AttendanceSheets-form/components/AttendanceDialog";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { getWorkingHours } from "../../../../utils/getWorkingHours";
import AttendanceRequestDialog from "../AttendanceSheets-form/components/AttendanceRequestDialog";
import { removeNullProps } from "../../../../utils/removeNullProps";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";

function AttendancesList({ myAttendance = false }) {
  const { storeUserGuid, storeUserTenant, userRoles, isManager } = useAuth();

  const { manageAttendance, isAdmin } = userRoles;

  const [data, setData] = useState(null);
  const [filterState, setFilterState] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState(
    filterState && filterState.length > 0 ? filterState : {}
  );
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [dayLogGuid, setDayLogGuid] = useState(null);
  const [reuqestGuid, setRequestGuid] = useState(null);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedDayLog, setSelectedDayLog] = useState(null);

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_DAY_LOG,
    "DeleteDayLog"
  );

  const handleSetDayLogGuid = (id) => {
    setEditMode(true);
    setOpenModal(true);
    setDayLogGuid(id);
  };

  const handleOpenRequestModal = (id, row) => {
    setOpenRequestModal(true);
    setDayLogGuid(id);
    setSelectedDayLog(row);
  };

  useEffect(() => {
    if (myAttendance) {
      const objectIndexEmployeeIDs = filterState.findIndex(
        (obj) => obj.property === "employee"
      );

      var today = new Date();
      today.setHours(2);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      if (objectIndexEmployeeIDs < 0)
        setDefaultFilters({
          ...defaultFilters,
          employee: [storeUserGuid],
          date: today.toDateString(),
        });
      const employeeIDsObj = {
        value: [storeUserGuid],
        property: "employee",
      };
      setFilterState([
        ...filterState,
        employeeIDsObj,
        { value: today.toDateString(), property: "date" },
      ]);
    }
  }, [myAttendance]);

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
        showAll: true,
        // attendanceDisabled: true,
      },
    },
  });

  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: workTypesList,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes", {
    variables: {
      showAll: true,
    },
    skip: employeeWait,
    isWait: true,
  });

  const workType_list =
    workTypesList &&
    workTypesList.GetAllWorkTypes &&
    workTypesList.GetAllWorkTypes.workTypes
      ? workTypesList.GetAllWorkTypes.workTypes
      : [];

  const {
    data: locationsList,
    loading: locationLoading,
    tokenChange: locationWait,
  } = useQueryAuth(GET_ALL_LOCATIONS_DROPDOWN, "GetAllLocations", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
      },
    },
    skip: employeeWait || workTypesWait,
    isWait: true,
  });

  const location_list =
    locationsList &&
    locationsList.GetAllLocations &&
    locationsList.GetAllLocations.locationsList &&
    locationsList.GetAllLocations.locationsList.locations
      ? locationsList.GetAllLocations.locationsList.locations
      : [];

  const filtersObject = [
    {
      label: "attendanceSheet.list.filters.employees",
      name: "employee",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeesList,
      access: "employeeGuid",
      loading: employeeLoading,
      disabled: myAttendance === true && !isManager && !isAdmin ? true : false,
      disableClear:
        myAttendance === true && !isManager && !isAdmin ? true : false,
    },
    {
      label: "attendanceSheet.list.filters.workType",
      name: "workType",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: workType_list,
      access: "workTypeGuid",
      loading: workTypeLoading,
    },
    {
      label: "attendanceSheet.list.filters.locations",
      name: "location",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: location_list,
      access: "locationGuid",
      loading: locationLoading,
    },
    {
      type: "date",
      label: "attendanceSheet.list.filters.date",
      name: "date",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.isLate",
      name: "isLate",
      access: "isLate",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.isAutomaticRecord",
      name: "isAutomaticRecord",
      access: "isAutomaticRecord",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.fromWeb",
      name: "fromWeb",
      access: "fromWeb",
    },
    {
      type: "check",
      label: "attendanceSheet.list.filters.fromKiosk",
      name: "fromKiosk",
      access: "fromKiosk",
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.CurrentAttendances,
          userID: storeUserGuid,
        },
      },
      skip: employeeWait || workTypesWait || locationWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;
        const actions = ({ compProps }) => {
          let props = compProps;

          return (
            <EditRecord
              {...props}
              row={props}
              dayLogGuid={props.dayLogGuid}
              handleSetDayLogGuid={handleSetDayLogGuid}
              handleOpenModal={isAdmin || isManager ? handleSetDayLogGuid : handleOpenRequestModal}
              disabled={(row) => {
                return (
                  row?.workType?.mnemonic === Mnemonic.WorkTypes.timeoff ||
                  (row?.attendanceRequest &&
                    row?.attendanceRequest?.approvedBy !== null && !isAdmin && !isManager)
                  // || (!isManager && manageAttendance !== "M" && !isAdmin)
                );
              }}
            />
          );
        };

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(
              key,
              value,
              UTC2Local,
              componentDisplay,
              UTC2Time,
              actions,
              getWorkingHours
            )
          );
          // if (parsed.columns)
          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
          if (parsed.columns) {
            if (myAttendance) {
              const cols = parsed.columns;
              cols.map((col) => {
                if (
                  col.id === "checkOutTime" ||
                  col.id === "workTime" ||
                  col.id === "automaticallyClockedOut" ||
                  col.id === "isEarly" ||
                  col.id === "fromWeb" ||
                  col.id === "fromKiosk" ||
                  col.id === "createdBy" ||
                  col.id === "modifiedBy"
                )
                  col.visible = true;
                col = removeNullProps(col);
                return col;
              });
              setColumns(cols);
            } else setColumns(parsed.columns);
          }
        }
      },
    }
  );

  const {
    mfunction: getAttendances,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ATTENDANCES, "GetAttendances");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAttendances(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantGuid: storeUserTenant,
              userID: storeUserGuid,
              filters: filters,
              current: myAttendance ? false : true,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData]);

  const rows =
    data &&
    data.GetAttendances &&
    data.GetAttendances.attendancesResponse &&
    data.GetAttendances.attendancesResponse.dayLogs
      ? data.GetAttendances.attendancesResponse.dayLogs
      : [];

  const numberOfRecords =
    data &&
    data.GetAttendances &&
    data.GetAttendances.attendancesResponse &&
    data.GetAttendances.attendancesResponse.totalNumberOfRecords
      ? data.GetAttendances.attendancesResponse.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            dayLogIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("attendanceSheet.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);
        fetchTableData(0, rowsPerPage, order, orderBy, search, defaultFilters);
      }
    );
  };

  const customButtons = [
    {
      label: "attendanceSheet.btn.add.label",
      handleClick: () => {
        if(isAdmin || isManager) {
          setEditMode(false);
          setOpenModal(true);
        } else {
          handleOpenRequestModal();
        }
      },
      condition: !selected || selected.length === 0,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "openTimeEntries.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.CurrentAttendances,
    filtersObject,
    readOnly: myAttendance === true ? false : manageAttendance !== "M",
    customButtons,
    deleteText: "attendanceSheet.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    isFixed: true,
  };

  return (
    <div>
      <AttendanceDialog
        open={openModal}
        setOpen={setOpenModal}
        employeeLoading={employeeLoading}
        locationLoading={locationLoading}
        workTypeLoading={workTypeLoading}
        employee_list={employeesList}
        location_list={location_list}
        workType_list={workType_list?.filter((x) => x.mnemonic !== "timeoff")}
        attendanceSheetGuid={null}
        dayLogGuid={dayLogGuid}
        editMode={editMode}
        refetchData={refetchData}
        handleEditMode={(v) => setEditMode(v)}
        status={Mnemonic.AttendanceSheetStatuses.Pending}
        handleChangeDayLogGuid={(v) => setDayLogGuid(v)}
      />
      <AttendanceRequestDialog
        open={openRequestModal}
        setOpen={setOpenRequestModal}
        dayLogGuid={dayLogGuid}
        dayLog={selectedDayLog}
        title={dayLogGuid ? t("requests.label.edit.attendance") : t("requests.label.add.attendance")}
        fullScreen
      />
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"dayLogGuid"}
          isFixed
          hasCheckbox={manageAttendance === "M"}
          checkForCheckbox={(row) => {
            return (
              ((row?.employee?.directManagerGuid === storeUserGuid ||
                row?.employee?.employeeGuid === storeUserGuid) &&
                isManager &&
                manageAttendance === "M") ||
              isAdmin
            );
          }}
        />
      )}
    </div>
  );
}

export default AttendancesList;
