import React, {  useState } from "react";

import { Link, useNavigate } from "react-router-dom";

//Exterior Components
import { Input } from "antd";
import { Typography } from "@mui/material";

//colors
import colorLight from "../../assets/theme/light/colors";

//links
import urls from "../../urls";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useForms from "../../hooks/useForms";
import useMutationAuth from "../../hooks/useMutationAuth";

//graphql
import { USER_LOGIN } from "../../graphql/user";

//redux
import { useDispatch } from "react-redux";
import { saveToken } from "../../app/reducers/tokenSlice";
import { loginUser as userLogin } from "../../app/reducers/userSlice";
import { changeNotification } from "../../app/reducers/notificationSlice";

//for cookies
import { useCookies } from "react-cookie";

//variables
import variables from "../../variables";
import IALoadingButton from "../../components/IAButtons/IALoadingButton";

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { values, errors, touched, setValues, setTouched } = useForms();

  const { error_light, pure_white, light_logo_blue } =
    colorLight; //colors

  const { forgot_password, verify_login } = urls; //urls

  const { mfunction: loginUser, loading } = useMutationAuth(
    USER_LOGIN,
    "Authenticate"
  );

  //To handle cookies
  const [cookies] = useCookies();
  
  const { t } = useTranslation(); //translation

 
  const COOKIE_NAME = variables.COOKIE_NAME;

  const handleLogin = (event) => {
    event.preventDefault();
  
    if (!values.email || !values.password) {
      if (!values.email || errors.email) {
        setValues("email", "email", "");
        setTouched("email");
      }
  
      if (!values.password || errors.password) {
        setValues("password", "password", "");
        setTouched("password");
      }
      return;
    }
  
    loginUser(
      {
        variables: {
          model: {
            email: values.email,
            password: values.password,
            deviceToken:
              cookies[COOKIE_NAME + values.email.toLowerCase()] !== undefined &&
              cookies[COOKIE_NAME + values.email.toLowerCase()] !== null
                ? cookies[COOKIE_NAME + values.email.toLowerCase()]
                : null,
            fromWebApp: true,
            registrationToken: localStorage.getItem("token"),
          },
        },
      },
      (data) => {
        const response =
          data && data.Authenticate && data.Authenticate.userView
            ? data.Authenticate.userView
            : {};
        const notifications = response.notifications;
        dispatch(changeNotification(notifications));
  
        delete response.notifications;
        dispatch(userLogin(response));
        dispatch(saveToken(response?.jwtToken));
        
        navigate(verify_login);
      }
    );
  };
  


  return (
    <>
     
     
      <form onSubmit={handleLogin}>
        <div className="field-input">
          <Input
            allowClear
            size="large"
            type="email"
            placeholder={t("login.email")}
            value={values.email}
            name="email"
            onFocus={() => setTouched("email")}
            onChange={(e) => {
              setValues("email", "email", e.target.value);
            }}
            status={touched.email && errors.email ? "error" : ""}
            style={{
              color: `${pure_white}`,
              placeholder: {
                color: `${pure_white}`,
              },
            }}
          />
          <Typography
            variant="caption"
            component="caption"
            className="error-text"
            sx={{ color: `${error_light}` }}
          >
            {touched.email && t(errors.email)}
          </Typography>
        </div>
        <div className="field-input">
          <Input
            allowClear
            size="large"
            type="password"
            style={{
              color: `${pure_white}`,
              placeholder: {
                color: `${pure_white}`,
              },
            }}
            placeholder={t("login.password")}
            //prefix={<LockOutlined />}
            value={values.password}
            name="password"
            onFocus={() => setTouched("password")}
            status={touched.password && errors.password ? "error" : ""}
            onChange={(e) => {
              setValues("password", "password", e.target.value);
            }}
          />
          <Typography
            variant="caption"
            component="caption"
            className="error-text"
            sx={{ color: `${error_light}` }}
          >
            {touched.password && t(errors.password)}
          </Typography>
        </div>
        <div className="button-and-link">
          <IALoadingButton
            sx={{ borderRadius: "50px !important", backgroundColor: light_logo_blue, boxShadow: "none !important", fontWeight: "bold !important" }}

            fullWidth
            type="submit"
            loading={loading}
            label={t("login.button")}
          />
          <Typography variant="caption" component="caption">
            <Link to={`${forgot_password}`} style={{ color: `${pure_white}` }}>
              {t("login.forgotPassword")}
            </Link>
          </Typography>
        </div>
      </form></>
  );
};

export default Login;
