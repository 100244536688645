export const getMonthNames = (t) => [
    t("jan"),
    t("feb"),
    t("mar"),
    t("apr"),
    t("may"),
    t("jun"),
    t("jul"),
    t("aug"),
    t("sep"),
    t("oct"),
    t("nov"),
    t("dec"),
];

export const addMonthsToYear = (year, monthsToAdd) => {
    let y = year;
    let m = monthsToAdd;
    while (m >= 12) {
      m -= 12;
      y += 1;
    }
    return { year: y, month: m + 1 };
  };
  
  export const addMonthsToYearAsDate = (year, monthsToAdd) => {
    const r = addMonthsToYear(year, monthsToAdd);
    return new Date(`${r.year}-${r.month}`);
  };
  