import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query ($model: GetAllCompaniesRequestModelInput!) {
    GetAllCompanies(model: $model) {
      compList {
        companies {
          name
          companyGuid
          name
          tenant {
            tenantGuid
            tenantMiddlewareInfo {
              connector {
                name
              }
            }
          }
          externalReference
          creationDate
          creatingUsers {
            employeeGuid
            firstName
            lastName
            email
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_COMPANIES_DROPDOWN = gql`
  query ($model: GetAllCompaniesRequestModelInput!) {
    GetAllCompanies(model: $model) {
      compList {
        companies {
          name
          companyGuid
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_COMPANY_CREATED_BY_USERS = gql`
  query ($userID: UUID!) {
    GetCompanyCreatedByUsers(userID: $userID) {
      createdByUsers {
        employeeGuid
        email
      }
      errorCode
      errorVariables
      errorMessage
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  query ($companyID: UUID!) {
    GetCompany(id: $companyID) {
      companyOutput {
        companyGuid
        connectionString
        username
        password
        businessCentralVersion {
          businessCentralVersionGuid
          description
          mnemonic
        }
        clientId
        tenantId
        secretId
        tenant {
          tenantGuid
          name
        }
        externalReference
        name
        creationDate
        creatingUsers {
          email
        }
        modificationDate
        modifiedUsers {
          employeeGuid
          email
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation ($model: EditCompanyRequestInput!) {
    EditCompany(model: $model) {
      companyOutput {
        companyGuid
        name
        creationDate
        modificationDate
        modifiedUsers{
          email
        }
        creatingUsers {
          email
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation ($model: CreateCompanyRequestInput!) {
    CreateCompany(model: $model) {
      crudCompany
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation ($model: DeleteCompaniesListRequestModelInput!) {
    DeleteCompaniesList(model: $model) {
      crudCompany
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
