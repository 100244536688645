import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../../../assets/theme/light/colors";
import {
  CloseIcon,
  DeleteIcon,
  EditPenIcon,
  SaveIcon,
} from "../../../../components/Icon";
import { Title } from "../../../../components/Titles/FormTitles";

import "./styles.css";

function SavedFilters({
  filters,
  loading,
  handleApplyFilter,
  selectedFilterGuid,
  handleSaveUserTableFilters,
  handleRemoveUserTableFilters,
}) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [filterName, setFilterName] = useState(null);

  const handleEdit = (filter) => {
    setEditMode(false);
    const res = handleSaveUserTableFilters(
      filter.filter,
      filterName ? filterName : filter.title,
      false
    );
  };

  const toggleEditMode = () => {
    setFilterName(null);
    setEditMode(!editMode);
  };

  return (
    <Card
      style={{
        margin: "10px 0 0 10px",
        paddingTop: "10px",
        paddingLeft: "5px",
        height: "calc(100vh - 105px)",
        backgroundColor: "transparent",
      }}
    >
      <Title>{t("ts.savedFilters")}</Title>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          marginTop="20px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <List aria-label="filters" dense sx={{ marginTop: "15px" }}>
          {filters.map((filter, index) => (
            <ListItem
              key={index}
              sx={{ paddingLeft: "0" }}
              className="filter-item"
            >
              {editMode &&
              selectedFilterGuid === filter.userTableFiltersGuid ? (
                <TextField
                  // //   multiline
                  value={filterName !== null ? filterName : filter.title}
                  sx={{
                    "& .MuiInputBase-input": {
                      paddingTop: "0 !important",
                      paddingBottom: "0 !important",
                      textAlign: "left",
                      fontSize: "13px !important",
                      minHeight: "32px !important",
                    },
                    "& .MuiInputBase-root": {
                      paddingTop: "0 !important",
                      paddingBottom: "0 !important",
                    },
                  }}
                  onChange={(e) => {
                    setFilterName(e.target.value);
                  }}
                  required={true}
                />
              ) : (
                <ListItemButton
                  sx={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    backgroundColor: "transparent",
                  }}
                  role={undefined}
                  onClick={() =>
                    handleApplyFilter(
                      filter.userTableFiltersGuid,
                      filter.filter
                    )
                  }
                  dense
                >
                  <ListItemText
                    primary={filter?.title}
                    sx={
                      selectedFilterGuid === filter.userTableFiltersGuid
                        ? {
                            color: colors.light_logo_blue,
                            paddingTop: "5px !important",
                          }
                        : { paddingTop: "5px !important" }
                    }
                  />
                </ListItemButton>
              )}
              {selectedFilterGuid === filter.userTableFiltersGuid ? (
                editMode ? (
                  <span style={{ display: "flex" }}>
                    <IconButton
                      edge="end"
                      aria-label="actions"
                      disabled={!filterName || filterName === filter.title}
                      sx={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginLeft: "5px !important",
                        backgroundColor: "transparent !important",
                      }}
                      onClick={() => handleEdit(filter)}
                    >
                      <SaveIcon
                        color={
                          !filterName
                            ? colors.icon_button_grey
                            : colors.light_logo_blue
                        }
                      />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="actions"
                      sx={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginLeft: "20px !important",
                        backgroundColor: "transparent !important",
                      }}
                      onClick={toggleEditMode}
                    >
                      <CloseIcon color={colors.icon_button_grey} />
                    </IconButton>
                  </span>
                ) : (
                  <span style={{ display: "flex" }}>
                    <IconButton
                      edge="end"
                      aria-label="actions"
                      sx={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginLeft: "5px !important",
                        backgroundColor: "transparent !important",
                      }}
                      onClick={toggleEditMode}
                    >
                      <EditPenIcon
                        color={colors.light_logo_blue}
                        strokeWidth={1}
                      />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="actions"
                      sx={{
                        paddingLeft: "0",
                        paddingRight: "0",
                        marginLeft: "20px !important",
                        backgroundColor: "transparent !important",
                      }}
                      onClick={() =>
                        handleRemoveUserTableFilters(
                          filter.userTableFiltersGuid
                        )
                      }
                    >
                      <DeleteIcon color={colors.red_color} />
                    </IconButton>
                  </span>
                )
              ) : (
                <span className="filter-delete">
                  <IconButton
                    edge="end"
                    aria-label="actions"
                    sx={{
                      paddingLeft: "0",
                      paddingRight: "0",
                      marginLeft: "20px !important",
                      backgroundColor: "transparent !important",
                    }}
                    onClick={() =>
                      handleRemoveUserTableFilters(filter.userTableFiltersGuid)
                    }
                  >
                    <DeleteIcon color={colors.red_color} />
                  </IconButton>
                </span>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
}

export default SavedFilters;
