import React, { useEffect, useRef, useState } from "react";

//schemas
import form from "./schema/form";
import { getValidation } from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

import { formatDateTimeZone } from "../../../../UTC2Local";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import {
  GET_TIME_ENTRY_BY_ID,
  UPDATE_TIME_ENTRY,
  CREATE_TIME_ENTRY,
} from "../../../../graphql/timeEntry";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

const TimesheetForm = ({
  timeSheetGuid,
  timeEntryGuid,
  synced = false,
  refetchData,
  editMode = false,
  myTime = false,
  handleChangeTimeEntryGuid,
  handleSetEmployeeGuid,
  handleEditMode,
  employees = [],
  tasks = [],
  selectedTask = null,
  resetFields,
  fromTimesheet = false,
  fromDashboard = false,
  group = false,
  handleCloseTimeEntryModal,
  handleDeselect,
  taskBillable,
}) => {
  const {
    storeUserTenant,
    storeUserGuid,
    userRoles,
    isTimeEntryDescriptionMandatory,
  } = useAuth();
  const { isAdmin } = userRoles;

  const { t } = useTranslation();

  const { formField } = form;

  const {
    employee,
    task,
    date,
    duration,
    description,
    minutes,
    hours,
    project,
    billable,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);
  const [status, setStatus] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [manager, setManager] = useState(null);
  const [userID, setUserID] = useState(null);
  const [projectGuid, setProjectGuid] = useState(null);
  const [open, setOpen] = useState(null);
  const [button1Subitting, setButton1Submitting] = useState(false);
  const [button2Subitting, setButton2Submitting] = useState(false);
  const saveAndClose = useRef(false);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const validation = getValidation(isTimeEntryDescriptionMandatory, myTime);

  useEffect(() => {
    if (editMode === false) {
      setFormData(initialValues);
      setStatus(null);
      setManager(null);
      setTenant(null);
      setUserID(null);
      setProjectGuid(null);
      synced = null;
    }
  });

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_TIME_ENTRY_BY_ID,
    "GetTimeEntryByID",
    {
      variables: { id: timeEntryGuid },
      skip: timeEntryGuid == null,
      isWait: false,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetTimeEntryByID?.timeEntry || {};
        const totalDurationInHours = data?.duration;

        const totalHours = Math.floor(totalDurationInHours);
        const totalMinutes = Math.round(
          (totalDurationInHours - totalHours) * 60
        );

        setFormData({
          [employee.name]: data.employee || null,
          [task.name]: data.task || null,
          [duration.name]: totalDurationInHours || 0.0,
          [minutes.name]: totalMinutes.toString() || "0",
          [hours.name]: totalHours.toString() || "0",
          [description.name]: data.description || "",
          [project.name]: data.task?.ticket?.project || "",
          [billable.name]: data.billable,
          [date.name]:
            data.startTime == null
              ? null
              : new Date(formatDateTimeZone(data.startTime, false)),
        });
        setStatus(data.timeSheet?.status);
        setTenant(data.employee?.tenantGuid);
        setManager(data.employee?.directManagerGuid);
        setUserID(data.employee?.employeeGuid);
        setProjectGuid(data.task?.ticket?.project?.projectGuid);
        if (handleSetEmployeeGuid != undefined)
          handleSetEmployeeGuid(data.employee?.employeeGuid);
        setOpen(data.open);
      },
    }
  );

  const { cfunction: editTimeEntryData } = useMutationAuth(
    UPDATE_TIME_ENTRY,
    "UpdateTimeEntryByManager"
  );

  function EditTimeEntryData(values, actions) {
    if (saveAndClose.current === false) setButton1Submitting(true);
    else setButton2Submitting(true);
    if (!open && values[hours.name] === 0 && values[minutes.name] === 0) {

      enqueueSnackbar(t("time.error"), {
        variant: "error",
        autoHideDuration: 5000,
      });
      setButton1Submitting(false);
      setButton2Submitting(false);
    } else
      editTimeEntryData(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              timeEntryGuid: timeEntryGuid,
              employeeGuid: values[employee.name]?.employeeGuid,
              description: values[description.name],
              taskGuid: values[task.name]?.taskGuid,
              date: new Date(values[date.name]).toDateString(),
              duration:
                parseFloat(values[hours.name]) +
                parseFloat(values[minutes.name] / 60 || 0),
              synced: synced ? synced : false,
              billable: values[billable.name],
            },
          },
        },
        (response) => {
          if (
            response["UpdateTimeEntryByManager"].errorCode !== null &&
            response["UpdateTimeEntryByManager"].errorCode !== ""
          ) {
            enqueueSnackbar(
              t(response["UpdateTimeEntryByManager"].errorMessage),
              {
                variant: "error",
                autoHideDuration: 5000,
              }
            );
            setButton1Submitting(false);
            setButton2Submitting(false);
          } else {
            enqueueSnackbar(t("timeEntryForm.updatedSuccessfully"), {
              variant: "success",
            });
            sessionStorage.removeItem("unsavedChanges");
            if (handleDeselect !== undefined) handleDeselect(false);
            setTimeout(() => {
              if (saveAndClose.current === true) handleCloseTimeEntryModal();
            }, 200);
            if (refetchData) refetchData();
          }
          setButton1Submitting(false);
          setButton2Submitting(false);
        },
        (error) => {
          setButton1Submitting(false);
          setButton2Submitting(false);
        }
      );
  }

  const { cfunction: createTimeEntryData } = useMutationAuth(
    CREATE_TIME_ENTRY,
    "CreateTimeEntryByManager"
  );

  function CreateTimeEntryData(values, actions) {
    if (saveAndClose.current === false) setButton1Submitting(true);
    else setButton2Submitting(true);
    if(!open && !values[task.name]?.taskGuid) {
      enqueueSnackbar(t("timeEntryForm.task.requiredMessage"), {
        variant: "error",
        autoHideDuration: 5000,
      });
      // actions?.setSubmitting(false);
      setButton1Submitting(false);
      setButton2Submitting(false);
      return; 
    }
    if (!open && values[hours.name] === 0 && values[minutes.name] === 0) {
      enqueueSnackbar(t("time.error"), {
        variant: "error",
        autoHideDuration: 5000,
      });
      // actions?.setSubmitting(false);
      setButton1Submitting(false);
      setButton2Submitting(false);
    } else
      createTimeEntryData(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              employeeGuid: values[employee.name]?.employeeGuid,
              taskGuid: values[task.name]?.taskGuid,
              date: new Date(values[date.name]).toDateString(),
              description: values[description.name] || "",
              duration:
                parseFloat(values[hours.name]) +
                parseFloat(values[minutes.name] / 60 || 0),
              timeSheetGuid: timeSheetGuid,
              billable: values[billable.name],
            },
          },
        },
        (response) => {
          if (
            response["CreateTimeEntryByManager"].errorCode !== null &&
            response["CreateTimeEntryByManager"].errorCode !== ""
          ) {
            enqueueSnackbar(
              t(response["CreateTimeEntryByManager"].errorMessage),
              {
                variant: "error",
                autoHideDuration: 5000,
              }
            );
            setButton1Submitting(false);
            setButton2Submitting(false);
          } else {
            enqueueSnackbar(t("timeEntryForm.createdSuccessfully"), {
              variant: "success",
            });
            sessionStorage.removeItem("unsavedChanges");
            if (refetchData) refetchData();
            if (saveAndClose.current === true) handleCloseTimeEntryModal();
            if (handleDeselect !== undefined) handleDeselect(false);
            if (handleEditMode && saveAndClose.current === false)
              handleEditMode(true);
            if (handleChangeTimeEntryGuid && saveAndClose.current === false)
              handleChangeTimeEntryGuid(
                response["CreateTimeEntryByManager"]?.timeEntry?.timeEntryGuid
              );
            // if (fromDashboard === true) handleCloseTimeEntryModal(false)
          }
          // actions?.setSubmitting(false);
          setButton1Submitting(false);
          setButton2Submitting(false);
        },
        (error) => {
          // actions?.setSubmitting(false);
          setButton1Submitting(false);
          setButton2Submitting(false);
        }
      );
  }

  const handleSubmit = (values, actions) => {

    if (editMode) EditTimeEntryData(values, actions);
    else CreateTimeEntryData(values, actions);
  };


  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard
          sx={{
            height: "50vh",
            width: "600px",
            border: "none",
            boxShadow: "none",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%" }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* <ContainerCard> */}
              <Formik
                innerRef={formikRef}
                key={`${form.formID}-form`}
                initialValues={editMode === true ? formData : initialValues}
                validationSchema={validation[0]}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, touched, setFieldValue, setTouched }) => {
                  formikValuesRef.current = values;
                  return (
                    <Form id={form.formID} autoComplete="off">
                      <Box p={2}>
                        <Box>
                          <Main 
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              editMode,
                              synced,
                            }}
                            employees={employees}
                            tasks={tasks}
                            selectedTask={selectedTask}
                            resetFields={resetFields}
                            status={status}
                            tenant={tenant}
                            manager={manager}
                            userID={userID}
                            fromTimesheet={fromTimesheet}
                            myTime={myTime}
                            group={group}
                            handleSetEmployeeGuid={handleSetEmployeeGuid}
                            fromDashboard={fromDashboard}
                            setProjectGuid={setProjectGuid}
                            taskBillable={taskBillable}
                          />
                        </Box>

                        {
                          !synced &&
                          status !== Mnemonic.TimesheetStatuses.Approved &&
                          (
                            userID == null ||
                            (userID === storeUserGuid) ||

                            (isAdmin && (tenant ? tenant === storeUserTenant : true)) ||

                            manager === storeUserGuid 

                            // (values[project.name] !== null
                            //   ? values[project.name]?.projectPermissions?.some(
                            //     permission => permission.resourceGuid === storeUserGuid
                            //   )
                            //   : true)
                          ) &&

                          (
                            <Box
                              mt={2}
                              width="100%"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <IALoadingButton
                                disabled={button1Subitting}
                                loading={button1Subitting}
                                type="button"
                                label={t("timeEntryForm.save")}
                               
                                  onClick={() => {
                                    if (!button1Subitting) {
                                      handleSubmit(formikValuesRef.current); 
                                    }
                                  }}
                              />
                              <span style={{ marginLeft: "10px" }}>
                                <IALoadingButton
                                  disabled={button2Subitting}
                                  loading={button2Subitting}
                                  type="button"

                                  onClick={() => {
                                    if (!button2Subitting) {
                                      handleSubmit(formikValuesRef.current);
                                     (saveAndClose.current = true) }}
                                  }
                                 
                                  label={t("timeEntryForm.save.close")}
                                />
                              </span>
                            </Box>
                          )}
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
              {/* </ContainerCard> */}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TimesheetForm;
