import { gql } from "@apollo/client";

export const GET_MISSED_CLOCKINS_FOR_DAY = gql`
  query ($model: GetMissedClockInsForDayRequestModelInput!) {
    GetMissedClockIns(model: $model) {
      missedClockIns {
        records {
          employee {
            profilePictureURL
            firstName
            lastName
          }
          workShiftRecord {
            startHour
          }
        }

        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_DAILY_TIME_OFFS = gql`
  query ($model: GetMissedClockInsForDayRequestModelInput!) {
    GetPtosForUsers(model: $model) {
      toEntitlementListResponse {
        toEntitlements {
          toEntitlementGuid
          toRequest {
            toRequestGuid
            timeFrom
            timeTo
            description
          }
          employeePTO {
            employee {
              firstName
              lastName
            }
            pto {
              description
              dateUnit {
                description
              }
            }
          }
          numberOfDays
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_LATE_CLOCKINS = gql`
  query ($model: GetMissedClockInsForDayRequestModelInput!) {
    GetLateEmployees(model: $model) {
      employeesDayLogs {
        employeesDayLogs {
          employee {
            employeeGuid
            firstName
            lastName
            profilePictureURL
          }
          workShiftDay {
            startHour
            endHour
            dayName
          }
          checkInTime
          checkOutTime
        }

        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_EARLY_CLOCKOUT = gql`
  query ($model: GetMissedClockInsForDayRequestModelInput!) {
    GetEarlyLeaveEmployees(model: $model) {
      employeesDayLogs {
        employeesDayLogs {
          employee {
            employeeGuid
            firstName
            lastName
            profilePictureURL
          }
          workShiftDay {
            startHour
            endHour
            dayName
          }
          checkInTime
          checkOutTime
        }

        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_DAY_LOGS_FOR_DATE = gql`
  query ($model: GetDayLogsForDateModelInput!) {
    GetDayLogsForDate(model: $model) {
      dayLogs {
        dayLogs {
          employee {
            employeeGuid
            firstName
            lastName
            profilePictureURL

            position
            department {
              name
            }

            workType {
              description
            }
          }
          dailyTotal
          dayLogs {
            dayLogGuid
            checkInTime
            checkOutTime
            workShiftDay {
              startHour
              endHour
            }
            workType {
              description
              mnemonic
            }
            location {
              name
              projects {
                description
              }
            }
            project {
              title
            }
            task {
              title
            }
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ATTENDANCE_ANALYTICS = gql`
  query ($model: AttendanceAnalyticsRequestModelInput!) {
    GetAttendanceAnalytics(model: $model) {
      attendanceAnalytics {
        missedClockinsCount
        whosOffTodayCount
        whosLateToday
        whoLeftEarlyToday
        todaysClockinsCount
        upcomingHolidaysCount
        upcomingPTOsCount

        requests {
          requestGuid

          requestType {
            requestTypeGuid
            description
            mnemonic
          }
          employee {
            employeeGuid
            firstName
            lastName
            email
          }
          manager {
            firstName
            lastName
          }
          requestDate
          timeFrom
          timeTo
          dateFrom
          dateTo
          clockIn
        }
      }
      errorVariables
      errorCode
      errorMessage
    }
  }
`;

export const GET_ATTENDANCE_ANALYTICS_SUBSCRIPTION = gql`
  subscription ($model: AttendanceAnalyticsRequestModelInput!) {
    attendanceAnalyticsSubscription(model: $model) {
      attendanceAnalytics {
        missedClockinsCount
        whosOffTodayCount
        whosLateToday
        whoLeftEarlyToday
        todaysClockinsCount
        upcomingHolidaysCount
        upcomingPTOsCount

        requests {
          requestGuid

          requestType {
            requestTypeGuid
            description
            mnemonic
          }
          employee {
            employeeGuid
            firstName
            lastName
            email
          }
          manager {
            firstName
            lastName
          }
          requestDate
          timeFrom
          timeTo
          dateFrom
          dateTo
          clockIn
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_DAY_LOG_BY_ID = gql`
  query ($id: UUID!) {
    GetDayLogByID(id: $id) {
      dayLogObject {
        dayLogGuid
        description
        employee {
          firstName
          lastName
          tenantGuid
          directManagerGuid
          employeeGuid
        }
        workType {
          workTypeGuid
          description
          mnemonic
        }
        location {
          locationGuid
          name
        }
        checkInTime
        checkOutTime
        isOfficialHoliday
        isLate
        isEarly
        isAutomaticRecord
        automaticallyClockedOut
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const EDIT_DAY_LOG = gql`
  mutation ($model: DayLogEditRequestInput!) {
    EditDayLog(model: $model) {
      dayLogObject {
        dayLogGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_DAY_LOG = gql`
  mutation ($model: DayLogCreateRequestInput!) {
    CreateDayLog(model: $model) {
      dayLogObject {
        dayLogGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_DAY_LOG = gql`
  mutation ($model: DeleteDayLogRequestInput!) {
    DeleteDayLog(model: $model) {
      deletedDayLog
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ATTENDANCES = gql`
  query ($model: AttendancesGetRequestInput!) {
    GetAttendances(model: $model) {
      attendancesResponse {
        dayLogs {
          dayLogGuid
          kioskDevicetGuid
          employee {
            firstName
            lastName
            workShift {
              description
            }
          }
          workShiftStartHour
          workShiftEndHour
          location {
            locationGuid
            name
          }
          workType {
            workTypeGuid
            mnemonic
            description
          }
          isLate
          isEarly
          isAutomaticRecord
          webClockin
          checkInTime
          checkOutTime
          workTimeHour
          workTimeMinute
          automaticallyClockedOut
          createdBy {
            email
          }
          creationDate
          modifiedBy {
            email
          }
          modificationDate
          attendanceRequest {
            approvalDate
            approvedBy {
              email
            }
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
