import React from "react";

import { styled } from "styled-components";

import { useTranslation } from "react-i18next";

const BadgeContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  align-items:center;
  gap:5px;
`;

const Badge = styled.span`
  background-color: var(--${(props) => props.color + "-color"});
  width: 10px;
  height: 10px;
  border-radius: 50%;

`;

const Text = styled.p`
  font-weight: 600;
  font-size: 0.85rem;
`;

const BadgeDot = ({ color, text }) => {
  const { t } = useTranslation();

  const color_array = ["success", "error", "info"];

  const color_chosen = color_array.includes(color) ? color : "error";

  return (
    <BadgeContainer>
      <Badge color={color_chosen}></Badge>
      <Text>{t(text)}</Text>
    </BadgeContainer>
  );
};

export default BadgeDot;
