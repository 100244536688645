import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Link } from "react-router-dom";

import UTC2Local, {formatDateTimeToString, formatDate} from "../../../../UTC2Local";

import LongText from "../../../../components/Text/LongText";

import "./stye.css";

export function EmployeeTimesheetTable({
  t,
  data,
  handleOpenModal,
  generateTaskRoute,
}) {
  const { dateSummaries, taskSummaries, totalDuration } = data;

  const displayDateDetailsCells = () => {
    const dateDetailsCells = [];
    for (let i = 0; i < 1; i++) {
      dateDetailsCells.push(
        <TableCell key={`empty-date-${i}`} align="left" sx={{ left: 0, position: "sticky", backgroundColor: "#fff", zIndex: "10" }}></TableCell>
      );
    }

    dateSummaries.forEach((row, index) => {
      dateDetailsCells.push(
        <TableCell
          key={`key-${index}`}
          align="right"
          sx={{
            width: "20%",
            fontWeight: "bold",
            paddingRight: "5px",
            paddingLeft: 0,
            top: 0, // Ensure the top property is set to 0
            left: 0,
            position: "sticky",
            backgroundColor: "#fff",
            zIndex: "1", // Adjusted zIndex value
          }}
        >
          {row?.totalDurationByDate}
        </TableCell>
      );
    });

    dateDetailsCells.push(
      <TableCell
        align="right"
        sx={{
          width: "20%",
          fontWeight: "bold",
          paddingRight: "5px",
          paddingLeft: 0,
          top: 0, // Ensure the top property is set to 0
          left: 0,
          position: "sticky",
          backgroundColor: "#fff",
          zIndex: "1", // Adjusted zIndex value
        }}
      >
        {totalDuration}
      </TableCell>
    );
    return dateDetailsCells;
  };

  const findTaskDurationForDate = (date, dateDetails) => {
    const matchingDate = dateDetails.find(
      (detail) => UTC2Local(detail.date, true) === UTC2Local(date, true)
    );
    return matchingDate ? matchingDate.taskDuration : "";
  };

  return (
    <TableContainer component={Paper} className={"table-timesheet-height"} >
      <Table
        id="employee-timesheet-table"
        sx={{ tableLayout: "fixed", width: "100%" }}
        stickyHeader
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {/* <TableCell sx={{ minWidth: "auto", width: "300px" }}>
              {t("employeeTimesheet.project")}
            </TableCell> */}
            <TableCell sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap !important",
              overflow: "hidden",
              width: "20vw !important",
              minWidth: "auto",  top: 0, left: 0, position: "sticky", backgroundColor: "#fff", zIndex: "3"
            }}>
              {t("employeeTimesheet.task")}
            </TableCell>
            {dateSummaries &&
              dateSummaries.map((date, index) => (
                <TableCell
                  key={index}
                  align="right"
                  sx={{
                    paddingLeft: 0,
                    paddingRight: "5px",
                    width: "70px",
                    top: 0, // Ensure the top property is set to 0
                    left: 0,
                    position: "sticky",
                    backgroundColor: "#fff",
                    zIndex: "1", // Adjusted zIndex value
                  }}
                >
                  {formatDateTimeToString(date?.date, false, true, null, false)}
                </TableCell>
              ))}
            <TableCell
              align="right"
              sx={{
                paddingLeft: 0,
                paddingRight: "5px",
                width: "70px",
                top: 0, // Ensure the top property is set to 0
                left: 0,
                position: "sticky",
                backgroundColor: "#fff",
                zIndex: "1", // Adjusted zIndex value
              }}
            >
              {t("employeeTimesheet.total")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {taskSummaries &&
            taskSummaries.map((row, index) => {
              const { dateDetails } = row;
              const dateDetailsCells = [];

              dateSummaries.forEach((dateSummary) => {
                const taskDuration = findTaskDurationForDate(
                  dateSummary?.date,
                  dateDetails
                );
                dateDetailsCells.push(
                  <TableCell
                    key={dateSummary.date}
                    align="right"
                    sx={{
                      paddingLeft: 0, paddingRight: "5px",
                      top: 0, // Ensure the top property is set to 0
                      left: 0,
                      position: "sticky",
                      backgroundColor: "#fff",
                      zIndex: "1", // Adjusted zIndex value
                    }}
                  >
                    {taskDuration}
                  </TableCell>
                );
              });

              dateDetailsCells.push(
                <TableCell
                  key="total"
                  align="right"
                  sx={{
                    fontWeight: "bold",
                    paddingLeft: 0,
                    paddingRight: "5px",
                    top: 0, // Ensure the top property is set to 0
                    left: 0,
                    position: "sticky",
                    backgroundColor: "#fff",
                    zIndex: "1", // Adjusted zIndex value
                  }}
                >
                  {row?.totalTaskDuration}
                </TableCell>
              );

              return (
                <TableRow
                  key={row?.taskGuid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell
                    component={Link}
                    align="left"
                    className="link-cell"
                    sx={{ paddingRight: 0 }}
                    to={generateProjectRoute(row)}
                    onClick={(event) => handleOpenModal(event, row)}
                  >
                    {LongText({ text: row.projectTitle, length: 48 })}
                  </TableCell> */}
                  <TableCell
                    component={Link}
                    align="left"
                    className="link-cell task"
                    sx={{
                      paddingRight: 0, left: 0, position: "sticky", top: 0, // Ensure the top property is set to 0
                      left: 0,
                      position: "sticky",
                      backgroundColor: "#fff",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap !important",
                      overflow: "hidden",
                      zIndex: "3", // Adjusted zIndex value
                    }}
                    to={generateTaskRoute(row)}
                    onClick={(event) => handleOpenModal(event, row)}
                  >
                    {LongText({ text: `${row?.projectTitle} - ${row?.taskName}`, length: 70 })}
                  </TableCell>
                  {dateDetailsCells}
                </TableRow>
              );
            })}
          <TableRow>{displayDateDetailsCells()}</TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
