import React from "react";
import colorsLight from "../../assets/theme/light/colors";
import LongText from "../Text/LongText";

const TicketStatus = (props) => {
  const { size, row, length, widthAuto } = props;
  
  const { taskStateColors } = colorsLight;
  if (!props) return null;
  return (
    <div
      style={{
        padding: "5px 7px",
        width: widthAuto ? "fit-content" : "100px",
        fontSize: size ? size : "14px",
        borderRadius: "5px",
        fontFamily: "poppins  !important",
        margin: "auto",
        ...taskStateColors[
          row?.status?.taskState?.mnemonic || props?.status?.taskState?.mnemonic
        ],
      }}
    >
      {
       props?.status?.description?.length < 10 || row?.status?.description?.length < 10
       ? row?.status?.description || props?.status?.description
       : 
       LongText({
        text: `${row?.status?.description || props?.status?.description}`,
        length: length ? length : 10,
      })}
    </div>
  );
};

export default TicketStatus;
