import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PaperComponent from "./PaperComponent";

import { styled } from "@mui/material/styles";
import colors from "../../assets/theme/light/colors";
import { CloseIcon, ExclamationIcon } from "../Icon";

const ConfirmationModal = ({
  icon,
  title,
  content,
  contentText,
  openDialog,
  handleClose,
  customButtons,
  closeButton,
  t,
  justifyButtons,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{ cursor: "move", display: "flex", alignItems: "center" }}
        id="draggable-dialog-title"
      >
        {icon && icon === "warning" ? (
          <ExclamationIcon color={colors.red_color} />
        ) : (
          icon
        )}

        <span style={{ marginLeft: "5px" }}>{title}</span>
        {closeButton && <IconButton
          onClick={handleClose}
        >
          <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
        </IconButton>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {content && content()}
      </DialogContent>
      <DialogActions style={justifyButtons ? { justifyContent: justifyButtons } : {}}>{customButtons()}</DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
