const form = {
  formID: "company",
  formField: {
    name: {
      name: "name",
      label: "companyForm.name.label",
      requiredMessage: "companyForm.name.requiredMessage",
    },
    tenant:{
      name:"tenant",
      label:"companyForm.tenant.label",
      requiredMessage:"companyForm.tenant.requiredMessage"
    },
    version:{
      name:"version",
      label:"companyForm.version.label",
      requiredMessage:"companyForm.version.requiredMessage"
    },
    externalReference:{
      name:"externalReference",
      label:"companyForm.externalReference.label",
      requiredMessage:"companyForm.externalReference.requiredMessage",
    },
    connectionString:{
      name:"connectionString",
      label:"companyForm.connectionString.label",
      requiredMessage:"companyForm.connectionString.requiredMessage"
    },
    userName:{
      name:"userName",
      label:"companyForm.userName.label",
      requiredMessage:"companyForm.userName.requiredMessage"
    },
    password:{
      name:"password",
      label:"companyForm.password.label",
      requiredMessage:"companyForm.password.requiredMessage"
    },
    clientId:{
      name:"clientId",
      label:"companyForm.clientId.label",
      requiredMessage:"companyForm.clientId.requiredMessage"
    },
    secretId:{
      name:"secretId",
      label:"companyForm.secretId.label",
      requiredMessage:"companyForm.secretId.requiredMessage"
    },
    tenantId:{
      name:"tenantId",
      label:"companyForm.tenantId.label",
      requiredMessage:"companyForm.tenantId.requiredMessage"
    },
  },
};
  
export default form;
