import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import { Grid, CircularProgress, Autocomplete } from "@mui/material";

import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import AutoComplete from "../../../../components/AutoComplete";
import { GET_ATTENDANCE_BY_EMPLOYEE } from "../../../../graphql/reports";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import { useSnackbar } from "notistack";

import { AttendanceByEmployeeTable } from "./_table";

import colors from "../../../../assets/theme/light/colors";
import { handleValidateDates } from "../../../../utils/functions";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import Tooltip from "../../../../components/Tooltip";
import { NextIcon, PreviousIcon } from "../../../../components/Icon";
import { DATE_FILTER_DATA, handleDateBackward, handleDateForward, handleTimeBetween } from "../../../../utils/period";

import { v4 as uuidv4 } from "uuid";

function AttendanceByEmployee() {
  const { storeUserTenant, storeUserGuid, userRoles, isManager, user } =
    useAuth();

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const { dark_logo_blue } = colors;

  const { enqueueSnackbar } = useSnackbar();

  const { manageAttendance } = userRoles;

  const [re, setRe] = useState(false);

  const nowDate = new Date();

  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );

  const [open, setOpen] = useState(false);

  const [employeeID, setEmployeeID] = useState(
    isManager === true && user ? user.user?.employeeGuid : null
  );

  const [employeeValue, setEmployeeValue] = useState(
    isManager === true && user ? user.user : null
  );

  const [timer, setTimer] = useState(null);
  const [list, setList] = useState(null);
  const [fetchEmployees, setFetchEmployees] = useState();
  const [fetchProjects, setFetchProjects] = useState();

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        // attendanceDisabled: true,
      },
    },
    skip: !fetchEmployees,
    isWait: fetchEmployees,
  });

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    mfunction: getData,
    refetch,
    loading: dataLoading,
  } = useLazyQueryAuth(GET_ATTENDANCE_BY_EMPLOYEE, "GetAttendanceByEmployee");

  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  useEffect(() => {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    if (!employeeWait && oneDayBefore <= dateTo) {
      getData(
        {
          variables: {
            model: {
              //   userID: storeUserGuid,
              tenantGuid: storeUserTenant,
              employeeGuid: employeeID,
              //   employeeGuids: employeeIDs.length > 0 ? employeeIDs : null,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              loggedInUserID: storeUserGuid,
            },
          },
        },
        (response) => {
          if (response["GetAttendanceByEmployee"].attendanceByEmployee != null)
            setList(response?.GetAttendanceByEmployee?.attendanceByEmployee);
          else
            enqueueSnackbar(
              t(response["GetAttendanceByEmployee"].errorMessage),
              {
                variant: "error",
                autoHideDuration: 5000,
              }
            );
        }
      );
    }
  }, [employeeWait, re]);

  // const handleValidateDates = (value) => {
  //   if (timer) {
  //     //if timer is set, we empty it so it's not called and wait another delay
  //     clearTimeout(timer);
  //     setTimer(null);
  //   }

  //   //set timer for after 2 seconds of wait
  //   setTimer(
  //     setTimeout(() => {
  //       const oneDayBefore = new Date(dateFrom);
  //       oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
  //       if (value < oneDayBefore)
  //         enqueueSnackbar(t("attendanceByEmployee.invalid.date"), {
  //           variant: "error",
  //           autoHideDuration: 5000
  //         });
  //       else setDateTo(value);
  //     }, 500)
  //   );
  // };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
          }}
        >
          <Grid container spacing={1}>
            {manageAttendance !== "N" && (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <AutoComplete
                  loading={employeeLoading}
                  options={employeeList}
                  getOptionLabel={(option) =>
                    option ? option.firstName + " " + option.lastName : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.firstName + " " + option.lastName}
                    </li>
                  )}
                  identifier={"employeeGuid"}
                  size="small"
                  value={employeeValue}
                  onOpen={() => setFetchEmployees(true)}
                  onClose={() => setFetchEmployees(false)}
                  onChange={(event, value) => {
                    setEmployeeValue(value);
                    setEmployeeID(value?.employeeGuid);
                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                  }}
                  label={t("attendanceByEmployee.label.employee")}
                  roundedTextField
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                label={t("attendanceByEmployee.label.dateFrom")}
                value={dateFrom}
                // disableFuture
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                onClose={() => setOpen(true)}
                // renderInput={(params) => (
                //   <RoundedTextField removeMinWidth {...params} />
                // )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                label={t("attendanceByEmployee.label.dateTo")}
                value={dateTo}
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                // renderInput={(params) => (
                //   <RoundedTextField removeMinWidth {...params} />
                // )}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <PreviousIcon
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={1.5} md={1.5} lg={1.5} xl={1.5}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {t(option.name)}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{".MuiInputBase-input": {fontSize: "14px !important"}}}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <NextIcon 
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }} 
                />
              </Grid>
            </Tooltip>

            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <IALoadingButton
                onClick={() => setRe((val) => !val)}
                style={{
                  width: "150px",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                }}
                label={t("attendanceByEmployee.search")}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ overflowX: "auto" }}
          >
            {dataLoading && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!dataLoading && list && (
              <AttendanceByEmployeeTable data={list} t={t} />
            )}
            {!dataLoading && (!list || list.length == 0) && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                {t("attendanceByEmployee.no.attendance")}
              </div>
            )}
          </Grid>
        </ContainerCard>
      </Grid>
    </>
  );
}

export default AttendanceByEmployee;
