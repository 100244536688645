import React, { useState, useCallback, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";



//translation
import { useTranslation } from "react-i18next";

//component
import GridTable from "../../../../components/Tables/GridTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//gql
import {
  GET_ALL_SKILLS,
  DELETE_SKILLS_LIST,
  CREATE_SKILL,
  UPDATE_SKILL,
} from "../../../../graphql/skill";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

function Skills({ fromTenant }) {
  const { tID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const DEFAULT_SKILL = {
    skillGuid: "",
    name: "",
    tenantID: fromTenant ? tID : storeUserTenant,
  };

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [re, setRe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newObj, setNewObj] = useState({
    skillGuid: "",
    name: "",
    tenantID: fromTenant ? tID : storeUserTenant,
  });
  const requiredFields=["skillGuid", "name"];

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_SKILLS_LIST,
    "DeleteSkillsList"
  );

  const { mfunction: getAllSkills, loading } = useLazyQueryAuth(
    GET_ALL_SKILLS,
    "GetAllSkills"
  );

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.SkillsGrid,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }
        }
      },
    }
  );

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    getAllSkills(
      {
        variables: {
          model: {
            enableFilters: true,
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            keyWord: search,
            tenantGuid: fromTenant ? tID : storeUserTenant,
          },
        },
      },
      (response) => {
        setData(response);
        setRows(response?.GetAllSkills?.skillsList?.skills);
      }
    );
  }, []);

  const records =
    data &&
    data.GetAllSkills &&
    data.GetAllSkills.skillsList &&
    data.GetAllSkills.skillsList.skills
      ? data.GetAllSkills.skillsList.skills
      : [];
  const numberRecords =
    data &&
    data.GetAllSkills &&
    data.GetAllSkills.skillsList &&
    data.GetAllSkills.skillsList.skills &&
    data.GetAllSkills.skillsList.totalNumberOfRecords
      ? data.GetAllSkills.skillsList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (!columnsWait && setCreate.current == false)
      fetchTableData(page, rowsPerPage, search);
  }, [columnsWait, fetchTableData, re]);

  const handleDelete = (selectedItems) => {
    deleteRecords(
      {
        variables: {
          model: {
            skillIDs: selectedItems,
          },
        },
      },
      (response) => {
        if (response["DeleteSkillsList"].crudSkill == true) {
          enqueueSnackbar(t("skill.list.delete.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        } else {
        }
      }
    );
  };

  const { cfunction: createSkillData } = useMutationAuth(
    CREATE_SKILL,
    "CreateSkill"
  );

  const { cfunction: updateSkillData } = useMutationAuth(
    UPDATE_SKILL,
    "UpdateSkill"
  );

  function CreateSkillData(newRow) {
    setIsSubmitting(true);
    if (newRow != null && newRow.skillGuid != null) {
      createSkillData(
        {
          variables: {
            model: {
              name: newRow.name,
              tenantID: newRow.tenantID,
            },
          },
        },
        (response) => {
          if (
            response["CreateSkill"].errorCode !== null &&
            response["CreateSkill"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateSkill"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            setRows(rows.filter((r) => r.skillGuid !== newObj.skillGuid));
            setNewObj(DEFAULT_SKILL);
          } else {
            enqueueSnackbar(t("skillForm.createdSuccessfully"), {
              variant: "success",
            });
            fetchTableData(page, rowsPerPage, search);
            setNewObj(DEFAULT_SKILL);
          }
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        },
        (error) => {
          setRows(rows.filter((r) => r.skillGuid !== newObj.skillGuid));
          setNewObj(DEFAULT_SKILL);
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        }
      );
    }
  }

  function UpdateSkillData(updatedRow) {
    updateSkillData(
      {
        variables: {
          model: {
            skillGuid: updatedRow.skillGuid,
            name: updatedRow.name,
            tenantID: updatedRow.tenantID,
          },
        },
      },
      (response) => {
        if (
          response["UpdateSkill"].errorCode !== null &&
          response["UpdateSkill"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateSkill"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_SKILL);
        } else {
          enqueueSnackbar(t("skillForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_SKILL);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_SKILL);
      }
    );
  }

  const pagination = {
    rows: rows.length == 0 ? records : rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    removeFilters:true,
    rowsPerPage,
    fetchTableData,
    loading: loading || getColumnsLoading,
    searchText: "skill.list.search.label",
    // refetch,
    tableMnemonic: Mnemonic.Tables["Skills"],
    deleteText: "skill.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== 'M',
    isSubmitting,
    requiredFields,
    enqueueSnackbar
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && (
          <GridTable
            {...pagination}
            identifier="skillGuid"
            hasCheckbox={false}
            handleCreate={(skill) => CreateSkillData(skill)}
            handleUpdate={(skill) => UpdateSkillData(skill)}
            handleDelete={(skills) => handleDelete(skills)}
          />
        )
      )}
    </div>
  );
}

export default Skills;
