import React, { useState, useEffect, useRef } from "react";

import "./style.css";
//components
import {
  Grid,
  Box,
  IconButton,
  Modal,
  Popper,
  Button,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import Tooltip from "../../../../../components/Tooltip";
import AutoComplete from "../../../../../components/AutoComplete";
import {
  Title,
  MiniSubtitle,
} from "../../../../../components/Titles/FormTitles";

//translation
import { useTranslation } from "react-i18next";
///time
import { day_month_year } from "../../../../../utils/timezones";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";

//Quill
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

import colors from "../../../../../../src/assets/theme/light/colors";
import urls from "../../../../../urls";

//graphql
import { GET_ALL_COMPANIES_DROPDOWN } from "../../../../../graphql/company";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../../graphql/location";
import { GET_ALL_TENANTS } from "../../../../../graphql/tenant";

import LocationForm from "../../../Locations/Locations-form";
import {
  FAVORITE_PROJECT,
  GET_ALL_PROJECTS_STATUSES,
} from "../../../../../graphql/project";
import FormModal from "../../../../../components/Modal/FormModal";
import { useParams } from "react-router-dom";

import Mnemonics from "../../../../../Mnemonics.json";
import { LOGOUT_USER } from "../../../../../graphql/user";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import axios from "axios";
import { parseJwt } from "../../../../../utils/parseJwt";
import componentDisplay from "../../../../../componentDisplay";
import TextField from "../../../../../components/TextFields/TextField";
import {
  AlertIcon,
  FilledStarIcon,
  OutlinedStarIcon,
} from "../../../../../components/Icon";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

var Image = Quill.import("formats/image");
Image.className = "custom-image-size";
Quill.register(Image, true);

const backendURL = process.env.REACT_APP_REST_URL;

const Main = ({
  formData,
  tenantID,
  projectGuid,
  formFlag,
  handleChangeFlag,
  mediaList,
  setMediaList,
  favorite,
  setFavorite,
  updateProjectQuick,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { my_locations_form } = urls;

  const {
    storeUserGuid,
    userRoles,
    storeUserTenant,
    isEmployee,
    jwtToken,
    logout,
  } = useAuth();

  const dispatch = useDispatch();

  const { manageTimeEntry, manageSystemConfigurations } = userRoles;
  const { projectColors } = colors;
  // const [displayAddLocation, setDisplayAddLocation] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fetchCompanies, setFetchCompanies] = useState(false);
  const [fetchTenants, setFetchTenants] = useState(false);
  const [fetchLocations, setFetchLocations] = useState(false);
  const [fetchStatuses, setFetchStatuses] = useState(false);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [location_list, setLocation_List] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);

  const imageUrl = useRef("");
  const quillRef = useRef();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    wait,
  } = formData;

  const {
    title,
    description,
    status,
    tenant,
    company,
    location,
    externalReference,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
  } = formField;


  const {
    title: titleV,
    synced: syncedV,
    description: descriptionV,
    status: statusV,
    tenant: tenantV,
    company: companyV,
    location: locationV,
    externalReference: externalReferenceV,
    EstimatedStartDate: EstimatedStartDateV,
    EstimatedEndDate: EstimatedEndDateV,
    EstimatedTime: EstimatedTimeV,
    ActualStartDate: ActualStartDateV,
    ActualEndDate: ActualEndDateV,
    ActualTime: ActualTimeV,
  } = values;


  // console.log("main", values)
  const { pID } = useParams();

  useEffect(() => {
    const allImgs = document.querySelectorAll(".custom-image-size");

    const handleClick = (event) => {
      const img = event.target;
      const imageWindow = window.open("", "_blank");
      imageWindow?.document.write(`
        <html>
          <head></head>
          <body>
            <img src="${img.src}" alt="image">
          </body>
        </html>
      `);
    };

    if (allImgs && allImgs.length > 0) {
      allImgs.forEach((img) => {
        img.addEventListener("click", handleClick);
      });
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (allImgs && allImgs.length > 0) {
        allImgs.forEach((img) => {
          img.removeEventListener("click", handleClick);
        });
      }
    };
  }, []);

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  const { cfunction: favoriteProject } = useMutationAuth(
    FAVORITE_PROJECT,
    "EditProjectFavourites"
  );

  const handleFavorite = (addToFavorites) => {
    favoriteProject(
      {
        variables: {
          model: {
            addToFavorites: addToFavorites,
            employeeGuid: storeUserGuid,
            projectGuid: pID,
          },
        },
      },
      (response) => {
        // if (response["EditProjectFavourites"].editProjectFavouritesResponse === true) {
        //   enqueueSnackbar(addToFavorites ? t("favoriteProject.success.added") : t("favoriteProject.success.removed"), {
        //     variant: "success",
        //   });
        // }
        setFavorite(!favorite);
      }
    );
  };

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  const removeImg = () => {
    const images = document.querySelectorAll("img");
    // Loop through each image element
    images.forEach((img) => {
      // Check if the src attribute contains "data:image/png;base64"
      if (img.src.startsWith("data:image/")) {
        // Remove the image element from the DOM
        img.remove();
      }
    });
  };

  async function uploadImage(formData, newImage) {
    setLoadingImage(true);
    const quill = quillRef.current;
    let range =
      quill.getEditorSelection() || quill?.editor?.selection?.savedRange;

    const res = await apiCall(
      backendURL + `/Project/UploadProjectImage`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      let tempMediaList = mediaList;
      tempMediaList.push(res.data.fileName);
      setMediaList(tempMediaList);
      imageUrl.current = res.data.url;
      if (newImage === true) {
        removeImg();
        quill
          .getEditor()
          .insertEmbed(range.index, "image", `${imageUrl.current}`);
      }
      setLoadingImage(false);
    } else {
      setLoadingImage(false);
      removeImg();
    }

    return imageUrl.current;
  }

  function imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      // var file = input && input.files ? input.files[0] : null;
      var file = input.files[0];
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var formDataObj = new FormData();
        formDataObj.append("image", file, file.name);
        formDataObj.append("tenantID", tenantID);
        if (projectGuid) formDataObj.append("itemID", projectGuid);
      }
      uploadImage(formDataObj, true);
    };
  }

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [({ direction: "" }, { direction: "rtl" })],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["link"],
          ["image"],
          ["clean"],
        ],

        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  useEffect(() => {
    const link = document.querySelector(".ql-link");
    const clean = document.querySelector(".ql-clean");
    const tooltip = document.querySelector(".tooltip-link");
    const tooltipClean = document.querySelector(".tooltip-clean");

    const input = document.querySelector(".ql-tooltip input");

    if (link != null) {
      link.addEventListener("mouseover", () => {
        tooltip.style.display = "block";
      });

      link.addEventListener("mouseout", () => {
        tooltip.style.display = "none";
      });
      link.addEventListener("click", () => {
        input.value = "http://";
      });
    }
    if (clean != null && tooltipClean != null) {
      clean.addEventListener("mouseover", () => {
        tooltipClean.style.display = "block";
      });

      clean.addEventListener("mouseout", () => {
        tooltipClean.style.display = "none";
      });
    }
  }, []);

  function extractTypeFromDataURL(dataURL) {
    const base64String = dataURL.split(",")[0].split(";")[0];
    const type = base64String.substring(5);
    return type;
  }

  function base64ToBlob(base64Data, contentType = "image/png") {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function base64ToFile(base64Data, filename, contentType) {
    const blob = base64ToBlob(base64Data, contentType);
    return new File([blob], filename, { type: contentType });
  }

  async function replaceAndUploadImage(htmlText, filenameTemplate) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");

    const images = doc.querySelectorAll("img");
    let replacements = [];

    for (let img of images) {
      if (img.src.startsWith("data:image/")) {
        const base64String = img.src.split(",")[1]; // Extract base64 without the prefix
        const contentType = extractTypeFromDataURL(img.src);
        const ext = contentType.split("/")[1];
        const filename = `${generateRandomName()}.${ext}`;

        const file = base64ToFile(base64String, filename, contentType);

        // Prepare form data for the upload
        let formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (projectGuid) formData.append("itemID", projectGuid);

        // Upload the image and replace the src with the new URL
        const newUrl = await uploadImage(formData, false);
        img.src = newUrl; // Update the src in the DOM

        replacements.push(newUrl);
      }
    }
    return doc.body.innerHTML;
  }

  function generateRandomName(length = 12) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  useEffect(() => {
    const editor = document.querySelector(".ql-editor");

    if (editor) {
      editor?.addEventListener("paste", async (event) => {
        let data = event.clipboardData || window.clipboardData;
        let htmlObj = event.explicitOriginalTarget;

        if (data.files?.length > 0) {
          event.preventDefault();
          var file = data.files[0];
          var imageType = /image.*/;

          if (file.type.match(imageType)) {
            var formData = new FormData();
            formData.append("image", file, file.name);
            formData.append("tenantID", storeUserTenant);
            if (projectGuid) formData.append("itemID", projectGuid);
          }
          await uploadImage(formData, true);
        } else if (htmlObj?.innerHTML) {
          const innerHTML = await replaceAndUploadImage(
            htmlObj?.innerHTML,
            generateRandomName()
          );
          setFieldValue(description.name, innerHTML);
        }
      });
    }
  }, []);

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
  ];

  const handleQuillChange = (html) => {
    if (touched[description.name]) handleChangeFlag(touched[description.name]);
    setFieldValue(description.name, html);
  };

  const {
    loading: tenantLoading,
    tokenChange: tenantWait,
    data: tenantData,
  } = useQueryAuth(GET_ALL_TENANTS, "GetAllTenants", {
    variables: {
      model: {
        enableFilters: false,
      },
    },
    skip: !fetchTenants,
    isWait: fetchTenants,
  });

  const tenantList =
    tenantData &&
      tenantData.GetAllTenants &&
      tenantData.GetAllTenants.tenantsList &&
      tenantData.GetAllTenants.tenantsList.tenants
      ? tenantData.GetAllTenants.tenantsList.tenants
      : [];

  const {
    data: companiesList,
    loading: companyLoading,
    tokenChange: companyWait,
  } = useQueryAuth(GET_ALL_COMPANIES_DROPDOWN, "GetAllCompanies", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: tenantV != null ? tenantV.tenantGuid : storeUserTenant,
      },
    },
    skip: !fetchCompanies,
    isWait: fetchCompanies,
  });

  const company_list =
    companiesList &&
      companiesList.GetAllCompanies &&
      companiesList.GetAllCompanies.compList &&
      companiesList.GetAllCompanies.compList.companies
      ? companiesList.GetAllCompanies.compList.companies
      : [];

  const {
    data: locationsList,
    loading: locationLoading,
    tokenChange: locationWait,
    refetch: refetchData,
  } = useQueryAuth(GET_ALL_LOCATIONS_DROPDOWN, "GetAllLocations", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: tenantV != null ? tenantV.tenantGuid : storeUserTenant,
      },
    },
    skip: !fetchLocations,
    isWait: fetchLocations,
  });

  const { data: statusesList, loading: statusLoading } = useQueryAuth(
    GET_ALL_PROJECTS_STATUSES,
    "GetProjectStatuses",
    {
      skip: !createMode && !fetchStatuses,
      isWait: fetchStatuses ? true : false,
    }
  );

  const statusList = statusesList?.GetProjectStatuses?.projectStatuses || [];

  useEffect(() => {
    if (locationsList)
      setLocation_List(
        locationsList &&
          locationsList.GetAllLocations &&
          locationsList.GetAllLocations.locationsList &&
          locationsList.GetAllLocations.locationsList.locations
          ? locationsList.GetAllLocations.locationsList.locations
          : []
      );
  }, [locationsList]);

  // function handleAddLocation(data) {
  //   const newLocation = data?.CreateLocation?.location || {};

  //   setLocation_List((old) => [...old, newLocation]);

  //   setFieldValue(location.name, newLocation);
  //   setDisplayAddLocation(false);
  // }

  // const handleClose = () => {
  //   setDisplayAddLocation(false);
  // };

  const StartDateFields = () => {
    const fields = [
      {
        label: t(EstimatedStartDate.label),
        value: EstimatedStartDateV,
      },
      {
        label: t(ActualStartDate.label),
        value: ActualStartDateV,
      },
    ];

    const isStartDateLate =
      EstimatedStartDateV !== null &&
      ActualStartDateV !== null &&
      ActualStartDateV > EstimatedStartDateV;

    return (
      <Grid
        container
        spacing={2}
        mt={0.5}
        mb={0.5}
        ml={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ margin: "0", padding: "0" }}
            >
              <p className="timeFields">{field.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{
                margin: "0",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="timeFieldsV">{field.value}</p>
              {index === 1 && isStartDateLate ? (
                <AlertIcon
                  color="#ffbf00"
                  width="20"
                  height="20"
                  title="Late"
                />
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };
  const EndDateFields = () => {
    const formatDate = (date, userTimezone) => {
      if (!date) {
        return ""; // Return an empty string for null or undefined dates
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      const formattedDate = new Intl.DateTimeFormat("en", options).format(date);


      if (day_month_year.includes(userTimezone)) {
        // If the user's timezone is in the day_month_year format
        const [month, day, year] = formattedDate.split("/");
        return `${day}-${month}-${year}`;
      } else {
        // If the user's timezone is in the month_day_year format
        const [month, day, year] = formattedDate.split("/");
        return `${month}-${day}-${year}`;
      }
    };

    const fields = [
      {
        label: t(EstimatedEndDate.label),
        value: EstimatedEndDateV,
      },
      {
        label: t(ActualEndDate.label),
        value: ActualEndDateV,
      },
    ];

    const isEndDateLate =
      EstimatedEndDateV !== null &&
      ActualEndDateV !== null &&
      ActualEndDateV > EstimatedEndDateV;

    return (
      <Grid
        container
        spacing={2}
        mt={0.5}
        mb={0.5}
        ml={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ margin: "0", padding: "0" }}
            >
              <p className="timeFields">{field.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{
                margin: "0",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="timeFieldsV">{field.value}</p>
              {index === 1 && isEndDateLate ? (
                <AlertIcon
                  color="#ffbf00"
                  width="20"
                  height="20"
                  title="Late"
                />
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  const TimeByHour = () => {
    const fields = [
      {
        label: t(EstimatedTime.label),
        value: EstimatedTimeV,
      },
      {
        label: t(ActualTime.label),
        value: ActualTimeV,
      },
    ];

    const isActualLate =
      EstimatedTimeV !== null &&
      ActualTimeV !== null &&
      parseFloat(ActualTimeV) > parseFloat(EstimatedTimeV) &&
      parseFloat(EstimatedTimeV) !== 0;

    return (
      <Grid
        container
        spacing={2}
        mt={0.5}
        mb={0.5}
        ml={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              sx={{ margin: "0", padding: "0" }}
            >
              <p className={`timeFields ${index === 2 || index === 3 ? "timeFields timeFields-italic" : "timeFields"}`}>{field.label}</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{
                margin: "0",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className={`timeFieldsV ${index === 2 || index === 3 ? "timeFieldsV timeFieldsV-italic" : "timeFieldsV"}`}>{field.value}</p>
              {index === 1 && isActualLate ? (
                <AlertIcon
                  color="#ff2825"
                  width="20"
                  height="20"
                  title="Late"
                />
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  const statusContainer = () => {
    switch (syncedV) {
      case true:
        return (
          <Grid
            style={{
              //top: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-rotate-clockwise"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#00b341"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
            </svg>
            {t("projectForm.synced")}
          </Grid>
        );
      case false:
        return null;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (createMode && statusList.length > 0)
      setFieldValue(
        status.name,
        statusList.filter((s) => s.mnemonic == "new")[0]
      );
  }, [statusList.length]);

  const handleOpenLocationModal = () => {
    setTimeout(() => {
      setOpenModal(true);
    }, 800);
  };

  const handleStatusButtonClick = (event) => {
    setFetchStatuses(true);
    setAnchorElStatus(event.currentTarget);
  };

  const handleStatusMenuItemClick = (value) => {
    setAnchorElStatus(null);
    if (createMode) {
      setTouched({ ...touched, [status.name]: true });
      handleChangeFlag({ ...touched, [status.name]: true });
    }
    // setFieldValue(status.name, value);
    values.status = value;
    if (
      updateProjectQuick &&
      !createMode &&
      !sessionStorage.getItem("unsavedChanges")
    )
      updateProjectQuick(value?.projectStatusGuid);
    else if (
      handleSubmit &&
      !createMode &&
      sessionStorage.getItem("unsavedChanges")
    ) {
      setTimeout(() => {
        handleSubmit(values);
      }, 1000);
    }
  };

  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const statusPicked = () => {
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <Tooltip title={t("task.tootlip.status")}>
          <Button
            onClick={handleStatusButtonClick}
            disabled={
              readOnly ||
              manageTimeEntry === "R" ||
              isEmployee === Mnemonics.ResourceTypes.External ||
              false
            }
            sx={{
              ".MuiButton-root": { minWidth: "0px" },
              ":hover": { backgroundColor: "transparent !important" },
              padding: 0,
            }}
          >
            {componentDisplay({
              compName: "BackgroundStatus",
              compProps: { row: values, size: 12, length: 25, widthAuto: true },
            })}
          </Button>
          <Menu
            anchorEl={anchorElStatus}
            open={Boolean(anchorElStatus)}
            onClose={handleStatusClose}
          >
            {statusList?.map((option) => (
              <MenuItem
                key={option?.projectStatusGuid}
                onClick={() => {
                  handleStatusMenuItemClick(option);
                }}
              >
                {" "}
                {option?.description}
              </MenuItem>
            ))}
          </Menu>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <Box>
      <FormModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={false}
        refetch={refetchData}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
        Child={LocationForm}
      />
      {/* <Modal
        open={displayAddLocation}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div
          style={{
            maxWidth: "700px",
            maxHeight: "700px",
            margin: "50px 0px",
            overflow: "auto",
          }}
        >
          <LocationForm
            fromProject
            onSuccess={handleAddLocation}
            handleClose={handleClose}
          />
        </div>
      </Modal> */}
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={8.5}
          lg={8.5}
          xl={8.5}
          spacing={2}
          gap={1}
          className="grid-item"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              style={{ display: "flex" }}
            >
              <Title style={{ padding: "10px 0", marginRight: "10px " }}>
                {t("projectForm.projectInformation")}
              </Title>
              {statusContainer()}
            </Grid>
            {!createMode && (
              <Tooltip title={t("projectForm.favorite")}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  mr={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {favorite === false ? (
                    <OutlinedStarIcon
                      color="#F2CE09"
                      handleClick={(v) => handleFavorite(v)}
                    />
                  ) : (
                    <FilledStarIcon
                      color="#F2CE09"
                      handleClick={(v) => handleFavorite(v)}
                    />
                  )}
                </Grid>
              </Tooltip>
            )}
            {statusPicked()}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ display: "flex", alignItems: "start" }}
              >
                <TextField
                  autoFocus
                  fullWidth
                  value={titleV}
                  onChange={(e) => {
                    setFieldValue(title.name, e.target.value);
                  }}
                  label={t(title.label)}
                  name={title.name}
                  formData={formData}
                  required
                  disabled={readOnly}
                />
              </Grid>
              {tenantID == null && (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AutoComplete
                    options={tenantList}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.name}
                      </li>
                    )}
                    identifier={"tenantGuid"}
                    disabled={readOnly || manageTimeEntry === "R"}
                    value={tenantV}
                    onOpen={() => setFetchTenants(true)}
                    onClose={() => setFetchTenants(false)}
                    onChange={(e, value) => {
                      if (value && value.tenantGuid != null) {
                        setFieldValue(tenant.name, value);
                        setFieldValue(company.name, null);
                      }
                    }}
                    loading={tenantLoading}
                    label={t(tenant.label)}
                    name={tenant.name}
                    formData={formData}
                    required
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoComplete
                  options={company_list}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"companyGuid"}
                  disabled={readOnly || manageTimeEntry === "R"}
                  value={companyV}
                  onOpen={() => setFetchCompanies(true)}
                  onClose={() => setFetchCompanies(false)}
                  onChange={(e, value) => {
                    setFieldValue(company.name, value);
                  }}
                  loading={companyLoading}
                  label={t(company.label)}
                  name={company.name}
                  formData={formData}
                  required
                />
              </Grid>
              {!createMode && (
                <>
                  <Tooltip
                    title={t("project.tooltip.regularPayExternalResource")}
                  >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        fullWidth
                        value={externalReferenceV}
                        onChange={(e) => {
                          setFieldValue(externalReference.name, e.target.value);
                        }}
                        label={t(externalReference.label)}
                        name={externalReference.name}
                        formData={formData}
                        disabled={readOnly || manageTimeEntry === "R"}
                      />
                    </Grid>
                  </Tooltip>
                </>
              )}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoComplete
                  options={location_list}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"locationGuid"}
                  disabled={readOnly || manageTimeEntry === "R"}
                  value={locationV}
                  onOpen={() => setFetchLocations(true)}
                  onClose={() => setFetchLocations(false)}
                  onChange={(e, value) => {
                    setFieldValue(location.name, value);
                  }}
                  loading={locationLoading}
                  label={t(location.label)}
                  name={location.name}
                  formData={formData}
                  showDisplayView={
                    manageSystemConfigurations !== "N" && locationV
                  }
                  handleClickView={() =>
                    window.open(
                      my_locations_form.replace(
                        "/:lName/:lID",
                        `/${encodeURIComponent(
                          locationV?.name
                        )}/${encodeURIComponent(locationV?.locationGuid)}`
                      )
                    )
                  }
                  showDisplayAdd={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations === "M" &&
                    (locationV === null ||
                      locationV === undefined ||
                      locationV === "")
                  }
                  handleClickAdd={() => handleOpenLocationModal()}
                />
              </Grid>{" "}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                <div
                  style={{ position: "relative" }}
                  onFocus={() =>
                    setTouched({ ...touched, [description.name]: true })
                  }
                >
                  <Backdrop
                    sx={{
                      color: "#fff",
                      position: "absolute !important",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={loadingImage}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  <ReactQuill
                    ref={quillRef}
                    name="body"
                    value={descriptionV}
                    onChange={handleQuillChange}
                    readOnly={readOnly || manageTimeEntry === "R"}
                    modules={modules}
                    formats={formats}
                    placeholder="Description"
                  />
                </div>
                <div
                  className="tooltip-link"
                  style={{
                    fontFamily: "poppins",
                    lineHeight: "21px",
                    backgroundColor: "#ffffff",
                    color: "#000",
                    borderRadius: "2px",
                    opacity: "0.8",
                    boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                    padding: "13px !important",
                  }}
                >
                  {t("tooltip.link")}
                </div>
                <div
                  className="tooltip-clean"
                  style={{
                    fontFamily: "poppins",
                    lineHeight: "21px",
                    backgroundColor: "#ffffff",
                    color: "#000",
                    borderRadius: "2px",
                    opacity: "0.8",
                    boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                    padding: "13px !important",
                  }}
                >
                  {t("tooltip.clean")}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Grid container spacing={1}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mt={1}
                className="flex_space-between_center"
              >
                <MiniSubtitle>{t("projectForm.StartDate.label")}</MiniSubtitle>
              </Grid>
              {StartDateFields()}
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mt={2}
                style={{
                  paddingTop: "15px",
                  marginTop: "2rem",
                }}
                className="flex_space-between_center"
              >
                <MiniSubtitle>{t("projectForm.EndDate.label")}</MiniSubtitle>
              </Grid>
              {EndDateFields()}
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                mt={2}
                style={{
                  paddingTop: "15px",
                  marginTop: "2rem",
                }}
                className="flex_space-between_center"
              >
                <MiniSubtitle>{t("projectForm.TimeByHour.label")}</MiniSubtitle>
              </Grid>
              {TimeByHour()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
