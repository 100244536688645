const form = {
  formID: "Projects",
  formField: {
    title: {
      name: "title",
      label: "projectForm.title.label",
      requiredMessage: "projectForm.title.requiredMessage",
    },
    description: {
      name: "description",
      label: "projectForm.description.label",
      
    },
    synced:{
      name: "synced",
      label: "ticketForm.synced.label",
      requiredMessage: "TenantForm.synced.requiredMessage",
    },
    status: {
      name: "status",
      label: "projectForm.status.label",
      requiredMessage: "projectForm.status.requiredMessage",
    },
    tenant: {
      name: "tenant",
      label: "projectForm.tenant.label",
      requiredMessage:"projectForm.tenant.requiredMessage"
    },
    company: {
      name: "company",
      label: "projectForm.company.label",
      requiredMessage:"projectForm.company.requiredMessage"
    },
    location: {
      name: "location",
      label: "projectForm.location.label",
      requiredMessage:"projectForm.location.requiredMessage"
    },
    client: {
      name: "client",
      label: "projectForm.client.label",
      requiredMessage:"projectForm.client.requiredMessage"
    },
    externalReference: {
      name: "externalReference",
      label: "projectForm.externalReference.label",
      requiredMessage:"projectForm.externalReference.requiredMessage"
    },
    EstimatedStartDate:{
      name: "EstimatedStartDate",
      label: "projectForm.EstimatedStartDate.label",
      requiredMessage: "TenantForm.EstimatedStartDate.requiredMessage",
    },
    EstimatedEndDate:{
      name: "EstimatedEndDate",
      label: "projectForm.EstimatedEndDate.label",
      requiredMessage: "TenantForm.EstimatedEndDate.requiredMessage",
    },
    EstimatedTime:{
      name: "EstimatedTime",
      label: "projectForm.EstimatedTime.label",
      requiredMessage: "TenantForm.EstimatedTime.requiredMessage",
    },
    ActualStartDate:{
      name: "ActualStartDate",
      label: "projectForm.ActualStartDate.label",
      requiredMessage: "TenantForm.ActualStartDate.requiredMessage",
    },
    ActualEndDate:{
      name: "ActualEndDate",
      label: "projectForm.ActualEndDate.label",
      requiredMessage: "TenantForm.ActualEndDate.requiredMessage",
    },
    ActualTime:{
      name: "ActualTime",
      label: "projectForm.ActualTime.label",
      requiredMessage: "TenantForm.ActualTime.requiredMessage",
    },
    ActualBillableTime:{
      name: "ActualBillableTime",
      label: "projectForm.ActualBillableTime.label",
      requiredMessage: "taskForm.ActualBillableTime.requiredMessage",
    },
    ActualNonBillableTime:{
      name: "ActualNonBillableTime",
      label: "projectForm.ActualNonBillableTime.label",
      requiredMessage: "taskForm.ActualNonBillableTime.requiredMessage",
    },
  },
};

export default form;