import UTC2Local from "../../../../UTC2Local";

export const getTable = (modal) => {
  return {
    columns:
      modal === true
        ? [
            {
              id: "employee",
              label: "employeeTeam.list.column.description",
              align: "left",
              visible: true,
              renderCell: (row) => {
                return row?.description;
              },
            },
          ]
        : [
            {
              id: "employee",
              label: "employeeTeam.list.column.description",
              align: "left",
              visible: true,
              renderCell: (row) => {
                return row?.team?.description;
              },
            },
            {
              id: "createdBy",
              label: "employeeTeam.list.column.createdBy",
              align: "left",
              visible: true,
              renderCell: (row) => {
                return row?.createdBy?.email;
              },
            },
            {
              id: "creationDate",
              label: "employeeTeam.list.column.creationDate",
              align: "left",
              visible: true,
              renderCell: (row) => {
                return UTC2Local(row?.creationDate, true);
              },
            },
          ],
    defaultSort: "employee",
    defaultSortDirection: "asc",
  };
};
