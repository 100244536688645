import { createSlice } from "@reduxjs/toolkit";

const initialState = { form: false };

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    changeFormFlag: (state, { payload }) => {
      let { form } = state;
      form = { form: payload };
      return form;
    },
  },
});

export const { changeFormFlag } = formSlice.actions;

export const getFormFlagFromStore = (state) => state.form.form;
export default formSlice.reducer;
