import { createSlice } from "@reduxjs/toolkit";

const initialState = { notification: {} };

// const notificationSlice = createSlice({
//   name: "notification",
//   initialState,
//   reducers: {
//     changeNotification: (state, { payload }) => {

//       let { notification } = state;

//       notification = { notification: payload };
//       return notification;
//     },
//   },
// });
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    changeNotification: (state, { payload }) => {
      state.notification = { ...state.notification, ...payload };
    },
  },
});


export const { changeNotification } = notificationSlice.actions;

export const getNotificationFromStore = (state) => state.notification.notification;
export default notificationSlice.reducer;
