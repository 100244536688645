import checkout from "./form";

const {
  formField: {
    employee,
    pTO,
    description,
    date,
    numberOfDays,
    numberOfHours,
    entitlement
  },
} = checkout;

export default {
  [employee.name]:null,
  [pTO.name]:null,
  [description.name]:"",
  [date.name]:null,
  [numberOfDays.name]:0,
  [numberOfHours.name]:0,
  [entitlement.name]:"0",
};
