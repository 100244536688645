export const getDataTableData = (t) => {
  return {
    columns: [
      {
        id: "f7c800f7-008a-4000-814e-029b5a09c96e",
        field: "description",
        align: "left",
        headerName: t("areas.list.column.description"),
        label: "areas.list.column.description",
        flex: 1,
        editable: true,
      },
    ],
  };
};
