import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
//editors
import { Parse } from "../../../../JSON.editor";
import { useNavigate } from "react-router-dom";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress, Grid } from "@mui/material";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ConfirmationPopup from "../../../../components/Popup";
import "./style.css"
import { DELETE_RELEASE_NOTE, GET_ALL_RELEASE_NOTES_LIST } from "../../../../graphql/releaseNotes";
import ReleaseCard from "../../../../components/Cards/releaseCard";
import { removeNullProps } from "../../../../utils/removeNullProps";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";

const ReleaseNotesList = ({ refetchTable = false, fromTenant = false, showCards, fromUser }) => {
  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();
  const { isEntelligence, isAdmin } = userRoles;
  const [newsGuid, setNewsGuid] = useState("");
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { releaseNotes_list, releaseNotes_details, new_releaseNote } = urls;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  

  const [selectedReleaseIndex, setSelectedReleaseIndex] = useState(-1);
  const [clickedIndex, setClickedIndex] = useState(-1);

  const handleReleaseClick = (index) => {
    setSelectedReleaseIndex(index);
    setClickedIndex(index);
  };

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    setNewsGuid(null);
  }

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_RELEASE_NOTE,
    "DeletReleaseNotes"
  );


  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.ReleaseNotesList,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          const cols = parsed.columns;
          if (cols) {
            cols.map(col => {
              col = removeNullProps(col);
              if(col.id == 'Tenant' && !fromTenant) col.visible = false
            });
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );


  const {
    mfunction: GetAllReleaseNotesList,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ALL_RELEASE_NOTES_LIST, "GetAllReleaseNotesList");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      GetAllReleaseNotesList(
        {
          variables: {
            model: {

              enableFilters: true,
              keyword: search,

              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData]);

  useEffect(() => {
    if (!columnsWait) refetchData();
  }, [refetchTable]);



  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            releaseIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("releaseNotes.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);
        fetchTableData(0, rowsPerPage, order, orderBy, search, defaultFilters);
      }
    );
  };

  const rows =
    data &&
      data.GetAllReleaseNotesList &&
      data.GetAllReleaseNotesList.releaseNotesListResponse &&
      data.GetAllReleaseNotesList.releaseNotesListResponse.releaseNotes
      ? data.GetAllReleaseNotesList.releaseNotesListResponse.releaseNotes
      : [];

  const numberOfRecords =
    data &&
      data.GetAllReleaseNotesList &&
      data.GetAllReleaseNotesList.releaseNotesListResponse &&
      data.GetAllReleaseNotesList.releaseNotesListResponse.totalNumberOfRecords
      ? data.GetAllReleaseNotesList.releaseNotesListResponse.totalNumberOfRecords
      : 0;


  const customButton = [
    {
      label: "releaseNotes.addNew",
      handleClick: () => navigate(new_releaseNote),
      condition: !selected || selected.length === 0,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    removeFilters: true,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    hideSearchBar: showCards ? true : false,
    removeRefresh: showCards ? true : false,
    loading: loading || columnsWait,
    searchText: "release.list.search.label",
    refetch,
    handleDelete,
    customButtons: !showCards && customButton,
    deleteText: "release.list.delete.label",
    tableMnemonic: Mnemonic.Tables.ReleaseNotesList,
    generateRoute: (row) => isEntelligence ? releaseNotes_details.replace(
      "/:rName/:rID",
      `/${encodeURIComponent(row?.releaseID)}/${encodeURIComponent(
        row?.releaseGuid
      )}`) : false
  };

  return (
    <div>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />

      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : showCards === true ? (
        <Grid container className="timeline-and-info">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="timeline-container" style={{ overflowY: 'auto', maxHeight: 'calc(82vh - 64px)', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.5,
                },
              }}
            >
              {rows.map((r, index) => (
                <TimelineItem key={r.releaseGuid}>
                  <TimelineOppositeContent sx={{ fontSize: "14px !important" }} color="textSecondary">
                    {UTC2Local(r.releaseDate, true)}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    {index !== rows.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent
                    className={`timeline-content ${clickedIndex === index ? 'clicked' : ''}`}
                    sx={{
                      fontSize: "14px !important",
                      cursor: "pointer",
                      maxWidth: "100%",
                      wordWrap: "break-word",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                    }}
                    onClick={() => handleReleaseClick(index)}
                  >
                    {/* 21 characters max */}
                    {/* {`Version 1.0.${index + 1}`} */}
                    {r.version}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9} className="info-card-container">
            <ReleaseCard
              rows={rows}
              selectedReleaseIndex={selectedReleaseIndex}
              setSelectedReleaseIndex={setSelectedReleaseIndex}
            />
          </Grid>
        </Grid>

      ) : (<DataTable
        {...pagination}
        identifier={"releaseGuid"}
        hasCheckbox
      />)}
    </div>
  );
};

export default ReleaseNotesList;
