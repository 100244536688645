const form = {
  formID: "location",
  formField: {
    name: {
      name: "name",
      label: "locationForm.name.label",
      requiredMessage: "locationForm.name.requiredMessage",
    },
    address:{
      name:"address",
      label:"locationForm.address.label",
      requiredMessage:"locationForm.address.requiredMessage"
    },
    latitude:{
      name:"latitude",
      label:"locationForm.latitude.label",
      requiredMessage:"locationForm.latitude.requiredMessage"
    },
    longitude: {
      name:"longitude",
      label:"locationForm.longitude.label",
      requiredMessage:"locationForm.longitude.requiredMessage",
    },
    radius: {
      name:"radius",
      label:"locationForm.radius.label",
      requiredMessage:"locationForm.radius.requiredMessage",
    },
    isOnSite:{
      name:"isOnSite",
      label:"locationForm.isOnSite.label",
    },
    UnitOfMeasure:{
      name:"UnitOfMeasure",
      label:"locationForm.UnitOfMeasure.label",
      requiredMessage:"locationForm.UnitOfMeasure.requiredMessage",
    },
    country: {
      name:"Country",
      label:"locationForm.country.label",
      requiredMessage:"locationForm.country.requiredMessage",
    },

    
  },
};

export default form;