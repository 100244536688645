import urls from "./urls";

//layout
import DefaultLayout from "./layouts/DefaultLayout";

//authorization
import Authorization from "./authorization";


import AdminRoutes from "./private.admin.routes";
import UserRoutes from "./private.user.routes";

const routes = [

  ...AdminRoutes,
  ...UserRoutes,
];

export default [
  {
    type: "authentication",
    name: "user-auth",
    element: <Authorization type={["authorize", "otp"]} />,
    routes_inside: [
      {
        type: "nested",
        name: "Default Layout",
        path: urls.default,
        element: <DefaultLayout />,
        routes_inside: routes,
      },
    ],
  },
];
