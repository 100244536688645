import React, { useState } from "react";

import { useParams } from "react-router-dom";

//components
import { Grid, Box } from "@mui/material";

import "./style.css";
import PTOForm from "./PTOForm";
import AttendanceForm from "./AttendanceForm";

const Main = ({
  formData,
  newRequest = false,
  dateError,
  setDateError,
  setShowSideInfo,
  setSideInfoData,
  setRemaining,
  remaining,
  requested,
  handleSideInfoData,
  paid,
  attachmentName,
  setAttachmentName,
  attendanceRequest,
  disableFields,
  dayLog,
}) => {
  const { reqID } = useParams();

  const {
    values,
    formField,
    requestStatus,
    // setRequestStatus,
    refetch,
    refetchAttendance,
  } = formData;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          {/* {!newRequest && (
            <PTODetails
              values={values}
              formField={formField}
              requestStatus={requestStatus}
              reqID={reqID}
              setRequestStatus={(req) => setRequestStatus(req)}
              refetch={refetch}
            />
          )} */}
          {attendanceRequest ? (
            <AttendanceForm
              formData={formData}
              requestStatus={requestStatus}
              reqID={reqID}
              // setRequestStatus={(req) => setRequestStatus(req)}
              refetch={refetchAttendance}
              setDateError={setDateError}
              dateError={dateError}
              setShowSideInfo={setShowSideInfo}
              setSideInfoData={setSideInfoData}
              setRemaining={setRemaining}
              remaining={remaining}
              requested={requested}
              handleSideInfoData={handleSideInfoData}
              disableFields={disableFields}
              dayLog={dayLog}
            />
          ) : (
            <PTOForm
              formData={formData}
              requestStatus={requestStatus}
              reqID={reqID}
              // setRequestStatus={(req) => setRequestStatus(req)}
              refetch={refetch}
              setDateError={setDateError}
              dateError={dateError}
              setShowSideInfo={setShowSideInfo}
              setSideInfoData={setSideInfoData}
              setRemaining={setRemaining}
              remaining={remaining}
              requested={requested}
              handleSideInfoData={handleSideInfoData}
              paid={paid}
              attachmentName={attachmentName}
              setAttachmentName={setAttachmentName}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
