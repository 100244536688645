import { gql } from "@apollo/client";

export const GET_ALL_INVOICES_LIST=gql`
query ($model: ProjectInvoicesGetListRequestInput!) {
    GetProjectInvoicesList(model: $model) {
      projectInvoicesListResponse {
        projectInvoices {
          projectInvoiceGuid
          externalReference
          type
          postingDate
          documentNo
          quantity
          remainingQuantity
          task {
            title
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`


  export const UNDO_INVOICE=gql`
  
  mutation ($projectGuid:UUID!){
    UndoInvoicesAllocation(projectGuid: $projectGuid) {
      undoInvoicesAllocationResponse
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const APPLIED_INVOICES=gql`
  query ($model: AppliedInvoicesListGetRequestInput!) {
  GetAppliedInvoicesList(model: $model) {
    appliedInvoicesListResponse {
      totalNumberOfRecords
      appliedInvoices {
        quantity
        creditNote {
          documentNo
          externalReference
          postingDate
          projectInvoiceID
          quantity
          remainingQuantity
        
          type
        }
        projectInvoice {
          documentNo
          externalReference
          postingDate
          projectInvoiceID
          quantity
          remainingQuantity
          task {
            title
          }
          type
        }
        timeEntry {
          appliedQuantity
          duration
          employee {
            firstName
            lastName
          }
          startTime
        
        }
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
  `