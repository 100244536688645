import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

import useAuth from "../../../../hooks/useAuth";


function RequestList({fromTenant = false}) {
  const { tName, tID, } = useParams();

  const { isManager } = useAuth();

  const {
    my_requests,
    my_received_requests,
    tenant_request_list,
    tenant_requests_offline
  } = urls;

  const { t } = useTranslation();
    
  if (window.location.href.split("/")[5] == undefined)
  return <Navigate to={my_requests} replace />;

  const TabLinks = [
    {
      key: 1,
      Header: t("requestsTabs.sent"),
      Link: my_requests,
    },
    ...(isManager === true ? [{
      key: 2,
      Header: t("requestsTabs.received"),
      Link: my_received_requests,
    }] : [])
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default RequestList;
