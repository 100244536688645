import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const ProjectStatus = (props) => {
 
  const { employeeStatusColors } = colorsLight;

  if (!props) return null;
  return (
    <div
      style={{
        padding: "2px 5px",
        borderRadius: "5px",
        fontFamily: "poppins  !important",
        margin: "auto",
        width:"70px",
        ...employeeStatusColors[props?.mnemonic],
      }}
    >
      {props?.description}
    </div>
  );
 
};

export default ProjectStatus;
