import React from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, Typography, Grid, styled } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";
const WordWrapContainer = styled(Grid)({
  overflowWrap: "break-word",
  wordWrap: "break-word",
  hyphens: "auto",
  wordBreak: "break-all",
});
const SideInfo = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box lineHeight={0}>
        <Title>{t("employeeForm.sideInfo")}</Title>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {data.map((info) => (
            <Grid item xs={12} key={info[0]}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography variant="caption">{t(info[0] || "")}</Typography>
                </Grid>
                <WordWrapContainer
                  item
                  xs={7}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="caption" fontWeight="bold" width={"100%"}>
                    {info[1] || ""}
                  </Typography>
                </WordWrapContainer>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SideInfo;
