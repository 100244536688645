import React from "react";

//components
import { Typography } from "@mui/material";

//assets
import activ from "../../assets/images/white_verti_logo.png";

//colors
import colorsLight from "../../assets/theme/light/colors";

import { useTranslation } from "react-i18next";

const AuthCard = ({ children }) => {
  const { pure_white } = colorsLight;
  const { t } = useTranslation();

  return (
    <div className="auth-card">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={activ}
          alt="IXIA-ACTIV-LOGO"
          style={{ width: "150px", height: "150px", marginBottom: "30px" }}
        />
        <Typography
          component="h6"
          variant="h6"
          className="app-name"
          sx={{ color: `${pure_white}`, marginTop: "2rem" }}
        >
          {t("welcome.msg")}
        </Typography>
      </div>

      <div>{children}</div>
    </div>
  );
};

export default AuthCard;
