import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const SuggestionStatus = (props) => {
  const { suggestionStatus } = props;
  const { suggestionStatusColors } = colorsLight;

  if (!suggestionStatus) return null;
  return (
    <span
    style={{
      padding: "5px 10px",
      borderRadius: "5px",
      fontFamily: "poppins",
      margin: "auto",
      fontSize: "13px",
      width: "90px", 
      textAlign: "center", 
      display: "inline-block", 
      ...suggestionStatusColors[suggestionStatus?.mnemonic],
    }}
  >
    {suggestionStatus?.description}
  </span>
  );
 
};

export default SuggestionStatus;
