import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function KioskTabs({  myKioskDevices = false }) {
  const { kName, kID, tName, tID } = useParams();

  const { tenant_kiosk_form, kiosk_devices_form } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !kName ||
    !kID ||
    kName === "undefined" ||
    kName === "null" ||
    kName === "" ||
    kID === "null" ||
    kID === "undefined" ||
    kID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(kName);
  const encodedID = encodeURIComponent(kID);



  const TabLinks = [
    {
      key: 1,
      Header: t("kioskDevices.form"),
      Link: myKioskDevices
        ? kiosk_devices_form.replace("/:kName/:kID", `/${encodedName}/${encodedID}`)
        : tenant_kiosk_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:kName/:kID", `/${encodedName}/${encodedID}`),
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default KioskTabs;
