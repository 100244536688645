import React, { useState, useEffect } from "react";

//components
import { Avatar, Grid, Box, IconButton, Dialog } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";

//translation
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import "./styles.css";
import AutoComplete from "../../../../../components/AutoComplete";
import { GET_SUGGESTIONS_STATUSES, GET_SUGGESTION_BY_ID, UNVOTE_FOR_SUGGESTION, VOTE_FOR_SUGGESTION } from "../../../../../graphql/suggestions";
import useQueryAuth from "../../../../../hooks/useQueryAuth"
import useAuth from "../../../../../hooks/useAuth";
import colors from "../../../../../assets/theme/light/colors";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import UTC2Local from "../../../../../UTC2Local";
import VotersList from "../../voters-list";
import QuickInfoCard from "../../../../../components/Cards/QuickInfoCard";
import { FilledLikeIcon, OutlinedLikeIcon, VoterIcon } from "../../../../../components/Icon";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData, getByID, suggGuid,getDataByIDLoading }) => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,

    setWhatField,
  } = formData;

  const { suggestionStatus, description } = formField;
  const { enqueueSnackbar } = useSnackbar();
  const { suggestionStatus: suggestionStatusV, description: descriptionV } = values;
  const { suggestionStatusColors, dark_logo_blue } = colors;
  const { sID } = useParams()
  const { userRoles, storeUserGuid, storeUserTenant } = useAuth();
  const { isEntelligence } = userRoles;
  const [voted, setVoted] = useState(getByID?.thisUserUpvoted);

  const UserTenant = getByID?.suggestion?.createdBy?.tenantGuid;

  const [totalVotes, setTotalVotes] = useState(getByID?.totalVotes || 0);




  useEffect(() => {
    setTotalVotes(getByID?.totalVotes || 0);
  }, [getByID]);

  const {
    data: statusesData,
    loading: suggestionStatusLoading,
  } = useQueryAuth(GET_SUGGESTIONS_STATUSES, "GetAllSuggestionStatuses", { skip: createMode, isWait: true });

  const statuses_list = statusesData?.GetAllSuggestionStatuses?.suggestionStatuses || [];

  const statusPicked = () => {
    let circleSize = "12px";
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        <Grid
          style={{
            backgroundColor: suggestionStatusV?.mnemonic
              ? suggestionStatusColors[suggestionStatusV?.mnemonic]?.color
              : "transparent",
            borderRadius: "50%",
            width: circleSize,
            height: circleSize,
            marginRight: "5px",
          }}
        >
          {" "}
        </Grid>



        <AutoComplete
          options={statuses_list}
          getOptionLabel={(option) => (option ? option.description : "")}
          renderOption={(props, option) => (
            <li {...props} key={uuidv4()}>
              {option.description}
            </li>
          )}
          identifier={"suggestionStatusGuid"}
          value={suggestionStatusV}
          onChange={(e, value) => {
            setFieldValue(suggestionStatus.name, value);
          }}
          loading={suggestionStatusLoading}
          label={t(suggestionStatus.label)}
          name={suggestionStatus.name}
          formData={formData}
          removeLabel
          removePadding
          variant="outlined"
          required
        />

      </Grid>
    );
  };

  const { mfunction: voteFunction, loading: voteLoading } = useMutationAuth(
    VOTE_FOR_SUGGESTION,
    "UpvoteSuggestion"
  );

  const handleVote = () => {
    voteFunction(
      {
        variables: {
          model: {
            employeeGuid: storeUserGuid,
            suggestionGuid: sID || suggGuid
          },
        },
      },
      (response) => {
        if (response["UpvoteSuggestion"].upvoteSuggestionResponse === true) {
          enqueueSnackbar(t("suggs.list.vote.success"), {
            variant: "success",
          });
          setTotalVotes(prevVotes => prevVotes + 1);
          setVoted(true);

        } else {
        }
      }
    );
  };

  const { mfunction: unvoteFunction, loading: unvoteLoading } = useMutationAuth(
    UNVOTE_FOR_SUGGESTION,
    "UndoUpvoteSuggestion"
  );

  const handleUnvote = () => {
    unvoteFunction(
      {
        variables: {
          model: {
            employeeGuid: storeUserGuid,
            suggestionGuid: sID || suggGuid
          },
        },
      },
      (response) => {
        if (response["UndoUpvoteSuggestion"].undoUpvoteSuggestionResponse === true) {
          enqueueSnackbar(t("suggs.list.unvote.success"), {
            variant: "success",
          });
          setTotalVotes(prevVotes => prevVotes - 1);
          setVoted(false);
        } else {
        }
      }
    );
  };
  const vote = () => {
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "column",
        }}
      >
        <IconButton onClick={voted ? handleUnvote : handleVote}>
          {voted ? <FilledLikeIcon color="#1082e7" />  : <OutlinedLikeIcon color="#1082e7" /> }</IconButton>
        <p style={{ fontSize: "12px" }}>{totalVotes}</p>
      </Grid>
    )
  }
  return (
    <Box>

      <Grid container spacing={1} sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          sx={{
            maxWidth: "400px !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
          className="flex_space-between_center"
        >
          <Title style={{ fontSize: "13px !important", lineHeight: "2rem" }}>
            {t("sugg.form.content")}
          </Title>


        </Grid>
        {isEntelligence && !createMode && statusPicked()}
        {!isEntelligence && !createMode && (getByID?.suggestion?.suggestionStatus?.mnemonic === "suggested") && vote()}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mt={3}
              mb={3}
              className="suggestion-textArea"
            >
              <TextArea
                autoFocus
                variant="standard"
                fullWidth
                placeHolder={t("sugg.desc")}
                multiline
                readOnly={!isEntelligence && !createMode}
                // label={t(description.label)}
                value={descriptionV}
                required
                onBlur={(e) =>
                  setTouched({ ...touched, [description.name]: true })
                }
                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                error={touched[description.name] && errors[description.name]}
                helperText={
                  touched[description.name] &&
                  errors[description.name] &&
                  t(errors[description.name])
                }
              />
            </Grid>

          </Grid>
          {!createMode && (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mt={1}
              sx={{ fontSize: "13px !important", alignItems: "center" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {(storeUserTenant == UserTenant) || isEntelligence ? (
                  <Avatar
                    alt="User Avatar"
                    src={getByID?.suggestion?.createdBy?.profilePictureURL}
                    sx={{ width: 35, height: 35, marginRight: 1 }}
                  />
                ) : (
                  <Avatar
                    alt="User Avatar"
                    sx={{ width: 35, height: 35, marginRight: 1 }}
                  />
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {(storeUserTenant == UserTenant) || isEntelligence ? (
                    <p>{getByID?.suggestion?.createdBy?.firstName + " " + getByID?.suggestion?.createdBy?.lastName}</p>
                  ) : (
                    <p>{t("sugg.creator.anonymous")}</p>
                  )}
                  <p>{UTC2Local(getByID?.suggestion?.creationDate)}</p>
                  {isEntelligence && <p>{getByID?.suggestion?.tenant?.name}</p>}
                </div>
              </div>
              {/* New div for the total votes */}
              {isEntelligence && <div className="voters" style={{ marginLeft: "auto" }}>
                {/* <p onClick={handleClick} style={{ cursor: 'pointer' }}>{t("sugg.nbofVoters")}: {getByID?.totalVotes}</p> */}
                <QuickInfoCard
                  icon={
                    <VoterIcon color="var(--color--light-logo-blue)" />
                  }
                  icon2={
                    <VoterIcon color="var(--color--light-logo-blue)" />
                  }
                  label={t("sugg.showVoters")}
                  title={t("sugg.showVoters.title")}
                  amount={getByID?.totalVotes}
                  color={dark_logo_blue}
                  loading={getDataByIDLoading}
                  table={<VotersList getByID={getByID} getDataByIDLoading={getDataByIDLoading}/>}
                  
                />
              </div>}
            </Grid>
          )}






          {/* <Dialog open={modalOpen} onClose={handleCloseModal}>
            <VotersList />      </Dialog> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
