import React from "react";
import Tooltip from "../Tooltip";
import { useTranslation } from "react-i18next";
import { AlertIcon } from "../Icon";

const TimeStatus = (props) => {

  const {t} = useTranslation();
  

  const behindScheduleLate = props?.timeStatus?.behindSchedule || props?.row?.task?.behindSchedule  ;
 const overPercentage = props?.timeStatus?.percentageComplete || props?.row?.task?.percentageComplete;
  
  
 return (
  <div style={{ paddingRight: "4px"}}>
    {overPercentage > "100" ? (
        <AlertIcon color="#ff2825" title={t("tooltip.performance")} />
    ) : behindScheduleLate == true ? (
        <AlertIcon color="#ffbf00"  title={t("tooltip.performance")} />
    ) : null}
  </div>
);

};

export default TimeStatus;
