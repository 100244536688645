import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    title,
    status,
    project,
    ticket,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
    ActualBillableTime,
    ActualNonBillableTime,
    externalReference,
    synced,
    area,
    priority,
    task,
    allowCreateTimeEntry,
    billable,
  },
} = checkout;

export const getValidation = (taskRequired) => {
  return [
  Yup.object().shape({
    [title.name]: Yup.string().required(title.requiredMessage),
    [priority.name]: Yup.string().required(priority.requiredMessage),
    [status.name]: Yup.object().required(status.requiredMessage),
    [EstimatedEndDate.name]: Yup.date().nullable(),
    [EstimatedStartDate.name]: Yup.date().nullable(),
    [externalReference.name]: Yup.string().nullable(),
    [EstimatedTime.name]: Yup.string().nullable(),
    [ActualEndDate.name]: Yup.date().nullable(),
    [ActualStartDate.name]: Yup.date().nullable(),
    [ActualTime.name]: Yup.string().nullable(),
    [ActualBillableTime.name]: Yup.string().nullable(),
    [ActualNonBillableTime.name]: Yup.string().nullable(),
    [synced.name]: Yup.bool().nullable(),
    [area.name]: Yup.object().nullable(),  
    [allowCreateTimeEntry.name]: Yup.bool().required(allowCreateTimeEntry.requiredMessage),  
    [billable.name]: Yup.bool().required(billable.requiredMessage),  
    [ticket.name]: taskRequired ? Yup.object().nullable() : Yup.object().required(ticket.requiredMessage),
    [project.name]: taskRequired ? Yup.object().nullable() : Yup.object().required(project.requiredMessage),  
    [task.name]: taskRequired ? Yup.object().required(task.requiredMessage) : Yup.object().nullable(),
  })
];
}