import checkout from "./form";

const {
  formField: {
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    employmentType,
    workShift,
    directManager,
    workType,
    isOffice365User,
    isAppUser,
    isAutoClockIn,
    preferEmail,
    preferSMS,
    department,
    defaultWorkCompany,
    tenant,
    homeLocation,
    employmentDate,
    position,
    isManager,
    profilePicture,
    employeeStatuses,
    probation,
    officialHoliday,
    resignationDate,
    inactiveDate,
    attendance,
    timeEntry,
    timeZone,
    resourceType,
    contractor,
    canCreateKiosk
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [phoneNumber.name]: "",
  [role.name]: null,
  [directManager.name]: null,
  [employmentType.name]: null,
  [workType.name]: null,
  [workShift.name]: null,
  [isOffice365User.name]: false,
  [isAppUser.name]: false,
  [isAutoClockIn.name]: false,
  [preferEmail.name]: false,
  [preferSMS.name]: false,
  [canCreateKiosk.name]:false,
  [defaultWorkCompany.name]: null,
  [department.name]: null,
  [tenant.name]: null,
  [homeLocation.name]: null,
  [employmentDate.name]: null,
  [position.name]: "",
  [isManager.name]: false,
  [profilePicture.name]: "",
  [employeeStatuses.name]: null,
  [probation.name]: null,
  [officialHoliday.name]: null,
  [resignationDate.name]: null,
  [inactiveDate.name]: null,
  [attendance.name]: false,
  [timeEntry.name]: false,
  [timeZone.name]: null,
  [resourceType.name]: "",
  [contractor.name]: null,
};

export default initialValues;