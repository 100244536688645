import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import No_Image_Found from "../../../assets/images/no-user-image.png";

const data = (t) => {
  return {
  title: <></>,

  headers: [
    t("dashboard.card.employee"),
    { align: "center", label: t("dashboard.card.dailyTotalHours") },
    { label: "", width: "50px" },
  ],
  render: [
    (row) => {
      return (
        <div className="data-card-employee-name-image">
          <span>
            {(row?.employee?.profilePictureURL && (
              <img
                src={row.employee.profilePictureURL}
                alt="Not Found"
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src=No_Image_Found;
                }}
              />
            )) || <AccountCircleIcon />}
          </span>
          <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>
        </div>
      );
    },
    (row) => {
      return <p style={{ textAlign: "center" }}>{row?.dailyTotal}</p>;
    },
    (row) => {
      return <div style={{ width: "20px", height: "15px" }}>...</div>;
    },
  ],
}
};

export default data;
