import { gql } from "@apollo/client";

export const GET_ALL_HELP = gql`
  query {
    GetAllHelps {
      helpsList {
        helps {
          description
          helpGuid
          helpID
          title
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_HELP_BY_ID = gql`
  query ($id: UUID!) {
    GetHelpByID(helpGuid: $id) {
      help {
        description
        helpGuid
        helpID
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_HELP = gql`
  mutation ($model: HelpEditRequestInput!) {
    EditHelp(model: $model) {
      help {
        description
        helpGuid
        helpID
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
