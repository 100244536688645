import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Skeleton } from "@mui/material";

import "./card.css";

const DataCard = ({
  id,
  className,
  title,
  tableHeaders,
  initialLoad,
  render,
  data,
  onLazyLoad,
  stopLazyLoad,
  loading,
  setLoading,
  clickable,
  additionalFunctionality,
  giveMax,
  removeBorders,
  maxHeight,
  myTask
}) => {
  const { t } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(0);

  const colSpan = tableHeaders.length; //for skeleton to see how many columns are in the card
  //so we span it fully on getting the data

  useEffect(() => {
    document.getElementById(id)?.focus();
  }, [id])

  const handleDivScroll = (event) => {
    //function that handles the scroll of the div
    var element = document.getElementById(`table-container-${title}`);

    var distanceFromTopToBottom = element.scrollHeight - element.clientHeight;

    // Check if the user has scrolled to the bottom or near the bottom (within 1 pixel tolerance)
    if (
      Math.abs(element.scrollTop - distanceFromTopToBottom) <= 1 &&
      !stopLazyLoad
    ) {
      setLoading(true);
      onLazyLoad();
    }
  };

  function handleHover() {
    // Save the current scroll position when hovering
    setScrollPosition(document.querySelector('.data-card-table-container').scrollTop);
  }

  function handleLeave() {
    // Set the scroll position when leaving the hover
    setTimeout(() => {
      document.querySelector('.data-card-table-container').scrollTop = scrollPosition;
    }, 0);
  }
  
  return (
    <div
      className={`${!removeBorders ? "data-card-container" : ""}`}
      style={{ maxHeight: giveMax ? "350px" : "", paddingBottom: "10px" }}
    >
      {!removeBorders && (
        <div className="data-card-title">
          <h4 style={{ fontSize: "15px" }}>{title}</h4>
        </div>
      )}
      <div
        className="data-card-table-container"
        // onScroll={handleDivScroll}
        // onMouseLeave={handleLeave}
        // onMouseEnter={handleHover}
        id={`table-container-${title}`}
        style={maxHeight ? {maxHeight: maxHeight} : {}}
      >
        <table className={`data-card-table ${className}`}>
          {!removeBorders && (
            <thead>
              <tr>
                {tableHeaders.map((head, index) => (
                  <th key={index} style={{width:typeof head === "object"?head.width || "" : ""}}>
                    <h5
                      className="data-card-table-head"
                      style={{
                        textAlign: typeof head === "object" ? head.align || "" : "",
                        fontSize: "14px",
                        paddingLeft:  "20px",
                        paddingRight: "0px",
                      }}
                    >
                      {typeof head === "object" ? t(head.label) : t(head)}
                    </h5>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className={myTask ? "tasksBody" : "tables-modal"} id={id} tabIndex={1}>
            {!initialLoad && (
              <>
                <tr className={myTask ? "tasksRow" : "data-card-row"}>
                  <td colSpan={colSpan}>
                    <Skeleton variant="rectangular" />
                  </td>
                </tr>
                <tr className={myTask ? "tasksRow" : "data-card-row"}>
                  <td colSpan={colSpan}>
                    <Skeleton variant="rectangular" />
                  </td>
                </tr>
                <tr className={myTask ? "tasksRow" : "data-card-row"}>
                  <td colSpan={colSpan}>
                    <Skeleton variant="rectangular" />
                  </td>
                </tr>
                <tr className={myTask ? "tasksRow" : "data-card-row"}>
                  <td colSpan={colSpan}>
                    <Skeleton variant="rectangular" />
                  </td>
                </tr>
              </>
            )}
            {initialLoad &&
              data &&
              data.map((val, index) => {
                return (
                  <tr
                    className={myTask ? `data-card-task-row ${
                      clickable && "table-arrow-icon"
                    }` : `data-card-row ${
                      clickable && "table-arrow-icon"
                    }`}
                    onClick={() => {
                      if (clickable) additionalFunctionality(val);
                    }}
                    key={index}
                  >
                    {render.map((ren, index2) => {
                      return (
                        <td
                          key={index + "-" + index2}
                          style={{
                            paddingLeft:  "20px",
                            paddingRight:"0px",
                          }}
                        >
                          {ren(val, t)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {initialLoad && data && data.length === 0 && (
              <tr className="data-card-row" style={{ height: "56px" }}>
                <td colSpan={7} className="no-records">
                  <p>{t("dataCrd.noDataToDisplay")}</p>
                </td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={7}>
                  <div className="loading-dots">
                    <div className="dot-flashing"></div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

DataCard.propTypes = {
  title: PropTypes.string,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
  initialLoad: PropTypes.bool,
  render: PropTypes.array,
  data: PropTypes.array,
  onLazyLoad: PropTypes.func,
  stopLazyLoad: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  additionalFunctionality: PropTypes.func,
  clickable: PropTypes.bool,
  giveMax: PropTypes.bool,
  requests: PropTypes.bool,
};

DataCard.defaultProps = {
  title: "",
  tableHeaders: [],
  initialLoad: false,
  render: [],
  data: [],
  onLazyLoad: () => {},
  stopLazyLoad: false,
  loading: false,
  setLoading: () => {},
  additionalFunctionality: () => {},
  clickable: false,
  giveMax: false,
  requests: false,
};

export default DataCard;
