import { gql } from "@apollo/client";

export const GET_ATTENDANCE_SHEET_LIST = gql`
query ($model: AttendanceSheetGetListRequestInput!) {
    GetAttendanceSheetList(model: $model) {
      attendanceSheetsList {
        attendanceSheets {
          attendanceSheetGuid
          status
          workShift {
            workShiftGuid
            description
          }
          endDate
          startDate
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ATTENDANCE_SHEET_DAY_LOGS = gql`
query($model: AttendanceSheetByIDRequestInput!) {
  GetAttendanceSheetDayLogs(model: $model) {
    attendanceSheetView {
      dayLogs{
         dayLogGuid
         kioskDevicetGuid
         description
         employee {
          firstName
          lastName
         } 
         createdBy {
          firstName
          lastName
         } 
         modifiedBy {
          firstName
          lastName
         } 
          workType {
            workTypeGuid
            description
            mnemonic
          }
          location {
            locationGuid
            name
          }
          checkInTime
          checkOutTime
          isOfficialHoliday
          isLate
          isEarly
          webClockin
          workTimeHour
          workTimeMinute
          isAutomaticRecord
          automaticallyClockedOut
          createdBy {
            email
          }
          creationDate
          modifiedBy {
            email
          }
          modificationDate
          attendanceRequest{
            approvedBy{
              email
            }
            approvalDate
          }
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
} 
`;

export const EDIT_ATTENDANCE_SHEET=gql`
  mutation($model:AttendanceSheetEditRequestInput!){
    EditAttendanceSheet(model:$model){
      attendanceSheet{
        attendanceSheetGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_ATTENDANCE_SHEET=gql`
  mutation($attendanceSheetGuid:UUID!){
    DeleteAttendanceSheet(attendanceSheetGuid:$attendanceSheetGuid){
      attendanceSheetDeleteResponse
      errorCode
      errorMessage
      errorVariables
    }
  }`


export const GET_EMPLOYEE_ATTENDANCE= gql`
query($model: EmployeesAttendanceRequestInput!){
  GetEmployeesAttendance(model: $model) {
    employeesAttendance {
      employees {
        employeeId
        name
        totalOfHours
        attendance {
          date
          totalOfHours
          isLate
          isEarly
          isOfficialHoliday
          isAuto
        }
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_SINGLE_EMPLOYEE_ATTENDANCE = gql`
query($model: EmployeesAttendanceRequestInput!) {
  GetSingleEmployeeDayLogs(model: $model) {
    singleEmployeesAttendance {
       dayLogWithIcons {
        title
        start
        icons
       }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;