import React, {useState, useEffect, useMemo} from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import DataCard from "../../../components/Cards/DataCard";

//data
import getTableData from "./_missed-clock-in";

import useQueryAuth from "../../../hooks/useQueryAuth";

import useAuth from "../../../hooks/useAuth";

import {GET_MISSED_CLOCKINS_FOR_DAY} from "../../../graphql/dayLogs"
import { useTranslation } from "react-i18next";

const MissedClockIn = ({ tenant = null, date }) => {
  const [loading, setLoading] = useState(false);  //set true when we wish to start lazy loading
const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const { t } = useTranslation();

  const {isEntelligence} = userRoles;

  const [_missedClockIns, _setMissedClockIns] = useState([]);
  const [_missedClockInsNumb, _setMissedClockInsNumb] = useState(0);

  const {
    loading: missingClockInsLoad,
  } = useQueryAuth(
    GET_MISSED_CLOCKINS_FOR_DAY,
    "GetMissedClockIns",
    {
      variables: {
        model: {
          tenantID: isEntelligence
            ? tenant
              ? tenant.tenantGuid
              : null
            : storeUserTenant,
          date: new Date(date).toISOString(),
          userID: storeUserGuid,
        },
      },
      
    },
    {
      onCompleted: (data) => {
        var array = data?.GetMissedClockIns?.missedClockIns?.records || [];

        _setMissedClockIns((records) => [...records, ...array]);
        _setMissedClockInsNumb(
          data?.GetMissedClockIns?.missedClockIns?.totalNumberOfRecords || 0
        );
      },
    }
  );



  const _records =  useMemo(() =>_missedClockIns || [], [_missedClockIns]); //holds data
  const _maxRecords = _missedClockInsNumb || 0;  //holds total number of records in database that satisfy the query

  useEffect(()=>{

    if(_records && _records.length > 0)
      setLoading(false)

  },[_records])

  function fetchData() {
    //function that gets records on lazy load

    if(missingClockInsLoad) return; //data is already being fetched, we wait for it to at least be fetched

    if(_records.length >= _maxRecords) return;  //if data has reached its max

  } 

  const _tableData = getTableData(t);

  const { title, headers, render } = _tableData;  //holds card title, table headers and the display of data

  const stopLazyLoad = _records.length >= _maxRecords;  //if all records have been fetched we no longer wish to lazy load


  return (
    <DataCard
      id="dashboard-missed-modal"
      title={title}
      stopLazyLoad={stopLazyLoad}
      onLazyLoad={fetchData}
      tableHeaders={headers}
      render={render}
      data={_records}
      initialLoad={!(missingClockInsLoad && _missedClockInsNumb === 0)}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

MissedClockIn.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch:PropTypes.bool
};

MissedClockIn.defaultProps = {
  initialLoad: false,
  data: {},
  next: ()=>{},
  waitBatch:false
};

export default MissedClockIn;
