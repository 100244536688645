const form = {
    formID: "EmployeeReference",
    formField: {
      company: {
        name: "company",
        label: "employeeReference.company.label",
        requiredMessage: "employeeReference.company.requiredMessage",
      },
      overtimeExternalResource: {
        name: "overtimeExternalResource",
        label: "employeeReference.overtimeReference.label",
        requiredMessage: "employeeReference.overtimeReference.requiredMessage",
      },
      regularPayExternalResource: {
        name: "regularPayExternalResource",
        label: "employeeReference.regularPayReference.label",
        requiredMessage: "employeeReference.regularPayReference.requiredMessage",
      },
      weekendOvertimeExternalResource: {
        name: "weekendOvertimeExternalResource",
        label: "employeeReference.weekendOvertimeExternalResource.label",
        requiredMessage: "employeeReference.weekendOvertimeExternalResource.requiredMessage",
      },
    },
  };
  
export default form;