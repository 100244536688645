import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


import UTC2Local from "../../../../UTC2Local";


import "./style.css";

export function EmployeeAttendanceSheetTable({
  t,
  data: employees,
  dateSummaries,
  handleOpenDialog
}) {
  
  const findAttendanceForDate = (date, attendance) => {
    const matchingDate = attendance.find(
      (detail) => UTC2Local(detail.date, true) === UTC2Local(date, true)
    );
    
    return matchingDate ? matchingDate.totalOfHours.toFixed(2) : "";
  };

  return (
    <TableContainer component={Paper} className={"table-timesheet-height"}>
      <Table
        id="employee-timesheet-table"
        sx={{ tableLayout: "fixed", width: "100%" }}
        stickyHeader
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: "auto", width: "500px", top: 0, left: 0, position: "sticky", backgroundColor: "#fff", zIndex:"2" }}>
              {t("employeeAttendanceSheet.employee")}
            </TableCell>
            {dateSummaries &&
              dateSummaries.map((date, index) => (
                <TableCell
                  key={index}
                  align="right"
                  sx={{
                    // paddingLeft: 0,
                    // paddingRight: "5px",
                    // width: "70px",
                    paddingLeft: 0,
                    paddingRight: "5px",
                    width: "70px",
                    top: 0, // Ensure the top property is set to 0
                    left: 0,
                    position: "sticky",
                    backgroundColor: "#fff",
                    zIndex: "1", // Adjusted zIndex value
                  }}
                >
                  {UTC2Local(date, true, false)}
                </TableCell>
              ))}
            <TableCell
              align="right"
              sx={{
                paddingLeft: 0,
                paddingRight: "5px",
                width: "70px",
                top: 0, // Ensure the top property is set to 0
                left: 0,
                position: "sticky",
                backgroundColor: "#fff",
                zIndex: "2", // Adjusted zIndex value
              }}
            >
              {t("employeeAttendanceSheet.total")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees &&
            employees.map((row, index) => {
              const { attendance } = row;
              const dateDetailsCells = [];

              dateSummaries.forEach((dateSummary) => {
                const taskDuration = findAttendanceForDate(
                  dateSummary,
                  attendance
                );
                
                dateDetailsCells.push(
                  <TableCell
                    key={dateSummary}
                    align="right"
                    sx={{ paddingLeft: 0, paddingRight: "5px" }}
                  >
                    {taskDuration}
                  </TableCell>
                );
              });

              dateDetailsCells.push(
                <TableCell
                  key="total"
                  align="right"
                  sx={{
                    fontWeight: "bold",
                    paddingLeft: 0,
                    paddingRight: "5px",
                  }}
                >
                  {row?.totalOfHours?.toFixed(2)}
                </TableCell>
              );
              return (
                <TableRow
                  key={row?.taskGuid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="left"
                    className="link-cell"
                    sx={{ paddingRight: 0, left: 0, position: "sticky", backgroundColor: "#fff", zIndex: "1" }}
                    onClick={() => handleOpenDialog(row?.employeeId)}
                  >
                    {row?.name}
                  </TableCell>
                  {dateDetailsCells}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
