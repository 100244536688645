import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid } from "@mui/material";

//components
import IALoadingButton from "../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useMutationAuth from "../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../hooks/useAuth";

//graphql
import { CHANGE_PASSWORD } from "../../../graphql/user";
import Help from "./components/_help";

//urls
import urls from "../../../urls";

import ContainerCard from "../../../components/Cards/ContainerCard";


const PasswordForm = () => {
  const { profileName, profileID } = useParams();

  const { storeUserGuid, userRoles } = useAuth();
  const [whatField, setWhatField] = useState(null);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const { isEntelligence } = userRoles;

  const { my_profile, admin_profile } =
    urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;


  const {
    newPassword,
    confirmPassword,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();
 
  const { mfunction: resetPassword } = useMutationAuth(
    CHANGE_PASSWORD,
    "ChangePassword"
  );

  const handleResetPassword = (values, actions) => {
  
    if(values.newPassword !== values.confirmPassword){
      enqueueSnackbar(t("passwordsDontMatch"), {
        variant: "error",
        autoHideDuration: 5000
      });
      actions.setSubmitting(false);
      return;
    }

    resetPassword(
      {
        variables: {
          model: {
            employeeGuid: storeUserGuid,
            password: values[newPassword.name],
            confirmPassword: values[confirmPassword.name],
          },
        },
      },
      (response) => {
        if (
          response["ChangePassword"].errorCode !== null &&
          response["ChangePassword"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["ChangePassword"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("password.changedSuccessfully"), {
            variant: "success",
          });
          navigate(isEntelligence ? admin_profile.replace(
            "/:profileName/:profileID",
            `/${encodeURIComponent(profileName)}/${encodeURIComponent(storeUserGuid)}`
          ) : my_profile
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`));
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  };

  const handleSubmit = (values, actions) => {
    handleResetPassword(values, actions);
  };

  return (
    <>
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-edit`}
                  initialValues={initialValues}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form id={form.formID} autoComplete="off">
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              setWhatField,
                            }}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="submit"
                            label={t("employeeForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
            {whatField && (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ContainerCard>
                  <Box p={2}>
                    <Help data={whatField} title={"roleForm.whatField"} />
                  </Box>
                </ContainerCard>
              </Grid>
            )}
          </Grid>
        </Box>
    </>
  );
};

export default PasswordForm;
