import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import { Grid, CircularProgress } from "@mui/material";
import ContainerCard from "../../../../../components/Cards/ContainerCard";
import AutoComplete from "../../../../../components/AutoComplete";
import FileInput from "../../../../../utils/importExcelFile";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../../graphql/employee";
import { CREATE_TIME_ENTRIES_FROM_EXCEL } from "../../../../../graphql/timeEntry";
import { useSnackbar } from "notistack";
import { getTable } from "./_table";
import colors from "../../../../../assets/theme/light/colors";
import DataTable from "../../../../../components/Tables/DataTable";
import FieldModal from "../../../../../components/Modal/FieldModal";
import PaperComponent from "../../../../../components/Modal/PaperComponent";
import CustomButton from "../../../../../components/CustomButton";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import {
  DeleteIcon,
  EyeIcon,
  HelpIcon,
  PDFIcon,
  UploadIcon,
  WarningIcon,
} from "../../../../../components/Icon";
import ReactFileReader from "react-file-reader";
import { base64StringtoFile } from "../../../../../utils/base64StringtoFile";
import * as XLSX from "xlsx";

import { v4 as uuidv4 } from "uuid";

function ImportExcelTimeSheet() {
  const { storeUserTenant, userRoles, user, storeUserGuid, timeEntryEnabled } =
    useAuth();
  const { dark_logo_blue } = colors;
  const { t } = useTranslation();
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];
  const { enqueueSnackbar } = useSnackbar();
  const { manageTimeEntry } = userRoles;

  const [openDialog, setOpenDialog] = useState(false);
  const [includeSubtasks, setIncludeSubtasks] = useState(false);
  const [columns, setColumns] = useState([]);
  const [employeeValue, setEmployeeValue] = useState(
    user && timeEntryEnabled ? user.user : null
  );
  const [projectsValue, setProjectsValue] = useState([]);
  const [employeeID, setEmployeeID] = useState();
  const [data, setData] = useState([]);
  const [numOfRecords, setNumOfRecords] = useState(0);

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();
      if (col) {
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const { data: employeesData, loading: employeeLoading } = useQueryAuth(
    GET_ALL_EMPLOYEES_DROPDOWN,
    "GetCompanyUsersList",
    {
      variables: {
        model: {
          enableFilters: false,
          userID: storeUserGuid,
          tenantID: storeUserTenant,
          showAll: true,
          attendanceDisabled: true,
        },
      },
    }
  );
  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];
  const selectedEmployee = employeesList.find(
    (employee) => employee.employeeGuid === employeeID
  );

  const { cfunction: createTimeEntryData } = useMutationAuth(
    CREATE_TIME_ENTRIES_FROM_EXCEL,
    "CreateTimeEntriesFromExcelByManager"
  );

  function CreateTimeEntryData(values) {
    createTimeEntryData(
      {
        variables: {
          model: {
            timeEntries: values,
          },
        },
      },
      (response) => {
        if (
          response["CreateTimeEntriesFromExcelByManager"].errorCode !== null &&
          response["CreateTimeEntriesFromExcelByManager"].errorCode !== ""
        ) {
          enqueueSnackbar(
            t(response["CreateTimeEntriesFromExcelByManager"].errorMessage),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
        } else {
          enqueueSnackbar(t("timeEntryForm.createdSuccessfully"), {
            variant: "success",
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const handleClose = () => {
    setIncludeSubtasks(false);
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleClose();
  };
  const sendData = () => {
    if (data !== [] && data !== null && data.length > 0) {
      var dataToSend = data.map((row, index) => {
        const rowData = {
          employeeGuid: employeeID,
          taskGuid: row["taskGuid"],
          description: row["description"],
          date: new Date(row["date"]).toDateString(),
          duration: parseFloat(row["time"]),
        };

        return { ...rowData };
      });
      CreateTimeEntryData(dataToSend);
    } 
  };

  const handleFile = (event) => {
    const file = event.fileList[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const resourceGuid = jsonData[0][1];
      if (jsonData.length > 1) {
        const headerRow = jsonData[1]; // Extract the second row as the header
        const dataRows = jsonData.slice(2); // Extract data starting from the third row

        // Filter out rows where the project name (assuming it's in the first column) is null or empty
        const filteredDataRows = dataRows.filter((row) => {
          return row[0] !== null && row[0] !== "" && row[0] != undefined;
        });

        handleFileLoaded(filteredDataRows, resourceGuid);
      } else {
        console.error(
          "The file does not contain enough rows for headers and data."
        );
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileLoaded = ( dataRows, resourceGuid) => {
    setEmployeeID(resourceGuid)
    setData(dataRows?.map((row, index) => {
      const rowData = {
        "projectName": row[0],
        "taskGuid":row[1],
        "task":row[2],
        "description":row[3],
        "date":row[4],
        "time":row[5]
      };
      return { id: index, ...rowData };
    }));
    setNumOfRecords(dataRows?.length??0);
  };

  const pagination = {
    rows: data,
    columns,
    numberRecords: numOfRecords,
    loading: false,
    hideEditColumns: true,
    removeFilters: true,
    removeSelectAll: true,
    hideSearchBar: true,
    removeRefresh: true,
    removePagination: true,
    removeSorting: true,
  };
  
  return (
    <>
      <FieldModal
        PaperComponent={PaperComponent}
        CustomButton={CustomButton}
        loading={false}
        openDialog={openDialog}
        handleClose={handleClose}
        handleSave={handleSave}
        title={t("dialog.file.name")}
        label={t("task.list.exportToExcel.fileName")}
        t={t}
      />
      <Grid item xs={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
          }}
        >
          <Grid container spacing={1} display="flex" alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <AutoComplete
                loading={employeeLoading}
                options={employeesList}
                getOptionLabel={(option) =>
                  option ? option.firstName + " " + option.lastName : ""
                }
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.firstName + " " + option.lastName}
                  </li>
                )}
                identifier={"employeeGuid"}
                size="small"
                value={employeeID != null ? selectedEmployee : null}
                roundedTextField
                label={t("reports.label.employee")}
                disabled
                sx={{
                  "& .MuiAutocomplete-endAdornment": {
                    display: "none",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6} xl={6} display="flex">
              {/* <FileInput onFileLoaded={handleFileLoaded} /> */}
              <ReactFileReader
                fileTypes={[".xlsx"]}
                base64={true}
                handleFiles={(e) => handleFile(e)}
              >
                <IALoadingButton
                  sx={{
                    ".MuiLoadingButton-root": {
                      BackgroundColor: "inherit !important",
                    },
                  }}
                  tooltipLabel={t("requestForm.uploadFile")}
                >
                  <UploadIcon width="25" height="25" color="#ffffff" />
                </IALoadingButton>
              </ReactFileReader>
              <IALoadingButton
                onClick={sendData}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginLeft: "10px",
                }}
                label={t("task.list.importExcel")}
              />
            </Grid>
          </Grid>
          {columns.length === 0 ? (
            <ContainerCard
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </ContainerCard>
          ) : (
            <>
              <DataTable {...pagination} height={"calc(100vh - 370px)"}  />
            </>
          )}
        </ContainerCard>
      </Grid>
    </>
  );
}

export default ImportExcelTimeSheet;
