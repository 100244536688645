const form = {
  formID: "help",
  formField: {
    title: {
      name: "title",
      label: "helpForm.title.label",
    },
    description: {
      name: "description",
      label: "helpForm.description.label",
    },
  },
};
  
export default form;
