export const getFiltersObject = (
  fromProject,
  projectsList,
  employeesList,
  status_list,
  teams_list,
  teamsLoading,
  projectLoading,
  employeeLoading,
  statusLoading,
  isAdmin,
  isManager,
  defaultFilters,
  filterState,
  areaList,
  areaLoading,
  taskList,
  taskLoading,
  setFetchProjects,
  setFetchTasks,
  setFetchAreas,
  setSelectedProject,
  selectedProject,
  prioritiesList,
  // setFetchTaskStatuses,
  // setFetchTeams,
  // setFetchEmployees,
) => {
  return fromProject
    ? [
        {
          type: "text",
          label: "task.filters.label.title",
          name: "title",
        },
        {
          type: "text",
          label: "task.filters.label.description",
          name: "description",
        },
        {
          label: "task.filters.label.project",
          name: "projectID",
          type: "autocomplete",
          getOptionLabel: (option) => (option ? option.title : ""),
          options: projectsList,
          access: "projectGuid",
          loading: projectLoading,
          fetchData: setFetchProjects,
          selectedData: selectedProject,
          setSelectedData: setSelectedProject,
          fieldsToClear:["parentIDs", "areaIDs"]
        },
        // {
        //   type: "text",
        //   label: "task.filters.label.ticket",
        //   name: "ticket",
        // },
        {
          label: "task.filters.label.parent",
          name: "parentIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.title : ""),
          options: taskList,
          access: "taskGuid",
          loading: taskLoading,
          fetchData: setFetchTasks,
          disabled: !selectedProject,
        },
        {
          label: "task.filters.label.area",
          name: "areaIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: areaList,
          access: "areaGuid",
          loading: areaLoading,
          fetchData: setFetchAreas,
          disabled: !selectedProject,
        },
        {
          label: "task.filters.label.status",
          name: "statusIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: status_list,
          access: "taskStatusGuid",
          loading: statusLoading,
          // fetchData: setFetchTaskStatuses,
        },
        {
          label: "task.filters.label.teams",
          name: "teamsIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: teams_list,
          access: "teamGuid",
          loading: teamsLoading,
          disabled:
            filterState && filterState?.some(
              (state) =>
                state?.property == "unassignedTasks" && state?.value == true
            )
              ? true
              : false,
          fetchData: setFetchTasks,
        },
        {
          label: "task.filters.label.employee",
          name: "employeeIDs",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeesList,
          access: "employeeGuid",
          loading: employeeLoading,
          disabled:
            filterState && filterState?.some(
              (state) =>
                state?.property == "unassignedTasks" && state?.value == true
            )
              ? true
              : false,
          // fetchData: setFetchEmployees,
        },
        {
          label: "task.list.filters.priority",
          name: "priority",
          type: "dropdown",
          options: prioritiesList,
        },
        {
          type: "check",
          label: "task.filters.label.unassignedTasks",
          name: "unassignedTasks",
          access: "unassignedTasks",
          disabled: filterState && filterState?.some(
            (state) => (state?.property == "employeeIDs" && state?.value != null) || (state?.property == "teamsIDs" && state?.value != null)
          )
            ? true
            : false,
        },
        {
          type: "check",
          label: "task.filters.label.billable",
          name: "billable",
          access: "billable",
          disabledProperty: "nonBillable",
          disabledValue: false,
          disableClear: true,
        },
        {
          type: "check",
          label: "task.filters.label.nonBillable",
          name: "nonBillable",
          access: "nonBillable",
          disabledProperty: "billable",
          disabledValue: false,
          disableClear: true,
        },
      ]
    : [
        {
          type: "text",
          label: "task.filters.label.title",
          name: "title",
        },
        {
          type: "text",
          label: "task.filters.label.description",
          name: "description",
        },
        {
          label: "task.filters.label.area",
          name: "areaIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: areaList,
          access: "areaGuid",
          loading: areaLoading,
          fetchData: setFetchAreas,
        },
        {
          label: "task.filters.label.status",
          name: "statusIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: status_list,
          access: "taskStatusGuid",
          loading: statusLoading,
          // fetchData: setFetchTaskStatuses,
          // disabled:
          //   filterState && filterState?.some(
          //     (state) =>
          //       state?.property == "statusIDs" && state?.value == true
          //   )
          //     ? true
          //     : false,
        },
        {
          label: "task.filters.label.teams",
          name: "teamsIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: teams_list,
          access: "teamGuid",
          loading: teamsLoading,
          disabled:
            filterState && filterState?.some(
              (state) =>
                state?.property == "unassignedTasks" && state?.value == true
            )
              ? true
              : false,
          // fetchData: setFetchTeams,
        },
        {
          label: "task.filters.label.employee",
          name: "employeeIDs",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeesList,
          access: "employeeGuid",
          loading: employeeLoading,
          disabled:
            filterState && filterState?.some(
              (state) =>
                state?.property == "unassignedTasks" && state?.value == true
            )
              ? true
              : false,
          // fetchData: setFetchEmployees,
        },
        {
          label: "task.list.filters.priority",
          name: "priority",
          type: "dropdown",
          options: prioritiesList,
        },
        {
          type: "check",
          label: "task.filters.label.unassignedTasks",
          name: "unassignedTasks",
          access: "unassignedTasks",
          disabled: filterState && filterState?.some(
            (state) => (state?.property == "employeeIDs" && state?.value != null) || (state?.property == "teamsIDs" && state?.value != null)
          )
            ? true
            : false,
        },
        {
          type: "check",
          label: "task.filters.label.billable",
          name: "billable",
          access: "billable",
          disabledProperty: "nonBillable",
          disabledValue: false,
          disableClear: true,
        },
        {
          type: "check",
          label: "task.filters.label.nonBillable",
          name: "nonBillable",
          access: "nonBillable",
          disabledProperty: "billable",
          disabledValue: false,
          disableClear: true,
        },
      ];
};
