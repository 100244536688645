import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

import useAuth from "../../../../hooks/useAuth";

function ProjectTabs({ fromTenant = false, myTenant = false, fromCompany = false, fromFavorites = false }) {
  const { pName, pID, tName, tID, cName, cID } = useParams();

  const {
    tenant_project_form,
    my_projects_form,
    project_members,
    tenant_project_direct_tasks,
    my_project_direct_tasks,
    project_plan,
    favorite_project_plan,
    my_favorite_projects_form,
    favorite_project_members,
    my_favorite_project_direct_tasks,
    favorite_project_area,
    project_area,
    invoices,
    favorite_invoices
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  const { userRoles } = useAuth();

  
  const { manageTimeEntry } = userRoles;

  if (
    !pName ||
    !pID ||
    pName === "undefined" ||
    pName === "null" ||
    pName === "" ||
    pID === "null" ||
    pID === "undefined" ||
    pID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(pName);
  const encodedID = encodeURIComponent(pID);

  const TabLinks = [
    {
      key: 0,
      Header: t("projectTabs.form"),
      Link: fromTenant
        ? tenant_project_form
          .replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
          .replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          )
        : my_projects_form.replace(
          "/:pName/:pID",
          `/${encodedName}/${encodedID}`
        ),
    },
    ...(manageTimeEntry !== "N"
      ? [
        {
          key: 2,
          Header: t("projectTabs.members"),
          Link: project_members.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
    ...(manageTimeEntry !== "N"
      ? [
        {
          key: 3,
          Header: t("projectTabs.plan"),
          Link: project_plan.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
    // ...(manageTimeEntry !== "N"
    //   ? [
    //       {
    //         key: 3,
    //         Header: t("projectTabs.tree"),
    //         Link: project_tree.replace(
    //               "/:pName/:pID",
    //               `/${encodedName}/${encodedID}`
    //             ),
    //       },
    //     ]
    //   : []),
    ...(manageTimeEntry !== "N"
      ? [
        {
          key: 4,
          Header: t("projectTabs.tasks"),
          Link: fromTenant
            ? tenant_project_direct_tasks
              .replace(
                "/:pName/:pID",
                `/${encodedName}/${encodedID}`
              )
            : my_project_direct_tasks.replace(
              "/:pName/:pID",
              `/${encodedName}/${encodedID}`
            ),
        },
      ]
      : []),
      ...(manageTimeEntry !== "N"
      ? [
        {
          key: 5,
          Header: t("projectTabs.Areas"),
          Link: project_area.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
      ...(manageTimeEntry !== "N"
      ? [
        {
          key: 6,
          Header: t("projectTabs.invoices"),
          Link: invoices.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
  ];
  const FavTabLinks = [
    {
      key: 0,
      Header: t("projectTabs.form"),
      Link: my_favorite_projects_form.replace(
        "/:pName/:pID",
        `/${encodedName}/${encodedID}`
      ),
    },
    ...(manageTimeEntry !== "N"
      ? [
        {
          key: 2,
          Header: t("projectTabs.members"),
          Link: favorite_project_members.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
    ...(manageTimeEntry !== "N"
      ? [
        {
          key: 3,
          Header: t("projectTabs.plan"),
          Link: favorite_project_plan.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),

    ...(manageTimeEntry !== "N"
      ? [
        {
          key: 4,
          Header: t("projectTabs.tasks"),
          Link: my_favorite_project_direct_tasks.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
      ...(manageTimeEntry !== "N"
      ? [
        {
          key: 5,
          Header: t("projectTabs.Areas"),
          Link: favorite_project_area.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
      ...(manageTimeEntry !== "N"
      ? [
        {
          key: 6,
          Header: t("projectTabs.invoices"),
          Link: favorite_invoices.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          ),
        },
      ]
      : []),
      
  ];
  return (
    <Box>
      <BasicTabs TabHeaders={fromFavorites ? FavTabLinks : TabLinks} />
    </Box>
  );
}

export default ProjectTabs;
