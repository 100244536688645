import PropTypes from "prop-types";
import Tooltip from "../Tooltip";

import ReactHtmlParser from "react-html-parser";

const ReactParseHtml = ({ text }) => {
  if (!text) return null;
  const parsedText = ReactHtmlParser(text); // Function to extract and join text content

  function extractAndJoinTextContent(element) {
    if (typeof element === "string") {
      return element;
    } else if (Array.isArray(element)) {
      return element.map((child) => extractAndJoinTextContent(child)).join("");
    } else if (element && element.props && element.props.children) {
      return extractAndJoinTextContent(element.props.children);
    }
    return "";
  }

  const textContent = extractAndJoinTextContent(parsedText);

  return textContent.length > 45 ? (
    <Tooltip title={textContent}>
      {textContent.substring(0, 45) + "..."}
    </Tooltip>
  ) : (
    textContent
  );
};

export default ReactParseHtml;

ReactParseHtml.propTypes = {
  text: PropTypes.string,
};

ReactParseHtml.defaultProps = {
  text: "",
};
