const errors = {
    "email.isRequired": "Email is required",
    "password.isRequired": "Password is required",
    "systemError": "System error",
    "passwordsDontMatch": "Passwords do not match",
    "IXIA_ACTIV_SYSTEM_ERROR": "System error. Please contact your administrator.",
    "IXIA_ACTIV_FIRST_NAME_REQUIRED": "First name is required",
    "IXIA_ACTIV_LAST_NAME_REQUIRED": "Last name is required",
    "IXIA_ACTIV_EMAIL_REQUIRED": "Email is required", 
    "IXIA_ACTIV_PHONE_REQUIRED": "Phone number is required",
    "IXIA_ACTIV_INVALID_EMAIL": "Invalid email", 
    "IXIA_ACTIV_USER_ALREADY_EXISTS": "User already exists",
    "IXIA_ACTIV_STATUS_NOT_FOUND": "Status not found",
    "IXIA_ACTIV_INVALID_DIRECT_MANAGER": "Invalid direct manager",
    "IXIA_ACTIV_INVALID_USER_ROLE": "Invalid role",
    "IXIA_ACTIV_INVALID_EMPLOYMENT_TYPE": "Invalid employment type",
    "IXIA_ACTIV_INVALID_WORK_TYPE": "Invalid work type",
    "IXIA_ACTIV_INVALID_LOCATION": "Invalid location",
    "IXIA_ACTIV_WORK_SHIFT_DESCRIPTION_REQUIRED": "Workshift description is required",
    "IXIA_ACTIV_WORK_SHIFT_DAYS_REQUIRED": "Workshift days are required",
    "IXIA_ACTIV_INVALID_WORK_SHIFT_DAY": "Invalid workshift day",
    "IXIA_ACTIV_WORK_SHIFT_REQUIRED": "Workshift is required",
    "IXIA_ACTIV_INVALID_WORK_SHIFT_START_HOUR": "Invalid start time",
    "IXIA_ACTIV_INVALID_WORK_SHIFT_END_HOUR": "Invalid end time",
    "IXIA_ACTIV_START_GREATER_EQUAL_END": "Selected date is not within the valid range", //indicates that the start hour should be before the end date
    "IXIA_ACTIV_PASSWORD_FIELD_REQUIRED": "Password is required",
    "IXIA_ACTIV_INVALID_CREATED_BY_USER": "User trying to create was not found",
    "IXIA_ACTIV_COULDNT_CREATE_USER": "User couldn't be created", //indicates that the user couldn't be created
    "IXIA_ACTIV_USER_DOESNT_EXIST": "User doesn't exist",
    "IXIA_ACTIV_FAILED_UPDATE_EMAIL": "Failed to update the email",
    "IXIA_ACTIV_FAILED_DATABASE_ERROR": "The process failed due to a database error",
    "IXIA_ACTIV_INVALID_CREATED_BY": "The user trying to create was not found", 
    "IXIA_ACTIV_INVALID_EMPLOYEE_STATUS": "Invalid employee status", 
    "IXIA_ACTIV_INVALID_WORK_SHIFT": "Invalid workshift", 
    "IXIA_ACTIV_INVALID_LOGIN": "Invalid login",
    "IXIA_ACTIV_USER_NOT_ACTIVE_LOGIN": "Unable to login", 
    "IXIA_ACTIV_WILL_ATTEMPT_TO_SEND_EMAIL": "We will attempt to send the email", 
    "IXIA_ACTIV_INACTIVE_USER_CANT_SEND_EMAIL": "Unable to send email", 
    "IXIA_ACTIV_CANNOT_SEND_RESET_EMAIL": "Failed to send email",
    "IXIA_aCTIV_INVALID_EMAIL_TEMPLATE": "Invalid email template", 
    "IXIA_ACTIV_INVALID_PASSWORD_FORMAT": "Invalid password format", 
    "IXIA_ACTIV_PASSWORDS_DONT_MATCH": "Passwords don't match",
    "IXIA_ACTIV_TOKEN_EXPIRED_RESENT": "Token is expired. New token has been sent", 
    "IXIA_ACTIV_ACCOUNT_LOCKED": "Account locked", 
    "IXIA_ACTIV_INVALID_ATTENDANCE": "Invalid attendance", 
    "IXIA_ACTIV_INVALID_TIME_ENTRY": "Invalid time entry", 
    "IXIA_ACTIV_OPEN_ENTRY_EXISTS": "Open entry already exists", 
    "IXIA_ACTIV_DAY_LOG_ALREADY_EXISTS": "Day log already exists", 
    "IXIA_ACTIV_SMS_PROVIDER_NOT_FOUND": "Sms provider not found", 
    "IXIA_ACTIV_INACTIVE_USER_CANT_SEND_SMS": "Unable to send sms", 
    "IXIA_ACTIV_ERROR_SENDING_SMS": "An error occurred while attempting to send the sms", 
    "IXIA_ACTIV_EXPIRED_OTP_CODE": "Expired otp code", 
    "IXIA_ACTIV_INVALID_TASK": "Invalid task", 
    "IXIA_ACTIV_TIME_ENTRY_ALREADY_EXISTS": "Time entry already exists", 
    "IXIA_ACTIV_OPEN_ENTRY_DOESNT_EXIST": "Open entry doesn't exist",
    "IXIA_ACTIV_INVALID_PROJECT": "Invalid project", 
    "IXIA_ACTIV_INVALID_DEVICE_TOKEN": "Invalid device token",
    "IXIA_ACTIV_FAILED_TO_SAVE_DEVICE": "Failed to save your device",
    "IXIA_ACTIV_INVALID_EMAIL_PROVIDER": "Email provider not found", 
    "IXIA_ACTIV_UNAUTHORIZED": "Unauthorized", 
    "IXIA_ACTIV_NEW_TOKEN": "New token",
    "IXIA_ACTIV_ERROR_SENDING_EMAIL": "Email sending error", 
    "IXIA_ACTIV_INVALID_RANGE": "Range of records to skip is invalid", //in case skip is negative
    "IXIA_ACTIV_INVALID_TICKET": "Invalid phase", //in case of invalid ticket
    "IXIA_ACTIV_USER_NO_ACCESS_TO_COMPANIES": "It appears that you do not have access to any company or organization in our system", //if user does not have access to any company
    "IXIA_ACTIV_NO_TICKET_FOUND": "Phase not found", //if ticket not found
    "IXIA_ACTIV_INVALID_COMPANY": "Invalid company", //if company is invalid
    "IXIA_ACTIV_INVALID_NOTIFICATION": "Invalid notification", //if notification is invalid
    "IXIA_ACTIV_INVALID_SENDER": "Invalid sender", //if sender sending news is invalid
    "IXIA_ACTIV_INVALID_RECEIVER": "Invalid rceiver", // if person receiving notification is invalid
    "IXIA_ACTIV_INVALID_FILENAME": "Invalid filename", //if filename uploaded is invalid
    "IXIA_ACTIV_INVALID_FILE_EXTENSION": "Invalid file extension", //if filename extension invalid
    "IXIA_aCTIV_INVALID_IMAGE_LOCATION": "Invalid image location", //if path to save image doesn't exist
    "IXIA_ACTIV_INVALID_TENANT_NAME": "Invalid tenant name", //if tenant name is invalid
    "IXIA_ACTIV_NO_TENANT_FOUND": "Tenant not found", //if tenant not found
    "IXIA_ACTIV_INVALID_DEPARTMENT_NAME": "Invalid department name", //if department name is invalid
    "IXIA_ACTIV_NO_DEPARTMENT_FOUND": "Department not found", //if department not found
    "IXIA_ACTIV_INVALID_COMPANY_NAME": "Invalid company name", //if company name is invalid
    "IXIA_ACTIV_NO_COMPANY_FOUND": "Company not founc", //if company not found
    "IXIA_ACTIV_INVALID_ROLE_NAME": "Invalid role",
    "IXIA_ACTIV_INVALID_ROLE_DESCRIPTION": "Invalid role description.",
    "IXIA_ACTIV_INVALID_ROLE_MANAGEATTENDANCE": "Invalid role Manage Attendance.",
    "IXIA_ACTIV_INVALID_ROLE_MANAGETIMEENTRY": "Invalid role Manage Time Entry.",
    "IXIA_ACTIV_INVALID_ROLE_manageParameters": "Invalid role Manage SystemConfigurations.",
    "IXIA_ACTIV_INVALID_ROLE_manageSystemConfigurations": "Invalid role Manage SystemSetup.",
    "IXIA_ACTIV_INVALID_ROLE_MANAGEUSERS": "Invalid role manageusers.",
    "IXIA_ACTIV_NO_ROLE_FOUND": "Role not found", //if role not found
    "IXIA_ACTIV_INVALID_TASK_TITLE": "Invalid task title.",
    "IXIA_ACTIV_INVALID_TASK_DESCRIPTION": "Invalid task description.",
    "IXIA_ACTIV_INVALID_TASK_EXTERNALREFERNCE": "Invalid external reference for the task.",
    "IXIA_ACTIV_NO_PROJECT_FOUND": "Project not found.",
    "IXIA_ACTIV_NO_EMPLOYEE_FOUND": "Employee not found.",
    "IXIA_ACTIV_NO_TASKSTATUS_FOUND": "Task status not found.",
    "IXIA_ACTIV_NO_TASK_FOUND": "Task not found.",
    "IXIA_ACTIV_INVALID_PHONE_NUMBER": "Phone number not found.",
    "IXIA_ACTIV_NO_WORKSHIFT_FOUND": "Workshift not found.",
    "IXIA_ACTIV_EMPLOYEES_FOUND_FOR_WORKSHIFT": "Attempted to delete a workshift assigned to employees.",
    "IXIA_ACTIV_NO_WORKSHIFTDAY_FOUND": "Workshift day not found.",
    "IXIA_ACTIV_DATES_DONT_MATCH": "Selected date is not within the valid range.",
    "IXIA_ACTIV_INVALID_TABLE": "Invalid table.",
    "IXIA_ACTIV_INVALID_COLUMNS": "Invalid or empty table columns.",
    "IXIA_ACTIV_INVALID_SORT_DIRECTION": "Invalid sort direction.",
    "IXIA_ACTIV_MULTIPLE_TASKS_FOUND": "User is assigned to multiple tasks.",
    "IXIA_ACTIV_NO_DEFAULT_TASK_FOUND": "Default task not found.",
    "IXIA_ACTIV_INVALID_AUTO_CLOCK_IN": "User's auto clock-in is not enabled.",
    "IXIA_ACTIV_INVALID_LOGIN_INACTIV_TENANT": "Invalid login",
    "IXIA_ACTIV_INVALID_PROJECT_TITLE": "Invalid project title.",
    "IXIA_ACTIV_INVALID_PROJECT_DESCRIPTION": "Invalid project description.",
    "IXIA_ACTIV_INVALID_PROJECT_EXTERNALREFERNCE": "Invalid project external reference.",
    "IXIA_ACTIV_NO_LOCATION_FOUND": "Location not found.",
    "IXIA_ACTIV_NO_CLIENT_FOUND": "Client not found.",
    "IXIA_ACTIV_INVALID_TASKSTATUS_DESCRIPTION": "Invalid task status description.",
    "IXIA_ACTIV_NO_TASKSTATE_FOUND": "Task state not found.",
    "IXIA_ACTIV_RESOURCE_ALREADY_EXISTS": "Resource already exists.",
    "IXIA_aCTIV_INVALID_RESOURCE_CODE": "Invalid resource code.",
    "IXIA_aCTIV_INVALID_RESOURCE_NAME": "Invalid resource name.",
    "IXIA_ACTIV_INVALID_RESOURCE": "Invalid resource.",
    "IXIA_ACTIV_NO_TICKETSTATUS_FOUND": "Phase status not found.",
    "IXIA_ACTIV_INVALID_TICKETSTATUS_DESCRIPTION": "Invalid phase status description.",
    "IXIA_ACTIV_INVALID_TICKET_NAME": "Invalid phase name.",
    "IXIA_ACTIV_INVALID_TICKET_DESCRIPTION": "Invalid phase description.",
    "IXIA_ACTIV_NO_TASKS_ASSIGNED": "No tasks are assigned to user.",
    "IXIA_ACTIV_ID_ALREADY_EXISTS": "Record id already exists", 
    "IXIA_ACTIV_PTO_REQUEST_STATUS_NOT_FOUND": "Request status not found", 
    "IXIA_ACTIV_PTO_REQUEST_NOT_FOUND": "Time off request not found", 
    "IXIA_ACTIV_INVALID_PTO": "Invalid time off", 
    "IXIA_ACTIV_INVALID_PTO_REQUEST_STATUS": "Invalid time off request status", 
    "IXIA_ACTIV_INVALID_EMPLOYEE": "Invalid employee", 
    "IXIA_ACTIV_PTO_REQUEST_CANNOT_BE_EDITED": "Time off request cannot be modified", 
    "IXIA_ACTIV_INVALID_PTO_REQUEST": "Invalid time off request", 
    "IXIA_ACTIV_PTO_REQUEST_CANNOT_BE_DELETED": "Time off request cannot be deleted", 
    "IXIA_ACTIV_RECORDS_LINKED_TO_COMPANY": "Cannot delete company since there are records linked to it", 
    "IXIA_ACTIV_PTO_NOT_FOUND": "Time off record not found", 
    "IXIA_ACTIV_PTO_STATUS_INVALID": "Invalid time off status", 
    "IXIA_ACTIV_TENANT_INVALID": "Invalid tenant", 
    "IXIA_ACTIV_PTO_ASSIGN_DATE_INVALID": "Invalid time off assign date", 
    "IXIA_ACTIV_DATE_UNIT_INVALID": "Invalid date unit", 
    "IXIA_ACTIV_PTO_SENIORITY_NOT_FOUND": "Time off seniority not found", 
    "IXIA_ACTIV_PTO_STATUS_NOT_FOUND": "Time off status not found", 
    "IXIA_ACTIV_PTO_REQUEST_CANNOT_BE_CANCELED": "Cannot cancel time off request", 
    "IXIA_ACTIV_PTO_REQUEST_ALREADY_CANCELED": "Time off request already canceled",
    "IXIA_ACTIV_ROLES_LINKED_TO_TENANT": "Cannot delete tenant due to there being roles linked to it", 
    "IXIA_ACTIV_EMPLOYEE_PTO_NOT_FOUND": "Employee time off not found", 
    "IXIA_ACTIV_RECORDS_LINKED_TO_USER": "There are users linked to this role", 
    "IXIA_ACTIV_ROLE_DOESNT_EXIST": "Invalid role", 
    "IXIA_ACTIV_WORK_SHIFT_RECORDS_REFERENCED": "There are records linked to this workshift", 
    "IXIA_ACTIV_REQUEST_TYPE_INVALID": "Invalid request type", 
    "IXIA_ACTIV_WORK_SHIFT_NOT_VALID": "No working days were found", 
    "IXIA_ACTIV_INVALID_ROLE_MANAGEUSERSWORKINFORMATION": "Invalid role manageusersworkinformation", 
    "IXIA_ACTIV_INVALID_ROLE_MANAGEUSERSCHECKINS": "Invalid role manageuserscheckins", 
    "IXIA_ACTIV_INVALID_LOCATION_NAME": "Invalid location name", 
    "IXIA_ACTIV_INVALID_LOCATION_ADDRESS": "Invalid address", 
    "IXIA_ACTIV_INVALID_LATITUDE": "Invaid latitude", 
    "IXIA_aCTIV_INVALID_LONGITUDE": "Invalid longitude", 
    "IXIA_ACTIV_INVALID_COUNTRY": "Invalid country", 
    "IXIA_ACTIV_INVALID_UNIT_OF_MEASURE": "Invalid unit of measure", 
    "IXIA_ACTIV_RECORDS_LINKED_TO_LOCATION": "There are records linked to this location", 
    "IXIA_ACTIV_LOCATION_REQUIRED": "Location is required", 
    "IXIA_ACTIV_PHONE_NUMBER_EXISTS": "Phone number already in use", 
    "IXIA_ACTIV_INVALID_ROLE_ManageCompanyConfigurations": "Invalid role managecompanyconfigurations", 
    "IXIA_ACTIV_INVALID_ADDRESS": "Address cannot be null", 
    "IXIA_ACTIV_WORKTYPEGUID_REQUIRED": "Work type is required", 
    "IXIA_ACTIV_HOLIDAY_ALREADY_EXISTS": "Holiday already exists in the same year", 
    "IXIA_ACTIV_HOLIDAY_NOT_FOUND": "Holiday not found", 
    "IXIA_ACTIV_HOLIDAY_DAY_NOT_FOUND": "Holiday day not found", 
    "IXIA_ACTIV_CLOCKINREQUESTGUID_REQUIRED": "Clock in request is required", 
    "IXIA_ACTIV_RECORDS_LINKED_TO_OFFICIAL_HOLIDAY": "There are records linked to this official holiday",
    "IXIA_ACTIV_INPUT_LIST_REQUIRED": "Input list is required", 
    "IXIA_ACTIV_LOG_NOT_FOUND": "Log not found",
    "IXIA_ACTIV_LOG_DETAILS_NOT_FOUND": "Log details not found",
    "IXIA_ACTIV_INPUT_LOG_REQUIRED": "Input log is required", 
    "IXIA_ACTIV_PTO_RECORDS_REFERENCED": "Cannot delete time off. It has records linked to it", 
    "IXIA_ACTIV_DATE-UNIT_NOT_FOUND": "Date unit not found", 
    "IXIA_ACTIV_TASKGUID_REQUIRED": "Task is required", 
    "IXIA_ACTIV_ONLY_NEW_USERS_CAN_BE_DELETED": "Only new users can be deleted", 
    "IXIA_ACTIV_REQUEST_NOT_FOUND": "Invalid request", 
    "IXIA_ACTIV_CANNOT_DELETE_ROLE": "Role could not be deleted", 
    "IXIA_ACTIV_CANNOT_REMOVE_USER_FROM_MANAGERIAL_ROLE": "This user already has managerial duties", 
    "IXIA_ACTIV_TICKETS_LINKED_TO_PROJECT_WERE_FOUND": "Cannot delete project as there are phases linked to it", 
    "IXIA_ACTIV_LOCATION_LINKED_TO_CLOCKIN": "Cannot delete clock in since there is a location linked to it", 
    "IXIA_ACTIV_INVALID_MANAGER": "Could not perform operation due to the user not having permission to do so", 
    "IXIA_ACTIV_INVALID_EMPLOYMENT_STATUS": "Invalid employment status", 
    "IXIA_ACTIV_INVALID_TIMESHEET": "Timesheet not found", 
    "IXIA_ACTIV_INVALID_EMPLOYEE_EXTERNAL_REFERENCE": "Employee external reference not found", 
    "IXIA_ACTIV_NO_TIME_ENTRIES_WERE_FOUND": "No time entries found", 
    "IXIA_ACTIV_RESOURCES_AVAILABLE_FOR_THIS_COMPANY": "Company already has an external reference", 
    "IXIA_ACTIV_NO_SYSTEM_VARIABLES_FOUND": "System variable record not found", 
    "IXIA_ACTIV_APP_USER_CANNOT_LOGIN_ON_WEB": "Self-service users cannot log in to web interface",
    "IXIA_ACTIV_SKILL_NAME_REQUIRED": "Name is required",
    "IXIA_ACTIV_SKILL_ALREADY_EXISTS": "Skill already exists",
    "IXIA_ACTIV_SKILL_NOT_FOUND": "Skill not found",
    "IXIA_ACTIV_EMPOYEE_SKILL_ALREADY_EXISTS": "Employee Skill already exists",
    "IXIA_ACTIV_EMPOYEE_SKILL_NOT_FOUND": "Employee Skill not found",
    "IXIA_ACTIV_CAN_DELETE_SKILL": "Cannot delete skill because it is linked to at least one employee",
    "IXIA_ACTIV_START_DATE_GREATER_THAN_END_DATE": "Selected date is not within the valid range",
    "IXIA_ACTIV_START_OF_THE_WEEK_NOT_FOUND":"Cannot create time entry. You need to setup the days of your workshift first.",
    "IXIA_ACTIV_TIME_ENTRY_SYNCED": "Synced time entries cannot be deleted",
    "IXIA_ACTIV_NEW_TIME_ENTRY_DATE_INVALID": "Date should be within TimeSheet range",
    "IXIA_ACTIV_MIDDLEWAREJOB_NOT_FOUND": "Middleware Job not found",
    "IXIA_ACTIV_CANNOT_EDIT_OR_DELETE_WORKSHIFT":"You cannot edit this workshift, it has records linked to it",
    "IXIA_ACTIV_TIME_ENTRY_OPEN_CANNOT_START_NEW_TASK":"You can only start one task at a time",
    "IXIA_ACTIV_INVALID_LOG":"Invalid Log",
    "IXIA_ACTIV_OPEN_TIME_ENTRY_ALREADY_EXISTS":"Open time entry already exists",
    "IXIA_ACTIV_TIMEZONE_NOT_FOUND":"Timezone not found",
    "IXIA_ACTIV_STATUS_CANNOT_BE_DELETED_LINKED_TO_TASKS":"You cannot delete this status, it has records linked to it",
    "IXIA_ACTIV_TASK_CANNOT_BE_DELETED_LINKED_TO_TIMEENTRY":"Tasks cannot be deleted, one or more task has records linked to it",
    "IXIA_ACTIV_TICKET_CANNOT_BE_DELETED_LINKED_TO_TASKS":"You cannot delete this phase, it has records linked to it",
    "IXIA_ACTIV_OTP_NOT_FOUND":"OTP not found",
    "IXIA_ACTIV_INVALID_DATE_RANGE":"You can only choose a date within a one-month range",
    "IXIA_ACTIV_CONNECTOR_NOT_FOUND":"Connector not found",
    "IXIA_ACTIV_CANNOT_CHOOSE_MONTHLY_TIMESHEET_WITH_CONNECTOR-1":"Monthly timesheet and connector 1 doesn't match",
    "IXIA_ACTIV_CANNOT_CHOOSE_WEEKLY_TIMESHEET_WITH_CONNECTOR-2":"Weekly timesheet and connector 2 doesn't match",
    "IXIA_ACTIV_COMPANY_AND_JOB_FIELDS_ARE_REQUIRED_WITH_CONNECTOR-2":"Company and job fields are required when connector 2 is selected",
    "IXIA_ACTIV_DESCRIPTION_REQUIRED":"Description is required",
    "IXIA_ACTIV_STATUS_CANNOT_BE_DELETED_TENANT_DEFAULT_STATUS":"Status cannot be deleted. It is set as tenant default status.",
    "IXIA_ACTIV_CANNOT_CREATE_TIME_ENTRY":"You cannot create a time entry for this date. Related Timesheet has been closed.",
    "IXIA_ACTIV_CANNOT_APPROVE_TIMSHEET_BEFORE_CLOSING_DATE":"Cannot approve timesheet before closing date",
    "IXIA_ACTIV_INVALID_WORKSHIFT": "Invalid workshift",
    "IXIA_ACTIV_ATTENDANCE_SHEET_NOT_FOUND": "Attendance Sheet not found",
    "IXIA_ACTIV_ATTENDANCE_SHEET_CANNOT_BE_DELETED": "Attendance Sheet cannot be deleted",
    "IXIA_ACTIV_INVALID_ATTENDANCE_SHEET": "Invalid Attendance Sheet",
    "IXIA_ACTIV_DAY_lOG_NOT_FOUND": "DayLog not found",
    "IXIA_ACTIV_CANNOT_CREATE_DAY_LOG": "Cannot create day log",
    "IXIA_ACTIV_ATTENDANCE_SHEET_CANNOT_BE_EDITED": "Attendance Sheet cannot be edited",
    "IXIA_ACTIV_ACCESS_POINT_NOT_FOUND":"Access Point not found",
    "IXIA_ACTIV_REQUIRED_USERNAME_AND_PASSWORD":"Username and password are required",
    "IXIA_ACTIVE_CANNOT_DELETE_DAY_LOG_WITH_RELATED_TIME_ENTRIES":"Cannot delete day log with related time entries",
    "IXIA_ACTIV_TEAM_NOT_FOUND":"Team not found",
    "IXIA_ACTIV_CANNOT_ASSIGN_TO_EMPLOYEE_AND_TEAM_AT_THE_SAME_TIME":"Cannot assign to employee and team at the same time",
    "IXIA_ACTIV_INVALID_TEAM":"Invalid Team",
    "IXIA_ACTIV_ATTENDANCE_RECORD_NOT_SAVED":"Attendance Record Not Saved",
    "IXIA_ACTIV_RECORDS_LINKED_TO_TEAM":"You cannot delete this team, it has records linked to it",
    "IXIA_ACTIV_TENANT_CREATION_FAILED":"Tenant Creation Failed",
    "IXIA_ACTIV_NO_PROJECTS_ASSIGNED_TO_THIS_LOCATION":"No projects assigned to this location",
    "IXIA_ACTIV_ATTENDANCE_AND_TIME_ENTRY_DISABLED_CHOOSE_AT_LEAST_ONE":"Attendance and Time Entry are disabled, choose at least one",
    "IXIA_ACTIV_INVALID_EMPLOYEE_PTO":"Invalid Employee PTO",
    "IXIA_ACTIV_TOENTITLEMENT_NOT_FOUND":"Time-off entitlement not found",
    "IXIA_ACTIVATTENDANCE_DATE_OUTSIDE_ATTENDANCE_SHEET_SCOPE":"Date is outside attendance sheet scope",
    "IXIA_ACTIV_FAILED_TO_RESET_TOKEN":"Failed to reset token",
    "IXIA_ACTIV_TIME_ENTRIES_RELATED_TO_PROJECT_WERE_FOUND":"Time entries related to project were found",
    "IXIA_ACTIV_MISSING_MANDATORY_FIELDS":"Missing mandatory fields",
    "IXIA_ACTIV_BUSINESS_CENTRAL_VERSION_NOT_FOUND":"Business Central Version not found",
    "IXIA_ACTIV_WORKDAY_TIME_REQUIRED":"Work Day Time required",
    "IXIA_ACTIV_INVALID_SUGGESTION_STATUS":"Invalid Suggestion Status",
    "IXIA_ACTIV_SUGGESTION_NOT_FOUND":"Suggestion not found",
   "IXIA_ACTIV_SUGGESTION_STATUS_NOT_FOUND":"Suggestion Status not found",
    "IXIA_ACTIV_RETENTION_DAYS_CANNOT_BE_GREATER_THAN_14":"Retention Days cannot be greater than 14",
    "IXIA_ACTIV_MIDDLEWAREINFO_NOT_FOUND":"Middleware Info not found",
    "IXIA_ACTIV_CONTRACTOR_OF_THIS_NAME_ALREDY_EXISTS":"Contractor of this name already exists",
    "IXIA_ACTIV_CONTRACTOR_NOT_FOUND":"Contractor not found",
    "IXIA_ACTIV_JOB_TASK_COMPANY_REQUIRED":"Job task company not found",
    "IXIA_ACTIV_START_TIME_NOT_FOUND":"Start time not found",
    "IXIA_ACTIV_OVERTIME_REFERENCE_REQUIRED":"Overtime reference required",
    "IXIA_ACTIV_INVALID_MODIFIED_BY":"The user used in the created by field is invalid",
    "IXIA_ACTIV_TRIAL_EXPIRED":"Trial Expired",
    "IXIA_ACTIV_INVALID_BUSINESS_EMAIL":"Please enter a business email in the Email field",
    "IXIA_ACTIV_TENANT_NAME_ALREADY_EXISTS":"Tenant name already exists",
    "IXIA_ACTIV_CANNOT_DELETE_TASK_SUBTASKS_FOUND":"Unable to delete the task as there are associated subtasks",
    "IXIA_ACTIV_INVALID_SUGGESTION":"Invalid Suggestion",
    "IXIA_ACTIV_TOKEN_NOT_FOUND_IN_HEADER":"Token not found in header",
    "IXIA_ACTIV_INVALID_INPUT_CHARACTERS":"Invalid Input Characters",
    "IXIA_ACTIV_NEWS_NOT_FOUND":"News not found",
    "IXIA_ACTIV_INVALID_WORKSHIFTDAY_TIME":"Invalid workshift day time",
    "IXIA_ACTIV_RELEASE_NOT_FOUND":"Release not found",
    "IXIA_ACTIV_PTO_NOT_ACTIVE":"PTO not active",
    "IXIA_ACTIV_OFFICIAL_HOLIDAY_FOUND_ON_THIS_DATE":"Official Holiday not found",
    "IXIA_ACTIV_LOANED_ITEM_NOT_FOUND":"Loaned Item not found",
    "IXIA_ACTIV_OFFICIAL_HOLIDAYE_FOUND_ON_THIS_DATE":"Official Holiday found on this date",
    "IXIA_ACTIV_CONFLICT_WITH_EXISTING_REQUESTS":"Conflict with existing requests",
    "IXIA_ACTIV_CONFLICT_WITH_EXISTING_ATTENDANCE":"Conflict with existing attendance",
    "IXIA_ACTIV_TIMESHEET_END_DATE_INVALID":"Invalid Timesheet End Date",
    "IXIA_ACTIV_REQUEST_CANNOT_BE_DELETED":"Request cannot be deleted",
    "IXIA_ACTIV_TASKSTATUS_CANNOT_BE_DELETED":"Task Status cannot be deleted. Should have at least one status of each state",
    "IXIA_ACTIV_STATUS_CANNOT_BE_MOVED_FROM_ONE_STATE_TO_ANOTHER":"Status cannot be moved from one state to another",
    "IXIA_ACTIV_STATUS_HAS_TASKS_LINKED_TO_IT":"Status has tasks linked to it",
    "IXIA_ACTIV_TEAM_ALREADY_EXISTS":"Team already exists",
    "IXIA_ACTIV_DEPARTMENT_ALREADY_EXISTS":"Deparmtent already exists",
    "IXIA_ACTIV_STATUS_ALREADY_EXISTS":"Status already exists",
    "IXIA_ACTIV_AREA_ALREADY_EXISTS":"Area already exists",
    "IXIA_ACTIV_CONTRACTOR_ALREADY_EXISTS":"Contractor already exists",
    "IXIA_ACTIV_FAVORITE_PROJECT_NOT_FOUND":"Favorite Project not found",
    "IXIA_ACTIV_REQUESTED_TIME_OUT_OF_WORKSHIFT_HOURS":"Requested time is outside your designated work shift hours",
    "IXIA_ACTIV_AT_LEAST_ONE_TENANT_ADMIN":"There should be at least one admin per tenant",
    "IXIA_ACTIV_NUMBER_OF_HOURS_GREATER_THAN_WORKSHIFT_TOTAL_TIME":"Number of hours cannot be greater than workshift total time",
    "IXIA_ACTIV_USER_LINKED_TO_RECORDS":"Resource cannot be deleted, it has records linked to it",
    "IXIA_ACTIV_PTO_HAS_RECORDS_LINKED_TO_IT":"Time off cannot be edited, it has records linked to it",
    "IXIA_ACTIV_USER_PREFERENCES_TABLE_NOT_FOUND":"User Preferences Table not found",
    "IXIA_ACTIV_TIME_ENTRY_LESS_THAN_MINIMUM":"The time entered is less than the minimum required.",
    "IXIA_ACTIV_WORK_TIME_LESS_THAN_MINIMUM":"The work time entered for the attendance is less than the minimum required time",
    "IXIA_ACTIV_TIMESHEET_CANNOT_BE_DELETED_TIME_ENTRIES_SYNCED":"Timesheet cannot be deleted. It is linked to one or more synced time entries.",
    "IXIA_ACTIV_SYNCED_PROJECTS_CANNOT_BE_DELETED":"Synced projects cannot be deleted.",
    "IXIA_ACTIV_SYNCED_PHASES_CANNOT_BE_DELETED":"Synced phases cannot be deleted.",
    "IXIA_ACTIV_SYNCED_TASKS_CANNOT_BE_DELETED":"Synced tasks cannot be deleted.",
    "IXIA_ACTIV_CANNOT_EDIT_OPEN_TIME_ENTRY_EXISTS":"Task cannot be edited. There exists one or more open time entries linked to it.",
    "IXIA_ACTIV_CANNOT_CANCEL_APPROVED_ATTENDANCE" :"Can not cancel approved attendance.",
    "IXIA_ACTIV_TASK_EXTERNAL_REFERENCE_ALREADY_EXISTS_IN_THIS_PROJECT":"Task external reference already exists in this project.",
    "IXIA_ACTIV_CANT_REOPEN_SYNCED_APPROVED_TIMESHEET":"Cannot reopen a synced timesheet."

}

export default errors;