import React from "react";

import Tooltip from "../Tooltip";
import { getOptionPriorityIcon } from "../Icon";

const FlagBadge = (props) => {

  const prio = props?.row?.task?.priority;
  const { priority = false, flag, tree } = props;

  if (priority === false || flag === null || prio === null) {
    return null;
  }
  return (
    <div style={tree ? { display: "flex", alignItems: "center", justifyContent: "center" } : null}>
      <Tooltip title={priority || flag || prio}>
        {getOptionPriorityIcon(priority || flag || prio)}
      </Tooltip>
    </div>

  )
};

export default FlagBadge;
