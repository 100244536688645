import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip";

function stripHtmlTags(html) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;
  return tempElement.textContent || tempElement.innerText || "";
}

const StripHtmlTags = ({ text, length }) => {
  if (!text) return null;

  const strippedText = stripHtmlTags(text);
  const len = strippedText.length;
  if (!length) return strippedText;
  else if (length != null && length === 1 && len > 30) {
    return (
      <Tooltip title={strippedText}>{strippedText}</Tooltip>
    );
  } else if (length != null && length === 1 && len < 30) {
    return strippedText;
  } else if (length != null && len > length) {
    return (
      <Tooltip title={strippedText}>
        {strippedText.substring(0, length) + "..."}
      </Tooltip>
    );
  }

  return len > 30 && !length ? (
    <Tooltip title={strippedText}>
      {strippedText.substring(0, 30) + "..."}
    </Tooltip>
  ) : (
    strippedText
  );
};

StripHtmlTags.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number,
};

StripHtmlTags.defaultProps = {
  text: "",
};

export default StripHtmlTags;
