import checkout from "./form";

const {
  formField: {
    company,
    regularPayExternalResource,
    overtimeExternalResource,
    weekendOvertimeExternalResource
  },
} = checkout;

const initialValues = {
  [company.name]: null,
  [regularPayExternalResource.name]: null,
  [overtimeExternalResource.name]: null,
  [weekendOvertimeExternalResource.name]: null
};

export default initialValues;