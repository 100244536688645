import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";
import { Navigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

function TimesheetsList() {
  const { t } = useTranslation();

  const {
    my_timesheets_list, my_timesheets_list_approved, my_timesheets_list_pending
  } = urls;

  const { tenantMonthlyTimesheet } = useAuth();

  if(window.location.href.split("/")[6] === undefined || (window.location.href.split("/")[6] === null)) return <Navigate to={my_timesheets_list_pending} replace />;

  const TabLinks = [
    {
      key: 1,
      Header: t("timesheetsTabs.pending"),
      Link: my_timesheets_list_pending,
    },
    {
      key: 2,
      Header: t("timesheetsTabs.approved"),
      Link: my_timesheets_list_approved,
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default TimesheetsList;
