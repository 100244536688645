

import { useParams } from "react-router-dom";

import { Modal, Grid, IconButton } from "@mui/material";

import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UnassignedEmployeesList from "../Unassigned-employees-list";

const UnassignedEmployeeTable = ({
  display = false,
  setDisplay = () => {},
  tenant = null,
  handleClick = () => {},
  initialVal = [],
}) => {

  const { rID } = useParams();

  return (
    <Modal
      open={display}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ContainerCard sx={{ padding: "10px 20px", width: "70%", maxHeight: "97vh" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            {/* <Typography
              variant="h5"
              style={{
                fontFamily: "poppins",
              }}
            >
              {t("teamTable.AddTeam")}
            </Typography> */}
            <IconButton onClick={() => setDisplay(false)}>
              <ClearOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <UnassignedEmployeesList
              fromEmployee
              initialVal={initialVal}
              tenant={tenant}
              handleClick={handleClick}
              roleGuid={rID}
            />
          </Grid>
        </Grid>
      </ContainerCard>
    </Modal>
  );
};

export default UnassignedEmployeeTable;
