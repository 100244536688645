import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

import { AddCircle as AddIcon } from "@mui/icons-material";

//graphql
import { GET_PTO_LIST, GET_TO_ENTITLEMENT_LIST } from "../../../../graphql/pto";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { DELETE_TO_ENTITLEMENT } from "../../../../graphql/pto";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress, Modal } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import AddDayUsedForm from "../AddDayUsed-Form";
import colors from "../../../../assets/theme/light/colors";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getEmployeeFromStore } from "../../../../app/reducers/employeeSlice";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const EmployeeDaysUsedList = () => {
  const { eID, eName, duID, duName, tID, profileID } = useParams();

  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const { manageAttendance, isAdmin, isManager } = userRoles;

  const employee = useSelector(getEmployeeFromStore);
  
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [filterState, setFilterState] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState(
    filterState && filterState.length > 0 ? filterState : {}
  );
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const [displayModal, setDisplayModal] = useState(false);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TO_ENTITLEMENT,
    "DeleteTOEntitlement"
  );

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            toEntitlementIDs: selected,
          },
        },
      },
      () => {
        enqueueSnackbar(t("dayUsed.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };
  
  const {
    data: ptosData,
    loading: ptosLoading,
    tokenChange: ptosWait,
  } = useQueryAuth(GET_PTO_LIST, "GetPTOsList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: tID ? tID : storeUserTenant,
        employeeID: eID ? eID : storeUserGuid
      },
    },
  });

  const ptosList = ptosData?.GetPTOsList?.ptOs?.ptOs || [];

  const { data: employeeData, loading: employeeLoading, tokenChange: employeesWait } = useQueryAuth(
    GET_ALL_EMPLOYEES_DROPDOWN,
    "GetCompanyUsersList",
    {
      variables: {
        model: {
          enableFilters: false,
          tenantID: storeUserTenant,
          userID: storeUserGuid,
        },
      },
      skip: ptosWait,
      isWait: true,
    }
  );

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  useEffect(() => {
    if (!ptosLoading) {
      const objectIndexPTOIDs = filterState.findIndex(
        (obj) => obj.property === "ptoiDs"
      );

      const filteredPTOsGuids = ptosList.map((t) => t.ptoGuid);

      if (ptosList?.length > 0) {
        if (filteredPTOsGuids.length > 0) {
          if (objectIndexPTOIDs < 0) {
            setDefaultFilters({
              ...defaultFilters,
              ptoiDs: duID,
            });
            const ptoObj = {
              value: duID,
              property: "ptoiDs",
            };
            setFilterState([...filterState, ptoObj]);
          } else {
            const val =
              filterState[objectIndexPTOIDs].value == null
                ? null
                : [filterState[objectIndexPTOIDs].value];
            const ptoObj = { value: val, property: "ptoiDs" };
            setDefaultFilters({ ...defaultFilters, ptoiDs: val });

            setFilterState([...filterState, ptoObj]);
          }
        }
      }
    }

    
    if (employeeLoading === false) {
      const objectIndexEmployeeIDs = filterState.findIndex(
        (obj) => obj.property === "employeeIDs"
      );

      const filteredEmployeesGuids = employeeList.map((t) => t.employeeGuid);

        if (filteredEmployeesGuids.length > 0 && employeeList.length > 0) {
          if (objectIndexEmployeeIDs < 0) {
            setDefaultFilters({
              ...defaultFilters,
              employeeIDs: eID || profileID,
            });
            const employeeObj = {
              value: eID || profileID,
              property: "employeeIDs",
            };
            setFilterState([...filterState, employeeObj]);
          } else {
            const val =
              filterState[objectIndexEmployeeIDs].value == null
                ? null
                : [filterState[objectIndexEmployeeIDs].value];
            const employeeObj = { value: val, property: "employeeIDs" };
            setDefaultFilters({ ...defaultFilters, employeeIDs: val });

            setFilterState([...filterState, employeeObj]);
          }
      }

      setRe(v => !v);
    }
  }, [ptosLoading, employeeLoading]);

  const filtersObject = [
    {
      label: "requests.list.filters.ptoType",
      name: "ptoiDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: ptosList,
      loading: ptosLoading,
      access: "ptoGuid",
    },
    {
      label: "requests.list.filters.employees",
      name: "employeeIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeeList,
      loading: employeeLoading,
      access: "employeeGuid",
      disabled: !isManager && !isAdmin,
      disableClear: !isManager && !isAdmin,
    },
    {
      type: "date",
      label: "requests.list.filters.fromDate",
      name: "dateFrom",
    },
    {
      type: "date",
      label: "requests.list.filters.toDate",
      name: "dateTo",
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.DaysUsed,
          userID: storeUserGuid,
        },
      },
      skip: ptosWait || employeesWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllTOEntitlements, loading } = useLazyQueryAuth(
    GET_TO_ENTITLEMENT_LIST,
    "GetTOEntitlementList"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTOEntitlements(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              // keyWord: search,
              filters: filters,
              enableFilters: true,
              tenantGuid: storeUserTenant
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait && !ptosWait && !employeesWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, employeesWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetTOEntitlementList &&
    data.GetTOEntitlementList.toEntitlementListResponse &&
    data.GetTOEntitlementList.toEntitlementListResponse.toEntitlements
      ? data.GetTOEntitlementList.toEntitlementListResponse.toEntitlements
      : [];
  const numberOfRecords =
    data &&
    data.GetTOEntitlementList &&
    data.GetTOEntitlementList.toEntitlementListResponse &&
    data.GetTOEntitlementList.toEntitlementListResponse.totalNumberOfRecords
      ? data.GetTOEntitlementList.toEntitlementListResponse.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "employeeDaysUsed.addDayUsed",
      handleClick: () => setDisplayModal(true),
      condition: employee?.employeeStatus?.mnemonic !== Mnemonic.EmployeeStatuses.inactive,
      icon: true,
      iconButton: <AddIcon sx={{ fill: `${colors.light_logo_blue} !important` }} />,
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "dayUsed.list.search.label",
    handleDelete: handleDelete,
    deleteText: "dayUsed.list.delete.label",
    deleteLoading,
    refetch,
    tableMnemonic: Mnemonic.Tables.DaysUsed,
    filtersObject,
    customButtons: eID ? customButtons : !customButtons,
    readOnly: manageAttendance !== "M",
    hideSearchBar: true,
  };

  return (
    <div>
      <Modal
        open={displayModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <AddDayUsedForm
          handleClose={() => setDisplayModal(false)}
          onSuccess={() => setRe((val) => !val)}
          pTOs={ptosList}
          employees={employeeList}
        />
      </Modal>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"toEntitlementGuid"} hasCheckbox={isAdmin} />
      )}
    </div>
  );
};

export default EmployeeDaysUsedList;
