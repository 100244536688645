import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    description,
    suggestionStatus
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [description.name]:Yup.string(),
    [suggestionStatus.name]:Yup.object().nullable()

  }),
];

export default validation;