import React, { useState } from "react";
import {
  DeleteIcon,
  EyeIcon,
  HelpIcon,
  PDFIcon,
  UploadIcon,
  WarningIcon,
} from "../../../../../../components/Icon";
//components
import { Grid, Box, TextField, CircularProgress, IconButton, Button, Menu, MenuItem } from "@mui/material";
import { Title, Subtitle } from "../../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../../components/AutoComplete";
import ReactFileReader from "react-file-reader";

//translation
import { useTranslation } from "react-i18next";
import { GET_ALL_ONBOARDING_TASK_STATUSES, GET_ALL_ONBOARDING_TASK_TYPES } from "../../../../../../graphql/onBoardingTasks";
import useQueryAuth from "../../../../../../hooks/useQueryAuth";
import TextArea from "antd/es/input/TextArea";
import "./style.css"
import { base64StringtoFile } from "../../../../../../utils/base64StringtoFile";
import colors from "../../../../../../assets/theme/light/colors";
import IALoadingButton from "../../../../../../components/IAButtons/IALoadingButton";
import { useParams } from "react-router-dom";
import CustomTooltip from "../../../../../../components/Tooltip";
import componentDisplay from "../../../../../../componentDisplay";
import useAuth from "../../../../../../hooks/useAuth";
import Mnemonics from "../../../../../../Mnemonics.json";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData, taskGuid }) => {
  const { t } = useTranslation();
  const { obName, obID } = useParams();
  const { userRoles, isEmployee } = useAuth();
  const { manageTimeEntry, } = userRoles;

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [types, setType] = useState("");
  const [fetchStatuses, setFetchStatuses] = useState(false);
  const [anchorElStatus, setAnchorElStatus] = useState(null);

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    whatField,
    setWhatField,
  } = formData;

  const {
    type,
    description,
    name,
    attachment,
    status,
  } = formField;

  const {
    type: typeV,
    description: descriptionV,
    name: nameV,
    attachment: attachmentV,
    status: statusV,

  } = values;

  const onboarding_types = [];

  // const { data: onBoardingTypes, loading: onboardingTaskLoading, tokenChange: onboardingTaskWait } = useQueryAuth(
  //   GET_ALL_ONBOARDING_TASK_TYPES,
  //   "GetAllOnBoardingTypes",
  // );

  // const onboarding_types =
  //   onBoardingTypes &&
  //     onBoardingTypes.GetAllOnBoardingTypes &&
  //     onBoardingTypes.GetAllOnBoardingTypes.onBoardingTypesList
  //     ? onBoardingTypes.GetAllOnBoardingTypes.onBoardingTypesList
  //     : [];

  const { data: onBoardingStatuses, loading: onboardingStatusLoading, tokenChange: onboardingStatusWait } = useQueryAuth(
    GET_ALL_ONBOARDING_TASK_STATUSES,
    "GetAllOnBoardingTaskStatuses",
    {
      skip: !fetchStatuses,
      isWait: fetchStatuses ? true : false,
    }
  );

  const onboarding_statuses =
    onBoardingStatuses &&
      onBoardingStatuses.GetAllOnBoardingTaskStatuses &&
      onBoardingStatuses.GetAllOnBoardingTaskStatuses.onBoardingTaskStatusesList
      ? onBoardingStatuses.GetAllOnBoardingTaskStatuses.onBoardingTaskStatusesList

      : [];






  const handleStatusButtonClick = (event) => {
    setFetchStatuses(true);
    setAnchorElStatus(event.currentTarget);
  };

  const handleStatusMenuItemClick = (value) => {
    setAnchorElStatus(null);
    setTouched({ ...touched, [status.name]: true });
    setFieldValue(status.name, value);
  };

  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };


  const handleFiles = (files, setFieldValue) => {
    setLoading(true);
    setType(files.fileList[0]?.type);
    const attach = base64StringtoFile(files.base64, files.fileList[0].name);
    setImage(files);
    setTimeout(() => {
      setFieldValue(attachment.name, attach);
      setLoading(false);
    }, 500);
  };
  function swipe() {
    var image = document.getElementById("attachment");
    var url = image.getAttribute("src");
    window.open(url);
  }

  const statusPicked = () => {
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <CustomTooltip title={t("task.tootlip.status")}>
          <Button
            onClick={handleStatusButtonClick}
            disabled={
              readOnly ||
              manageTimeEntry === "R" ||
              isEmployee === Mnemonics.ResourceTypes.External ||
              false
            }
            sx={{
              ".MuiButton-root": { minWidth: "0px" },
              ":hover": { backgroundColor: "transparent !important" },
              padding: 0,
            }}
          >
            {componentDisplay({
              compName: "BackgroundStatus",
              compProps: { row: values, size: 12, length: 25, widthAuto: true },
            })}
          </Button>
          <Menu
            anchorEl={anchorElStatus}
            open={Boolean(anchorElStatus)}
            onClose={handleStatusClose}
          >
            {onboarding_statuses?.map((option) => (
              <MenuItem
                key={option?.projectStatusGuid}
                onClick={() => {
                  handleStatusMenuItemClick(option);
                }}
              >
                {" "}
                {option?.description}
              </MenuItem>
            ))}
          </Menu>
        </CustomTooltip>
      </Grid>
    );
  };


  return (
    <Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("OnboardingTask.Information")}
          </Title>
          {statusPicked()}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                autoFocus
                sx={{ ".MuiInputBase-input": { fontSize: "14px !important" } }}
                variant="standard"
                fullWidth
                disabled
                label={t(name.label)}
                value={nameV}

                onBlur={(e) =>
                  setTouched({ ...touched, [name.name]: true })
                }

                onChange={(e) => {
                  setFieldValue(name.name, e.target.value);
                }}
                error={touched[name.name] && errors[name.name]}
                helperText={
                  touched[name.name] &&
                  errors[name.name] &&
                  t(errors[name.name])
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AutoComplete
                options={onboarding_types}
                getOptionLabel={(option) => (option ? option.description : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.description}
                  </li>
                )}
                identifier={"onBoardingTypeGuid"}
                disabled
                value={typeV}
                onChange={(e, value) => {
                  setFieldValue(type.name, value);
                }}
                loading={false}
                name={type.name}
                label={t(type.label)}
                formData={formData}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                sx={{ ".MuiInputBase-input": { fontSize: "14px !important" } }}
                variant="standard"
                fullWidth
                disabled={readOnly}
                label={t(description.label)}
                value={descriptionV}
                onBlur={(e) =>
                  setTouched({ ...touched, [description.name]: true })
                }

                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                error={touched[description.name] && errors[description.name]}
                helperText={
                  touched[description.name] &&
                  errors[description.name] &&
                  t(errors[description.name])
                }
              />
            </Grid>

            {attachmentV && <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: "6px !important" }}
            >
              <Box
                sx={{
                  // border: "1px dashed #949494",
                  // height: "60px",
                  padding: "20px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  cursor: "initial ",
                  marginTop: "20px",
                }}
              >
                {
                  <>
                    {" "}
                    {loading ? (
                      <CircularProgress size={20} style={{ marginRight: "10px" }} />
                    ) : (
                      <>
                        {(image || (taskGuid && attachmentV)) && (
                          <div
                            id="attachment-parent"
                            style={{ position: "relative", marginRight: "20px" }}
                          >
                            {types === "application/pdf" ? (
                              <PDFIcon />
                            ) : (
                              <img
                                id="attachment"
                                src={image?.base64 || attachmentV}
                                width={100}
                                height={100}
                                style={{ objectFit: "cover", marginRight: "10px" }}
                                onClick={() => {
                                  if (taskGuid && attachmentV) swipe();
                                }}
                              />
                            )}
                            {taskGuid && (
                              <IconButton
                                className={`my-icon-button-eye ${taskGuid ? "my-icon-center" : ""
                                  }`}
                                onClick={swipe}
                              >
                                <EyeIcon color={colors.pure_white} />
                              </IconButton>
                            )}
                            {!(taskGuid && attachmentV) && (
                              <IconButton
                                className={`my-icon-button-delete ${types === "application/pdf"
                                  ? "my-icon-button-delete-pdf"
                                  : "my-icon-button-delete-image"
                                  } ${!taskGuid ? "my-icon-center" : ""}`}
                                onClick={() => {
                                  setImage(null);
                                  setFieldValue(attachment.name, "");
                                }}
                              >
                                <DeleteIcon color={colors.pure_white} />
                              </IconButton>
                            )}
                          </div>
                        )}

                      </>
                    )}

                  </>
                }
              </Box>
            </Grid>}

            {/* <Grid container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>


              <Grid item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                mt={!attachmentV ? 3 : null}
                ml={2}>

                <Box mt={2} width="100%" display="flex" justifyContent="flex-start">
                  <ReactFileReader
                    fileTypes={[".png", ".jpg", ".pdf"]}
                    base64={true}
                    handleFiles={(e) => handleFiles(e, setFieldValue)}
                  >
                    <IALoadingButton sx={{ ".MuiLoadingButton-root": { BackgroundColor: "inherit !important", } }} tooltipLabel={t("requestForm.uploadFile")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-cloud-upload"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="#ffffff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                        <path d="M9 15l3 -3l3 3"></path>
                        <path d="M12 12l0 9"></path>
                      </svg>
                    </IALoadingButton>
                  </ReactFileReader>
                </Box>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>


      </Grid>
    </Box>
  );
};

export default Main;
