import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import {
  DeleteIcon,
  EditPenIcon,
  MaximizeComponentIcon,
  MinimizeComponentIcon,
} from "../../components/Icon";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

function CommentCard({
  key,
  comment,
  handleDelete,
  storeUserGuid,
  modules,
  formats,
  UpdateTaskCommentData,
  loading,
  t,
  editMode,
  setEditMode,
  fullscreenComment,
  setFullscreenComment,
}) {
  const { taskCommentGuid, employee, description, createdAt, editedAt } =
    comment;
  const [content, setContent] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  // const [editMode, setEditMode] = useState(false);
  // const [newUpdatedComment, setNewUpdatedComment] = useState(description);

  const quillRef = useRef();

  useEffect(() => {
    const commentCard = document.querySelector(".comment-card");
    if (commentCard) {
      const allContainers = commentCard.querySelectorAll(".ql-container");
      const allToolbars = commentCard.querySelectorAll(".ql-toolbar");
      const editor = commentCard.querySelector(".ql-editor");

      if (editor && editor.display) {
        if (fullscreenComment) editor.display.maxHeight = "1000px !important";
        else editor.display.maxHeight = "150px !important";
      }

      allToolbars.forEach((toolbar) => {
        if (toolbar && toolbar.style) {
          toolbar.style.borderTopLeftRadius = "5px";
          toolbar.style.borderTopRightRadius = "5px";
        }
      });

      allContainers.forEach((container) => {
        if (container && container.style) {
          container.style.borderBottomLeftRadius = "5px";
          container.style.borderBottomRightRadius = "5px";
        }
      });
    }
  }, [editMode]);

  useEffect(() => {
    if (description) {
      const mentionRegex =
        /@\[(.*?)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/g;
      setContent(description.replace(mentionRegex, "@$1"));
    }
  }, [description]);

  const handleQuillChange = (value) => {
    setContent(value);
  };

  const handleCancel = () => {
    setEditMode(false);
    if (description) {
      const mentionRegex =
        /@\[(.*?)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/g;
      setContent(description.replace(mentionRegex, "@$1"));
      setEditMode(false);
    }
  };

  // console.log("editMode", editMode);
  // console.log("fullscreenComment", fullscreenComment);
  // console.log("selectedComment", selectedComment);
  // console.log("taskCommentGuid", taskCommentGuid);
  // console.log(
  //   "fullscreenComment && selectedComment !== taskCommentGuid",
  //   fullscreenComment && selectedComment !== taskCommentGuid
  // );
  // console.log(
  //   "e!ditMode && !fullscreenComment",
  //   !editMode && !fullscreenComment
  // );

  return (
    <div
      className="comment-card"
      style={
        fullscreenComment && selectedComment === taskCommentGuid
          ? {
              width: "98%",
              position: "absolute",
              top: 0,
              bottom: 0,
              height: "calc(100vh - 350px)",
            }
          : { width: "100%" }
      }
    >
      {editMode === taskCommentGuid ? (
        <>
          <ReactQuill
            ref={quillRef}
            // className="comment-quill"
            bounds={"#root"}
            name="body"
            value={content}
            onChange={handleQuillChange}
            modules={modules}
            formats={formats}
            placeholder={t("taskForm.comment.placeholder")}
          />
          <div className="comment-action-buttons">
            <Button
              variant="text"
              disabled={loading}
              onClick={handleCancel}
              size="small"
            >
              {t("dialog.cancel")}
            </Button>
            <Button
              variant="text"
              disabled={content == "" || loading}
              onClick={() => {
                setEditMode(taskCommentGuid);
                UpdateTaskCommentData(content, taskCommentGuid);
              }}
              size="small"
            >
              {t("dialog.save")}
            </Button>
          </div>
        </>
      ) : (
        <Card
          key={key}
          sx={
            fullscreenComment && selectedComment === taskCommentGuid
              ? {
                  minWidth: "100%",
                  height: "calc(100vh - 350px)",
                  maxHeight: "calc(100vh - 350px)",
                  overflowY: "auto",
                  border: "1px solid #ccc !important",
                  borderRadius: "5px",
                }
              : fullscreenComment && selectedComment !== taskCommentGuid
              ? {
                  display: "none",
                }
              : {
                  minWidth: "100%",
                  maxHeight: "150px",
                  overflowY: "auto",
                  border: "1px solid #ccc !important",
                  borderRadius: "5px",
                }
          }
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: "red", width: "35px", height: "35px" }}
                aria-label="recipe"
              >
                {employee?.firstName?.substring(0, 1)}
              </Avatar>
            }
            action={
              storeUserGuid === employee?.employeeGuid && (
                <>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(taskCommentGuid)}
                  >
                    <DeleteIcon color="red" width="18" height="18" />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    onClick={() => setEditMode(taskCommentGuid)}
                  >
                    <EditPenIcon color="blue" width="18" height="18" />
                  </IconButton>
                  {fullscreenComment && selectedComment === taskCommentGuid ? (
                    <IconButton
                      aria-label="minimize"
                      onClick={() => {
                        setFullscreenComment(!fullscreenComment);
                        setSelectedComment(null);
                      }}
                    >
                      <MinimizeComponentIcon
                        color="grey"
                        width="18"
                        height="18"
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="maximize"
                      onClick={() => {
                        setFullscreenComment(!fullscreenComment);
                        setSelectedComment(taskCommentGuid);
                      }}
                    >
                      <MaximizeComponentIcon
                        color="grey"
                        width="18"
                        height="18"
                      />
                    </IconButton>
                  )}
                </>
              )
            }
            title={employee?.firstName + " " + employee?.lastName}
            subheader={
              editedAt
                ? new Date(editedAt).toDateString()
                : new Date(createdAt).toDateString()
            }
            sx={{
              padding: "16px 16px 0 16px !important",
              "& .MuiCardHeader-title": {
                fontSize: "12px !important",
                fontWeight: "bold !important",
              },
              "& .MuiCardHeader-subheader": { fontSize: "11px !important" },
            }}
          />
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "13px !important" }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default CommentCard;
