import React, { useState, useEffect, useMemo } from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import DataCard from "../../../components/Cards/DataCard";

//data
import getTableData from "./_upcoming-official-holidays";

import useQueryAuth from "../../../hooks/useQueryAuth";

import useAuth from "../../../hooks/useAuth";

import {
  GET_UPCOMING_OFFICIAL_HOLIDAYS,
} from "../../../graphql/officialHolidays";
import { useTranslation } from "react-i18next";


const UpcomingOfficialHolidays = ({ tenant = null, date }) => {

  const [loading, setLoading] = useState(false); //set true when we wish to start lazy loading


  const { t } = useTranslation();

  const { userRoles, storeUserTenant } = useAuth();

  const {isEntelligence} = userRoles;

   /**States for Upcoming Official Holidays */
   const [_upcomingOfficialHolidays, _setUpcomingOfficialHolidays] = useState(
    []
  );
  const [_upcomingOfficialHolidaysNumb, _setUpcomingOfficialHolidaysNumb] =
    useState(0);
  

    const {
      loading: upcomingOfficialHolidayLoading,
    } = useQueryAuth(
      GET_UPCOMING_OFFICIAL_HOLIDAYS,
      "GetUpcomingOfficialHolidayDays",
      {
        variables: {
          model: {
            tenantID: isEntelligence
              ? tenant != null
                ? tenant.tenantGuid
                : undefined
              : storeUserTenant,
              date: new Date(date).toISOString(),
              _365Days: true
          },
        }
      },
      {
        onCompleted: (response) => {
          var array =
            response?.GetUpcomingOfficialHolidayDays?.upcomingOfficialHolidays
              ?.officialHolidayDays || [];
  
          _setUpcomingOfficialHolidays((records) => [...records, ...array]);
          _setUpcomingOfficialHolidaysNumb(
            response?.GetUpcomingOfficialHolidayDays?.upcomingOfficialHolidays
              ?.totalNumberOfRecords || 0
          );
        },
      }
    );

  const _records = useMemo(()=>_upcomingOfficialHolidays || [], [_upcomingOfficialHolidays]); //holds data
  const _maxRecords =_upcomingOfficialHolidaysNumb || 0; //holds total number of records in database that satisfy the query

  useEffect(() => {
    if (_records && _records.length > 0) setLoading(false);
  }, [_records]);

  function fetchData() {
    //function that gets records on lazy load

    if (upcomingOfficialHolidayLoading) return; //data is already being fetched, we wait for it to at least be fetched

    if (_records.length >= _maxRecords) return; //if data has reached its max

  }

  const _tableData = getTableData(t);

  const { title, headers, render } = _tableData; //holds card title, table headers and the display of data

  const stopLazyLoad = _records.length >= _maxRecords; //if all records have been fetched we no longer wish to lazy load

  return (
    <DataCard
      id="dashboard-oh-modal"
      title={title}
      stopLazyLoad={stopLazyLoad}
      onLazyLoad={fetchData}
      tableHeaders={headers}
      render={render}
      data={_records}
      initialLoad={!(upcomingOfficialHolidayLoading && _upcomingOfficialHolidaysNumb === 0)}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

UpcomingOfficialHolidays.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch: PropTypes.bool,
};

UpcomingOfficialHolidays.defaultProps = {
  initialLoad: false,
  data: {},
  next: () => {},
  waitBatch: false,
};

export default UpcomingOfficialHolidays;
