import React from "react";

//components
import { Grid, Box } from "@mui/material";
import { Title, Subtitle } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";

//translation
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../../components/Tooltip";
//hooks
import { useSnackbar } from "notistack";
import TextField from "../../../../../components/TextFields/TextField";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../../graphql/location";
import { GET_ALL_WORK_TYPES } from "../../../../../graphql/workType";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";
import { GET_ALL_KIOSK_DEVICES_STATUSES } from "../../../../../graphql/kioskDevices";
import { GET_LOCATION_OPTIONS } from "../../../../../graphql/clockin-out";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData }) => {
  const { t } = useTranslation();
  const { storeUserGuid, storeUserTenant } = useAuth();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
  } = formData;

  const {
    status,
    description,
    deviceID,
    fingerprint,
    location,
    workType,
  } = formField;

  const {
    status: statusV,
    description: descriptionV,
    deviceID: deviceIDV,
    fingerprint: fingerprintV,
    location: locationV,
    workType: workTypeV,
  } = values;


  const {
    data: workTypesList,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes");

  const workType_list = workTypesList &&
    workTypesList.GetAllWorkTypes &&
    workTypesList.GetAllWorkTypes.workTypes
    ? workTypesList.GetAllWorkTypes.workTypes.filter(workType => workType.mnemonic !== "workfromhome")
    : [];


  const { data: locationsList, loading: locationLoading, tokenChange: locationWait } = useQueryAuth(
    GET_LOCATION_OPTIONS,
    "GetLocationsByWorkType",
    {
      variables: {
        model: {
          userID: storeUserGuid,

          workTypeID: workTypeV?.workTypeGuid
        },
      },
      skip: !workTypeV,
      isWait: true,
    }
  );

  const location_list =
    locationsList &&
      locationsList.GetLocationsByWorkType &&
      locationsList.GetLocationsByWorkType.locations
      ? locationsList.GetLocationsByWorkType.locations
      : [];

  const {
    data: kioskStatuses,
    loading: statusLoading,
    tokenChange: statusWait,
  } = useQueryAuth(GET_ALL_KIOSK_DEVICES_STATUSES, "GetAllKioskDeviceStatus", {
    skip: workTypesWait,
  });

  const status_list =
    kioskStatuses &&
      kioskStatuses?.GetAllKioskDeviceStatus.kioskDeviceStatusList ? kioskStatuses?.GetAllKioskDeviceStatus?.kioskDeviceStatusList
      : [];


  return (
    <Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("roleForm.roleInformation")}
          </Title>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField

                fullWidth
                value={deviceIDV}
                onChange={(e) => {
                  setFieldValue(deviceID.name, e.target.value);
                }}
                label={t(deviceID.label)}
                name={deviceID.name}
                formData={formData}
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AutoComplete
                options={status_list}
                getOptionLabel={(option) => (option ? option.description : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.description}
                  </li>
                )}
                identifier={"kioskDeviceStatusGuid"}
                value={statusV}
                disabled={readOnly}
                onChange={(e, value) => {
                  setFieldValue(status.name, value);
                }}
                label={t(status.label)}
                name={status.name}
                formData={formData}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                value={descriptionV}
                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                label={t(description.label)}
                name={description.name}
                formData={formData}
                required
                disabled={readOnly}
              />
            </Grid>




            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField

                fullWidth
                value={fingerprintV}
                onChange={(e) => {
                  setFieldValue(fingerprint.name, e.target.value);
                }}
                label={t(fingerprint.label)}
                name={fingerprint.name}
                formData={formData}
                required
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AutoComplete
                options={workType_list}
                getOptionLabel={(option) => (option ? option.description : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.description}
                  </li>
                )}
                identifier={"workTypeGuid"}
                value={workTypeV}
                disabled={readOnly}
                onChange={(e, value) => {
                  setFieldValue(workType.name, value);
                  setFieldValue(location.name, null)
                }}
                label={t(workType.label)}
                name={workType.name}
                required
                formData={formData}
              />
            </Grid>

            <Tooltip title={t("kiosk.tooltip.location")}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <AutoComplete
                  options={location_list}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"locationGuid"}
                  value={locationV}
                  // disabled={readOnly}
                  onChange={(e, value) => {
                    setFieldValue(location.name, value);
                  }}
                  disabled={!workTypeV}
                  label={t(location.label)}
                  name={location.name}
                  formData={formData}
                  required
                />
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
