import checkout from "./form";

const {
  formField: { newPassword, confirmPassword, },
} = checkout;

export default {
  [newPassword.name]: "",
  [confirmPassword.name]: ""
};
