import React, { useRef, useState } from "react";
import {
  DeleteIcon,
  EyeIcon,
  HelpIcon,
  PDFIcon,
  UploadIcon,
  WarningIcon,
} from "../../../../../components/Icon";
//components
import {
  Grid,
  Box,
  TextField,
  CircularProgress,
  IconButton,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Title, Subtitle } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";
import ReactFileReader from "react-file-reader";

//translation
import { useTranslation } from "react-i18next";
import {
  GET_ALL_ONBOARDING_TASK_STATUSES,
  GET_ALL_ONBOARDING_TASK_TYPES,
} from "../../../../../graphql/onBoardingTasks";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import TextArea from "antd/es/input/TextArea";
import "./style.css";
import { base64StringtoFile } from "../../../../../utils/base64StringtoFile";
import colors from "../../../../../assets/theme/light/colors";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import { useParams } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css';
import fileImage from "../../../../../assets/images/file.png";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData, attachmentName, setAttachmentName, setIsNew }) => {
  const { t } = useTranslation();
  const { obName, obID } = useParams();

  const [loading, setLoading] = useState(false);
  const [newlyUploaded, setNewlyUploaded] = useState(false);
  const [image, setImage] = useState(null);
  const [types, setType] = useState("");

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    whatField,
    setWhatField,
  } = formData;

  const { type, description, emailBody, emailTitle, attachment } = formField;

  const {
    type: typeV,
    description: descriptionV,
    emailBody: emailBodyV,
    emailTitle: emailTitleV,
    attachment: attachmentV,
  } = values;

  const {
    data: onBoardingTypes,
    loading: onboardingTaskLoading,
    tokenChange: onboardingTaskWait,
  } = useQueryAuth(GET_ALL_ONBOARDING_TASK_TYPES, "GetAllOnBoardingTypes");

  const onboarding_types =
    onBoardingTypes &&
    onBoardingTypes.GetAllOnBoardingTypes &&
    onBoardingTypes.GetAllOnBoardingTypes.onBoardingTypesList
      ? onBoardingTypes.GetAllOnBoardingTypes.onBoardingTypesList
      : [];

  const handleFiles = (files, setFieldValue) => {
    setAttachmentName(files.fileList[0].name);
    setLoading(true);
    setNewlyUploaded(true);
    setIsNew(true);
    setType(files.fileList[0]?.type);
    const attach = base64StringtoFile(files.base64, files.fileList[0].name);
    setImage(files);
    setTimeout(() => {
      setFieldValue(attachment.name, attach);
      setLoading(false);
    }, 500);
  };

  function swipe() {
    window.open(attachmentV);
  }

  const modules = React.useMemo(
    () => ({
      toolbar: false,
    }),
    []
  );

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
  ];
  
  const quillRef = useRef();

  const handleQuillChange = (html) => {
    if (touched[emailBody.name]) setFieldValue(emailBody.name, html);
  };
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("OnboardingTask.Information")}
          </Title>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                autoFocus
                sx={{ ".MuiInputBase-input": { fontSize: "14px !important" } }}
                variant="standard"
                fullWidth
                disabled={readOnly}
                label={t(description.label)}
                value={descriptionV}
                required
                onBlur={(e) =>
                  setTouched({ ...touched, [description.name]: true })
                }
                onChange={(e) => {
                  setFieldValue(description.name, e.target.value);
                }}
                error={touched[description.name] && errors[description.name]}
                helperText={
                  touched[description.name] &&
                  errors[description.name] &&
                  t(errors[description.name])
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <AutoComplete
                options={onboarding_types}
                getOptionLabel={(option) => (option ? option.description : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.description}
                  </li>
                )}
                identifier={"onBoardingTypeGuid"}
                // disabled={createMode === false || readOnly}
                value={typeV}
                onChange={(e, value) => {
                  setFieldValue(type.name, value);
                }}
                loading={onboardingTaskLoading}
                name={type.name}
                label={t(type.label)}
                formData={formData}
                required
                fullWidth
              />
            </Grid>

            {typeV &&
              (typeV?.description == "Email" ||
                typeV?.description == "Signature Required" ||
                typeV?.description == "Document Required") && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                    <Subtitle>{t("onboarding.emailTemplate")}</Subtitle>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="emailTtitle"
                  >
                    <TextArea
                      sx={{
                        ".MuiInputBase-input": { fontSize: "14px !important" },
                      }}
                      variant="standard"
                      fullWidth
                      multiline
                      disabled={readOnly}
                      placeHolder={t(emailTitle.label)}
                      value={emailTitleV}
                      required
                      onBlur={(e) =>
                        setTouched({ ...touched, [emailTitle.name]: true })
                      }
                      onChange={(e) => {
                        setFieldValue(emailTitle.name, e.target.value);
                      }}
                      error={
                        touched[emailTitle.name] && errors[emailTitle.name]
                      }
                      helperText={
                        touched[emailTitle.name] &&
                        errors[emailTitle.name] &&
                        t(errors[emailTitle.name])
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="emailBody"
                  >
                    <div
                      onFocus={() =>
                        setTouched({ ...touched, [emailBody.name]: true })
                      }
                    >
                      <ReactQuill
                        ref={quillRef}
                        name="body"
                        value={emailBodyV}
                        onChange={handleQuillChange}
                        modules={modules}
                        formats={formats}
                        placeholder="Description"
                        className="custom-onBoardingQuill"
                      />
                    </div>
                  </Grid>
                </>
              )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
              <Subtitle>{t("onboarding.Attachment")}</Subtitle>
            </Grid>
            {attachmentV && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ paddingTop: "6px !important" }}
              >
                <Box
                  sx={{
                    // border: "1px dashed #949494",
                    // height: "60px",
                    padding: "10px 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    cursor: "initial ",
                    width: "100%"
                  }}
                >
                  {
                    <>
                      {" "}
                      {loading ? (
                        <CircularProgress
                          size={20}
                          style={{ marginRight: "10px" }}
                        />
                      ) : (
                        <>
                          {obID && attachmentV && (
                            <Card
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0 0 0 20px",
                                width: "inherit",
                                boxShadow:
                                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important",
                              }}
                            >
                              <CardMedia
                                component="img"
                                sx={{ height: "100%", width: 80 }}
                                image={fileImage}
                                alt="Live from space album cover"
                              />
                              <CardContent id="attachment-parent" sx={{ width: "100%", position: "relative", paddingBottom: "16px !important" }}>
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={11} lg={11} xl={11} sx={{display: "flex", alignItems: "center"}}>
                                    {newlyUploaded ? (
                                      <Typography component="div" variant="body1">
                                        {attachmentName}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        component="div"
                                        variant="body1"
                                        sx={{ cursor: "pointer", width: "60%" }}
                                        onClick={() => window.open(attachmentV)}
                                      >
                                        {attachmentName}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{display: "flex", alignItems: "center"}}>
                                    {/* <div style={{display: "flex", alignItems: "center"}}> */}
                                      <IconButton
                                        className={`my-icon-button-delete-aligned`}
                                        onClick={() => {
                                          setImage(null);
                                          setIsNew(true);
                                          setFieldValue(attachment.name, "");
                                        }}
                                      >
                                        <DeleteIcon color={colors.icon_button_grey} />
                                      </IconButton>
                                    {/* </div> */}
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          )}
                          {/* {obID && attachmentV && (
                            <div
                              id="attachment-parent"
                              style={{
                                position: "relative",
                                marginRight: "20px",
                              }}
                            >
                              {!attachmentV.endsWith(".jpg") &&
                              !attachmentV.endsWith(".png") &&
                              !attachmentV.endsWith(".jpeg") ? (
                                <img
                                  id="attachment"
                                  src={fileImage}
                                  width={100}
                                  height={100}
                                  style={{
                                    objectFit: "cover",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    if (obID && attachmentV) swipe();
                                  }}
                                />
                              ) : (
                                <img
                                  id="attachment"
                                  src={attachmentV}
                                  width={100}
                                  height={100}
                                  style={{
                                    objectFit: "cover",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => {
                                    if (obID && attachmentV) swipe();
                                  }}
                                />
                              )}
                              {obID && (
                                <IconButton
                                  className={`my-icon-button-eye ${
                                    obID ? "my-icon-center" : ""
                                  }`}
                                  onClick={swipe}
                                >
                                  <EyeIcon color={colors.pure_white} />
                                </IconButton>
                              )}
                              {!(obID && attachmentV) && (
                                <IconButton
                                  className={`my-icon-button-delete ${
                                    types === "application/pdf"
                                      ? "my-icon-button-delete-pdf"
                                      : "my-icon-button-delete-image"
                                  } ${!obID ? "my-icon-center" : ""}`}
                                  onClick={() => {
                                    setImage(null);
                                    setFieldValue(attachment.name, "");
                                  }}
                                >
                                  <DeleteIcon color={colors.pure_white} />
                                </IconButton>
                              )}
                            </div>
                          )} */}
                        </>
                      )}
                    </>
                  }
                </Box>
              </Grid>
            )}

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                mt={!attachmentV ? 3 : null}
                ml={2}
              >
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="flex-start"
                >
                  <ReactFileReader
                    fileTypes={[".png", ".jpg", ".pdf"]}
                    base64={true}
                    handleFiles={(e) => handleFiles(e, setFieldValue)}
                  >
                    <IALoadingButton
                      sx={{
                        ".MuiLoadingButton-root": {
                          BackgroundColor: "inherit !important",
                        },
                      }}
                      tooltipLabel={t("requestForm.uploadFile")}
                    >
                      <UploadIcon
                        color="#ffffff"
                        width="25"
                        height="25"
                        strokeWidth="2"
                      />
                    </IALoadingButton>
                  </ReactFileReader>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
