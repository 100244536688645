import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { title, description },
} = checkout;

const validation = [
  Yup.object().shape({
    [title.name]: Yup.string(),
    [description.name]: Yup.string().nullable(),
  }),
];

export default validation;