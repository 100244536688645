import { gql } from "@apollo/client";

export const GET_ALL_TASK_STATES = gql`
query {
    GetAllTaskStates  {
      tasksState {
        taskStateGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
