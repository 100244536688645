import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../hooks/useLazyQueryAuth";
import useAuth from "../../../hooks/useAuth";

//graphql


//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../components/Cards/ContainerCard";
import { getTable } from "./_table";
import { useParams } from "react-router-dom";
import { APPLIED_INVOICES } from "../../../graphql/invoices";

const AppliedInvoices = ({ pageSize = 20 }) => {

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers } = userRoles;
  const { iID } = useParams();
  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (columns?.length === 0) {
      const col = getTable();

      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns?.length]);

  const {
    mfunction: getAllAppliedInvoices,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(APPLIED_INVOICES, "GetAppliedInvoicesList");


  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllAppliedInvoices(
        {
          variables: {
            model: {
              // enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              invoiceGuid: iID,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (columns.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columns.length]);

  const rows =
    data &&
      data.GetAppliedInvoicesList &&
      data.GetAppliedInvoicesList &&
      data.GetAppliedInvoicesList.appliedInvoicesListResponse
      ? data.GetAppliedInvoicesList.appliedInvoicesListResponse.appliedInvoices
      : [];
  const numberOfRecords =
    data &&
      data.GetAppliedInvoicesList &&
      data.GetAppliedInvoicesList &&
      data.GetAppliedInvoicesList.appliedInvoicesListResponse.totalNumberOfRecords
      ? data.GetAppliedInvoicesList.appliedInvoicesListResponse.totalNumberOfRecords
      : 0;

  // const customButtons = [
  //   {
  //     label: "employeeForm.addButton",
  //     handleClick: () => handleClick(selected),
  //     condition: selected && selected.length > 0 && manageUsers === "M",
  //   }
  // ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    readOnly: manageUsers !== "M",
    removeFilters: true,
    hideEditColumns: true,
    hideSearchBar: true,
    // customButtons,
  };

  return (
    <div>
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"projectInvoiceGuid"} minWidth={500} />
      )}
    </div>
  );
};

export default AppliedInvoices;
