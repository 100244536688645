import PendingActionsIcon from '@mui/icons-material/PendingActions';
import UTC2Local from "../../../UTC2Local";

const data = {
  title: (
    <span className="span-icon-title" style={{color:"#EF7323"}}>
    <PendingActionsIcon className="head-icon-table" fontSize="medium" style={{marginLeft:"0.3rem"}}/>
    Pending Approval Requests
  </span>
  ),
  
  headers: ["Employee", "Request Date", "Type"],
  render: [
    (row) => {
      return <div style={{marginTop:"0.8rem"}}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-2-check" width="17" height="17" viewBox="0 0 24 24" strokeWidth="1" stroke="var(--color--light-logo-blue)" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M8 9h8" />
      <path d="M8 13h6" />
      <path d="M12 21l-1 -1l-2 -2h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6" />
      <path d="M15 19l2 2l4 -4" />
    </svg><p style={{marginLeft:"0.5rem", display:"inline-block", verticalAlign:"middle", marginBottom:"0.5rem"}}>{row?.employee?.firstName + " " + row?.employee?.lastName}</p></div>;
    },
    (row) => {
      return <p style={{marginTop:"0.3rem"}}>{UTC2Local(row?.requestDate, true)}</p>;
    },
    (row) => {
      return <p style={{marginTop:"0.3rem"}}>{row?.requestType?.description}</p>;
    }
  ],
  // headers: ["Employee", "Request Date", "Type", "Date", "Time"],
  // render: [
  //   (row) => {
  //     return <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>;
  //   },
  //   (row) => {
  //     return <p>{UTC2Local(row?.requestDate, true)}</p>;
  //   },
  //   (row) => {
  //     return <p>{row?.requestType?.description}</p>;
  //   },
  //   (row) => {
  //     return (
  //       <p>
  //         {row.dateFrom? UTC2Local(row?.dateFrom, true) +
  //           (row.dateTo ? " till " + UTC2Local(row?.dateTo, true) : "") :""}
  //       </p>
  //     );
  //   },
  //   (row) => {
  //     return (
  //       <p>
  //         {row.dateTo
  //           ? "Full Day"
  //           : row.timeFrom? UTC2Time(row?.timeFrom)+" - "+ UTC2Time(row?.timeTo) : ""}
  //       </p>
  //     );
  //   },
  // ],
};

export default data;
