import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    name,
    task,
    hoursOfWork,
    job,
    company,
    timesheetBy,
    syncTimeEntries,
    projectRadius,
    attendance,
    taskStatuses,
    timeEntry,
    LogCleanUpRuntime,
    phaseStatuses,
    LogRetentionDays,
    connector,
    middlewareJob,
    allowTimesheetReopening,
    mandatoryTimeEntryDescription,
    allowAttendanceSheetReopening,
    monthlyAttendanceSheet,
    postJournal,
    postJournalDescription,
    postUnderAndOverUsageToNonBillableJob,
    trial,    
    trialExpirationDate,
    subscribedFrom,
  },
} = checkout;

export default [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.requiredMessage),
    [job.name]: Yup.string()
      .typeError(job.errorMsg)
      .nullable()
      .when(connector.name, {
        is: (name) => name === "Connector-2",
        then: Yup.string().typeError(job.errorMsg).required(job.errorMsg),
      }),
    [company.name]: Yup.string()
      .typeError(company.errorMsg)
      .nullable()
      .when(connector.name, {
        is: (name) => name === "Connector-2",
        then: Yup.string()
          .typeError(company.errorMsg)
          .required(company.errorMsg),
      }),
    [postUnderAndOverUsageToNonBillableJob.name]: Yup.bool()
      .typeError(postUnderAndOverUsageToNonBillableJob.errorMsg)
      .nullable()
      .when(connector.name, {
        is: (name) => name === "Connector-2",
        then: Yup.bool()
          .typeError(postUnderAndOverUsageToNonBillableJob.errorMsg)
          .required(postUnderAndOverUsageToNonBillableJob.errorMsg),
      }),
    [timeEntry.name]: Yup.bool().nullable(),
    [timesheetBy.name]: Yup.object().when(timeEntry.name, {
      is: true,
      then: () => Yup.object().required(timesheetBy.requiredMessage),
      otherwise: () => Yup.object().notRequired()
    }),
    [hoursOfWork.name]: Yup.number().when(timeEntry.name, {
      is: true,
      then: () => Yup.number().required(hoursOfWork.requiredMessage),
      otherwise: () => Yup.number().notRequired()
    }),
    [task.name]: Yup.string().nullable(),
    [projectRadius.name]: Yup.number().required(projectRadius.requiredMessage),
    [allowTimesheetReopening.name]: Yup.bool(),
    [syncTimeEntries.name]: Yup.bool().required(
      syncTimeEntries.requiredMessage
    ),
    [taskStatuses.name]: Yup.object().nullable(),
    [connector.name]: Yup.object().nullable(),
    [middlewareJob.name]: Yup.array().nullable(),
    [phaseStatuses.name]: Yup.object().nullable(),
    [LogCleanUpRuntime.name]: Yup.date().nullable(),
    [LogRetentionDays.name]: Yup.number().nullable(),
    [mandatoryTimeEntryDescription.name]: Yup.bool().nullable(),
    [allowAttendanceSheetReopening.name]: Yup.bool().nullable(),
    [trial.name]: Yup.bool().nullable(),
    [trialExpirationDate.name]: Yup.date().nullable(),
    [subscribedFrom.name]: Yup.string().nullable(),
    [attendance.name]: Yup.bool().nullable(),
    [monthlyAttendanceSheet.name]: Yup.object().when(attendance.name, {
      is: true,
      then: () => Yup.object().required(monthlyAttendanceSheet.requiredMessage),
      otherwise: () => Yup.object().notRequired()
    }),
    [postJournal.name]: Yup.bool().nullable(),
    [postJournalDescription.name]: Yup.string().when(syncTimeEntries.name, {
      is: true,
      then: () => Yup.string().required(postJournalDescription.requiredMessage),
      otherwise: () => Yup.string().notRequired()
    }),
  }),
];
