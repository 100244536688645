export const getTable = () => {
    return {
      columns: [
        {
          id: "FirstName",
          label: "attendance.list.column.employeeName",
          align: "left",
          visible: true,
          renderCell: (row) => {
            return (
              (row?.firstName || "") +
              " " +
              (row?.lastName || "")
            );
          },
        },
      ],
      defaultSort: "FirstName",
      defaultSortDirection: "asc",
    };
  };
  