import { useEffect, useRef, useState } from "react";

import { useQuery } from "@apollo/client";

import useAuth from "./useAuth";

import { useSnackbar } from "notistack";

import useMutationAuth from "./useMutationAuth";

import { useTranslation } from "react-i18next";

import { LOGOUT_USER } from "../graphql/user";
import { saveToken } from "../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";
import { store } from "../app/store";

function useQueryAuth(
  QUERY,
  objAccess = "",
  params = {},
  statusFunctions = {},
  isVersion = false,
  
) {
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const [tokenChange, setTokenChange] = useState(true);
  const { t } = useTranslation();

  // Snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  useEffect(() => {
    if (params && params.skip === true && !params.isWait) {
      setTokenChange(false);
    }
  }, [params, params.skip, params.isWait]);

  // Get data using GraphQL API
  const { loading, error, data, refetch } = useQuery(QUERY, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: false,
    ...params,
    onCompleted: (data) => {
      if (data[objAccess].errorMessage === "IXIA_ACTIV_UNAUTHORIZED") {
        setTokenChange(false);
        sessionStorage.removeItem("unsavedChanges");

        logsOutUser(
          {
            variables: {
              model: {
                jwtToken: store?.getState()?.token?.token
              },
            },
          },
          () => {
            logout();
          }
        );
      } else if (data[objAccess].errorMessage === "IXIA_ACTIV_NEW_TOKEN") {
        sessionStorage.removeItem("unsavedChanges");
        setTokenChange(true);
        dispatch(saveToken(data[objAccess].errorVariables.trim()));
        refetch();

      }
       else if (data[objAccess].errorMessage === "IXIA_ACTIV_INVALID_ATTENDANCE" && data[objAccess].errorCode === "err041") {
        localStorage.setItem('checkedIn', false)
      }
     
       else if (
        data[objAccess].errorCode !== null &&
        data[objAccess].errorCode !== ""
      ) {
        if (isVersion) logout();
        setTokenChange(false);
        //tokenIsBeingfetched(false);
        enqueueSnackbar(t(data[objAccess].errorMessage), {
          variant: "error",
          autoHideDuration: 5000
        });
      } else {
        setTokenChange(false);
        if (statusFunctions.onCompleted !== undefined)
          statusFunctions.onCompleted(data);
      }
    },
    onError: () => {
      if (isVersion) logout();
      setTokenChange(false);
      enqueueSnackbar(t("systemError"), {
        variant: "error",
        autoHideDuration: 5000
      });
      if (statusFunctions.onError !== undefined) statusFunctions.onError(data);
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
    tokenChange,
    reset: () => setTokenChange(true),
  };
}

export default useQueryAuth;
