const form = {
  formID: "requests",
  formField: {
    employee: {
      name: "employee",
      label: "requestForm.employee.label",
    },
    receiver: {
      name: "receiver",
      label: "requestForm.receiver.label",
    },
    requestDate: {
      name: "requestDate",
      label: "requestForm.requestDate.label",
    },
    requestType: {
      name: "requestType",
      label: "requestForm.requestType.label",
      requiredMessage: "requestForm.requestType.requiredMsg",
    },
    requestStatus: {
      name: "requestStatus",
      label: "requestForm.requestStatus.label",
      requiredMessage: "requestForm.requestStatus.requiredMsg",
    },
    dateFrom: {
      name: "dateFrom",
      label: "requestForm.dateFrom.label",
      secondaryLabel: "requestForm.date.label",
      requiredMessage: "requestForm.dateFrom.requiredMsg",
    },
    dateTo: {
      name: "dateTo",
      label: "requestForm.dateTo.label",
    },
    timeFrom: {
      name: "timeFrom",
      label: "requestForm.timeFrom.label",
    },
    timeTo: {
      name: "timeTo",
      label: "requestForm.timeTo.label",
    },
    description: {
      name: "description",
      label: "requestForm.description.label",
      requiredMessage: "requestForm.description.requiredMessage",
    },
    attachment: {
      name: "attachment",
      label: "requestForm.attachment.label",
    },
    rejectionReason: {
      name: "rejectionReason",
      label: "requestForm.rejectionReason.label",
    },
    approvedBy: {
      name: "approvedBy",
      label: "requestForm.approvedBy.label",
    },
    approvalDate: {
      name: "approvalDate",
      label: "requestForm.approvalDate.label",
    },
    rejectedBy: {
      name: "rejectedBy",
      label: "requestForm.rejectedBy.label",
    },
    rejectionDate: {
      name: "rejectionDate",
      label: "requestForm.rejectionDate.label",
    },
    cancelledBy: {
      name: "cancelledBy",
      label: "requestForm.cancelledBy.label",
    },
    cancellationDate: {
      name: "cancellationDate",
      label: "requestForm.cancellationDate.label",
    },
    workType: {
      name: "workType",
      label: "requestForm.workType.label",
      requiredMessage: "requestForm.workType.requiredMessage",
    },
    location: {
      name: "location",
      label: "requestForm.location.label",
      requiredMessage: "requestForm.location.requiredMessage",
    },
  },
};

export default form;
