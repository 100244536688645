import React, { useState } from "react";

//components
import {
  Grid,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Card,
  Typography,
  CardContent,
  CardMedia,
} from "@mui/material";

//translation
import { useTranslation } from "react-i18next";

import useAuth from "../../../../../hooks/useAuth";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";

import Tooltip from "../../../../../components/Tooltip";
import AutoComplete from "../../../../../components/AutoComplete";
import {
  DeleteIcon,
  EyeIcon,
  HelpIcon,
  PDFIcon,
  UploadIcon,
  WarningIcon,
} from "../../../../../components/Icon";
import ConfirmationModal from "../../../../../components/Modal/ConfirmationModal";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import TextField from "../../../../../components/TextFields/TextField";

import { GET_ALL_EMPLOYEE_PTOS } from "../../../../../graphql/pto";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../../graphql/employee";
import {
  CANCEL_TO_REQUEST,
  UPDATE_TO_REQUEST,
} from "../../../../../graphql/toRequest";

import {
  DesktopDatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { day_month_year } from "../../../../../utils/timezones";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import colors from "../../../../../assets/theme/light/colors";

import ReactFileReader from "react-file-reader";
import { base64StringtoFile } from "../../../../../utils/base64StringtoFile";

import { enqueueSnackbar } from "notistack";

import Mnemonic from "../../../../../Mnemonics.json";

import "./style.css";
import CustomButton from "../../../../../components/CustomButton";
import fileImage from "../../../../../assets/images/file.png";

import { v4 as uuidv4 } from "uuid";

function PTOForm({
  formData,
  reqID,
  refetch,
  requested,
  remaining,
  setDateError,
  dateError,
  setShowSideInfo,
  setSideInfoData,
  setRemaining,
  handleSideInfoData,
  paid,
  attachmentName,
  setAttachmentName,
}) {
  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchPTOS, setFetchPTOS] = useState(false);
  const [fetchAdmins, setFetchAdmins] = useState(false);

  const [rejectReasonMandatory, setRejectReasonMandatory] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [type, setType] = useState("");

  const [status, setStatus] = useState(false);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [contentText, setContentText] = useState("");
  const [newlyUploaded, setNewlyUploaded] = useState(false);

  const readOnly = reqID !== null && reqID !== undefined && reqID !== "";

  const {
    storeUserGuid,
    storeUserTenant,
    storeUserManagerGuid,
    storeUserWorkshiftDays,
  } = useAuth();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    isSubmitting,
    setErrors,
  } = formData;

  const {
    employee,
    receiver,
    requestType,
    requestStatus,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    attachment,
    description,
    rejectionReason,
  } = formField;

  const {
    employee: employeeV,
    receiver: receiverV,
    requestType: requestTypeV,
    requestStatus: requestStatusV,
    description: descriptionV,
    dateFrom: dateFromV,
    dateTo: dateToV,
    timeFrom: timeFromV,
    timeTo: timeToV,
    attachment: attachmentV,
    rejectionReason: rejectionReasonV,
  } = values;

  const { cfunction: changeStatus, loading: changeStatusLoading } =
    useMutationAuth(UPDATE_TO_REQUEST, "UpdateTORequestStatus");

  const { cfunction: cancelTORequest, loading: cancelTORequestLoading } =
    useMutationAuth(CANCEL_TO_REQUEST, "CancelTORequest");

  const { data: ptosData, loading: ptosLoading } = useQueryAuth(
    GET_ALL_EMPLOYEE_PTOS,
    "GetEmployeePTOsList",
    {
      variables: {
        model: {
          enableFilters: false,
          employeeGuid: employeeV?.employeeGuid || storeUserGuid,
          userID: storeUserGuid,
        },
      },
      skip: !fetchPTOS,
      isWait: fetchPTOS,
    }
  );

  const { data: employeesData, loading: adminsLoading } = useQueryAuth(
    GET_ALL_EMPLOYEES_DROPDOWN,
    "GetCompanyUsersList",
    {
      variables: {
        model: {
          enableFilters: false,
          tenantID: storeUserTenant,
          userID: storeUserGuid,
          adminsList: true,
        },
      },
      skip: !fetchAdmins,
      isWait: fetchAdmins,
    }
  );

  const ptosList =
    ptosData?.GetEmployeePTOsList?.employeePTOsListModel?.employeePTOs || [];
  const adminsList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const handleFiles = (files, setFieldValue) => {
    setAttachmentName(files.fileList[0].name);
    setLoading(true);
    setType(files.fileList[0]?.type);
    setNewlyUploaded(true);
    const attach = base64StringtoFile(files.base64, files.fileList[0].name);
    setImage(files);
    setTimeout(() => {
      setFieldValue(attachment.name, attach);
      setLoading(false);
    }, 500);
  };

  function changeRequestStatus(status) {
    setAcceptLoading(status);
    setRejectLoading(!status);

    changeStatus(
      {
        variables: {
          model: {
            approve: status,
            toRequestGuid: reqID,
            rejectionReason: rejectionReasonV,
            userID: storeUserGuid,
          },
        },
      },
      (response) => {
        if (
          response["UpdateTORequestStatus"].errorCode !== null &&
          response["UpdateTORequestStatus"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateTORequestStatus"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setAcceptLoading(false);
          setRejectLoading(false);
        } else {
          enqueueSnackbar(t("requestForm.submitted.successfully"), {
            variant: "success",
          });
          refetch();
          sessionStorage.removeItem("unsavedChanges");
          setAcceptLoading(false);
          setRejectLoading(false);
          setOpenDialog(false);
          setOpenWarning(false);
        }
      },
      (error) => {
        setAcceptLoading(false);
        setRejectLoading(false);
      }
    );
  }

  function handleCancelTORequest() {
    setCancelLoading(true);
    cancelTORequest(
      {
        variables: {
          model: {
            toRequestGuid: reqID,
            userID: storeUserGuid,
          },
        },
      },
      (response) => {
        const data = response?.CancelTORequest?.toRequestCancellation || {};
        refetch();
        // navigate(my_requests);
      },
      (error) => {
        setCancelLoading(false);
      }
    );
    setCancelLoading(false);
  }

  function swipe() {
    var image = document.getElementById("attachment");
    var url = image.getAttribute("src");
    window.open(url);
  }

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );
  const customModalButtonsWarning = () => (
    <>
      <CustomButton onClick={handleSaveWarning} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleCloseWarning} label={t("dialog.cancel")} />
      </div>
    </>
  );

  const handleOpenDialog = (status) => {
    setRejectReasonMandatory(null);
    setStatus(status);
    status === null
      ? setContentText(t("pto.dialog.confirm.cancel"))
      : status === true
      ? setContentText(t("pto.dialog.confirm.approve"))
      : setContentText(t("pto.dialog.confirm.reject"));
    setOpenDialog(true);
  };

  const handleOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleSave = () => {
    status === null
      ? handleCancelTORequest()
      : status === true
      ? requested > remaining && paid
        ? handleOpenWarning()
        : changeRequestStatus(status)
      : changeRequestStatus(status);
    handleClose();
  };

  const handleSaveWarning = () => {
    changeRequestStatus(status);
  };

  function disableDates(date) {
    const oneDayBefore = new Date(dateFromV);
    oneDayBefore.setDate(oneDayBefore?.getDate());
    return date < oneDayBefore;
  }

  return (
    <>
      <ConfirmationModal
        contentText={contentText}
        title={t("dialog.confirm")}
        icon={<HelpIcon color={colors.light_logo_blue} />}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <ConfirmationModal
        contentText={t("requestApproval.warningMsg")}
        title={t("dialog.warning")}
        icon={<WarningIcon color={colors.red_color} />}
        openDialog={openWarning}
        handleClose={handleCloseWarning}
        customButtons={customModalButtonsWarning}
        t={t}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AutoComplete
            autoFocus
            disabled={
              reqID &&
              requestStatusV?.mnemonic !==
                Mnemonic.RequestStatuses.SentForApproval
            }
            options={ptosList}
            getOptionLabel={(option) => (option ? option.pto.description : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.pto.description}
              </li>
            )}
            identifier={"employeePTOGuid"}
            loading={ptosLoading}
            value={requestTypeV}
            onOpen={() => setFetchPTOS(true)}
            onClose={() => setFetchPTOS(false)}
            onChange={(e, value) => {
              setFieldValue(requestType.name, value);
              value ? setShowSideInfo(true) : setShowSideInfo(false);
              const remaining = value?.numberOfDays - value?.daysUsed;
              setRemaining(remaining);
              handleSideInfoData(value, value?.daysUsed);
              // setSideInfoData({
              //   "requestForm.remaining": remaining.toString() + " " + value?.pto?.dateUnit?.description,
              //   "requestForm.daysUsed": value?.daysUsed.toString() + " " + value?.pto?.dateUnit?.description,

              //   "requestForm.numberOfDays": value?.numberOfDays.toString() + " " + value?.pto?.dateUnit?.description,

              // });
            }}
            label={t(requestType.label)}
            name={requestType.name}
            formData={formData}
            required
          />
        </Grid>
        {!storeUserManagerGuid ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <AutoComplete
              disabled={readOnly}
              options={adminsList}
              getOptionLabel={(option) =>
                option ? option.firstName + " " + option.lastName : ""
              }
              renderOption={(props, option) => (
                <li {...props} key={uuidv4()}>
                  {option.firstName + " " + option.lastName}
                </li>
              )}
              identifier={"employeeGuid"}
              loading={adminsLoading}
              value={receiverV}
              onOpen={() => setFetchAdmins(true)}
              onClose={() => setFetchAdmins(false)}
              onChange={(e, value) => {
                setFieldValue(receiver.name, value);
              }}
              label={t(receiver.label)}
              name={receiver.name}
              formData={formData}
              required
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
        )}

        <Grid
          className="timesheet-date"
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiFormLabel-root": {
              fontSize: "14px !important",
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              fullWidth
              disabled={readOnly}
              format={
                day_month_year.includes(userTimezone) === true
                  ? "dd-MM-yyyy"
                  : "MM-dd-yyyy"
              }
              label={t(dateFrom.label)}
              value={dateFromV}
              onChange={(newValue) => {
                setFieldValue(dateFrom.name, newValue);

                if (!newValue) {
                  setFieldValue(timeFrom.name, null);
                  setFieldValue(timeTo.name, null);
                } else {
                  const day =
                    newValue.getDay() >= 1
                      ? storeUserWorkshiftDays[newValue.getDay() - 1]
                      : storeUserWorkshiftDays[6];
                  // const day = {
                  //   startHour: "2024-05-20T08:00:00.000Z",
                  //   endHour: "2024-05-20T17:00:00.000Z",
                  //   isWorkDay: true
                  // }
                  if (day?.isWorkDay === false)
                    setDateError(t("non.working.day"));
                  else setDateError(null);
                  if (day?.isWorkDay === true) {
                    const startHour = day?.startHour
                      ? day?.startHour.includes("Z")
                        ? new Date(day?.startHour.slice(0, -1))
                        : new Date(day?.startHour)
                      : null;
                    setFieldValue(timeFrom.name, startHour);
                  }
                  if (
                    day?.isWorkDay === true &&
                    requestTypeV?.pto?.dateUnit?.mnemonic !==
                      Mnemonic.DateUnits.halfDays
                  ) {
                    const endHour = day?.endHour
                      ? day?.endHour.includes("Z")
                        ? new Date(day?.endHour.slice(0, -1))
                        : new Date(day?.endHour)
                      : null;
                    setFieldValue(timeTo.name, endHour);
                  }
                }
              }}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                dialog: {
                  disablePortal: true,
                  style: { zIndex: 1000000 },
                },
                textField: {
                  required: true,
                  size: "medium",
                  variant: "standard",
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },

                  error:
                    (touched[dateFrom.name] && errors[dateFrom.name]) ||
                    dateError,
                  helperText:
                    (touched[dateFrom.name] &&
                      errors[dateFrom.name] &&
                      t(errors[dateFrom.name])) ||
                    dateError,

                  onBlur: (e) =>
                    setTouched({
                      ...touched,
                      [dateFrom.name]: true,
                    }),
                },
              }}
              sx={{
                width: "100% !important",
                fontFamily: "poppins",
                "& .MuiInputBase-input": {
                  fontSize: "14px !important",
                },
                "& .MuiInputAdornment-root .MuiButtonBase-root": {
                  fontSize: "14px !important",
                  marginRight: "-5px !important",
                },
                "& .MuiInputBase-root": {
                  fontSize: "14px !important",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          className="timesheet-date"
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiFormLabel-root": {
              fontSize: "14px !important",
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={
                readOnly ||
                dateError ||
                requestTypeV?.pto?.dateUnit?.mnemonic ===
                  Mnemonic.DateUnits.hours
              }
              fullWidth
              shouldDisableDate={disableDates}
              format={
                day_month_year.includes(userTimezone) === true
                  ? "dd-MM-yyyy"
                  : "MM-dd-yyyy"
              }
              label={t(dateTo.label)}
              value={dateToV}
              onChange={(newValue) => {
                setFieldValue(dateTo.name, newValue);
                setFieldValue(timeFrom.name, null);
                setFieldValue(timeTo.name, null);
              }}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                dialog: {
                  disablePortal: true,
                  style: { zIndex: 1000000 },
                },
                textField: {
                  size: "medium",
                  variant: "standard",
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },

                  error: touched[dateTo.name] && errors[dateTo.name],
                  helperText:
                    touched[dateTo.name] &&
                    errors[dateTo.name] &&
                    t(errors[dateTo.name]),

                  onBlur: (e) =>
                    setTouched({
                      ...touched,
                      [dateTo.name]: true,
                    }),
                },
              }}
              sx={{
                width: "100% !important",
                fontFamily: "poppins",
                "& .MuiInputBase-input": {
                  fontSize: "14px !important",
                },
                "& .MuiInputAdornment-root .MuiButtonBase-root": {
                  fontSize: "14px !important",
                  marginRight: "-5px !important",
                },
                "& .MuiInputBase-root": {
                  fontSize: "14px !important",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderBottom: "1px solid #949494 !important",
              borderRadius: "0 !important",
            },
            "& .MuiOutlinedInput-input": {
              padding: "8.5px 0 !important",
            },
            "& .MuiInputLabel-root": {
              marginLeft: "-15px !important",
              fontSize: "14px !important",
            },
            "& .MuiInputBase-input": { fontSize: "14px !important" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t(timeFrom.label)}
              value={timeFromV}
              ampm={false}
              disabled={readOnly || dateToV || !dateFromV || dateError}
              maxTime={timeToV}
              variant="standard"
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },
                  onBlur: (e) => {
                    setTouched({
                      ...touched,
                      [timeFrom.name]: true,
                    });
                  },
                  error: touched[timeFrom.name] && errors[timeFrom.name],
                  helperText:
                    touched[timeFrom.name] &&
                    errors[timeFrom.name] &&
                    t(errors[timeFrom.name]),
                },
              }}
              onChange={(newValue) => {
                setFieldValue(timeFrom.name, newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderBottom: "1px solid #949494 !important",
              borderRadius: "0 !important",
            },
            "& .MuiOutlinedInput-input": {
              padding: "8.5px 0 !important",
            },
            "& .MuiInputLabel-root": {
              marginLeft: "-15px !important",
              fontSize: "14px !important",
            },
            "& .MuiInputBase-input": { fontSize: "14px !important" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t(timeTo.label)}
              value={timeToV}
              minTime={timeFromV}
              disabled={
                readOnly ||
                dateToV ||
                !timeFromV ||
                dateError ||
                requestTypeV?.pto?.dateUnit?.mnemonic ===
                  Mnemonic.DateUnits.halfDays
              }
              // className="date_picker_styles"
              ampm={false}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },
                  onBlur: (e) => {
                    setTouched({
                      ...touched,
                      [timeTo.name]: true,
                    });
                  },
                  error: touched[timeTo.name] && errors[timeTo.name],
                  helperText:
                    touched[timeTo.name] &&
                    errors[timeTo.name] &&
                    t(errors[timeTo.name]),
                },
              }}
              onChange={(newValue) => {
                setFieldValue(timeTo.name, newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ paddingTop: "10px !important" }}
        >
          <TextField
            fullWidth
            value={descriptionV}
            multiline
            onChange={(e) => {
              const newValue = e.target.value;
              setFieldValue(description.name, newValue);
            }}
            label={t(description.label)}
            name={description.name}
            formData={formData}
            disabled={readOnly}
            required
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              ".Mui-disabled": { color: "#636563" },
              "& .MuiFormLabel-root": { fontSize: "14px !important" },
            }}
          />
        </Grid>

        {reqID !== undefined && reqID !== null && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ paddingTop: "6px !important" }}
          >
            <TextField
              fullWidth
              value={rejectionReasonV}
              onChange={(e) => {
                setFieldValue(rejectionReason.name, e.target.value);
              }}
              label={t(rejectionReason.label)}
              name={rejectionReason.name}
              formData={formData}
              required={rejectReasonMandatory}
              disabled={
                employeeV?.employeeGuid === storeUserGuid ||
                requestStatusV?.mnemonic !==
                  Mnemonic.RequestStatuses.SentForApproval
              }
              sx={{
                ".MuiInputBase-input": { fontSize: "14px !important" },
                ".MuiInputLabel-root": { fontSize: "14px !important" },
                ".Mui-disabled": {
                  color: "#636563",
                  WebkitTextFillColor: "#636563",
                },
              }}
              error={
                (touched[rejectionReason.name] &&
                  errors[rejectionReason.name]) ||
                rejectReasonMandatory
              }
              helperText={
                (touched[rejectionReason.name] &&
                  errors[rejectionReason.name] &&
                  t(errors[rejectionReason.name])) ||
                rejectReasonMandatory
              }
            />
          </Grid>
        )}
        {attachmentV && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ paddingTop: "6px !important" }}
          >
            <Box
              sx={{
                // border: "1px dashed #949494",
                // height: "60px",
                padding: "20px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: reqID && !attachmentV ? "initial " : "pointer",
                marginTop: "20px",
                width: "100%",
              }}
            >
              {
                <>
                  {" "}
                  {loading ? (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: "10px" }}
                    />
                  ) : (
                    <>
                      {(image || (reqID && attachmentV)) && (
                        //   <div
                        //     id="attachment-parent"
                        //     style={{ position: "relative", marginRight: "20px" }}
                        //   >
                        //     {type === "application/pdf" ? (
                        //       <PDFIcon />
                        //     ) : (
                        //       <img
                        //         id="attachment"
                        //         src={image?.base64 || attachmentV}
                        //         width={100}
                        //         height={100}
                        //         style={{
                        //           objectFit: "cover",
                        //           marginRight: "10px",
                        //         }}
                        //         onClick={() => {
                        //           if (reqID && attachmentV) swipe();
                        //         }}
                        //       />
                        //     )}
                        //     {reqID && (
                        //       <IconButton
                        //         className={`my-icon-button-eye ${
                        //           reqID ? "my-icon-center" : ""
                        //         }`}
                        //         onClick={swipe}
                        //       >
                        //         <EyeIcon color={colors.pure_white} />
                        //       </IconButton>
                        //     )}
                        //     {!(reqID && attachmentV) && (
                        //       <IconButton
                        //         className={`my-icon-button-delete ${
                        //           type === "application/pdf"
                        //             ? "my-icon-button-delete-pdf"
                        //             : "my-icon-button-delete-image"
                        //         } ${!reqID ? "my-icon-center" : ""}`}
                        //         onClick={() => {
                        //           setImage(null);
                        //           setFieldValue(attachment.name, "");
                        //         }}
                        //       >
                        //         <DeleteIcon color={colors.pure_white} />
                        //       </IconButton>
                        //     )}
                        //   </div>
                        <Card
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: reqID ? "8px 20px" : "0 0 0 20px",
                            width: "inherit",
                            boxShadow:
                              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important",
                          }}
                        >
                          <CardMedia
                            component="img"
                            sx={{ height: "100%", width: 80 }}
                            image={fileImage}
                            onClick={() => window.open(attachmentV)}
                            alt="Live from space album cover"
                          />
                          <CardContent
                            id="attachment-parent"
                            sx={{
                              width: "100%",
                              position: "relative",
                              paddingBottom: "16px !important",
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={11}
                                lg={11}
                                xl={11}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {newlyUploaded ? (
                                  <Typography component="div" variant="body1">
                                    {attachmentName}
                                  </Typography>
                                ) : (
                                  <Typography
                                    component="div"
                                    variant="body1"
                                    sx={{ cursor: "pointer", width: "60%" }}
                                    onClick={() => window.open(attachmentV)}
                                  >
                                    {attachmentName}
                                  </Typography>
                                )}
                              </Grid>
                              {!reqID && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <IconButton
                                    className={`my-icon-button-delete-aligned`}
                                    onClick={() => {
                                      setImage(null);
                                      setFieldValue(attachment.name, "");
                                    }}
                                  >
                                    <DeleteIcon
                                      color={colors.icon_button_grey}
                                    />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                    </>
                  )}
                </>
              }
            </Box>
          </Grid>
        )}
        {!reqID && (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              mt={!attachmentV ? 2 : null}
              ml={2}
            >
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="flex-start"
              >
                <ReactFileReader
                  fileTypes={[".png", ".jpg", ".pdf"]}
                  base64={true}
                  handleFiles={(e) => handleFiles(e, setFieldValue)}
                >
                  <IALoadingButton
                    sx={{
                      ".MuiLoadingButton-root": {
                        BackgroundColor: "inherit !important",
                      },
                    }}
                    tooltipLabel={t("requestForm.uploadFile")}
                  >
                    <UploadIcon width="25" height="25" color="#ffffff" />
                  </IALoadingButton>
                </ReactFileReader>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              mt={!attachmentV ? 2 : null}
            >
              <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
                <IALoadingButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  label={t("requestForm.submit")}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid mt={3} xs={12} sm={12} md={12} lg={12} xl={12}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {requestStatusV?.mnemonic ===
              Mnemonic.RequestStatuses.SentForApproval &&
              receiverV?.employeeGuid === storeUserGuid && (
                <>
                  <IALoadingButton
                    loading={acceptLoading}
                    disabled={rejectLoading || cancelLoading}
                    label={t("requestForm.Approve")}
                    onClick={() => handleOpenDialog(true)}
                    lightBlue
                  />
                  <IALoadingButton
                    loading={rejectLoading}
                    disabled={acceptLoading || cancelLoading}
                    error
                    label={t("requestForm.Reject")}
                    onClick={() => {
                      if (
                        rejectionReasonV === null ||
                        rejectionReasonV === undefined ||
                        rejectionReasonV === ""
                      )
                        setRejectReasonMandatory(
                          t("requestForm.reason.requiredMsg")
                        );
                      else handleOpenDialog(false);
                    }}
                  />
                </>
              )}

            {((employeeV?.employeeGuid === storeUserGuid &&
              requestStatusV?.mnemonic ===
                Mnemonic.RequestStatuses.SentForApproval) ||
              (receiverV?.employeeGuid === storeUserGuid &&
                requestStatusV?.mnemonic ===
                  Mnemonic.RequestStatuses.Approved)) && (
              <Grid container>
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <IALoadingButton
                    loading={cancelLoading}
                    disabled={cancelLoading || acceptLoading || rejectLoading}
                    // secondary
                    label={t("requestForm.Cancel")}
                    onClick={() => handleOpenDialog(null)}
                  />
                </Box>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default PTOForm;
