import React from "react";
import { CheckIcon, UncheckIcon } from "../Icon";

const IconBadge = ({ success = false, status = null }) => {

  if (success === true || success === status)
    return (
      <CheckIcon color="#00b341" />
    );

  return (
    <UncheckIcon color="#c7c7c5" />
  );
};

export default IconBadge;