const tableStyles = {

  header: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: 'light',
    padding: '1rem 0.5rem !important',
    height: '50px',
    position: 'sticky',
    top: 0,
    // zIndex: 1,
    backgroundColor: 'white',
  },

  // body: {
  //   maxHeight: '430px',

  // },
  pagination: {
    fontSize: '12px',
  },
  

};

export default tableStyles;