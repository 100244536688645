import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const CustomStatus = (props) => {
  const { requestStatus, width } = props;
  const { requestStatusColors } = colorsLight;

  if (!requestStatus) return null;
  return (
    <div
      style={{
        width: width ? width : "100px",
        padding: "5px",
        borderRadius: "5px",
        fontFamily: "Poppins  !important",
        margin: "auto",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        ...requestStatusColors[requestStatus?.mnemonic],
      }}
    >
      {requestStatus?.description}
    </div>
  );
 
};

export default CustomStatus;
