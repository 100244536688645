export default {
  formID: "profile",
  formField: {
    newPassword: {
      name: "newPassword",
      label: "profileForm.newPassword.label",
      requiredMessage: "profileForm.newPassword.requiredMessage",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "profileForm.confirmPassword.label",
      requiredMessage: "profileForm.confirmPassword.requiredMessage",
    },
  },
};
