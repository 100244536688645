import React, { useState, useEffect, useCallback } from "react";

//routes
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";


//graphql
import {
  ASSIGN_PROJECTS_TO_RESOURCE,
  DELETE_PROJECTS,
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_STATUSES,
  UNASSIGN_PROJECTS_TO_RESOURCE,
} from "../../../../graphql/project";
//graphql
import {
  DELETE_PROJECT_PERMISSIONS_LIST,
} from "../../../../graphql/projectPermission";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress, Card, Modal, IconButton } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local, { formatDateTimeToString } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import EmployeeTable from "../../Teams/Teams-list/EmployeeTable";
import ProjectsTable from "./ProjectsTable";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";
import ProjectPermission from "./ProjectPermissions";
import { CloseIcon } from "../../../../components/Icon";

const ProjectList = ({ myCompany = false, fromTenant = false, fromFavorites = false, myEmployees = false, fromResource = false, setDisplay = () => {}, refetchProjects=()=>{} }) => {
  const { tName, tID, eID, } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  
  const [fetchStatuses, setFetchStatuses] = useState(false);
  const [statusList, setStatusList] = useState([]);

  const {
    tenant_project_form,
    new_tenant_project,
    my_projects_form,
    my_new_project,
    my_favorite_projects_form,
    my_new_favorite_project
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageTimeEntry, manageUsers } = userRoles;
  const [displayTable, setDisplayTable] = useState(false);

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [filterState, setFilterState] = useState([]);
  const [projectGuid, setProjectGuid] = useState("");
  const [displayProjectMembers, setDisplayProjectMembers] = useState(false);
  
  const {
    data: statusesList,
    loading: statusLoading,
    tokenChange: statusWait,
  } = useQueryAuth(GET_ALL_PROJECTS_STATUSES, "GetProjectStatuses");

  useEffect(() => {
    if(statusesList) 
      setStatusList(statusesList?.GetProjectStatuses?.projectStatuses || []);
  }, [statusesList])
  
  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.Projects,
          userID: storeUserGuid,
        },
      },
      skip: statusWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, null, null, null, formatDateTimeToString)
          );

          const cols = parsed.columns;
          const filteredColumns = cols.filter((c) => c.id == "Title"  || c.id == "Company"  || c.id == "Location");


          if (myEmployees || fromResource) {
            {
              let cols = filteredColumns;

              cols.map(c => {
                c = removeNullProps(c);
                return c;
              })
              setColumns(cols);
            }
          } else {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          
          // if (parsed.columns) setColumns(parsed.columns);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const filtersObject = [
    {
      type: "text",
      label: "project.filters.label.title",
      name: "title",
    },
    {
      type: "text",
      label: "project.filters.label.description",
      name: "description",
    },
    {
      type: "text",
      label: "project.filters.label.company",
      name: "company",
    },
    {
      label: "project.list.filter.isOpen",
      name: "projectStatusesIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: statusList,
      access: "projectStatusGuid",
      loading: statusLoading,
      fetchData: setFetchStatuses,
    },
  ];

  const customButtons = [
    {
      label: "projectList.addProject",
       handleClick: () => setDisplayTable(true),
      condition: (!selected || selected.length === 0) && manageUsers === "M" ,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important"
          }}
        />
      ),
    },
  
  ];

  

   const customButtons1 = [
    {
      label: "employeeForm.addButton",
      handleClick: () => handleAddProject(selected),
      condition: selected && selected.length > 0 && manageUsers === "M",
    }
  ];

  useEffect(() => {
    const objectProjectStatus = filterState.findIndex(
      (obj) => obj.property === "projectStatusesIDs"
    );


    const projectStatusGuidsArray = statusList
      ?.filter((stat) => stat.mnemonic !== Mnemonic.ProjectStatuses.Closed)
      .map((stat) => stat.projectStatusGuid);
     
    if (statusList.length > 0) {
      if (objectProjectStatus < 0) {
        setDefaultFilters({...defaultFilters, projectStatusesIDs: projectStatusGuidsArray });
        const projectStatusIDsObj = { value: projectStatusGuidsArray, property: "projectStatusesIDs" };
        setFilterState([...filterState, projectStatusIDsObj]);
      } else {
        // const val =
        //   filterState[objectProjectStatus].value == null
        //     ? null
        //     : [filterState[objectProjectStatus].value];
        // const projectStatusIDsObj = { value: val, property: "projectStatusesIDs" };
        // setDefaultFilters({...defaultFilters, projectStatusesIDs: val });
        // setFilterState([...filterState, projectStatusIDsObj]);
      }
    }

  }, [statusList?.length]);

  const { mfunction: getAllProjects, loading , refetch : refetchData} = useLazyQueryAuth(
    GET_ALL_PROJECTS,
    "GetAllProjects"
  );


  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllProjects(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              userID: storeUserGuid,
              tenantID: fromTenant ? null : storeUserTenant,
              filters: filters,
              employeeID: eID,
              userProjects: myEmployees ? true : false,
              favorites : window.location.href.includes("favorite"),
              unassignedProjects : fromResource ? true : false,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if ( !columnsWait && !statusWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, statusWait, fetchTableData, re, fromFavorites]);

  const rows =
    data &&
    data.GetAllProjects &&
    data.GetAllProjects.projectsList &&
    data.GetAllProjects.projectsList.projects
      ? data.GetAllProjects.projectsList.projects
      : [];
  const numberOfRecords =
    data &&
    data.GetAllProjects &&
    data.GetAllProjects.projectsList &&
    data.GetAllProjects.projectsList.totalNumberOfRecords
      ? data.GetAllProjects.projectsList.totalNumberOfRecords
      : 0;
  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_PROJECTS,
    "DeleteListOfProjects"
  );

  const { mfunction: deleteProject, loading: deleteProjectLoading } = useMutationAuth(
    UNASSIGN_PROJECTS_TO_RESOURCE,
    "DeleteProjectPermissionsByProjects"
  );

  

  const handleDelete = () => {
    myEmployees ? deleteProject(
      {
        variables: {
          model: {
            employeeGuid: eID,
            projectIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("projects.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    ) : deleteRecord(
      {
        variables: {
          model: {
            listIds: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("projects.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);

      }
    );
  };
  
  const {
    mfunction: assignProjectsToResourceFun,
    loading: assignProjectsToResourceFunLoading,
  } = useMutationAuth(ASSIGN_PROJECTS_TO_RESOURCE, "AssignProjectsToResource");

  
  function handleAddProject(array) {

      assignProjectsToResourceFun(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              projectsIDs: selected,
              resourceGuid: eID,
            },
          },
        },
        (data) => {
          enqueueSnackbar(t("assignResourcesToProject.success"), {
            variant: "success",
          });
          setRe((val) => !val);
          setDisplayTable(false);
          setDisplay(false);
refetchProjects()
        }
      );
  }

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    hideEditColumns: fromResource,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "project.list.search.label",
    refetch,
    removeFilters : fromResource,
    tableMnemonic: Mnemonic.Tables.Projects,
    filtersObject,
    handleOpenModal:  myEmployees ?  (row) => handleOpenModal(row) : null,

    generateRoute:  (row) => fromResource || myEmployees ? {} :
    !fromTenant
      ? fromFavorites
        ? my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
              row?.projectGuid
            )}`
          )
        : my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
              row?.projectGuid
            )}`
          )
      : tenant_project_form
          .replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
          .replace(
            "/:pName/:pID",
            `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
              row?.projectGuid
            )}`
          ),
  
    newText: !fromResource && !myEmployees && "project.list.new.label",
    newLink:  !myEmployees && !fromTenant
      ? fromFavorites ?( my_new_favorite_project) :(my_new_project)
      : new_tenant_project.replace(
          "/:tName/:tID",
          `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
        ),
    deleteText: fromResource ? null : "project.list.delete.label",
    customButtons : myEmployees ? customButtons : fromResource ? customButtons1 : !customButtons ,
    handleDelete: fromResource ? null : handleDelete,
    deleteLoading,
    readOnly: manageTimeEntry !== "M",
  };

  const handleOpenModal = (row) => {
    setProjectGuid(row?.projectGuid);
    setDisplayProjectMembers(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setDisplayProjectMembers(false);
        setProjectGuid(null);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  return (
    <div>
       {myEmployees && displayTable ? (
        <ProjectsTable
          display={displayTable}
          initialVal={[]}
           setDisplay={setDisplayTable}
           refetchProjects={refetchData}
        />
      ) : null}
      <Modal
        open={displayProjectMembers}
        onClose={() =>{ setDisplay(false);setDisplayProjectMembers(false)}}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ContainerCard
          sx={{ padding: "10px 20px 20px", width: "70%", height: "fitContent" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "1rem",
              width: "100%",
            }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {        
                setDisplay(false);
                setDisplayProjectMembers(false)}}
              aria-label="close"
            >
            <CloseIcon color={"#000000"} width={'20'} stroke={'1'} />
            </IconButton>
          </div>
          <ProjectPermission projectGuid={projectGuid} fromProfile display={displayTable} refetchProjects={refetchData} setDisplay={setDisplayTable} fromResource/>
        </ContainerCard>
      </Modal>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"projectGuid"} hasCheckbox heightClassName={myEmployees ?"tableHeightProjectsEmployees" : ""} fromEmployee ={myEmployees ? true : false}/>
      )}
    </div>
  );
};

export default ProjectList;
