import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const Status = (props) => {
  const { status,fontSize } = props;
  const { taskStateColors } = colorsLight;
  
  if (!status) return null;
  return (
    
    <div className="collapsible-table-status-cell" style={{ fontSize: fontSize }}>
        <div
        style={{
            borderRadius: "50%",
            width: "7px",
            height: "7px",
            marginRight: "5px",
            backgroundColor: taskStateColors[status?.taskState?.mnemonic]?.color,
            
        }}
        >
        </div>
      {status?.description}
  </div>
  );
 
};

export default Status;