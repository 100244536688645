import checkout from "./form";

const {
  formField: {
    name,
    address,
    latitude,
    longitude,
    radius,
    UnitOfMeasure,
    country,
    isOnSite,
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [address.name]: "",
  [latitude.name]: "",
  [longitude.name]: "",
  [radius.name]: null,
  [UnitOfMeasure.name]: null,
  [country.name]: null,
  [isOnSite.name]: false,
};

export default initialValues;