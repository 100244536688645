import { gql } from "@apollo/client";

export const GET_ATTENDANCE_REQUEST_BY_ID = gql`
  query ($id: UUID!) {
    GetAttendanceRequestByID(attendanceRequestGuid: $id) {
      attendanceRequest {
        attendanceRequestGuid
        rejectionReason
        requestDate
        description
        date
        timeFrom
        timeTo
        employee {
          employeeGuid
          firstName
          lastName
        }
        receiver {
          employeeGuid
          firstName
          lastName
        }
        cancellationDate
        cancelledBy {
          firstName
          lastName
          email
        }
        rejectionDate
        rejectionReason
        rejectedBy {
          firstName
          lastName
          email
        }
        approvalDate
        approvedBy {
          firstName
          lastName
          email
        }
        requestStatus {
          description
          mnemonic
        }
        workType {
          workTypeGuid
          description
          mnemonic
        }
        location {
          locationGuid
          name
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CANCEL_ATTENDANCE_REQUEST = gql`
  mutation CancelAttendanceRequest($model: AttendanceCancelRequestInput!) {
    CancelAttendanceRequest(model: $model) {
      cancelAttendanceRequestResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_ATTENDANCE_REQUEST = gql`
  mutation UpdateAttendanceRequestStatus(
    $model: UpdateAttendanceRequestStatusInput!
  ) {
    UpdateAttendanceRequestStatus(model: $model) {
      updateAttendanceRequestStatusResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
