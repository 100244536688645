import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function LocationTabs({ fromTenant = false, myTenant, fromCompany = false }) {
  const { lName, lID, tName, tID, cName, cID } = useParams();

  const {
    tenant_locations_form,
    my_locations_form,
    my_company_location_form,
    my_locations_wifi
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !lName ||
    !lID ||
    lName === "undefined" ||
    lName === "null" ||
    lName === "" ||
    lID === "null" ||
    lID === "undefined" ||
    lID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(lName);
  const encodedID = encodeURIComponent(lID);

  const TabLinks = [
    {
      key: 1,
      Header: t("locationTabs.form"),
      Link: fromTenant
        ? tenant_locations_form
            .replace("/:tName/:tID", `/${tName}/${tID}`)
            .replace(
              "/:lName/:lID",
              `/${encodeURIComponent(lName)}/${encodeURIComponent(lID)}`
            )
        : myTenant
        ? my_locations_form.replace(
            "/:lName/:lID",
            `/${encodeURIComponent(lName)}/${encodeURIComponent(lID)}`
          )
        : fromCompany
        ? my_company_location_form
            .replace("/:cName/:cID", `/${encodeURIComponent(cName)}/${encodeURIComponent(cID)}`)
            .replace("/:lName/:lID", `/${encodedName}/${encodedID}`)
        : "",
    },
    {
      key: 2,
      Header: t("locationTabs.accessPoints"),
      Link: 
          my_locations_wifi
            .replace("/:lName/:lID", `/${encodedName}/${encodedID}`),
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default LocationTabs;
