import { NordicWalkingTwoTone } from "@mui/icons-material";
import checkout from "./form";
const nowDate = new Date();
const {
  formField: {
    description,
    releaseDate,
    version
  },
} = checkout;

const initialValues = {
  [description.name]:"",
  [releaseDate.name]: nowDate,
  [version.name]:""

};

export default initialValues;