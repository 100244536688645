import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";
//components
import { CircularProgress } from "@mui/material";
import GridTable from "../../../../components/Tables/GridTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  GET_ALL_ACCESS_POINTS,
  CREATE_ACCESS_POINT,
  UPDATE_ACCESS_POINT,
  DELETE_ACCESS_POINTS,
} from "../../../../graphql/locationAccessPoint";
import { GET_LOCATION_BY_ID } from "../../../../graphql/location";

//snackbar
import { useSnackbar } from "notistack";

//translation
import { useTranslation } from "react-i18next";

import { getTable } from "./_table";

const LocationWifiList = () => {
  const { lID } = useParams();

  const { userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const DEFAULT_ACCESS_POINT = {
    locationGuid: lID,
    ssid: "",
    subnet: "",
  };

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [re, setRe] = useState(false);
  const [newObj, setNewObj] = useState({
    locationGuid: lID,
    ssid: "",
    subnet: "",
  });
  const requiredFields=["locationGuid", "ssid", "subnet"];

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);
  const [officeBranch, setOfficeBranch] = useState(null);

  useEffect(() => {
    const col = getTable(t);

    if (col) {
      setColumns(col.columns);
    }
  }, []);

  const { mfunction: createAccessPoint } = useMutationAuth(
    CREATE_ACCESS_POINT,
    "createAccessPoint"
  );

  const { mfunction: updateAccessPoint } = useMutationAuth(
    UPDATE_ACCESS_POINT,
    "updateAccessPoint"
  );

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_ACCESS_POINTS,
    "deleteLocationAccessPoints"
  );

  const { mfunction: getAllAccesPoints, loading } = useLazyQueryAuth(
    GET_ALL_ACCESS_POINTS,
    "GetAllLocationAccessPoints"
  );

  const { tokenChange: locationWait } =
    useQueryAuth(
      GET_LOCATION_BY_ID,
      "GetLocationByID",
      {
        variables: { id: lID },
      },
      {
        onCompleted: (response) => {
          const data = response.GetLocationByID.location || {};
          setOfficeBranch(!data.isOnSite);
        },
      }
    );

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    getAllAccesPoints(
      {
        variables: {
          model: {
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            locationGuid: lID,
            keyword: search,
          },
        },
      },
      (response) => {
        setData(response);
        setRows(
          response?.GetAllLocationAccessPoints?.accessPointsList
            ?.accessPoints || []
        );
      }
    );
  }, []);

  useEffect(() => {
    if (columns && !locationWait && setCreate.current === false)
      fetchTableData(page, rowsPerPage, search);
  }, [columns.length, locationWait, fetchTableData, re]);

  const numberRecords =
    data &&
    data.GetAllLocationAccessPoints &&
    data.GetAllLocationAccessPoints.accessPointsList &&
    data.GetAllLocationAccessPoints.accessPointsList.totalNumberOfRecords
      ? data.GetAllLocationAccessPoints.accessPointsList.totalNumberOfRecords
      : 0;

  const handleDelete = (selectedItems) => {
    deleteRecords(
      {
        variables: {
          model: {
            accessPointsIDs: selectedItems,
          },
        },
      },
      (response) => {
        if (
          response["deleteLocationAccessPoints"].accessPointDeleteResponse ==
          true
        ) {
          enqueueSnackbar(t("locationAccessPoint.list.delete.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        } else {
        }
      }
    );
  };

  function CreateAccessPointData(newRow) {
    if (officeBranch === false) {
      enqueueSnackbar(t("locationAccessPoint.cannot.create"), {
        variant: "error",
      });
      setRows(
        rows.filter(
          (r) => r.locationAccessPointGuid !== newObj.locationAccessPointGuid
        )
      );
      setNewObj(DEFAULT_ACCESS_POINT);
    } else if (newRow != null && newRow.locationAccessPointGuid != null) {
      setIsSubmitting(true);
      createAccessPoint(
        {
          variables: {
            model: {
              ssid: newRow.ssid,
              subnet: newRow.subnet,
              locationGuid: newRow.locationGuid,
            },
          },
        },
        (response) => {
          if (
            response["createAccessPoint"].errorCode !== null &&
            response["createAccessPoint"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["createAccessPoint"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            setRows(
              rows.filter(
                (r) =>
                  r.locationAccessPointGuid !== newObj.locationAccessPointGuid
              )
            );
            setNewObj(DEFAULT_ACCESS_POINT);
          } else {
            enqueueSnackbar(t("locationAccessPoint.createdSuccessfully"), {
              variant: "success",
            });
            fetchTableData(page, rowsPerPage, search);
            setNewObj(DEFAULT_ACCESS_POINT);
          }
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        },
        (error) => {
          setRows(
            rows.filter(
              (r) =>
                r.locationAccessPointGuid !== newObj.locationAccessPointGuid
            )
          );
          setNewObj(DEFAULT_ACCESS_POINT);
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        }
      );
    }
  }

  function EditAccessPointData(updatedRow) {
    updateAccessPoint(
      {
        variables: {
          model: {
            locationAccessPointGuid: updatedRow.locationAccessPointGuid,
            ssid: updatedRow.ssid,
            subnet: updatedRow.subnet,
          },
        },
      },
      (response) => {
        if (
          response["updateAccessPoint"].errorCode !== null &&
          response["updateAccessPoint"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["updateAccessPoint"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_ACCESS_POINT);
        } else {
          enqueueSnackbar(t("locationAccessPoint.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_ACCESS_POINT);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_ACCESS_POINT);
      }
    );
  }

  const pagination = {
    rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    removeFilters:true,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "locationAccessPoint.list.search.label",
    deleteText: "locationAccessPoint.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== "M",
    isSubmitting,
    requiredFields,
    enqueueSnackbar,
  };

  return (
    <div>
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && (
          <GridTable
            {...pagination}
            identifier="locationAccessPointGuid"
            hasCheckbox={false}
            handleCreate={(wifi) => CreateAccessPointData(wifi)}
            handleUpdate={(wifi) => EditAccessPointData(wifi)}
            handleDelete={(wifis) => handleDelete(wifis)}
          />
        )
      )}
    </div>
  );
};

export default LocationWifiList;
