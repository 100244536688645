import { useMemo, useState } from "react";
import FlagBadge from "../../../../components/FlagBadge";
import LinearProgressCountUp from "../../../../components/LinearProgressBar";
import BackgroundStatus from "../../../../components/StatusCell/backgroundStatus";
import TimeStatus from "../../../../components/TimeStatus";
import RowsSubrows from "./RowsSubrows";
import AutoComplete from "../../../../components/AutoComplete";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import { GET_ALL_TASK_STATUSES } from "../../../../graphql/taskStatus";
import useAuth from "../../../../hooks/useAuth";
import { MenuItem } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { UPDATE_TASK_QUICK } from "../../../../graphql/task";
import CustomTooltip from "../../../../components/Tooltip";

const componentDisplay = ({
  compName,
  row,
  column,
  rowGuid,
  handleRefetchTree,
  data,
  updateData,
  getTotalFilters,
}) => {
  const components = {
    RowsSubrows,
    BackgroundStatus,
    ProgressBar: LinearProgressCountUp,
    FlagBadge,
    TimeStatus,
    AutoComplete,
  };

  const Component = components[compName];
  return Component ? (
    <Component
      row={row}
      column={column}
      rowGuid={rowGuid}
      handleRefetchTree={handleRefetchTree}
      data={data}
      updateData={updateData}
      getTotalFilters={getTotalFilters}
      fromTree
    />
  ) : null;
};

const useColumnsConfig = (
  fetchedColumns,
  t,
  rowGuid,
  handleRefetchTree,
  chipFilters,
  defaultFilters,
  sorting,
  search,
  data,
  updateData,
  getTotalFilters,
  status_list
) => {
  const { storeUserTenant, storeUserGuid } = useAuth();
  const [editingRowID, setEditingRowID] = useState(null);
  const [selectedStatusObj, setSelectedStatusObj] = useState({});
  const [selectedPriority, setSelectedPriority] = useState("");

  const { cfunction: updateTaskQuickData } = useMutationAuth(
    UPDATE_TASK_QUICK,
    "UpdateTaskQuick"
  );

  const updateTaskQuick = (value, row, type, setSelectedStatusObj) => {
    const updatedValue = type === "status" ? value : value;
    
    updateTaskQuickData(
      {
        variables: {
          model: {
            taskGuid: row?.id,
            userID: storeUserGuid,
            statusGuid: type === "status" ? updatedValue : null,
            priority: type === "priority" ? value : null,
          },
        },
      },
      (response) => {
        if (response["UpdateTaskQuick"].errorCode) {
          enqueueSnackbar(t(response["UpdateTaskQuick"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          const successMessage =
            response["UpdateTaskQuick"].editTaskQuickResponse === false
              ? t("projectForm.status.change.warning")
              : t("projectForm.updatedSuccessfully");

          enqueueSnackbar(successMessage, {
            variant:
              response["UpdateTaskQuick"].editTaskQuickResponse === false
                ? "warning"
                : "success",
            autoHideDuration: 5000,
          });

           setEditingRowID(null);
           type === "status" && setSelectedStatusObj((prev) => {
            const updated = {
              ...prev,
              [row?.id]: type === "status" ? updatedValue : prev[row?.id],
            };
            return updated;
          });
          setSelectedPriority((prev) => {
            const updated = {
              ...prev,
              [row?.id]: type === "priority" ? value : prev[row?.id],
            };
            return updated;
          });
        }
      },
      (error) => {
        if (handleRefetchTree) handleRefetchTree();
      }
    );
  };

  const priority_list = [
    {
      value: "High",
      label: "High",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-flag"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="var(--color-statuses-error-color)"
          fill="var(--color-statuses-error-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0v9a5 5 0 0 1 -7 0a5 5 0 0 0 -7 0v-9z" />
          <path d="M5 21v-7" />
        </svg>
      ),
    },
    {
      value: "Medium",
      label: "Medium",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-flag"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="var(--color-employeeStatusColors-new-color)"
          fill="var(--color-employeeStatusColors-new-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0v9a5 5 0 0 1 -7 0a5 5 0 0 0 -7 0v-9z" />
          <path d="M5 21v-7" />
        </svg>
      ),
    },
    {
      value: "Low",
      label: "Low",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-flag"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="rgba(0, 138, 214, 1)"
          fill="rgba(0, 138, 214, 1)"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0v9a5 5 0 0 1 -7 0a5 5 0 0 0 -7 0v-9z" />
          <path d="M5 21v-7" />
        </svg>
      ),
    },
  ];

  return useMemo(() => {
    if (!fetchedColumns) return [];

    const visibleColumns = fetchedColumns.filter(
      (column) => column.visible !== false
    );

    return visibleColumns.map((column) => ({
      accessorFn: (row) => {
        if (column.renderCell) {
          const renderFunction = new Function("row", column.renderCell);
          return renderFunction(row);
        }
        return row[column.id];
      },
      id: column.id,
      header: t(column.label),
      size: column.width ? parseInt(column.width) : undefined,
      enableSorting: column.sort !== false,
      align: column.align,
      enableEditing: column.enableEditing,
      editVariant: column.id === "Status" ? "select" : "text",
      editSelectOptions:
        column.id === "Status"
          ? status_list.map((status) => ({
              value: status.taskStatusGuid,
              label: status.description,
            }))
          : column.id === "Priority"
          ? priority_list
          : null,

      muiEditTextFieldProps: ({ row }) => {
        const selectedStatusGuid =
          selectedStatusObj[row.id] ||
          row?.original?.task?.status?.taskStatusGuid;
        const selectedPriorityOption =
          selectedPriority[row.id] || row?.original?.task?.priority;

        if (column.id === "Status") {
          return {
            select: true,
            value: selectedStatusGuid,
            onChange: (event) => {
              const newStatusGuid = event.target.value;
              setSelectedStatusObj((prev) => ({
                ...prev,
                [row.id]: newStatusGuid,
              }));
              setTimeout(() => {
                // selectedStatusObj[row.id] !== null &&
                updateTaskQuick(
                  newStatusGuid,
                  row,
                  "status",
                  setSelectedStatusObj
                );
              }, 1000);
            },
            // onBlur: () => {
            //   selectedStatusObj[row.id] !== null &&
            //     updateTaskQuick(selectedStatusObj[row.id], row, "status");
            // },
            SelectProps: {
              native: false,
            },
            children: status_list.map((status) => (
              <MenuItem
                key={status.taskStatusGuid}
                value={status.taskStatusGuid}
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  background:
                    status.taskStatusGuid === selectedStatusGuid
                      ? "none"
                      : "inherit",
                }}
              >
                <BackgroundStatus status={status} />
              </MenuItem>
            )),
          };
        } else if (column.id === "Priority") {
          return {
            select: true,
            value: selectedPriorityOption || "",
            onChange: (event) => {
              const newPriority = event.target.value;
              setSelectedPriority((prev) => ({
                ...prev,
                [row.id]: newPriority,
              }));
              setTimeout(() => {
                // selectedPriority[row.id] !== null &&
                updateTaskQuick(
                  newPriority,
                  row,
                  "priority",
                  selectedPriorityOption
                );
              }, 1000);
            },
            // onBlur: () => {
            //   selectedPriority[row.id] !== null &&
            //     updateTaskQuick(selectedPriority[row.id], row, "priority");
            // },

            children: priority_list.map((prio) => (
              <MenuItem
                key={prio.value}
                value={prio.value}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    prio.value === selectedPriorityOption ? "none" : "inherit",
                }}
              >
                <CustomTooltip title={prio.label}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "4px",
                      justifyContent: "center",
                    }}
                  >
                    {prio.icon}
                  </div>
                </CustomTooltip>
              </MenuItem>
            )),
          };
        }
      },

      Cell: ({ row }) => {
        // setEditingRowID(row.id);

        if (column.compName) {
          return componentDisplay({
            compName: column.compName,
            row: row.original,
            column,
            rowGuid,
            handleRefetchTree,
            data,
            updateData,
            getTotalFilters,
          });
        }

        if (column.renderCell) {
          const renderFunction = new Function("row", column.renderCell);
          return renderFunction(row.original);
        }

        return row.original[column.id];
      },
    }));
  }, [
    fetchedColumns,
    t,
    rowGuid,
    handleRefetchTree,
    chipFilters,
    defaultFilters,
    sorting,
    search,
    data,
    updateData,
    getTotalFilters,
    editingRowID,
    selectedStatusObj,
    selectedPriority,
    status_list,
  ]);
};

export default useColumnsConfig;
