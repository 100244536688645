import React, { useState, useEffect, useRef } from "react";

//PropTypes
import PropTypes from "prop-types";

import "../style.css";

//components
import DataCard from "../../../components/Cards/DataCard";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

import { Link } from "react-router-dom";

import {
  Button,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import useMutationAuth from "../../../hooks/useMutationAuth";
import useAuth from "../../../hooks/useAuth";

//urls
import urls from "../../../urls";

import { START_TASK } from "../../../graphql/task";

import { getData } from "./_my-tasks";

import TaskTabsModal from "../../../components/Modal/TaskTabsModal";
import PaperComponent from "../../../components/Modal/PaperComponent";
import { useDispatch } from "react-redux";
import { changeTimer } from "../../../app/reducers/timerSlice";

import componentDisplay from "../../../componentDisplay";
import TimesheetForm from "../../../pages/admin-configuration/Timesheets/Timesheets-Form";
import TimeEntryDrawer from "../../../pages/admin-configuration/Timesheets/Timesheets-Form/components/TimeEntryDrawer";
import FieldModal from "../../../components/Modal/FieldModal";
import CustomButton from "../../../components/CustomButton";
import { useSearchParams } from "react-router-dom";

const MyTasks = ({
  initialLoad,
  loading,
  data,
  openTask,
  removeBorders,
  refetch,
  refetchOpenTask,
  employeeList,
  getTask,
  setNewTask,
  newTask,
  type,
  setType,
  wait
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { storeUserGuid, timeEntryEnabled } = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  
  const { my_project_direct_tasks_form } = urls;

  const [taskID, setTaskID] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const timeEntryID = useRef(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTimeEntryModal, setOpenTimeEntryModal] = useState(false);
  const [description, setDescription] = useState(null);
  const [editMode, setEditMode] = React.useState(false);
    
  useEffect(() => {
    if(searchParams.size > 0 && !loading) {
      // Get the value of the 'subscribeFrom' parameter
      setTaskID(searchParams?.get('taskGuid'));
      setTaskName(searchParams?.get('taskName'));
      setProjectID(searchParams?.get('projectGuid'));
      setTaskName(searchParams?.get('projectName'));
      setNewTask(false);
      setTimeout(() => {
        setOpenModal(true);
      }, 500);
    }
  }, [taskID])

  useEffect(() => {
    if(newTask === true) {
      setTaskID(null);
      setTaskName(null);
      setProjectID(null);
      setProjectName(null);
      setNewTask(false);
      setOpenModal(true);
    }
  }, [newTask])

  function sortOpenTask(a, b) {
    if (a.taskGuid === openTask?.task?.taskGuid) {
      return -1; // a comes first
    }
    if (b.taskGuid === openTask?.task?.taskGuid) {
      return 1; // b comes first
    }
    return 0; // maintain the order for other elements
  }

  const sortedData = data?.sort(sortOpenTask);

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenModal(false);
        setOpenDialog(false);
        setTaskID(null);
        setTaskName(null);
        setProjectID(null);
        setProjectName(null);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { cfunction: startTask, loading: loadingStart  } = useMutationAuth(START_TASK, "StartTask");

  const startTaskFunction = (value, employeeGuid) => {
    setTaskID(value);
    startTask(
      {
        variables: {
          model: {
            taskGuid: value,
            employeeGuid: employeeGuid,
          },
        },
      },
      (response) => {
        if (
          response["StartTask"].errorCode !== null &&
          response["StartTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["StartTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("task.startedSuccessfully"), {
            variant: "success",
          });
          dispatch(changeTimer(0));
          refetch();
          refetchOpenTask();
        }
      }
    );
  };

  const handleClickOpen = (row) => {
    if(!wait) refetchOpenTask();
    setTaskID(row?.taskGuid);
    setTaskName(row?.title);
    setSelectedTask({taskGuid: row?.taskGuid, title: row?.title, ticket:row?.ticket, parentTask: row?.parentTaskGuid});
    
    let timeEntries = getTask?.GetOpenTask?.openTask?.task?.timeEntries;
    timeEntries?.map(timeEntry => {
      if(timeEntry.open === true && timeEntry.employeeGuid === storeUserGuid) { 
        setEditMode(true);
        timeEntryID.current = timeEntry.timeEntryGuid;
      }
      return timeEntries;
    })
    setEditMode(true);
    setOpenTimeEntryModal(true);
  };

  const handleCreateTimeEntry = (row) => {
    setEditMode(false);
    setSelectedTask({taskGuid: row.taskGuid, title: row.title, ticket:row?.ticket, parentTask: row?.parentTaskGuid});
    setOpenTimeEntryModal(true);
  };

  const dataRows = getData(
    t,
    taskID,
    setTaskID,
    setTaskName,
    setProjectID,
    setProjectName,
    setOpenModal,
    startTaskFunction,
    handleClickOpen,
    Link,
    PendingActionsIcon,
    my_project_direct_tasks_form,
    storeUserGuid,
    componentDisplay,
    handleCreateTimeEntry,
    loadingStart,
    timeEntryEnabled,
    refetchOpenTask,
    setType,
  );
  
  return (
    <>
      <FieldModal
        PaperComponent={PaperComponent}
        CustomButton={CustomButton}
        content={t("dialog.task.content")}
        loading={loading}
        openDialog={openDialog}
        handleClose={handleClose}
        setState={(v) => setDescription(v)}
        label={t("dialog.description")}
        t={t}
      />
      <TimeEntryDrawer
        PaperComponent={PaperComponent}
        openTimeEntryModal={openTimeEntryModal}
        setOpenTimeEntryModal={setOpenTimeEntryModal}
        handleChangeTimeEntryGuid={(v) => timeEntryID.current = v}
        handleEditMode={(v) => setEditMode(v)}
        timeSheetGuid={null}
        timeEntryGuid={timeEntryID.current}
        description={null}
        synced={false}
        editMode={editMode}
        employees={employeeList}
        tasks={sortedData}
        selectedTask={selectedTask}
        resetFields={false}
        fromDashboard={true}
        refetchTasks={null}
        refetchData={() => {
          refetchOpenTask();
          refetch();
        }}
        t={t}
      />
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        handleChangeTaskName={(v) => setTaskName(v)}
        taskName={taskName}
        refetch={refetch}
        fromDashboard={true}
        fromProject={true}
        TimesheetForm={TimesheetForm}
        t={t}
        editMode={true}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        type={selectedTask?.parentTask ? "subtask" : type}
        setType={(v) => setType(v)}
        removeSearchParams={() => setSearchParams("")}
      />
      <DataCard
        className={sortedData?.length > 0 ? "tasks-table" : ""}
        title={t("dashboard.myTasks")}
        stopLazyLoad
        tableHeaders={dataRows.headers}
        render={dataRows.render}
        data={sortedData}
        initialLoad={initialLoad}
        loading={false}
        removeBorders={removeBorders}
        maxHeight={"calc(-250px + 100vh)"}
        myTask
      />
    </>
  );
};

MyTasks.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch: PropTypes.bool,
  removeBorders: PropTypes.bool,
};

MyTasks.defaultProps = {
  initialLoad: false,
  data: {},
  next: () => {},
  waitBatch: false,
  removeBorders: false,
};

export default MyTasks;
