import { gql } from "@apollo/client";

export const GET_ALL_NOTIFICATIONS=gql`
query ($model: GetAllNotificationsRequestInput!) {
  GetAllUserNotifications(model: $model) {
      notificationsList {
        url
        logoURL
        notifications{
          senderID
          newsGuid
          news{
            description
          }
          sender{
            employeeGuid
            profilePictureURL
          }
          type
          toRequest {
            toRequestGuid
            employeePTO {
              employee {
                firstName
                lastName
              }
            }
            requestStatus {
              mnemonic
            }
          }
          attendanceRequest {
            attendanceRequestGuid
            employee {
              firstName
              lastName
            }
            requestStatus {
              mnemonic
            }
          }
          task {
            taskGuid
            title
          }
          notificationGuid
          dateSent
          notificationTitle
          notificationText
          isRead
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`

  export const GET_ALL_NOTIFICATIONS_SUBSCRIPTION=gql`
  subscription ($id: UUID!) {
    onUpdateNotification(userID: $id) {
      notificationsList {
        url
        logoURL
        notifications {
          senderID
          sender{
            employeeGuid
            profilePictureURL
          }
           notificationGuid
           dateSent
           notificationTitle
           notificationText
           isRead
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
  `

  export const NOTIF_READ=gql`
  mutation ($notificationGuid: UUID!){
    UserReadNotification(notificationID: $notificationGuid){
        notificationIsRead
        errorCode
        errorMessage
        errorVariables
    }
}`

export const DELETE_NOTIFICATION=gql`
mutation($id:UUID!){
  DeleteNotification (notificationID: $id) {
    deleteNotificationResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`