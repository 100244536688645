export default {
  formID: "add-day-used",
  formField: {
    employee:{
      name:"employee",
      label:"addDayUsed.employee.label",
      requiredMessage: "addDayUsed.numberOfHours.requiredMsg"
    },
    pTO:{
      name:"pTO",
      label:"addDayUsed.pTO.label",
      requiredMessage:"addDayUsed.pTO.requiredMsg"
    },
    description:{
      name:"description",
      label:"addDayUsed.description.label",
      requiredMessage:"addDayUsed.description.requiredMsg"
    },
    date: {
      name:"date",
      label:"addDayUsed.date.label",
      requiredMessage:"addDayUsed.date.requiredMsg"
    },
    numberOfDays: {
      name:"numberOfDays",
      label:"addDayUsed.numberOfDays.label",
      requiredMessage: "addDayUsed.numberOfDays.requiredMsg"
    },
    numberOfHours: {
      name:"numberOfHours",
      label:"addDayUsed.numberOfHours.label",
    },
    entitlement: {
      name:"entitlement",
      label:"addDayUsed.entitlement.label",
    },
  },
};
