const UTC2Local = (utcDate, dateOnly = false, withYear = true) => {
  if (!utcDate) return null;
  // Assuming formatDateTimeZone is defined elsewhere
  var date = new Date(formatDateTimeZone(utcDate));
  var originDate = new Date("January 1, 2000 00:00:00");
  if (date < originDate) {
    return "";
  } else {
    const d = date.toString();

    if (d === "Invalid Date") {
      return "";
    }

    let formattedDate;
    if (withYear) {
      formattedDate =
        d.substring(8, 10) +
        " " +
        d.substring(4, 7) +
        (dateOnly ? " " + d.substring(11, 15) : ", " + d.substring(11, 15));
    } else {
      formattedDate = d.substring(8, 10) + " " + d.substring(4, 7);
    }

    if (!dateOnly) {
      formattedDate += " at " + d.substring(16, 21);
    }

    return formattedDate;
  }
};

export const formatDateString = (dateString, userTimezone = 'UTC') =>{
  if (!dateString) {
      return '';
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
      return '';
  }

  // Formatting options
  const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      // timeZone: userTimezone,
  };

  const formatter = new Intl.DateTimeFormat('en-GB', options);

  // Format the date and return
  return formatter.format(date);
}

export const UTC2Time = (utcDate, format = true) => {
  if (!utcDate) return null;
  const isZuluFormat = utcDate.includes("Z");
  var date = new Date(formatDateTimeZone(utcDate));
  if (!format) date = utcDate;
  var originDate = new Date("January 1, 2000 00:00:00");
  if (date < originDate) {
    return "";
  } else {
    if (!format || (isZuluFormat && !format)) return date?.substring(11, 16);
    const d = date.toString();

    if (d === "Invalid Date") {
      return "";
    }

    return d.substring(16, 21);
  }
};

export const getTime = (date) => {
  if (!date) return null;
  var formatDate = new Date(date);

  const hours = String(formatDate.getHours()).padStart(2, "0");
  const minutes = String(formatDate.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

  export const formatDateTimeZone = (inputDate, zulu = true) => {
  if (
    typeof inputDate !== "string" ||
    inputDate.includes("Z") ||
    inputDate.includes("z")
  ) {
    return inputDate;
  }

  if (inputDate.includes("+")) {
    return zulu ? inputDate.split("+")[0] + "Z" : inputDate.split("+")[0];
  } else if (
    inputDate.includes("-") &&
    (inputDate.match(/-/g) || []).length === 3
  ) {
    const parts = inputDate.split("-");
    parts.pop();
    return zulu ? parts.slice(0, 3).reverse().join("-") + "Z" : parts.reverse().join("-");
  } else if (
    inputDate.includes("-") &&
    (inputDate.match(/[-]/g) || []).length === 2
  ) {
    const parts = inputDate.split("-");
    return zulu ? parts.slice(0, 3).reverse().join("-") + "Z" : parts.reverse().join("-");
  } else if (
    inputDate.includes("/") &&
    (inputDate.match(/[/]/g) || []).length === 2
  ) {
    const parts = inputDate.split("/");
    return zulu ? parts.slice(0, 3).reverse().join("-") + "Z" : parts.reverse().join("-");
  } else {
    const hours = inputDate.substring(0, 2);
    const minutes = inputDate.substring(3, 5);
    const seconds = inputDate.substring(6, 8);
    const milliseconds = inputDate.substring(9);
    const dateString = zulu ? `1970-01-01T${hours}:${minutes}:${seconds}.${milliseconds}Z` : `1970-01-01T${hours}:${minutes}:${seconds}.${milliseconds}`;
    return dateString;
  }
};

export const formatDateTimeToString = (inputDate, zulu = true, formatted = true, timezoneFormat = null, withYear = true) => {
  if (!inputDate) return null;
  var date = formatDateTimeZone(inputDate, zulu);
  var formattedDate = date.substring(0, 10);
  if(!formatted) formattedDate = formattedDate.split("-").reverse().join("-");
  if(timezoneFormat) {
    let parts = formattedDate.split("-");
    let year = parts[2];
    let month = parts[1];
    let day = parts[0];

    if (timezoneFormat === "DD-MM-YYYY") {
        return `${day}-${month}-${year}`;
    } else if (timezoneFormat === "MM-DD-YYYY") {
        return `${month}-${day}-${year}`;
    } else {
        throw new Error("Unsupported format");
    }
  }
  if(formatted) formattedDate = formatDate(formattedDate, withYear);

  return formattedDate;
}

export const formatDate = (dateString, withYear = true) => {
  if (!dateString) return null; // If date is null, return null

  if(dateString.includes("T")) {
    // Extract the date part (YYYY-MM-DD) from the ISO date string
    const datePart = dateString.split('T')[0];

    // Split the date part into its components
    const [year, month, day] = datePart.split('-');

    // Create a new date object in UTC to avoid timezone shifts
    const date = new Date(Date.UTC(year, month - 1, day));
    return withYear === true ? date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });

  } else {
      // return withYear === true ? dateString.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : dateString.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
      ];

      let [year, month, day] = dateString.split("-");
      let monthName = month > 12 ? months[parseInt(day, 10) - 1] : months[parseInt(month, 10) - 1];

      return withYear === true ? `${month > 12 ? month : day} ${monthName} ${year}` : `${month > 12 ? month : day} ${monthName}`;
    }
  };

export default UTC2Local;
