import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {  type,
    description,
    emailBody,
    emailTitle,
    attachment,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [type.name]:Yup.object().required(type.requiredMessage),
    [description.name]:Yup.string(),
    [emailBody.name]:Yup.string(),
    [emailTitle.name]:Yup.string(),
    [attachment.name]: Yup.string().nullable(),

  }),
];

export default validation;