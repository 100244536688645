import checkout from "./form";

const {
  formField: {
    licenseType,
    numberOfLicenses,
    numberOfUsedLicenses,
  },
} = checkout;

export default {
  [licenseType.name]: "",
  [numberOfLicenses.name]: 0,
  [numberOfUsedLicenses.name]: 0,
};
