import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const EmployeeStatus = (props, tile) => {
  const { employeeStatus } = props;
  const { employeeStatusColors } = colorsLight;

  const status = props?.description === "Active" ? "active" : "inactive";

  if (!props) return null;
  return (
    <div
      style={{
        padding: tile ? "2px 3px" : "5px",
        width: "80px",
        fontSize: tile ? "13.6px" : "14px",
        textAlign: "center",
        borderRadius: "5px",
        fontFamily: "poppins  !important",
        margin: "auto",
        ...employeeStatusColors[
          employeeStatus?.mnemonic ||
            props?.row?.employeeStatus?.mnemonic ||
            status
        ],
      }}
    >
      {employeeStatus?.description
        ? employeeStatus?.description
        : props?.row?.employeeStatus?.description
        ? props?.row?.employeeStatus?.description
        : status === "active"
        ? "Active"
        : "Inactive"}
    </div>
  );
};

export default EmployeeStatus;
