import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { encryptTransform } from "redux-persist-transform-encrypt";

// reducers
import userReducer from "./reducers/userSlice";
import languageReducer from "./reducers/languageSlice";
import employeeReducer from "./reducers/employeeSlice";
import timerReducer from "./reducers/timerSlice";
import formReducer from "./reducers/formSlice";
import notificationReducer from "./reducers/notificationSlice";
import tokenReducer from "./reducers/tokenSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  language: languageReducer,
  user: userReducer,
  employee: employeeReducer,
  timer: timerReducer,
  token: tokenReducer,
  notification: notificationReducer
});

const persistedReducer = persistReducer(
  {
    ...persistConfig,
    transforms: [
      encryptTransform({
        secretKey: "q1w2e3r4t5y6u7i8o9p1a2s3d4f5g6h7j8k9",
        onError: (error) => {},
      }),
    ],
  },
  rootReducer
);

const middlewares = [
  createStateSyncMiddleware({
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
  }),
  thunk,
];

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: middlewares,
});

export const persistor = persistStore(store);

initMessageListener(store);
