import React from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

//external components
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TaskForm from "./../Tasks-form";
import TimeEntries from "./../../TimeEntries/TimeEntries-list";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import TasksList from "../Tasks-list";
import { CloseIcon } from "../../../../components/Icon";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0, pb: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TaskTabs({
  fromTenant = false,
  fromProject = false,
  fromPlan = false,
  fromDashboard = false,
  showModal = false,
  projectID,
  projectName,
  taskID,
  handleChangeTask,
  handleChangeTaskName,
  taskName,
  refetchData,
  handleCloseModal,
  modalDisplay,
  type,
  parent,
  resetFields,
  key,
}) {
  const { pName, pID, tkName, tkID, tskID, tskName } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TABS_STYLES = {
    //background: `${colorsLight.gradients.defaultColor.state}`
  };

  const {
    tenant_project_tasks_form,
    my_project_tasks_form,
    tenant_project_direct_tasks_form,
    my_project_direct_tasks_form,
    my_project_tasks_time_entries,
    my_project_direct_tasks_time_entries,
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    (!pName ||
      !pID ||
      pName === "undefined" ||
      pName === "null" ||
      pName === "" ||
      pID === "null" ||
      pID === "undefined" ||
      pID === "" ||
      !tskName ||
      !tskID ||
      tskName === "undefined" ||
      tskName === "null" ||
      tskName === "" ||
      tskID === "null" ||
      tskID === "undefined" ||
      tskID === "") &&
    modalDisplay === false
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(pName);
  const encodedID = encodeURIComponent(pID);

  const TabLinks = [
    {
      key: 0,
      Header: t("taskTabs.form"),
      Link: fromTenant
        ? fromProject || fromDashboard
          ? tenant_project_direct_tasks_form
              .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
              .replace(
                "/:tskName/:tskID",
                `/${encodeURIComponent(tskName)}/${encodeURIComponent(tskID)}`
              )
          : tenant_project_tasks_form
              .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
              .replace(
                "/:tkName/:tkID",
                `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
              )
              .replace(
                "/:tskName/:tskID",
                `/${encodeURIComponent(tskName)}/${encodeURIComponent(tskID)}`
              )
        : fromProject || fromDashboard
        ? my_project_direct_tasks_form
            .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(tskName)}/${encodeURIComponent(tskID)}`
            )
        : my_project_tasks_form
            .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            )
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(tskName)}/${encodeURIComponent(tskID)}`
            ),
    },
    // ...(taskID || tskID
    //   ? [
    //       {
    //         key: 1,
    //         Header: t("taskTabs.timeEntries"),
    //         Link:
    //           fromProject || fromDashboard
    //             ? my_project_direct_tasks_time_entries
    //                 .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
    //                 .replace(
    //                   "/:tskName/:tskID",
    //                   `/${encodeURIComponent(tskName)}/${encodeURIComponent(
    //                     tskID
    //                   )}`
    //                 )
    //             : my_project_tasks_time_entries
    //                 .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
    //                 .replace(
    //                   "/:tkName/:tkID",
    //                   `/${encodeURIComponent(tkName)}/${encodeURIComponent(
    //                     tkID
    //                   )}`
    //                 )
    //                 .replace(
    //                   "/:tskName/:tskID",
    //                   `/${encodeURIComponent(tskName)}/${encodeURIComponent(
    //                     tskID
    //                   )}`
    //                 ),
    //       },
    //     ]
    //   : []),
      // ...(taskID || tskID
      //   ? [
      //       {
      //         key: 2,
      //         Header: t("taskTabs.subtasks"),
      //         Link:
      //           fromProject || fromDashboard
      //             ? my_project_direct_tasks_time_entries
      //                 .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
      //                 .replace(
      //                   "/:tskName/:tskID",
      //                   `/${encodeURIComponent(tskName)}/${encodeURIComponent(
      //                     tskID
      //                   )}`
      //                 )
      //             : my_project_tasks_time_entries
      //                 .replace("/:pName/:pID", `/${encodedName}/${encodedID}`)
      //                 .replace(
      //                   "/:tkName/:tkID",
      //                   `/${encodeURIComponent(tkName)}/${encodeURIComponent(
      //                     tkID
      //                   )}`
      //                 )
      //                 .replace(
      //                   "/:tskName/:tskID",
      //                   `/${encodeURIComponent(tskName)}/${encodeURIComponent(
      //                     tskID
      //                   )}`
      //                 ),
      //       },
      //     ]
      //   : []),
  ];

  return (
    <Box>
      {modalDisplay === true ? (
        <Box sx={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              key={key}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              orientation="horizontal"
              TabIndicatorProps={{ style: TABS_STYLES }}
            >
              <Tab label={t("taskTabs.form")} {...a11yProps(0)} />
              {taskID && (
                <Tab label={t("taskTabs.timeEntries")} {...a11yProps(1)} />
              )}
              {/* {taskID && (
                <Tab label={t("taskTabs.subtasks")} {...a11yProps(2)} />
              )} */}
            </Tabs>
            <IconButton onClick={() => handleCloseModal()}>
              <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
            </IconButton>
          </div>
          <TabPanel value={value} index={0}>
            <TaskForm
              fromPlan={fromPlan}
              fromTenant={fromTenant}
              fromProject={fromProject}
              fromDashboard={fromDashboard}
              projectID={projectID}
              projectName={projectName}
              taskID={taskID}
              handleChangeTask={handleChangeTask}
              handleChangeTaskName={handleChangeTaskName}
              taskName={taskName}
              refetchData={refetchData}
              showModal={showModal}
              parent={parent}
              resetFields={resetFields}
              type={type}
              handleCloseModal={handleCloseModal}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TimeEntries
              fromProject={fromProject}
              fromDashboard={fromDashboard}
              projectID={projectID}
              projectName={projectName}
              taskName={taskName}
              taskID={taskID}
              refetchTasks={refetchData}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <TasksList
              fromSubtask={true}
              parentGuid={taskID}
              parentName={taskName}
              projectIDInherit={projectID}
              projectNameInherit={projectName}
              handleCloseModal={handleCloseModal}
              fromProject={true}
            />
          </TabPanel> */}
        </Box>
      ) : (
        <BasicTabs TabHeaders={TabLinks} removeTabHeader/>
      )}
    </Box>
  );
}

export default TaskTabs;
