import React from "react";

import { IconButton } from "@mui/material";
import Tooltip from "../../../../../components/Tooltip";

//translation
import { useTranslation } from "react-i18next";

const EditRecord = (props) => {
  const {
    timeEntryGuid,
    synced,
    description,
    handleClick,
    handleEditMode,
    handleRowSelect,
    handleChangeTask,
    task,
  } = props;

  const { t } = useTranslation();

  const handleEditClick = () => {
    handleClick(true);
    if(handleChangeTask) handleChangeTask(task);
    handleEditMode(true);
    const obj = {
      timeEntryGuid: timeEntryGuid,
      description: description,
      synced: synced,
    };
    handleRowSelect(obj);
  };

  if (!timeEntryGuid) return null;
  return (
    <>
      <Tooltip title={t("timeEntry.info")}>
        <IconButton onClick={handleEditClick} className="timeSheetCol">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-dots"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="#000000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
            <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
          </svg>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default EditRecord;
