import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../../hooks/useAuth";
import { Outlet } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  Backdrop
} from "@mui/material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../../components/Cards/ContainerCard";
import AutoComplete from "../../../../../components/AutoComplete";
import axios from "axios";
import { EXPORT_TO_EXCEL } from "../../../../../graphql/reports";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../../graphql/project";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../../graphql/employee";
import { CREATE_TIME_ENTRIES_FROM_EXCEL } from "../../../../../graphql/timeEntry";

import {
  GET_ALL_TASKS,
  GET_TASKS_BY_TICKET,
} from "../../../../../graphql/task";

import { LOGOUT_USER } from "../../../../../graphql/user";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../../hooks/useLazyQueryAuth";

import useMutationAuth from "../../../../../hooks/useMutationAuth";

import { useSnackbar } from "notistack";

import { getTable } from "./_table";
import { getImportTable } from "./_importTable";

import colors from "../../../../../assets/theme/light/colors";
import ReactFileReader from "react-file-reader";
import {
  DeleteIcon,
  EyeIcon,
  HelpIcon,
  PDFIcon,
  UploadIcon,
  WarningIcon,
} from "../../../../../components/Icon";
import DataTable from "../../../../../components/Tables/DataTable";
import { exportToExcel } from "../../../../../utils/exportToExcel";
import FieldModal from "../../../../../components/Modal/FieldModal";
import PaperComponent from "../../../../../components/Modal/PaperComponent";
import CustomButton from "../../../../../components/CustomButton";
import { convertNodeToElement } from "react-html-parser";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

const backendURL = process.env.REACT_APP_REST_URL;
function ExcelTimeSheet() {
  const {
    storeUserTenant,
    userRoles,
    user,
    storeUserGuid,
    timeEntryEnabled,
    isEmployee,
    jwtToken,
    logout,
    isTimeEntryDescriptionMandatory
  } = useAuth();

  const dispatch = useDispatch();

  const { dark_logo_blue } = colors;

  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const { enqueueSnackbar } = useSnackbar();

  const { manageTimeEntry } = userRoles;

  const [re, setRe] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [exportData, setExport] = useState(true);
  const [loading, setLoading] = useState(false);
  const [includeSubtasks, setIncludeSubtasks] = useState(false);

  const [importLoading, setImportLoading] = useState(false);
  const [Applyloading, setApplyLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [importTableColumns, setImportTableColumns] = useState([]);

  const [employeeValue, setEmployeeValue] = useState(
    user && timeEntryEnabled ? user.user : null
  );
  const [projectsValue, setProjectsValue] = useState([]);
  const [fetchEmployees, setFetchEmployees] = useState();
  const [fetchProjects, setFetchProjects] = useState();
  const [excelFileResult, setExcelFileResult] = useState(null);

  const [projectIDs, setProjectIDs] = useState([]);
  const [employeeID, setEmployeeID] = useState(
    user && timeEntryEnabled ? user.user?.employeeGuid : null
  );

  const [timer, setTimer] = useState(null);
  const [data, setData] = useState([]);
  const [importData, setImportData] = useState([]);
  const [numOfImportRecords, setNumOfImportRecords] = useState(0);
  const [numOfRecords, setNumOfRecords] = useState(0);

  const [fetchTasks, setFetchTasks] = useState();

  const [fileName, setFileName] = useState("");
  const [tasks_list, setList] = useState([]);
  const [fetched, setFetched] = useState(false);
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();

      if (col) {
        setColumns(col.columns);
      }
    }
  }, [columns.length]);
  useEffect(() => {
    if (importTableColumns.length === 0) {
      const col = getImportTable();

      if (col) {
        setImportTableColumns(col.columns);
      }
    }
  }, [importTableColumns.length]);
  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
        showAll: true,
        attendanceDisabled: true,
      },
    },
  });
  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];
  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: employeeValue?.tenant.tenantGuid,
        userID: employeeValue?.employeeGuid,
      },
    },
    skip: !fetchProjects,
    isWait: fetchProjects,
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const { mfunction: getData, loading: dataLoading } = useLazyQueryAuth(
    EXPORT_TO_EXCEL,
    "ProjectExportTimeEntries"
  );

  const {
    mfunction: getTasks,
    refetch,
    loading: dataTaskLoading,
  } = useLazyQueryAuth(GET_TASKS_BY_TICKET, "GetTasksByTicket");
  const fetchTableData = useCallback((employeeValue, projectIDs) => {
    setExport(true)
    getTasks(
      {
        variables: {
          model: {
            tenantID: employeeValue?.tenant.tenantGuid,
            userID: employeeValue?.employeeGuid,
            enableFilters: true,
            onGoingTasks: true,
            filters: { projectIDs },
            orderColumn: "ProjectTask",
            orderDirection: "asc",
          },
        },
      },
      (response) => {
        if (response["GetTasksByTicket"].errorMessage == null) {
          setData(response.GetTasksByTicket.tasksList.taskViews);
          setNumOfRecords(response.GetTasksByTicket.totalNumberOfRecords);
        } else
          enqueueSnackbar(t(response["GetTasksByTicket"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
      }
    );
  }, []);

  useEffect(() => {
    if (data) {
      const tasks = data;
      let timesheetList = [];
      tasks?.forEach((task) => {
        timesheetList.push({
          TaskGuid: task?.task?.taskGuid,
          ProjectName: task?.task?.ticket?.project.title,
          // projectGuid: task?.task?.ticket?.projectGuid,
          TaskName: task?.task?.title,
        });
        return timesheetList;
      });
      setFetched(true);
      setList(timesheetList);
    }
  }, [data]);

  useEffect(() => {
    fetchTableData(employeeValue, projectIDs);
  }, [columns.length, fetchTableData, re]);


  const handleFile = (event) => {
    const file = event.fileList[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    setApplyLoading(true)
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const resourceGuid = jsonData[0][1];
      if (jsonData.length > 1) {
        const headerRow = jsonData[1]; // Extract the second row as the header
        const dataRows = jsonData.slice(2); // Extract data starting from the third row

        // Filter out rows where the project name (assuming it's in the first column) is null or empty
        const filteredDataRows = dataRows.filter((row) => {
          return row[0] !== null && row[0] !== "" && row[0] != undefined;
        });

        handleFileLoaded(filteredDataRows, resourceGuid);
      } else {
        setApplyLoading(false)
        console.error(
          "The file does not contain enough rows for headers and data."
        );
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const { cfunction: createTimeEntryData } = useMutationAuth(
    CREATE_TIME_ENTRIES_FROM_EXCEL,
    "CreateTimeEntriesFromExcelByManager"
  );

  function CreateTimeEntryData(values) {
    createTimeEntryData(
      {
        variables: {
          model: {
            timeEntries: values,
            userID:storeUserGuid
          },
        },
      },
      (response) => {
        if (
          response["CreateTimeEntriesFromExcelByManager"].errorCode !== null &&
          response["CreateTimeEntriesFromExcelByManager"].errorCode !== ""
        ) {
          enqueueSnackbar(
            t(response["CreateTimeEntriesFromExcelByManager"].errorMessage),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
        } else {
          enqueueSnackbar(t("timeEntryForm.createdSuccessfully"), {
            variant: "success",
          });
        }
        setApplyLoading(false)
setImportData([])
      },
      (error) => {
        setApplyLoading(false)
        setImportData([])

        console.log(error);
      }
    );
  }

 
  const handleSave = () => {
    handleClose();
  };
  const sendData = () => {
  var emptyField=false;
    if (importData !== null && importData.length > 0) {
    setApplyLoading(true)

      setExport(false)
      var dataToSend = importData.map((row, index) => {
        const rowData = {
          employeeGuid: employeeID,
          taskGuid: row["taskGuid"],
          projectName: row["projectName"],
          task: row['task'],
          
          description: row["description"],
          date: new Date(row["date"]).toDateString(),
          duration: parseFloat(row["duration"]),
        };
 // Check if any property in rowData is null or empty

  if (Object.values(rowData).some(m => m === "" || m === null || m === undefined)) { 
    emptyField=(isTimeEntryDescriptionMandatory && !row["description"]
      )
   
   const  rowData = {
       employeeGuid: employeeID,
          taskGuid: row["taskGuid"],
          projectName: row["projectName"],
          task: row['task'],
          
          description: row["description"],
          date:row["date"]===""||row["date"]===null||row["date"]===undefined?"": new Date(row["date"]).toDateString(),
          duration: row["duration"]===""||row["duration"]===null||row["duration"]===undefined?"":parseFloat(row["duration"]),
      error:t("excel.oneOrMoreFieldAreEmpty.message")
    };
    return { ...rowData };
  }
        return { ...rowData };
      });
      if(emptyField){
        setApplyLoading(false)
        setImportData(dataToSend)
        enqueueSnackbar(t("excel.errorImportDataFromExcel.message"), {
          variant: "error",
          autoHideDuration: 5000,
        })
      }else{
        CreateTimeEntryData(dataToSend);

      }

    }
  };
  const handleFileLoaded = ( dataRows, resourceGuid) => {
    setEmployeeID(resourceGuid)
    setExport(false)
    const filteredData = dataRows.filter(row => row[4] && row[5]);
    setImportData(filteredData?.map((row, index) => {
      const rowData = {
        "projectName": row[0],
        "taskGuid":row[1],
        "task":row[2],
        "description":row[3],
        "date":row[4],
        "duration":row[5],
        "error":""
      };
      return { id: index, ...rowData };
    }));
    setApplyLoading(false)
    setNumOfImportRecords(dataRows?.length??0);
  };
  const pagination=
    {
    rows: data,
   columns,
    numberRecords: numOfRecords,
    fetchTableData,
    loading: dataLoading,
    hideEditColumns: true,
    removeFilters: true,
    removeSelectAll: true,
    hideSearchBar: true,
    removeRefresh: true,
    removePagination: true,
    removeSorting: true,
  }
 
  const importPagination =  {
    rows: importData,
   columns: importTableColumns,
   numberRecords: numOfImportRecords,
   loading: false,
   hideEditColumns: true,
   removeFilters: true,
   removeSelectAll: true,
   hideSearchBar: true,
   removeRefresh: true,
   removePagination: true,
   removeSorting: true,
  };
  
  const handleClose = () => {
    setFileName("");
    setIncludeSubtasks(false);
    setOpenDialog(false);
  };

  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
   }  

  async function exportTimeToExcel(model) {
    setLoading(true);
    setExport(true)
    const res = await apiCall(
      backendURL + `/ExcelTimeEntry/exportTimeEntry`,
      "POST",
      model,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t,
      null,
      'blob'
    );

    if (res) {
      let fileName = model.FileName + ".xlsx"; // Default file name

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blob = new Blob([res.data], { type: fileType });

      FileSaver.saveAs(blob, fileName);
      enqueueSnackbar(t("report.exportSuccessfully"), { variant: "success" });
      setFetched(false);
      setLoading(false);
    }
    setFetched(false);
      setLoading(false);
  }

  const handleExport = () => {
    var tasks_list_data = {
      ResourseGuid: employeeValue.employeeGuid,
      FileName: employeeValue.firstName + employeeValue.lastName + Date.now(),
      ExcelTasks: tasks_list,
    };
    if (data != [] && data != null && data.length > 0)
      exportTimeToExcel(tasks_list_data);
    handleClose();
  };

  return (
    <>
      <FieldModal
        PaperComponent={PaperComponent}
        CustomButton={CustomButton}
        // content={t("dialog.file.name")}
        loading={false}
        openDialog={openDialog}
        handleClose={handleClose}
        setState={(v) => setFileName(v)}
        title={t("dialog.file.name")}
        label={t("task.list.exportToExcel.fileName")}
        t={t}
      />
       {loading ||Applyloading? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
             <div
              style={{
                width: "100%",
                padding: "13px 40px 0 10px",
                marginLeft: "17px",
              }}
            >
              <Outlet />
            </div>)}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
          }}
        >
          <Grid container spacing={1} display="flex" alignItems="center">
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <AutoComplete
                loading={employeeLoading}
                options={employeesList}
                getOptionLabel={(option) =>
                  option ? option.firstName + " " + option.lastName : ""
                }
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.firstName + " " + option.lastName}
                  </li>
                )}
                identifier={"employeeGuid"}
                size="small"
                value={employeeValue}
                onOpen={() => setFetchEmployees(true)}
                onClose={() => setFetchEmployees(false)}
                onChange={(event, value) => {
                  setEmployeeValue(value);
                  setEmployeeID(value?.employeeGuid);
                  setFetchProjects(false);
                  setProjectIDs([]);
                  setProjectsValue([]);
                  setData([]);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                }}
                roundedTextField
                label={t("reports.label.employee")}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <AutoComplete
                multiple
                loading={projectLoading}
                options={projectsList}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"projectGuid"}
                size="small"
                value={projectsValue}
                onOpen={() => setFetchProjects(true)}
                onClose={() => setFetchProjects(false)}
                onChange={(event, value) => {
                  setProjectsValue(value);
                  let tmp = [];
                  value.map((ids, inxex) => {
                    tmp.push(ids.projectGuid);
                    return tmp;
                  });
                  setProjectIDs(tmp);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                }}
                label={t("reports.label.projects")}
                roundedTextField
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <IALoadingButton
                onClick={() => setRe((val) => !val)}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                }}
                label={t("projectDashboard.Search")}
                disabled={ Applyloading}

                tooltipLabel={t("timesheet.tooltip.project.search")}
              />
              <IALoadingButton
                tooltipLabel={t("excel.tooltip.export")}
                onClick={() => handleExport()}
                loading={loading}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginLeft: "10px",
                }}
                label={t("task.list.exportToExcel")}
                disabled={!exportData || Applyloading || data.length<1}
              />
               <ReactFileReader
                fileTypes={[".xlsx"]}
                base64={true}
                handleFiles={(e) => handleFile(e)}
              >
                <IALoadingButton
                disabled={Applyloading }
style={{ marginLeft: "10px",}}
                  sx={{
                    ".MuiLoadingButton-root": {
                      BackgroundColor: "inherit !important",
                    },
                  }}
                  tooltipLabel={t("excel.tooltip.apply")}
                >
                  <UploadIcon width="25" height="25" color="#ffffff" />
                </IALoadingButton>
              </ReactFileReader>
              <IALoadingButton
              tooltipLabel={t("excel.tooltip.import")}
                onClick={sendData}
                loading={Applyloading}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginLeft: "10px",
                }}
                disabled={importLoading || exportData || Applyloading}
                label={t("importExcelBcFormat")}
              />
            </Grid>
          </Grid>

          {employeeValue != null &&
            (dataLoading 
              //&& columns.length === 0 
              ? (
              <ContainerCard
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <CircularProgress />
              </ContainerCard>
            ) : (
              <Grid>
                {exportData?t("DataToExport"):t("DataToImport")}
             { exportData?  <DataTable {...pagination} height={"calc(100vh - 370px)"} />
           : <DataTable {...importPagination} height={"calc(100vh - 370px)"} />
          } </Grid>
            ))}
        </ContainerCard>
      </Grid>
    </>
  );
}

export default ExcelTimeSheet;
