import React, { useState } from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, Typography, Grid, styled } from "@mui/material";
import { Title } from "../Titles/FormTitles";
import { ExpandLeftIcon, CollapseRightIcon } from "../Icon";
const WordWrapContainer = styled(Grid)({
  overflowWrap: "break-word",
  wordWrap: "break-word",
  hyphens: "auto",
  wordBreak: "break-all",
});
const SideInfo = ({ data, lessSpace = false}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box lineHeight={0}>
        <Title>{t("companyForm.sideInfo")}</Title>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {data.map((info) => (
            <Grid item xs={12} key={info[0]}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={lessSpace ? 8 : 5}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography variant="caption" fontSize="12px">{t(info[0] || "")}</Typography>
                </Grid>
                <WordWrapContainer
                  item
                  xs={lessSpace ? 4 : 7}
                  sx={{ display: "flex", justifyContent: "flex-end"}}
                >
                  <Typography variant="caption" fontWeight="bold" fontSize="9">
                    {info[1] || ""}
                  </Typography>
                </WordWrapContainer>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SideInfo;
