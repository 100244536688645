import checkout from "./form";

const {
  formField: { holidays, description },
} = checkout;

const initialValues = {
  [description.name]:"",
  [holidays.name]: [],
};

export default  initialValues;