import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//Exterior Components
import { Input } from "antd";
import { Typography } from "@mui/material";
//colors
import colorLight from "../../assets/theme/light/colors";

//links
import urls from "../../urls";

import { useDispatch } from "react-redux";

//translation
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
//hooks
import useForms from "../../hooks/useForms";
import useMutationAuth from "../../hooks/useMutationAuth";

//graphql
import { RESET_PASSWORD } from "../../graphql/user";

//snackbar
import { useSnackbar } from "notistack";
import IALoadingButton from "../../components/IAButtons/IALoadingButton";
import { logoutUser } from "../../app/reducers/userSlice";
import colors from "../../assets/theme/light/colors";

const ResetPassword = () => {
  //we get query params if needed
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { login } = urls;

  const { enqueueSnackbar } = useSnackbar();

  const { values, errors, touched, setValues, setTouched } = useForms();

  const { dark_logo_blue,light_logo_blue, error_light } = colorLight; //colors

  const { t } = useTranslation(); //translation
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

  const passwordRequirements = [
    "changePassword.first", "changePassword.second", "changePassword.third"
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <div key={itemKey} component="li" lineHeight={2} style={{direction:"flex", justifyContents:"center", alignItems:"flex-start", padding:"0 3px", color:`${colors.pure_white}`}}>
        <p variant="button" fontWeight="regular" style={{ fontSize: "12px" }}>
          <Trans components={{ br: <br /> }}> - {t(item)}</Trans>
        </p>
      </div>
    );
  });

  const { mfunction: resetPassword, loading } = useMutationAuth(
    RESET_PASSWORD,
    "ResetPassword"
  );

  const handleResetPassword = (event) => {
    event.preventDefault();


    if (!values.confirmPassword || !values.password) {
      if (!values.confirmPassword || errors.confirmPassword) {
        setValues("password", "confirmPassword", "");
        setTouched("confirmPassword");
      }

      if (!values.password || errors.password) {
        setValues("password", "password", "");
        setTouched("password");
      }
      return;
    }

    if (values.password !== values.confirmPassword) {
      enqueueSnackbar(t("passwordsDontMatch"), {
        variant: "error",
        autoHideDuration: 5000
      });
      return;
    }

    resetPassword(
      {
        variables: {
          model: {
            token: token,
            password: values.password,
            confirmPassword: values.confirmPassword,
          },
        },
      },
      () => {
        enqueueSnackbar(t("user.resetSuccessful"), {
          variant: "success",
        });
        dispatch(logoutUser());
        navigate(login);
      }
    );
  };
  return (
    <form onSubmit={handleResetPassword}>
      <div className="field-input">
        <div className="field-title" style={{ color: `${dark_logo_blue}` }}>
          <Typography variant="h6" component="h6" sx={{ color: colors.pure_white}}>
            {t("resetPassword.title")}
          </Typography>
          <Typography variant="caption" component="caption" sx={{ color: colors.pure_white}}>
            {t("resetPassword.text")}
          </Typography>
        </div>
        <br></br>
        <div className="field-input">
          <Input

            allowClear
            size="large"
            type="password"
            placeholder={t("resetPassword.password")}
           // prefix={<LockOutlined />}
            value={values.password}
            name="password"
            onFocus={() => setShowPasswordRequirements(true) || setTouched("password")}
            onBlur={() => setShowPasswordRequirements(false)}
            onChange={(e) => {
              setValues("password", "password", e.target.value);
            }}
            status={touched.password && errors.password ? "error" : ""}
          />
         
          <Typography
            variant="caption"
            component="caption"
            className="error-text"
            sx={{ color: `${error_light}`, width: "max-content !important", padding: "0 3px" }}
          >
            {touched.password && t(errors.password)}

          </Typography>
          {showPasswordRequirements && (
            <>{renderPasswordRequirements}
            </>


          )}
        </div>
        <div className="field-input">
          <Input
            allowClear
            size="large"
            type="password"
            placeholder={t("resetPassword.confirmPassword")}
            //prefix={<LockOutlined />}
            value={values.confirmPassword}
            name="confirmPassword"
            onFocus={() => setTouched("confirmPassword")}
            status={
              touched.confirmPassword && errors.confirmPassword ? "error" : ""
            }
            onChange={(e) => {
              setValues("password", "confirmPassword", e.target.value);
            }}
          />
          <Typography
            variant="caption"
            component="caption"
            className="error-text"
            sx={{ color: `${error_light}` }}
          >
            {touched.confirmPassword && t(errors.confirmPassword)}
          </Typography>
        </div>
        <br></br>
        <IALoadingButton
          sx={{ borderRadius: "50px !important", backgroundColor: light_logo_blue, boxShadow:"none !important", fontWeight:"bold !important" }}

          dark
          fullWidth
          type="submit"
          loading={loading}
          label={t("resetPassword.button")}
        />

      </div>
    </form>
  );
};

export default ResetPassword;
