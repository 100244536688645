import React, { useState, useEffect } from "react";

//routing
import { Link, useNavigate } from "react-router-dom";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

//external components
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  CircularProgress,
  IconButton,
  Modal,
  Grid,
  Typography,
  Button,
  Stack,
  Chip,
  Dialog,
} from "@mui/material";

import Tooltip from "../../Tooltip";

//filters
import { COPY_ITEMS, CLEAR_FILTERS } from "../../../types/filterTypes";

//icons
import {
  ClearOutlined as ClearOutlinedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

//translation
import { useTranslation } from "react-i18next";

//utils
import { visuallyHidden } from "@mui/utils";

//components
import IALoadingButton from "../../IAButtons/IALoadingButton";
import TableColumnsDnD from "../../DragAndDrops/TableColumnsDnD";

//styles
import "./style.css";
//hooks
import useFilters from "../../../hooks/useFilters";
import FilterField from "../../FilterField";
import ContainerCard from "../../Cards/ContainerCard";
import RoundedTextField from "../../TextFields/RoundedTextField";
import ConfirmationModal from "../../Modal/ConfirmationModal";

import ProfilePhoto from "../../../pages/admin-configuration/Employees/Employee-form/components/profilePhoto";
import colors from "../../../assets/theme/light/colors";
import {
  AppliedFiltersIcon,
  CloseIcon,
  CollapseRightIcon,
  DeleteIcon,
  EditColumnsIcon,
  ExpandLeftIcon,
  FilterIcon,
  QuickFilterIcon,
  RefreshIcon,
  UnlockIcon,
} from "../../Icon";

import CustomAlert from "../../CustomAlert/CustomAlert";
import ColumnResize from "react-table-column-resizer";
import CustomButton from "../../CustomButton";
import PaperComponent from "../../Modal/PaperComponent";
import SideInfo from "../../SideInfo";
import set from "date-fns/set/index.js";

//wait before search function call
const DELAY_BEFORE_SEARCH = 1000;
const { red_color } = colors;

export default function DataTable(props) {
  const {
    rows,
    columns,
    order,
    setOrder,
    isFixed,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    identifier,
    middlewareJob,
    numberRecords,
    hasCheckbox,
    fetchTableData,
    getTreeData,
    pagination,
    chipFilters,
    sorting,
    rowCount,
    search,
    setSearch,
    loading,
    searchText,
    tableMnemonic,
    refetch,
    filtersObject,
    generateRoute,
    newText,
    newLink,
    deleteText,
    handleDelete,
    defaultFilters,
    setDefaultFilters,
    removeFilters,
    hideEditColumns, // hides settings icon that changes order of table columns
    readOnly, // prop that indicates readOnly so no selecting or buttons
    hideSearchBar,
    fullObject, // prop that indicates that we must store the entire object and not just the ID on select from Checkbox
    customButtons, // props if user wants to enter custom buttons for specific tables other than new and delete
    onCheckbox, //props if we want something to happen on checkbox click
    checkForCheckbox,
    removeSelectAll, //prop if we want to remove the user's ability to select all columns
    checkboxDisable, //prop if we want to disable a specific checkbox
    removeRefresh,
    fromEmployee,
    disableAddBtn,
    linkRoute,
    linkCell,
    handleOpenModal,
    handleChangeState,
    tableSheetHeight,
    heightClassName,
    popUpTable,
    minWidth,
    users,
    removePagination,
    projectHeight,
    ticketHeight,
    height,
    withSideInfo = false,
    sideInfoData,
    singleSelect,
    removeSorting,
    quickFilters = false,
    chips = [],
    rightChips = [],
    disableRowClick = () => { },
    treeTable = false,
    handleDefaultFilters = () => { },
    setChipFilters = () => { },
    setChipFiltersClicked = () => { },
    handleShowQuickFilters = () => { },
    deleteIcon = false,
    setPagination = () => { },
    fixedWidth,
    handleRefresh,
    deletedRecords,
    showCustomDiv,
    savedFilterFlag = false,
    showUpdateFilterButton = false,
    handleUpdateFilter = () => { },
    handleSaveFilter = () => { },
    handleClearFilter = () => { },
    handleResetChipFilter = () => { },
    defaultChipFilters = null,
  } = props;

  const navigate = useNavigate();

  //get object to send to filters
  const { state: filtersState, dispatch: handleFilters } = useFilters();

  //to open filters
  const [openFilters, setOpenFilters] = useState(false);
  const [showQuickFilters, setShowQuickFilters] = useState(() => {
    const savedState = localStorage.getItem("showQuickFilters");
    return savedState === "true";
  });
  const [isFocused, setIsFocused] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [active, setActive] = useState(false);
  const [showSideInfo, setShowSideInfo] = useState(true);

  //timer for search functionality
  const [timer, setTimer] = useState(null);

  //opens Column Sort Change
  const [changeColumns, setChangeColumns] = useState(false);
  const { green_color, pure_white } = colors;
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem("showQuickFilters", showQuickFilters);
  }, [showQuickFilters]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleDelete();
    handleClose();
  };

  const handleOpenDeleteConfirmationModal = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (filtersState) {
      handleDefaultFilters(filtersState);
    }
  }, [filtersState]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleRequestSort = (event, property) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(property);
    if (fetchTableData)
      fetchTableData(
        page,
        rowsPerPage,
        order === "asc" ? "desc" : "asc",
        property,
        search,
        defaultFilters,
        chipFilters
      );
    if (getTreeData)
      getTreeData(
        chipFilters,
        sorting,
        rowCount,
        search,
        defaultFilters,
        pagination
      );
  };

  const handleSelectAllClick = (event) => {
    setActive(null);
    if (event.target.checked) {
      const newSelected = rows?.map((n) =>
        fullObject ? n : eval("n." + identifier)
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    // Clear active state
    setActive(null);

    // Index of the clicked item in the current selection
    const selectedIndex = fullObject
      ? selected.findIndex(
        (x) => eval("x." + identifier) === eval("name." + identifier)
      )
      : selected.indexOf(name);

    // New selection array
    let newSelected = [];

    if (!singleSelect) {
      // Multi-select logic
      if (selectedIndex === -1) {
        // Add the clicked item if it's not already selected
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        // Remove the first item from selection
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        // Remove the last item from selection
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        // Remove the clicked item if it's in between other selected items
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      // Single-select logic
      if (selectedIndex === -1) {
        // Add the clicked item if it's not already selected
        newSelected.push(name);
      } else {
        // Toggle the selection of the clicked item
        newSelected = selectedIndex === 0 ? [] : [name];
      }
    }

    // Update the selection state
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    if (setPage) setPage(newPage);
    if (fetchTableData)
      fetchTableData(
        newPage,
        rowsPerPage,
        order,
        orderBy,
        search,
        defaultFilters,
        chipFilters
      );
    if (getTreeData)
      getTreeData(
        chipFilters,
        sorting,
        rowCount,
        search,
        defaultFilters,
        pagination
      );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    if (setPage) setPage(0);
    if (fetchTableData)
      fetchTableData(
        0,
        parseInt(event.target.value, 10),
        order,
        orderBy,
        search,
        defaultFilters,
        chipFilters
      );
    if (getTreeData)
      getTreeData(
        chipFilters,
        sorting,
        rowCount,
        search,
        defaultFilters,
        pagination
      );
  };

  const handleSearch = (value) => {
    if (setPage) setPage(0);
    if (timer) {
      //if timer is set, we empty it so it's not called and wait another delay
      clearTimeout(timer);
      setTimer(null);
    }

    //set timer for after 2 seconds of wait
    setTimer(
      setTimeout(() => {
        if (fetchTableData)
          fetchTableData(
            0,
            rowsPerPage,
            order,
            orderBy,
            value,
            defaultFilters,
            chipFilters
          );
        if (getTreeData)
          getTreeData(
            chipFilters,
            sorting,
            rowCount,
            value,
            defaultFilters,
            pagination
          );
      }, DELAY_BEFORE_SEARCH)
    );
  };

  const isSelected = (name) =>
    name
      ? fullObject
        ? selected.findIndex((x) => eval("x." + identifier) === name) !== -1
        : selected.indexOf(name) !== -1
      : false;

  const handleLinkClick = (col, row) => {
    if (col.link === true) {
      handleOpenModal(row);
    }
  };

  //Table Header Component
  function EnhancedTableHead(props) {
    return (
      <TableHead>
        <TableRow>
          {hasCheckbox && (
            <TableCell
              padding="checkbox"
              style={{
                height: "50px",
                padding: "5px",
                zIndex: isFixed ? 21 : null,
                left: isFixed ? 0 : "inherit",
              }}
            >
              {!readOnly && !removeSelectAll && !singleSelect && (
                <Checkbox
                  color="primary"
                  disabled={!columns || columns.length === 0 || loading}
                  checkedIcon={
                    selected.length === numberRecords ? (
                      <CheckBoxIcon />
                    ) : (
                      <IndeterminateCheckBoxIcon />
                    )
                  }
                  checked={selected.length !== 0}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all",
                  }}
                />
              )}
            </TableCell>
          )}

          {columns &&
            columns.map((headCell, index2) => {

              if (headCell.visible === true)
                return (
                  <>
                    <TableCell
                      key={headCell.id}
                      align={headCell.align ? headCell.align : "left"}
                      style={{
                        height: "1.4375em",
                        padding: "5px",
                        zIndex: headCell.sticky ? 20 : null,
                        right:
                          withSideInfo && index2 === columns.length - 1
                            ? "-10px"
                            : null,
                      }}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{
                        margin: headCell.align === "left" ? "0 20px" : 0,
                        paddingRight:
                          headCell.align !== "right" ? "1em" : "1.5em",
                        position: headCell.sticky ? "sticky" : "sticky",
                        right: headCell.sticky
                          ? index2 === columns.length - 1
                            ? 0
                            : "auto"
                          : null,
                        left: headCell.sticky
                          ? index2 === 0
                            ? hasCheckbox
                              ? "50px"
                              : "0px"
                            : "inherit"
                          : "inherit",
                      }}
                    >
                      {/* ///TOOLTIPS */}
                      {headCell.id === "automaticallyClosed" ? (
                        <Tooltip
                          title={t("automaticcalyClosed.timesheet")}
                          arrow
                        >
                          <div>
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={(event) =>
                                handleRequestSort(event, headCell.id)
                              }
                              sx={{
                                paddingLeft:
                                  headCell.align !== "left" ? "1.5em" : "0em",
                                paddingRight:
                                  headCell.align !== "right" ? "0em" : "1.5em",
                                fontFamily: "poppins  !important",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {t(headCell.label)}
                              {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </div>
                        </Tooltip>
                      ) : headCell.id === "automaticallyClockedOut" ? (
                        <Tooltip
                          title={t("automaticcalyClosed.attendance")}
                          arrow
                        >
                          <div>
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={(event) =>
                                handleRequestSort(event, headCell.id)
                              }
                              sx={{
                                paddingLeft:
                                  headCell.align !== "left" ? "1.5em" : "0em",
                                paddingRight:
                                  headCell.align !== "right" ? "0em" : "1.5em",
                                fontFamily: "poppins  !important",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {t(headCell.label)}
                              {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </div>
                        </Tooltip>
                      ) : headCell.id === "PercentageComplete" ? (
                        <Tooltip title={t("progressBar.tooltip")} arrow>
                          <div>
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={(event) =>
                                handleRequestSort(event, headCell.id)
                              }
                              sx={{
                                paddingLeft:
                                  headCell.align !== "left" ? "1.5em" : "0em",
                                paddingRight:
                                  headCell.align !== "right" ? "0em" : "1.5em",
                                fontFamily: "poppins  !important",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {t(headCell.label)}
                              {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </div>
                        </Tooltip>
                      ) : !removeSorting ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={(event) =>
                            handleRequestSort(event, headCell.id)
                          }
                          sx={{
                            paddingLeft:
                              headCell.align !== "left" ? "1.5em" : "0em",
                            paddingRight:
                              headCell.align !== "right" ? "0em" : "1.5em",
                            fontFamily: "poppins  !important",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t(headCell.label)}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <div
                          style={{
                            fontFamily: "poppins",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {t(headCell.label)}
                        </div>
                      )}
                    </TableCell>

                    <ColumnResize id={headCell.id} className="columnResizer" />
                  </>
                );
              else return null;
            })}
        </TableRow>
      </TableHead>
    );
  }

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  const generateLeftChips = () => {
    return chips
      .filter((chip) => chip?.visible)
      .map((chip, index) => (
        <Tooltip key={index} title={chip?.tooltip}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "7px 5px 20px 0" }}
          >
            <Chip
              label={chip?.label}
              onClick={chip?.onClick}
              color={chip?.color}
              variant={chip?.variant}
              sx={chip?.sx}
              icon={chip?.icon}
            />
          </Stack>
        </Tooltip>
      ));
  };

  const generateRightChips = () => {
    return rightChips
      .filter((chip) => chip?.visible)
      .map((chip, index) => (
        <Tooltip key={index} title={chip?.tooltip}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "7px 5px 20px 0" }}
          >
            <Chip
              label={chip?.label}
              onClick={chip?.onClick}
              color={chip?.color}
              variant={chip?.variant}
              sx={chip?.sx}
              icon={chip?.icon}
            />
          </Stack>
        </Tooltip>
      ));
  };

  const handleButtonClick = () => {
    setFilterState([]);
    handleClearFilter();
    handleResetChipFilter();
    if (handleChangeState !== undefined) handleChangeState([]);
    
    handleFilters({
      type: CLEAR_FILTERS,
      payload: filtersObject?.reduce((acc, filter) => {
        acc[filter.name] = { disableClear: filter.disableClear === true };
        return acc;
      }, {}),
    });
    
    const filtersArray = filtersObject.filter(
      (filter) => filter.disableClear === true
    );
    let filters = {};
    filtersArray.forEach((filter) => {
      let name = filter.name;
      if (defaultFilters?.hasOwnProperty(name))
        filters = { ...filters, [name]: defaultFilters[name] };
    });
    setDefaultFilters(filters);
    if (setPage) setPage(0);
  
    setChipFilters((prev) => ({ ...prev, showParents: true }));
    setChipFiltersClicked(true);
  };
  
  
  
   

  return (
    <>
      <ConfirmationModal
        contentText={
          selected?.length > 1
            ? t("dialog.user.delete.records.confirmation")
            : t("dialog.user.delete.record.confirmation")
        }
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      {changeColumns ? (
        <TableColumnsDnD
          TableColumns={columns}
          SortID={orderBy}
          SortDirection={order}
          Table={tableMnemonic}
          handleClose={() => setChangeColumns(false)}
          refetch={refetch}
          ChangeOrder={(v) => setOrder(v)}
          ChangeOrderBy={(v) => setOrderBy(v)}
          refetchTableData={() => {
            if (fetchTableData)
              fetchTableData(
                page,
                rowsPerPage,
                order,
                orderBy,
                search,
                defaultFilters,
                chipFilters
              );
            if (getTreeData)
              getTreeData(
                chipFilters,
                sorting,
                rowCount,
                search,
                defaultFilters,
                pagination
              );
          }}
        />
      ) : null}
      <Dialog
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <ContainerCard
          sx={{ padding: "20px", width: "100%", overflowY: "auto" }}
          id="draggable-dialog-title"
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: "poppins",
                }}
              >
                {t("table.FilterTitle")}
              </Typography>
              <IconButton onClick={() => setOpenFilters(false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </Grid>
            {filtersObject &&
              filtersObject.map((filter, index) => {
                if (filter.skip) return null;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={filter.type == "radio-group" ? 12 : 6}
                    xl={filter.type == "radio-group" ? 12 : 6}
                    key={index}
                  >
                    <FilterField
                      {...filter}
                      filtersState={filtersState}
                      handleFilters={handleFilters}
                      handleSaveState={(v) => {
                        const objectIndex = filterState.findIndex(
                          (obj) => obj.property === v.property
                        );

                        if (objectIndex !== -1) {
                          // If the object exists, update the state by replacing the old object with the updated one
                          setFilterState((prevState) => [
                            ...prevState.slice(0, objectIndex),
                            v,
                            ...prevState.slice(objectIndex + 1),
                          ]);
                          if (handleChangeState !== undefined)
                            handleChangeState((prevState) => [
                              ...prevState.slice(0, objectIndex),
                              v,
                              ...prevState.slice(objectIndex + 1),
                            ]);
                        } else {
                          setFilterState([...filterState, v]);
                          if (handleChangeState !== undefined)
                            handleChangeState([...filterState, v]);
                        }
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>

          {savedFilterFlag ? (
            <div className={"filter-buttons-between"}>
              <span style={{ display: "flex", gap: "10px" }}>
                {showUpdateFilterButton && (
                  <IALoadingButton
                    label={t("table.filters.update")}
                    onClick={() => {
                      const filterObj = {
                        filters: filtersState,
                        quickFilters: chipFilters,
                      };
                      const res = handleUpdateFilter(
                        JSON.stringify(filterObj),
                        null,
                        false
                      );
                      setDefaultFilters(filtersState);
                      if (fetchTableData)
                        fetchTableData(
                          0,
                          rowsPerPage,
                          order,
                          orderBy,
                          search,
                          filtersState,
                          chipFilters
                        );
                      if (getTreeData)
                        getTreeData(
                          chipFilters,
                          sorting,
                          rowCount,
                          search,
                          filtersState,
                          pagination
                        );
                      if (setPage) setPage(0);
                      setOpenFilters(false);
                    }}
                  />
                )}
                <IALoadingButton
                  label={
                    showUpdateFilterButton
                      ? t("table.filters.save.new")
                      : t("table.filters.save")
                  }
                  onClick={() => {
                    const filterObj = {
                      filters: filtersState,
                      quickFilters: chipFilters,
                    };
                    const res = handleSaveFilter(JSON.stringify(filterObj));
                    setDefaultFilters(filtersState);
                    if (fetchTableData)
                      fetchTableData(
                        0,
                        rowsPerPage,
                        order,
                        orderBy,
                        search,
                        filtersState,
                        chipFilters
                      );
                    if (getTreeData)
                      getTreeData(
                        chipFilters,
                        sorting,
                        rowCount,
                        search,
                        filtersState,
                        pagination
                      );
                    if (setPage) setPage(0);
                    setOpenFilters(false);
                  }}
                />
              </span>
              <span style={{ display: "flex", gap: "10px" }}>
                <IALoadingButton
                  label={t("table.filters.clear")}
                  onClick={() => {
                    setFilterState([]);
                    handleClearFilter();
                    handleResetChipFilter();
                    if (handleChangeState !== undefined) handleChangeState([]);
                    handleFilters({
                      type: CLEAR_FILTERS,
                      payload: filtersObject?.reduce((acc, filter) => {
                        if (filter.disableClear === true) {
                          acc[filter.name] = { disableClear: false };
                        } else {
                          acc[filter.name] = { disableClear: true };
                        }
                        return acc;
                      }, {}),
                    });
                    const filtersArray = filtersObject.filter(
                      (filter) => filter.disableClear === true
                    );
                    let filters = {};
                    filtersArray.map((filter) => {
                      let name = filter.name;
                      if (defaultFilters?.hasOwnProperty(name))
                        filters = { ...filters, [name]: defaultFilters[name] };
                    });
                    setDefaultFilters(filters);
                    if (fetchTableData)
                      fetchTableData(
                        0,
                        rowsPerPage,
                        order,
                        orderBy,
                        search,
                        filters,
                        defaultChipFilters
                      );
                    if (getTreeData)
                      getTreeData(
                        defaultChipFilters,
                        sorting,
                        rowCount,
                        search,
                        filters,
                        pagination
                      );
                    if (setPage) setPage(0);
                  }}
                />
                <IALoadingButton
                  label={t("table.filters.apply")}
                  onClick={() => {
                    setDefaultFilters(filtersState);
                    handleClearFilter();
                    if (fetchTableData)
                      fetchTableData(
                        0,
                        rowsPerPage,
                        order,
                        orderBy,
                        search,
                        filtersState,
                        chipFilters
                      );
                    if (getTreeData)
                      getTreeData(
                        chipFilters,
                        sorting,
                        rowCount,
                        search,
                        filtersState,
                        pagination
                      );
                    if (setPage) setPage(0);
                    setOpenFilters(false);
                  }}
                />
              </span>
            </div>
          ) : (
            <div className={"filter-buttons"}>
              <IALoadingButton
                label={t("table.filters.clear")}
                onClick={() => {
                  setFilterState([]);
                  handleClearFilter();
                  handleResetChipFilter();
                  if (handleChangeState !== undefined) handleChangeState([]);
                  handleFilters({
                    type: CLEAR_FILTERS,
                    payload: filtersObject?.reduce((acc, filter) => {
                      if (filter.disableClear === true) {
                        acc[filter.name] = { disableClear: false };
                      } else {
                        acc[filter.name] = { disableClear: true };
                      }
                      return acc;
                    }, {}),
                  });
                  const filtersArray = filtersObject.filter(
                    (filter) => filter.disableClear === true
                  );
                  let filters = {};
                  filtersArray.map((filter) => {
                    let name = filter.name;
                    if (defaultFilters?.hasOwnProperty(name))
                      filters = { ...filters, [name]: defaultFilters[name] };
                  });
                  setDefaultFilters(filters);
                  if (fetchTableData)
                    fetchTableData(
                      0,
                      rowsPerPage,
                      order,
                      orderBy,
                      search,
                      filters,
                      defaultChipFilters
                    );
                  if (getTreeData)
                    getTreeData(
                      defaultChipFilters,
                      sorting,
                      rowCount,
                      search,
                      filters,
                      pagination
                    );
                  if (setPage) setPage(0);
                }}
              />
              <IALoadingButton
                label={t("table.filters.apply")}
                onClick={() => {
                  setDefaultFilters(filtersState);
                  handleClearFilter();
                  if (fetchTableData)
                    fetchTableData(
                      0,
                      rowsPerPage,
                      order,
                      orderBy,
                      search,
                      filtersState,
                      chipFilters
                    );
                  if (getTreeData)
                    getTreeData(
                      chipFilters,
                      sorting,
                      rowCount,
                      search,
                      filtersState,
                      pagination
                    );
                  if (setPage) setPage(0);
                  setOpenFilters(false);
                }}
              />
            </div>
          )}
        </ContainerCard>
      </Dialog>
      <Box>
        <div className="table-bar">
          {!hideSearchBar && (
            <RoundedTextField
              label={searchText ? t(searchText) : ""}
              fullWidth
              size="small"
              value={search}
              onFocus={() => {
                setActive(null);
                setIsFocused(true);
              }}
              onChange={(e) => {
                setSearch(e.target.value);
                handleSearch(e.target.value);
              }}
              inputProps={{
                style: {
                  backgroundColor: "white",
                  borderRadius: "5px",
                  height: "15px",
                  fontSize: "14px",
                },
              }}
              InputProps={{
                endAdornment: isFocused ? (
                  <IconButton
                    onClick={() => {
                      setSearch("");
                      handleSearch("");
                      setIsFocused(false);
                    }}
                  >
                    <CloseIcon color={"#979797"} width={"24"} stroke={"2"} />
                  </IconButton>
                ) : (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                style: {
                  backgroundColor: "white",
                  paddingRight: "0",
                  opacity: 0.7,
                  height: "33px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px", // Adjust the font size as needed
                  lineHeight: 1.1,
                },
              }}
            />
          )}
          {showCustomDiv && (
            <div style={{ width: "auto" }}>{showCustomDiv()}</div>
          )}
          {!removeFilters &&
            (JSON.stringify(defaultFilters) === "{}" ? (
              <CustomAlert color="info" severity="info">
                {t("gridTable.noFilters")}
              </CustomAlert>
            ) : (
              // <HtmlTooltip
              //   title={
              //     <React.Fragment>
              //       {Object.entries(filterText).map((value) => {
              //         return (
              //           <p>
              //             {value[0]}:{" "}
              //             {typeof value[1] === "object" ? value[1].toString() : value[1]}
              //           </p>
              //         );
              //       })}
              //     </React.Fragment>
              //   }
              // >
              <IALoadingButton
                label={t("gridTable.filtersApplied")}
                style={{
                  backgroundColor: colors.light_logo_blue,
                  minWidth: "8rem",
                  whiteSpace: "nowrap",
                  ".MuiLoadingButton-root": { width: "10% !important " },
                }}
                onClick={handleButtonClick}
              />
              // </HtmlTooltip>
            ))}
          {!removeRefresh && (
            <Tooltip title={t("table.RefreshBtn")}>
              <IconButton
                onClick={() => {
                  setActive(null);
                  if (fetchTableData)
                    fetchTableData(
                      0,
                      rowsPerPage,
                      order,
                      orderBy,
                      search,
                      defaultFilters,
                      chipFilters
                    );
                  if (getTreeData)
                    getTreeData(
                      chipFilters,
                      sorting,
                      rowCount,
                      search,
                      defaultFilters,
                      { pageIndex: 0, pageSize: rowsPerPage }
                    );
                  if (setPage) setPage(0);
                  if (setPagination)
                    setPagination({ pageIndex: 0, pageSize: rowsPerPage });
                }}
              >
                <RefreshIcon color="#000000" />
              </IconButton>
            </Tooltip>
          )}
          {quickFilters &&
            (showQuickFilters ? (
              <Tooltip title={t("table.QuickFilterTitle")}>
                <IconButton
                  onClick={() => {
                    setShowQuickFilters(!showQuickFilters);
                    handleShowQuickFilters(!showQuickFilters);
                  }}
                >
                  <QuickFilterIcon color="var(--color--green-color)" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("table.QuickFilterTitle")}>
                <IconButton
                  onClick={() => {
                    setShowQuickFilters(!showQuickFilters);
                    handleShowQuickFilters(!showQuickFilters);
                  }}
                >
                  <QuickFilterIcon color="#000000" />
                </IconButton>
              </Tooltip>
            ))}

          {!removeFilters &&
            (JSON.stringify(defaultFilters) === "{}" ? (
              <Tooltip title={t("table.FilterTitle")}>
                <IconButton
                  onClick={() => {
                    setActive(null);
                    handleFilters({
                      type: COPY_ITEMS,
                      payload: { value: defaultFilters },
                    });
                    setOpenFilters(true);
                  }}
                >
                  <FilterIcon color="#000000" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("table.FilterTitleApplied")}>
                <IconButton
                  onClick={() => {
                    setActive(null);
                    handleFilters({
                      type: COPY_ITEMS,
                      payload: { value: defaultFilters },
                    });
                    setOpenFilters(true);
                  }}
                >
                  <AppliedFiltersIcon color="var(--color--green-color)" />
                </IconButton>
              </Tooltip>
            ))}
          {!readOnly && newText && (!selected || selected.length === 0) ? (
            <Tooltip title={t("table.AddNew")}>
              <IconButton
                onClick={() => {
                  setActive(null);
                  navigate(newLink);
                }}
                disabled={disableAddBtn === true}
              >
                <AddCircleRoundedIcon
                  sx={{
                    color: "var(--color--light-logo-blue)",
                    fontSize: "28px !important",
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {customButtons &&
            customButtons.map((button, index) => {
              if (button.condition)
                if (button.icon)
                  return (
                    <Tooltip title={t(button.label)} key={index}>
                      <IconButton
                        onClick={(e) => {
                          setActive(null);
                          button.handleClick(e);
                        }}
                        disabled={button.disabled}
                      >
                        {button.iconButton}
                      </IconButton>
                    </Tooltip>
                  );
                else
                  return (
                    <IALoadingButton
                      key={index}
                      label={t(button.label)}
                      className="new-record-btn"
                      dark
                      error={button.error}
                      startIcon={button.startIcon}
                      loading={button.loading}
                      disabled={button.disabled}
                      onClick={() => {
                        setActive(null);
                        button.handleClick();
                      }}
                    />
                  );
              else return null;
            })}

          {deleteText && selected && selected.length > 0 && users ? (
            <Tooltip title={t("table.Unlock")}>
              <IconButton onClick={handleOpenDeleteConfirmationModal}>
                <UnlockIcon color={green_color} />
              </IconButton>
            </Tooltip>
          ) : null}

          {(deleteText &&
            selected &&
            selected.length > 0 &&
            !readOnly &&
            !users) ||
            deleteIcon ? (
            <Tooltip title={t("table.Delete")}>
              <IconButton onClick={handleOpenDeleteConfirmationModal}>
                <DeleteIcon color={red_color} />
              </IconButton>
            </Tooltip>
          ) : null}

          {!hideEditColumns && (
            <Tooltip title={t("table.EditColumns")}>
              <IconButton
                onClick={() => {
                  setChangeColumns((prev) => !prev);
                  setActive(null);
                }}
              >
                <EditColumnsIcon color="#000000" />
              </IconButton>
            </Tooltip>
          )}
          {withSideInfo &&
            (showSideInfo ? (
              <Tooltip title={t("tooltip.hide.sideInfo")}>
                <IconButton onClick={() => setShowSideInfo(!showSideInfo)}>
                  <ExpandLeftIcon color={"#000"} width={"22"} height={"22"} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("tooltip.show.sideInfo")}>
                <IconButton onClick={() => setShowSideInfo(!showSideInfo)}>
                  <CollapseRightIcon
                    color={"#000"}
                    width={"22"}
                    height={"22"}
                  />
                </IconButton>{" "}
              </Tooltip>
            ))}
        </div>
        {showQuickFilters && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <span style={{ display: "flex", flexWrap: "wrap" }}>
              {generateLeftChips()}
            </span>
            <span style={{ display: "flex", flexWrap: "wrap" }}>
              {generateRightChips()}
            </span>
          </div>
        )}

        {!fromEmployee && !treeTable ? (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={withSideInfo && showSideInfo ? 10 : 12}
            >
              <Box sx={{ width: "100%" }}>
                <Paper
                  sx={{
                    width: fixedWidth ? fixedWidth : "100%",
                    mb: 0,
                    borderRadius: "5px",
                  }}
                >
                  <>
                    {" "}
                    <TableContainer
                      className={
                        heightClassName ? `${heightClassName}` : "table-height"
                      }
                      sx={{
                        borderRadius: "5px",
                        overflowX: "auto",
                        height: height
                          ? `${height} !important`
                          : "inherit !important",
                      }}
                    >
                      <Table
                        sx={{ minWidth: minWidth ? minWidth : 750 }}
                        stickyHeader
                      >
                        <EnhancedTableHead />
                        <TableBody>
                          {loading ? (
                            <TableRow tabIndex={-1} key="loading">
                              <TableCell
                                component="td"
                                id={"loading"}
                                scope="row"
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  height: "1.4375em",
                                  borderBottom: "none",
                                  minWidth: "100%",
                                }}
                                padding="none"
                                colSpan={(columns.length + 1) * 2}
                              >
                                <CircularProgress />
                              </TableCell>
                            </TableRow>
                          ) : null}
                          {!loading && rows && rows?.length === 0 ? (
                            <TableRow
                              tabIndex={-1}
                              key="no data"
                              sx={middlewareJob && { height: "2.5rem" }}
                            >
                              <TableCell
                                component="td"
                                id={"no data"}
                                scope="row"
                                sx={{
                                  color: "grey",
                                  textAlign: "center",
                                  padding: "5px",
                                  fontFamily: "poppins  !important",
                                  height: "1.4375em",
                                  borderBottom: "none",
                                  minWidth: "100%",
                                }}
                                padding="none"
                                colSpan={(columns.length + 1) * 2}
                              >
                                {t("table.noData")}
                              </TableCell>
                            </TableRow>
                          ) : null}

                          {!loading &&
                            rows &&
                            rows?.map((row, index) => {
                              const itemID = eval("row." + identifier);
                              const isItemSelected = isSelected(itemID);
                              const labelId = `table-row-${index}`;
                              const keys = identifier?.split(".") || [
                                identifier,
                              ];
                              const value = keys.reduce(
                                (obj, key) => obj[key],
                                row
                              );
                              const _disabled = checkboxDisable
                                ? checkboxDisable(row)
                                : false;
                              return (
                                <TableRow
                                  hover={linkCell === true ? false : true}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={eval("row." + identifier)}
                                  selected={isItemSelected}
                                  sx={
                                    !hasCheckbox
                                      ? { height: "40px" }
                                      : middlewareJob
                                        ? { height: "2rem" }
                                        : { cursor: "pointer " }
                                  }
                                >
                                  {hasCheckbox ? (
                                    <TableCell
                                      padding="checkbox"
                                      sx={{
                                        padding: "5px",
                                        height: "1.4375em",
                                        position: isFixed ? "sticky" : null,
                                        left: 0,
                                        top: 0,
                                        right:
                                          withSideInfo == true
                                            ? "-10px !important"
                                            : null,
                                        zIndex: isFixed ? 20 : 0,
                                        borderBottom: "none !important",
                                        borderTop: "none !important",
                                        backgroundColor:
                                          active === value
                                            ? colors.light_background_color
                                            : isFixed
                                              ? pure_white
                                              : "transparent",
                                        cursor: deletedRecords?.includes(
                                          eval("row." + identifier)
                                        )
                                          ? "default"
                                          : "initial",
                                      }}
                                    >
                                      {!readOnly &&
                                        (checkForCheckbox !== undefined
                                          ? checkForCheckbox(row)
                                          : true) && (
                                          <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            disabled={
                                              _disabled ||
                                              deletedRecords?.includes(
                                                eval("row." + identifier)
                                              )
                                            }
                                            inputProps={{
                                              "aria-labelledby": labelId,
                                            }}
                                            sx={
                                              deletedRecords?.includes(
                                                eval("row." + identifier)
                                              )
                                                ? {
                                                  ".MuiSvgIcon-root": {
                                                    fill: "grey !important",
                                                  },
                                                  cursor: "default",
                                                }
                                                : {}
                                            }
                                            onClick={(event) => {
                                              if (onCheckbox)
                                                onCheckbox(
                                                  removeSelectAll
                                                    ? true
                                                    : false,
                                                  row,
                                                  selected
                                                );
                                              handleClick(
                                                event,
                                                fullObject
                                                  ? row
                                                  : eval("row." + identifier)
                                              );
                                            }}
                                          />
                                        )}
                                    </TableCell>
                                  ) : null}
                                  {columns &&
                                    columns.map((column, index2) => {
                                      const columnWidth = column.width
                                        ? `${column.width}px`
                                        : "auto";
                                      if (linkCell === true) {
                                        if (column.visible === true) {
                                          return handleOpenModal instanceof
                                            Function ===
                                            true ? (
                                            <>
                                              <TableCell
                                                component={"td"}
                                                id={labelId}
                                                scope="row"
                                                align={column.align}
                                                key={index2}
                                                style={{
                                                  position: column.sticky
                                                    ? "sticky"
                                                    : "relative",
                                                  left:
                                                    column.sticky && hasCheckbox
                                                      ? "50px"
                                                      : 0,
                                                  zIndex: column.sticky ? 2 : 1,
                                                  top: 0,
                                                  backgroundColor: column.sticky
                                                    ? pure_white
                                                    : "transparent",
                                                  right:
                                                    index2 ===
                                                      columns.length - 1
                                                      ? withSideInfo == true
                                                        ? "-10px"
                                                        : 0
                                                      : "auto",
                                                }}
                                                onClick={() => {
                                                  if (column.link === true)
                                                    handleOpenModal(row);
                                                }}
                                                className={
                                                  linkCell === true &&
                                                    column.link === true
                                                    ? "link-cell"
                                                    : ""
                                                }
                                                sx={{
                                                  maxWidth: `${columnWidth} !important`,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  cursor: "default",
                                                  textDecoration: "none",
                                                  fontFamily:
                                                    "poppins  !important",
                                                  whiteSpace: "nowrap",
                                                  height: "1.4375em",
                                                  padding: "5px",
                                                  borderBottom:
                                                    "none !important",
                                                  borderTop: "none !important",

                                                  borderTop: "none !important",
                                                  paddingRight:
                                                    column.align !== "right"
                                                      ? "1em"
                                                      : "1.5em",
                                                  maxWidth: column.width
                                                    ? column.width
                                                    : "initial",
                                                  color:
                                                    deletedRecords?.includes(
                                                      eval("row." + identifier)
                                                    )
                                                      ? "grey"
                                                      : "initial",
                                                }}
                                              >
                                                {column.renderCell(row)}
                                              </TableCell>
                                              <td
                                                className={`column_resizer_body ${active === value
                                                    ? "grey_background"
                                                    : ""
                                                  }`}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <TableCell
                                                key={index2}
                                                component={"td"}
                                                id={labelId}
                                                scope="row"
                                                align={column.align}
                                                style={{
                                                  position: column.sticky
                                                    ? "sticky"
                                                    : "relative",
                                                  left:
                                                    column.sticky && hasCheckbox
                                                      ? "50px"
                                                      : 0,
                                                  zIndex: column.sticky ? 2 : 1,
                                                  top: 0,
                                                  backgroundColor: column.sticky
                                                    ? pure_white
                                                    : "transparent",
                                                  right:
                                                    index2 ===
                                                      columns.length - 1
                                                      ? withSideInfo == true
                                                        ? "-10px"
                                                        : 0
                                                      : "auto",
                                                }}
                                                onClick={() =>
                                                  handleLinkClick(column, row)
                                                }
                                                className={
                                                  linkCell === true &&
                                                    column.link === true
                                                    ? "link-cell"
                                                    : ""
                                                }
                                                sx={{
                                                  maxWidth: `${columnWidth} !important`,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  cursor: "default",
                                                  textDecoration: "none",
                                                  fontFamily:
                                                    "poppins  !important",
                                                  whiteSpace: "nowrap",
                                                  height: "1.4375em",
                                                  padding: "5px",
                                                  borderBottom:
                                                    "none !important",
                                                  borderTop: "none !important",
                                                  borderTop: "none !important",
                                                  paddingRight:
                                                    column.align !== "right"
                                                      ? "1em"
                                                      : "1.5em",
                                                  maxWidth: column.width
                                                    ? column.width
                                                    : "initial",
                                                  color:
                                                    deletedRecords?.includes(
                                                      eval("row." + identifier)
                                                    )
                                                      ? "grey"
                                                      : "initial",
                                                }}
                                              >
                                                {column.renderCell(row)}
                                              </TableCell>
                                              <td
                                                className={`column_resizer_body ${active === value
                                                    ? "grey_background"
                                                    : ""
                                                  }`}
                                              />
                                            </>
                                          );
                                        } else return null;
                                      } else {
                                        if (column.visible === true) {
                                          return handleOpenModal instanceof
                                            Function ===
                                            true ? (
                                            <>
                                              <TableCell
                                                component="td"
                                                id={labelId}
                                                key={index2}
                                                scope="row"
                                                align={column.align}
                                                onClick={(e) => {
                                                  handleOpenModal(row);
                                                  setActive(value);
                                                }}
                                                sx={{
                                                  maxWidth: `${columnWidth} !important`,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  cursor:
                                                    disableRowClick(row) ===
                                                      true
                                                      ? "default"
                                                      : generateRoute
                                                        ? "pointer "
                                                        : "default",
                                                  textDecoration: "none",
                                                  fontFamily:
                                                    "poppins  !important",
                                                  whiteSpace: "nowrap",
                                                  height: "1.4375em",
                                                  padding: "5px",
                                                  borderBottom:
                                                    "none !important",
                                                  borderTop: "none !important",
                                                  borderTop: "none !important",
                                                  paddingRight:
                                                    column.align !== "right"
                                                      ? "1em"
                                                      : "1.5em",
                                                  position: column.sticky
                                                    ? "sticky"
                                                    : "relative",
                                                  left:
                                                    column.sticky && hasCheckbox
                                                      ? "50px"
                                                      : 0,
                                                  zIndex: column.sticky ? 2 : 1,
                                                  top: 0,
                                                  backgroundColor:
                                                    active === value
                                                      ? colors.light_background_color
                                                      : column.sticky
                                                        ? pure_white
                                                        : "transparent",
                                                  right:
                                                    index2 ===
                                                      columns.length - 1
                                                      ? withSideInfo == true
                                                        ? "-10px"
                                                        : 0
                                                      : "auto",
                                                  maxWidth: column.width
                                                    ? column.width
                                                    : "initial",
                                                  color:
                                                    deletedRecords?.includes(
                                                      eval("row." + identifier)
                                                    )
                                                      ? "grey"
                                                      : "initial",
                                                }}
                                              >
                                                {column.renderCell(row)}
                                              </TableCell>
                                              <td
                                                className={`column_resizer_body ${active === value
                                                    ? "grey_background"
                                                    : ""
                                                  }`}
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <TableCell
                                                component={
                                                  generateRoute ? Link : "td"
                                                }
                                                to={
                                                  generateRoute
                                                    ? generateRoute(row)
                                                    : ""
                                                }
                                                id={labelId}
                                                scope="row"
                                                align={column.align}
                                                key={index2}
                                                sx={{
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  maxWidth: `${columnWidth} !important`,
                                                  cursor:
                                                    disableRowClick(row) ===
                                                      true
                                                      ? "default"
                                                      : generateRoute
                                                        ? "pointer "
                                                        : "default",
                                                  textDecoration: "none",
                                                  fontFamily:
                                                    "poppins  !important",
                                                  whiteSpace: "nowrap",
                                                  height: "1.4375em",
                                                  padding: "5px",
                                                  borderBottom:
                                                    "none !important",
                                                  borderTop: "none !important",
                                                  paddingRight:
                                                    column.align !== "right"
                                                      ? "1em"
                                                      : "1.5em",
                                                  position: column.sticky
                                                    ? "sticky"
                                                    : "relative",
                                                  left:
                                                    column.sticky && hasCheckbox
                                                      ? "50px"
                                                      : 0,
                                                  zIndex: column.sticky ? 2 : 1,
                                                  top: 0,
                                                  backgroundColor: column.sticky
                                                    ? pure_white
                                                    : "transparent",
                                                  right:
                                                    index2 ===
                                                      columns.length - 1
                                                      ? withSideInfo == true
                                                        ? "-10px"
                                                        : 0
                                                      : "auto",
                                                  maxWidth: column.width
                                                    ? column.width
                                                    : "initial",
                                                  color:
                                                    deletedRecords?.includes(
                                                      eval("row." + identifier)
                                                    )
                                                      ? "grey"
                                                      : "initial",
                                                }}
                                              >
                                                {column.renderCell(row)}
                                              </TableCell>
                                              <td
                                                className={`column_resizer_body ${active === value
                                                    ? "grey_background"
                                                    : ""
                                                  }`}
                                              />
                                            </>
                                          );
                                        } else return null;
                                      }
                                    })}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {removePagination === true ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            ".MuiTypography-root": {
                              fontSize: "14px !important",
                            },
                          }}
                        >
                          {selected && selected?.length > 0 && (
                            <Typography ml={2} variant="body1">{`${selected?.length
                              } row${selected?.length !== 1 ? "s" : ""
                              } selected`}</Typography>
                          )}
                        </Box>
                        <TablePagination
                          rowsPerPageOptions={[20, 50, 100]}
                          component="div"
                          count={numberRecords}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    )}
                  </>
                </Paper>
              </Box>
            </Grid>
            {withSideInfo !== undefined &&
              withSideInfo !== null &&
              withSideInfo == true &&
              showSideInfo == true && (
                <Grid
                  item
                  xs={showSideInfo ? 12 : 0}
                  sm={showSideInfo ? 12 : 0}
                  md={showSideInfo ? 12 : 0}
                  lg={showSideInfo ? 2 : 0}
                  pl={1}
                >
                  <ContainerCard
                    sx={{ boxShadow: "0px 0px 10px rgba(24,117,214,255)" }}
                  >
                    <Box p={2}>
                      <SideInfo
                        lessSpace
                        data={Object.entries(
                          sideInfoData !== undefined && sideInfoData !== null
                            ? sideInfoData
                            : null
                        )}
                      />
                    </Box>
                  </ContainerCard>
                </Grid>
              )}
          </Grid>
        ) : (
          !treeTable && (
            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <Paper
                    sx={{
                      width: fixedWidth ? fixedWidth : "100%",
                      mb: 0,
                      borderRadius: "5px",
                    }}
                  >
                    <>
                      <TableContainer
                        className={
                          heightClassName
                            ? `${heightClassName}`
                            : popUpTable
                              ? "table-pop-up-height"
                              : tableSheetHeight
                                ? "table-sheet-height"
                                : ticketHeight
                                  ? "table-task-ticket-height"
                                  : projectHeight
                                    ? "table-project-height"
                                    : showQuickFilters
                                      ? "table-filter-height"
                                      : "table-height"
                        }
                        sx={{ borderRadius: "5px", overflowX: "auto" }}
                      >
                        <Table
                          sx={{ minWidth: minWidth ? minWidth : 750 }}
                          stickyHeader
                        >
                          <EnhancedTableHead />
                          <TableBody>
                            {loading ? (
                              <TableRow tabIndex={-1} key="loading">
                                <>
                                  <TableCell
                                    component="td"
                                    id={"loading"}
                                    scope="row"
                                    style={{
                                      textAlign: "center",
                                      padding: "5px",
                                      height: "1.4375em",
                                      maxWidth: "initial",
                                      borderBottom: "none",
                                      minWidth: "100%",
                                    }}
                                    padding="none"
                                    colSpan={(columns.length + 1) * 2}
                                  >
                                    <CircularProgress />
                                  </TableCell>
                                  <td className={`column_resizer_body`} />
                                </>
                              </TableRow>
                            ) : null}
                            {!loading && rows && rows.length === 0 ? (
                              <TableRow
                                tabIndex={-1}
                                key="no data"
                                sx={middlewareJob && { height: "2.5rem" }}
                              >
                                <>
                                  <TableCell
                                    component="td"
                                    id={"no data"}
                                    scope="row"
                                    sx={{
                                      color: "grey",
                                      textAlign: "center",
                                      padding: "5px",
                                      fontFamily: "poppins  !important",
                                      height: "1.4375em",
                                      maxWidth: "initial",
                                      borderBottom: "none",
                                      minWidth: "100%",
                                    }}
                                    padding="none"
                                    colSpan={(columns.length + 1) * 2}
                                  >
                                    {t("table.noData")}
                                  </TableCell>
                                  <td className={`column_resizer_body`} />
                                </>
                              </TableRow>
                            ) : null}

                            {!loading &&
                              rows &&
                              rows.map((row, index) => {
                                const itemID = eval("row." + identifier);
                                const isItemSelected = isSelected(itemID);
                                const labelId = `table-row-${index}`;
                                const keys = identifier?.split(".") || [
                                  identifier,
                                ];
                                const value = keys.reduce(
                                  (obj, key) => obj[key],
                                  row
                                );
                                const _disabled = checkboxDisable
                                  ? checkboxDisable(row)
                                  : false;
                                return (
                                  <TableRow
                                    hover={linkCell === true ? false : true}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={eval("row." + identifier)}
                                    selected={isItemSelected}
                                    sx={
                                      !hasCheckbox
                                        ? { height: "40px" }
                                        : middlewareJob
                                          ? { height: "2rem" }
                                          : { cursor: "pointer " }
                                    }
                                  >
                                    {hasCheckbox ? (
                                      <TableCell
                                        padding="checkbox"
                                        sx={{
                                          padding: "5px",
                                          height: "1.4375em",
                                          position: isFixed ? "sticky" : null,
                                          left: 0,
                                          top: 0,
                                          zIndex: isFixed ? 20 : 0,
                                          borderBottom: "none !important",
                                          borderTop: "none !important",
                                          backgroundColor: isFixed
                                            ? pure_white
                                            : "transparent",
                                        }}
                                      >
                                        {!readOnly &&
                                          (checkForCheckbox !== undefined
                                            ? checkForCheckbox(row)
                                            : true) && (
                                            <Checkbox
                                              color="primary"
                                              checked={isItemSelected}
                                              disabled={_disabled}
                                              inputProps={{
                                                "aria-labelledby": labelId,
                                              }}
                                              onClick={(event) => {
                                                if (onCheckbox)
                                                  onCheckbox(
                                                    removeSelectAll
                                                      ? true
                                                      : false,
                                                    row,
                                                    selected
                                                  );
                                                handleClick(
                                                  event,
                                                  fullObject
                                                    ? row
                                                    : eval("row." + identifier)
                                                );
                                              }}
                                            />
                                          )}
                                      </TableCell>
                                    ) : null}
                                    {columns &&
                                      columns.map((column, index2) => {
                                        if (linkCell === true) {
                                          if (column.visible === true) {
                                            return handleOpenModal instanceof
                                              Function ===
                                              true ? (
                                              <>
                                                <TableCell
                                                  component={"td"}
                                                  id={labelId}
                                                  key={index2}
                                                  scope="row"
                                                  align={column.align}
                                                  style={{
                                                    position: column.sticky
                                                      ? "sticky"
                                                      : "relative",
                                                    left:
                                                      column.sticky &&
                                                        hasCheckbox
                                                        ? "50px"
                                                        : 0,
                                                    zIndex: column.sticky
                                                      ? 2
                                                      : 1,
                                                    top: 0,
                                                    backgroundColor:
                                                      column.sticky
                                                        ? pure_white
                                                        : "transparent",
                                                    right:
                                                      index2 ===
                                                        columns.length - 1
                                                        ? 0
                                                        : "auto",
                                                  }}
                                                  onClick={() => {
                                                    if (column.link === true)
                                                      handleOpenModal(row);
                                                  }}
                                                  className={
                                                    linkCell === true &&
                                                      column.link === true
                                                      ? "link-cell"
                                                      : ""
                                                  }
                                                  sx={{
                                                    cursor: "default",
                                                    textDecoration: "none",
                                                    fontFamily:
                                                      "poppins  !important",
                                                    whiteSpace: "nowrap",
                                                    height: "1.4375em",
                                                    padding: "5px",
                                                    borderBottom:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    paddingRight:
                                                      column.align !== "right"
                                                        ? "1em"
                                                        : "1.5em",
                                                    maxWidth: column.width
                                                      ? column.width
                                                      : "initial",
                                                    color:
                                                      deletedRecords?.includes(
                                                        eval(
                                                          "row." + identifier
                                                        )
                                                      )
                                                        ? "grey"
                                                        : "initial",
                                                  }}
                                                >
                                                  {column.renderCell(row)}
                                                </TableCell>
                                                <td
                                                  className={`column_resizer_body ${active === value
                                                      ? "grey_background"
                                                      : ""
                                                    }`}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <TableCell
                                                  component={"td"}
                                                  id={labelId}
                                                  scope="row"
                                                  align={column.align}
                                                  key={index2}
                                                  style={{
                                                    position: column.sticky
                                                      ? "sticky"
                                                      : "relative",
                                                    left:
                                                      column.sticky &&
                                                        hasCheckbox
                                                        ? "50px"
                                                        : 0,
                                                    zIndex: column.sticky
                                                      ? 2
                                                      : 1,
                                                    top: 0,
                                                    backgroundColor:
                                                      column.sticky
                                                        ? pure_white
                                                        : "transparent",
                                                    right:
                                                      index2 ===
                                                        columns.length - 1
                                                        ? 0
                                                        : "auto",
                                                  }}
                                                  onClick={() =>
                                                    handleLinkClick(column, row)
                                                  }
                                                  className={
                                                    linkCell === true &&
                                                      column.link === true
                                                      ? "link-cell"
                                                      : ""
                                                  }
                                                  sx={{
                                                    cursor: "default",
                                                    textDecoration: "none",
                                                    fontFamily:
                                                      "poppins  !important",
                                                    whiteSpace: "nowrap",
                                                    height: "1.4375em",
                                                    padding: "5px",
                                                    borderBottom:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    paddingRight:
                                                      column.align !== "right"
                                                        ? "1em"
                                                        : "1.5em",
                                                    maxWidth: column.width
                                                      ? column.width
                                                      : "initial",
                                                    color:
                                                      deletedRecords?.includes(
                                                        eval(
                                                          "row." + identifier
                                                        )
                                                      )
                                                        ? "grey"
                                                        : "initial",
                                                  }}
                                                >
                                                  {column.renderCell(row)}
                                                </TableCell>
                                                <td
                                                  className={`column_resizer_body ${active === value
                                                      ? "grey_background"
                                                      : ""
                                                    }`}
                                                />
                                              </>
                                            );
                                          } else return null;
                                        } else {
                                          if (column.visible === true) {
                                            return handleOpenModal instanceof
                                              Function ===
                                              true ? (
                                              <>
                                                <TableCell
                                                  component={Link}
                                                  to={
                                                    generateRoute
                                                      ? generateRoute(row)
                                                      : ""
                                                  }
                                                  id={labelId}
                                                  scope="row"
                                                  key={index2}
                                                  align={column.align}
                                                  onClick={(e) => {
                                                    handleOpenModal(row);
                                                  }}
                                                  sx={{
                                                    cursor:
                                                      disableRowClick(row) ===
                                                        true
                                                        ? "default"
                                                        : generateRoute
                                                          ? "pointer "
                                                          : "default",
                                                    textDecoration: "none",
                                                    fontFamily:
                                                      "poppins  !important",
                                                    whiteSpace: "nowrap",
                                                    height: "1.4375em",
                                                    padding: "5px",
                                                    borderBottom:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    paddingRight:
                                                      column.align !== "right"
                                                        ? "1em"
                                                        : "1.5em",
                                                    position: column.sticky
                                                      ? "sticky"
                                                      : "relative",
                                                    left:
                                                      column.sticky &&
                                                        hasCheckbox
                                                        ? "50px"
                                                        : 0,
                                                    zIndex: column.sticky
                                                      ? 2
                                                      : 1,
                                                    top: 0,
                                                    backgroundColor:
                                                      column.sticky
                                                        ? pure_white
                                                        : "transparent",
                                                    right:
                                                      index2 ===
                                                        columns.length - 1
                                                        ? 0
                                                        : "auto",
                                                    maxWidth: column.width
                                                      ? column.width
                                                      : "initial",
                                                    color:
                                                      deletedRecords?.includes(
                                                        eval(
                                                          "row." + identifier
                                                        )
                                                      )
                                                        ? "grey"
                                                        : "initial",
                                                  }}
                                                >
                                                  {column.renderCell(row)}
                                                </TableCell>
                                                <td
                                                  className={`column_resizer_body ${active === value
                                                      ? "grey_background"
                                                      : ""
                                                    }`}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <TableCell
                                                  component={
                                                    generateRoute ? Link : "td"
                                                  }
                                                  to={
                                                    generateRoute
                                                      ? generateRoute(row)
                                                      : ""
                                                  }
                                                  id={labelId}
                                                  scope="row"
                                                  key={index2}
                                                  align={column.align}
                                                  sx={{
                                                    cursor:
                                                      disableRowClick(row) ===
                                                        true
                                                        ? "default"
                                                        : generateRoute
                                                          ? "pointer "
                                                          : "default",
                                                    textDecoration: "none",
                                                    fontFamily:
                                                      "poppins  !important",
                                                    whiteSpace: "nowrap",
                                                    height: "1.4375em",
                                                    padding: "5px",
                                                    borderBottom:
                                                      "none !important",
                                                    borderTop:
                                                      "none !important",
                                                    paddingRight:
                                                      column.align !== "right"
                                                        ? "1em"
                                                        : "1.5em",
                                                    position: column.sticky
                                                      ? "sticky"
                                                      : "relative",
                                                    left:
                                                      column.sticky &&
                                                        hasCheckbox
                                                        ? "50px"
                                                        : 0,
                                                    zIndex: column.sticky
                                                      ? 2
                                                      : 1,
                                                    top: 0,
                                                    backgroundColor:
                                                      column.sticky
                                                        ? pure_white
                                                        : "transparent",
                                                    right:
                                                      index2 ===
                                                        columns.length - 1
                                                        ? 0
                                                        : "auto",
                                                    maxWidth: column.width
                                                      ? column.width
                                                      : "initial",
                                                    color:
                                                      deletedRecords?.includes(
                                                        eval(
                                                          "row." + identifier
                                                        )
                                                      )
                                                        ? "grey"
                                                        : "initial",
                                                  }}
                                                >
                                                  {column.renderCell(row)}
                                                </TableCell>
                                                <td
                                                  className={`column_resizer_body ${active === value
                                                      ? "grey_background"
                                                      : ""
                                                    }`}
                                                />
                                              </>
                                            );
                                          } else return null;
                                        }
                                      })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {removePagination === true ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              ".MuiTypography-root": {
                                fontSize: "14px !important",
                              },
                            }}
                          >
                            {selected && selected?.length > 0 && (
                              <Typography ml={2} variant="body1">{`${selected?.length
                                } row${selected?.length !== 1 ? "s" : ""
                                } selected`}</Typography>
                            )}
                          </Box>
                          <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={numberRecords}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </div>
                      )}
                    </>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <ContainerCard
                    sx={{
                      boxShadow: "0px 0px 10px rgba(24,117,214,255)",
                      height: "200px",
                      marginTop: "0.1rem",
                    }}
                  >
                    <Box p={3.5}>
                      <ProfilePhoto fromTab />
                    </Box>
                  </ContainerCard>
                </Grid>
              </Grid>
            </Box>
          )
        )}
      </Box>
    </>
  );
}
