import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_ROLE_BY_ID,
} from "../../../../graphql/role";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import { GET_KIOSK_DEVICE_BY_ID, UPDATE_KIOSK_DEVICE } from "../../../../graphql/kioskDevices";



const KioskForm = ({
  fromModal = false,
  resetFields = false,
}) => {
  const { rName, rID, kID, kName } = useParams();

  const createMode = !rName && !rID;

  const { storeUserGuid, userRoles } = useAuth();

  const { manageParameters: manageParametersRole } = userRoles;

  const { tenant_list, tenant_roles, my_roles_list } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;



  const {
    status,
    description,
    deviceID,
    fingerprint,
    location,
    workType,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const [whatField, setWhatField] = useState(null);

  const [unTouchedForm, setUnTouchedForm] = useState(true);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_KIOSK_DEVICE_BY_ID,
    "GetKioskDeviceByID",
    {
      variables: { deviceGuid: kID },
    },
    {
      onCompleted: (response) => {
        const data = response?.GetKioskDeviceByID?.kioskDevice || {};

        setFormData({
          [status.name]: data.status || null,
          [description.name]: data.description || "",
          [fingerprint.name]: data.fingerPrint || "",
          [deviceID.name]: data.deviceID || "",
          [location.name]: data.location || null,
          [workType.name]: data.workType || null,

        });

      },
    }
  );

  const { cfunction: EditKioskDeviceData } = useMutationAuth(UPDATE_KIOSK_DEVICE, "EditKioskDevice");


  function editKiosk(values, actions) {
    EditKioskDeviceData(
      {
        variables: {
          model: {

            kioskDeviceGuid: kID,
            statusGuid: values[status.name]?.kioskDeviceStatusGuid,
            locationID: values[location.name]?.locationGuid,
            workTypeID: values[workType.name]?.workTypeGuid,
            modifiedByGuid: storeUserGuid,
            description: values[description.name],

          },
        },
      },
      (response) => {
        if (
          response["EditKioskDevice"].errorCode !== null &&
          response["EditKioskDevice"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditKioskDevice"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("roleForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if (
      performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
      unTouchedForm && !fromModal
    ) {
      sessionStorage.clear();
    }
  }, []);

  const handleSubmit = (values, actions) => {
    editKiosk(values, actions);

  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${createMode ? "creation" : "edit"
                    }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                      <Form
                        id={form.formID}
                        autoComplete="off"
                        onChange={() => {
                          if (touched) {
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            setUnTouchedForm(false);
                          }
                        }}
                      >
                        <Box p={2}>
                          <Box>
                            <Main
                              formData={{
                                values,
                                touched,
                                formField: form.formField,
                                errors,
                                setFieldValue,
                                setTouched,
                                createMode,
                                whatField,
                                setWhatField,
                                readOnly:
                                  manageParametersRole === "R"
                              }}
                            />
                          </Box>
                          <Box
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              disabled={
                                isSubmitting || manageParametersRole !== "M"
                              }
                              loading={isSubmitting}
                              type="submit"
                              label={t("roleForm.save")}
                            />
                          </Box>
                        </Box>
                      </Form>
                    )
                  }}
                </Formik>
              </ContainerCard>
            </Grid>

          </Grid>
        </Box>
      )}
    </>
  );
};

export default KioskForm;
