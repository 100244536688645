import { Navigate } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function ImportExportTime() {
    const { t } = useTranslation();

  const {
    import_time, export_time_by_resource,excel_time_entries
  } = urls;

  if(window.location.href.split("/")[6] === undefined || (window.location.href.split("/")[6] === null)) return <Navigate to={excel_time_entries} replace />;


  const TabLinks = [
    {
      key: 1,
      Header: t("importExportTimeEntries"),
      Link: excel_time_entries,
    },
    {
      key: 2,
      Header: t("importExportTimeEntriesByResource"),
      Link: export_time_by_resource,
    },
    // {
    //   key: 2,
    //   Header: t("importTimeSheet"),
    //   Link: import_time,
    // },
   
   
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default ImportExportTime;
