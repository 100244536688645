import {gql} from "@apollo/client";

export const GET_TIME_ENTRY_BY_ID = gql`
query($id: UUID!) {
    GetTimeEntryByID(id: $id) {
      timeEntry {
        timeEntryGuid
        startTime
        endTime
        duration
        description
        employee {
            firstName
            lastName
            employeeGuid
            directManagerGuid
            tenantGuid
        }
        task {
            taskGuid
            title 
            ticket {
              project {
                projectGuid
                title
                projectPermissions {
                  resourceGuid
                }
              }
            }
        }
        open
        synced
        automaticallyClosed
        billable
        timeSheet {
          timeSheetGuid
          status
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TIME_ENTRY = gql`
mutation($model: UpdateTimeEntryNonSyncedBoolRequestInput!) {
  UpdateTimeEntryByManager(model: $model) {
    timeEntryCrud
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TIME_ENTRY = gql`
mutation($model: UpdateTimeEntryNonSyncedBoolRequestInput!) {
  CreateTimeEntryByManager(model: $model) {
    timeEntry {
      timeEntryGuid
    }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TIME_ENTRIES_FROM_EXCEL = gql`
mutation($model: ListUpdateTimeEntryNonSyncedBoolRequestInput!) {
  CreateTimeEntriesFromExcelByManager(model: $model) {
      timeEntryCrud
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TIME_ENTRY_LIST = gql`
mutation($model: DeleteTimeEntriesRequestModelInput!) {
  DeleteTimeEntries(model: $model) {
    timeEntryCrud
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TIME_ENTRIES = gql`
query($model: GetTimeEntriesRequestModelInput!) {
  GetTimeEntries(model: $model) {
    timeEntriesList {
      totalHours
      todaysTotal
      weeklyTotal
      timeEntries {
        timeEntryGuid
        modifiedBy{email}
        createdBy{email}
        creationDate
        modificationDate
        description
        employee {
          employeeGuid
          firstName
          lastName
          directManagerGuid
        }
        task {
          taskGuid
          title 
          ticket {
            project {
              projectGuid
              title
              projectPermissions {
                resourceGuid
              }
            }
          }
          parentTaskGuid
        }
        startTime
        endTime
        duration
        open
        synced
        automaticallyClosed
        billable
        timeSheet {
          status
        }
        createdBy {
          email
        }
        creationDate
        modifiedBy {
          email
        }
        modificationDate
      }
      totalNumberOfRecords
    } 
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_OPEN_TIME_ENTRIES = gql`
query($model: GetOpenTimeEntriesRequestModelInput!) {
  GetOpenTimeEntries(model: $model) {
    timeEntriesList {
      timeEntries {
        timeEntryGuid
        description
        employee {
          employeeGuid
          firstName
          lastName
          directManagerGuid
        }
        task {
          taskGuid
          title 
          ticket {
            project {
              projectGuid
              title
            }
          }
        }
        startTime
        endTime
        duration
        open
        synced
        automaticallyClosed
        billable
        timeSheet {
          status
        }
        createdBy {
          email
        }
        creationDate
        modifiedBy {
          email
        }
        modificationDate
      }
      totalNumberOfRecords
    } 
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_TIME_ENTRIES_APPROVAL = gql`
query ($model: GetTimeEntriesListRequestModelInput!){
  GetTimeEntriesForApproval(model: $model) {
    timeSheetTimeEntries {
      totalDuration
      totalNumberOfRecords
      timeEntries {
        automaticallyClosed
        creationDate
        billable
        description
        duration
        employee
        modificationDate
        open
        project
        projectGuid
        startTime
        synced
        task
        taskGuid
        taskID
        timeEntryGuid
        timeSheetGuid
        createdBy {
          email
        }
        modifiedBy {
          email
        }
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
} 
`;

//check data and import to database if no error
export const CHECK_AND_CREATE_TIME_ENTRIES_FROM_EXCEL = gql`
mutation ($model: CreateAndCheckTimeEntriesListRequestInput!) {
  CreateTimeEntriesFromBCExcelFormat(model: $model) {
    createTimeEntriesResult {
      createdTimeEntries
      result {
        billable
        date
        description
        error
        projectName
        resourceName
        taskName
        time
      }
    }
    errorCode
    errorMessage
    errorVariables
    
  }
}
`;
