import React, { useState, useEffect } from "react";

import {
  Paper,
  Collapse,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Tooltip,
} from "@mui/material";

import { styled } from "@mui/system";

import colors from "../../../../assets/theme/light/colors";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Link } from "react-router-dom";

//uuid generator
import { v4 as uuidv4 } from "uuid";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableExpandIcon, TableCollapseIcon, TaskIcon } from "../../../../components/Icon";
import IconBadge from "../../../../components/StatusCell/IconBadge"

const TableCell = styled(MuiTableCell)({
  width: "20%",
});

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "10px !important",
          borderRadius: "2px !important",
          backgroundColor: "white !important",
          boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
          color: "black",
        },
      },
    },
  },
});

export function TimeByEmployeeTable({
  t,
  data,
  handleOpenModal,
  generateTaskRoute,
}) {
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedIDs, setExpandedIDs] = useState([]);
  const [expandedProject, setExpandedProject] = useState(false);
  const [expandedProjectIDs, setExpandedProjectIDs] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    function addAnotherIdToProjects(dataArray) {
      dataArray.forEach((row) => {
        row?.projects.forEach((project) => {
          // Generate a random task ID and add it to each task in the project
          project.selectedId = uuidv4();
          project.tasks.forEach((task) => {
            task.task = true;
          });
        });
      });

      return dataArray;
    }

    // Example usage
    setRows(addAnotherIdToProjects(data));
  }, []);

  function handleExpand(id) {
    setExpanded(true);
    if (!expandedIDs.includes(id)) setExpandedIDs([...expandedIDs, id]);
  }

  function handleCollapse(id) {
    const updatedExpandedIDs = expandedIDs.filter(
      (expandedID) => expandedID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpanded(false);
    setExpandedIDs(updatedExpandedIDs);
  }

  function handleExpandProject(id) {
    setExpandedProject(true);
    if (!expandedProjectIDs.includes(id))
      setExpandedProjectIDs([...expandedProjectIDs, id]);
  }

  function handleCollapseProject(id) {
    const updatedExpandedIDs = expandedProjectIDs.filter(
      (expandedProjectID) => expandedProjectID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpandedProject(false);
    setExpandedProjectIDs(updatedExpandedIDs);
  }

  function handleExpandAll() {
    const projectArr = [];
    const emplArr = [];
    rows.forEach((row) => {
      const { projects } = row;
      setExpanded(true);
      emplArr.push(row?.id);
      projects.forEach((project) => {
        setExpandedProject(true);
        projectArr.push(project?.selectedId)
      })
    })
    setExpandAll(true);
    setExpandedIDs(emplArr);
    setExpandedProjectIDs(projectArr);
  }

  function handleCollapseAll() {
    setExpandAll(false);
    setExpanded(false);
    setExpandedIDs([]);
    setExpandedProjectIDs([]);
  }

  function Row(props) {
    const { row, collapsible, parent, parentName, key } = props;
    const { projects } = row;

    return (
      <React.Fragment>
        {row?.task == true ? (
          <TableRow>
            <TableCell sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : expanded && expandedIDs.includes(row?.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row?.id)}
                    />
                  )}
                </IconButton>
              )}
            </TableCell>
            {/* <TableCell sx={{ width: "5%" }}>{row?.id}</TableCell> */}
            <TableCell sx={{ width: "40%", padding: "0 16px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
                component={Link}
                align="left"
                className="link-cell task"
                to={generateTaskRoute(row, parentName, parent)}
                onClick={(event) => {
                  event.preventDefault();
                  handleOpenModal(event, row, parentName, parent)
                }
                }
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "65px",
                    }}
                  >
                    <TaskIcon />
                  </span>
                )}
                <div style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "17vw",
                }}>   {`${row?.name}`}</div>              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
                verticalAlign: "bottom",
              }}
            >
              {row?.billable !== null && row?.billable !== undefined ? <IconBadge success={row?.billable} /> : <></>}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
              }}
            >
              {row?.totalOfHours}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "15%",
              }}
            />
          </TableRow>
        ) : (
          // row?.task === false &&
          <TableRow>
            <TableCell sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : expanded && expandedIDs.includes(row?.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row?.id)}
                    />
                  )}
                </IconButton>
              )}
            </TableCell>
            {/* <TableCell sx={{ width: "5%" }}>{row?.id}</TableCell> */}
            <TableCell sx={{ width: "40%", padding: "0 16px", fontWeight: "500"  }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",

                    }}
                  >
                    <TaskIcon />
                  </span>
                )}
                <div style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "17vw",
                }}>   {`${row?.name}`}</div>
              </div>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "20%",
              }}
            />
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 50px 0 16px",
                fontWeight: "bold",
              }}
            >
              {row?.totalOfHours}
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "15%",
              }}
            />
          </TableRow>
        )}

        {collapsible &&
          expanded &&
          expandedIDs.length != 0 &&
          expandedIDs.includes(row?.id) && (
            <>
              {projects &&
                projects.map((project) => {
                  return (
                    <React.Fragment key={project?.id}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "5%",
                            textAlign: "end",
                            padding: "0 16px",
                          }}
                        >
                          {collapsible && (
                            <IconButton aria-label="expand row" size="small">
                              {expandedProject &&
                                expandedProjectIDs.includes(
                                  project?.selectedId
                                ) ? (
                                <KeyboardArrowUpIcon
                                  onClick={() =>
                                    handleCollapseProject(project?.selectedId)
                                  }
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  onClick={() =>
                                    handleExpandProject(project?.selectedId)
                                  }
                                />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ width: "40%", padding: "0 16px 0 26px"  ,textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "17vw"}}
                        >
                          {project?.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "20%",
                          }}
                        />
                        <TableCell
                          align="right"
                          sx={{
                            width: "20%",
                            padding: "0 50px 0 16px",
                          }}
                        >
                          {project?.totalOfHours}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "15%",
                          }}
                        >
                          {project?.percentageOfTotal} {" %"}
                        </TableCell>
                      </TableRow>
                      {collapsible && (
                        <TableRow
                          sx={{
                            display:
                              expandedIDs.length != 0 &&
                                expandedIDs.includes(row?.id) &&
                                expandedProject &&
                                expandedProjectIDs.length != 0 &&
                                expandedProjectIDs.includes(project?.selectedId)
                                ? "table-row"
                                : "none",
                          }}
                        >
                          <TableCell
                            colSpan={5}
                            sx={{
                              padding: 0,
                              borderBottom: "none",
                            }}
                          >
                            <Collapse
                              in={expandedProject}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table>
                                <TableBody>
                                  {project?.tasks &&
                                    project?.tasks.map((task) => {
                                      return (
                                        <Row
                                          key={task.id}
                                          ancestor={row.id}
                                          parent={project?.id}
                                          parentName={project?.name}
                                          row={task}
                                          collapsible={false}
                                        />
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            </>
          )}
      </React.Fragment>
    );
  }

  return (
    <>
      {rows.length > 0 ? (
        <TableContainer component={Paper} className={"table-timesheet-height"}>
          <Table size="small" sx={{ overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{
                  width: "5%",
                  padding: "0 30px 0 16px",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                }}>
                  {!expandAll ? (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t("table.ExpandAll")}>
                        <IconButton onClick={() => handleExpandAll()}>
                          <TableExpandIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t("table.CollapseAll")}>
                        <IconButton onClick={() => handleCollapseAll()}>
                          <TableCollapseIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  )}
                </TableCell>
                {/* <TableCell sx={{ width: "5%" }}>ID</TableCell> */}
                <TableCell sx={{
                  width: "40%",
                  padding: "0",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                }}>
                  {t("timeByEmployee.name")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "20%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}                >
                  {t("timeByEmployee.billable")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "20%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}                >
                  {t("timeByEmployee.total")}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: "15%",
                    padding: "0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}                >
                  {"%"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row?.id} row={row} collapsible={true} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
