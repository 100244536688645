import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog, Grid } from "@mui/material"; //icons
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from "@mui/icons-material";
//components
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useSubscription } from "@apollo/client";
import Tooltip from "../Tooltip";

//styling
import "./style.css";

import { LOGOUT_USER } from "../../graphql/user";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useAuth from "../../hooks/useAuth";
import useMutationAuth from "../../hooks/useMutationAuth";

//graphql
import {
  GET_ALL_NOTIFICATIONS_SUBSCRIPTION,
  NOTIF_READ,
  DELETE_NOTIFICATION,
  GET_ALL_NOTIFICATIONS,
} from "../../graphql/notifications";

import { useSelector } from "react-redux";
import {
  getNotificationFromStore,
  changeNotification,
} from "../../app/reducers/notificationSlice";

import Breadcrumbs from "./breadcrumbs";

import urls from "../../urls";

import { useNavigate, Link } from "react-router-dom";
import ReleaseCards from "../../pages/admin-configuration/ReleaseNotes/release-list";
import InfoDrawer from "../InfoDrawer";
import AccessibleBadges from "../BadgeIcon";
import AlignItemsList from "../NotificationList";
import ConfirmationPopup from "../../components/Popup";
import colors from "../../assets/theme/light/colors";
import {
  ExpandMoreIcon,
  CloseIcon,
  TaskIcon,
  SubtaskIconOutlined,
  AddTimeIcon,
  DisableChatIcon,
  EnableChatIcon,
  SuggestionsIcon,
  ReleasesIcon,
  UserIcon,
  PasswordIcon,
  TenantIcon,
  LogOutIcon,
  ChatBotIcon,
  HelpIcon,
} from "../../components/Icon";
import No_Image_Found from "../../assets/images/no-user-image.png";
import SuggestionList from "../../pages/admin-configuration/Suggestions/suggestions-list";
import { Title } from "../Titles/FormTitles";
import useLazyQueryAuth from "../../hooks/useLazyQueryAuth";
import { useDispatch } from "react-redux";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import TaskTabsModal from "../Modal/TaskTabsModal";
import TimeEntryDrawer from "../../pages/admin-configuration/Timesheets/Timesheets-Form/components/TimeEntryDrawer";
import PaperComponent from "../Modal/PaperComponent";
import { GET_ALL_TASK_STATUSES } from "../../graphql/taskStatus";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../graphql/employee";
import { GET_ALL_TASKS_WITH_PROJECT } from "../../graphql/task";
import useQueryAuth from "../../hooks/useQueryAuth";
import Mnemonic from "../../Mnemonics.json";

const Navbar = ({ open, setOpen, side }) => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [scriptElement, setScriptElement] = useState(null);
  const [Clicked, setClicked] = useState(() => {
    const storedClicked = localStorage.getItem("Clicked");
    return storedClicked ? JSON.parse(storedClicked) : false;
  });
  const [notRead, setNotRead] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(
    JSON.parse(sessionStorage.getItem("unsavedChanges"))
  );
  const notification = useSelector(getNotificationFromStore);
  const [openDialog, setOpenDialog] = useState(false);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openTimeEntryModal, setOpenTimeEntryModal] = useState(false);
  const [timeEntryGuid, setTimeEntryGuid] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const type = useRef("");
  const [routeToNavigate, setRouteToNavigate] = useState(null);
  const [notifsList, setNotifsList] = useState([]);
  const [URL, setURL] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [trialMsg, setTrialMsg] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNewBtnEl, setAnchorNewBtnEl] = useState(null);
  const [menuItems, setMenuItems] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [task, setTask] = useState(null);
  // const [isModalNewOpen, setIsModalNewOpen] = useState(false);

  const handleMenuItemClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setIsModalNewOpen(false);
  };
  // const handleMenuNewClick = () => {
  //   setIsModalNewOpen(true);
  // };

  const { icon_button_grey, light_logo_blue } = colors;

  const {
    logout: LogoutFunction,
    storeUserTenant,
    storeUserTenantName,
    userRoles,
    storeUserFirstName,
    storeUserLastName,
    storeUserRoleDescription,
    storeUserProfilePicture,
    storeUserGuid,
    jwtToken,
    isTrial,
    trialExpirationDate,
    isEmployee,
    isTimeEntry,
    timeEntryEnabled,
  } = useAuth();

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeesWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !openTimeEntryModal,
    isWait: !openTimeEntryModal,
  });

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        userID: storeUserGuid,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: !openTimeEntryModal || employeesWait,
    isWait: !openTimeEntryModal || !employeesWait,
  });

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const {
    mfunction: GetAllUserNotifications,
    loading: allNotifsLoading,
    tokenChange: notifsWait,
    refetch,
  } = useLazyQueryAuth(GET_ALL_NOTIFICATIONS, "GetAllUserNotifications");

  const fetchTableData = useCallback(() => {
    GetAllUserNotifications(
      {
        variables: { model: { userID: storeUserGuid } },
      },
      (response) => {
        const list = response?.GetAllUserNotifications?.notificationsList;
        const notificationsObj = {
          logoURL: logoURL,
          url: URL,
          notifications: list,
        };
        dispatch(changeNotification(notificationsObj));
      }
    );
  }, []);

  useEffect(() => {
    if (isDrawerOpen) {
      fetchTableData();
    }
  }, [isDrawerOpen]);

  // useSubscription(GET_ALL_NOTIFICATIONS_SUBSCRIPTION, {
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     id: storeUserGuid,
  //   },
  //   onData: ({ data }) => {
  //     const list = data?.data?.onUpdateNotification?.notificationsList;
  //     const notificationsObj = {
  //       logoURL: logoURL,
  //       url: URL,
  //       notifications: list?.notifications,
  //     };
  //     dispatch(changeNotification(notificationsObj));
  //   },
  // });

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: "10px !important",
            borderRadius: "2px !important",
            boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
          },
        },
      },
    },
  });

  const { isEntelligence, isAdmin, manageTimeEntry } = userRoles;

  const { mfunction: logUserOut, loading: logoutLoading } = useMutationAuth(
    LOGOUT_USER,
    "LogoutUser"
  );

  const {
    my_tenant,
    my_profile,
    admin_profile,
    change_password,
    admin_change_password,
  } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const openNewBtnMenu = Boolean(anchorNewBtnEl);

  const handleNewBtnClick = (event) => {
    setAnchorNewBtnEl(event.currentTarget);
  };

  const handleNewBtnClose = () => {
    setAnchorNewBtnEl(null);
  };

  const openMenu = Boolean(anchorEl);
  const openMenuItems = Boolean(menuItems);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event) => {
    setMenuItems(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuItems(null);
  };

  useEffect(() => {
    if (Clicked) {
      var script;
      // If the script is not present, create and append it
      script = document.createElement("script");
      script.id = "Microsoft_Omnichannel_LCWidget";
      script.src =
        "https://oc-cdn-public-eur.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js";
      script.setAttribute(
        "data-app-id",
        "fe28ccce-f331-4ce2-a870-3311ff6de3ae"
      );
      script.setAttribute("data-lcw-version", "prod");
      script.setAttribute(
        "data-org-id",
        "056feb09-3243-ee11-be68-000d3a64d346"
      );
      script.setAttribute(
        "data-org-url",
        "https://unq056feb093243ee11be68000d3a64d-crm4.omnichannelengagementhub.com"
      );

      document.body.appendChild(script);
      setScriptElement(script);
    } else if (scriptElement) {
      var body = document.getElementsByTagName("body")[0];
      var script = document.getElementById(
        "Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window"
      );
      if (script != null) {
        body.removeChild(script);
      }
      setScriptElement(null);
    } // Reset the reference
  }, [Clicked]);

  function logoutTheUser() {
    logUserOut(
      {
        variables: {
          model: {
            jwtToken: jwtToken,
            registrationToken: localStorage.getItem("token"),
          },
        },
      },
      (response) => {
        LogoutFunction();
      }
    );
  }

  useEffect(() => {
    if (isTrial) getExpirationOffset();
  }, [isTrial]);

  useEffect(() => {
    if (notification) {
      setNotifsList(notification?.notifications || []);
      setURL(notification?.url || "");
      setLogoURL(notification?.logoURL || "");
      const newNotReadNotifications = Array.isArray(notification?.notifications)
        ? notification.notifications.filter((notif) => !notif.isRead)
        : [];

      setNotRead(newNotReadNotifications?.length);
    }
  }, [notification]);

  function handleOpenNotifs() {
    setDrawerOpen(!isDrawerOpen);
  }
  const { mfunction: notifsReadData, loading: notifsLoading } = useMutationAuth(
    NOTIF_READ,
    "UserReadNotification"
  );
  const handleRead = (id) => {
    notifsReadData(
      {
        variables: {
          notificationGuid: id,
        },
      },
      (response) => {
        if (response["UserReadNotification"].notificationIsRead === true) {
          const notifs = notifsList?.notifications?.map((notif) =>
            notif.notificationGuid === id ? { ...notif, isRead: true } : notif
          );
          const notificationsObj = {
            logoURL: logoURL,
            url: URL,
            notifications: notifs,
          };

          setNotifsList({ ...notifsList, notifications: notifs });
        }
      }
    );
  };

  const { mfunction: deleteNotif, loading: deleteLoading } = useMutationAuth(
    DELETE_NOTIFICATION,
    "DeleteNotification"
  );
  // const handleDelete = (id) => {
  //   deleteNotif({
  //     variables: {
  //       id: id,
  //     },
  //   });
  // };

  const handleDelete = async (id) => {
    try {
      await deleteNotif({
        variables: {
          id: id,
        },
      });
      refetch();
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const renderChatBot = () => {
    return (
      <Menu
        anchorEl={document.getElementById("toggleChatBot")}
        // className="account-menu-profile"
        MenuListProps={{ sx: { py: 0, width: "185px" } }}
        open={openMenuItems}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{ textAlign: "left" }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            },
          },
        }}
      >
        <div
          className="suggestionsMenu"
          style={{
            width: "185px",
            textAlign: "left",
            backgroundColor: `${colors.grey_background} !important`,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MenuItem
              className="user-menu-item-hover"
              style={{ paddingTop: "20px" }}
              onClick={() => {
                setClicked((prevClicked) => {
                  const newClicked = !prevClicked;
                  localStorage.setItem("Clicked", JSON.stringify(newClicked));
                  return newClicked;
                });
              }}
            >
              <ListItemIcon>
                {Clicked ? (
                  <DisableChatIcon color="#000000" />
                ) : (
                  <EnableChatIcon color="#000000" />
                )}{" "}
              </ListItemIcon>
              <span style={{ fontSize: "13px", fontFamily: "Poppins" }}>
                {" "}
                {Clicked ? t("Disable.chatBot") : t("Enable.chatBot")}
              </span>
            </MenuItem>
            <MenuItem
              className="user-menu-item-hover"
              onClick={handleMenuItemClick}
            >
              {" "}
              <ListItemIcon>
                <SuggestionsIcon color="#000000" width="16" height="16" /> {" "}
              </ListItemIcon>
              <span style={{ fontSize: "13px", fontFamily: "Poppins" }}>
                {" "}
                {t("suggestions.box")}
              </span>
            </MenuItem>
            <MenuItem
              className="user-menu-item-hover"
              onClick={() => navigate(urls.user_releaseNotes_details)}
            >
              {" "}
              <ListItemIcon>
                <ReleasesIcon color="#000000" width="16" height="16" />{" "}
              </ListItemIcon>
              <span style={{ fontSize: "13px", fontFamily: "Poppins" }}>
                {" "}
                {t("wtsnew")}
              </span>
            </MenuItem>
          </div>
        </div>
      </Menu>
    );
  };

  const renderNewBtnMenu = () => {
    return (
      <Menu
        anchorEl={anchorNewBtnEl}
        className="account-menu-profile"
        MenuListProps={{ sx: { py: 0, width: "200px" } }}
        open={openNewBtnMenu}
        onClose={handleNewBtnClose}
        onClick={handleNewBtnClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{ textAlign: "center" }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            },
          },
        }}
      >
        {manageTimeEntry === "M" && (
          <MenuItem
            className="user-menu-item-hover"
            sx={{ paddingTop: "15px" }}
            onClick={(event) => {
              type.current = "task";
              handleNewBtnItemClick();
            }}
          >
            <ListItemIcon>
              <TaskIcon />
            </ListItemIcon>
            <Link
              // to={""}
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                color: "inherit",
                textDecoration: "inherit",
              }}
            >
              <span style={{ fontSize: "13px" }}>
                {" "}
                {t("dashboard.task.add")}
              </span>
            </Link>
          </MenuItem>
        )}

        <MenuItem
          className="user-menu-item-hover"
          sx={{ paddingTop: "15px" }}
          onClick={(event) => {
            type.current = "subtask";
            handleNewBtnItemClick();
          }}
        >
          <ListItemIcon>
            <SubtaskIconOutlined color={colors.icon_button_grey} />
          </ListItemIcon>
          <Link
            // to={""}
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              color: "inherit",
              textDecoration: "inherit",
            }}
          >
            <span style={{ fontSize: "13px" }}>
              {" "}
              {t("dashboard.subtask.add")}
            </span>
          </Link>
        </MenuItem>

        <MenuItem
          className="user-menu-item-hover"
          sx={{ paddingTop: "15px" }}
          onClick={(event) => {
            type.current = "timeentry";
            handleNewBtnItemClick();
          }}
        >
          <ListItemIcon>
            <AddTimeIcon color={colors.light_logo_blue} />
          </ListItemIcon>
          <Link
            // to={""}
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              color: "inherit",
              textDecoration: "inherit",
            }}
          >
            <span style={{ fontSize: "13px" }}>
              {" "}
              {t("dashboard.task.addTimeEntry")}
            </span>
          </Link>
        </MenuItem>
      </Menu>
    );
  };

  const handleNewBtnItemClick = (event, route) => {
    if (type.current === "task" || type.current === "subtask")
      setOpenNewDialog(true);
    else if (type.current === "timeentry") setOpenTimeEntryModal(true);
    // if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
    //   setOpenDialog(true);
    //   setRouteToNavigate(route);
    // } else navigate(route);
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        className="account-menu-profile"
        MenuListProps={{ sx: { py: 0, width: "260px" } }}
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{ textAlign: "center" }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            },
          },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            style={{
              fontWeight: "700",
              margin: "0px 20px",
              minWidth: "150px",
              textAlign: "left",
              paddingTop: "20px",
              color: light_logo_blue,
            }}
          >
            {storeUserFirstName} {storeUserLastName}
          </Typography>

          <Typography
            align="center"
            variant="caption"
            style={{ margin: "0px 20px", minWidth: "150px", textAlign: "left" }}
          >
            {storeUserTenantName}
          </Typography>
          <Typography
            align="center"
            variant="caption"
            style={{
              margin: "0px 20px",
              minWidth: "150px",
              textAlign: "left",
              paddingBottom: "10px",
            }}
          >
            {storeUserRoleDescription}
          </Typography>
        </div>

        <MenuItem
          className="user-menu-item-hover"
          sx={{ paddingTop: "15px" }}
          onClick={(event) => {
            const route = isEntelligence
              ? admin_profile.replace(
                  "/:profileName/:profileID",
                  `/${encodeURIComponent(
                    storeUserFirstName + " " + storeUserLastName
                  )}/${encodeURIComponent(storeUserGuid)}`
                )
              : my_profile.replace(
                  "/:profileName/:profileID",
                  `/${encodeURIComponent(
                    storeUserFirstName + " " + storeUserLastName
                  )}/${encodeURIComponent(storeUserGuid)}`
                );
            handleItemClick(event, route);
          }}
        >
          <ListItemIcon>
            <UserIcon color="#000000" width="22" height="22" />
          </ListItemIcon>
          <Link
            // to={""}
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              color: "inherit",
              textDecoration: "inherit",
            }}
          >
            <span style={{ fontSize: "13px" }}> {t("profile")}</span>
          </Link>
        </MenuItem>
        <MenuItem
          className="user-menu-item-hover"
          onClick={(event) => {
            const route = isEntelligence
              ? admin_change_password.replace(
                  "/:profileName/:profileID",
                  `/${encodeURIComponent(
                    storeUserFirstName + " " + storeUserLastName
                  )}/${encodeURIComponent(storeUserGuid)}`
                )
              : change_password
                  .replace(
                    "/:profileName",
                    `/${encodeURIComponent(
                      storeUserFirstName + " " + storeUserLastName
                    )}`
                  )
                  .replace(
                    "/:profileID",
                    `/${encodeURIComponent(storeUserGuid)}`
                  );
            handleItemClick(event, route);
          }}
        >
          <ListItemIcon>
            <PasswordIcon color="#000000" />
          </ListItemIcon>
          <span style={{ fontFamily: "poppins", fontSize: "13px" }}>
            {t("change.password")}
          </span>
        </MenuItem>
        {isAdmin && storeUserTenant && storeUserTenantName && (
          <MenuItem
            className="user-menu-item-hover"
            onClick={(event) => {
              const route = my_tenant.replace(
                "/:tName/:tID",
                `/${encodeURIComponent(
                  storeUserTenantName
                )}/${encodeURIComponent(storeUserTenant)}`
              );
              handleItemClick(event, route);
            }}
          >
            <ListItemIcon>
              <TenantIcon color="#000000" width="22" height="22"/>
            </ListItemIcon>
            <span style={{ fontFamily: "poppins", fontSize: "13px" }}>
              {t("tenant")}
            </span>
          </MenuItem>
        )}

        <MenuItem
          className="user-menu-item-hover"
          onClick={() => logoutTheUser()}
        >
          <ListItemIcon>
            <LogOutIcon color="#000000" />
          </ListItemIcon>
          <span style={{ fontFamily: "poppins", fontSize: "13px" }}>
            {t("logout")}
          </span>
        </MenuItem>
      </Menu>
    );
  };

  const handleItemClick = (event, route) => {
    event.preventDefault();
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
      setOpenDialog(true);
      setRouteToNavigate(route);
    } else navigate(route);
  };

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    navigate(routeToNavigate);
  };

  useEffect(() => {
    setUnsavedChanges(JSON.parse(sessionStorage.getItem("unsavedChanges")));
  }, []);

  const getExpirationOffset = () => {
    if (isTrial) {
      // Parse the given date string
      const givenDate = new Date(trialExpirationDate);

      // Get the current date
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const timeDifference = givenDate - currentDate;

      // Calculate the difference in days
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      // If the difference is less than a day, return the number of hours
      if (daysDifference < 1) {
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        setTrialMsg(t("trial.ends.today"));
      } else {
        setTrialMsg(`${t("trial.ends.x.days")} ${daysDifference} days.`);
      }
    }
  };

  return (
    <div className="navbar-container">
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <TaskTabsModal
        showModal={openNewDialog}
        setShowModal={(v) => {
          setOpenNewDialog(v);
          type.current = "";
          setTask(null);
        }}
        projectID={task?.ticket?.projectGuid}
        projectName={task?.ticket?.project?.title}
        taskID={task?.taskGuid}
        taskName={task?.title}
        loading={false}
        fromProject={true}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
        setType={(v) => type.current=v}
        type={type.current}
        subtask={type.current === "subtask"}
        quickAdd={true}
      />
      {!employeeLoading && !taskLoading && (
        <TimeEntryDrawer
          PaperComponent={PaperComponent}
          openTimeEntryModal={openTimeEntryModal}
          setOpenTimeEntryModal={(v) => {
            setOpenTimeEntryModal(v);
            type.current = "";
          }}
          fromTimeEntriesList={false}
          employees={employeeList}
          tasks={task_list}
          group={true}
          fromDashboard={true}
          editMode={editMode}
          handleEditMode={(v) => setEditMode(v)}
          timeEntryGuid={timeEntryGuid}
          handleChangeTimeEntryGuid={(v) => setTimeEntryGuid(v)}
          t={t}
        />
      )}
      {isTrial === true && (
        <div className="trial-banner">
          {trialMsg} {"  "}{" "}
          <span className="link-text-banner">{t("trial.subscribe")}</span>
        </div>
      )}
      <div className="navbar-inner-container">
        <span
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: "5px",
          }}
        >
          <IconButton
            onClick={() => {
              setOpen((open) => !open);
              localStorage.setItem("miniSidenav", !open);
            }}
          >
            {open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Breadcrumbs />
        </span>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isEmployee !== Mnemonic.ResourceTypes.External && isTimeEntry && timeEntryEnabled && (
              <Tooltip title={t("tooltip.QuickAdd")}>
              <AddCircleRoundedIcon
                onClick={(e) => {
                  handleNewBtnClick(e);
                }}
                sx={{
                  color: colors.light_logo_blue,
                  fontSize: "38px !important",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              /></Tooltip>
            )}
            <div className="user-container">
              <img
                src={
                  storeUserProfilePicture
                    ? storeUserProfilePicture
                    : No_Image_Found
                }
                width="32"
                height="32"
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = No_Image_Found;
                }}
              />
              <Typography variant="caption">{`${storeUserFirstName} ${storeUserLastName}`}</Typography>

              <ThemeProvider theme={theme}>
                <Tooltip
                  title={t("user.accountSettings")}
                  sx={{ ".MuiTooltip-popper": { padding: "20px" } }}
                >
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{
                      ml: 0,
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <ExpandMoreIcon color={icon_button_grey} />
                  </IconButton>
                </Tooltip>
              </ThemeProvider>
            </div>
            <Tooltip title={t("table.Notifs")}>
              <div>
                <AccessibleBadges
                  onClick={handleOpenNotifs}
                  notificationCount={notRead}
                />
              </div>
            </Tooltip>
            {!isEntelligence && (
              <Tooltip title={t("show.chatBot")}>
                <div>
                  <IconButton
                    id="toggleChatBot"
                    onClick={handleClickMenu}
                    sx={{
                      backgroundColor: "#F2F1FB",
                      marginRight: "5px",
                      "&:hover": { backgroundColor: "#F2F1FB" },
                    }}
                  >
                    <ChatBotIcon color={colors.icon_button_grey} />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {/* <Tooltip title={t("show.help")}>
              <div>
                <IconButton
                  onClick={handleClickMenu}
                  sx={{
                    backgroundColor: "#F2F1FB",
                    "&:hover": { backgroundColor: "#F2F1FB" },
                  }}
                >
                  <HelpIcon color={colors.icon_button_grey} width="24" height="24" />
                </IconButton>
              </div>
            </Tooltip> */}
          </div>
          <>
            {isDrawerOpen === true && (
              <InfoDrawer
                placement={side}
                width={notifsList ? "auto" : "400px"}
                onCloseFct={() => setDrawerOpen(false)}
                open={isDrawerOpen}
                zIndex={1600}
                title={t("notif.drawer.title")}
                className="custom-notif-drawer"
                bodyStyle={{ overflowY: "auto", padding: "0" }}
              >
                {notifsList?.notifications?.length > 0 ? (
                  <div style={{ width: "35rem" }}>
                    <AlignItemsList
                      notifsList={notifsList}
                      logoURL={logoURL}
                      URL={URL}
                      handleRead={handleRead}
                      handleDelete={handleDelete}
                      handleCloseDrawer={() => setDrawerOpen(false)}
                      handleTask={(v) => setTask(v)}
                      handleOpenNewDialog={() => setOpenNewDialog(true)}
                    />
                  </div>
                ) : (
                  <div style={{ padding: "5px" }}>{t("no.notifications")}</div>
                )}
              </InfoDrawer>
            )}
          </>
        </div>
        {isModalOpen && (
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            sx={{
              ".MuiDialog-paper": {
                maxHeight: "100% !important",
                minWidth: "1350px !important",
                padding: "6px 30px !important",
                backgroundColor: `${colors.grey_background}`,
              },
            }}
          >
            <Grid container alignItems="center">
              <Grid
                item
                sm={10}
                lg={10}
                md={10}
                xs={10}
                xl={10}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  mr={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <SuggestionsIcon width="22" height="22" color="#344767"/>
                </Grid>
                <Title>{t("SuggestionBox.title")}</Title>
              </Grid>
              <Grid
                item
                sm={2}
                lg={2}
                md={2}
                xs={2}
                xl={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => handleCloseModal()}
                  sx={{ fontSize: "14px !important" }}
                >
                  <CloseIcon width="24" height="24" strokeWidth="1" color="#9e9e9e" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid mt={-1} item pb={2}>
              <SuggestionList fromNav handleCloseModal={handleCloseModal} />
            </Grid>
          </Dialog>
        )}
        {/* {isModalNewOpen && (
          <Dialog
            open={isModalNewOpen}
            onClose={handleCloseModal}
            sx={{
              ".MuiDialog-paper": {
                maxHeight: "100% !important",
                minWidth: "1350px !important",
                padding: "6px 30px !important",
                backgroundColor: `${colors.grey_background}`,
              },
            }}
          >
            <Grid container alignItems="center">
              <Grid
                item
                sm={10}
                lg={10}
                md={10}
                xs={10}
                xl={10}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  mr={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-gift" width="22" height="22" viewBox="0 0 24 24" stroke-width="1.8" stroke="#344767" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
                    <path d="M12 8l0 13" />
                    <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
                    <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
                  </svg>
                </Grid>
                <Title>{t("wtsnew")}</Title>
              </Grid>
              <Grid
                item
                sm={2}
                lg={2}
                md={2}
                xs={2}
                xl={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => handleCloseModal()}
                  sx={{ fontSize: "14px !important" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#9e9e9e"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                  </svg>
                </IconButton>
              </Grid>
            </Grid>
            <Grid mt={-1} item>
              <ReleaseCards showCards handleCloseModal={handleCloseModal} />
            </Grid>
          </Dialog>
        )} */}
        {renderNewBtnMenu()}
        {renderMenu()}
        {renderChatBot()}
      </div>
    </div>
  );
};

export default Navbar;
