export const Stringify = (key, value) => {
  if (typeof value === "function") {
    return value.toString();
  }
  return value;
};

export const Parse = (
  key,
  value,
  UTC2Local,
  componentDisplay,
  UTC2Time,
  actions,
  getWorkingHours,
  formatDateTimeToString
) => {
  if (
    typeof value === "string" &&
    value.includes("=>") &&
    value.includes("{") &&
    value.includes("}") &&
    value.includes("return")
  ) {
    const parsedFunction = eval(`(${value})`);
    return parsedFunction;
  }

  return value;
};
