import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    licenseType,
    numberOfLicenses,
    numberOfUsedLicenses,
  },
} = checkout;

export default [
  Yup.object().shape({
    [licenseType.name]: Yup.string().required(licenseType.requiredMessage),
    [numberOfLicenses.name]: Yup.number().required(numberOfLicenses.requiredMessage),
    [numberOfUsedLicenses.name]: Yup.number().nullable(),
  }),
];
