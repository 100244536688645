import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

import useAuth from "../../../../hooks/useAuth";

function TicketTabs({ fromTenant = false, myTenant = false, fromCompany = false }) {
  const { pName, pID, tName, tID, tkName, tkID } = useParams();

  const {
    tenant_project_tickets_form,
    my_project_tickets_form,
    tenant_project_tasks,
    my_project_tasks,
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  const { userRoles } = useAuth();

  const { manageTimeEntry } = userRoles;

  if (
    !pName ||
    !pID ||
    pName === "undefined" ||
    pName === "null" ||
    pName === "" ||
    pID === "null" ||
    pID === "undefined" ||
    pID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(pName);
  const encodedID = encodeURIComponent(pID);

  const TabLinks = [
    {
      key: 0,
      Header: t("ticketTabs.form"),
      Link: fromTenant
        ? tenant_project_tickets_form
            .replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
            .replace(
              "/:pName/:pID",
              `/${encodedName}/${encodedID}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            )
        : my_project_tickets_form.replace(
            "/:pName/:pID",
            `/${encodedName}/${encodedID}`
          )
          .replace(
            "/:tkName/:tkID",
            `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
          ),
    },
    ...(manageTimeEntry !== "N"
      ? [
          {
            key: 1,
            Header: t("ticketTabs.tickets"),
            Link: fromTenant
            ? tenant_project_tasks
                .replace(
                  "/:pName/:pID",
                  `/${encodedName}/${encodedID}`)
                  .replace("/:tkName/:tkID", `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`)
                
            : my_project_tasks.replace(
                "/:pName/:pID",
                `/${encodedName}/${encodedID}`
              )
              .replace("/:tkName/:tkID", `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`),
          },
        ]
      : []),
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default TicketTabs;
