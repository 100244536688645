import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import axios from "axios";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../../hooks/useAuth";



//urls
import urls from "../../../../../urls";

import ContainerCard from "../../../../../components/Cards/ContainerCard";
import { GET_EMPLOYEE_ONBOARDING_TASK_BY_ID, GET_ONBOARDING_TASK_BY_ID, UPDATE_EMPLOYEE_ONBOARDING_TASK } from "../../../../../graphql/onBoardingTasks";
import { LOGOUT_USER } from "../../../../../graphql/user";
import { parseJwt } from "../../../../../utils/parseJwt";


const EmployeeOnBoardingTaskForm = ({
  fromModal = false,
  handleCloseModal,
  taskGuid,
  resetFields = false,
  fromDialog = false,
  refreshTasks
}) => {
  const { obName, obID } = useParams();

  const { userRoles, storeUserTenant, jwtToken, logout, updateToken } =
    useAuth();

  const { manageParameters: manageParametersRole } = userRoles;

  const { onBoarding_tasks } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const {
    type,
    description,
    attachment,
    name,
    status
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const [whatField, setWhatField] = useState(null);

  const [unTouchedForm, setUnTouchedForm] = useState(true);
  const [guid, setGuid] = useState("");

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);




  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_EMPLOYEE_ONBOARDING_TASK_BY_ID,
    "GetEmployeeOnBoardingTaskByID",
    {
      variables: { id: taskGuid },

    },
    {
      onCompleted: (response) => {
        const data = response?.GetEmployeeOnBoardingTaskByID?.employeeOnBoardingTask || {};
        setGuid(data?.employeeOnBoardingTaskGuid)
        setFormData({
          [type.name]: data?.onBoardingTask?.onBoardingType || null,
          [description.name]: data.description || "",
          [attachment.name]: data?.onBoardingTask?.attachment || null,
          [name.name]: data?.onBoardingTask?.description || "",
          [status.name]: data?.onBoardingTaskStatus || null
        });

      },

    },

  );
  const { cfunction: editData } = useMutationAuth(UPDATE_EMPLOYEE_ONBOARDING_TASK, "UpdateEmployeeOnBoardingTask");


  function editEmployeeOnboardingTask(values, actions) {
    editData(
      {
        isWait: guid,
        variables: {
          model: {

            employeeOnBoardingTaskGuid: guid,
            description: values[description.name],
            onBoardingTaskStatusGuid: values[status.name]?.onBoardingTaskStatusGuid

          },
        },
      },
      (response) => {
        if (
          response["UpdateEmployeeOnBoardingTask"].errorCode !== null &&
          response["UpdateEmployeeOnBoardingTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateEmployeeOnBoardingTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("onboardingTasks.updatedSuccessfully"), {
            variant: "success",
          });
          refreshTasks()
          sessionStorage.removeItem("unsavedChanges");

        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if (
      performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
      unTouchedForm && !fromModal
    ) {
      sessionStorage.clear();
    }
  }, []);

  const handleSubmit = (values, actions) => {
    editEmployeeOnboardingTask(values, actions)

  };

  return (
    <>

      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={fromDialog ? 12 : 9} lg={fromDialog ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${"edit"
                    }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched) {
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                          setUnTouchedForm(false);
                        }
                      }}
                    >
                      <Box p={fromDialog ? 0 : 2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              whatField,
                              setWhatField,
                              readOnly:
                                manageParametersRole === "R"
                            }}
                            taskGuid={taskGuid}
                          />
                        </Box>
                        <Box
                          mt={4}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={
                              isSubmitting || manageParametersRole !== "M"
                            }
                            loading={isSubmitting}
                            type="submit"
                            label={t("roleForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>

          </Grid>
        </Box>
      )}
    </>
  );
};

export default EmployeeOnBoardingTaskForm;
