import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { CloseIcon } from "../../../../../components/Icon";
import { Subtitle } from "../../../../../components/Titles/FormTitles";
import TimesheetForm from "../index";
import { v4 as uuidv4 } from "uuid";

const TimeEntryDrawer = ({
  PaperComponent,
  openTimeEntryModal,
  setOpenTimeEntryModal,
  handleChangeTimeEntryGuid,
  handleSetEmployeeGuid,
  fromTimeEntriesList,
  fromDashboard,
  handleEditMode,
  timeSheetGuid,
  timeEntryGuid,
  resetFields,
  refetchData,
  fromTimesheet,
  description,
  selectedTask,
  refetchTasks,
  refetchTask,
  handleFetchTree,
  handleDeselect,
  synced,
  editMode,
  employees,
  myTime,
  tasks,
  group,
  t,
  billable,
}) => {
  const handleCloseTimeEntryModal = () => {
    setOpenTimeEntryModal(false);
    if (refetchData) refetchData();
    if (handleEditMode !== undefined) handleEditMode(false);
    if (refetchTask !== undefined && refetchTask !== null) refetchTask();
    if (handleFetchTree !== undefined && handleFetchTree !== null)
      handleFetchTree();
  };

  return (
    <Dialog
      onClose={() => {
        if (handleChangeTimeEntryGuid) handleChangeTimeEntryGuid(null);
        if (handleCloseTimeEntryModal) handleCloseTimeEntryModal();
        if (handleDeselect !== undefined) handleDeselect(false);
        if (handleEditMode !== undefined) handleEditMode(false);
        sessionStorage.removeItem("unsavedChanges");
      }}
      PaperComponent={PaperComponent}
      open={openTimeEntryModal}
    >
      <DialogTitle
        style={{
          cursor: "move",
          borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
        }}
        id="draggable-dialog-title"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Subtitle
            style={{
              textAlign: "left",
              fontFamily: "poppins !important",
              fontSize: "16px",
              // paddingLeft: "35px",
            }}
          >
            {!fromDashboard
              ? t("timesheet.drawer.title")
              : editMode
              ? t("timesheet.drawer.title.edit")
              : t("timesheet.drawer.title.create")}
          </Subtitle>
          <IconButton
            onClick={() => {
              if (
                refetchTasks !== undefined &&
                refetchTasks !== null &&
                !fromDashboard &&
                !fromTimeEntriesList
              )
                refetchTasks();
              if (handleCloseTimeEntryModal) handleCloseTimeEntryModal();
              if (handleChangeTimeEntryGuid) handleChangeTimeEntryGuid(null);
              if (handleDeselect !== undefined) handleDeselect(false);
              if (handleEditMode !== undefined) handleEditMode(false);
              if (refetchTask !== undefined && refetchTask !== null)
                refetchTask();
              if (handleFetchTree !== undefined && handleFetchTree !== null)
                handleFetchTree();
              sessionStorage.removeItem("unsavedChanges");
            }}
          >
            <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
          </IconButton>
        </div>
      </DialogTitle>
      <TimesheetForm
        key={uuidv4()}
        timeSheetGuid={timeSheetGuid}
        timeEntryGuid={timeEntryGuid}
        description={description}
        synced={synced}
        editMode={editMode}
        employees={employees}
        resetFields={resetFields}
        refetchData={refetchData}
        fromTimesheet={fromTimesheet}
        selectedTask={selectedTask}
        handleEditMode={handleEditMode}
        handleChangeTimeEntryGuid={handleChangeTimeEntryGuid}
        handleSetEmployeeGuid={handleSetEmployeeGuid}
        tasks={tasks}
        fromDashboard={fromDashboard}
        handleDeselect={handleDeselect}
        handleCloseTimeEntryModal={handleCloseTimeEntryModal}
        myTime={myTime}
        group={group}
        taskBillable={billable}
      />
    </Dialog>
  );
};

export default TimeEntryDrawer;
