import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import GridTable from "../../../../components/Tables/GridTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_STATUS_COLOR,
} from "../../../../graphql/taskStatus";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";
import "./style.css";

//external components
import { CircularProgress, MenuItem, Select } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { CloseIcon, SaveIcon } from "../../../../components/Icon";
import { CREATE_TICKET_STATUS, DELETE_TICKET_STATUS, GET_ALL_TICKET_STATUSES, UPDATE_TICKET_STATUS, UPDATE_TICKET_STATUSES_LIST } from "../../../../graphql/ticketStatus";
import { GET_ALL_TASK_STATES } from "../../../../graphql/taskSate";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const TicketStatusList = ({ fromTenant = false }) => {
  const { tID, pID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const DEFAULT_STATUS = {
    ticketStatusGuid: "",
    description: "",
    state: "",
    ordered: 0,
    tenantGuid: fromTenant ? tID : storeUserTenant,
  };

  const { manageSystemConfigurations } = userRoles;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatusColorGuid, setSelectedStatusColorGuid] = useState(null);
  const [selectedStateGuid, setSelectedStateGuid] = useState(null);
  const [rowsUpdated, setRowsUpdated] = useState(false);
  const editMode = React.useRef(false);
  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows, setRows] = useState([]);
  const [max, setMax] = useState(0);
  const clickedRow = React.useRef(null);
  const clickedRowGuid = React.useRef("");

  
  const [newObj, setNewObj] = useState({
    ticketStatusGuid: "",
    description: "",
    state: "",
    order: 0,
    tenantGuid: fromTenant ? tID : storeUserTenant,
  });
  const requiredFields = ["ticketStatusGuid", "description", "State", "order"];

  const setCreate = React.useRef(false);
  const [changeOrder, setChangeOrder] = useState(false);

  const { mfunction: deleteRecord } = useMutationAuth(
    DELETE_TICKET_STATUS,
    "DeleteTicketStatus"
  );

  const { data: statusColor, tokenChange: statusWait } = useQueryAuth(
    GET_STATUS_COLOR,
    "GetStatusColors"
  );

  const statusColorsList =
    statusColor &&
    statusColor.GetStatusColors &&
    statusColor.GetStatusColors.statusColors
      ? statusColor.GetStatusColors.statusColors
      : [];

  const { data: taskStates, tokenChange: taskStatesWait } = useQueryAuth(
    GET_ALL_TASK_STATES,
    "GetAllTaskStates",
    {
      skip: statusWait,
      isWait: true,
    }
  );

  const taskStatesList =
    taskStates &&
    taskStates.GetAllTaskStates &&
    taskStates.GetAllTaskStates.tasksState
      ? taskStates.GetAllTaskStates.tasksState
      : [];    
      
  const { loading: getColumnsLoading, tokenChange: columnsWait } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["PhaseStatuses"],
          userID: storeUserGuid,
        },
      },
      skip: statusWait || taskStatesWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;
            cols.push({
              field: "State",
              headerName: t("taskStatus.list.column.State"),
              headerAlign: "center",
              align: "center",
              order: 2,
              flex: 0.2,
              renderCell: (params) => {
                const disabled = editMode.current === true ||
                  (editMode.current === false && clickedRow.current === null) ||
                  (clickedRow.current !== "" &&
                    clickedRow.current !== undefined &&
                    clickedRow.current !== null &&
                    clickedRow.current.ticketStatusGuid !== params.id) ||
                  (clickedRowGuid.current !== "" &&
                    clickedRowGuid.current !== undefined &&
                    clickedRowGuid.current !== null &&
                    clickedRowGuid.current !== params.id);
                return (
                  <div>
                    {taskStatesList.length > 0 && (
                      <Select
                        value={
                          params.row?.state?.taskStateGuid
                            ? params.row?.state?.taskStateGuid
                            : taskStatesList[0]?.taskStateGuid
                        }
                        onChange={(event) =>
                          handleStateChange(event, params.row, rows)
                        }
                        disabled={disabled}
                      >
                        {taskStatesList.map((stateObj) => (
                          <MenuItem
                            key={stateObj?.taskStateGuid}
                            value={stateObj?.taskStateGuid}
                          >
                            {stateObj.description}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                );
              },
            });
            cols.push({
              field: "Color",
              headerName: t("taskStatus.list.column.Color"),
              headerAlign: "center",
              align: "center",
              flex: 0.2,
              order: 4,
              renderCell: (params) => {
                const disabled = (editMode.current === false && clickedRow.current === null) || (clickedRow.current !== "" && clickedRow.current !== undefined && clickedRow.current !== null && clickedRow.current.ticketStatusGuid !== params.id) || (clickedRowGuid.current !== "" && clickedRowGuid.current !== undefined && clickedRowGuid.current !== null && clickedRowGuid.current !== params.id);
                return (
                  <div>
                    {statusColorsList.length > 0 && (
                      <Select
                        value={
                          params.row?.statusColor?.statusColorGuid
                            ? params.row?.statusColor?.statusColorGuid
                            : statusColorsList[0]?.statusColorGuid
                        }
                        onChange={(event) => {
                         handleStatusColorChange(event, params.row, rows);}
                        }
                      
                        disabled={disabled}
                      >
                        {statusColorsList.map((colorObj) => (
                          <MenuItem
                            key={colorObj?.statusColorGuid}
                            value={colorObj?.statusColorGuid}
                          >
                            <div
                              style={{
                                backgroundColor: colorObj.colorCode,
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            ></div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                );
              },
            });

            cols.sort((a, b) => {
              return a.order - b.order;
            });
            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const handleStatusColorChange = (event, id, r) => {
    const selectedColorGuid = event.target.value;
    setRows(prevRows => {
      const updatedRows = [...prevRows];
  
      // Find index of the row with the given ticketStatusGuid
      const index = updatedRows.findIndex(
        (x) => x.ticketStatusGuid === id.ticketStatusGuid
      );
  
      if (index !== -1) {
        updatedRows[index].statusColor = { statusColorGuid: selectedColorGuid };
      } else {
        const newStatus = {
          ...DEFAULT_STATUS,
          ticketStatusGuid: id.ticketStatusGuid,
          statusColor: {
            statusColorGuid: selectedColorGuid,
          },
          order: max,
          isNew: true,
        };
        updatedRows.unshift(newStatus);
      }
  
      // Return the updated rows
      return updatedRows;
    });
    setRowsUpdated(!rowsUpdated);
    setSelectedStatusColorGuid(selectedColorGuid);
  };

  const handleStateChange = (event, id, r) => {
    const selectedStateValue = event.target.value;
    setRows((prevRows) => {
      const updatedRows = [...prevRows];

      // Find index of the row with the given ticketStatusGuid
      const index = updatedRows.findIndex(
        (x) => x.ticketStatusGuid === id.ticketStatusGuid
      );
      
      if (index !== -1) {
        updatedRows[index].state = { taskStateGuid: selectedStateValue };
      } else {
        const newStatus = {
          ...DEFAULT_STATUS,
          ticketStatusGuid: id.ticketStatusGuid,
          state: {
            taskStateGuid: selectedStateValue,
          },
          order: max,
          isNew: true,
        };
        updatedRows.unshift(newStatus);
      }

      // Return the updated rows
      return updatedRows;
    });
    setRowsUpdated(!rowsUpdated);
    setSelectedStateGuid(selectedStateValue);
  };


  useEffect(() => {
    if (statusColorsList?.length > 0)
      setSelectedStatusColorGuid(statusColorsList[0]?.statusColorGuid);
  }, [statusColorsList.length]);

  useEffect(() => {
    if (rows.length > 0) {
      let updatedColumns = columns.map((col) => {
        if (col.field === "Color") {
          col.renderCell = (params) => {
            const disabled = (
              !editMode.current && 
              (clickedRow.current === null || 
              (clickedRow.current !== "" && 
              clickedRow.current !== undefined && 
              clickedRow.current !== null && 
              clickedRow.current.ticketStatusGuid !== params.id) || 
              (clickedRowGuid.current !== "" && 
              clickedRowGuid.current !== undefined && 
              clickedRowGuid.current !== null && 
              clickedRowGuid.current !== params.id))
            );
            return (
              <div>
                {statusColorsList.length > 0 && (
                  <Select
                    value={
                      params.row?.statusColor?.statusColorGuid ?
                      params.row?.statusColor?.statusColorGuid :
                      statusColorsList[0]?.statusColorGuid
                    }
                    onChange={(event) => handleStatusColorChange(event, params.row, rows)}
                    disabled={disabled}
                  >
                    {statusColorsList.map((colorObj) => (
                      <MenuItem key={colorObj?.statusColorGuid} value={colorObj?.statusColorGuid}>
                        <div
                          style={{
                            backgroundColor: colorObj.colorCode,
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        ></div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            );
          };
        } else if (col.field === "State") {
          col.renderCell = (params) => {
            const disabled = editMode.current === true ||
              (editMode.current === false && clickedRow.current === null) ||
              (clickedRow.current !== "" &&
                clickedRow.current !== undefined &&
                clickedRow.current !== null &&
                clickedRow.current.ticketStatusGuid !== params.id) ||
              (clickedRowGuid.current !== "" &&
                clickedRowGuid.current !== undefined &&
                clickedRowGuid.current !== null &&
                clickedRowGuid.current !== params.id);
            return (
              <div>
                {taskStatesList.length > 0 && (
                  <Select
                    value={
                      params.row?.state?.taskStateGuid ?
                      params.row?.state?.taskStateGuid :
                      taskStatesList[0]?.taskStateGuid
                    }
                    onChange={(event) => handleStateChange(event, params.row, rows)}
                    disabled={disabled}
                  >
                    {taskStatesList.map((stateObj) => (
                      <MenuItem key={stateObj?.taskStateGuid} value={stateObj?.taskStateGuid}>
                        {stateObj.description}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            );
          };
        }
        return col;
      });
  
      updatedColumns.sort((a, b) => a.order - b.order);
      setColumns(updatedColumns);
    }
  }, [rows, clickedRow.current, clickedRowGuid.current, editMode.current]);  

  const {
    mfunction: GetTicketsStatus,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ALL_TICKET_STATUSES, "GetTicketsStatus");

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    GetTicketsStatus(
      {
        variables: {
          model: {
            enableFilters: true,
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            keyWord: search,
            userID: storeUserGuid,
            tenantID: fromTenant ? tID : storeUserTenant,
          },
        },
        skip: columnsWait,
      },
      (response) => {
        setData(response);
        var myData =
          response?.GetTicketsStatus?.ticketStatusesList?.ticketStatuses;
        myData.forEach((status) => {
          // if (
          //   status.state?.toLowerCase() == "new" ||
          //   status.state?.toLowerCase() == "done"
          // )
          //   status.disabledSorting = true;
        });
        setRows(myData);
        setRowsUpdated(!rowsUpdated);
        setMax(response?.GetTicketsStatus?.ticketStatusesList?.maxOrder);
        setNewObj({
          ...newObj,
          order: response?.GetTicketsStatus?.ticketStatusesList?.maxOrder,
        });
      }
    );
  }, []);

  useEffect(() => {
    if (!columnsWait && columns.length > 0 && setCreate.current == false)
      fetchTableData(page, rowsPerPage, search);
  }, [columnsWait, columns.length, fetchTableData, re]);

  // const records =
  //   data &&
  //   data.GetTicketsStatus &&
  //   data.GetTicketsStatus.ticketStatusesList &&
  //   data.GetTicketsStatus.ticketStatusesList.ticketStatuses
  //     ? data.GetTicketsStatus.ticketStatusesList.ticketStatuses
  //     : [];
  const numberOfRecords =
    data &&
    data.GetTicketsStatus &&
    data.GetTicketsStatus.ticketStatusesList &&
    data.GetTicketsStatus.ticketStatusesList.totalNumberfRecords
      ? data.GetTicketsStatus.ticketStatusesList.totalNumberfRecords
      : 0;

  const handleDelete = (id) => {

    deleteRecord(
      {
        variables: { id: id },
      },
      (response) => {
        enqueueSnackbar(t("ticketStatus.list.delete.success"), {
          variant: "success",
        });
        fetchTableData(page, rowsPerPage, search);
        setRe((v) => !v);
        setPage(0);
      }
    );
  };

  const { cfunction: editTaskStatusData } = useMutationAuth(
    UPDATE_TICKET_STATUS,
    "EditTicketStatus"
  );

  const { cfunction: updateTicketStatusesData } = useMutationAuth(
    UPDATE_TICKET_STATUSES_LIST,
    "ChangeTicketStatusesOrder"
  );

  const { cfunction: createTaskStatusData } = useMutationAuth(
    CREATE_TICKET_STATUS,
    "CreateTicketStatus"
  );
  
  function CreateTicketStatus(newRow) {
    setIsSubmitting(true);
    createTaskStatusData(
      {
        variables: {
          model: {
            description: newRow.description,
            taskStateGuid: selectedStateGuid || taskStatesList[0]?.taskStateGuid,
            tenantID: fromTenant ? tID : storeUserTenant,
            // order: max,
            statusColorGuid: selectedStatusColorGuid,
          },
        },
      },
      (response) => {
        if (
          response["CreateTicketStatus"].errorCode !== null &&
          response["CreateTicketStatus"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateTicketStatus"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setRows(
            rows.filter((r) => r.ticketStatusGuid !== newObj.ticketStatusGuid)
          );
          setRowsUpdated(!rowsUpdated);
          setNewObj(DEFAULT_STATUS);
        } else {
          enqueueSnackbar(t("ticketStatusForm.createdSuccessfully"), {
            variant: "success",
          });
          editMode.current = false;
          clickedRow.current = null;
          clickedRowGuid.current = "";
          fetchTableData(page, rowsPerPage, search);
          setRowsUpdated(!rowsUpdated);
          setNewObj(DEFAULT_STATUS);
          refetchData();

        }
         setSelectedStatusColorGuid(statusColorsList[0]?.statusColorGuid);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      },
      (error) => {
        setRows(rows.filter((r) => r.ticketStatusGuid !== newObj.ticketStatusGuid));
        setRowsUpdated(!rowsUpdated);
        setNewObj(DEFAULT_STATUS);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      }
    );
  }
  
  function EditTicketStatus(updatedRow) {

    editTaskStatusData(
      {
        variables: {
          model: {
            ticketStatusGuid: updatedRow.ticketStatusGuid,
            description: updatedRow.description,
            taskStateGuid: updatedRow.state?.taskStateGuid,
            tenantID: fromTenant ? tID : storeUserTenant,
            order: updatedRow.order,
            statusColorGuid: updatedRow?.statusColor?.statusColorGuid ? updatedRow?.statusColor?.statusColorGuid : selectedStatusColorGuid ,
          },
        },
      },
      (response) => {
        //  const data = response?.EditTicketStatus?.TaskStatus || {};
        if (
          response["EditTicketStatus"].errorCode !== null &&
          response["EditTicketStatus"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditTicketStatus"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_STATUS);
        } else {
          enqueueSnackbar(t("ticketStatusForm.updatedSuccessfully"), {
            variant: "success",
          });
          editMode.current = false;
          clickedRow.current = null;
          clickedRowGuid.current = "";
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_STATUS);

        }
        setSelectedStatusColorGuid(statusColorsList[0]?.statusColorGuid);
        setRowsUpdated(!rowsUpdated);
        refetchData();
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_STATUS);
      }
    );
  }

  function UpdateTicketStatusList(updatedRows) {
    updateTicketStatusesData(
      {
        variables: {
          model: {
            ticketStatuses: updatedRows,
          },
        },
      },
      (response) => {
        if (
          response["ChangeTicketStatusesOrder"].errorCode !== null &&
          response["ChangeTicketStatusesOrder"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["ChangeTicketStatusesOrder"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          fetchTableData(page, rowsPerPage, search);
        } else {
          setChangeOrder(false);
          enqueueSnackbar(t("ticketStatusForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
      }
    );
  }

  const handleChangeOrder = () => {
    const updatedRows = rows;
    updatedRows.map((row) => {
      row.statusColorGuid = row.statusColor?.statusColorGuid;
      return row;
    });
    updatedRows.map((row) => {
      row.taskStateGuid = row.state?.taskStateGuid;
      return row;
    });
    updatedRows.map((row) => delete row.statusColor);
    updatedRows.map((row) => delete row.state);
    UpdateTicketStatusList(updatedRows);
  };

  const customButtons = [
    {
      label: "table.ChangeOrder",
      handleClick: () => {
        setChangeOrder(true);
        editMode.current = false;
      },

      condition: !changeOrder,
      icon: true,
      iconButton: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-arrows-up-down"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 3l0 18" />
          <path d="M10 6l-3 -3l-3 3" />
          <path d="M20 18l-3 3l-3 -3" />
          <path d="M17 21l0 -18" />
        </svg>
      ),
    },
    {
      label: "table.Save",
      handleClick: () => handleChangeOrder(),
      condition: changeOrder,
      icon: true,
      iconButton: <SaveIcon color={"var(--color-enabled-color)"} />,
    },
    {
      label: "table.Cancel",
      handleClick: () => {
        setChangeOrder(!changeOrder);
      },
      condition: changeOrder,
      icon: true,
      iconButton: <CloseIcon color={"#000000"} width={"24"} stroke={"1"} />,
    },
  ];

  const handleMoveUp = (row) => {
    if (row != null && row?.row?.order >= 1 && row?.row?.order <= max) {
      const newData = [...rows];
      const index = newData.findIndex((x) => x.ticketStatusGuid == row.id);
      const tempOrder = newData[index].order;
      newData[index].order = newData[index - 1].order;
      newData[index - 1].order = tempOrder;
      setRows(newData.sort((a, b) => a.order - b.order));
      setRowsUpdated(!rowsUpdated);
    }
  };
  const handleMoveDown = (row) => {
    if (row != null && row?.row?.order >= 1 && row?.row?.order <= max) {
      const newData = [...rows];
      const index = newData.findIndex((x) => x.ticketStatusGuid == row.id);
      const tempOrder = newData[index].order;
      newData[index].order = newData[index + 1].order;
      newData[index + 1].order = tempOrder;
      setRows(newData.sort((a, b) => a.order - b.order));
      setRowsUpdated(!rowsUpdated);
    }
  };

  const pagination = {
    rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "taskStatus.list.search.label",
    // refetch,
    deleteText: "taskStatus.list.delete.label",
    handleDelete: handleDelete,
    readOnly: manageSystemConfigurations !== "M",
    changeOrder,
    customButtons,
    handleMoveUp,
    removeFilters: true,
    handleMoveDown,
    max,
    hideAddButton: changeOrder,
    hideDeleteButton: true,
    isSubmitting,
    requiredFields,
    enqueueSnackbar,
  };

  // useEffect(() => {

  // }, [])

  const disableUpArrow = (row) => {
    const statusesArray = rows.filter((r) => r.state?.mnemonic === row?.state?.mnemonic);
    let {0 : first} = statusesArray;
    return first.ticketStatusGuid === row.ticketStatusGuid;
  }

  const disableDownArrow = (row) => {
    const statusesArray = rows.filter((r) => r.state?.mnemonic === row?.state?.mnemonic);
    let {[statusesArray.length - 1] : last} = statusesArray;
    return last.ticketStatusGuid === row.ticketStatusGuid;
  }
  
  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && (
          <GridTable
            {...pagination}
            identifier="ticketStatusGuid"
            hasCheckbox={false}
            editMode={editMode}
            changeOrder={changeOrder}
            handleCreate={(ticketStatus) => CreateTicketStatus(ticketStatus)}
            handleUpdate={(ticketStatus) => EditTicketStatus(ticketStatus)}
            handleDelete={(id) => handleDelete(id)}
            handleEditMode={(v) => (editMode.current = v)}
            handleClickedRowGuid={(v) => {
              clickedRowGuid.current = v;
            }}
            handleClickedRow={(v) => {
              clickedRow.current = v;
            }}
            disableUpArrow={(row) => disableUpArrow(row)}
            disableDownArrow={(row) => disableDownArrow(row)}
          />
        )
      )}
    </div>
  );
};

export default TicketStatusList;
