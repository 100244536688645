import { gql } from "@apollo/client";

export const GET_ALL_TICKET_STATUSES = gql`
query($model: GetAllTicketStatusesRequestModelInput!) {
    GetTicketsStatus(model: $model) {
      ticketStatusesList {
        ticketStatuses {
          ticketStatusGuid 
            description
            state {
              description
              taskStateGuid
              mnemonic
            }
            order
            statusColor{
              colorCode
              statusColorGuid
            }
          }
          maxOrder
        totalNumberfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TICKET_STATUS_BY_ID = gql`
query($id: UUID!) {
  GetTicketStatus(id: $id) {
    ticketStatus {
      ticketStatusGuid 
      description
      taskState {
          taskStateGuid 
          description
          mnemonic
      }
      tenant {
        tenantGuid
        name
      }
    }
    errorCode
    errorMessage
    errorVariables
    }
  }
`;

export const UPDATE_TICKET_STATUS = gql`
  mutation ($model: EditTicketStatusRequestInput!) {
    EditTicketStatus(model: $model) {
      crudTicketStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TICKET_STATUSES_LIST = gql`
  mutation ($model: ChangeTicketStatusesOrderModelInput!) {
    ChangeTicketStatusesOrder(model: $model) {
      crudTicketStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TICKET_STATUS = gql`
  mutation ($model: CreateTicketStatusRequestInput!) {
    CreateTicketStatus(model: $model) {
      crudTicketStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TICKET_STATUSES = gql`
  mutation ($model: DeleteTicketStatusListRequestInput!) {
    DeleteListOfTicketsStatus(model: $model) {
      crudTicketStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TICKET_STATUS = gql`
  mutation ($id: UUID!) {
    DeleteTicketStatus(id: $id) {
      crudTicketStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;