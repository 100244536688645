import React, { useEffect } from "react";
import Timeline from "react-timelines";
import "./style.css";
import "react-timelines/lib/css/style.css";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

import { buildTimebar } from "./Builder";
import { useState } from "react";

import { GET_TASK_BY_ID } from "../../graphql/task";
import useLazyQueryAuth from "../../hooks/useLazyQueryAuth";

import UTC2Local from '../../UTC2Local';

const AvailabilityTimeline = ({ tracks, startDate, endDate, zoom, setTask, setShowModal, setLoading, setParent }) => {
  const { t } = useTranslation();
  
  const now = new Date();
  const [tracksList, setTracksList] = React.useState([]);
  const [timebar, setTimeBar] = useState(null);

  const clickElement = (element) => showTask(element.id);

  const { mfunction: getTask, loading } = useLazyQueryAuth(
    GET_TASK_BY_ID,
    "GetTask"
  );

  const showTask = (id) => {
    getTask(
      {
        variables: { id: id },
      },
      (response) => {
        setTask(response?.GetTask?.taskView?.task || {});
        setParent(response?.GetTask?.taskView?.parentTask?.taskGuid	|| "");
      }
    );
    setShowModal(true);
  };

  useEffect(() => {
    setLoading(loading)
    setTimeBar(buildTimebar(startDate, endDate, (new Date(startDate)).getFullYear(), t))
  }, [startDate, endDate]);

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        setShowModal(false);
      }
    })
  }, []);

  useEffect(() => {
    const trckArr = [];
    tracks.forEach((track) => {
      const newElements = track.elements.map((element) => {
        const start = new Date(new Date(element.start).getTime() - (new Date(element.start).getTimezoneOffset() * 60000));
        let end = '';
        if(element.start === element.end) {
          const newEndDate = new Date(element.end)
          // Adjust the date to the user's local timezone
          end = new Date(new Date(newEndDate.setHours(newEndDate.getHours() + 23)).getTime() - (new Date(newEndDate.setHours(newEndDate.getHours() + 23)).getTimezoneOffset() * 60000));
        }
        else end = new Date(new Date(element.end).getTime() - (new Date(element.end).getTimezoneOffset() * 60000));
        return {
          ...element,
          start,
          end,
        };
      });
      
      const newTrack = {
        title: ReactHtmlParser(track.title),
        id: track.id,
        elements: newElements,
      };
      trckArr.push(newTrack);
    });

    setTracksList(trckArr);
  }, [tracks]);

  return (
    <div className="timeline">
      
      {timebar && (
        <Timeline
          scale={{
            start: startDate,
            end: endDate,
            zoom,
          }}
          timebar={timebar}
          tracks={tracksList}
          clickElement={clickElement}
          now={now}
          enableSticky
          scrollToNow
        />
      )}
    </div>
  );
};

export default AvailabilityTimeline;