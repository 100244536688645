import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function RequestTabs({ fromTenant = false }) {
  const { reqName, reqID, tName, tID } = useParams();

  const {
    my_new_request,
    my_requests_form,
    my_attendance_requests_form,
    my_new_attendance_request,
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  // if (
  //   !reqName ||
  //   !reqID ||
  //   reqName === "undefined" ||
  //   reqName === "null" ||
  //   reqName === "" ||
  //   reqID === "null" ||
  //   reqID === "undefined" ||
  //   reqID === ""
  // )
  //   return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(reqName);
  const encodedID = encodeURIComponent(reqID);

  const TabLinks = [
    {
      key: 1,
      Header: t("requestTabs.form"),
      Link: window.location.href.includes("attendance")
        ? window.location.href.split("/")[8] !== undefined
          ? my_attendance_requests_form.replace(
              "/:reqName/:reqID",
              `/${encodedName}/${encodedID}`
            )
          : my_new_request
        : window.location.href.split("/")[8] !== undefined
        ? my_requests_form.replace(
            "/:reqName/:reqID",
            `/${encodedName}/${encodedID}`
          )
        : my_new_request,
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} removeTabHeader />
    </Box>
  );
}

export default RequestTabs;
