import checkout from "./form";

const {
  formField: {
    description,
    suggestionStatus,
  },
} = checkout;

const initialValues = {
  [description.name]:"",
  [suggestionStatus.name]:null

};

export default initialValues;