import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

//components
import {
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";

import Tooltip from "../../../../../components/Tooltip";
import AutoComplete from "../../../../../components/AutoComplete";
import { Title, Subtitle } from "../../../../../components/Titles/FormTitles";

//translation
import { useTranslation } from "react-i18next";

import "./style.css";
//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";

//graphql
import { GET_ALL_EMPLOYMENT_TYPES } from "../../../../../graphql/employmentType";
import {
  GET_PTO_ASSIGN_DATES,
  CHANGE_PTO_STATUS,
  GET_DATE_UNITS,
} from "../../../../../graphql/pto";

import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";

import { Field } from "formik";

import Mnemonic from "../../../../../Mnemonics.json";
import BadgeDot from "../../../../../components/StatusCell/BadgeDot";
import form from "../schema/form";
import { AddIconOutlined, DeleteIcon } from "../../../../../components/Icon";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const { ptoID } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [fetchPTOAssignDates, setFetchPTOAssignDates] = useState(false);
  const [fetchDateUnits, setFetchDateUnits] = useState(false);
  const [fetchEmploymentTypes, setFetchEmploymentTypes] = useState(false);

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    wait,
    ptoStatus,
    setPTOStatus,
  } = formData;

  const {
    description,
    paid,
    accrued,
    accumulated,
    numberOfDays,
    employmentType,
    assignDate,
    seniorities,
    From,
    To,
    NewNumberOfDays,
    dateUnit,
  } = formField;

  const {
    description: descriptionV,
    paid: paidV,
    accrued: accruedV,
    accumulated: accumulatedV,
    numberOfDays: numberOfDaysV,
    employmentType: employmentTypeV,
    assignDate: assignDateV,
    seniorities: senioritiesV,
    dateUnit: dateUnitV,
  } = values;

  const {
    data: empTypeData,
    loading: empTypeLoading,
    tokenChange: empTypeWait,
  } = useQueryAuth(GET_ALL_EMPLOYMENT_TYPES, "GetAllEmploymentTypes", {
    skip: !fetchEmploymentTypes,
    isWait: fetchEmploymentTypes,
  });

  const empTypeList =
    empTypeData?.GetAllEmploymentTypes?.employmentTypeList?.employmentTypes ||
    [];

  const {
    data: ptoAssignDateData,
    loading: ptoAssignDateLoading,
    tokenChange: ptoAssignDateWait,
  } = useQueryAuth(GET_PTO_ASSIGN_DATES, "GetPTOAssignDates", {
    skip: !fetchPTOAssignDates,
    isWait: fetchPTOAssignDates,
  });

  const ptoAssignDateList =
    ptoAssignDateData?.GetPTOAssignDates?.assignDates || [];

  const { data: dateUnitsData, loading: getDateUnitsLoading } = useQueryAuth(
    GET_DATE_UNITS,
    "GetDateUnitsList",
    {
      skip: !fetchDateUnits,
      isWait: fetchDateUnits,
    }
  );

  const dateUnitList = dateUnitsData?.GetDateUnitsList?.dateUnitsList || [];

  const { mfunction: changePTOStatus, loading: changePTOStatusLoading } =
    useMutationAuth(CHANGE_PTO_STATUS, "UpdatePTOStatus");

  function handlePTOStatusChange() {
    changePTOStatus(
      {
        variables: {
          model: {
            active:
              ptoStatus.mnemonic === Mnemonic.PTOStatuses.Active ? false : true,
              ptoID: ptoID,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("ptoForm.statusChange.success"), {
          variant: "success",
        });
        setPTOStatus(data?.UpdatePTOStatus?.pto?.ptoStatus || null);
        //setIsActive((val) => !val);
      }
    );
  }

  function handleAddSeniority() {
    let newObj = {
      seniorityGuid: null,
      from: "",
      to: "",
      numberOfUnits: "",
    };

    setFieldValue(seniorities.name, [...senioritiesV, newObj]);
  }

  function handleDeleteSeniority(index) {
    const newArray = structuredClone(senioritiesV); //we clone a new array from the original one

    newArray.splice(index, 1); //we remove the object from the new array

    setFieldValue(seniorities.name, newArray);
  }

  function handleEditSeniorityValue(key, value, index) {
    const newArray = structuredClone(senioritiesV);

    newArray[index][key] = value;

    setFieldValue(seniorities.name, newArray);
  }
  
  useEffect(() => {
    if ((senioritiesV && senioritiesV.length !== 0) || !paidV)
      setFieldValue(numberOfDays.name, 0);
  }, [senioritiesV, paidV]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("ptoForm.PTOInformation")}
            {!createMode && (
              <BadgeDot
                color={
                  ptoStatus?.mnemonic === Mnemonic.PTOStatuses.Active
                    ? "success"
                    : "error"
                }
                text={ptoStatus?.description}
              />
            )}
          </Title>

          {!readOnly && !createMode && ptoStatus && (
            <IALoadingButton
              small
              label={
                ptoStatus.mnemonic === Mnemonic.PTOStatuses.Active
                  ? t("ptoForm.button.deactivate")
                  : t("ptoForm.button.activate")
              }
              error={ptoStatus.mnemonic === Mnemonic.PTOStatuses.Active}
              loading={changePTOStatusLoading}
              onClick={handlePTOStatusChange}
              center
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            autoFocus
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              "& .MuiFormLabel-root": { fontSize: "14px !important" },
            }}
            variant="standard"
            fullWidth
            label={t(description.label)}
            value={descriptionV}
            onBlur={(e) => setTouched({ ...touched, [description.name]: true })}
            onChange={(e) => {
              setFieldValue(description.name, e.target.value);
            }}
            disabled={readOnly}
            error={touched[description.name] && errors[description.name]}
            helperText={
              touched[description.name] &&
              errors[description.name] &&
              t(errors[description.name])
            }
            required
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
          <AutoComplete
            loading={getDateUnitsLoading}
            options={dateUnitList}
            getOptionLabel={(option) => option?.description}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"dateUnitGuid"}
            name={dateUnit.name}
            size="small"
            value={dateUnitV}
            onOpen={() => setFetchDateUnits(true)}
            onClose={() => setFetchDateUnits(false)}
            onChange={(event, value) => {
              setFieldValue(dateUnit.name, value);
            }}
            required
            disabled={readOnly}
            label={t(dateUnit.label)}
            formData={formData}
            sx={{ ".MuiInputBase-root": { marginTop: "19px !important" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <TextField
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              "& .MuiFormLabel-root": { fontSize: "14px !important" },
            }}
            variant="standard"
            type="number"
            fullWidth
            label={t(numberOfDays.label)}
            value={numberOfDaysV}
            onBlur={(e) =>
              setTouched({ ...touched, [numberOfDays.name]: true })
            }
            onChange={(e) => {
              setFieldValue(numberOfDays.name, e.target.value);
            }}
            required={
              !paidV &&
              (!senioritiesV || (senioritiesV && senioritiesV.length === 0))
            }
            disabled={
              readOnly || (senioritiesV && senioritiesV.length !== 0) || !paidV
            }
            error={touched[numberOfDays.name] && errors[numberOfDays.name]}
            helperText={
              touched[numberOfDays.name] &&
              errors[numberOfDays.name] &&
              t(errors[numberOfDays.name])
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <AutoComplete
            loading={empTypeLoading}
            options={empTypeList}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.name}
              </li>
            )}
            identifier={"employmentTypeGuid"}
            name={employmentType.name}
            size="small"
            required
            formData={formData}
            disabled={readOnly}
            value={employmentTypeV}
            onOpen={() => setFetchEmploymentTypes(true)}
            onClose={() => setFetchEmploymentTypes(false)}
            onChange={(event, value) => {
              setFieldValue(employmentType.name, value);
            }}
            label={t(employmentType.label)}
            sx={{ ".MuiInputBase-root": { marginTop: "18.5px !important" } }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <AutoComplete
            loading={ptoAssignDateLoading}
            options={ptoAssignDateList}
            getOptionLabel={(option) => option?.description}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"ptoAssignDateGuid"}
            name={assignDate.name}
            size="small"
            required
            formData={formData}
            disabled={readOnly}
            value={assignDateV}
            onOpen={() => setFetchPTOAssignDates(true)}
            onClose={() => setFetchPTOAssignDates(false)}
            onChange={(event, value) => {
              setFieldValue(assignDate.name, value);
            }}
            label={t(assignDate.label)}
            sx={{ ".MuiInputBase-root": { marginTop: "18.5px !important" } }}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={4} xl={4}></Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Field
            as={FormControlLabel}
            type={paid.type}
            name={paid.name}
            control={
              <Switch
                size="small"
                disabled={readOnly}
                checked={paidV}
                onBlur={(e) => setTouched({ ...touched, [paid.name]: true })}
                onChange={(e) => {
                  setFieldValue(paid.name, e.target.checked);
                }}
                error={touched[paid.name] && errors[paid.name]}
              />
            }
            label={t(paid.label)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Field
            as={FormControlLabel}
            type={accrued.type}
            name={accrued.name}
            control={
              <Switch
                size="small"
                disabled={readOnly}
                checked={accruedV}
                onBlur={(e) => setTouched({ ...touched, [accrued.name]: true })}
                onChange={(e) => {
                  setFieldValue(accrued.name, e.target.checked);
                }}
                error={touched[accrued.name] && errors[accrued.name]}
              />
            }
            label={t(accrued.label)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Field
            as={FormControlLabel}
            type={accumulated.type}
            name={accumulated.name}
            control={
              <Switch
                size="small"
                disabled={readOnly}
                checked={accumulatedV}
                onBlur={(e) =>
                  setTouched({ ...touched, [accumulated.name]: true })
                }
                onChange={(e) => {
                  setFieldValue(accumulated.name, e.target.checked);
                }}
                error={touched[accumulated.name] && errors[accumulated.name]}
              />
            }
            label={t(accumulated.label)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Subtitle>{t("ptoForm.Seniorities")}</Subtitle>
        </Grid>
        {senioritiesV &&
          senioritiesV.map((senio, index) => {
            return (
              <Grid container className="pto_seniorities_record" key={index} sx={{ paddingLeft: "16px"}}>
                <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
                  <TextField
                    sx={{
                      ".MuiInputBase-input": { fontSize: "14px !important" },
                      "& .MuiFormLabel-root": { fontSize: "14px !important" },
                    }}
                    variant="standard"
                    type="number"
                    fullWidth
                    required
                    key={index}
                    label={t(From.label)}
                    value={senio.from || 0}
                    onKeyPress={(e) => {
                      if (
                        e.nativeEvent.key === "-" ||
                        e.nativeEvent.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) =>
                      setTouched({
                        ...touched,
                        [From.name + "-" + index]: true,
                      })
                    }
                    onChange={(e) => {
                      handleEditSeniorityValue("from", e.target.value, index);
                    }}
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    disabled={readOnly}
                    error={touched[From.name + "-" + index] && !senio.from}
                    helperText={
                      touched[From.name + "-" + index] &&
                      !senio.from &&
                      t(From.requiredMessage)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
                  <TextField
                    sx={{
                      ".MuiInputBase-input": { fontSize: "14px !important" },
                      "& .MuiFormLabel-root": { fontSize: "14px !important" },
                    }}
                    variant="standard"
                    required
                    type="number"
                    fullWidth
                    key={index}
                    label={t(To.label)}
                    value={senio.to || 0}
                    onKeyPress={(e) => {
                      if (
                        e.nativeEvent.key === "-" ||
                        e.nativeEvent.key === "e"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) =>
                      setTouched({ ...touched, [To.name + "-" + index]: true })
                    }
                    onChange={(e) => {
                      handleEditSeniorityValue("to", e.target.value, index);
                    }}
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    disabled={readOnly}
                    error={touched[To.name + "-" + index] && !senio.to}
                    helperText={
                      touched[To.name + "-" + index] &&
                      !senio.to &&
                      t(To.requiredMessage)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3.5} lg={3.5} xl={3.5}>
                  <TextField
                    sx={{
                      ".MuiInputBase-input": { fontSize: "14px !important" },
                      "& .MuiFormLabel-root": { fontSize: "14px !important" },
                    }}
                    variant="standard"
                    required
                    type="number"
                    fullWidth
                    key={index}
                    label={t(NewNumberOfDays.label)}
                    value={senio.numberOfUnits || 0}
                    onKeyPress={(e) => {
                      if (
                        e.nativeEvent.key === "-" ||
                        e.nativeEvent.key === "e" ||
                        e.nativeEvent.key === "."
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) =>
                      setTouched({
                        ...touched,
                        [NewNumberOfDays.name + "-" + index]: true,
                      })
                    }
                    InputProps={{ inputProps: { min: 0, step: 1 } }}
                    onChange={(e) => {
                      handleEditSeniorityValue(
                        "numberOfUnits",
                        e.target.value,
                        index
                      );
                    }}
                    disabled={readOnly}
                    error={
                      touched[NewNumberOfDays.name + "-" + index] &&
                      !senio.numberOfUnits
                    }
                    helperText={
                      touched[NewNumberOfDays.name + "-" + index] &&
                      !senio.numberOfUnits &&
                      t(NewNumberOfDays.requiredMessage)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={0.5} lg={0.5} xl={0.5}>
                  {!readOnly && (
                    <Tooltip
                      key={index}
                      title={t("ptoForm.seniority.deleteDay")}
                    >
                      <IconButton onClick={() => handleDeleteSeniority(index)}>
                        <DeleteIcon color="#000000" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            );
          })}
        {assignDateV?.mnemonic !== Mnemonic.PTOAssignDate.Once && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {!readOnly && (
              <Tooltip title={t("ptoForm.seniority.addSeniority")}>
                <IconButton onClick={handleAddSeniority}>
                  <AddIconOutlined  width="20" height="20" color="#000000" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Main;
