import { gql } from "@apollo/client";

export const GET_ALL_CONTRACTORS = gql`
  query ($model: ContractorsListGetRequestInput!) {
    GetContractorsList(model: $model) {
      contractorsList {
        contractors {
          contractorGuid
          name
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
export const GET_CONTRACTOR_BY_ID = gql`
  query ($id: UUID!) {
    GetContractorByID(contractorId: $id) {
      contractor {
        contractorGuid
        name
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
export const CREATE_CONTRACTOR = gql`
  mutation ($model: ContractorCreateRequestInput!) {
    CreateContractor(model: $model) {
      contractor {
        contractorGuid
        name
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
export const UPDATE_CONTRACTOR = gql`
  mutation ($model: ContractorUpdateRequestInput!) {
    UpdateContractor(model: $model) {
      contractor {
        contractorGuid
        name
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
export const DELETE_CONTRACTORS = gql`
  mutation ($model: ContractorDeleteRequestInput!) {
    DeleteContractor(model: $model) {
      deleteContractorResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
