import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UTC2Time } from "../../../UTC2Local";
import {
  getTimeDifference,
} from "../../../utils/functions";

import colorsLight from "../../../assets/theme/light/colors";
import No_Image_Found from "../../../assets/images/no-user-image.png";

const data = (t) => {
  return {
  title: <></>,
  headers: [
    t("dashboard.card.employee"),
    { align: "center", label: t("dashboard.card.workShiftClockIn") },
    { align: "center", label: t("dashboard.card.actualClockOut") },
    { align: "center", label: t("dashboard.card.timeDifference") },
  ],
  render: [
    (row) => {
      return (
        <div className="data-card-employee-name-image">
          <span>
            {(row?.employee?.profilePictureURL && (
              <img
                src={row.employee.profilePictureURL }
                alt="Not Found"
                className="userImage"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; 
                  currentTarget.src=No_Image_Found;
                }}
              />
            )) || <AccountCircleIcon />}
          </span>
          <p>{row?.employee?.firstName + " " + row?.employee?.lastName}</p>
        </div>
      );
    },

    (row) => {
      return (
        <p style={{ textAlign: "center" }}>
          {UTC2Time(row?.workShiftDay?.startHour?.toLocaleString(), false)}
        </p>
      );
    },
    (row) => {
      return (
        <p style={{ textAlign: "center" }}>{UTC2Time(row?.checkInTime)}</p>
      );
    },
    (row, t) => {
      let _return = "";

      const diff = getTimeDifference(
        UTC2Time(row?.checkInTime),
        UTC2Time(row?.workShiftDay?.startHour?.toLocaleString(), false)
      );

      const { hours, minutes } = diff;

      if (hours !== null && hours > 0 && minutes !== null && minutes > 0)
        _return = t("timeDiff.HoursAndMinutes", {
          hours: hours,
          hPlural: hours > 1 ? "s" : "",
          minutes: minutes,
          mPlural: minutes > 1 ? "s" : "",
        });
      else if (hours !== null && hours > 0)
        _return = t("timeDiff.HoursOnly", {
          hours: hours,
          hPlural: hours > 1 ? "s" : "",
        });
      else if (minutes !== null)
        _return = t("timeDiff.MinutesOnly", {
          minutes: minutes,
          mPlural: minutes > 1 ? "s" : "",
        });

      return (
        <p
          style={{
            textAlign: "center",
            color:
              hours !== null || (hours.minutes !== null && hours.minutes >= 15)
                ? colorsLight.error
                : "",
          }}
        >
          {_return}
        </p>
      );
    },
  ],
}
};

export default data;
