import React, { useRef, useState } from "react";

import { Box, Grid } from "@mui/material";

//translation
import { useTranslation } from "react-i18next";

import useAuth from "../../../../../hooks/useAuth";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";

import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../../graphql/location";
import { GET_ALL_WORK_TYPES } from "../../../../../graphql/workType";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../../graphql/employee";
import {
  CANCEL_ATTENDANCE_REQUEST,
  UPDATE_ATTENDANCE_REQUEST,
} from "../../../../../graphql/attendanceRequests";

import colors from "../../../../../assets/theme/light/colors";
import Mnemonic from "../../../../../Mnemonics.json";

import TextField from "../../../../../components/TextFields/TextField";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import AutoComplete from "../../../../../components/AutoComplete";

import {
  DesktopDatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { day_month_year } from "../../../../../utils/timezones";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "../../../../../components/Modal/ConfirmationModal";
import CustomButton from "../../../../../components/CustomButton";
import { HelpIcon } from "../../../../../components/Icon";

import { enqueueSnackbar } from "notistack";

const AttendanceForm = ({
  formData,
  reqID,
  refetch,
  setDateError,
  dateError,
  dayLog,
}) => {
  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchWorkTypes, setFetchWorkTypes] = useState(false);
  const [fetchAdmins, setFetchAdmins] = useState(false);
  const [fetchLocations, setFetchLocations] = useState(false);

  const [rejectReasonMandatory, setRejectReasonMandatory] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [type, setType] = useState("");

  const [status, setStatus] = useState(false);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const isNotOnsite = useRef(false);

  const [contentText, setContentText] = useState("");

  const readOnly = reqID !== null && reqID !== undefined && reqID !== "";

  const {
    storeUserGuid,
    storeUserTenant,
    storeUserManagerGuid,
    storeUserWorkshiftDays,
  } = useAuth();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    isSubmitting,
    setSubmitting,
    disableFields,
  } = formData;

  const {
    employee,
    receiver,
    requestStatus,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    description,
    rejectionReason,
    workType,
    location,
  } = formField;

  const {
    employee: employeeV,
    receiver: receiverV,
    requestStatus: requestStatusV,
    description: descriptionV,
    dateFrom: dateFromV,
    dateTo: dateToV,
    timeFrom: timeFromV,
    timeTo: timeToV,
    rejectionReason: rejectionReasonV,
    workType: workTypeV,
    location: locationV,
  } = values;

  const { cfunction: changeStatus, loading: changeStatusLoading } =
    useMutationAuth(UPDATE_ATTENDANCE_REQUEST, "UpdateAttendanceRequestStatus");

  const { cfunction: cancelAttendanceRequest, loading: cancelRequestLoading } =
    useMutationAuth(CANCEL_ATTENDANCE_REQUEST, "CancelAttendanceRequest");

  const {
    data: workTypesData,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes", {
    skip: !fetchWorkTypes,
    isWait: fetchWorkTypes,
  });

  const { data: employeesData, loading: adminsLoading } = useQueryAuth(
    GET_ALL_EMPLOYEES_DROPDOWN,
    "GetCompanyUsersList",
    {
      variables: {
        model: {
          enableFilters: false,
          tenantID: storeUserTenant,
          userID: storeUserGuid,
          adminsList: true,
        },
      },
      skip: !fetchAdmins,
      isWait: fetchAdmins,
    }
  );

  const {
    data: locationData,
    loading: locationLoading,
    refetch: refetchLocations,
  } = useQueryAuth(GET_ALL_LOCATIONS_DROPDOWN, "GetAllLocations", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        // isNotOnsite: isNotOnsite.current,
        isNotOnsite:
          workTypeV?.mnemonic === Mnemonic.WorkTypes.workfromoffice
            ? true
            : workTypeV?.mnemonic === Mnemonic.WorkTypes.workfromsite
            ? false
            : false,
      },
    },
    skip:
      !fetchLocations ||
      workTypeV?.mnemonic === Mnemonic.WorkTypes.workfromhome,
    isWait: fetchLocations,
  });

  const locationsList =
    locationData?.GetAllLocations?.locationsList?.locations || [];
  const workTypeList = workTypesData?.GetAllWorkTypes?.workTypes || [];
  const adminsList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  function changeRequestStatus(status) {
    setAcceptLoading(status);
    setRejectLoading(!status);

    changeStatus(
      {
        variables: {
          model: {
            approve: status,
            attendanceRequestGuid: reqID,
            rejectionReason: rejectionReasonV,
            userID: storeUserGuid,
            worktypeGuid: workTypeV?.workTypeGuid,
            locationGuid: locationV?.locationGuid,
          },
        },
      },
      (response) => {
        if (
          response["UpdateAttendanceRequestStatus"].errorCode !== null &&
          response["UpdateAttendanceRequestStatus"].errorCode !== ""
        ) {
          enqueueSnackbar(
            t(response["UpdateAttendanceRequestStatus"].errorMessage),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
          setAcceptLoading(false);
          setRejectLoading(false);
        } else {
          enqueueSnackbar(t("requestForm.submitted.successfully"), {
            variant: "success",
          });
          refetch();
          sessionStorage.removeItem("unsavedChanges");
          setAcceptLoading(false);
          setRejectLoading(false);
          setOpenDialog(false);
          setOpenWarning(false);
        }
      },
      (error) => {
        setAcceptLoading(false);
        setRejectLoading(false);
      }
    );
  }

  function handleCancelAttendanceRequest() {
    setCancelLoading(true);
    cancelAttendanceRequest(
      {
        variables: {
          model: {
            attendanceRequestGuid: reqID,
            userID: storeUserGuid,
          },
        },
      },
      (response) => {
        refetch();
        // navigate(my_requests);
      },
      (error) => {
        setCancelLoading(false);
      }
    );
    setCancelLoading(false);
  }

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  const handleOpenDialog = (status) => {
    setRejectReasonMandatory(null);
    setStatus(status);
    status === null
      ? setContentText(t("pto.dialog.confirm.cancel"))
      : status === true
      ? setContentText(t("pto.dialog.confirm.approve"))
      : setContentText(t("pto.dialog.confirm.reject"));
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    status === null
      ? handleCancelAttendanceRequest()
      : status === true
      ? changeRequestStatus(status)
      : changeRequestStatus(status);
    handleClose();
  };

  return (
    <>
      <ConfirmationModal
        contentText={contentText}
        title={t("dialog.confirm")}
        icon={<HelpIcon color={colors.light_logo_blue} />}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AutoComplete
            autoFocus
            disabled={
              disableFields ||
              (reqID &&
                requestStatusV?.mnemonic !==
                  Mnemonic.RequestStatuses.SentForApproval)
            }
            options={workTypeList}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"workTypeGuid"}
            loading={workTypeLoading}
            value={workTypeV}
            onOpen={() => setFetchWorkTypes(true)}
            onClose={() => setFetchWorkTypes(false)}
            onChange={(e, value) => {
              setFieldValue(workType.name, value);
              setFieldValue(location.name, null);
            }}
            label={t(workType.label)}
            name={workType.name}
            formData={formData}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AutoComplete
            disabled={
              disableFields ||
              (reqID &&
                requestStatusV?.mnemonic !==
                  Mnemonic.RequestStatuses.SentForApproval) ||
              !workTypeV ||
              workTypeV.mnemonic === Mnemonic.WorkTypes.workfromhome
            }
            options={locationsList}
            getOptionLabel={(option) => (option ? option.name : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.name}
              </li>
            )}
            identifier={"locationGuid"}
            loading={locationLoading}
            value={locationV}
            onClose={() => setFetchLocations(false)}
            onChange={(e, value) => {
              setFieldValue(location.name, value);
            }}
            label={t(location.label)}
            name={location.name}
            formData={formData}
            required={
              workTypeV &&
              workTypeV.mnemonic !== Mnemonic.WorkTypes.workfromhome
            }
          />
        </Grid>
        {!storeUserManagerGuid && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <AutoComplete
              disabled={readOnly}
              options={adminsList}
              getOptionLabel={(option) =>
                option ? option.firstName + " " + option.lastName : ""
              }
              renderOption={(props, option) => (
                <li {...props} key={uuidv4()}>
                  {option.firstName + " " + option.lastName}
                </li>
              )}
              identifier={"employeeGuid"}
              loading={adminsLoading}
              value={receiverV}
              onOpen={() => setFetchAdmins(true)}
              onClose={() => setFetchAdmins(false)}
              onChange={(e, value) => {
                setFieldValue(receiver.name, value);
              }}
              label={t(receiver.label)}
              name={receiver.name}
              formData={formData}
              required
            />
          </Grid>
        )}

        <Grid
          className="timesheet-date"
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiFormLabel-root": {
              fontSize: "14px !important",
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              fullWidth
              disableFuture
              disabled={readOnly}
              format={
                day_month_year.includes(userTimezone) === true
                  ? "dd-MM-yyyy"
                  : "MM-dd-yyyy"
              }
              label={t(dateFrom.secondaryLabel)}
              value={dateFromV}
              onChange={(newValue) => {
                setFieldValue(dateFrom.name, newValue);

                if (!newValue) {
                  setFieldValue(timeFrom.name, null);
                  setFieldValue(timeTo.name, null);
                } else {
                  const day =
                    newValue.getDay() >= 1
                      ? storeUserWorkshiftDays[newValue.getDay() - 1]
                      : storeUserWorkshiftDays[6];
                  if (day?.isWorkDay === false)
                    setDateError(t("non.working.day"));
                  else setDateError(null);
                  if (day?.isWorkDay === true) {
                    const startHour = day?.startHour
                      ? day?.startHour.includes("Z")
                        ? new Date(day?.startHour.slice(0, -1))
                        : new Date(day?.startHour)
                      : null;
                    setFieldValue(timeFrom.name, startHour);
                    const endHour = day?.endHour
                      ? day?.endHour.includes("Z")
                        ? new Date(day?.endHour.slice(0, -1))
                        : new Date(day?.endHour)
                      : null;
                    setFieldValue(timeTo.name, endHour);
                  }
                }
              }}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                dialog: {
                  disablePortal: true,
                  style: { zIndex: 1000000 },
                },
                textField: {
                  required: true,
                  size: "medium",
                  variant: "standard",
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },

                  error:
                    (touched[dateFrom.name] && errors[dateFrom.name]) ||
                    dateError,
                  helperText:
                    (touched[dateFrom.name] &&
                      errors[dateFrom.name] &&
                      t(errors[dateFrom.name])) ||
                    dateError,

                  onBlur: (e) =>
                    setTouched({
                      ...touched,
                      [dateFrom.name]: true,
                    }),
                },
              }}
              sx={{
                width: "100% !important",
                fontFamily: "poppins",
                "& .MuiInputBase-input": {
                  fontSize: "14px !important",
                },
                "& .MuiInputAdornment-root .MuiButtonBase-root": {
                  fontSize: "14px !important",
                  marginRight: "-5px !important",
                },
                "& .MuiInputBase-root": {
                  fontSize: "14px !important",
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        {storeUserManagerGuid && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderBottom: "1px solid #949494 !important",
              borderRadius: "0 !important",
            },
            "& .MuiOutlinedInput-input": {
              padding: "8.5px 0 !important",
            },
            "& .MuiInputLabel-root": {
              marginLeft: "-15px !important",
              fontSize: "14px !important",
            },
            "& .MuiInputBase-input": { fontSize: "14px !important" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t(timeFrom.label)}
              value={timeFromV}
              ampm={false}
              disabled={readOnly || dateToV || !dateFromV || dateError}
              maxTime={timeToV}
              variant="standard"
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },
                  onBlur: (e) => {
                    setTouched({
                      ...touched,
                      [timeFrom.name]: true,
                    });
                  },
                  error: touched[timeFrom.name] && errors[timeFrom.name],
                  helperText:
                    touched[timeFrom.name] &&
                    errors[timeFrom.name] &&
                    t(errors[timeFrom.name]),
                },
              }}
              onChange={(newValue) => {
                setFieldValue(timeFrom.name, newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderBottom: "1px solid #949494 !important",
              borderRadius: "0 !important",
            },
            "& .MuiOutlinedInput-input": {
              padding: "8.5px 0 !important",
            },
            "& .MuiInputLabel-root": {
              marginLeft: "-15px !important",
              fontSize: "14px !important",
            },
            "& .MuiInputBase-input": { fontSize: "14px !important" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t(timeTo.label)}
              value={timeToV}
              minTime={timeFromV}
              disabled={readOnly || dateToV || !timeFromV || dateError}
              // className="date_picker_styles"
              ampm={false}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  size: "small",
                  fullWidth: true,
                  sx: {
                    ".Mui-disabled": {
                      color: "#636563",
                      WebkitTextFillColor: "#636563",
                    },
                    fontSize: "14px !important",
                  },
                  onBlur: (e) => {
                    setTouched({
                      ...touched,
                      [timeTo.name]: true,
                    });
                  },
                  error: touched[timeTo.name] && errors[timeTo.name],
                  helperText:
                    touched[timeTo.name] &&
                    errors[timeTo.name] &&
                    t(errors[timeTo.name]),
                },
              }}
              onChange={(newValue) => {
                setFieldValue(timeTo.name, newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ paddingTop: "10px !important" }}
        >
          <TextField
            fullWidth
            value={descriptionV}
            multiline
            onChange={(e) => {
              setFieldValue(description.name, e.target.value);
            }}
            label={t(description.label)}
            name={description.name}
            formData={formData}
            disabled={readOnly}
            required
            sx={{
              ".MuiInputBase-input": { fontSize: "14px !important" },
              ".Mui-disabled": { color: "#636563" },
              "& .MuiFormLabel-root": { fontSize: "14px !important" },
            }}
          />
        </Grid>

        {reqID !== undefined &&
          reqID !== null &&
          receiverV?.employeeGuid !== employeeV?.employeeGuid &&
          ((requestStatusV?.mnemonic !==
            Mnemonic.RequestStatuses.SentForApproval &&
            receiverV?.employeeGuid === storeUserGuid) ||
            requestStatusV?.mnemonic !== Mnemonic.RequestStatuses.Approved ||
            requestStatusV?.mnemonic !== Mnemonic.RequestStatuses.Canceled) && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: "6px !important" }}
            >
              <TextField
                fullWidth
                value={rejectionReasonV}
                onChange={(e) => {
                  setFieldValue(rejectionReason.name, e.target.value);
                }}
                label={t(rejectionReason.label)}
                name={rejectionReason.name}
                formData={formData}
                required={rejectReasonMandatory}
                disabled={
                  employeeV?.employeeGuid === storeUserGuid ||
                  requestStatusV?.mnemonic !==
                    Mnemonic.RequestStatuses.SentForApproval
                }
                sx={{
                  ".MuiInputBase-input": { fontSize: "14px !important" },
                  ".MuiInputLabel-root": { fontSize: "14px !important" },
                  ".Mui-disabled": {
                    color: "#636563",
                    WebkitTextFillColor: "#636563",
                  },
                }}
                error={
                  (touched[rejectionReason.name] &&
                    errors[rejectionReason.name]) ||
                  rejectReasonMandatory
                }
                helperText={
                  (touched[rejectionReason.name] &&
                    errors[rejectionReason.name] &&
                    t(errors[rejectionReason.name])) ||
                  rejectReasonMandatory
                }
              />
            </Grid>
          )}
        {!reqID && (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} mt={2}>
              <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
                <IALoadingButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  label={t("requestForm.submit")}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid mt={3} xs={12} sm={12} md={12} lg={12} xl={12}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {requestStatusV?.mnemonic ===
              Mnemonic.RequestStatuses.SentForApproval &&
              receiverV?.employeeGuid === storeUserGuid && (
                <>
                  <IALoadingButton
                    loading={acceptLoading}
                    disabled={rejectLoading || cancelLoading}
                    label={t("requestForm.Approve")}
                    onClick={() => handleOpenDialog(true)}
                    lightBlue
                  />
                  <IALoadingButton
                    loading={rejectLoading}
                    disabled={acceptLoading || cancelLoading}
                    error
                    label={t("requestForm.Reject")}
                    onClick={() => {
                      if (
                        rejectionReasonV === null ||
                        rejectionReasonV === undefined ||
                        rejectionReasonV === ""
                      )
                        setRejectReasonMandatory(
                          t("requestForm.reason.requiredMsg")
                        );
                      else handleOpenDialog(false);
                    }}
                  />
                </>
              )}

            {employeeV?.employeeGuid === storeUserGuid &&
              requestStatusV?.mnemonic ===
                Mnemonic.RequestStatuses.SentForApproval && (
                <Grid container>
                  <Box
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <IALoadingButton
                      loading={cancelLoading}
                      disabled={cancelLoading || acceptLoading || rejectLoading}
                      // secondary
                      label={t("requestForm.Cancel")}
                      onClick={() => handleOpenDialog(null)}
                    />
                  </Box>
                </Grid>
              )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceForm;
