import React from 'react';
import UTC2Local, { getTime, UTC2Time } from '../../UTC2Local';
import { RightArrow } from '../Icon';

const DateTime = ({ dateFrom, dateTo, timeFrom, timeTo }) => {

    const rowStyle = {
        display: 'flex',
        alignItems: 'center'
    };


    return (
        <div>
            {dateFrom && dateTo && timeFrom == "" && timeTo == "" (
                <div style={rowStyle}>
                    <span>{UTC2Local(dateFrom, true)}</span>
                    <span style={{ margin: '5px 5px 0 5px' }}>{RightArrow()}</span>
                    <span>{UTC2Local(dateTo, true)}</span>
                </div>
            )}
            {timeFrom && timeTo && (
                <div style={rowStyle}>
                    <span>{UTC2Local(dateFrom, true)}</span>
                    <span style={{ margin: '5px' }}>-</span>
                    <span>{UTC2Local(dateTo, true)}</span>
                    <span>{UTC2Time(timeFrom, false)}</span>
                    <span style={{ margin: '5px 5px 0 5px' }}>{RightArrow()}</span>
                    <span>{UTC2Time(timeTo, false)}</span>
                </div>
            )}
            {dateFrom && dateTo && (
                <div style={rowStyle}>
                    <span>{UTC2Local(dateFrom, true)}</span>
                    <span style={{ margin: '5px 5px 0 5px' }}>{RightArrow()}</span>
                    <span>{UTC2Local(dateTo, true)}</span>
                </div>
            )}
            { timeTo == null && dateTo == null   && (
                <div style={rowStyle}>
                    <span>{UTC2Local(dateFrom, true)}</span>
                    <span style={{ margin: '5px' }}>-</span>
                    <span>{UTC2Local(dateTo, true)}</span>
                    <span>{UTC2Time(timeFrom, false)}</span>
                    <span style={{ margin: '5px 5px 0 5px' }}>{RightArrow()}</span>
                    <span>{UTC2Time(timeTo, false)}</span>
                </div>
            )}
        </div>
    );
}

export default DateTime;
