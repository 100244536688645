
export const DataTableData = {
    columns: [
      
      {
        id:"f7c800f7-008a-4000-814e-029b5a09c96e",
        field: "description",
        align: "left",
        headerName: "Description",
        label: "systVar.list.column.description",
        flex: 1,
        editable: false,
      },
      { id:"0d8cd556-fb22-455e-84fa-5c9f464ed9f5",
      field: "additionalInfo",
      align: "left",
      headerName: "Additional Info",
      label: "systVar.list.column.additionalInfo",
      flex: 1,
      editable: true,
    },
      { id:"0d8cd556-fb22-455e-84fa-5c9f464ed9f6",
        field: "value",
        align: "left",
        headerName: "Value",
        label: "systVar.list.column.Value",
        flex: 1,
        editable: true,
      },
     
    ],
  };
