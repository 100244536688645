import { styled } from "styled-components";

export const Title = styled.h5`
  font-size: 1.25rem;
  line-height: 1.375rem;
  font-weight: 700;
  // letter-spacing: 0em;
  color:var(--text-color-primary);
  font-family: 'poppins' !important;
  display:flex;
  gap: 10px;
`;

export const Subtitle = styled.h6`
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 700;
  // letter-spacing: 0.0075em;
  color: var(--text-color-secondary);
  font-family: 'poppins' !important;
`;

export const MiniSubtitle = styled.h6`
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 0.6rem;
  font-weight: 700;
  // letter-spacing: 0.0075em;
  color: var(--text-color-secondary);
  font-family: 'poppins' !important;
`;

export const MiniTitle = styled.h6`
  font-size: 0.9rem;
  line-height: 1rem;
  margin-top: 0.6rem;
  font-weight: 700;
  // letter-spacing: 0.0075em;
  color: var(--text-color-secondary);
  font-family: 'poppins' !important;
`;

export const Labeltitle = styled.p`
 font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 600;
  // letter-spacing: 0.0075em;
  color: var(--text-color-primary);
  font-family: 'poppins' !important;
`