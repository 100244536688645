import checkout from "./form";

const {
  formField: {
    status,
    description,
    deviceID,
    fingerprint,
    location,
    workType,
  },
} = checkout;

const initialValues = {
  [status.name]:null,
  [description.name]:"",
  [deviceID.name]:"",
  [location.name]:null,
  [fingerprint.name]:"",
  [workType.name]:null,
};

export default initialValues;