import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import RoundedTextField from "../TextFields/RoundedTextField";

const FieldModal = ({
  PaperComponent,
  CustomButton,
  components,
  loading,
  content,
  openDialog,
  handleClose,
  setState,
  handleSave,
  title,
  label,
  t,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {title ? title : t("dialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <RoundedTextField
          autoFocus
          margin="dense"
          id={label}
          label={label}
          onChange={(e) => setState(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
          multiline
        />
        {components && components()}
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
        <div>
          <CustomButton onClick={handleSave} label={t("dialog.save")}/>
          {loading && (
            <Box
              sx={{
                backgroundColor: "#00000025",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <CircularProgress
                size={24}
                sx={{
                  color: "#337ab7",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                  zIndex: 12,
                }}
              />
            </Box>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FieldModal;
