import React, { useEffect, useState } from "react";

//redux
import { useLocation, useNavigate, Link } from "react-router-dom";

//external components
import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Collapse,
} from "@mui/material";

//translation
import { useTranslation } from "react-i18next";

//styles
import { item, itemContent } from "./styles/styles";
import "./style.css";

//components
import List from "./list";

import useAuth from "../../hooks/useAuth";

import ConfirmationPopup from "../../components/Popup";
import { ExpandLessIcon, ExpandMoreIcon } from "../../components/Icon";
import colors from "../../assets/theme/light/colors";
import Tooltip from "../Tooltip";

const Items = (props) => {
  const [unsavedChanges, setUnsavedChanges] = useState(JSON.parse(sessionStorage.getItem('unsavedChanges')));
  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);

  const { dark_logo_blue, pure_white, open_menu_blue } = colors;

  const {
    userRoles,
    timeEntryEnabled,
    attendanceEnabled,
    tenantMonthlyTimesheet,
    isAttendance,
    isTimeEntry,
    isEmployee
  } = useAuth();
  const auth = useAuth();
  const { t } = useTranslation();

  const {
    type,
    display,
    label,
    icon,
    path,
    name,
    miniSideNav,
    inline_routes,
    collapse,
    handleCloseList,
    closeList,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const items = pathname.split("/").slice(1);
  const dashboardRoute = items[0];
  const keyRoute = items[1];

  const [open, setOpen] = useState(
    inline_routes &&
      inline_routes.findIndex(
        (r) =>
          (r.type === "route" && r.name === keyRoute) || //for route
          (r.type !== "route" &&
            r.routes_inside.findIndex((x) => x.name === keyRoute) !== -1) //for authentication or nested
      ) !== -1
  );

  useEffect(() => {
    setUnsavedChanges(JSON.parse(sessionStorage.getItem('unsavedChanges')))
  }, [])

  useEffect(() => {
    if ((miniSideNav || closeList) && type === "collapse") setOpen(false);
  }, [miniSideNav, closeList]);

  const active = dashboardRoute === props.name || keyRoute === props.name; //to open collapselist

  if (type === "collapse" && display !== undefined) {
    if (!display(userRoles, auth)) return null;
    if (display.toString().includes("manageAttendance"))
      if (userRoles.manageAttendance !== "N" && isAttendance === false)
        return null;

    if (display.toString().includes("manageTimeEntry"))
      if (userRoles.manageTimeEntry !== "N" && isTimeEntry === false)
        return null;
  }
  if (type === "route") {
    // if (
    //   display.toString().includes("auth") &&
    //   (auth === false)
    // )
    //   return null;
    if (
      display.toString().includes("manageTimeEntry") &&
      (timeEntryEnabled === false || isTimeEntry === false)
    )
      return null;
    if (
      display.toString().includes("manageAttendance") &&
      (attendanceEnabled === false || isAttendance === false)
    )
      return null;
    if(display.toString().includes("myTimeSheet")) {
      if(timeEntryEnabled === false) return null;
    }
    if(display.toString().includes("monthlyTimesheet")) {
      if(display.toString().includes("true") && tenantMonthlyTimesheet === false) return null;
      if(display.toString().includes("false") && tenantMonthlyTimesheet === true) return null;
    }
  }

  if(type === "nested") {
    if(display.toString().includes("monthlyTimesheet")) {
      if(display.toString().includes("true") && tenantMonthlyTimesheet === false) return null;
      if(display.toString().includes("false") && tenantMonthlyTimesheet === true) return null;
    }
  }
  
  if (type === "route" && display !== undefined && !display(userRoles, auth)) {
    return null;
  }

  if (
    display.toString().includes("manageAttendance&&manageTimeEntry") &&
    isTimeEntry === false &&
    isAttendance === false
  ) {
    return null;
  }

  const handleAgree = () => {
    sessionStorage.removeItem('unsavedChanges')
    setOpenDialog(false);
    navigate(routeToNavigate);
  };

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <ListItem
        component={type === "collapse" ? "li" : Link}
        to={path}
        sx={(theme) =>
          item(theme, {
            active,
            open,
            miniSidenav: miniSideNav,
            collapse,
          })
        }
        onClick={(e) => {
          if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
            setOpenDialog(true);
            e.preventDefault();
            setRouteToNavigate(path);
          }
          if (type === "collapse") {
            handleCloseList(!closeList);
          }
          if (type !== "collapse") handleCloseList(true);
        }}
      >
        <ListItemButton
          key={name}
          onClick={() => {
            if (type === "collapse") setOpen((val) => !val);
          }}
          sx={(theme) =>
            itemContent(theme, {
              active,
              open,
              name,
              icon,
              miniSidenav: miniSideNav,
            })
          }
        >
          {miniSideNav ? <Tooltip title={t(label)}> <ListItemIcon
            sx={{
              minWidth: miniSideNav ? "0px" : "30px",
              fontSize: "small",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className={`${
              active
                ? "dashboard-active-icon"
                : open
                ? "dashboard-open-icon"
                : ""
            }`}
          >
            {icon}
          </ListItemIcon> </Tooltip>: <ListItemIcon
            sx={{
              minWidth: miniSideNav ? "0px" : "30px",
              fontSize: "small",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className={`${
              active
                ? "dashboard-active-icon"
                : open
                ? "dashboard-open-icon"
                : ""
            }`}
          >
            {icon}
          </ListItemIcon>}
          {!miniSideNav && (
            <ListItemText
              primary={t(label)}
              sx={{ paddingLeft: "5px" }}
              className={`${
                active ? "dashboard-active" : "dashboard-not-active"
              }`}
            />
          )}
          {type !== "collapse" || miniSideNav ? null : open ? (
            <ExpandLessIcon color={active ? dark_logo_blue : pure_white} />
          ) : (
            <ExpandMoreIcon color={active ? dark_logo_blue : pure_white} />
          )}
        </ListItemButton>
      </ListItem>
      {type === "collapse" ? (
        <Collapse
          key={1}
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ padding: "0px 0px 0px 15px", backgroundColor:"transparent" }}
        >
          <List
            key={2}
            list={inline_routes}
            miniSideNav={miniSideNav}
            collapse
          />
        </Collapse>
      ) : null}
    </>
  );
};

export default Items;
