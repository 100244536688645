import { gql } from "@apollo/client";

export const GET_ALL_TASKS_LIST = gql`
  query ($model: GetAllTasksRequestModelInput!) {
    GetFilteredTasks(model: $model) {
      tasksTreeResponse {
        totalNumberOfRecords
        thisTaskViews {
          task {
            guid
            id
            title
            ticketName
            ticketGuid
            projectName
            projectGuid
            priority
            percentageComplete
            parentTaskGuid
            parentName
            actualEndDate
            actualStartDate
            actualTime
            assignedTo
            assignedToGuid
            behindSchedule
            billable
            createdAt
            estimatedEndDate
            estimatedStartDate
            estimatedTime
            externalReference
            actualBillableTime
            actualNonBillableTime
            modifiedAt
            modifiedBy {
              firstName
              lastName
            }
            status {
              description
              taskStatusGuid
              state {
                description
                mnemonic
                taskStateGuid
              }
              statusColor {
                colorCode
                statusColorGuid
              }
            }
            createdBy {
              firstName
              lastName
            }
            area {
              areaGuid
              description
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_TASKS = gql`
  query ($model: GetAllTasksRequestModelInput!) {
    GetTasks(model: $model) {
      tasks {
        id
        percentageComplete
        taskGuid
        title
        description
        priority
        externalReference
        employee {
          employeeGuid
        }
        ticket {
          ticketGuid
          title
          description
          project {
            projectGuid
            title
            description
          }
        }
        billable
        status {
          taskStatusGuid
          description
          taskState {
            mnemonic
          }
          statusColor {
            colorCode
          }
        }
        timeEntries {
          timeEntryGuid
          startTime
          employeeGuid
          open
        }
        area {
          areaGuid
          description
        }
        externalReference
        parentTaskGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_TASKS_WITH_PROJECT = gql`
  query ($model: GetAllTasksRequestModelInput!) {
    GetTasks(model: $model) {
      tasks {
        taskGuid
        title
        billable
        ticket {
          project {
            projectGuid
            title
            projectPermissions {
              resourceGuid
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_PARENT_TASKS = gql`
  query ($model: GetAllTasksRequestModelInput!) {
    GetTasks(model: $model) {
      tasks {
        taskGuid
        title
        ticketGuid
        ticket {
          ticketGuid
          title
          project {
            projectGuid
            title
          }
        }
        area {
          areaGuid
          description
        }
        externalReference
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_TASKS_DROPDOWN = gql`
  query ($model: GetAllTasksRequestModelInput!) {
    GetTasks(model: $model) {
      tasks {
        taskGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_TASKS_PROJECTS = gql`
  query ($id: UUID!) {
    GetAllTasksWithProjects(id: $id) {
      tasksProjects {
        taskGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASKS_BY_TICKET = gql`
  query ($model: GetAllTasksRequestModelInput!) {
    GetTasksByTicket(model: $model) {
      tasksList {
        taskViews {
          task {
            id
            parentTaskGuid
            percentageComplete
            priority
            taskGuid
            title
            priority
            externalReference
            employee {
              employeeGuid
              firstName
              lastName
            }
            ticket {
              ticketGuid
              title
              description
              projectGuid
              project {
                title
                projectGuid
              }
            }
            team {
              description
            }
            createdBy {
              email
            }
            createdAt
            modifiedBy {
              email
            }
            modifiedAt
            estimatedEndDate
            estimatedStartDate
            estimatedTime
            actualEndDate
            actualStartDate
            actualTime
            actualBillableTime
            actualNonBillableTime
            status {
              taskStatusGuid
              description
              taskState {
                mnemonic
              }
              statusColor {
                colorCode
              }
            }
            area {
              areaGuid
              description
            }
          }
          parentTask {
            taskGuid
            title
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASKS_BY_PROJECT = gql`
  query ($model: GetTaskByProjectModelInput!) {
    GetTasksByProject(model: $model) {
      tasks {
        id
        taskGuid
        title
        priority
        description
        employee {
          employeeGuid
          firstName
          lastName
        }
        area {
          areaGuid
          description
        }
        ticket {
          ticketGuid
          title
          description
        }
        estimatedStartDate
        estimatedEndDate
        status {
          taskStatusGuid
          description
          taskState {
            mnemonic
          }
        }
      }

      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASKS_BY_PROJECT_DROPDOWN = gql`
  query ($model: GetTaskByProjectModelInput!) {
    GetTasksByProject(model: $model) {
      tasks {
        taskGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_BY_ID = gql`
  query ($id: UUID!) {
    GetTask(id: $id) {
      taskView {
        task {
          synced
          percentageComplete
          priority
          externalReference
          id
          taskGuid
          title
          description
          employee {
            employeeGuid
            firstName
            lastName
          }
          team {
            teamGuid
            description
          }
          area {
            areaGuid
            description
          }
          estimatedEndDate
          estimatedStartDate
          estimatedTime
          actualEndDate
          actualStartDate
          actualTime
          actualBillableTime
          actualNonBillableTime
          allowCreateTimeEntry
          billable
          modifiedAt
          ticket {
            ticketGuid
            title
            description
            project {
              projectGuid
              title
              company {
                tenant {
                  tenantGuid
                }
              }
              projectPermissions {
                resourceGuid
              }
            }
            area {
              areaGuid
              description
            }
          }
          status {
            taskStatusGuid
            description
            taskState {
              mnemonic
            }
            statusColor {
              colorCode
            }
          }
          taskAttachments {
            taskAttachmentGuid
            displayTitle
            fileName
            uploadedBy {
              firstName
              lastName
            }
            uploadedDate
          }
        }
          estimatedEndDate
          estimatedStartDate
          actualEndDate
          actualStartDate
          parentTask {
          taskGuid
          title
          id
          parentTaskGuid
          employeeGuid
          externalReference
          ticketGuid
          area {
            areaGuid
            description
          }
          ticket {
            project {
              projectPermissions {
                resourceGuid
              }
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_OPEN_TASK = gql`
  query ($model: GetOpenTaskRequestModelInput!) {
    GetOpenTask(model: $model) {
      openTask {
        task {
          taskGuid
          title
          timeEntries {
            timeEntryGuid
            startTime
            employeeGuid
            open
          }
        }
        currentDate
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation ($model: EditTaskRequestInput!) {
    EditTask(model: $model) {
      task {
        taskGuid
        title
        externalReference
        area {
          areaGuid
          description
        }
        ticketGuid
        ticket {
          project {
            projectGuid
            title
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TASK = gql`
  mutation ($model: CreateTaskRequestInput!) {
    CreateTask(model: $model) {
      task {
        taskGuid
        id
        title
        externalReference
        area {
          areaGuid
          description
        }
        ticketGuid
        ticket {
          project {
            projectGuid
            title
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TASKS = gql`
  mutation ($model: DeleteTasksListRequestInput!) {
    DeleteListOfTasks(model: $model) {
      crudTask
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TASK = gql`
  mutation ($id: UUID!) {
    DeleteTask(id: $id) {
      crudTask
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const START_TASK = gql`
  mutation ($model: StartTaskRequestInput!) {
    StartTask(model: $model) {
      startTaskResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_TASKS_SUBSCRIPTION = gql`
  subscription ($model: GetAllTasksRequestModelInput!) {
    allTasksSubscription(model: $model) {
      tasks {
        id
        taskGuid
        title
        ticket {
          ticketGuid
          title
          description
          project {
            projectGuid
            title
            description
          }
        }
        priority
        timeEntries {
          timeEntryGuid
          startTime
          employeeGuid
        }
        status {
          taskStatusGuid
          description
          taskState {
            mnemonic
          }
          statusColor {
            colorCode
          }
        }
        parentTaskGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_OPEN_TASK_SUBSCRIPTION = gql`
  subscription ($model: GetOpenTaskRequestModelInput!) {
    openTaskSubscription(model: $model) {
      openTask {
        task {
          taskGuid
          title
          timeEntries {
            timeEntryGuid
            startTime
            employeeGuid
          }
        }
        currentDate
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const MOVE_TASK = gql`
  mutation ($model: MoveTaskRequestInput!) {
    MoveTask(model: $model) {
      moveTaskResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TASK_QUICK = gql`
  mutation ($model: UpdateTaskQuickRequestInput!) {
    UpdateTaskQuick(model: $model) {
      updateTaskQuick {
        editTaskQuickResponse
        task {
          taskGuid
          title
          externalReference
          area {
            areaGuid
            description
          }
          ticketGuid
          billable
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TASK_ATTACHMENT = gql`
  mutation ($model: DeleteTaskAttachmentRequestInput!) {
    DeleteTaskAttachment(model: $model) {
      crudTask
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_SUBSCRIPTION = gql`
  subscription ($id: UUID!) {
    checkTaskUpdatedSubscription(taskGuid: $id) {
      taskView {
        task {
          modifiedAt
        }
        key
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_BACKLOG_SUBSCRIPTION = gql`
  subscription ($model: GetAllTasksRequestModelInput!) {
    filteredTasksTreeGQL(model: $model) {
      tasksTreeResponse {
        totalNumberOfRecords
        thisTaskViews {
          task {
            guid
            id
            title
            ticketName
            ticketGuid
            projectName
            projectGuid
            priority
            percentageComplete
            parentTaskGuid
            parentName
            actualEndDate
            actualStartDate
            actualTime
            assignedTo
            assignedToGuid
            behindSchedule
            billable
            createdAt
            estimatedEndDate
            estimatedStartDate
            estimatedTime
            externalReference
            modifiedAt
            modifiedBy {
              firstName
              lastName
            }
            status {
              description
              taskStatusGuid
              state {
                description
                mnemonic
                taskStateGuid
              }
              statusColor {
                colorCode
                statusColorGuid
              }
            }
            createdBy {
              firstName
              lastName
            }
            area {
              areaGuid
              description
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_BOARD_DATA = gql`
  query GetTaskStatusesWithTasks(
  $model: GetAllTaskStatusesWithTasksRequestInput!) {
  GetTaskStatusesWithTasks(model: $model) {
    tasksStatusesWithTasksList {
      taskStatuses {
        taskStatusGuid
        description
        tenantGuid
        statusColorGuid
        taskStateGuid
        order
        statusColor {
          statusColorGuid
          colorCode
          description
          colorOrder
        }
        taskState {
          taskStateGuid
          description
        }
        tasks {
          guid
          id
          title
          ticketGuid
          externalReference
          assignedToGuid
          assignedTo
          parentTaskGuid
          priority
          actualTime
          area {
            areaGuid
            description
          }
          estimatedStartDate
          estimatedEndDate
          estimatedTime
          percentageComplete
          behindSchedule
          createdBy {
            firstName
            lastName
          }
          createdAt
          modifiedBy {
            firstName
            lastName
          }
          modifiedAt
          ticketName
          projectName
          actualStartDate
          actualEndDate
          actualBillableTime
          actualNonBillableTime
        }
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;
