import React, { useState } from "react";

//drag and drop component
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//external components
import { Button, Grid, Modal } from "@mui/material";

//components
import IALoadingButton from "../IAButtons/IALoadingButton";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useMutationAuth from "../../hooks/useMutationAuth";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";

//Custom functions
import { Stringify } from "../../JSON.editor";

//graphql
import { REMOVE_USER_TABLE_PREFERENCES, SAVE_USER_TABLE_PREFERENCES } from "../../graphql/userTablePreferences";

import "./style.css";
import ContainerCard from "../Cards/ContainerCard";
import ConfirmationModal from "../Modal/ConfirmationModal";
import CustomButton from "../CustomButton";

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) {
    return;
  }

  const { source, destination } = result;

  const sourceItem = columns[source.index];

  const newArray = [...columns];

  newArray.splice(source.index, 1);
  newArray.splice(destination.index, 0, sourceItem);
  setColumns(newArray);
};

const changeVisibility = (columns, setColumns, oldValue, index) => {
  var newArray = [...columns];

  newArray[index].visible = !oldValue;

  setColumns(newArray);
};

function TableColumnsDnD({
  TableColumns = [],
  SortID = "",
  SortDirection = "asc",
  Table = "",
  handleClose = () => { },
  refetch,
  refetchTableData = () => { },
  ChangeOrder = () => { },
  ChangeOrderBy = () => { },
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { storeUserGuid } = useAuth();

  const { mfunction: removeUserPreferences, loading } = useMutationAuth(
    REMOVE_USER_TABLE_PREFERENCES,
    "RemoveUserTablePreferences"
  );

  const { mfunction: saveUserPreferences, loadind } = useMutationAuth(
    SAVE_USER_TABLE_PREFERENCES,
    "SaveUserTablePreferences"
  );

  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [columns, setColumns] = useState(TableColumns);
  const [defaultSort, setDefaultSort] = useState(SortID);
  const [defaultSortDirection, setDefaultSortDirection] =
    useState(SortDirection);

  const changeDefaultSort = (setData, newVal) => {
    setData(newVal);
    ChangeOrderBy(newVal);
  };

  const changeSortDirection = (event, setData) => {
    setData(event.target.value);
    ChangeOrder(event.target.value);
  };

  const handleReset = () => {
    removeUserPreferences(
      {
        variables: {
          model: {
            columns: "",
            defaultSort: "",
            defaultSortDirection: "",
            tablePreference: Table,
            userID: storeUserGuid,
          },
        },
      },
      (data) => {
        if (data["RemoveUserTablePreferences"].removedTablePreferences === true) {
          refetch();
          refetchTableData();
          enqueueSnackbar(t("columns.resetSuccessfully"), { variant: "success" });
        } else if (data["RemoveUserTablePreferences"].removedTablePreferences === false) {
          enqueueSnackbar(t("columns.notReset"), { variant: "success" });
        }
        setOpenDialog(false);
        handleClose();
      }
    );
  };

  const handleSave = () => {
    const json = { columns, defaultSort, defaultSortDirection };
    const stringified = JSON.stringify(json, Stringify);
    saveUserPreferences(
      {
        variables: {
          model: {
            columns: stringified,
            defaultSort: defaultSort,
            defaultSortDirection: defaultSortDirection,
            tablePreference: Table,
            userID: storeUserGuid,
          },
        },
      },
      (data) => {
        refetch();
        refetchTableData();
        enqueueSnackbar(t("columns.savedSuccessfully"), { variant: "success" });
        handleClose();
      }
    );
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleReset} label={t("dialog.yes")}/>
      <div>
        <CustomButton onClick={() => setOpenDialog(false)} label={t("dialog.cancel")}/>
      </div>
    </>
  );

  return (
    <>
      <Modal
        open={true}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ContainerCard sx={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <h2>{t("columns.title")}</h2>
          </div>
        <div
          style={{ display: "flex", justifyContent: "center", height: "100%" }}
        >
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              <Droppable key={"columns"} droppableId={"columns"}>
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="droppabe-style"
                    style={{ maxHeight: "400px" }}
                  >
                    <Grid
                      container
                      className="table-head"
                      style={{
                        color: "black",
                        backgroundColor: "var(--color-pure-white)",
                      }}
                    >
                      <ConfirmationModal
                        contentText={t("table.edit.columns.confirm.message")}
                        title={t("dialog.warning")}
                        icon={"warning"}
                        openDialog={openDialog}
                        handleClose={() => setOpenDialog(false)}
                        customButtons={customModalButtons}
                        t={t}
                      />
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <p className="columnHeader">{t("columns.label")}</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p className="columnHeader">{t("columns.visibility")}</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p className="columnHeader">{t("columns.sortedByDefault")}</p>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p className="columnHeader">{t("columns.sortDirection")}</p>
                      </Grid>
                    </Grid>
                    {columns.map((item, index) => {
                      if (item.editable != null && item.editable === false) return null;
  
                      if (item.fixedCol) {
                        return (
                          <Grid
                            container
                            key={item.id}
                            className="table-row"
                            style={{
                              backgroundColor: "var(--color-dnd-not-dragging)",
                              color: "black",
                            }}
                          >
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <p>{t(item.label)}</p>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={item.visible}
                                name={"checkbox-visible-" + index}
                                className="checkbox checkbox-primary"
                                onChange={(e) =>
                                  changeVisibility(columns, setColumns, item.visible, index)
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                checked={item.id === defaultSort}
                                name={"radio-defaultSort"}
                                onChange={(e) => changeDefaultSort(setDefaultSort, item.id)}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={3}
                              md={3}
                              lg={3}
                              xl={3}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <select
                                style={{
                                  fontFamily: "poppins",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  visibility: item.id === defaultSort ? "visible" : "hidden",
                                }}
                                name="direction"
                                value={defaultSortDirection}
                                onChange={(e) => changeSortDirection(e, setDefaultSortDirection)}
                              >
                                <option value="asc" style={{ fontFamily: "poppins" }}>
                                  {t("columns.ascending")}
                                </option>
                                <option value="desc" style={{ fontFamily: "poppins" }}>
                                  {t("columns.descending")}
                                </option>
                              </select>
                            </Grid>
                          </Grid>
                        );
                      }
  
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                            <Grid
                              container
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="table-row"
                              style={{
                                backgroundColor: snapshot.isDragging
                                  ? "var(--color-dnd-dragging)"
                                  : "var(--color-dnd-not-dragging)",
                                color: "black",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <p>{t(item.label)}</p>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={item.visible}
                                  name={"checkbox-visible-" + index}
                                  className="checkbox checkbox-primary"
                                  onChange={(e) =>
                                      changeVisibility(
                                        columns,
                                        setColumns,
                                        item.visible,
                                        index
                                      )
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  checked={item.id === defaultSort}
                                  name={"radio-defaultSort"}
                                    onChange={(e) =>
                                      changeDefaultSort(setDefaultSort, item.id)
                                    }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <select
                                  style={{
                                    fontFamily: "poppins",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                      visibility:
                                        item.id === defaultSort
                                          ? "visible"
                                          : "hidden",
                                  }}
                                  name="direction"
                                  value={defaultSortDirection}
                                    onChange={(e) =>
                                      changeSortDirection(
                                        e,
                                        setDefaultSortDirection
                                      )
                                    }
                                >
                                    <option
                                      value="asc"
                                      style={{
                                        fontFamily: "poppins",
                                      }}
                                    >
                                    {t("columns.ascending")}
                                  </option>
                                    <option
                                      value="desc"
                                      style={{
                                        fontFamily: "poppins",
                                      }}
                                    >
                                    {t("columns.descending")}
                                  </option>
                                </select>
                              </Grid>
                            </Grid>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
              </Droppable>
            </DragDropContext>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IALoadingButton
              label={t("table.edit.columns.reset")}
              onClick={() => setOpenDialog(true)}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                padding: "10px 0px 0px 0px",
              }}
            >
              <IALoadingButton
                label={t("table.edit.columns.cancel")}
              onClick={handleClose}
              />
              <IALoadingButton
                label={t("table.edit.columns.save")}
              onClick={handleSave}
              />
            </div>
          </div>
        </ContainerCard>
      </Modal>
    </>
  );
  
}

export default TableColumnsDnD;
