import React from 'react';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { day_month_year } from "../../utils/timezones";

import colors from "../../assets/theme/light/colors";

import './style.css';

const RoundedDatePicker = (props) => {
  const { light_logo_blue } = colors;
  const userTimezone = Intl.DateTimeFormat()
  .resolvedOptions()
  .timeZone.split("/")[0];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        className="date_picker_styles"
        format={
          day_month_year.includes(userTimezone) === true
            ? "dd-MM-yyyy"
            : "MM-dd-yyyy"
        }
        sx={props.dashboard ? {
          fontFamily: "poppins",
          width: "150px",
          "& .MuiInputBase-input, .MuiInputLabel-root": {
            fontSize: "14px !important",
          },
          "& .MuiInputAdornment-root .MuiButtonBase-root": {
            color: light_logo_blue,
            fontSize: "14px !important",
            marginRight: "-5px !important"
          },
          "& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root ":
            {
              width: "20px !important",
            },
          } : {
          width: "100%",
          fontFamily: "poppins",
          "& .MuiInputBase-input, .MuiInputLabel-root": {
            fontSize: "14px !important",
          },
          "& .MuiInputAdornment-root .MuiButtonBase-root": {
            fontSize: "14px !important",
            marginRight: "-5px !important",
          },
          "& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root ": {
            width: "20px !important",
          },
        }}
        slotProps={props.dashboard ? {
          desktopPaper: {
            sx: { boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,}
          },
          textField: {
            size: "small",
            inputProps: {
              style: {
                padding: "5px",
                fontSize: "12px !important",
                color: light_logo_blue,
              },
            },
          },
        } : {
          desktopPaper: {
            sx: { boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,}
          },
          textField: {
            size: "small", 
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default RoundedDatePicker;