import { width } from "@mui/system";
import React from "react";
import colors from "../../assets/theme/light/colors";
import LongText from "../Text/LongText";

const ResponsiveBackgroundStatus = (props) => {

  const { size, row, length, widthAuto } = props;

  if (!props) return null;

  const getColorAndBackground = (mnemonic) => {
    if (
      colors.projectColors[mnemonic] ||
      colors.EmployeeOnBoardingTasks[mnemonic]
    ) {
      return {
        color:
          colors.projectColors[mnemonic]?.color ||
          colors.EmployeeOnBoardingTasks[mnemonic]?.color,
        backgroundColor:
          colors.projectColors[mnemonic]?.backgroundColor ||
          colors.EmployeeOnBoardingTasks[mnemonic]?.backgroundColor,
      };
    } else if (row?.status === null || row?.status?.mnemonic == null) {
      return {
        color:
          colors.projectColors.new.color ||
          colors.EmployeeOnBoardingTasks.new.color,
        backgroundColor:
          colors.projectColors.new?.backgroundColor ||
          colors.EmployeeOnBoardingTasks.new?.backgroundColor,
      };
    }
  };

  const code =
    props?.status?.statusColor?.colorCode ||
    props?.row?.status?.statusColor?.colorCode;

  const colorCode =
    code ||
    getColorAndBackground(props?.row?.status?.mnemonic || props?.mnemonic)
      ?.color;

  const backgroundColor = code
    ? `${code}40`
    : getColorAndBackground(props?.row?.status?.mnemonic || props?.mnemonic)
        ?.backgroundColor;

  return (
    <div
      style={{
        padding: "5px 7px",
        // width: widthAuto ? "fit-content" : "100px",
        maxWidth: width ? width : "initial",
        fontSize: size ? size : "14px",
        borderRadius: "5px",
        fontFamily: "poppins !important",
        margin: "auto",
        color: colorCode,
        backgroundColor: backgroundColor,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}
    >
      {row?.status?.description ||
        props?.status?.description ||
        props?.row?.status?.description ||
        props?.description}
    </div>
  );
};

export default ResponsiveBackgroundStatus;
