import { gql } from "@apollo/client";

export const GET_ALL_TASK_STATUSES = gql`
query($model: GetAllTaskStatusesRequestModelInput!) {
    GetTaskStatuses(model: $model) {
        tasksStatus {
            taskStatusGuid 
            description
            statusColor{
              description
              statusColorGuid
              colorCode
            }
            taskState {
              mnemonic
              taskStateGuid
            }

      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_STATUSES_LIST = gql`
query($model: GetAllTaskStatusesRequestModelInput!) {
  GetAllTaskStatuses(model: $model) {
    tasksStatusList {
      
      taskStatusList {
        statusColor{
          description
          statusColorGuid
          colorCode
        }
          taskStatusGuid 
          description
          state {
            description
            taskStateGuid
            mnemonic
          }
          order
        }
        maxOrder
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_STATUS_BY_ID = gql`
query($id: UUID!) {
  GetTaskStatusByID(id: $id) {
    taskStatus {
      statusColor{
        description
        statusColorGuid
        colorCode
      }
      taskStatusGuid 
      description
      taskState {
          taskStateGuid 
          description
          mnemonic
      }
      tenant {
        tenantGuid
        name
      }
    }
    errorCode
    errorMessage
    errorVariables
    }
  }
`;

export const UPDATE_TASK_STATUS = gql`
  mutation ($model: EditTaskStatusRequestInput!) {
    EditTaskStatus(model: $model) {
      crudTaskStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TASK_STATUSES_LIST = gql`
  mutation ($model: ChangeTaskStatusesOrderModelInput!) {
    ChangeTaskStatusesOrder(model: $model) {
      crudTaskStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TASK_STATUS = gql`
  mutation ($model: CreateTaskStatusRequestInput!) {
    CreateTaskStatus(model: $model) {
      crudTaskStatus
      taskStatus {
        statusColor {
          description
          statusColorGuid
          colorCode
        }
        taskStatusGuid 
        description
        taskState {
            taskStateGuid 
            description
            mnemonic
        }
        tenant {
          tenantGuid
          name
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TASK_STATUSES = gql`
  mutation ($model: DeleteTasksStatusListRequestInput!) {
    DeleteListOfTaskStatus(model: $model) {
      crudTaskStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_TASK_STATUS = gql`
  mutation ($id: UUID!) {
    DeleteTaskStatus(id: $id) {
      crudTaskStatus
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_STATUS_COLOR=gql`
query{
  GetStatusColors {
     statusColors{
      statusColorGuid
      statusColorID
      description
      colorCode
     }
     errorCode
     errorMessage
     errorVariables
  } 
}
`