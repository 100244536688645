export default class RolePermissions {
  constructor(name, values) {
    this.Name = name;
    this.Values = values;
  }

  checkValid(roles){
    return !this.Values.includes(roles[this.Name]);
  }
}
