import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import { useReactToPrint } from "react-to-print";
import { useCallback, useEffect, useRef, useState } from "react";
import { PrintView } from "./PrintView";
import { GET_PROJECT_INVOICE_REPORT } from "../../../../graphql/project";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import { useParams } from "react-router-dom";
import AutoComplete from "../../../../components/AutoComplete";
import { GET_ALL_INVOICES_LIST } from "../../../../graphql/invoices";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import { v4 as uuidv4 } from "uuid";

function PrintableInvoices() {
  const { t } = useTranslation();

  const { pID } = useParams();

  const [data, setData] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [fetchInvoice, setFetchInvoice] = useState(null);
  const [toInvoice, setToInvoice] = useState(false);
  const show = useRef(false);

  const { data: invoicessList, loading: invoiceLoading } = useQueryAuth(
    GET_ALL_INVOICES_LIST,
    "GetProjectInvoicesList",
    {
      variables: {
        model: {
          projectGuid: pID,
          grouped: true
        },
      },
      skip: !fetchInvoice,
      wait: fetchInvoice,
    }
  );

  const invoices =
    invoicessList &&
    invoicessList.GetProjectInvoicesList &&
    invoicessList.GetProjectInvoicesList.projectInvoicesListResponse
      ? invoicessList.GetProjectInvoicesList.projectInvoicesListResponse
          .projectInvoices
      : [];

  const {
    mfunction: getReport,
    loading: getReportLoading,
    tokenChange: reportWait,
    refetch,
  } = useLazyQueryAuth(
    GET_PROJECT_INVOICE_REPORT,
    "GenerateProjectInvoiceReport"
  );

  const fetchReport = useCallback((invoice, toInvoice) => {
    getReport(
      {
        variables: {
          model: {
            projectGuid: pID,
            documentNumber: invoice?.documentNo,
            toInvoice: toInvoice,
          },
        },
      },
      (response) => {
        setData(
          response?.GenerateProjectInvoiceReport?.projectInvoiceReport || {}
        );
        show.current = true;
      }
    );
  });
  
  useEffect(() => {
    fetchReport(invoice, toInvoice);
  }, [toInvoice, invoice]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center">
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{marginRight: "1px !important", fontSize: "14px !important"}}>
              {t("invoice.filter.label")}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{marginRight: "10px !important"}}>
              <AutoComplete
                options={invoices}
                getOptionLabel={(option) =>
                  option ? option.documentNo : ""
                }
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.documentNo}
                  </li>
                )}
                identifier={"projectInvoiceGuid"}
                value={invoice}
                onOpen={() => setFetchInvoice(true)}
                onClose={() => setFetchInvoice(false)}
                onChange={(e, value) => {
                  setInvoice(value);
                }}
                size="small"
                loading={invoiceLoading}
                name={"invoice"}
                label={t("invoice.invoice")}
                roundedTextField
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <FormGroup
                sx={{
                  ".MuiTypography-root": {
                    fontSize: "14px !important",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        ".MuiCheckbox-root": {padding: "9px !important"}
                      }}
                      checked={toInvoice}
                      onChange={(e) => setToInvoice(e.target.checked)}
                    />
                  }
                  label={t("invoice.toInvoice")}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <IALoadingButton
            label={t("invoice.print.label")}
            onClick={handlePrint}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex">
          <ContainerCard
            sx={{
              height: "calc(100vh - 145px)",
              width: "100%",
              marginTop: "10px",
              overflowY: "auto",
            }}
          >
            {getReportLoading ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              show.current === true && (
                <PrintView ref={componentRef} data={data} toInvoiceOnly={toInvoice}/>
              )
            )}
          </ContainerCard>
        </Grid>
      </Grid>
    </>
  );
}

export default PrintableInvoices;
