import React, { useState, useRef } from "react";

//external components
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from "react-i18next";

//icons
import { ArrowRightIcon, ArrowLeftIcon, DashboardRefreshIcon } from "../../../components/Icon";

import colors from "../../../assets/theme/light/colors";

import { GET_ALL_TENANTS } from "../../../graphql/tenant";

import useQueryAuth from "../../../hooks/useQueryAuth";
import DailyDayLogUpdated from "../DailyDayLogUpdated";

import Tooltip from "../../../components/Tooltip";
import RoundedDatePicker from "../../../components/DateField/RoundedDatePicker";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0, pb: 0.5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function DashboardTabRoutes({
  value,
  isEntelligence = false,
  side,
}) {
  const [tenant, setTenant] = useState(null);
  //holds shared states
  const [date, setDate] = useState(new Date());

  const { t } = useTranslation();

  const { icon_button_grey, light_logo_blue } = colors;

  const matches = useMediaQuery('(max-width:500px)');

  const childRef1 = useRef();
  const childRef2 = useRef();
  const childRef3 = useRef();
  const childRef4 = useRef();
  const childTemp = useRef();

  const [received, setReceived] = useState(false);

  const { data: tenantData, tokenChange: wait } = useQueryAuth(
    GET_ALL_TENANTS,
    "GetAllTenants",
    {
      variables: {
        model: {
          enableFilters: false,
        },
      },
      skip: !isEntelligence,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetAllTenants?.tenantsList?.tenants || [];
        setTenant(data.length > 0 ? data[0] : null);
        setReceived(true);
      },
    }
  );

  const tenantList = tenantData?.GetAllTenants?.tenantsList?.tenants || [];

  function handleRefetch() {
    switch (value) {
      case 0:
        if (childRef1 && childRef1.current) {
          childRef1.current.click();
        }
        break;
      case 1:
        if (childRef2 && childRef2.current) {
          childRef2.current.click();
        }
        break;
      case 2:
        if (childRef3 && childRef3.current) {
          childRef3.current.click();
        }
        break;
      case 3:
        if (childRef4 && childRef4.current) {
          childRef4.current.click();
        }
        break;
      default:
        break;
    }
  }

  function changeDate(difference) {
    if (childTemp && childTemp.current) {
      childTemp.current.click();
    }
    let newDate = new Date(date);

    newDate.setDate(newDate.getDate() + difference);

    if (newDate > new Date()) return null;

    setDate(newDate);
  }
  
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexDirection: "row",
          position: "relative",
          marginTop: matches ? "40px" : 0,
        }}
      >
        <span style={{ position: "absolute", top: "-40px", display: "flex" }}>
          {value !== 2 && value !== 0 && (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "5px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  border: "1px solid #E6E6E6",
                  borderRadius: "50px",
                  padding: "5px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                onClick={() => changeDate(-1)}
              >
                <ArrowLeftIcon color={icon_button_grey} />
              </div>
                <RoundedDatePicker
                  name={"date"}
                  value={date}
                  onChange={(newValue) => {
                    //  resetFields();
                    if (childTemp && childTemp.current) {
                      childTemp.current.click();
                    }
                    setDate(newValue);
                  }}
                  dashboard
                  disableFuture
                  // renderInput={(params) => (
                  //   <RoundedTextField size="small" {...params} />
                  // )}
                />
              <div
                style={{
                  display: "flex",
                  border: "1px solid #E6E6E6",
                  borderRadius: "50px",
                  padding: "5px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => changeDate(1)}
              >
                <ArrowRightIcon color={icon_button_grey} />
              </div>
            </span>
          )}
          <Tooltip title={t("table.RefreshBtn")}>
            <div
              style={{ marginRight: "8px", marginLeft: "3px", cursor: "pointer", display: "flex", "&:hover": {backgroundColor: "transparent !important"} }}
              onClick={() => {
                handleRefetch();
              }}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-reload"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="#2c3e50"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                <path d="M20 4v5h-5" />
              </svg> */}
              <DashboardRefreshIcon />
            </div>
          </Tooltip>
        </span>
      </div>
      <div>
        <TabPanel value={value} index={3}>
          <DailyDayLogUpdated
            {...{
              tenant,
              setTenant,
              tenantList,
              wait: (tenant === null && !received && isEntelligence) || wait,
              childRef: childRef4,
              date,
            }}
            isEntelligence={isEntelligence}
          />
        </TabPanel>
      </div>
    </>
  );
}
