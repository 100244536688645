import { createSlice } from "@reduxjs/toolkit";

const initialState = { timer: 0 };

const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    changeTimer: (state, { payload }) => {
      let { timer } = state;
      timer = { timer: payload };
      return timer;
    },
  },
});

export const { changeTimer } = timerSlice.actions;

export const getTimerFromStore = (state) => state.timer.timer;
export default timerSlice.reducer;
