import { CircularProgress } from "@mui/material";
import Tooltip from "../../../components/Tooltip";
import  "./style.css"
export const getData = (
  t,
  taskID,
  setTaskID,
  setTaskName,
  setProjectID,
  setProjectName,
  setOpenModal,
  startTaskFunction,
  handleClickOpen,
  Link,
  PendingActionsIcon,
  my_project_direct_tasks_form,
  storeUserGuid,
  componentDisplay,
  handleCreateTimeEntry,
  loading,
  timeEntryEnabled,
  refetchOpenTask,
  setType,
) => {
  
  return {
    title: (
      <span className="span-icon-title" style={{ color: "#EF7323" }}>
        <PendingActionsIcon
          className="head-icon-table"
          fontSize="medium"
          style={{ marginLeft: "0.3rem" }}
        />
        {t("dashboard.myTasks")}
      </span>
    ),

    headers: [],
    render: [
      (row) => {
        return <p style={{ marginTop: "0" }}>{row?.id}</p>;
      },
      (row) => {
        return (
          <Link
            to={my_project_direct_tasks_form
              .replace(
                "/:pName/:pID",
                `/${encodeURIComponent(
                  row?.ticket?.project?.title
                )}/${encodeURIComponent(row?.ticket?.project?.projectGuid)}`
              )
              .replace(
                "/:tskName/:tskID",
                `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
                  row?.taskGuid
                )}`
              )}
            className="task-link"
            style={{ marginTop: "0.3rem" }}
            onClick={(e) => {
              setTaskID(row?.taskGuid);
              setTaskName(row?.title);
              setProjectID(row?.ticket?.project?.projectGuid);
              setProjectName(row?.ticket?.project?.title);
              setType(row?.parentTaskGuid ? "subtask" : "task")
              setOpenModal(true);
              e.preventDefault();
            }}
          >
            {row?.title}
          </Link>
        );
      },
      (row) => {
        return <p style={{ marginTop: "0" }}>{row?.ticket?.project?.title}</p>;
      },
      (row) => {
        return componentDisplay({ compName: "BackgroundStatus", compProps: { row, size: 12, length: 10 } });
      },
      (row) => {
        return componentDisplay({ compName: "FlagBadge", compProps: row });
      },
      (row) => {
        return (
          <div className="actionBtns">
            {row?.timeEntries?.some(x => x.employeeGuid === storeUserGuid) ? (
              <div
                style={{
                  border: "1px solid rgba(244, 67, 53, 0.8)",
                  color: "rgba(244, 67, 53, 1)",
                  fontFamily: "poppins  !important",
                  borderRadius: "5px",
                  padding: "5px",
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "center"
                }}
                onClick={() => handleClickOpen(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-player-pause-filled"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="#F44335"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M9 4h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z"
                    strokeWidth="0"
                    fill="currentColor"
                  />
                  <path
                    d="M17 4h-2a2 2 0 0 0 -2 2v12 a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z"
                    strokeWidth="0"
                    fill="currentColor"
                  />
                </svg>
              </div>
            ) : (
              loading === true && taskID === row?.taskGuid ? <div
              style={{
                border: "1px solid rgba(63, 181, 74, 0.8)",
                color: "rgba(62, 186, 18)",
                fontFamily: "poppins  !important",
                borderRadius: "5px",
                padding: "5px",
                textAlign: "right",
                display: "flex",
                justifyContent: "center"
              }}
              onClick={() => handleClickOpen(row)}
            >
              <CircularProgress color="inherit" size={18} />
            </div>
            :
            <span
                style={{
                  border: timeEntryEnabled === false ? "1px solid rgba(155, 155, 155, 0.8)" : "1px solid rgb(63, 181, 74, 0.8)",
                  color: timeEntryEnabled === false ? "rgba(155, 155, 155, 1)" : "rgb(62, 186, 18)",
                  fontFamily: "poppins  !important",
                  borderRadius: "5px",
                  padding: "5px",
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "center",
                  cursor: timeEntryEnabled === false ? "default" : "pointer"
                }}
                onClick={() =>{ if(timeEntryEnabled) startTaskFunction(row?.taskGuid, storeUserGuid)}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-player-play-filled"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="var(--color--green-color)"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z"
                    strokeWidth="0"
                    fill="currentColor"
                  />
                </svg>
              </span>
            )}
          </div>
        );
      },
      (row) => {
        return (
          <Tooltip title={t("dashboard.task.addTimeEntry")}>
            <div style={{cursor: 'pointer', paddingRight:"10px"}} onClick={() => handleCreateTimeEntry(row)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-pencil-plus"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" />
                <path d="M13.5 6.5l4 4" />
                <path d="M16 19h6" />
                <path d="M19 16v6" />
              </svg>
            </div>
          </Tooltip>
        );
      },
    ],
  };
};
