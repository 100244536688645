import { useReducer } from "react";

const initialValues = {
  value: {},
  errors: {},
  touched: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "touch":
    case "Touch":
    case "touched":
    case "Touched":
      var tmp = { ...state };

      tmp = {
        ...state,
        touched: {
          ...state.touched,
          [action.payload.name]: true,
        },
      };
      return tmp;

    case "email":
    case "Email":
      var tmp = { ...state };
      if (
        action.payload.value === "" ||
        action.payload.value === null ||
        action.payload.value === undefined
      ) {
        tmp = {
          ...state,
          errors: {
            ...state.errors,
            [action.payload.name]: "email.isRequired",
          },
          value: {
            ...state.value,
            [action.payload.name]: action.payload.value,
          },
        };
      } else {
        delete tmp["errors"][action.payload.name];
        tmp = {
          ...state,
          value: {
            ...state.value,
            [action.payload.name]: action.payload.value,
          },
        };
      }

      return tmp;

    case "password":
    case "Password":
      var tmp = { ...state };
      if (
        action.payload.value === "" ||
        action.payload.value === null ||
        action.payload.value === undefined
      ) {
        tmp = {
          ...state,
          errors: {
            ...state.errors,
            [action.payload.name]: "password.isRequired",
          },

          value: {
            ...state.value,
            [action.payload.name]: action.payload.value,
          },
        };
      } else {
        delete tmp["errors"][action.payload.name];

        tmp = {
          ...state,
          value: {
            ...state.value,
            [action.payload.name]: action.payload.value,
          },
        };
      }

      return tmp;

    default:
      return state;
  }
};

const useForms = () => {
  const [state, dispatch] = useReducer(reducer, initialValues);

  return {
    values: state.value,
    errors: state.errors,
    touched: state.touched,
    setValues: (type, name, value) =>
      dispatch({ type, payload: { name, value } }),
    setTouched: (name) => dispatch({ type: "touched", payload: { name } }),
  };
};

export default useForms;
