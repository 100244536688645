const form = {
  formID: "task",
  formField: {
    employee: {
      name: "employee",
      label: "timeEntryForm.employee.label",
      requiredMessage: "timeEntryForm.employee.requiredMessage",
    },
    task: {
      name: "task",
      label: "timeEntryForm.task.label",
      requiredMessage: "timeEntryForm.task.requiredMessage",
    },
    project: {
      name: "project",
      label: "timeEntryForm.project.label",
      requiredMessage: "timeEntryForm.project.requiredMessage",
    },
    date: {
      name: "date",
      label: "timeEntryForm.date.label",
      requiredMessage: "timeEntryForm.date.requiredMessage",
    },
    duration: {
      name: "duration",
      label: "timeEntryForm.duration.label",
      requiredMessage: "timeEntryForm.duration.requiredMessage",
    },
    minutes: {
      name: "minutes",
      label: "timeEntryForm.minutes.label",
      requiredMessage: "timeEntryForm.minutes.requiredMessage",
    },
    hours: {
      name: "hours",
      label: "timeEntryForm.hours.label",
      requiredMessage: "timeEntryForm.hours.requiredMessage",
    },
    description: {
      name: "description",
      label: "timeEntryForm.description.label",
      requiredMessage: "timeEntryForm.description.requiredMessage",
    },
    billable: {
      name: "billable",
      label: "timeEntryForm.billable.label",
      requiredMessage: "timeEntryForm.billable.requiredMessage",
    },
  },
};

export default form;