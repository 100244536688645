import checkout from "./form";

const {
  formField: {
    type,
    description,
  emailBody,
  emailTitle,
  attachment,
  status
  },
} = checkout;

const initialValues = {
  [type.name]:null,
  [description.name]:"",
  [emailBody.name]:"",
  [emailTitle.name]:"",
  [attachment.name]: "",
  [status.name]:null,


};

export default initialValues;