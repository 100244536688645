import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function EmployeeReferencesTabs({ fromTenant = false }) {
  const { eName, eID, tName, tID, rID } = useParams();

  const { employee_reference_form, my_employee_reference_form } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !eName ||
    !eID ||
    eName === "undefined" ||
    eName === "null" ||
    eName === "" ||
    eID === "null" ||
    eID === "undefined" ||
    eID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(eName);
  const encodedID = encodeURIComponent(eID);

  const TabLinks = [
    {
      key: 1,
      Header: t("employeeTabs.form"),
      Link: fromTenant ? 
      employee_reference_form
      .replace("/:tName/:tID",`/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
      .replace("/:eName/:eID", `/${encodedName}/${encodedID}`) 
      .replace("/:rID", `/${encodeURIComponent(rID)}`)
      : my_employee_reference_form
      .replace("/:eName/:eID", `/${encodedName}/${encodedID}`)
      .replace("/:rID", `/${encodeURIComponent(rID)}`),
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default EmployeeReferencesTabs;
