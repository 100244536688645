import { Route } from "react-router-dom";

const Routing = (route) => {
  if (route.type === "route") {
    return <Route key={route.name} {...route} />;
  }

  if (route.type === "collapse") {
    return (
      route.inline_routes &&
      route.inline_routes.map((val, index) => Routing(val))
    );
  }

  if (route.type === "authentication") {
    return (
      <Route key={route.name} {...route}>
        {route.routes_inside &&
          route.routes_inside.map((val, index) => Routing(val))}
      </Route>
    );
  }

  if (route.type === "nested") {
    return (
      <Route key={route.name} {...route}>
        {route.routes_inside &&
          route.routes_inside.map((val, index) => Routing(val))}
      </Route>
    );
  }
};

export default Routing;
