import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYMENT_STATUS = gql`
  query {
    GetAllEmployeeStatuses {
        employeeStatusesOutput {
            employeeStatusGuid
            description
            mnemonic
        }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
