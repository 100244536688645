import React from 'react';
import * as XLSX from 'xlsx';
import IALoadingButton from '../components/IAButtons/IALoadingButton';

class FileInput extends React.Component {
  handleFile = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 ,raw: false, dateNF: 'yyyy-mm-dd'});
const resourceGuid=jsonData[0][1];
      if (jsonData.length > 1) {
        const headerRow = jsonData[1];  // Extract the second row as the header
        const dataRows = jsonData.slice(2);  // Extract data starting from the third row

        // Filter out rows where the project name (assuming it's in the first column) is null or empty
        const filteredDataRows = dataRows.filter(row => {
          
          return row[0] !== null && row[0] !== "" && row[0]!=undefined;
        });

        this.props.onFileLoaded( filteredDataRows,resourceGuid);
      } else {
        console.error('The file does not contain enough rows for headers and data.');
      }
    };

    reader.readAsArrayBuffer(file);
  };

  render() {
    return (
      <>
         {/* <IALoadingButton
                onClick={this.handleFile}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                }}
                label={t("task.list.exportToExcel")}
              /> */}

        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={this.handleFile}
          style={{ display: 'none' }}
          id="fileInput"
        />
        <label
          htmlFor="fileInput"
          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        >
          Click to select a file
        </label>
      </>
    );
  }
}

export default FileInput;


