import React from "react";

//external components
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProjectForm from "../../Projects-form";
import ProjectPlan from "../../Project-plan";
import ProjectTree from "../../Project-tree";
import TicketsList from "../../../Tickets/Tickets-list";
import TasksList from "../../../Tasks/Tasks-list";
import ProjectPermission from "../../Projects-list/ProjectPermissions";
import Areas from "../../../Areas";
import InvoicesList from "../../Project-invoices";

//components

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0.5, pb: 0.5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ProjectsTabRoutes({ value, fromTenant , myTenant, fromCompany, fromFavorites}) {
  return (
    <>
      <TabPanel value={value} index={0}>
       <ProjectForm fromTenant={fromTenant} myTenant={myTenant} fromCompany={fromCompany} fromFavorites={fromFavorites}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
       <ProjectPlan fromTenant={fromTenant} myTenant={myTenant} fromProject={true}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
       <ProjectTree fromTenant={fromTenant} myTenant={myTenant} fromProject={true} fromTree={true}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
       <ProjectPermission fromTenant={fromTenant} myTenant={myTenant} fromCompany={fromCompany}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
       <TasksList fromTenant={fromTenant} myTenant={myTenant} fromCompany={fromCompany} fromProject={true} fromBacklog={true} fromProjectTasks={true}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
       <Areas fromTenant={fromTenant} myTenant={myTenant} fromCompany={fromCompany} fromProject={true} fromBacklog={true} />
      </TabPanel>
      <TabPanel value={value} index={6}>
       <InvoicesList fromTenant={fromTenant} myTenant={myTenant} fromCompany={fromCompany} fromProject={true} fromFavorites={fromFavorites} fromBacklog={true} />
      </TabPanel>
    </>
  );
}
