import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

//routing
import { BrowserRouter } from "react-router-dom";

//graphql
import { ApolloProvider } from "@apollo/client";

// gql client
import client from "./configurations/gql-configurations";

// Snackbar Wrapper component
import { SnackbarProvider } from "notistack";

// redux components
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/store";

//for cookies
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
          <BrowserRouter>
            <Suspense fallback={null}>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={1500}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <App />
              </SnackbarProvider>
            </Suspense>
          </BrowserRouter>
        </CookiesProvider>
      </PersistGate>
    </ApolloProvider>
  </Provider>
);
