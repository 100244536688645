import React from "react";

import Tooltip from "../Tooltip";
import { useTranslation } from "react-i18next";
import { NeedsReviewIcon } from "../Icon";

const MarkBadge = ({ success = false, height = null, viewBox = null }) => {
  const { t } = useTranslation();
  if (success)
    return (
      <NeedsReviewIcon color="#ff2825" height={height} viewBox={viewBox} title={t("needs.review")} Tooltip={Tooltip} />
    );
  return <></>;
};

export default MarkBadge;
