//This file contains the help regarding the fields displayed in the forms

const help = new Map();

/**Tenant Form */
help.set("Tenant-Form-name-title", "tenant.name.title");
help.set("Tenant-Form-name-text", "tenant.name.text");
help.set(
  "Tenant-Form-numberFullLicense-title",
  "tenant.numberFullLicense.title"
);
help.set("Tenant-Form-numberFullLicense-text", "tenant.numberFullLicense.text");
help.set(
  "Tenant-Form-numberSelfService-title",
  "tenant.numberSelfService.title"
);
help.set("Tenant-Form-numberSelfService-text", "tenant.numberSelfService.text");
help.set("Tenant-Form-projectRadius-title", "tenant.projectRadius.title");
help.set("Tenant-Form-projectRadius-text", "tenant.projectRadius.text");
help.set("Profile-Pleasefollowthisguideforastrongpassword","changePassword.Pleasefollowthisguideforastrongpassword");


help.set("Role-Form-name-title", "role.name.title");
help.set("Role-Form-name-text", "role.name.text");
help.set("Role-Form-description-title", "role.description.title");
help.set("Role-Form-description-text", "role.description.text");
help.set("Role-Form-manageSystemConfigurations-title", "role.manageSystemConfigurations.title");
help.set("Role-Form-manageSystemConfigurations-text", "role.manageSystemConfigurations.text");
help.set("Role-Form-manageUsers-title", "role.manageUsers.title");
help.set("Role-Form-manageUsers-text", "role.manageUsers.text");
help.set("Role-Form-manageAttendance-title", "role.manageAttendance.title");
help.set("Role-Form-manageAttendance-text", "role.manageAttendance.text");
help.set("Role-Form-manageTimeEntry-title", "role.manageTimeEntry.title");
help.set("Role-Form-manageTimeEntry-text", "role.manageTimeEntry.text");
help.set("Role-Form-manageParameters-title", "role.manageParameters.title");
help.set("Role-Form-manageParameters-text", "role.manageParameters.text");

export function _help(code) {
  var result = "";
  if (help.has(code)) {
    result = help.get(code);
  }
  return result;
}
