import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

import { DELETE_TEAMS_LIST, GET_ALL_TEAMS } from "../../../../graphql/team";

//editors
import { getTable } from "./_table";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";

const EmployeeTeamsList = ({
  fromTenant = false,
  fromEmployee = false,
  handleClick = () => {},
  initialVal = [],
}) => {
  const { tID, tName, eID } = useParams();

  const {
    tenant_teams_form,
    new_tenant_team,
    my_teams_form,
    new_my_team,
  } = urls;

  const { storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isEntelligence } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState(fromEmployee ? initialVal : []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TEAMS_LIST,
    "DeleteTeams"
  );

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable(true);
      
      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const { mfunction: getAllTeams, loading } = useLazyQueryAuth(
    GET_ALL_TEAMS,
    "GetTeamsList"
  );

  const fetchTableData = useCallback(
    (page = 0, pageSize = 0, order = "", orderBy = "", search = "") => {
      getAllTeams(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyword: search,
              tenantGuid: isEntelligence ? tID : storeUserTenant,
              employeeID: eID,
              unassignedTeams: true
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    fetchTableData(page, rowsPerPage, order, orderBy, search);
  }, [columns.length, fetchTableData, re]);

  const rows =
    data &&
    data.GetTeamsList &&
    data.GetTeamsList.teamsList &&
    data.GetTeamsList.teamsList.teams
      ? data.GetTeamsList.teamsList.teams
      : [];
  const numberOfRecords =
    data &&
    data.GetTeamsList &&
    data.GetTeamsList.teamsList &&
    data.GetTeamsList.teamsList.totalNumberOfRecords
      ? data.GetTeamsList.teamsList.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            teams: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("teams.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const customButtons = [
    {
      label: "employeeForm.addButton",
      handleClick: () => handleClick(selected),
      condition: selected && selected.length > 0 && fromEmployee && manageUsers === "M",
    }
  ];
  
  const pagination = {
    rows,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    removeFilters: true,
    loading: loading,
    searchText: "team.list.search.label",
    tableMnemonic: Mnemonic.Tables.TeamsList,
    ...(!fromEmployee && {
      generateRoute: (row) =>
        fromTenant
          ? tenant_teams_form
              .replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
              .replace(
                "/:ptoName/:ptoID",
                `/${encodeURIComponent(row?.description)}/${encodeURIComponent(
                  row?.ptoGuid
                )}`
              )
          : my_teams_form.replace(
              "/:ptoName/:ptoID",
              `/${encodeURIComponent(row?.description)}/${encodeURIComponent(
                row?.ptoGuid
              )}`
            ),
      newText: "ptos.list.new.label",
      newLink: fromTenant
        ? new_tenant_team.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
        : new_my_team,
      deleteText: "teams.list.delete.label",
      handleDelete: handleDelete,
      deleteLoading,
    }),
    hideEditColumns: true,
    readOnly: !fromEmployee && manageUsers !== "M",
    customButtons,
  };

  return (
    <div>
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"teamGuid"}
          hasCheckbox
          fullObject={fromEmployee}
        />
      )}
    </div>
  );
};

export default EmployeeTeamsList;
