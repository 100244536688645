export const UPDATE_VALUE = "UPDATE_VALUE"
export const UPDATE_LIST = "UPDATE_LIST"
export const CLEAR_FILTERS = "CLEAR_FILTERS"
export const UPDATE_DATE = "UPDATE_DATE"
export const UPDATE_OBJECT = "UPDATE_OBJECT"
export const UPDATE_LIST_VALUES = "UPDATE_LIST_VALUES";
export const UPDATE_LIST_OBJECT_OF_OBJECT="UPDATE_LIST_OBJECT_OF_OBJECT";
export const COPY_ITEMS = "COPY_ITEMS";
export const UPDATE_DISABLED = "UPDATE_DISABLED";
export const CLEAR_VALUE = "CLEAR_VALUE";
