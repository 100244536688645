import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import axios from "axios";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import { GET_ONBOARDING_TASK_BY_ID } from "../../../../graphql/onBoardingTasks";
import { LOGOUT_USER } from "../../../../graphql/user";
import { parseJwt } from "../../../../utils/parseJwt";
import apiCall from "../../../../hooks/useRest";
import hasEmptyTags from "../../../../utils/hasEmptyTags";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

const OnBoardingTaskForm = ({
  fromModal = false,
  handleCloseModal,
  resetFields = false,
}) => {
  const { obName, obID } = useParams();

  const createMode = !obName && !obID;

  const { userRoles, storeUserTenant, jwtToken, logout } =
    useAuth();

  const dispatch = useDispatch();

  const { manageParameters: manageParametersRole } = userRoles;

  const { onBoarding_tasks } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const { type, description, attachment, emailBody, emailTitle } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const [whatField, setWhatField] = useState(null);

  const [isNew, setIsNew] = useState(false);

  const [attachmentName, setAttachmentName] = useState("");

  const [unTouchedForm, setUnTouchedForm] = useState(true);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  const backendURL = process.env.REACT_APP_REST_URL;

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_ONBOARDING_TASK_BY_ID,
    "GetOnBoardingTaskByID",
    {
      variables: { id: obID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetOnBoardingTaskByID?.onBoardingTaskByID?.onBoardingTask || {};
        setFormData({
          [type.name]: data?.onBoardingType || null,
          [description.name]: data.description || "",
          [attachment.name]: data?.attachment || null,
          [emailBody.name]: data?.emailBody || "",
          [emailTitle.name]: data?.emailTitle || "",
        });
        setAttachmentName(response?.GetOnBoardingTaskByID?.onBoardingTaskByID?.attachmentName);
      },
    }
  );

  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
  }  

  async function handleCreateOnBoardingTask(values, actions) {
    var htmlString = values[emailBody.name];
    let updatedEmailBody = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if(hasEmptyTags(updatedEmailBody)) updatedEmailBody = "";
    var formData = new FormData();
    formData.append("TenantGuid", storeUserTenant);
    formData.append("Description", values[description.name]);
    if (values[emailBody.name])
      formData.append("EmailBody",updatedEmailBody);
    if (values[emailTitle.name])
      formData.append("EmailTitle", values[emailTitle.name]);
    formData.append("OnBordingTypeGuid", values[type.name]?.onBoardingTypeGuid);
    if (values[attachment.name])
      formData.append("Attachment", values[attachment.name]);
    if (values[attachment.name])
      formData.append(
        "IsImage",
        values[attachment.name]?.includes(".jpg") ||
        values[attachment.name]?.includes(".jpeg") ||
        values[attachment.name]?.includes(".png")
      );

    const res = await apiCall(
      backendURL + `/OnBoardingTask/CreateOnBoardingTaskAsync`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      actions.setSubmitting(false);
      sessionStorage.removeItem("unsavedChanges");
      navigate(onBoarding_tasks);
    }
  }

  async function handleEditOnBoardingTask(values, actions) {
    var htmlString = values[emailBody.name];
    let updatedEmailBody = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if(hasEmptyTags(updatedEmailBody)) updatedEmailBody = "";
    var formData = new FormData();
    formData.append("TenantGuid", storeUserTenant);
    formData.append("OnBoardingTaskGuid", obID);
    formData.append("Description", values[description.name]);
    if (values[emailBody.name])
      formData.append("EmailBody",updatedEmailBody);
    if (values[emailTitle.name])
      formData.append("EmailTitle", values[emailTitle.name]);
    formData.append("OnBordingTypeGuid", values[type.name]?.onBoardingTypeGuid);
    if (values[attachment.name])
      formData.append("Attachment", values[attachment.name]);
    if (values[attachment.name])
      formData.append(
        "IsImage",
        values[attachment.name].type
          ? values[attachment.name]?.type?.includes("image")
          : values[attachment.name]?.includes(".jpg") ||
              values[attachment.name]?.includes(".jpeg") ||
              values[attachment.name]?.includes(".png")
      );
    formData.append("IsNew", isNew);

    const res = await apiCall(
      backendURL + `/OnBoardingTask/UpdateOnBoardingTaskAsync`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      actions.setSubmitting(false);
      sessionStorage.removeItem("unsavedChanges");
      navigate(onBoarding_tasks);
    }
  }

  useEffect(() => {
    if (
      performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
      unTouchedForm &&
      !fromModal
    ) {
      sessionStorage.clear();
    }
  }, []);

  const handleSubmit = (values, actions) => {
    if (createMode) {
      handleCreateOnBoardingTask(values, actions);
    } else {
      handleEditOnBoardingTask(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                      <Form
                        id={form.formID}
                        autoComplete="off"
                        onChange={() => {
                          if (touched) {
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            setUnTouchedForm(false);
                          }
                        }}
                      >
                        <Box p={2}>
                          <Box>
                            <Main
                              formData={{
                                values,
                                touched,
                                formField: form.formField,
                                errors,
                                setFieldValue,
                                setTouched,
                                createMode,
                                whatField,
                                setWhatField,
                                readOnly: manageParametersRole === "R",
                              }}
                              attachmentName={attachmentName}
                              setAttachmentName={setAttachmentName}
                              setIsNew={setIsNew}
                            />
                          </Box>
                          <Box
                            mt={4}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              disabled={
                                isSubmitting || manageParametersRole !== "M"
                              }
                              loading={isSubmitting}
                              type="submit"
                              label={t("roleForm.save")}
                            />
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default OnBoardingTaskForm;
