import React, { useState, useCallback, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

//component
import GridTable from "../../../../components/Tables/GridTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  CREATE_LOANED_ITEM,
  DELETE_LOANED_ITEM,
  GET_ALL_LOANED_ITEMS_LIST,
  SEND_EMAIL_TO_EMPLOYEE,
  UPDATE_LOANED_ITEM,
} from "../../../../graphql/loanedItems";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { EmailIcon } from "../../../../components/Icon";
import { useSelector } from "react-redux";
import { getEmployeeFromStore } from "../../../../app/reducers/employeeSlice";

function LoanedItemsList(fromProfile) {
  const { tID, eID, profileID } = useParams();
  const myProfile = fromProfile?.fromProfile;
  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const employee = useSelector(getEmployeeFromStore);

  const DEFAULT_LOANED_ITEM = {
    loanedItemGuid: "",
    description: "",
    assignDate: new Date(),
    returnDate: null,
    employeeGuid: eID,
  };
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [selectedEmployeeGuid, setSelectedEmployeeGuid] = useState(eID);
  const [filterState, setFilterState] = useState([]);
  const [changedEmployee, setChangedEmployee] = useState(false);

  const [defaultFilters, setDefaultFilters] = useState(
    filterState && filterState.length > 0 ? filterState : {}
  );
  const [re, setRe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newObj, setNewObj] = useState({
    loanedItemGuid: "",
    description: "",
    assignDate: new Date(),
    returnDate: null,
    employeeGuid: eID,
  });
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [columnAdded, setColumnAdded] = useState(false);
  const editMode = React.useRef(false);
  const clickedRow = React.useRef(null);
  const clickedRowGuid = React.useRef("");
  const [rowsUpdated, setRowsUpdated] = useState(false);
  const [hideActions, setHideActions] = useState(false);

  const requiredFields = [
    "loanedItemGuid",
    "description",
    "assignDate",
    "employeeGuid",
  ];

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setNewObj({ ...newObj, employeeGuid: selectedEmployeeGuid });
  }, [selectedEmployeeGuid]);

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        showAll: true,
      },
    },
  });
  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];


  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_LOANED_ITEM,
    "DeleteLoanedItems"
  );
  const { mfunction: sendEmail, loading: emailLoading } = useMutationAuth(
    SEND_EMAIL_TO_EMPLOYEE,
    "SendAssignedItemEmail"
  );

  const {
    mfunction: getAllLoanedItemsList,
    loading,
    refetch,
  } = useLazyQueryAuth(GET_ALL_LOANED_ITEMS_LIST, "GetAllLoanedItemsList");

  useEffect(() => {
    if (myProfile === true) {
      setHideActions(true);
    }
  }, [myProfile]);

  const [columns, setColumns] = useState([
    {
      field: "description",
      align: "left",
      headerName: "loanedItems.list.column.Description",
      label: "loanedItems.list.column.Description",
      flex: 1,
      editable: true,
      renderCell: (params) =>
        componentDisplay({
          compName: "LongText",
          compProps: params.value,
          length: 60,
        }),
    },
    {
      field: "assignDate",
      align: "left",
      type: "date",
      headerName: "loanedItems.list.column.assignDate",
      label: "loanedItems.list.column.assignDate",
      flex: 0.3,
      editable: true,
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : new Date();
      },
    },
    {
      field: "returnDate",
      align: "left",
      type: "date",
      headerName: "loanedItems.list.column.returnDate",
      label: "loanedItems.list.column.returnDate",
      flex: 0.3,
      editable: true,
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : null;
      },
    },
  ]);

  const fetchTableData = useCallback(
    (page = 0, pageSize = 0, search = "", filters = null) => {
      getAllLoanedItemsList(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyword: search,
              userID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
          setRows(
            response?.GetAllLoanedItemsList?.getAllLoanedItemsList
              ?.loanedItemsList
          );
          setRowsUpdated(!rowsUpdated);
        }
      );
    },
    []
  );

  const records =
    data &&
    data.GetAllLoanedItemsList &&
    data.GetAllLoanedItemsList.getAllLoanedItemsList &&
    data.GetAllLoanedItemsList.getAllLoanedItemsList.loanedItemsList
      ? data.GetAllLoanedItemsList.getAllLoanedItemsList.loanedItemsList
      : [];
  const numberRecords =
    data &&
    data.GetAllLoanedItemsList &&
    data.GetAllLoanedItemsList.getAllLoanedItemsList &&
    data.GetAllLoanedItemsList.getAllLoanedItemsList.totalNumberOfRecords
      ? data.GetAllLoanedItemsList.getAllLoanedItemsList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (columnAdded && setCreate.current == false)
      fetchTableData(page, rowsPerPage, search, defaultFilters);
  }, [columnAdded, fetchTableData, re, defaultFilters, columns.length]);

  const handleDelete = (selectedItems) => {
    deleteRecords(
      {
        variables: {
          model: {
            loanedItemGuids: selectedItems,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("skill.list.delete.success"), {
          variant: "success",
        });
        fetchTableData(page, rowsPerPage, search, defaultFilters);
        setRe((v) => !v);
        setPage(0);
        setSelected([]);
      }
    );
  };

  const handleEmail = () => {
    sendEmail(
      {
        variables: {
          ids: selected,
        },
      },
      (response) => {
        enqueueSnackbar(t("loanedItems.list.email.success"), {
          variant: "success",
        });
        setSelected([]);

        setRe((v) => !v);
        setPage(0);
        fetchTableData(0, rowsPerPage, search, defaultFilters);
      }
    );
  };
  useEffect(() => {
    const objectIndexEmployeeIDs = filterState.findIndex(
      (obj) => obj.property === "assignedToGuids"
    );

    if (objectIndexEmployeeIDs < 0)
      setDefaultFilters({
        ...defaultFilters,
        assignedToGuids: [eID ? eID : profileID],
      });
    const employeeIDsObj = {
      value: [eID ? eID : profileID],
      property: "assignedToGuids",
    };
    setFilterState([...filterState, employeeIDsObj]);
  }, [eID, profileID]);

  const filtersObject = [
    {
      label: "loanedItems.list.filters.employees",
      name: "assignedToGuids",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeesList ?? [],
      access: "employeeGuid",
      loading: employeeLoading,
    },
  ];
  const customButtons = [
    {
      label: "sendEmailTotheemployee",
      handleClick: () => {
        handleEmail();
      },
      condition: selected?.length > 0 && !myProfile === true,
      icon: true,
      iconButton: (
        <EmailIcon strokeWidth="1.5" color="var(--color--light-logo-blue)" />
      ),
    },
  ];

  const pagination = {
    rows: rows?.length == 0 ? records : rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    filtersObject,
    defaultFilters,
    setDefaultFilters,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "items.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables["LoanedItems"],
    deleteText: "items.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    isSubmitting,
    removeFilters: myProfile == true ? true : false,
    requiredFields,
    enqueueSnackbar,
    customButtons,
    hideEditColumns: myProfile === true ? true : false,
    readOnly: myProfile === true ? true : false,
    hideAddButton: employee?.employeeStatus?.mnemonic === Mnemonic.EmployeeStatuses.inactive,
  };

  const backendConfig = {
    includeExtraColumn: true,
  };

  const handleEmployeeChange = (event, id, rows) => {
    const selectedEmployeeGuid = event.target.value;
    setRows((prevRows) => {
      const updatedRows = [...prevRows];

      // Find index of the row with the given loanedItemGuid
      const index = updatedRows.findIndex(
        (x) => x.loanedItemGuid === id.loanedItemGuid
      );

      if (index !== -1) {
        updatedRows[index] = {
          ...updatedRows[index],
          employeeGuid: selectedEmployeeGuid,
        };
      } else {
        // Add new row
        const newLoanedItem = {
          ...DEFAULT_LOANED_ITEM,
          loanedItemGuid: id.loanedItemGuid,
          employeeGuid: selectedEmployeeGuid,
          isNew: true,
        };
        updatedRows.unshift(newLoanedItem);
      }
      // Return the updated rows
      return updatedRows;
    });
    setRowsUpdated(!rowsUpdated);
    setChangedEmployee(!changedEmployee);
    setSelectedEmployeeGuid(selectedEmployeeGuid);
  };

  useEffect(() => {
    if (
      columns.length > 0 &&
      columnAdded === false &&
      employeesList.length > 0
    ) {
      let updatedColumns = [...columns];

      if (backendConfig.includeExtraColumn && myProfile !== true) {
        updatedColumns.push({
          field: "employeeGuid",
          headerName: t("loanedItems.list.column.resource"),
          headerAlign: "left",
          align: "left",
          flex: 0.3,
          renderCell: (params) => {
            const disabled =
              (editMode.current === false && clickedRow.current === null) ||
              (clickedRow.current !== "" &&
                clickedRow.current !== undefined &&
                clickedRow.current !== null &&
                clickedRow.current.loanedItemGuid !== params.id) ||
              (clickedRowGuid.current !== "" &&
                clickedRowGuid.current !== undefined &&
                clickedRowGuid.current !== null &&
                clickedRowGuid.current !== params.id);
            return (
              <div className="selectEmployee">
                {employeesList.length > 0 && (
                  <Select
                    value={
                      params?.row?.employeeGuid
                        ? params.row.employeeGuid
                        : eID
                        ? eID
                        : profileID
                    }
                    onChange={(event) => {
                      handleEmployeeChange(event, params.row, rows);
                    }}
                    sx={{
                      ".MuiInputBase-input": { paddingLeft: "0px !important" },
                    }}
                    disabled={disabled}
                  >
                    {employeesList.map((emp) => (
                      <MenuItem
                        key={emp?.employeeGuid}
                        value={emp?.employeeGuid}
                      >
                        <div
                          className="selectEmployeeName"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "13px !important",
                          }}
                        >
                          {" "}
                          {emp?.firstName + " " + emp?.lastName}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            );
          },
        });
      }

      setColumnAdded(true);
      setColumns(updatedColumns);
    }
  }, [
    columns.length,
    backendConfig.includeExtraColumn,
    columnAdded,
    employeesList.length,
    clickedRow.current,
    clickedRowGuid.current,
    rows.length,
    editMode,
    myProfile,
  ]);

  const { cfunction: createLoanedItemData } = useMutationAuth(
    CREATE_LOANED_ITEM,
    "CreateLoanedItem"
  );

  const { cfunction: updateLoanedItemData } = useMutationAuth(
    UPDATE_LOANED_ITEM,
    "UpdateLoanedItem"
  );

  function CreateLoanedItem(newRow) {
    const employeeGuidToSend = selectedEmployeeGuid || eID;
    setIsSubmitting(true);

    createLoanedItemData(
      {
        variables: {
          model: {
            assignDate: newRow.assignDate,
            returnDate: newRow.returnDate,
            assignedTo: employeeGuidToSend,
            description: newRow.description,
          },
        },
      },
      (response) => {
        if (
          response["CreateLoanedItem"].errorCode !== null &&
          response["CreateLoanedItem"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateLoanedItem"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setRows(
            rows.filter((r) => r.loanedItemGuid !== newObj.loanedItemGuid)
          );
          setNewObj(DEFAULT_LOANED_ITEM);
          setRowsUpdated(!rowsUpdated);
        } else {
          enqueueSnackbar(t("record.createdSuccessfully"), {
            variant: "success",
          });
          editMode.current = false;
          clickedRowGuid.current = "";
          clickedRow.current = null;
          setSelectedEmployeeGuid(null);
          setRowsUpdated(!rowsUpdated);

          fetchTableData(page, rowsPerPage, search, defaultFilters);
          setNewObj(DEFAULT_LOANED_ITEM);
        }
        setSelectedEmployeeGuid(eID);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      },
      (error) => {
        setRows(rows.filter((r) => r.loanedItemGuid !== newObj.loanedItemGuid));
        setRowsUpdated(!rowsUpdated);

        setNewObj(DEFAULT_LOANED_ITEM);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      }
    );
  }

  function UpdateLoanedItem(updatedRow) {
    updateLoanedItemData(
      {
        variables: {
          model: {
            loanedItemGuid: updatedRow.loanedItemGuid,
            assignDate: updatedRow.assignDate,
            returnDate: updatedRow.returnDate,
            employeeGuid: updatedRow?.employeeGuid
              ? updatedRow?.employeeGuid
              : selectedEmployeeGuid,
            description: updatedRow.description,
          },
        },
      },
      (response) => {
        if (
          response["UpdateLoanedItem"].errorCode !== null &&
          response["UpdateLoanedItem"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateLoanedItem"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          fetchTableData(page, rowsPerPage, search, defaultFilters);
          setNewObj(DEFAULT_LOANED_ITEM);
        } else {
          enqueueSnackbar(t("record.updatedSuccessfully"), {
            variant: "success",
          });
          setSelectedEmployeeGuid(null);
          editMode.current = false;
          clickedRowGuid.current = "";
          clickedRow.current = null;

          fetchTableData(page, rowsPerPage, search, defaultFilters);
          setNewObj(DEFAULT_LOANED_ITEM);
        }
        setRowsUpdated(!rowsUpdated);
        setSelectedEmployeeGuid(eID);
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search, defaultFilters);
        setNewObj(DEFAULT_LOANED_ITEM);
      }
    );
  }

  return (
    <div>
      {!columns ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && (
          <GridTable
            fromEmployees
            {...pagination}
            identifier="loanedItemGuid"
            hasCheckbox={myProfile === true ? false : true}
            editMode={editMode}
            hideActions={hideActions}
            handleCreate={(item) => CreateLoanedItem(item)}
            handleUpdate={(item) => UpdateLoanedItem(item)}
            handleDelete={(items) => handleDelete(items)}
            handleEditMode={(v) => (editMode.current = v)}
            handleClickedRow={(v) => (clickedRow.current = v)}
            handleClickedRowGuid={(v) => (clickedRowGuid.current = v)}
          />
        )
      )}
    </div>
  );
}

export default LoanedItemsList;
