import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../urls";

import useAuth from "../../../hooks/useAuth";

function ProfileTabs({ fromTenant = false }) {
  const { storeUserGuid } = useAuth();
  const { profileID, profileName } = useParams();

  const {
    my_profile,
    admin_profile,
    my_skills2,
    my_ptos2,
    admin_ptos,
    admin_skills,
    admin_teams,
    my_teams_profile,
    my_loaned_items
  } = urls;

  const { t } = useTranslation();

  const TabLinks = [
    {
      key: 1,
      Header: t("profileForm.myProfile"),
      Link: fromTenant
        ? admin_profile.replace(
            "/:profileName/:profileID",
            `/${encodeURIComponent(profileName)}/${encodeURIComponent(
              storeUserGuid
            )}`
          )
        : my_profile
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
    },
    {
      key: 2,
      Header: t("profileTabs.timeOffs"),
      Link: fromTenant
        ? admin_ptos.replace(
            "/:profileName/:profileID",
            `/${encodeURIComponent(profileName)}/${encodeURIComponent(
              storeUserGuid
            )}`
          )
        : my_ptos2
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
    },
    {
      key: 3,
      Header: t("profileTabs.skills"),
      Link: fromTenant
        ? admin_skills.replace(
            "/:profileName/:profileID",
            `/${encodeURIComponent(profileName)}/${encodeURIComponent(
              storeUserGuid
            )}`
          )
        : my_skills2
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
    },
    {
      key: 4,
      Header: t("profileTabs.teams"),
      Link: fromTenant 
      ? admin_teams.replace(
          "/:profileName/:profileID",
          `/${encodeURIComponent(profileName)}/${encodeURIComponent(
            storeUserGuid
          )}`
        )
        : my_teams_profile
          .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
          .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
    },
    {
      key: 5,
      Header: t("profileTabs.loanedItems"),
      Link: my_loaned_items.replace(
          "/:profileName/:profileID",
          `/${encodeURIComponent(profileName)}/${encodeURIComponent(
            storeUserGuid
          )}`
        )
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default ProfileTabs;
