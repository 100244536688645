import { useReducer } from "react";

//get filter types
import {
  UPDATE_VALUE,
  UPDATE_LIST,
  CLEAR_FILTERS,
  UPDATE_DATE,
  UPDATE_OBJECT,
  UPDATE_LIST_OBJECT_OF_OBJECT,
  UPDATE_LIST_VALUES,
  COPY_ITEMS,
  CLEAR_VALUE,
} from "../types/filterTypes";

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_VALUE:
      var tmp = { ...state };
      if (
        action.payload.value === "" ||
        action.payload.value === 0 ||
        action.payload.value === null
      ) {
        delete tmp[action.payload.property];
      } else if (action.payload.value === "none") {
        tmp = { ...state, [action.payload.property]: null };
      } else {
        tmp = { ...state, [action.payload.property]: action.payload.value };
      }
      return tmp;
    case UPDATE_DATE:
      var tmp = { ...state };
      if (
        action.payload.value === "" ||
        action.payload.value === 0 ||
        action.payload.value === null
      ) {
        delete tmp[action.payload.property];
      } else {
        tmp = { ...state, [action.payload.property]: action.payload.value };
      }
      return tmp;
    case UPDATE_LIST:
      var tmp = { ...state };
      if (action.payload.value === null || action.payload.value.length === 0) {
        delete tmp[action.payload.property];
      } else {
        tmp = {
          ...state,
          [action.payload.property]: action.payload.value.map(
            (v) => v[action.payload.access]
          ),
        };
      }
      return tmp;
    case UPDATE_LIST_OBJECT_OF_OBJECT:
      var tmp = { ...state };
      if (action.payload.value === null || action.payload.value.length === 0) {
        delete tmp[action.payload.property];
      } else {
        tmp = {
          ...state,
          [action.payload.property]: action.payload.value.map((v) => {
            try {
              return eval("v." + action.payload.access);
            } catch (error) {
              return null;
            }
          }),
        };
      }
      return tmp;
    case UPDATE_OBJECT:
      var tmp = { ...state };
      if (action.payload.value === null || action.payload.value.length === 0) {
        delete tmp[action.payload.property];
      } else {
        tmp = {
          ...state,
          [action.payload.property]:
            action.payload.value[action.payload.access],
        };
      }
      return tmp;
    case CLEAR_VALUE:
      var tmp = { ...state };
      if (action.payload.value === null || action.payload.value.length === 0) {
        delete tmp[action.payload.property];
      }
      return tmp;
    case UPDATE_LIST_VALUES:
      var tmp = { ...state };
      if (action.payload.value === null || action.payload.value.length <= 0) {
        delete tmp[action.payload.property];
      } else {
        tmp = { ...state, [action.payload.property]: action.payload.value };
      }
      return tmp;

    case COPY_ITEMS:
      var tmp = action.payload.value;

      return tmp;

    case CLEAR_FILTERS:

      var tmp = {};
      for (const property in state) {

        
        if (state.hasOwnProperty(property) && action.payload && action.payload[property] && !action.payload[property].disableClear) {
          tmp[property] = state[property];
        }
      }
      return tmp;



    default:
      return state;
  }
}

const useFilters = () => {
  const [state, dispatch] = useReducer(reducer, {});

  return { state, dispatch };
};

export default useFilters;
