import * as Yup from "yup";
import checkout from "./form";

import Mnemonics from "../../../../../Mnemonics.json";

const {
  formField: {
    employee,
    description,
    date,
    checkInTime,
    checkOutTime,
    workType,
    location,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [employee.name]: Yup.object().required(employee.requiredMessage),
    [date.name]:Yup.string().required(date.requiredMessage),
    [description.name]:Yup.string().nullable(),
    [checkInTime.name]:Yup.date().required(checkInTime.requiredMessage),
    [checkOutTime.name]:Yup.date().required(checkOutTime.requiredMessage),
    [workType.name]: Yup.object().required(workType.requiredMessage),
    [location.name]: Yup.object()
    .nullable()
    .when(workType.name, {
      is: (type) =>
        type && type.mnemonic !== Mnemonics.WorkTypes.workfromhome,
      then: () => Yup.object().required(location.requiredMessage),
    }),
  }),
];

export default validation;