import React, { useEffect, useState, useRef } from "react";

import "./style.css";

//external components
import {
  Box,
  IconButton,
  Stack,
  Button,
  Modal,
  MenuItem,
  Grid,
  Typography,
  Alert,
  Dialog,
} from "@mui/material";

//components
import IALoadingButton from "../../IAButtons/IALoadingButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
//icons
import { Search as SearchIcon } from "@mui/icons-material";

//translation
import { useTranslation } from "react-i18next";

//hooks
import RoundedTextField from "../../TextFields/RoundedTextField";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import Tooltip from "../../Tooltip";

//uuid generator
import { v4 as uuidv4 } from "uuid";
import ContainerCard from "../../Cards/ContainerCard";
//mnemonic values
import Mnemonic from "../../../Mnemonics.json";
import { COPY_ITEMS, CLEAR_FILTERS } from "../../../types/filterTypes";

import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import colors from "../../../assets/theme/light/colors";
import { CloseIcon, EditIcon, SaveIcon } from "../../Icon";
import useFilters from "../../../hooks/useFilters";
//icons
import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";
import FilterField from "../../FilterField";
import ProfilePhoto from "../../../pages/admin-configuration/Employees/Employee-form/components/profilePhoto";
import CustomAlert from "../../CustomAlert/CustomAlert";
import CustomButton from "../../CustomButton";
import PaperComponent from "../../Modal/PaperComponent";

//wait before search function call
const DELAY_BEFORE_SEARCH = 1000;

export default function GridTable(props) {
  const {
    rows,
    columns,
    setRows,
    setCreate,
    newObj,
    setNewObj,
    handleChangeState,
    selected,
    setSelected,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    identifier,
    order,
    setOrderBy,
    orderBy,
    numberRecords,
    fetchTableData,
    search,
    setSearch,
    searchText,
    handleDelete,
    handleCreate,
    handleUpdate,
    hasCheckbox,
    hideRefreshButton,
    hideAddButton,
    hideSearchBar,
    customButtons,
    changeOrder,
    disableUpArrow,
    disableDownArrow,
    handleMoveUp,
    handleMoveDown,
    max,
    defaultFilters,
    setDefaultFilters,
    removeFilters,
    hideDeleteButton,
    fetchedBool,
    removeDeleteButton,
    hidePagination,
    readOnly,
    customAction,
    rowClick,
    isSubmitting,
    handleEditMode,
    editMode,
    handleClickedRow,
    handleClickedRowGuid,
    filtersObject,
    fromEmployees,
    requiredFields,
    enqueueSnackbar,
    hideActions,
  } = props;

  const { red_color, light_logo_blue, disabled_text_grey, icon_button_grey } =
    colors;

  const [rowModesModel, setRowModesModel] = useState({});
  const [savedKey, setSavedKey] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: rowsPerPage,
    page: page,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [disabledAdd, setDisableAdd] = useState(readOnly);
  const fetched = useRef(fetchedBool ? fetchedBool : false);
  const [isFocused, setIsFocused] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowID, setSelectedRowID] = useState(null);
  const [filterState, setFilterState] = useState([]);

  const [openFilters, setOpenFilters] = useState(false);

  //get object to send to filters
  const { state: filtersState, dispatch: handleFilters } = useFilters();
  //timer for search functionality
  const [timer, setTimer] = useState(null);

  const { t } = useTranslation();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleDelete(selectedRowID);
    handleClose();
  };

  const handleOpenDeleteConfirmationModal = (id) => {
    setSelectedRowID(id);
    setOpenDialog(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setSelectedRowID(null);
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (fetched.current === false) {
      columns.map((col) => {
        if (col.hasOwnProperty("headerName")) {
          col.headerName = t(col.headerName);
        }
        return col;
      });
      fetched.current = true;
    }
  }, columns);

  const cols =
    fetched.current === true
      ? [
        ...columns,
        hideActions
          ? null 
          : {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            flex: 0.2,
            cellClassName: "actions",
            getActions: (row) => {
              const isInEditMode =
                rowModesModel[row?.id]?.mode === GridRowModes.Edit;
              const guid = Object.keys(rowModesModel)[0];

              if (isInEditMode) {
                return [
                  <Tooltip title={t("table.Save")}>
                    <GridActionsCellItem
                      icon={<SaveIcon color={light_logo_blue} />}
                      label="Save"
                      sx={{ color: light_logo_blue }}
                      onClick={handleSaveClick(row?.id)}
                    />
                  </Tooltip>,

                  <Tooltip title={t("table.Cancel")}>
                    <GridActionsCellItem
                      icon={
                        <CloseIcon
                          color={icon_button_grey}
                          width={"24"}
                          stroke={"1"}
                        />
                      }
                      label="Cancel"
                      className="textPrimary"
                      onClick={handleCancelClick(row?.id)}
                    />
                  </Tooltip>,
                ];
              }

              if (changeOrder) {
                if (row?.row?.disabledSorting === true) return [];
                
                else
                  return [
                    <Tooltip title={t("table.MoveUp")}>
                      <GridActionsCellItem
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-arrow-narrow-up"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1"
                            stroke={light_logo_blue}
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 5l0 14" />
                            <path d="M16 9l-4 -4" />
                            <path d="M8 9l4 -4" />
                          </svg>
                        }
                        label="Move Up"
                        className="textPrimary"
                        sx={{ color: disabled_text_grey }}
                        onClick={() => handleMoveUp(row)}
                        disabled={disabledAdd || disableUpArrow ? disableUpArrow(row?.row) : false}
                      />
                    </Tooltip>,
                    <Tooltip title={t("table.MoveDown")}>
                      <GridActionsCellItem
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-arrow-narrow-down"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1"
                            stroke={light_logo_blue}
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 5l0 14" />
                            <path d="M16 15l-4 4" />
                            <path d="M8 15l4 4" />
                          </svg>
                        }
                        label="Move Down"
                        className="textPrimary"
                        sx={{ color: disabled_text_grey }}
                        onClick={() => handleMoveDown(row)}
                        disabled={disabledAdd || disableDownArrow ? disableDownArrow(row?.row) : false}
                      />
                    </Tooltip>,
                  ];
              }

              if (removeDeleteButton === true)
                return [
                  <Tooltip title={t("table.Edit")}>
                    <GridActionsCellItem
                      icon={
                        <EditIcon
                          color={
                            disabledAdd || selected.length > 0
                              ? disabled_text_grey
                              : light_logo_blue
                          }
                        />
                      }
                      label="Edit"
                      className="textPrimary"
                      disabled={disabledAdd || selected.length > 0}
                      onClick={handleEditClick(row?.id)}
                      sx={{ color: light_logo_blue }}
                    />
                  </Tooltip>,
                ];

              if (customAction) {
                return [
                  <Tooltip title={customAction.title}>
                    <IconButton
                      onClick={() =>
                        customAction.handleClick(
                          row?.id,
                          eval("row?.row?." + customAction.identifier)
                        )
                      }
                      disabled={
                        customAction.disabled ||
                        disabledAdd ||
                        selected.length > 0
                      }
                    >
                      {customAction.icon(disabledAdd || selected.length > 0)}
                    </IconButton>
                  </Tooltip>,
                  <Tooltip title={t("table.Edit")}>
                    <GridActionsCellItem
                      icon={
                        <EditIcon
                          color={
                            disabledAdd || selected.length > 0
                              ? disabled_text_grey
                              : light_logo_blue
                          }
                        />
                      }
                      label="Edit"
                      className="textPrimary"
                      disabled={disabledAdd || selected.length > 0}
                      onClick={handleEditClick(row?.id)}
                      sx={{ color: light_logo_blue }}
                    />
                  </Tooltip>,
                  <Tooltip title={t("table.Delete")}>
                    <IconButton
                      onClick={() => handleOpenDeleteConfirmationModal(row?.id)}
                      disabled={disabledAdd}
                      // sx={{
                      //   visibility:
                      //     hideDeleteButton &&
                      //     (row?.row?.state?.mnemonic ===
                      //       Mnemonic.TaskState.new ||
                      //       row?.row?.state?.mnemonic ===
                      //         Mnemonic.TaskState.done)
                      //       ? "hidden"
                      //       : "visible",
                      // }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-trash"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1"
                        stroke={disabledAdd ? disabled_text_grey : red_color}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7l16 0" />
                        <path d="M10 11l0 6" />
                        <path d="M14 11l0 6" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </IconButton>
                  </Tooltip>,
                ];
              }

              return [
                <Tooltip title={t("table.Edit")}>
                  <GridActionsCellItem
                    icon={
                      <EditIcon
                        color={
                          disabledAdd || selected.length > 0
                            ? disabled_text_grey
                            : light_logo_blue
                        }
                      />
                    }
                    label="Edit"
                    className="textPrimary"
                    disabled={disabledAdd || selected.length > 0}
                    onClick={handleEditClick(row?.id)}
                    sx={{ color: light_logo_blue }}
                  />
                </Tooltip>,
                <Tooltip title={t("table.Delete")}>
                  <IconButton
                    onClick={() => handleOpenDeleteConfirmationModal(row?.id)}
                    disabled={disabledAdd}
                    // sx={{
                    //   visibility:
                    //     hideDeleteButton &&
                    //     (row?.row?.state?.mnemonic ===
                    //       Mnemonic.TaskState.new ||
                    //       row?.row?.state?.mnemonic ===
                    //         Mnemonic.TaskState.done)
                    //       ? "hidden"
                    //       : "visible",
                    // }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-trash"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="1"
                      stroke={disabledAdd ? disabled_text_grey : red_color}
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 7l16 0" />
                      <path d="M10 11l0 6" />
                      <path d="M14 11l0 6" />
                      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                    </svg>
                  </IconButton>
                </Tooltip>,
              ];
            },
          },
        ].filter(Boolean)
      : [];

  const handleSearch = (value, event) => {
    if(setPage) setPage(0);
    event.preventDefault();
    if (timer) {
      //if timer is set, we empty it so it's not called and wait another delay
      clearTimeout(timer);
      setTimer(null);
    }

    //set timer for after 2 seconds of wait
    setTimer(
      setTimeout(() => {
        setSearch(value);
        fetchTableData(0, rowsPerPage, value, defaultFilters);
      }, DELAY_BEFORE_SEARCH)
    );
  };
  
  const handleRowModesModelChange = (newRowModesModel) => {
    // setRowModesModel(newRowModesModel);
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setRowsPerPage(newPaginationModel.pageSize);
    if(setPage) setPage(newPaginationModel.page);
    setPaginationModel(newPaginationModel);

    fetchTableData(
      newPaginationModel.page,
      newPaginationModel.pageSize,
      search,
      defaultFilters
    );
  };

  const handleEditClick = (id) => () => {
    if (handleEditMode !== undefined) handleEditMode(true);
    if (handleClickedRowGuid !== undefined) handleClickedRowGuid(id);
    if (handleClickedRow !== undefined) handleClickedRow(null);

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setDisableAdd(true);
  };

  const handleSaveClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setDisableAdd(false);
    setSavedKey(id);
    if (handleClickedRowGuid !== undefined) handleClickedRowGuid(null);
    if (handleClickedRow !== undefined) handleClickedRow(null);
  };

  const handleCancelClick = (id) => () => {
    if (handleClickedRowGuid !== undefined) handleClickedRowGuid(null);
    if (handleClickedRow !== undefined) handleClickedRow(null);
    if (handleEditMode !== undefined) handleEditMode(false);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => eval("row." + identifier) === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => eval("row." + identifier) !== id));
    }
    setDisableAdd(false);
  };

  const handleRowEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridCellEditStopReasons.enterKeyDown ||
      params.reason === GridCellEditStopReasons.escapeKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
  };
  
  const handleCellEditStop = (params, event) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridCellEditStopReasons.enterKeyDown ||
      params.reason === GridCellEditStopReasons.escapeKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {

    let proceed = requiredFields?.every((field) => {
      if (newRow[field] === null || newRow[field] === "") {
        return false;
      }
      return true;
    });
    if (newRow.returnDate !== null && newRow.assignDate > newRow.returnDate) {
      proceed = false;
     
      if (enqueueSnackbar !== undefined) {
        enqueueSnackbar(t("date.error"), {
          variant: "error",
        });
      }
    }
    
    if (proceed) {
      setNewObj(newRow);
      const newArr = rows;
      newArr.forEach((element) => {
        if (eval("element." + identifier) === eval("newRow." + identifier)) {
          element = newRow;
        }
      });
      setRows(newArr);
      if (newRow.isNew === true) handleCreate(newRow);
      else handleUpdate(newRow);
      //call update r create function
      const updatedRow = { ...newRow, isNew: true };
      return updatedRow;
    } else {
      setRowModesModel({ ...rowModesModel, [savedKey]: { mode: GridRowModes.Edit } });
      setDisableAdd(true);
      if(enqueueSnackbar !== undefined && (newRow.assignDate < newRow.returnDate)) enqueueSnackbar(t("table.fill.requiredFields"), {
        variant: "error",
      });
      return newRow;
    }
  };

  const handleClick = () => {
    setDisableAdd(true);
    const key = uuidv4();

    newObj[identifier] = key;
    newObj["isNew"] = true;
    let newArray = structuredClone(rows);
    newArray.unshift(newObj);
    setRows(newArray);
    setCreate.current = true;

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [key]: {
        mode: GridRowModes.Edit,
        fieldToFocus: Object.keys(newArray[0])[1],
      },
    }));
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  
  return (
    <>
      <ConfirmationModal
        contentText={
          selected?.length > 1
            ? t("dialog.user.delete.records.confirmation")
            : t("dialog.user.delete.record.confirmation")
        }
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <Dialog
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <ContainerCard sx={{ padding: "20px", width: "100%", overflowY: "auto" }} id="draggable-dialog-title">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: "poppins",
                }}
              >
                {t("table.FilterTitle")}
              </Typography>
              <IconButton onClick={() => setOpenFilters(false)}>
                <ClearOutlinedIcon />
              </IconButton>
            </Grid>
            {filtersObject &&
              filtersObject.map((filter, index) => {
                if (filter.skip) return null;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={filter.type == "radio-group" ? 12 : 6}
                    xl={filter.type == "radio-group" ? 12 : 6}
                    key={index}
                  >
                    <FilterField
                      {...filter}
                      filtersState={filtersState}
                      handleFilters={handleFilters}
                      handleSaveState={(v) => {
                        const objectIndex = filterState.findIndex(
                          (obj) => obj.property === v.property
                        );

                        if (objectIndex !== -1) {
                          // If the object exists, update the state by replacing the old object with the updated one
                          setFilterState((prevState) => [
                            ...prevState.slice(0, objectIndex),
                            v,
                            ...prevState.slice(objectIndex + 1),
                          ]);
                          if (handleChangeState !== undefined)
                            handleChangeState((prevState) => [
                              ...prevState.slice(0, objectIndex),
                              v,
                              ...prevState.slice(objectIndex + 1),
                            ]);
                        } else {
                          setFilterState([...filterState, v]);
                          if (handleChangeState !== undefined)
                            handleChangeState([...filterState, v]);
                        }
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <div className="filter-buttons">
            <IALoadingButton
              label={t("table.filters.clear")}
              onClick={() => {
                setFilterState([]);
                if (handleChangeState !== undefined) handleChangeState([]);
                handleFilters({
                  type: CLEAR_FILTERS,
                  payload: filtersObject?.reduce((acc, filter) => {
                    if (filter.disableClear === true) {
                      acc[filter.name] = { disableClear: false };
                    } else {
                      acc[filter.name] = { disableClear: true };
                    }
                    return acc;
                  }, {}),
                });
                const filtersArray = filtersObject.filter(
                  (filter) => filter.disableClear === true
                );
                let filters = {};
                filtersArray.map((filter) => {
                  let name = filter.name;
                  if (defaultFilters?.hasOwnProperty(name))
                    filters = { ...filters, [name]: defaultFilters[name] };
                });
                setDefaultFilters(filters);
                fetchTableData(page, rowsPerPage, search, filters);
              }}
            />
            <IALoadingButton
              label={t("table.filters.apply")}
              onClick={() => {
                setDefaultFilters(filtersState);
                fetchTableData(page, rowsPerPage, search, filtersState);
                setOpenFilters(false);
              }}
            />
          </div>
        </ContainerCard>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "10px 0",
          gap: "10px",
        }}
      >
        {!hideSearchBar && (
          <RoundedTextField
            label={searchText ? t(searchText) : ""}
            fullWidth
            size="small"
            value={search}
            onFocus={() => setIsFocused(true)}
            onChange={(e) => {
              setSearch(e.target.value);
              handleSearch(e.target.value, e);
            }}
            inputProps={{
              style: {
                backgroundColor: "white",
                borderRadius: "5px",
                height: "15px",
                fontSize: "14px",
              },
            }}
            InputProps={{
              endAdornment: isFocused ? (
                <IconButton
                  onClick={(e) => {
                    setSearch("");
                    handleSearch("", e);
                    setIsFocused(false);
                  }}
                >
                  <CloseIcon color={"#979797"} width={"24"} stroke={"2"} />
                </IconButton>
              ) : (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
              style: {
                backgroundColor: "white",
                paddingRight: "0",
                opacity: 0.7,
                height: "33px",
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: "14px", // Adjust the font size as needed
                lineHeight: 1.1,
              },
            }}
          />
        )}
        
       { !removeFilters && ( JSON.stringify(defaultFilters) === "{}" ? (
              <CustomAlert color="info" severity="info">
                {t("gridTable.noFilters")}
              </CustomAlert>
            ) : (
              // <HtmlTooltip
              //   title={
              //     <React.Fragment>
              //       {Object.entries(filterText).map((value) => {
              //         return (
              //           <p>
              //             {value[0]}:{" "}
              //             {typeof value[1] === "object" ? value[1].toString() : value[1]}
              //           </p>
              //         );
              //       })}
              //     </React.Fragment>
              //   }
              // >
                <IALoadingButton variant="gradient" color="error" label={t("gridTable.filtersApplied")} style={{ width: "10%", whiteSpace: "nowrap", ".MuiLoadingButton-root": { width: "10% !important "} }}

                onClick={() => {
                  setFilterState([]);
                  if (handleChangeState !== undefined) handleChangeState([]);
                  handleFilters({
                    type: CLEAR_FILTERS,
                    payload: filtersObject?.reduce((acc, filter) => {
                      if (filter.disableClear === true) {
                        acc[filter.name] = { disableClear: false };
                      } else {
                        acc[filter.name] = { disableClear: true };
                      }
                      return acc;
                    }, {}),
                  });
                  const filtersArray = filtersObject.filter(
                    (filter) => filter.disableClear === true
                  );
                  let filters = {};
                  filtersArray.map((filter) => {
                    let name = filter.name;
                    if (defaultFilters?.hasOwnProperty(name))
                      filters = { ...filters, [name]: defaultFilters[name] };
                  });
                  setDefaultFilters(filters);
                  fetchTableData(page, rowsPerPage, search, filters);
                }}
                />
              // </HtmlTooltip>
            ))}
        {!hideRefreshButton && (
          <Tooltip title={t("table.RefreshBtn")}>
            <IconButton
              // disabled={disabledAdd}
              onClick={() =>
                fetchTableData(page, rowsPerPage, search, defaultFilters)
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-reload"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="#000000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" />
                <path d="M20 4v5h-5" />
              </svg>
            </IconButton>
          </Tooltip>
        )}


        {!removeFilters &&
          (JSON.stringify(defaultFilters) === "{}" ? (
            <Tooltip title={t("table.FilterTitle")}>
              <IconButton
                onClick={() => {
                  handleFilters({
                    type: COPY_ITEMS,
                    payload: { value: defaultFilters },
                  });
                  setOpenFilters(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-filter"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" />
                </svg>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={t("table.FilterTitleApplied")}>
              <IconButton
                onClick={() => {
                  handleFilters({
                    type: COPY_ITEMS,
                    payload: { value: defaultFilters },
                  });
                  setOpenFilters(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-filter-check"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="var(--color--green-color)"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M11.18 20.274l-2.18 .726v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v3" />
                  <path d="M15 19l2 2l4 -4" />
                </svg>
              </IconButton>
            </Tooltip>
          ))}

        {selected &&
          selected.length === 0 &&
          !hideAddButton &&
          readOnly === false && (
            <Tooltip title={t("table.AddNew")}>
              <IconButton
                onClick={() => {
                  handleClick();
                  // if (handleEditMode !== undefined) handleEditMode(true);
                   if (handleClickedRow !== undefined) handleClickedRow(newObj);
                   if(handleClickedRowGuid !== undefined) handleClickedRowGuid(null)
                }}
                disabled={disabledAdd || selected.length > 0 || isSubmitting}
              >
                <AddCircleRoundedIcon
                  sx={{
                    color: "var(--color--light-logo-blue)",
                    fontSize: "28px !important",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        {customButtons &&
          customButtons.map((button, index) => {
            if (button.condition)
              if (button.icon)
                return (
                  <Tooltip key={index} title={t(button.label)}>
                    <IconButton
                      onClick={() => button.handleClick()}
                      disabled={disabledAdd}
                    >
                      {button.iconButton}
                    </IconButton>
                  </Tooltip>
                );
              else
                return (
                  <IALoadingButton
                    key={index}
                    label={t(button.label)}
                    className="new-record-btn"
                    dark
                    error={button.error}
                    startIcon={button.startIcon}
                    loading={button.loading}
                    disabled={button.disabled}
                    onClick={() => {
                      button.handleClick();
                    }}
                  />
                );
            else return null;
          })}
      </Box>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={fromEmployees ? 10 : 12}
          md={fromEmployees ? 10 : 12}
          lg={fromEmployees ? 10 : 12}
          xl={fromEmployees ? 10 : 12}
        >
          <DataGrid
            rows={rows}
            columns={cols}
            editMode="row"
            initialState={{
              pagination: { paginationModel: paginationModel },
            }}
            hideFooterPagination={hidePagination ? true : false}
            pageSizeOptions={[20, 50, 100]}
            rowCount={numberRecords}
            rowModesModel={rowModesModel}
            paginationMode="server"
            getRowId={(row) => eval("row." + identifier)}
            onRowModesModelChange={handleRowModesModelChange}
            onPaginationModelChange={handlePaginationModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
              setSelected(newRowSelectionModel);
            }}
            onCellEditStop={handleCellEditStop}
            rowSelectionModel={rowSelectionModel}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            disableColumnMenu
            disableColumnFilter
            disableRowSelectionOnClick={true}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onCellDoubleClick={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onRowClick={(params, event) => {
              if (rowClick == undefined) event.defaultMuiPrevented = true;
              else rowClick(params.row.teamGuid, params.row.description);
            }}
            onRowDoubleClick={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            checkboxSelection={hasCheckbox}
            rowHeight={35}
            slots={{
              noRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <p style={{ color: "grey" }}>{t("table.noData")}</p>
                </Stack>
              ),
            }}
            sx={{
              boxShadow: 1,
              backgroundColor: "white",
              "& .MuiDataGrid-cell:not(first-child)": {
                outline: "none",
                border: "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              maxHeight: hidePagination
                ? "calc(100vh - 110px)"
                : "calc(100vh - 150px)",
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },

              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-overlayWrapperInner": {
                height: "30px !important",
              },
              "& .MuiDataGrid-overlayWrapper": {
                height: "30px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </Grid>
        {fromEmployees ? (
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <ContainerCard
              sx={{
                boxShadow: "0px 0px 10px rgba(24,117,214,255)",
                height: "200px",
                marginTop: "0.1rem",
              }}
            >
              <Box p={3.5}>
                <ProfilePhoto fromTab />
              </Box>
            </ContainerCard>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
