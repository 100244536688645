import React, { useState, useEffect, useCallback, useRef } from "react";

import { useLocation } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AutoComplete from "../../../../components/AutoComplete";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import Tooltip from "../../../../components/Tooltip";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";

//graphql
import {
  GET_ALL_TIMESHEETS,
  GET_TIMESHEETS_LIST,
  GET_TIMESHEET_BY_ID,
  APPROVE_TIMESHEET,
  DELETE_TIMESHEET,
} from "../../../../graphql/timesheet";
import { DELETE_TIME_ENTRY_LIST } from "../../../../graphql/timeEntry";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TASKS_WITH_PROJECT } from "../../../../graphql/task";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";
import "./style.css";

//external components
import { CircularProgress, Grid, Button, Box } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import EditRecord from "../Timesheets-Form/components/EditRecord";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import TimeEntryDrawer from "../Timesheets-Form/components/TimeEntryDrawer";
import CustomButton from "../../../../components/CustomButton";
import { DeleteIcon, RefreshIcon } from "../../../../components/Icon";

import { v4 as uuidv4 } from "uuid";
import { removeNullProps } from "../../../../utils/removeNullProps";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";

const TimesheetListPending = () => {
  const location = useLocation();

  const {
    storeUserGuid,
    storeUserTenant,
    userRoles,
    isManager,
    syncTimeEntry,
    allowTimesheetReopening,
    allowAttendanceSheetReopening,
  } = useAuth();

  const { manageTimeEntry } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const redValue = "var(--color-error)";
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [linkCell, setLinkCell] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalDuration, setTotalDuration] = useState(0);
  const [search, setSearch] = useState("");
  const [timesheetObj, setTimesheetObj] = useState(null);
  const [rows, setRows] = useState([]);
  const [clicked, setClicked] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [resetFields, setResetFields] = React.useState(false);
  const [timeEntry, setTimeEntry] = React.useState(null);
  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [timeEntryGuid, setTimeEntryGuid] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [removeSheet, setRemoveSheet] = useState(false);
  const type = useRef("task");

  const timeSheetGuid = React.useRef(null);
  const searchParams = new URLSearchParams(location.search);

  const { data: timesheetData } = useQueryAuth(
    GET_TIMESHEET_BY_ID,
    "GetTimeSheetById",
    {
      variables: { id: searchParams?.get("timesheetGuid") },
      skip: searchParams.size === 0,
      isWait: searchParams.size > 0,
    }
  );

  useEffect(() => {
    setTimesheetObj(timesheetData?.GetTimeSheetById?.timeSheet || null);
    timeSheetGuid.current = searchParams?.get("timesheetGuid");
  }, [timesheetData]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    removeSheet ? handleRemove() : handleApprove();
    timeSheetGuid.current = null;
    handleClose();
  };

  const handleOpenDeleteConfirmationModal = (flag) => {
    setRemoveSheet(flag);
    setOpenDialog(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (timeEntry) setTimeEntryGuid(timeEntry.timeEntryGuid);
  }, [timeEntry]);

  const { mfunction: deleteRecords } = useMutationAuth(
    DELETE_TIME_ENTRY_LIST,
    "DeleteTimeEntries"
  );
  const { mfunction: deleteTimesheetRecord } = useMutationAuth(
    DELETE_TIMESHEET,
    "DeleteTimeSheet"
  );

  const { mfunction: approveTimesheet } = useMutationAuth(
    APPROVE_TIMESHEET,
    "ApproveTimeSheet"
  );

  const {
    data: timesheetListData,
    loading: timesheetListLoading,
    tokenChange: timesheetListWait,
    refetch: resetList,
  } = useQueryAuth(GET_ALL_TIMESHEETS, "GetTimeSheetList", {
    variables: {
      model: {
        enableFilters: false,
        tenantGuid: storeUserTenant,
        userID: storeUserGuid,
        pending: true,
      },
    },
  });

  const timesheets_list =
    timesheetListData?.GetTimeSheetList?.timeSheetList?.timeSheets || [];

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: timesheetListWait,
    isWait: true,
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: taskWait,
  } = useQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: timesheetListWait || projectsWait,
    isWait: true,
  });

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const {
    loading: getColumnsLoading,
    refetch: refetchColumns,
    tokenChange: columnsWait,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.TimesheetList,
          userID: storeUserGuid,
        },
      },
      skip: timesheetListWait || projectsWait || taskWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        const actions = ({ compProps }) => {
          let props = compProps;
          return (
            <EditRecord
              {...props}
              handleClick={(v) => setClicked(v)}
              handleRowSelect={(t) => setTimeEntry(t)}
              handleEditMode={(v) => setEditMode(v)}
              disabled={manageTimeEntry !== "M"}
            />
          );
        };

        if (col) {
          col = decodeUnicodeString(col);
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, null, formatDateTimeToString)
          );

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);

          const colmns = parsed.columns;
          if (colmns) {
            colmns.forEach((col) => {
              col = removeNullProps(col)
              if (col.id === "date")
                return (col.renderCell = (row) => {
                  return formatDateTimeToString(row?.startTime, false);
                });
            });
            setLinkCell(colmns.some((col) => col.link === true));
            setColumns(colmns);
          }
        }
      },
    }
  );

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        workshiftGuid: timesheetObj?.workShift?.workShiftGuid,
        isManagerUsersList: isManager === true ? true : null,
        userID: storeUserGuid,
        timeEntryDisabled: true,
      },
    },
    skip: timesheetObj == null,
    isWait: true,
  });
  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const closedByList = [
    { label: t("table.filters.all"), value: "none" },
    { label: "Closed By System", value: 1 },
    { label: "Closed By User", value: 2 },
  ];

  const filtersObject = [
    {
      label: "timesheet.list.filters.employees",
      name: "employee",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeeList,
      access: "employeeGuid",
      loading: employeeLoading,
    },
    {
      label: "timesheet.list.filters.projects",
      name: "project",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: projectsList,
      access: "projectGuid",
      loading: projectLoading,
    },
    {
      label: "timesheet.list.filters.tasks",
      name: "task",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: task_list,
      access: "taskGuid",
      loading: taskLoading,
      group: true,
      groupBy: (option) => option.ticket?.project?.title,
    },
    {
      type: "date",
      label: "timesheet.list.filters.date",
      name: "date",
    },
    {
      label: "timesheet.list.filters.automaticallyClosed",
      name: "automaticallyClosed",
      type: "radio-group",
      options: closedByList,
    },
  ];

  const {
    mfunction: getAllTimesheets,
    loading,
    refetch: refetchTimeSheet,
  } = useLazyQueryAuth(GET_TIMESHEETS_LIST, "GetTimeSheetTimeEntries");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTimesheets(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              filters: filters,
              orderColumn: orderBy,
              orderDirection: order,
              timesheetGuid: timeSheetGuid.current,
              userID: storeUserGuid
            },
          },
          skip: timeSheetGuid.current == null,
          isWait: true,
        },
        (response) => {
          setData(response);
          setRows(
            response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries?.timeEntries
          );
          setTotalDuration(
            response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries
              ?.totalDuration
          );
        }
      );
    },
    []
  );

  const records =
    data &&
    data.GetTimeSheetTimeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries.timeEntries
      ? data.GetTimeSheetTimeEntries.timeSheetTimeEntries.timeEntries
      : [];

  const numberRecords =
    data &&
    data.GetTimeSheetTimeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries.timeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries.totalNumberOfRecords
      ? data.GetTimeSheetTimeEntries.timeSheetTimeEntries.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (!columnsWait && !employeeWait && timesheetObj)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [timesheetObj, employeeWait, columnsWait]);

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            timeEntryGuids: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("company.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);
        refetchTimeSheet();
      }
    );
  };
  const handleRemove = () => {
    deleteTimesheetRecord(
      {
        variables: {
          timesheetGuid: timeSheetGuid.current,
        },
      },
      (response) => {
        enqueueSnackbar(t("timesheet.list.delete.success"), {
          variant: "success",
        });
        setTimesheetObj(null);
        resetList();
      }
    );
  };

  const handleApprove = () => {
    approveTimesheet(
      {
        variables: {
          model: {
            approved: true,
            timeSheetGuid: timeSheetGuid.current,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("timesheet.approval.success"), {
          variant: "success",
        });
        setTotalDuration(0);
        setRows([]);
        setTimesheetObj(null);
        timeSheetGuid.current = null;
      }
    );
  };

  const handleRefresh = () => {
    fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
    resetList();
  };

  const customButtons = [
    {
      label: "timesheet.btn.refresh.label",
      handleClick: () => handleRefresh(),
      condition: true,
      icon: true,
      iconButton: <RefreshIcon color="#000000" />,
    },
    {
      label: "timesheet.btn.approve.label",
      handleClick: () => handleOpenDeleteConfirmationModal(false),
      icon: false,
      condition:
        (!selected || selected.length === 0) && manageTimeEntry === "M",
      disabled:
        timeSheetGuid.current == null ||
        records.some((row) => row.automaticallyClosed === true) ||
        numberRecords === 0,
    },
    {
      label: "timesheet.btn.add.label",
      handleClick: () => {
        setResetFields(!resetFields);
        setEditMode(false);
        setClicked(true);
      },
      disabled: timeSheetGuid.current == null,
      condition:
        (!selected || selected.length === 0) && manageTimeEntry === "M",
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color:
              timeSheetGuid.current == null
                ? "rgba(0, 0, 0, 0.22)"
                : "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
    {
      label: "timesheet.btn.remove.label",
      handleClick: () => handleOpenDeleteConfirmationModal(true),
      condition: !rows || rows.length === 0,
      disabled: timeSheetGuid.current == null,
      icon: true,
      iconButton: (
        <DeleteIcon color={ timeSheetGuid.current == null ? "rgba(0, 0, 0, 0.22)" : redValue} />
      ),
    },
  ];

  const handleOpenModal = (row) => {
    setTaskID(row?.taskGuid);
    setProjectID(row?.projectGuid);
    setTaskName(row?.task);
    setProjectName(row?.project);
    setOpenModal(true);
  };

  const showCustomDiv = () => {
    return <span style={{fontSize: "14px", width: "10vw", display: "flex"}}>{t("timesheet.total.duration")} {" "} {totalDuration.toFixed(2)}</span>
  }

  const pagination = {
    rows: records,
    setRows,
    columns,
    filtersObject,
    defaultFilters,
    setDefaultFilters,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    search,
    hideFilters: timeSheetGuid.current == null ? true : false,
    refetch: refetchColumns,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    deleteText: "timesheet.list.delete.label",
    hideSearchBar: true,
    hideRefreshButton: true,
    hideAddButton: true,
    fetchTableData,
    loading: loading || getColumnsLoading,
    searchText: "timesheet.list.search.label",
    tableMnemonic: Mnemonic.Tables["TimesheetList"],
    readOnly: false || manageTimeEntry !== "M",
    // hideEditColumns: true,
    removeRefresh: true,
    handleDelete: handleDelete,
    customButtons,
    keepFilters: true,
    linkCell,
    handleOpenModal,
    showCustomDiv,
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  return (
    <div>
      <ConfirmationModal
        contentText={
          removeSheet
            ? t("dialog.user.delete.sheet.confirmation")
            : syncTimeEntry === true && allowTimesheetReopening === false
            ? t("dialog.user.sheet.approve.sync.time.entry.true.confirmation")
            : syncTimeEntry === false && allowTimesheetReopening === false
            ? t(
                "dialog.user.sheet.approve.sync.time.entry.false.allow.sheet.reopening.false.confirmation"
              )
            : t("dialog.user.sheet.approve.confirmation")
        }
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        taskName={taskName}
        refetch={refetchTimeSheet}
        fromProject={true}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />
      {timesheetListLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        !timesheetListLoading && (
          <>
            <TimeEntryDrawer
              PaperComponent={PaperComponent}
              openTimeEntryModal={clicked}
              setOpenTimeEntryModal={(v) => setClicked(setClicked)}
              timeSheetGuid={timeSheetGuid.current}
              timeEntryGuid={timeEntryGuid}
              description={timeEntry?.description}
              synced={manageTimeEntry !== "M" ? true : timeEntry?.synced}
              refetchData={refetchTimeSheet}
              editMode={editMode}
              handleEditMode={(v) => setEditMode(v)}
              handleChangeTimeEntryGuid={(v) => setTimeEntryGuid(v)}
              employees={employeeList}
              resetFields={resetFields}
              fromTimesheet={true}
              tasks={task_list}
              group={true}
              t={t}
            />
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: "10px", display: "flex", alignItems: "end" }}
            >
              <Tooltip title={t("timesheets.tooltip.timesheet")}>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                  <AutoComplete
                    options={timesheets_list}
                    getOptionLabel={(option) =>
                      (option
                        ? option.workShift.description +
                          "  ( " +
                          UTC2Local(option.startDate, true) +
                          "   —   " +
                          UTC2Local(option.endDate, true)
                        : "") + "  ) "
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.workShift.description +
                          "  ( " +
                          UTC2Local(option.startDate, true) +
                          "   —   " +
                          UTC2Local(option.endDate, true)}
                      </li>
                    )}
                    identifier={"timeSheetGuid"}
                    value={timesheetObj}
                    onChange={(e, value) => {
                      setSelected([]);
                      setTimesheetObj(value);
                      timeSheetGuid.current = value?.timeSheetGuid;
                      if (value === null) setRows([]);
                      setPage(0);
                    }}
                    label={t("timesheet.autoComplete.label")}
                    loading={timesheetListLoading}
                  />
                </Grid>
              </Tooltip>
              {/* <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={4}
                xl={4}
                style={{ paddingLeft: "10px" }}
              >
                {t("timesheet.total.hours")} {totalDuration.toFixed(2)}
              </Grid> */}
            </Grid>

            <Box
              sx={{
                marginTop: {
                  xs: "0px !important",
                  sm: "0px !important",
                  md: "0px !important",
                  lg: "0px !important",
                  xl: "0px !important",
                },
              }}
            >
              <DataTable
                {...pagination}
                identifier="timeEntryGuid"
                hasCheckbox
                fromTimesheets
                rows={rows}
                heightClassName="table-sheet-height"
                isFixed
              />
            </Box>
          </>
        )
      )}
    </div>
  );
};

export default TimesheetListPending;
