import { gql } from "@apollo/client";

export const GET_ALL_LOCATIONS = gql`
  query ($model: GetAllLocationsRequestModelInput!) {
    GetAllLocations(model: $model) {
      locationsList {
        locations {
          locationGuid
          country {
            name
          }
          name
          latitude
          longitude
          unitOfMeasure {
            description
          }
          tenant {
            name
          }
          isOnSite
          radius
          address
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_LOCATIONS_DROPDOWN = gql`
  query ($model: GetAllLocationsRequestModelInput!) {
    GetAllLocations(model: $model) {
      locationsList {
        locations {
          locationGuid
          name
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_LOCATIONS = gql`
  mutation ($model: DeleteLocationsRequestModelInput!) {
    DeleteLocations(model: $model) {
      locationDeleteResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query ($id: UUID!) {
    GetLocationByID(locationID: $id) {
      location {
        locationGuid
        tenant {
          tenantGuid
          name
        }
        country {
          countryGuid
          name
        }
        unitOfMeasure {
          unitOfMeasureGuid
          description
        }
        latitude
        longitude
        address
        radius
        name
        isOnSite
        creationDate
        creatorEmpolyee {
          employeeGuid
          email
        }
        modificationDate
        modifiedEmployee {
          employeeGuid
          email
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation ($model: CreateLocationRequestModelInput!) {
    CreateLocation(model: $model) {
      location {
        locationGuid
        name
        latitude
        longitude
        isOnSite
        radius
        address
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation ($model: UpdateLocationsRequestModelInput!) {
    UpdateLocation(model: $model) {
      location {
        locationGuid
        modificationDate
        modifiedEmployee {
          employeeGuid
          email
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_COUNTRIES = gql`
  query {
    GetAllCountries {
      countries {
        countryGuid
        name
        phoneCode
        timezone {
          timezoneGuid
          value
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_UNITS_OF_MEASURE = gql`
  query {
    GetAllUnitsOfMeasure {
      unitsOfMeasure {
        unitOfMeasureGuid
        description
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
