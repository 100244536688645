import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { employee, task, date, duration, description, minutes, hours, project, billable },
} = checkout;

export const getValidation = (isTimeEntryDescriptionMandatory, isProjectRequired) => {
  return [
    Yup.object().shape({
      [employee.name]: Yup.object().required(employee.requiredMessage),
      [task.name]: Yup.object().required(task.requiredMessage),
      [project.name]: isProjectRequired ? Yup.object().required(project.requiredMessage) : Yup.object().nullable(),
      [date.name]: Yup.date().required(date.requiredMessage),
      [billable.name]: Yup.bool().required(billable.requiredMessage),
      [duration.name]: Yup.number().required(duration.requiredMessage),
      [minutes.name]: Yup.number().required(minutes.requiredMessage).max(60, "Minutes must be less than or equal to 60"),
      [hours.name]: Yup.number()
      .required(hours.requiredMessage),
      // .max(24, "Hours must be less than or equal to 24"),
      [description.name]: isTimeEntryDescriptionMandatory
      ? Yup.string().required(description.requiredMessage)
      : Yup.string(),
    }),
  ];
};
