import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_MIDDLEWARE_JOBS } from "../../../../graphql/middlewareJobs";
//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress, Dialog, Grid, IconButton } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import {
  DELETE_SUGGESTIONS,
  GET_ALL_SUGGESTIONS_LIST,
  GET_SUGGESTIONS_STATUSES,
} from "../../../../graphql/suggestions";
import { GET_ALL_TENANTS } from "../../../../graphql/tenant";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import SuggestionBox from "../../../admin-configuration/Suggestions/suggestion-form";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import ConfirmationPopup from "../../../../components/Popup";
import { CloseIcon } from "../../../../components/Icon";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const SuggestionsList = ({ refetchTable = false, fromTenant = false }) => {
  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();
  const { isEntelligence } = userRoles;
  const [suggGuid, setSuggGuid] = useState("");
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    setSuggGuid(null);
    setDisplayModal(false);
  }

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_SUGGESTIONS,
    "DeleteSuggestions"
  );

  const {
    data: tenantsData,
    loading: tenantsLoading,
    tokenChange: tenantsWait,
  } = useQueryAuth(GET_ALL_TENANTS, "GetAllTenants", {
    variables: {
      model: {
        enableFilters: false,
      },
    },
  });

  const tenantsList = tenantsData?.GetAllTenants?.tenantsList?.tenants;

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.SuggestionsList,
          userID: storeUserGuid,
        },
      },
      skip: tenantsWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);

          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          const cols = parsed.columns;
          
          if (cols) {
            cols.map(col => {
              col = removeNullProps(col);
            });
            const filteredColumns = cols.filter((c) => c.id !== "Tenant");

            if (!isEntelligence) {
              setColumns(filteredColumns);
            } else setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const {
    data: statusesData,
    loading: suggestionStatusLoading,
    tokenChange: statusWait,
  } = useQueryAuth(GET_SUGGESTIONS_STATUSES, "GetAllSuggestionStatuses", {
    skip: tenantsWait || columnsWait,
    isWait: true,
  });

  const statuses_list =
    statusesData?.GetAllSuggestionStatuses?.suggestionStatuses || [];

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      skip: tenantsWait || statusWait || columnsWait,
      isWait: true,
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: !isEntelligence ? storeUserTenant : null,
        showAll: true,
        all: true,
      },
    },
  });

  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const filtersObject = !isEntelligence
    ? [
        {
          type: "multiple",
          label: "suggs.list.filters.suggsStatus",
          name: "statusesIDs",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: statuses_list,
          access: "suggestionStatusGuid",
          loading: suggestionStatusLoading,
        },
        {
          label: "logs.list.filters.employee",
          name: "employeesIDs",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeesList,
          access: "employeeGuid",
          loading: employeeLoading,
        },
        {
          type: "date",
          label: "suggs.list.filters.creationDate",
          name: "creationDate",
        },
      ]
    : [
        {
          label: "logs.list.filters.tenants",
          name: "tenantsIDs",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.name : ""),
          options: tenantsList,
          access: "tenantGuid",
          loading: tenantsLoading,
        },
        {
          label: "logs.list.filters.employee",
          name: "employeesIDs",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeesList,
          access: "employeeGuid",
          loading: employeeLoading,
        },
        {
          type: "multiple",
          label: "suggs.list.filters.suggsStatus",
          name: "statusesIDs",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: statuses_list,
          access: "suggestionStatusGuid",
          loading: suggestionStatusLoading,
        },
        {
          type: "date",
          label: "suggs.list.filters.creationDate",
          name: "creationDate",
        },
      ];

  const {
    mfunction: GetAllSuggestionsList,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ALL_SUGGESTIONS_LIST, "GetAllSuggestionsList");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      GetAllSuggestionsList(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              tenantGuid: null,

              enableFilters: true,
              filters: filters,
              keyword: search,

              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData]);

  useEffect(() => {
    if (!columnsWait) refetchData();
  }, [refetchTable]);

  const handleOpenModal = (row) => {
    setDisplayModal(true);
    setSuggGuid(row?.suggestion?.suggestionGuid);
  };

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            suggestionsIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("suggs.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);
        fetchTableData(0, rowsPerPage, order, orderBy, search, defaultFilters);
      }
    );
  };

  const rows =
    data &&
    data.GetAllSuggestionsList &&
    data.GetAllSuggestionsList.suggestionsListResponse &&
    data.GetAllSuggestionsList.suggestionsListResponse.suggestionsList
      ? data.GetAllSuggestionsList.suggestionsListResponse.suggestionsList
      : [];

  const numberOfRecords =
    data &&
    data.GetAllSuggestionsList &&
    data.GetAllSuggestionsList.suggestionsListResponse &&
    data.GetAllSuggestionsList.suggestionsListResponse.totalNumberOfRecords
      ? data.GetAllSuggestionsList.suggestionsListResponse.totalNumberOfRecords
      : 0;

  const customButton = [
    {
      label: "suggs.addNew",
      handleClick: () => setDisplayModal(true),
      condition: !selected || selected.length === 0,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    filtersObject,
    loading: loading || columnsWait,
    searchText: "suggs.list.search.label",
    refetch,
    handleDelete,
    customButtons: !isEntelligence && customButton,
    deleteText: "suggs.list.delete.label",
    tableMnemonic: Mnemonic.Tables.SuggestionsList,
    handleOpenModal,
  };

  return (
    <div>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      {displayModal && (
        <Dialog
          open={displayModal}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClose={() => {
            if(JSON.parse(sessionStorage.getItem("unsavedChanges")) == true) {
              setOpenDialog(true) 
            }
            else {
              setSuggGuid(null);
              setDisplayModal(false);
              refetchData();
            }
          }}
          sx={{
            ".Mui-container": { alignItems: "center !important" },
            ".MuiDialog-container .MuiPaper-root": {
              maxWidth: "800px !important",
              minWidth: "800px !important",
            },
          }}
        >
          <Grid
            id="draggable-dialog-title"
            item
            sm={12}
            lg={12}
            md={12}
            xs={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if(JSON.parse(sessionStorage.getItem("unsavedChanges")) == true) {
                  setOpenDialog(true) 
                }
                else {
                  setSuggGuid(null);
                  setDisplayModal(false);
                  refetchData();
                }
              }}
              sx={{ fontSize: "14px !important" }}
            >
              <CloseIcon color="#9e9e9e" strokeWidth="1" width="24" height="24" />
            </IconButton>
          </Grid>
          <SuggestionBox
            key={suggGuid}
            handleCloseModal={(open) => {
              if(JSON.parse(sessionStorage.getItem("unsavedChanges")) == true && (open === null || open === undefined)) {
                setOpenDialog(true) 
              }
              else {
                setSuggGuid(null);
                setDisplayModal(false);
              }
            }}
            refetchData={refetchData}
            suggGuid={suggGuid}
          />
        </Dialog>
      )}
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"suggestion.suggestionGuid"}
          hasCheckbox={isEntelligence ? true : false}
        />
      )}
    </div>
  );
};

export default SuggestionsList;
