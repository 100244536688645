import { React, useRef } from "react";
//react router dom
import { useParams } from "react-router-dom";

//components
import { Grid, Box } from "@mui/material";
import { Subtitle, Title } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";
import TextField from "../../../../../components/TextFields/TextField";

//graphql
import { GET_ALL_HELP } from "../../../../../graphql/help";

import Mnemonics from "../../../../../Mnemonics.json";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";

//translation
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css';

import "./style.css"

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData, setHelpGuid }) => {
  const { t } = useTranslation();

  const quillRef = useRef();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    wait,
    readOnly,
  } = formData;

  const { storeUserGuid } = useAuth();

  const {
    title,
    description,
  } = formField;

  const {
    title: titleV,
    description: descriptionV,
  } = values;

  const handleQuillChange = (html) => {
    setFieldValue(description.name, html);
  };

  const modules = {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [({ direction: "" }, { direction: "rtl" })],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        // ["hight"],
        ["clean"],
        ["image"],
        ["link"],
      ],

      clipboard: {
        matchVisual: false,
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    },
  };

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
  ];

  const { data: helpList, loading: helpLoading } = useQueryAuth(
    GET_ALL_HELP,
    "GetAllHelps",
    {
      skip: wait,
      wait: true,
    }
  );

  const help_list =
    helpList &&
    helpList.GetAllHelps &&
    helpList.GetAllHelps.helpsList &&
    helpList.GetAllHelps.helpsList.helps
      ? helpList.GetAllHelps.helpsList.helps
      : [];

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title style={{ padding: "10px 0" }}>
            {t("helpForm.helpInformation.title")}
          </Title>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AutoComplete
            options={help_list}
            getOptionLabel={(option) => (option ? option.title : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.title}
              </li>
            )}
            identifier={"helpGuid"}
            value={titleV}
            onChange={(e, value) => {
              setFieldValue(title.name, value);
              setHelpGuid(value?.helpGuid);
              setFieldValue(description.name, value?.description);
            }}
            loading={helpLoading}
            required
            name={title.name}
            fullWidth
            label={t(title.label)}
            size="small"
            roundedTextField
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          
        <ReactQuill
          ref={quillRef}
          className="help-quill"
          name="body"
          value={descriptionV}
          onChange={handleQuillChange}
          modules={modules}
          formats={formats}
          placeholder="Description"
        />
        </Grid>

      </Grid>
    </Box>
  );
};

export default Main;
