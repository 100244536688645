import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";
import SideInfo from "./components/sideInfo";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useAuth from "../../../../hooks/useAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";

//graphql
import { GET_PTO_BY_ID, CREATE_PTO, UPDATE_PTO } from "../../../../graphql/pto";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import Help from "./components/_help";
import { create } from "@mui/material/styles/createTransitions";

const PTOForm = ({ fromTenant = false }) => {
  const { tName, tID, ptoName, ptoID } = useParams();

  const createMode = !ptoName && !ptoID;

  const { tenant_ptos, my_ptos } = urls;

  const { userRoles, storeUserTenant } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [sideInfoData, setSideInfoData] = useState({});

  const [ptoStatus, setPTOStatus] = useState(null);

  const [editable, setEditable] = useState(null);

  const {
    description,
    paid,
    accrued,
    accumulated,
    numberOfDays,
    employmentType,
    assignDate,
    seniorities,
    From,
    To,
    NewNumberOfDays,
    dateUnit,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading, tokenChange: wait } = useQueryAuth(
    GET_PTO_BY_ID,
    "GetPTOByID",
    {
      variables: { id: ptoID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetPTOByID?.ptoView?.pto || {};

        const seniorityArray = data.seniorities
          ? data.seniorities.map((val, index) => {
              let tmp = {
                ...val,
                dateUnitGuid: val.dateUnit,
              };

              delete tmp.dateUnit;
              return tmp;
            })
          : [];

        setFormData({
          [description.name]: data?.description || "",
          [paid.name]: data?.paid || false,
          [accrued.name]: data?.accrued || false,
          [accumulated.name]: data?.accumulated || false,
          [dateUnit.name]: data?.dateUnit || null,
          [numberOfDays.name]: data?.numberOfUnits || 0,
          [employmentType.name]: data?.employmentType || null,
          [assignDate.name]: data?.ptoAssignDate || null,
          [seniorities.name]: seniorityArray || [],
        });

        setPTOStatus(data?.ptoStatus || null);
        setEditable(response?.GetPTOByID?.ptoView?.editable);
      },
    }
  );

  const { cfunction: editPTOData } = useMutationAuth(UPDATE_PTO, "UpdatePTO");

  const { cfunction: createPTOData } = useMutationAuth(CREATE_PTO, "CreatePTO");

  function createPTO(values, actions) {
    const seniorityArray = values[seniorities.name]
      ? values[seniorities.name].map((val, index) => {
          return {
            from: val.from ? parseFloat(val.from) : parseFloat(0),
            to: parseFloat(val.to),
            numberOfUnits: parseInt(val.numberOfUnits),
          };
        })
      : [];

    createPTOData(
      {
        variables: {
          model: {
            description: values[description.name],
            paid: values[paid.name],
            accrued: values[accrued.name],
            accumulated: values[accumulated.name],
            numberOfUnits:
              seniorityArray.length === 0 && values[paid.name]
                ? parseInt(values[numberOfDays.name])
                : 0,
            employmentTypeGuid: values[employmentType.name]?.employmentTypeGuid,
            ptoAssignDateGuid: values[assignDate.name]?.ptoAssignDateGuid,
            dateUnitGuid: values[dateUnit.name]?.dateUnitGuid,
            // active: values[status.name].mnemonic === "active" ? true : false,
            tenantGuid: fromTenant ? tID : storeUserTenant,
            seniorityCreateRequests: seniorityArray,
          },
        },
      },
      (response) => {
        if (
          response["CreatePTO"].errorCode !== null &&
          response["CreatePTO"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreatePTO"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("ptoForm.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(
            fromTenant
              ? tenant_ptos.replace(
                  "/:tName/:tID",
                  `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                )
              : my_ptos
          );
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editPTO(values, actions) {
    const seniorityArray = values[seniorities.name]
      ? values[seniorities.name].map((val, index) => {
          return {
            ...val,
            from: val.from ? parseFloat(val.from) : parseFloat(0),
            to: parseFloat(val.to),
            numberOfUnits: parseInt(val.numberOfUnits),
          };
        })
      : [];

    editPTOData(
      {
        variables: {
          model: {
            ptoGuid: ptoID,
            description: values[description.name],
            paid: values[paid.name],
            accrued: values[accrued.name],
            accumulated: values[accumulated.name],
            numberOfUnits:
              seniorityArray.length === 0 && values[paid.name]
                ? parseInt(values[numberOfDays.name])
                : 0,
            employmentTypeGuid: values[employmentType.name].employmentTypeGuid,
            ptoAssignDateGuid: values[assignDate.name].ptoAssignDateGuid,
            dateUnitGuid: values[dateUnit.name]?.dateUnitGuid,
            // active: values[status.name].mnemonic === "active" ? true : false,
            tenantGuid: fromTenant ? tID : storeUserTenant,
            ptoSeniorityUpdateRequests: seniorityArray,
          },
        },
      },
      (response) => {
        if (
          response["UpdatePTO"].errorCode !== null &&
          response["UpdatePTO"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdatePTO"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("ptoForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createPTO(values, actions);
    } else {
      editPTO(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched)
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              fromTenant,
                              wait,
                              readOnly:
                                (manageSystemConfigurations !== "M"
                                || !editable)
                                && !createMode,
                              ptoStatus,
                              setPTOStatus,
                            }}
                          />
                        </Box>
                        {((editable && !createMode) || createMode) && (
                          <Box
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              disabled={
                                isSubmitting ||
                                manageSystemConfigurations !== "M"
                              }
                              loading={isSubmitting}
                              type="submit"
                              label={t("ptoForm.save")}
                            />
                          </Box>
                        )}
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <ContainerCard>
                <Box p={2}>
                  <Help />
                </Box>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PTOForm;
