import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Card, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import urls from "../../../../urls";
import {
  CREATE_NEWS,
  GET_NEWS_BY_ID,
  UPDATE_NEWS,
} from "../../../../graphql/news";
import hasEmptyTags from "../../../../utils/hasEmptyTags";

const NewsForm = ({ handleCloseModal, fromDetails, fromNav }) => {
  const { nName, nID } = useParams();

  const createMode = (!nName && !nID) || fromNav;

  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const {
    manageParameters: manageParametersRole,
    isEntelligence,
    isAdmin,
  } = userRoles;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const { description } = formField;

  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState(initialValues);

  const [mediaList, setMediaList] = useState([]);

  const { tenant_news_list, news_list } = urls;
  const [whatField, setWhatField] = useState(null);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_NEWS_BY_ID,
    "GetNewsByID",
    {
      variables: { newsGuid: nID },
      skip: createMode,
      isWait: !createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetNewsByID?.news || {};

        setFormData({
          [description.name]: data?.description || "",
        });
      },
    }
  );
  const { cfunction: editNewsData } = useMutationAuth(
    UPDATE_NEWS,
    "UpdateNews"
  );

  const { cfunction: CreateNewsData } = useMutationAuth(
    CREATE_NEWS,
    "CreateNews"
  );

  function CreateNews(values, actions) {
    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    CreateNewsData(
      {
        variables: {
          model: {
            description: updatedDescription,
            createdByGuid: storeUserGuid,
            tenantGuid: storeUserTenant,
            media: mediaList,
          },
        },
      },
      (response) => {
        if (
          response["CreateNews"].errorCode !== null &&
          response["CreateNews"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateNews"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("news.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(isEntelligence ? tenant_news_list : news_list);
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editNews(values, actions) {
    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    editNewsData(
      {
        variables: {
          model: {
            newsGuid: nID,
            description: updatedDescription,
          },
        },
      },
      (response) => {
        if (
          response["UpdateNews"].errorCode !== null &&
          response["UpdateNews"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateNews"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("news.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(isEntelligence ? tenant_news_list : news_list);
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    if (createMode) {
      CreateNews(values, actions);
    } else {
      editNews(values, actions);
    }
  };

  const handleChangeFlag = (touched) => {
    if (touched) sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%" }}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={fromDetails ? 10 : 12}
              lg={fromDetails ? 10 : 12}
            >
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        handleChangeFlag(touched);
                      }}
                    >
                      <Box p={fromDetails ? 3 : 0}>
                        <Box>
                          <Main
                            getDataByIDLoading={getDataByIDLoading}
                            formData={{
                              values,
                              fromDetails,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              handleCloseModal,
                              whatField,
                              setWhatField,
                              readOnly: manageParametersRole === "R",
                            }}
                            handleChangeFlag={handleChangeFlag}
                            setMediaList={setMediaList}
                            mediaList={mediaList}
                            newsID={nID}
                          />
                        </Box>
                        {(isEntelligence || isAdmin) && (
                          <Box
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              loading={isSubmitting}
                              type="submit"
                              label={t("sugg.submit")}
                            />
                          </Box>
                        )}
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default NewsForm;
