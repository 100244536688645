import React from "react";

//external component
import { Card } from "@mui/material";

const ContainerCard = (props) => {

  return (
    <Card
      sx={{
        ...props.sx,
        borderRadius: "0.25rem",
      }}
      {...props}
    >
      {props.children}
    </Card>
  );
};

export default ContainerCard;
