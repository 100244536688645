import PropTypes from "prop-types";
import Tooltip from "../Tooltip";

const LongText = ({ text, length }) => {
  if (!text) return null;

  const len = text.length;
  if (length != null && length == 1 && len > 30)
    return <Tooltip title={text}>{text}</Tooltip>;
  else if (length != null && length == 1 && len < 30) return text;
  else if (length != null && len > length)
    return <Tooltip title={text}>{text.substring(0, length) + "..."}</Tooltip>;

  return len > 30 && !length ? (
    <Tooltip title={text}>{text.substring(0, 30) + "..."}</Tooltip>
  ) : (
    text
  );
};

export default LongText;

LongText.propTypes = {
  text: PropTypes.string,
};

LongText.defaultProps = {
  text: "",
};
