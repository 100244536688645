import React from "react";

//components
import Items from "./items";

import useAuth from "../../hooks/useAuth";

const List = ({ list = [], miniSideNav = false, collapse = false }) => {
  const { userRoles } = useAuth();
  const [closeList, setCloseList] = React.useState(false);

  const renderNavItems = (items, close = false) => {

    if (Array.isArray(items)) {
      return items.map((item) => renderNavItems(item)); //if array we access the objects inside
    }
    else if (items.type === "nested" && !items.display) {
      return renderNavItems(items.routes_inside); //if type authentication or nested we go for the routes inside
    } else if (items.type === "authentication") {
      if (
        userRoles &&
        (items.display === undefined ||
          (items.display !== undefined && userRoles[items.display] !== "N")) &&
        (items.isAdmin === undefined ||
          (items.isAdmin !== undefined &&
            userRoles.isEntelligence === items.isAdmin))
      ) {
        return renderNavItems(items.routes_inside); //if type authentication or nested we go for the routes inside
      } else {
        return;
      }
    } else if (!items.display) {
      return; //if we don't wish to display it then we skip it
    }

    return <Items key={items.name} {...items} collapse={collapse} miniSideNav={miniSideNav} handleCloseList={(v) => setCloseList(v)} closeList={closeList}/>; //display
  };
  
  return (
    <ul className={miniSideNav ? `menu-list-mini${collapse ? "-collapse" : ""}` : `menu-list${collapse ? "-collapse" : ""}`}>
      {renderNavItems(list)}
    </ul>
  );
};

export default List;
