import React from "react";

import EmployeeDaysUsedList from "../EmployeeDaysUsed-list";

const DaysUsedHistory = ({ fromTenant = false, fromProfile = false }) => {
  return (
    <div>
      <EmployeeDaysUsedList fromTenant={fromTenant} fromProfile={fromProfile} />
    </div>
  );
};

export default DaysUsedHistory;
