import * as React from "react";
import IconButton from "@mui/material/IconButton";

import colors from "../../assets/theme/light/colors";
import { NotificationsIcon } from "../Icon";

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

export default function AccessibleBadges({ onClick, notificationCount }) {
  const { red_color, icon_button_grey } = colors;

  return (
    <IconButton
      aria-label={notificationsLabel(notificationCount)}
      onClick={onClick}
      sx={{ "&:hover": { backgroundColor: "transparent" } }}
    >
      <NotificationsIcon color={"#F2F1FB"} secondColor={icon_button_grey} thirdColor={red_color} show={notificationCount > 0} />
    </IconButton>
  );
}
