import React from 'react';
import { Paper } from '@mui/material'
import colors from '../../assets/theme/light/colors';

function AutoCompletePaper(props) {
    return ( 
        <Paper
            sx={{
                boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            }}
            {...props}
        />
     );
}

export default AutoCompletePaper;