import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

//Exterior Components
import { Typography } from "@mui/material";
import OtpInput from "react18-input-otp";

//colors
import colorLight from "../../assets/theme/light/colors";

//links
import urls from "../../urls";

//translations
import { useTranslation } from "react-i18next";

//hooks
import useMutationAuth from "../../hooks/useMutationAuth";
import useAuth from "../../hooks/useAuth";

//graphql
import { USER_OTP_CODE, RESEND_OTP_CODE } from "../../graphql/user";

//redux
import { modifyOTPCode } from "../../app/reducers/userSlice";
import { useDispatch } from "react-redux";

//snackbar
import { useSnackbar } from "notistack";

import { LOGOUT_USER } from "../../graphql/user";

const OTP = () => {
  // Snackbar hook
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    storeUserEmail,
    jwtToken,
    storeUserMicroID,
    logout,
    storeUserTenant,
  } = useAuth();

  const { pure_white, error, light_divider_color } = colorLight; //colors

  const { default: default_url } = urls; //urls

  const [otp, setOtp] = useState(""); //holds otp value

  const { t } = useTranslation();

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const handleOTP = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const { cfunction: sendOTP } = useMutationAuth(
    USER_OTP_CODE,
    "VerifyOTPSMS"
  );

  const { mfunction: resendOTP } = useMutationAuth(
    RESEND_OTP_CODE,
    "ResendOTPCodeSMS"
  );

  const handleResendOTP = () => {
    resendOTP(
      {
        variables: {
          model: {
            email: storeUserEmail,
            tenantID: storeUserTenant,
          },
        },
      },
      () => {
        enqueueSnackbar(t("user.resentSuccessfully"), {
          variant: "success",
        });
        setOtp("");
      },
      () => {
        setOtp("");
      }
    );
  };

  const logUserOut = () => {
    logsOutUser(
      {
        variables: {
          model: {
            jwtToken: jwtToken,
          },
        },
      },
      () => {
        logout();
      }
    );
  };

  const handleCheckOtpCode = () => {
    sendOTP(
      {
        variables: {
          model: {
            id: storeUserMicroID,
            code: otp,
            fromWeb: true
          },
        },
      },
      (data) => {
        if (
          data["VerifyOTPSMS"].errorCode !== null &&
          data["VerifyOTPSMS"].errorCode !== ""
        ) {
          enqueueSnackbar(t(data["VerifyOTPSMS"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          setOtp("");
        } else {
          dispatch(modifyOTPCode());
          navigate(default_url);
        }
      },
      (error) => {
        setOtp("");
      }
    );
  };

  useEffect(() => {
    if (otp && otp.length >= 6) {
      handleCheckOtpCode();
    }
  }, [otp]);

  return (
    <div>
      <div className="field-input">
        <div className="field-title" style={{ color: `${pure_white}` }}>
          <Typography variant="h6" component="h6">
            {t("verifyLogin.title")}
          </Typography>
          <Typography variant="caption" component="caption">
            {t("verifyLogin.text")}
          </Typography>
        </div>
      </div>
      <div className="otp-input">
        <OtpInput
          value={otp}
          onChange={handleOTP}
          numInputs={6}
          shouldAutoFocus
          focusStyle={{color:` ${pure_white}`}}
          className="otp-squares"
          separator={<span style={{color: light_divider_color}}>-</span>}
          inputStyle={{ fontSize: "2.1em",border:`1px solid  ${pure_white}`}}
          inputProps={{backgroundColor:`${pure_white} !important`}}
          isInputNum
          isDisabled={otp.length >= 6}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Typography
          variant="caption"
          component="caption"
          style={{ color: `${pure_white}` }}
        >
          {t("verifyLogin.didntReceiveCode")}
        </Typography>
        <Typography
          onClick={handleResendOTP}
          variant="caption"
          component="caption"
          sx={{
            color: `${pure_white}`,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {t("verifyLogin.resendCode")}
        </Typography>
        <Typography
          onClick={logUserOut}
          variant="caption"
          component="caption"
          sx={{
            color: `${error}`,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {t("verifyLogin.login")}
        </Typography>
      </div>
    </div>
  );
};

export default OTP;
