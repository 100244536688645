import React, { useState, useEffect, useCallback, useRef } from "react";

import { useNavigate, useLocation, useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";

//graphql
import {
  GET_ALL_TIMESHEETS,
  DELETE_TIMESHEET,
} from "../../../../graphql/timesheet";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";

import urls from "../../../../urls";

//external components
import { CircularProgress, Box,Grid } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import CustomButton from "../../../../components/CustomButton";
import { DeleteIcon, RefreshIcon } from "../../../../components/Icon";
import { LOGOUT_USER } from "../../../../graphql/user";
import * as FileSaver from "file-saver";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import { useDispatch } from "react-redux";
import colors from "../../../../assets/theme/light/colors";

import * as XLSX from "xlsx";
import apiCall from "../../../../hooks/useRest";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { removeNullProps } from "../../../../utils/removeNullProps";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
const backendURL = process.env.REACT_APP_REST_URL;

const MonthyTimesheet = () => {
  const navigate = useNavigate();

  const { my_created_timesheets, my_approved_timesheet_form } = urls;

  const {
    storeUserGuid,
    storeUserTenant,
    userRoles,
    syncTimeEntry,
    allowTimesheetReopening,
    logout
  } = useAuth();

  const { manageTimeEntry } = userRoles;

  
  const { t } = useTranslation();
  const { dark_logo_blue } = colors;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const redValue = "var(--color-error)";
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [linkCell, setLinkCell] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [taskID, setTaskID] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [removeSheet, setRemoveSheet] = useState(false);
  const type = useRef("task");


  const timeSheetGuid = React.useRef(null);
  const [exportLoading, setExportLoading] = useState(false);
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
   }  

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    // skip: !fetchProject,
    // isWait: fetchProject
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];
  
  const filtersObject = [
    {
      label: "timesheet.list.filters.projects",
      name: "projectIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: projectsList,
      access: "projectGuid",
      loading: projectLoading,
    },
    {
      type: "date",
      label: "timesheet.list.filters.dateFrom",
      name: "dateFrom",
    },
    {
      type: "date",
      label: "timesheet.list.filters.dateTo",
      name: "dateTo",
    },
  ];

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    handleRemove();
    timeSheetGuid.current = null;
    handleClose();
  };

  const handleOpenDeleteConfirmationModal = (flag) => {
    setRemoveSheet(flag);
    setOpenDialog(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { mfunction: deleteTimesheetRecord } = useMutationAuth(
    DELETE_TIMESHEET,
    "DeleteTimeSheet"
  );

  const {
    loading: getColumnsLoading,
    refetch: refetchColumns,
    tokenChange: columnsWait,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.MonthlyTimesheets,
          userID: storeUserGuid,
        },
      },
      skip: projectsWait,
      isWait: true
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, null, null, formatDateTimeToString)
          );

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);

          const colmns = parsed.columns;
          if (colmns) {
            colmns.forEach((col) => {
              col = removeNullProps(col);
              if (col.id === "date")
                return (col.renderCell = (row) => {
                  return formatDateTimeToString(row?.startTime, false);
                });
            });
            setLinkCell(colmns.some((col) => col.link === true));
            setColumns(colmns);
          }
        }
      },
    }
  );

  const {
    mfunction: getAllTimesheets,
    loading,
    refetch: refetchTimeSheet,
  } = useLazyQueryAuth(GET_ALL_TIMESHEETS, "GetTimeSheetList");
  
  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTimesheets(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              keyword: search,
              filters: filters,
              orderColumn: orderBy,
              orderDirection: order,
              tenantGuid: storeUserTenant,
              userID: storeUserGuid,
              monthly: true,
              pending: false,
            },
          },
          skip: timeSheetGuid.current == null,
          isWait: true,
        },
        (response) => {
          setData(response);
          setRows(
            response?.GetTimeSheetList?.timeSheetList?.timeSheets
          );
        }
      );
    },
    []
  );


  const records =
    data &&
    data.GetTimeSheetList &&
    data.GetTimeSheetList.timeSheetList &&
    data.GetTimeSheetList.timeSheetList.timeSheets
      ? data.GetTimeSheetList?.timeSheetList?.timeSheets
      : [];

    
  const numberRecords =
    data &&
    data.GetTimeSheetList &&
    data.GetTimeSheetList.timeSheetList &&
    data.GetTimeSheetList.timeSheetList.timeSheets &&
    data.GetTimeSheetList.timeSheetList.totalNumberOfRecords
      ? data.GetTimeSheetList.timeSheetList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait]);


  const handleRemove = () => {
    deleteTimesheetRecord(
      {
        variables: {
          timesheetGuid: selected[0],
        },
      },
      (response) => {
        enqueueSnackbar(t("timesheet.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        refetchTimeSheet();
      }
    );
  };

  const handleRefresh = () => {
    fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  };

  const customButtons = [
    {
      label: "timesheet.btn.refresh.label",
      handleClick: () => handleRefresh(),
      condition: true,
      icon: true,
      iconButton: <RefreshIcon color="#000000" />,
    },
    {
      label: "timesheet.btn.create.label",
      handleClick: () => {
        navigate(my_created_timesheets)
      },
      condition: manageTimeEntry === "M",
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
    {
      label: "timesheet.btn.remove.label",
      handleClick: () => handleOpenDeleteConfirmationModal(true),
      condition: selected && selected.length > 0 && !records.some((row) => row.timeSheetGuid === selected[0] && row.synced === true),
      icon: true,
      iconButton: (
        <DeleteIcon color={redValue} />
      ),
    },
  ];
  
  async function exportTimeToExcel() {
    setExportLoading(true);
   
    const res = await apiCall(
      backendURL + `/ExportExcel/exportMyApprovedTimeSheet`,
      "POST",
      records,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t,
      null,
      'blob'
    );

    if (res) {
      let fileName = "ApprovedTimeSheet"+Date.now()+".xlsx"; // Default file name

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blob = new Blob([res.data], { type: fileType });

      FileSaver.saveAs(blob, fileName);
      enqueueSnackbar(t("report.exportSuccessfully"), { variant: "success" });
      
    }
    setExportLoading(false);

  }
  const pagination = {
    rows: records,
    setRows,
    columns,
    defaultFilters,
    setDefaultFilters,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    search,
    filtersObject,
    // hideFilters: timeSheetGuid.current == null ? true : false,
    refetch: refetchColumns,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    // hideSearchBar: true,
    hideRefreshButton: true,
    hideAddButton: true,
    fetchTableData,
    loading: loading || getColumnsLoading,
    searchText: "timesheet.list.search.label",
    tableMnemonic: Mnemonic.Tables["MonthlyTimesheets"],
    readOnly: false || manageTimeEntry !== "M",
    // hideEditColumns: true,
    generateRoute: (row) => my_approved_timesheet_form.replace(
      "/:tName/:tID",
      `/${encodeURIComponent(row?.description)}/${encodeURIComponent(row?.timeSheetGuid)}`
    ),
    // removeFilters: true,
    removeRefresh: true,
    customButtons,
    keepFilters: true,
    linkCell,
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  return (
    <div>
      <ConfirmationModal
        contentText={
          removeSheet
            ? t("dialog.user.delete.sheet.confirmation")
            : syncTimeEntry === true && allowTimesheetReopening === false
            ? t("dialog.user.sheet.approve.sync.time.entry.true.confirmation")
            : syncTimeEntry === false && allowTimesheetReopening === false
            ? t(
                "dialog.user.sheet.approve.sync.time.entry.false.allow.sheet.reopening.false.confirmation"
              )
            : t("dialog.user.sheet.approve.confirmation")
        }
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customModalButtons}
        t={t}
      />
        {/* <Grid item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: "10px", display: "flex", alignItems: "end",direction:"rtl" }}>
                <IALoadingButton
                onClick={() => exportTimeToExcel()}
                loading={loading}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginRight: "10px",
                }}
                label={t("exportTimeSheet")}
                disabled={!rows || exportLoading ||rows.length<1}
              />
                </Grid> */}
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading}
        projectID={projectID}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        refetch={refetchTimeSheet}
        fromProject={true}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
         (
          <>

            <Box
              sx={{
                marginTop: {
                  xs: "0px !important",
                  sm: "0px !important",
                  md: "0px !important",
                  lg: "0px !important",
                  xl: "0px !important",
                },
              }}
            >
             
              <DataTable
                {...pagination}
                identifier="timeSheetGuid"
                hasCheckbox
                fromTimesheets
                rows={rows}
                heightClassName="table-height"
                isFixed
                singleSelect
              />
                 
            </Box>
          </>
        )
      )}
    </div>
  );
};

export default MonthyTimesheet;
