import React from "react";

import { UTC2Time } from "../UTC2Local";

import { useTranslation } from "react-i18next";

const RequestDateTimeHandler = (props) => {
  const { t } = useTranslation();

  const { dateFrom, dateTo, timeFrom, timeTo } = props;

  if (!dateFrom && !dateTo && !timeFrom && !timeTo) {
    return null;
  }

  if (!dateTo && dateFrom && timeFrom && timeTo) {
    return (
      <span>
        {UTC2Time(timeFrom)} - {UTC2Time(timeTo)}
      </span>
    );
  }

  if (dateFrom && !timeFrom && !timeTo) {
    return <span>{t("RequestDateTimeHandler.fullDay")}</span>;
  }

  return null;
};

export default RequestDateTimeHandler;
