import { gql } from "@apollo/client";

export const GET_ALL_TASK_COMMENTS = gql`
  query ($id: UUID!) {
    GetAllTaskComments(taskCommentGuid: $id) {
      taskCommentsList {
        taskCommentGuid
        description
        updatedAt
        createdAt
        employee {
          employeeGuid
          firstName
          lastName
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TASK_COMMENT_BY_ID = gql`
  query ($id: UUID!) {
    GetTaskComment(id: $id) {
      taskComment {
        taskCommentGuid
        description
        updatedAt
        createdAt
        employee {
          employeeGuid
          firstName
          lastName
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TASK_COMMENT = gql`
mutation($model:CreateTaskCommentRequestInput!){
  CreateTaskComment (model: $model){
    taskComment {
      taskCommentGuid
      description
      updatedAt
      createdAt
      employee {
        employeeGuid
        firstName
        lastName
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const EDIT_TASK_COMMENT = gql`
mutation($model:EditTaskCommentRequestInput!){
  UpdateTaskComment (model: $model){
    taskComment {
      taskCommentGuid
      description
      updatedAt
      createdAt
      employee {
        employeeGuid
        firstName
        lastName
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const DELETE_TASK_COMMENT = gql`
mutation ($model: DeleteTaskCommentRequestInput!) {
  DeleteTaskComment(model: $model) {
    crudTaskComment
    errorCode
    errorMessage
    errorVariables
  }
}
`;