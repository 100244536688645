import Authentication from "./pages/authentication";

import Authorization from "./authorization";

import urls from "./urls";

const routes = [
  {
    type: "authentication",
    name: "Login",
    path: urls.login,
    element: <Authorization type={["loggedIn"]} />,
    routes_inside: [
      {
        type: "route",
        name: "login-page",
        path: urls.login,
        element: <Authentication page="login" />,
      },
    ],
  },
  {
    type: "authentication",
    name: "Forgot Password",
    path: urls.forgot_password,
    element: <Authorization type={["loggedIn"]} />,
    routes_inside: [
      {
        type: "route",
        name: "forgot-password",
        path: urls.forgot_password,
        element: <Authentication page="forgot-password" />,
      },
    ],
  },
  {
    type: "authentication",
    name: "reset-pass",
    path: urls.reset_password,
    element: <Authorization type={["reset-password"]} />, 
    routes_inside: [
      {
        type: "route",
        name: "Reset Password",
        path: urls.reset_password,
        element: <Authentication page="reset-password" />,
      },
    ],
  },
  {
    type: "authentication",
    name: "otp-code",
    path: urls.verify_login,
    element: <Authorization type={["authorize","loggedIn"]} />,
    routes_inside: [
      {
        type: "route",
        name: "Otp Code",
        path: urls.verify_login,
        element: <Authentication page="otp" />,
      },
    ],
  },
  {
    type: "authentication",
    name: "subscription",
    path: urls.subscription,
    element: <Authorization type={["loggedIn", "subscription"]} />,
    routes_inside: [
      {
        type: "route",
        name: "subscription",
        path: urls.subscription,
        element: <Authentication page="subscription" />,
      },
    ],
  },
  {
    type: "authentication",
    name: "subscription",
    path: urls.subscription_success,
    element: <Authorization type={["loggedIn"]} />,
    routes_inside: [
      {
        type: "route",
        name: "subscription",
        path: urls.subscription_success,
        element: <Authentication page="subscription-success" />,
      },
    ],
  },
  {
    type: "authentication",
    name: "default-condition",
    path: urls.default,
    element: <Authorization type={["default"]} />,
    routes_inside: [
      {
        type: "route",
        name: "Default COndition",
        path: urls.default,
        element: <></>,
      },
    ],
  },
];

export default routes;