import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import {
  Grid,
  CircularProgress,
  Button,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { LOGOUT_USER } from "../../../../graphql/user";

import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import AutoComplete from "../../../../components/AutoComplete";
import axios from "axios";
import { EXPORT_TO_EXCEL } from "../../../../graphql/reports";
import {
  GET_ALL_TASKS_DROPDOWN,
  GET_TASKS_BY_PROJECT_DROPDOWN,
} from "../../../../graphql/task";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import urls from "../../../../urls";

import { useSnackbar } from "notistack";

import { getTable } from "./_table";

import colors from "../../../../assets/theme/light/colors";

import DataTable from "../../../../components/Tables/DataTable";
import { exportToExcel } from "../../../../utils/exportToExcel";
import { handleValidateDates } from "../../../../utils/functions";
import FieldModal from "../../../../components/Modal/FieldModal";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import { day_month_year } from "../../../../utils/timezones";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import Tooltip from "../../../../components/Tooltip";
import CustomButton from "../../../../components/CustomButton";
import { NextIcon, PreviousIcon } from "../../../../components/Icon";
import { DATE_FILTER_DATA, handleDateBackward, handleDateForward, handleTimeBetween } from "../../../../utils/period";
import * as FileSaver from "file-saver";
import DateTime from "../../../../components/DateTime";
import EditRecord from "../../Timesheets/Timesheets-Form/components/EditRecord";
import TimeEntryDrawer from "../../Timesheets/Timesheets-Form/components/TimeEntryDrawer";

import { v4 as uuidv4 } from "uuid";

function ExcelReport() {
  const { storeUserTenant, storeUserGuid, userRoles,jwtToken,logout,
    updateToken,  } = useAuth();
  const backendURL = process.env.REACT_APP_REST_URL;
  const { dark_logo_blue } = colors;

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const { enqueueSnackbar } = useSnackbar();

  const { manageTimeEntry } = userRoles;

  const [re, setRe] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [includeSubtasks, setIncludeSubtasks] = useState(false);

  const nowDate = new Date();

  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );
  const [exportOptions, setExportOptions] = useState([
    {
      id:1,
      title:t("reports.ByTaskByTimeEntry.label")
    },
    {
      id:2,
      title:t("reports.ByTask.label")
    },
    {
      id:3,
      title:t("reports.ByTaskByTimeEntryByResource.label")
    },
  ]);
  const [exportOption, setExportOption] = useState({
    id:1,
    title:t("reports.ByTaskByTimeEntry.label")
  });
  const [includeOptions, setIncludeOptions] = useState([
    {
      id:1,
      title:t("reports.BillableAndNonBillable.label")
    },
    {
      id:2,
      title:t("reports.onlyBillable.label")
    },
    {
      id:3,
      title:t("reports.onlyNonBillable.label")
    },
  ]);
  const [includeOption, setIncludeOption] = useState({
    id:1,
    title:t("reports.BillableAndNonBillable.label")
  });

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );

  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);

  const [projectValue, setProjectValue] = useState(null);

  const [taskIDs, setTaskIDs] = useState([]);
  const [taskValue, setTaskValue] = useState([]);

  const [timer, setTimer] = useState(null);
  const [data, setData] = useState([]);
  const [numOfRecords, setNumOfRecords] = useState(0);

  const [fetchTasks, setFetchTasks] = useState();
  const [fetchProjects, setFetchProjects] = useState();
  const [timeEntryGuid, setTimeEntryGuid] = React.useState(null);
  
  const [fileName, setFileName] = useState("");
  const [tasks_list, setList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [timeEntry, setTimeEntry] = React.useState(null);
  useEffect(() => {
    if (columns.length === 0) {
      const actions = ({ compProps }) => {
        let props = compProps;
        return (
          <EditRecord
            {...props}
            handleClick={(v) => setClicked(v)}
            handleRowSelect={(t) => setTimeEntry(t)}
            handleEditMode={(v) => setEditMode(v)}
            disabled={manageTimeEntry !== "M"}
          />
        );
      };
      const col = getTable(actions);

      if (col) {
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_TASKS_DROPDOWN, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: false,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: !fetchTasks,
    isWait: fetchTasks,
  });

  const {
    data: projectTasksList,
    loading: projectTaskLoading,
    tokenChange: projectTasksWait,
  } = useQueryAuth(GET_TASKS_BY_PROJECT_DROPDOWN, "GetTasksByProject", {
    variables: {
      model: {
        projectID: projectValue?.projectGuid,
        userID: storeUserGuid,
        activeTask: false,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: !projectValue,
    isWait: !projectValue,
  });

  useEffect(() => {
    setList(projectTasksList?.GetTasksByProject?.tasks);
  }, [projectTasksList]);

  useEffect(() => {
    setList(tasksList?.GetTasks?.tasks);
  }, [tasksList]);

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !fetchProjects,
    isWait: fetchProjects,
  });
  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
        showAll: true,
        timeEntryDisabled: true,
      },
    },
  });
  const employeesList =
  employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const task_list =
  tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
    ? tasksList.GetTasks.tasks
    : [];
  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const { mfunction: getData, loading: dataLoading,refetch: refetchData, } = useLazyQueryAuth(
    EXPORT_TO_EXCEL,
    "ProjectExportTimeEntries"
  );

  const fetchTableData = useCallback(
    (taskIDs, projectValue, dateFrom, dateTo, includeSubtasks) => {
      setSearchLoading(true)
      let onlyBillable=includeOption?.id==2
      let onlyNotBillable=includeOption?.id==3
      var model= {
        tasksIDs: taskIDs,
        projectID: projectValue?.projectGuid,
        dateFrom: new Date(dateFrom).toDateString(),
        dateTo: new Date(dateTo).toDateString(),
        format:
          day_month_year.includes(userTimezone) === true
            ? "dd-MM-yyyy"
            : "MM-dd-yyyy",
        includeSubtasks: includeSubtasks,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        onlyBillable:onlyBillable,
        onlyNotBillable:onlyNotBillable
      };
      
      getData(
        {
          variables: {
            model: {
              tasksIDs: taskIDs,
              projectID: projectValue?.projectGuid,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              format:
                day_month_year.includes(userTimezone) === true
                  ? "dd-MM-yyyy"
                  : "MM-dd-yyyy",
              includeSubtasks: includeSubtasks,
              tenantID: storeUserTenant,
              userID: storeUserGuid,
              onlyBillable:onlyBillable,
              onlyNotBillable:onlyNotBillable
            },
          },
        },
        (response) => {
          setSearchLoading(false)
          setData(
            response.ProjectExportTimeEntries.projectTimeEntriesListForExcel
              .timeEntries
          );
          setNumOfRecords(
            response.ProjectExportTimeEntries.projectTimeEntriesListForExcel
              .totalNumberOfRecords
          );
        }
      );
      setSearchLoading(false)
    },
    [includeOption]
  );

  useEffect(() => {
    fetchTableData(taskIDs, projectValue, dateFrom, dateTo, includeSubtasks);
    setFileName(`report-${new Date(dateFrom)}-${new Date(dateTo)}`);
  }, [columns.length, fetchTableData, re]);

  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  
  async function exportTimeToExcel(model,withResource,token=null) { 
    {
      let new_model={
        withResource:withResource,
        TimeEntries:model,
        projectName:projectValue?.title,
        dateFrom: new Date(dateFrom).toDateString(),
        dateTo: new Date(dateTo).toDateString(),
      }
      axios.interceptors.response.use(
        (response) => {
    

          // Add caching headers to the response
          response.headers["Cache-Control"] =
            "no-cache, no-store, must-revalidate";
          response.headers["Pragma"] = "no-cache";
          response.headers["Expires"] = "0";
          return response;
        },
        (error) => {

          return Promise.reject(error);
        }
      );

      {
        await axios
          .post(backendURL + `/ExportExcel/exportTimeEntriesByProject`, new_model, {
            headers: {
              Authorization: "Bearer " + (token ? token : jwtToken),
            },
            responseType: 'blob', // Important to handle binary data
          })
          .then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            let fileName = projectValue.title+Date.now()+'.xlsx'; // Default file name
           
        
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const blob = new Blob([response.data], { type: fileType });
        
            FileSaver.saveAs(blob, fileName);
            setExportLoading(false)
            // setFetched(false);
            //  setLoading(false);
          })
          .catch((error) => {
            setExportLoading(false)
            const errorCode = error?.response?.status || "";
            if (errorCode === 401) {
              //if Unauthorized, we logout the user
              logsOutUser(
                {
                  variables: {
                    model: {
                      jwtToken: jwtToken,
                    },
                  },
                },
                () => {
                  logout();
                }
              );
            } else if (errorCode === 400) {
              const desc = error?.response?.data || {};

              if (desc.error?.error === "IXIA_ACTIV_NEW_TOKEN") {
                updateToken(desc.error.errorVariable.trim());
                exportTimeToExcel(desc.error.errorVariable.trim());
              } else if (desc.error?.error !== "IXIA_ACTIV_UNAUTHORIZED"){
                enqueueSnackbar(t(error?.response?.data?.message || ""), {
                  variant: "error",
                  autoHideDuration: 5000,
                });
              }
            } else {
              enqueueSnackbar(t(error?.response?.data?.message || ""), {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
          //  setLoading(false);
          });
      }   
    }
  }

  async function exportTaskToExcel(token=null) { 
    {
      var new_model={
        tasksIDs: taskIDs,
        projectID: projectValue?.projectGuid,
        projectName:projectValue?.title,
        dateFrom: new Date(dateFrom).toDateString(),
        dateTo: new Date(dateTo).toDateString(),
        format:
          day_month_year.includes(userTimezone) === true
            ? "dd-MM-yyyy"
            : "MM-dd-yyyy",
        includeSubtasks: includeSubtasks,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        byTask:true,
        OnlyBillable:includeOption?.id===2,
        OnlyNotBillable:includeOption?.id===3
      }
      axios.interceptors.response.use(
        (response) => {
          // Add caching headers to the response
          response.headers["Cache-Control"] =
            "no-cache, no-store, must-revalidate";
          response.headers["Pragma"] = "no-cache";
          response.headers["Expires"] = "0";
          return response;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      {
        await axios
          .post(backendURL + `/ExportExcel/exportTasksByProject`, new_model, {
            headers: {
              Authorization: "Bearer " + (token ? token : jwtToken),
            },
            responseType: 'blob', // Important to handle binary data
          })
          .then((response) => {
            const contentDisposition = response.headers['content-disposition'];
            let fileName = projectValue.title+Date.now()+'.xlsx'; // Default file name
           
        
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const blob = new Blob([response.data], { type: fileType });
        
            FileSaver.saveAs(blob, fileName);
            
            setExportLoading(false)
          })
          .catch((error) => {
            setExportLoading(false)
            const errorCode = error?.response?.status || "";
            if (errorCode === 401) {
              //if Unauthorized, we logout the user
              logsOutUser(
                {
                  variables: {
                    model: {
                      jwtToken: jwtToken,
                    },
                  },
                },
                () => {
                  logout();
                }
              );
            } else if (errorCode === 400) {
              const desc = error?.response?.data || {};

              if (desc.error?.error === "IXIA_ACTIV_NEW_TOKEN") {
                updateToken(desc.error.errorVariable.trim());
                exportTimeToExcel(desc.error.errorVariable.trim());
              } else if (desc.error?.error !== "IXIA_ACTIV_UNAUTHORIZED"){
                enqueueSnackbar(t(error?.response?.data?.message || ""), {
                  variant: "error",
                  autoHideDuration: 5000,
                });
              }
            } else {
              enqueueSnackbar(t(error?.response?.data?.message || ""), {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
          //  setLoading(false);
          });
      }   
    }
  }
  const handleExport = () => {
   
    if(data!=[] && data!=null && data.length>0)
   // exportTaskToExcel()
   {
    setExportLoading(true)
     exportOption?.id==1? exportTimeToExcel(data,false):exportOption?.id==2? exportTaskToExcel():exportTimeToExcel(data,true);
   } 
   handleClose();
  }
  const pagination = {
    rows: data,
    columns,
    numberRecords: numOfRecords,
    fetchTableData,
    loading: dataLoading,
    hideEditColumns: true,
    removeFilters: true,
    removeSelectAll: true,
    hideSearchBar: true,
    removeRefresh: true,
    removePagination: true,
    removeSorting: true,
  };

  const handleClose = () => {
    setFileName("");
    setIncludeSubtasks(false);
    setOpenDialog(false);
  };

  const handleSave = () => {
    exportToExcel(data, fileName);
    handleClose();
  };
  
  return (
    <>
    <TimeEntryDrawer
        PaperComponent={PaperComponent}
        openTimeEntryModal={clicked}
        setOpenTimeEntryModal={(v) => setClicked(setClicked)}
        timeSheetGuid={null}
        timeEntryGuid={timeEntry?.timeEntryGuid}
        description={timeEntry?.description}
        synced={false}
        refetchData={refetchData}
        refetchTasks={null}
        editMode={true}
        handleEditMode={(v) => setEditMode(v)}
        handleChangeTimeEntryGuid={(v) => setTimeEntryGuid(v)}
        employees={employeesList}
        resetFields={null}
        selectedTask={null}
        tasks={task_list}
        t={t}
      />
      <FieldModal
        PaperComponent={PaperComponent}
        CustomButton={CustomButton}
        // content={t("dialog.file.name")}
        loading={false}
        openDialog={openDialog}
        handleClose={handleClose}
        handleSave={handleSave}
        setState={(v) => setFileName(v)}
        title={t("dialog.file.name")}
        label={t("task.list.exportToExcel.fileName")}
        t={t}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <AutoComplete
                loading={projectLoading}
                options={projectsList}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"projectGuid"}
                size="small"
                value={projectValue}
                onOpen={() => setFetchProjects(true)}
                onClose={() => setFetchProjects(false)}
                required
                onChange={(event, value) => {
                  setProjectValue(value);
                  setFetchTasks(false);
                  setTaskValue([]);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                  "& .MuiChip-root": {
                    marginLeft: "15px !important",
                  },
                }}
                roundedTextField
                label={t("reports.label.project")}
                
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <AutoComplete
                multiple
                loading={taskLoading}
                options={tasks_list || []}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"taskGuid"}
                size="small"
                value={taskValue}
                onOpen={() => (!projectValue ? setFetchTasks(true) : {})}
                onClose={() => (!projectValue ? setFetchTasks(false) : {})}
                required
                onChange={(event, value) => {
                  setTaskValue(value);
                  let tmp = [];
                  value.map((ids, inxex) => {
                    tmp.push(ids.taskGuid);
                  });
                  setTaskIDs(tmp);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                  "& .MuiChip-root": {
                    marginLeft: "15px !important",
                  },
                }}
                roundedTextField
                label={t("reports.label.tasks")}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              sx={{ margin: { sm: "10px !important" } }}
            >
              <FormGroup
                sx={{
                  ".MuiTypography-root": {
                    fontSize: "14px !important",
                  },
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeSubtasks}
                      onChange={(e) => setIncludeSubtasks(e.target.checked)}
                    />
                  }
                  label={t("taskModal.includeSubtasks")}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2} mt={1}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateFrom")}
                value={dateFrom}
                disableFuture
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                onClose={() => setOpen(true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2} mt={1}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateTo")}
                value={dateTo}
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }} mt={1}>
                <PreviousIcon
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            
            <Grid item xs={12} sm={4} md={1.5} lg={1.5} xl={1.5} mt={1}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{".MuiInputBase-input": {fontSize: "14px !important"}}}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }} mt={1}>
                <NextIcon 
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}
            style={{
              paddingTop:"15px"
            }}>
              <AutoComplete
                options={exportOptions}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"id"}
                size="small"
                value={exportOption}
                onOpen={() => setFetchProjects(true)}
                onClose={() => setFetchProjects(false)}
                required
                onChange={(event, value) => {
                  setExportOption(value);
                  
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                  "& .MuiChip-root": {
                    marginLeft: "15px !important",
                  },
                }}
                roundedTextField
                label={t("reports.label.exportOption")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}
            style={{
              paddingTop:"15px"
            }}>
              <AutoComplete
                options={includeOptions}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"id"}
                size="small"
                value={includeOption}
                required
                onChange={(event, value) => {
                  setIncludeOption(value);
                  
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                  "& .MuiChip-root": {
                    marginLeft: "15px !important",
                  },
                }}
                roundedTextField
                label={t("reports.label.includeOption")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <IALoadingButton
                onClick={() => setRe((val) => !val)}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                }}
                loading={searchLoading}
                label={t("projectDashboard.Search")}
                tooltipLabel={t("reports.tooltip.task.search")}
                disabled={exportLoading}
              />
              <IALoadingButton
                onClick={() => {
                  // if (data?.length > 0) exportToExcel(data, fileName);
                  if (data?.length > 0) handleExport();
                }}
                style={{
                  width: "fit-content",
                  backgroundColor: "#107C41",
                  borderRadius: "50px",
                  height: "36px",
                  marginLeft: "10px",
                
                }}
                disabled={searchLoading || data.length<1}
                loading={exportLoading}
                label={t("report.exportToExcel")}
                tooltipLabel={t("reports.tooltip.excel.search")}
              />
              {/* <IALoadingButton
          onClick={() => handleExport()}
          style={{
            width: "fit-content",
            backgroundColor: dark_logo_blue,
            borderRadius: "50px",
            height: "36px",
          }}
          label={t("task.list.exportToExcel")}
        /> */}
            </Grid>
          </Grid>
          {dataLoading && columns.length === 0 ? (
            <ContainerCard
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <CircularProgress />
            </ContainerCard>
          ) : (
            <div style={{width:"100%"}}>
              <DataTable {...pagination} fixedWidth={"calc(100vw - 340px)"} height={"calc(100vh - 370px)"} />
            </div>
          )}
        </ContainerCard>
      </Grid>
    </>
  );
}

export default ExcelReport;
