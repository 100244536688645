import React, { useEffect, useState, useRef } from "react";

//routing
import { useNavigate, useParams } from "react-router-dom";

//schemas
import form from "./schema/form";
import initialValues from "./schema/initialValues";
import validation from "./schema/validation";

//external components
import { Box, CircularProgress, Grid } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//pages
import Main from "./components/main";

// formik components
import { Form, Formik } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks & graphql
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import {
  CREATE_WORKSHIFT,
  GET_WORKSHIFT_BY_ID,
  UPDATE_WORK_SHIFT,
} from "../../../../graphql/workshifts";

//urls
import urls from "../../../../urls";
import { THEME_ID } from "@mui/joy";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

const WorkShiftForm = ({
  myWorkShifts = false,
  fromModal = false,
  handleCloseModal,
  resetFields = false,
  disabledFields = false,
  object = null,
}) => {
  const { tName, tID, wsName, wsID } = useParams();

  const createMode = !wsName && !wsID;

  const { storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const { my_workshifts_list, tenant_work_shifts } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [notEditable, setNotEditable] = useState(false);
  
  const [ unTouchedForm, setUnTouchedForm ] = useState(true);

  const { description, totalWeeklyHours } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  useEffect(() => {
    if (object?.workShiftGuid) {
      showWorkShift(object?.workShiftGuid);
    }
  }, [object?.workShiftGuid]);

  useEffect(() => {
    if (wsID) {
      showWorkShift(wsID);
    }
  }, [wsID]);

  const { mfunction: getWorkShift, loading: getDataByIDLoading } =
    useLazyQueryAuth(GET_WORKSHIFT_BY_ID, "GetWorkShiftById");

  const showWorkShift = (id) => {
    getWorkShift(
      {
        variables: { id: id },
      },
      (response) => {
        const data = response?.GetWorkShiftById?.workShiftView?.workShift || {};
        let dataArray = structuredClone(data.workShiftDays || []);

        let newArray = dataArray.map((val, index) => {
          if (
            val.isWorkDay === true &&
            val.endHour !== null &&
            val.startHour !== null
          ) {
            // var tmpDate = val.endHour.split("+");
            // var date = new Date(tmpDate[0] + "Z");
            // var tmpDate2 = val.startHour.split("+");
            // var date2 = new Date(tmpDate2[0] + "Z");
            // val.endHour = date;
            // val.startHour = date2;

            if (val.startHour.includes("Z")) {
              val.startHour = new Date(val.startHour.slice(0, -1));
            } else {
              val.startHour = new Date(val.startHour);
            }

            if (val.endHour.includes("Z")) {
              val.endHour = new Date(val.endHour.slice(0, -1));
            } else {
              val.endHour = new Date(val.endHour);
            }
          } else if (
            val.isWorkDay === false &&
            val.endHour !== null &&
            val.startHour !== null
          ) {
            val.startHour = null;
            val.endHour = null;
          }

          val.workShiftDayGuid = structuredClone(val.workShiftDaysGuid);
          delete val.workShiftDaysGuid;

          return val;
        });

        setFormData({
          [description.name]: data.description || "",
          daysOfWeek: newArray || [],
          [totalWeeklyHours.name]: data.totalWeeklyHours || 0,
        });

        if (!response?.GetWorkShiftById?.workShiftView?.editable)
          setNotEditable(true);
      }
    );
  };

  // const { loading: getDataByIDLoading, refetch } = useQueryAuth(
  //   GET_WORKSHIFT_BY_ID,
  //   "GetWorkShiftById",
  //   {
  //     variables: { id: wsID || object?.workShiftGuid },
  //     skip: createMode,
  //   },
  //   {
  //     onCompleted: async (response) => {
  //       const data = response?.GetWorkShiftById?.workShift || {};

  //       let dataArray = structuredClone(data.workShiftDays || []);

  //       let newArray = await dataArray.map((val, index) => {
  //         if (
  //           val.isWorkDay === true &&
  //           val.endHour !== null &&
  //           val.startHour !== null
  //         ) {
  //           // var tmpDate = val.endHour.split("+");
  //           // var date = new Date(tmpDate[0] + "Z");
  //           // var tmpDate2 = val.startHour.split("+");
  //           // var date2 = new Date(tmpDate2[0] + "Z");
  //           // val.endHour = date;
  //           // val.startHour = date2;

  //           if(val.startHour.includes('Z')) {
  //             val.startHour = new Date(val.startHour.slice(0,-1))
  //           } else {
  //             val.startHour = new Date(val.startHour);
  //           }

  //           if(val.endHour.includes('Z')) {
  //             val.endHour = new Date(val.endHour.slice(0,-1))
  //           } else {
  //             val.endHour = new Date(val.endHour);
  //           }
  //         }
  //         else if(
  //           val.isWorkDay === false &&
  //           val.endHour !== null &&
  //           val.startHour !== null
  //         ) {
  //           val.startHour = null;
  //           val.endHour = null;
  //         }

  //         val.workShiftDayGuid = structuredClone(val.workShiftDaysGuid);
  //         delete val.workShiftDaysGuid;

  //         return val;
  //       });

  //       setFormData({
  //         [description.name]: data.description || "",
  //         daysOfWeek: newArray || [],
  //         [totalWeeklyHours.name]: data.totalWeeklyHours || 0,
  //       });

  //       if(data?.timeSheets?.length > 0 && data?.timeSheets?.some(timesheet => timesheet.timeEntries?.length > 0))
  //         setNotEditable(true)
  //     },
  //   }
  // );

  function formatDate(date) {
    var d = new Date(date),
      year = d.getFullYear(),
      month = ("0" + (d.getMonth() + 1)).slice(-2),
      day = ("0" + d.getDate()).slice(-2),
      hours = ("0" + d.getHours()).slice(-2),
      minutes = ("0" + d.getMinutes()).slice(-2),
      seconds = ("0" + d.getSeconds()).slice(-2);

    return (
      [year, month, day].join("-") + " " + [hours, minutes, seconds].join(":")
    );
  }

  const { cfunction: editUserData } = useMutationAuth(
    UPDATE_WORK_SHIFT,
    "EditWorkShift"
  );

  const { cfunction: createWorkShiftData } = useMutationAuth(
    CREATE_WORKSHIFT,
    "CreateWorkShift"
  );

  async function createWorkshift(values, actions) {
    let variables = structuredClone(values);

    // let totalHours = 0;

    let newVal = await variables.daysOfWeek.map((weekday, index) => {
      weekday.breakTime = parseFloat(weekday.breakTime);
      if (weekday.isWorkDay) {
        // totalHours +=
        //   new Date(weekday.endHour).getTime() -
        //   new Date(weekday.startHour).getTime();
        weekday.startHour = formatDate(new Date(weekday.startHour));
        weekday.endHour = formatDate(new Date(weekday.endHour));
      }

      return weekday;
    });

    createWorkShiftData(
      {
        variables: {
          model: {
            days: newVal,
            description: values[description.name],
            tenantGuid: tID ? tID : storeUserTenant,
          },
        },
      },
      (response) => {
        if (
          response["CreateWorkShift"].errorCode !== null &&
          response["CreateWorkShift"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateWorkShift"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("workshiftForm.createWorkShift"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          if (fromModal === true && handleCloseModal !== undefined)
            handleCloseModal(false);
          else
            navigate(
              tID
                ? tenant_work_shifts.replace(
                    "/:tName/:tID",
                    `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                  )
                : my_workshifts_list
            );
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  async function editWorkshift(values, actions) {
    let variables = structuredClone(values);

    // let totalHours = 0;

    let newVal = await variables.daysOfWeek.map((weekday, index) => {
      weekday.breakTime = parseFloat(weekday.breakTime);
      if (weekday.isWorkDay) {
        // totalHours +=
        //   new Date(weekday.endHour).getTime() -
        //   new Date(weekday.startHour).getTime();
        weekday.startHour = formatDate(new Date(weekday.startHour));
        weekday.endHour = formatDate(new Date(weekday.endHour));
      }

      return weekday;
    });

    editUserData(
      {
        variables: {
          model: {
            days: notEditable ? [] : newVal,
            description: values[description.name],
            workShiftGuid: wsID,
          },
        },
      },
      (response) => {
        if (
          response["EditWorkShift"].errorCode !== null &&
          response["EditWorkShift"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditWorkShift"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("workShiftForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if (
      performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
      unTouchedForm && !fromModal
    ) {
      sessionStorage.clear();
    }
  }, []);

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createWorkshift(values, actions);
    } else {
      editWorkshift(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                    setErrors,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched && fromModal !== true) {
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                          setUnTouchedForm(false);
                        }
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              setErrors,
                              readOnly:
                                manageSystemConfigurations === "R" ||
                                disabledFields === true,
                              notEditable,
                            }}
                          />
                        </Box>
                        {!disabledFields && (
                          <Box
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              disabled={
                                isSubmitting ||
                                manageSystemConfigurations !== "M"
                              }
                              loading={isSubmitting}
                              type="submit"
                              label={t("workShiftForm.save")}
                            />
                          </Box>
                        )}
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default WorkShiftForm;
