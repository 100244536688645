// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import colors from "../../assets/theme/light/colors";
import useAuth from "../../hooks/useAuth";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { breakpoints, transitions } = theme;

  const { isTrial } = useAuth();

  const { miniSidenav } = ownerState;

  const sidebarWidth = "256px";
  const { dark_logo_blue } = colors

  const drawerOpenStyles = () => ({
    background: `linear-gradient(${colors.dark_logo_blue}, ${colors.gradient_light_blue})`,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    minWidth: sidebarWidth,
    [breakpoints.down("xs")]: {
      width: "100%",
    },
    [breakpoints.up("md")]: {
      marginBottom: "15px",
      left: "0",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    background: dark_logo_blue,
    transform: `translateX(-320px)`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    [breakpoints.up("lg")]: {
      left: "0",
      width: "76px",
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      border: "none",
      overflowY: "auto",
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
    "& .MuiPaper-root": {
      top: isTrial ? "20px" : 0
    }
  };
});
