import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import EmployeeTable from "../Teams-list/EmployeeTable";
import { getTable } from "./_table";

//external components
import { CircularProgress } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  GET_TEAM_EMPLOYEES,
  ASSIGN_EMPLOYEES_TO_TEAM,
  REMOVE_EMPLOYEES,
} from "../../../../graphql/team";

import { useTranslation } from "react-i18next";

//snackbar
import { useSnackbar } from "notistack";

const TeamEmployeesList = ({teamGuid = null}) => {
  const { tID } = useParams();

  const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const { manageUsers } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [displayTable, setDisplayTable] = useState(false);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    REMOVE_EMPLOYEES,
    "RemoveAssignedEmployeesForTeam"
  );

  const { mfunction: assignEmployeesToTeam } = useMutationAuth(
    ASSIGN_EMPLOYEES_TO_TEAM,
    "AssignTeamToEmployees"
  );

  useEffect(() => {
    if (columns.length == 0) {
      const col = getTable();

      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const { mfunction: getAllEmployees, loading } = useLazyQueryAuth(
    GET_TEAM_EMPLOYEES,
    "GetAllTeamEmployees"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployees(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              teamGuid: tID || teamGuid,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if(columns.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columns.length, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllTeamEmployees &&
    data.GetAllTeamEmployees &&
    data.GetAllTeamEmployees.teamEmployeesList.employees
      ? data.GetAllTeamEmployees.teamEmployeesList.employees
      : [];
  const numberOfRecords =
    data &&
    data.GetAllTeamEmployees &&
    data.GetAllTeamEmployees &&
    data.GetAllTeamEmployees.teamEmployeesList.totalNumberOfRecords
      ? data.GetAllTeamEmployees.teamEmployeesList.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "employeeList.addEmployee",
      handleClick: () => setDisplayTable(true),
      condition: (!selected || selected.length === 0) && manageUsers === "M" && !teamGuid,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important"
          }}
        />
      ),
    },
  ];

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            teamGuid: tID,
            employeesIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("teams.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "employee.list.search.label",
    readOnly: manageUsers !== "M",
    removeFilters: true,
    hideEditColumns: true,
    customButtons,
    deleteText: "employees.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
  };

  function handleAddEmployee(array) {
    assignEmployeesToTeam(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            teamGuid: tID,
            employeesIDs: array,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("assignEmployeesToTeam.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        setDisplayTable(false);
      }
    );
  }

  return (
    <div>
      {displayTable ? (
        <EmployeeTable
          display={displayTable}
          tenant={storeUserTenant}
          initialVal={[]}
          handleClick={handleAddEmployee}
          setDisplay={setDisplayTable}
        />
      ) : null}
      {loading  && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"employee.employeeGuid"} hasCheckbox={!teamGuid} />
      )}
    </div>
  );
};

export default TeamEmployeesList;
