import { gql } from "@apollo/client";

export const GET_ALL_PROJECTS = gql`
  query ($model: GetAllProjectsRequestModelInput!) {
    GetAllProjects(model: $model) {
      projectsList {
        projects {
          projectGuid
          percentageComplete
          title
          description
          projectStatus{
            projectStatusGuid
            description
            mnemonic
         }
          estimatedEndDate
          estimatedStartDate
          estimatedTime
          actualEndDate
          actualStartDate
          actualTime
          client {
            clientGuid
            name
          }
          location {
            locationGuid
            name
          }
          company {
            companyGuid
            name
          }
          createdBy {
            email
          }
          creationDate
          modifiedBy {
            email
          }
          modificationDate
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_PROJECTS_DROPDOWN = gql`
  query ($model: GetAllProjectsRequestModelInput!) {
    GetAllProjects(model: $model) {
      projectsList {
        projectViews {
          projectGuid
          title
          oldestTimeEntry {
            timeEntryGuid
            startTime
            endTime
          }
          latestTimeEntry {
            timeEntryGuid
            startTime
            endTime
          }
          projectPermissions {
            resourceGuid
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_PROJECTS_STATUSES= gql`
query{
  GetProjectStatuses {
      projectStatuses{
        projectStatusGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
}`;

export const GET_PROJECT_BY_ID = gql`
  query ($id: UUID! , $userID : UUID!) {
    GetProjectById(id: $id, userID : $userID) {
      project {
        title
        synced
        favoriteProjects{
          favoriteProjectGuid
        }
        estimatedEndDate
            estimatedStartDate
            estimatedTime
            actualEndDate
            actualStartDate
            actualTime
        description
        projectStatus{
          projectStatusGuid
          description
          mnemonic
       }
        client {
          clientGuid
          name
        }
        location {
          locationGuid
          name
        }
        company {
          companyGuid
          name
          tenant {
            tenantGuid
            name
          }
        }
        externalReference
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation ($model: EditProjectInputRequestsInput!) {
    EditProject(model: $model) {
      project {
        projectGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation ($model: CreateProjectInputRequestInput!) {
    CreateProject(model: $model) {
      project {
        projectGuid
        title
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_PROJECTS = gql`
  mutation ($model: DeleteProjectListRequestInput!) {
    DeleteListOfProjects(model: $model) {
      crudProject
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_PROJECT_ANALYTICS_DASHBOARD = gql`
  query ($model: GetEmployeeProjectDetailsRequestModelInput!) {
    GetEmployeeProjectDetails(model: $model) {
      errorCode
      errorMessage
      errorVariables
      employeeProjects {
        response {
          employee {
            firstName
            lastName
          }
          totalTime
          projects {
            project {
              title
            }
            ticketsAndTasks {
              ticket {
                title
              }
              tasksAndTimes {
                task {
                  title
                }
                totalTime
              }
            }
          }
        }
      }
    }
  }
`;


export const FAVORITE_PROJECT=gql`
mutation($model:EditProjectFavoritesRequestInput!){
  EditProjectFavourites (model: $model) {
    editProjectFavouritesResponse
    errorCode
    errorMessage
    errorVariables
  }
}

`;

export const UPDATE_PROJECT_QUICK = gql`
  mutation($model:UpdateProjectQuickRequestInput!){
    UpdateProjectQuick (model: $model){
      updateProjectQuickResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_PROJECT_INVOICE_REPORT = gql`
  query ($model: GenerateInvoicesReportRequestInput!) {
    GenerateProjectInvoiceReport(model: $model) {
      projectInvoiceReport {
        projectTitle
        projectExternalReference
        invoices {
          invoiceDocNo
          invoicePostingDate
          invoiceQuantity
          appliedQuantity
          remainingQuantity
          appliedItems {
            documentNo
            quantity
            externalReference
            type
          }
        }
        totalTimeEntriesToInvoice
        timeEntriesToInvoiceByMonth {
          month
          monthlyTotal
          timeEntriesToInvoice {
            key
            value
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const ASSIGN_PROJECTS_TO_RESOURCE= gql`

mutation($model:AssignProjectsToResourceRequestInput!){
  AssignProjectsToResource(model: $model){
    crudProjectPermission
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const UNASSIGN_PROJECTS_TO_RESOURCE= gql`

mutation($model:DeleteProjectPermissionsByProjectsRequestInput!){
  DeleteProjectPermissionsByProjects(model: $model){
    crudProjectPermission
    errorCode
    errorMessage
    errorVariables
  }
}
`