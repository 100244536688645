import React, { useEffect, useState } from "react";

import { Grid, Typography, CircularProgress, Button, Box } from "@mui/material";

import ConfirmationModal from "../../../../../components/Modal/ConfirmationModal";
import ContainerCard from "../../../../../components/Cards/ContainerCard";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import RoundedTextField from "../../../../../components/TextFields/RoundedTextField";
import CustomButton from "../../../../../components/CustomButton";

import urls from "../../../../../urls";

import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { DELETE_TENANTS, RESET_TENANT } from "../../../../../graphql/tenant";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";

import { styled } from "@mui/material/styles";

function TenantManagement({ fromTenant }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { tName, tID } = useParams();

  const { isTrial } = useAuth();

  const { tenant_form, tenant_list } = urls;

  const [openDialog, setOpenDialog] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [name, setName] = useState(false);
  const [type, setType] = useState(null);

  const ErrorButton = styled(Button)({
    color: "var(--color--red-color)",
  });

  const { mfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_TENANTS,
    "DeleteListOfTenants"
  );
  const { mfunction: resetTenant, loading: resetTenantLoading } =
    useMutationAuth(RESET_TENANT, "ResetTenant");

  const handleDelete = () => {
    deleteRecord(
      {
        variables: {
          model: {
            listOfGuid: [tID],
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("tenant.list.delete.success"), {
          variant: "success",
        });
        setOpenSecondDialog(false);
        handleClose();
        navigate(tenant_list);
      }
    );
  };

  const handleReset = () => {
    resetTenant(
      {
        variables: { id: tID },
      },
      (response) => {
        enqueueSnackbar(t("tenant.list.reset.success"), {
          variant: "success",
        });
        setOpenSecondDialog(false);
        handleClose();
        navigate(tenant_form.replace(
          "/:tName/:tID",
          `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
        ));
      }
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    setOpenSecondDialog(true);
    handleClose();
  };

  const handleConfirm = () => {
    if (type == "delete") handleDelete();
    else if (type == "reset") handleReset();
  };

  const handleOpenDeleteConfirmationModal = (value) => {
    setOpenDialog(true);
    setType(value);
  };

  useEffect(() => {
    if (tName === name) setDisabledButton(false);
    else setDisabledButton(true);
  }, [name]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenDialog(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const customButtons_1 = () => (
    <>
      <CustomButton onClick={handleSave} label={t("dialog.yes")} />
      <div>
        <CustomButton onClick={handleClose} label={t("dialog.cancel")} />
      </div>
    </>
  );

  const customButtons_2 = () => (
    <>
      <ErrorButton
        onClick={handleConfirm}
        color="error"
        disabled={disabledButton || deleteLoading || resetTenantLoading}
      >
        {type === "delete" ? t("dialog.delete") : t("dialog.reset")}
      </ErrorButton>
      <div>
        <CustomButton
          onClick={() => setOpenSecondDialog(false)}
          disabled={deleteLoading || resetTenantLoading} 
          label={t("dialog.cancel")} 
        />
      </div>
    </>
  );

  return (
    <>
      <ConfirmationModal
        icon={"warning"}
        openDialog={openSecondDialog}
        contentText={type === "delete" ? t("tenant.enter.name.delete") : t("tenant.enter.name.reset")}
        content={() =>
          deleteLoading || resetTenantLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <RoundedTextField
              autoFocus
              margin="dense"
              id="description"
              onChange={(e) => setName(e.target.value)}
              type="text"
              fullWidth
              variant="standard"
              multiline
            />
          )
        }
        handleClose={() => setOpenSecondDialog(false)}
        customButtons={customButtons_2}
        t={t}
      />
      <ConfirmationModal
        contentText={
          type == "delete"
            ? t("dialog.user.delete.tenant.confirmation")
            : t("dialog.user.reset.tenant.confirmation")
        }
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openDialog}
        handleClose={handleClose}
        customButtons={customButtons_1}
        t={t}
      />
      <ContainerCard
        sx={{
          padding: "10px 20px",
          marginTop: "20px",
          border: "1px solid red",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderBottom: "1px solid #eee",
              paddingBottom: "10px",
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {t("tenant.delete.tenant.title")}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "light" }}>
                {t("tenant.delete.tenant.msg")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={2}
              xl={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IALoadingButton
                label={t("tenant.delete.tenant.title")}
                error
                onClick={() => handleOpenDeleteConfirmationModal("delete")}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {t("tenant.reset.data.title")}
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: "light" }}>
                {t("tenant.reset.data.msg")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={2}
              xl={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IALoadingButton
                label={t("tenant.reset.data.title")}
                error
                disabled={!fromTenant && !isTrial}
                onClick={() => handleOpenDeleteConfirmationModal("reset")}
              />
            </Grid>
          </Grid>
        </Grid>
      </ContainerCard>
    </>
  );
}

export default TenantManagement;
