import {
  addMonthsToYearAsDate, getMonthNames
} from "./Utils";

export const buildMonthCells = (startYear, t) => {
  const MONTH_NAMES = getMonthNames(t);
  const v = [];
  for (let i = 0; i < 24; i += 1) {
    const startMonth = i % 12; // Ensure months wrap around within a year
    const startYearOffset = Math.floor(i / 12); // Calculate the number of years to add
    const start = addMonthsToYearAsDate(startYear + startYearOffset, startMonth);
    const end = addMonthsToYearAsDate(startYear + startYearOffset, startMonth + 1);
    v.push({
      id: `m${startMonth}_${startYear + startYearOffset}`,
      title: MONTH_NAMES[startMonth],
      start,
      end,
    });
  }
  return v;
};

const buildWeekCells = (start, end) => {
  const cells = [];
  let currentDate = new Date(start);

  while (currentDate <= end) {
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Move to the start of the week

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // Move to the end of the week

    const weekCell = {
      id: `w${startOfWeek.toLocaleDateString()}`, // You can use any unique identifier
      title: (
        <div style={{ padding: "0 10px" }}>
          <span style={{ display: "block", height: 41 }}>
            {`${startOfWeek.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
            })}`}
          </span>
        </div>
      ),
      start: startOfWeek,
      end: endOfWeek,
      style: {
      },
    };

    cells.push(weekCell);

    currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
  }
  
  return cells;
};

export const buildTimebar = (start, end, startYear, t) => [
  {
    id: "months",
    title: "",
    cells: buildMonthCells(startYear, t),
    style: {},
  },
  {
    id: "weeks",
    title: "",
    cells: buildWeekCells(start, end),
    style: {},
    useAsGrid: true,
  },
];
