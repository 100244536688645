import React, { useEffect, useRef, useState } from "react";

//routing
import { useParams } from "react-router-dom";

//graphql
import { CHECK_AVAILABILITY } from "../../../../graphql/employee";
import { GET_ALL_SKILLS } from "../../../../graphql/skill";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//translation
import { useTranslation } from "react-i18next";

//mui
import {
  Modal,
  Grid,
  Paper,
  CircularProgress,
  IconButton,
  Stack,
  Pagination,
} from "@mui/material";

//date
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getDates } from "./getDates";

//component
import AvailabilityTimeline from "../../../../components/Timeline";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import AutoComplete from "../../../../components/AutoComplete";

import TasksList from "../../Tasks/Tasks-list";

import { day_month_year } from "../../../../utils/timezones";

import colors from "../../../../assets/theme/light/colors";
import { CloseIcon } from "../../../../components/Icon";

import { v4 as uuidv4 } from "uuid";

function EmployeesAvailability() {
  const { tID } = useParams();
  const { t } = useTranslation();
  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const { isEntelligence } = userRoles;
  const pageSize= 10;

  const { dark_logo_blue } = colors;

  const [dateFrom, setDateFrom] = useState(new Date(new Date().getFullYear(), 0, 1, 1, 1));
  const [dateTo, setDateTo] = useState(new Date(new Date().getFullYear(), 12, 1, 1, 1));
  const [skillIDs, setSkillIDs] = useState([]);
  const [skillValue, setSkillValue] = useState([]);
  const [employeeIDs, setEmployeeIDs] = useState([]);
  const [employeeValue, setEmployeeValue] = useState([]);
  const [task, setTask] = useState(null);
  const [parent, setParent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalNumOfPages, setTotalNumOfPages] = useState(1);
  const type = useRef("task");

  useEffect(() => {
    const dates = getDates();
    setDateFrom(new Date(dates.start));
    setDateTo(new Date(dates.end));
  }, []);

  const {
    data: skillData,
    loading: skillLoading,
    tokenChange: skillWait,
  } = useQueryAuth(GET_ALL_SKILLS, "GetAllSkills", {
    variables: {
      model: {
        enableFilters: false,
        tenantGuid: isEntelligence ? tID : storeUserTenant,
      },
    },
  });

  const skillList = skillData?.GetAllSkills?.skillsList?.skills || [];

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: isEntelligence ? tID : storeUserTenant,
        userID: storeUserGuid,
        activeUsers: true
      },
    },
    skip: skillWait,
    isWait: true
  });

  const {
    data: employeesAvailability,
    loading: employeesAvailabilityLoading,
    refetch,
  } = useQueryAuth(CHECK_AVAILABILITY, "GetEmployeesAvailability", {
    variables: {
      model: {
        tenantID: isEntelligence ? tID : storeUserTenant,
        currentUserID: storeUserGuid,
        skills: skillIDs,
        dateFrom: new Date(dateFrom).toISOString(),
        dateTo: new Date(dateTo).toISOString(),
        employees: employeeIDs,
        keys: {
          page: page,
          pageSize: pageSize,
        },
      },
    },
    skip: employeeWait || skillWait,
    isWait: true
  });

  const employees_availability =
    employeesAvailability &&
    employeesAvailability.GetEmployeesAvailability &&
    employeesAvailability.GetEmployeesAvailability.employeesAvailabilityResponse
      ? employeesAvailability.GetEmployeesAvailability
          .employeesAvailabilityResponse
      : [];

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  useEffect(() => {
    setTotalNumOfPages(Math.ceil(employeeList.length / pageSize));
  }, [employeeList.length]);

  return (
    <>
      {task && (
        <TaskTabsModal
          showModal={showModal}
          setShowModal={(v) => setShowModal(v)}
          loading={loading}
          projectID={task?.ticket?.projectGuid}
          projectName={task?.ticket?.project?.title}
          taskID={task?.taskGuid}
          taskName={task?.title}
          refetch={refetch}
          fromProject={true}
          editMode={true}
          type={parent ? "subtask" : type.current}
          setType={(v) => type.current=v}
          open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
          modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        />
      )}
      <Modal
        open={openTasksModal}
        onClose={() => {
          setOpenTasksModal(false);
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
          height: "97vh",
          minWidth: "1400px",
          zIndex: "1300 !important"
        }}
      >
        <>
          <Paper
            sx={{
              padding: "5px 0px 0px 0px",
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              overflowY: "auto",
              backgroundColor: "#F5F5F5",
              width: "1400px",
              height: "100%",
              position: "relative",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "flex-end",
                  paddingRight: "10px",
                }}
              >
                <IconButton
                  onClick={() => {
                    setOpenTasksModal(false);
                  }}
                >
                <CloseIcon color={"#9e9e9e"} width={'24'} stroke={'1.5'} />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ paddingTop: 0 }}
              >
                <TasksList fromProject={true} fromEmployeeAvailability={true} />
              </Grid>
            </Grid>
          </Paper>
        </>
      </Modal>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            "& .MuiCard-root": {
              backgroundColor: "transparent !important",
              boxShadow: "none !important",
            },
            gap: "20px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  format={
                    day_month_year.includes(userTimezone) === true
                      ? "dd-MM-yyyy"
                      : "MM-dd-yyyy"
                  }
                  className="date_picker_styles"
                  label={t("employeeAvailability.label.DateFrom")}
                  value={dateFrom}
                  onChange={(newValue) => {
                    setDateFrom(newValue);
                  }}
                  slotProps={{
                    desktopPaper: {
                      sx: { boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,}
                    },
                    textField: {
                      size: "small",
                    },
                  }}
                  sx={{
                    width: "100%",
                    fontFamily: "poppins",
                    "& .MuiInputBase-root": { height: "35px !important" },
                    "& .MuiInputBase-input": {
                      fontSize: "13px !important",
                    },
                    "& .MuiInputAdornment-root .MuiButtonBase-root": {
                      // color: light_logo_blue,
                      fontSize: "13px !important",
                      marginRight: "-5px !important",
                    },
                    "& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root ":
                      {
                        width: "20px !important",
                      },
                  }}
                  
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  format={
                    day_month_year.includes(userTimezone) === true
                      ? "dd-MM-yyyy"
                      : "MM-dd-yyyy"
                  }
                  className="date_picker_styles"
                  label={t("employeeAvailability.label.DateTo")}
                  value={dateTo}
                  onChange={(newValue) => {
                    setDateTo(newValue);
                  }}
                  slotProps={{
                    desktopPaper: {
                      sx: { boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,}
                    },
                    textField: {
                      size: "small",
                    },
                  }}
                  sx={{
                    width: "100%",
                    fontFamily: "poppins",
                    "& .MuiInputBase-root": { height: "35px !important" },
                    "& .MuiInputBase-input": {
                      fontSize: "13px !important",
                    },
                    "& .MuiInputAdornment-root .MuiButtonBase-root": {
                      // color: light_logo_blue,
                      fontSize: "13px !important",
                      marginRight: "-5px !important",
                    },
                    "& .MuiInputAdornment-root .MuiButtonBase-root .MuiSvgIcon-root ":
                      {
                        width: "20px !important",
                      },
                  }}
                 
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <AutoComplete
                multiple
                loading={employeeLoading}
                options={employeeList}
                getOptionLabel={(option) =>
                  option ? option.firstName + " " + option.lastName : ""
                }renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.firstName + " " + option.lastName}
                  </li>
                )}
                identifier={"employeeGuid"}
                size="small"
                value={employeeValue}
                onChange={(event, value) => {
                  setEmployeeValue(value);
                  let tmp = [];
                  value.map((ids, inxex) => {
                    tmp.push(ids.employeeGuid);
                    return tmp;
                  });
                  setEmployeeIDs(tmp);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                  "& .MuiChip-root":
                    {
                      marginLeft: "15px !important",
                    },
                }}
                roundedTextField={true}
                label={t("reports.label.employee")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <AutoComplete
                multiple
                loading={skillLoading}
                options={skillList}
                getOptionLabel={(option) => (option ? option.name : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                identifier={"skillGuid"}
                size="small"
                value={skillValue}
                onChange={(event, value) => {
                  setSkillValue(value);
                  let tmp = [];
                  value.map((ids, index) => {
                    tmp.push(ids.skillGuid);
                    return tmp;
                  });
                  setSkillIDs(tmp);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                  "& .MuiChip-root":
                    {
                      marginLeft: "15px !important",
                    },
                }}
                roundedTextField={true}
                label={t("employeeAvailability.label.skill")}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                paddingTop: 0,
              }}
            >
              <IALoadingButton
                onClick={() => setOpenTasksModal(true)}
                style={{
                  width: "100%",
                  backgroundColor: dark_logo_blue,
                  fontSize: "12.5px",
                  textTransform: "none",
                  padding: "6px 2px",
                  borderRadius: "50px",
                  height: "36px",
                }}
                label={t("timeByEmployee.assignTask")}
              />
            </Grid>
          </Grid>
        </ContainerCard>
      </Grid>
      {employeesAvailabilityLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <>
          <div
            style={{
              height: "calc(100vh - 180px)",
              overflow: "auto",
              padding: "0px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
          >
            <AvailabilityTimeline
              tracks={employees_availability}
              startDate={dateFrom}
              endDate={dateTo}
              zoom={10}
              setTask={(addedTask) => setTask(addedTask)}
              setParent={(parentTask) => setParent(parentTask)}
              setShowModal={(show) => setShowModal(show)}
              setLoading={(isLoading) => setLoading(isLoading)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={totalNumOfPages}
                onChange={(e, page) => setPage(page - 1)}
                page={page + 1}
                defaultPage={1}
                hideNextButton={page === totalNumOfPages}
              />
            </Stack>
          </div>
        </>
      )}
    </>
  );
}

export default EmployeesAvailability;
