import React, { useState, useRef, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";
import { formatDateTimeToString } from "../../../../UTC2Local";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import {
  GET_TICKET_BY_ID,
  CREATE_TICKET,
  UPDATE_TICKET,
  UPDATE_TICKET_QUICK,
} from "../../../../graphql/ticket";
import hasEmptyTags from "../../../../utils/hasEmptyTags";
import { day_month_year } from "../../../../utils/timezones";

const TicketForm = ({
  fromTenant = false,
  ticketID,
  projectID,
  refetchData,
  handleCloseModal,
  handleChangeTicket,
  handleChangeTicketName,
  resetFields,
  openModal,
  modal,
}) => {
  const { pID, tkName, tkID } = useParams();

  const createMode = !tkName && !tkID && !ticketID;

  const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const userTimezone = Intl.DateTimeFormat() .resolvedOptions() .timeZone.split("/")[0];

  const dateFormat = day_month_year.includes(userTimezone) === true ? "DD-MM-YYYY" : "MM-DD-YYYY";

  const { manageTimeEntry } = userRoles;
  const { t } = useTranslation();
  const { formField } = form;

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  const {
    title,
    description,
    status,
    EstimatedStartDate,
    EstimatedEndDate,
    synced,
    EstimatedTime,
    externalReference,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
    area,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();
  const [phaseId, setPhaseId] = useState("");
  const [button1Subitting, setButton1Submitting] = useState(false);
  const [button2Subitting, setButton2Submitting] = useState(false);
  const saveAndClose = useRef(false);
  const [formData, setFormData] = useState(initialValues);

  const [unTouchedForm, setUnTouchedForm] = useState(true);

  const [mediaList, setMediaList] = useState([]);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading, refetch: refetchTicket } = useQueryAuth(
    GET_TICKET_BY_ID,
    "GetTicket",
    {
      variables: { id: tkID ? tkID : ticketID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetTicket?.ticket || {};

        setFormData({
          [title.name]: data.title || "",
          [description.name]: data.description || "",
          [status.name]: data.status || null,
          [EstimatedStartDate.name]:
            data.estimatedStartDate == null
              ? null
              : formatDateTimeToString(data.estimatedStartDate, false, false, dateFormat),
          [EstimatedEndDate.name]:
            data.estimatedEndDate == null
              ? null
              : formatDateTimeToString(data.estimatedEndDate, false, false, dateFormat),
          [ActualStartDate.name]:
            data.actualStartDate == null
              ? null
              : formatDateTimeToString(data.actualStartDate, false, false, dateFormat),
          [ActualEndDate.name]:
            data.actualEndDate == null
              ? null
              : formatDateTimeToString(data.actualEndDate, false, false, dateFormat),
          [synced.name]: data?.synced || false,
          [externalReference.name]: data?.externalReference || null,
          [EstimatedTime.name]:
            data?.estimatedTime !== null
              ? parseFloat(data?.estimatedTime).toFixed(2)
              : null,

          [ActualTime.name]:
            data?.actualTime !== null
              ? parseFloat(data?.actualTime).toFixed(2)
              : null,
          //   [project.name]: data.project || null,
          [area.name]: data.area || null,
        });
        setPhaseId(data?.id);
      },
    }
  );
  
  const { cfunction: updateTicketQuickData } = useMutationAuth(UPDATE_TICKET_QUICK, "UpdateTicketQuick");

  const updateTicketQuick = (status) => {
    updateTicketQuickData(
      {
        variables: {
          model: {
            ticketGuid: tkID || ticketID,
            statusGuid: status,
          }
        }
      },
      (response) => {
        if (
          response["UpdateTicketQuick"].errorCode !== null &&
          response["UpdateTicketQuick"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateTicketQuick"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          if(refetchTicket) refetchTicket();
        } else {
          enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
      },
      (error) => {
        if(refetchTicket) refetchTicket();
      }
    )
  }

  const { cfunction: editTicketData } = useMutationAuth(
    UPDATE_TICKET,
    "EditTicket"
  );

  const { cfunction: createTicketData } = useMutationAuth(
    CREATE_TICKET,
    "CreateTicket"
  );

  function CreateTicketData(values, actions) {
    if (saveAndClose.current === false) setButton1Submitting(true);
    else setButton2Submitting(true);

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    createTicketData(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            title: values[title.name],
            description: updatedDescription,
            externalReference: values[externalReference.name],
            projectGuid: pID ? pID : projectID,
            ticketStatusGuid: values[status.name]?.ticketStatusGuid,
            areaGuid: values[area.name]?.areaGuid,
            tenantGuid: storeUserTenant,
            media: mediaList,
          },
        },
      },
      (response) => {
        if (
          response["CreateTicket"].errorCode !== null &&
          response["CreateTicket"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateTicket"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setButton1Submitting(false);
          setButton2Submitting(false);
          saveAndClose.current = false;
        } else {
          let phase = response["CreateTicket"].ticket;
          enqueueSnackbar(t("projectForm.createdSuccessfully"), {
            variant: "success",
          });
          if (handleChangeTicket != undefined)
            handleChangeTicket(phase.ticketGuid);
          if (handleChangeTicketName != undefined)
            handleChangeTicketName(phase.title);
          sessionStorage.removeItem("unsavedChanges");
          if (refetchData !== undefined) {
            refetchData();
          }
          // if (showCloseButton === true) {
          //   refetchData();
          //   handleCloseModal(false);
          // }
          if (saveAndClose.current === true) handleCloseModal();
        }
        setButton1Submitting(false);
        setButton2Submitting(false);
        saveAndClose.current = false;
      },
      (error) => {
        setButton1Submitting(false);
        setButton2Submitting(false);
        saveAndClose.current = false;
      }
    );
  }

  function EditTicketData(values, actions) {
    if (saveAndClose.current === false) setButton1Submitting(true);
    else setButton2Submitting(true);

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    editTicketData(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            ticketGuid: tkID ? tkID : ticketID,
            title: values[title.name],
            description: updatedDescription,
            externalReference: values[externalReference.name],
            projectGuid: pID ? pID : projectID,
            ticketStatusGuid: values[status.name]?.ticketStatusGuid,
            areaGuid: values[area.name]?.areaGuid,
          },
        },
      },
      (response) => {
        if (
          response["EditTicket"].errorCode !== null &&
          response["EditTicket"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditTicket"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setButton1Submitting(false);
          setButton2Submitting(false);
          saveAndClose.current = false;
        } else {
          enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          if (ticketID !== null && ticketID !== undefined) {
            refetchData();
          }
          if (saveAndClose.current === true) handleCloseModal();
        }
        setButton1Submitting(false);
        setButton2Submitting(false);
        saveAndClose.current = false;
      },
      (error) => {
        setButton1Submitting(false);
        setButton2Submitting(false);
        saveAndClose.current = false;
      }
    );
  }

  useEffect(() => {
    if (
      performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
      unTouchedForm
    ) {
      sessionStorage.clear();
    }
  }, []);

  const handleSubmit = (values, actions) => {
    if (createMode) {
      CreateTicketData(values, actions);
    } else {
      EditTicketData(values, actions);
    }
  };

  const handleChangeFlag = (touched) => {
    if (touched) {
      sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
      setUnTouchedForm(false);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          justifyContent="left"
          spacing={2}
          sx={{ height: "100%" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ContainerCard style={modal ? { boxShadow: "none" } : {}}>
              {getDataByIDLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="75vh"
                  width="100%"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ values, errors, touched, setFieldValue, setTouched }) =>   {
                    formikValuesRef.current = values;
                    return(
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      className="taskForm"
                      onChange={() => {
                        handleChangeFlag(touched);
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              readOnly: manageTimeEntry === "R",
                              fromTenant,
                              modal,
                            }}
                            phaseId={phaseId}
                            ticketID={ticketID || tkID}
                            modifyFormFlag={() =>
                              sessionStorage.setItem(
                                "unsavedChanges",
                                JSON.stringify(false)
                              )
                            }
                            handleChangeFlag={handleChangeFlag}
                            setMediaList={setMediaList}
                            mediaList={mediaList}
                            updateTicketQuick={updateTicketQuick}
                            handleSubmit={handleSubmit}
                            openModal={openModal}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <IALoadingButton
                            disabled={
                              button1Subitting || manageTimeEntry !== "M"
                            }
                            loading={button1Subitting}
                            type="submit"
                            label={t("projectForm.save")}
                          />
                          {!tkID && 
                          <span style={{ marginLeft: "10px" }}>
                            <IALoadingButton
                              disabled={
                                button2Subitting || manageTimeEntry !== "M"
                              }
                              loading={button2Subitting}
                              type="submit"
                              onClick={() => (saveAndClose.current = true)}
                              label={t("taskForm.save.close")}
                            />
                          </span>}
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              )}
            </ContainerCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TicketForm;
