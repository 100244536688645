import React, { useState, useEffect } from "react";

import {
  Paper,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Tooltip,
} from "@mui/material";

import colors from "../../../../assets/theme/light/colors";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Link } from "react-router-dom";

//uuid generator
import { v4 as uuidv4 } from "uuid";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableCollapseIcon, TableExpandIcon, TaskIcon } from "../../../../components/Icon";
import IconBadge from "../../../../components/StatusCell/IconBadge";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "10px !important",
          borderRadius: "2px !important",
          backgroundColor: "white !important",
          boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
          color: "black",
        },
      },
    },
  },
});

export function TimeByProjectTable({
  t,
  data,
  handleOpenModal,
  timeByContractor,
  generateTaskRoute,
  totalProjectsTime,
}) {
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedIDs, setExpandedIDs] = useState([]);
  const [expandedTask, setExpandedTask] = useState(false);
  const [expandedTaskIDs, setExpandedTaskIDs] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    function addAnotherIdToProjects(dataArray) {
      dataArray.forEach((row) => {
        row?.employees.forEach((employee) => {
          employee.selectedId = uuidv4();
          employee?.tasks.forEach((task) => {
            task.task = true;
          });
        });
      });

      return dataArray;
    }

    // Example usage
    setRows(addAnotherIdToProjects(data));
  }, []);

  function handleExpand(id) {
    setExpanded(true);
    if (!expandedIDs.includes(id)) setExpandedIDs([...expandedIDs, id]);
  }

  function handleCollapse(id) {
    const updatedExpandedIDs = expandedIDs.filter(
      (expandedID) => expandedID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpanded(false);
    setExpandedIDs(updatedExpandedIDs);
  }

  function handleExpandTask(id) {
    setExpandedTask(true);
    if (!expandedTaskIDs.includes(id))
      setExpandedTaskIDs([...expandedTaskIDs, id]);
  }

  function handleCollapseTask(id) {
    const updatedExpandedIDs = expandedTaskIDs.filter(
      (expandedTaskID) => expandedTaskID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpandedTask(false);
    setExpandedTaskIDs(updatedExpandedIDs);
  }

  function handleExpandAll() {
    const emplArr = [];
    const projectArr = [];
    rows.forEach((row) => {
      const { employees } = row;
      setExpanded(true);
      projectArr.push(row?.id);
      employees.forEach((empl) => {
        setExpandedTask(true);
        emplArr.push(empl?.selectedId);
      });
    });
    setExpandAll(true);
    setExpandedIDs(projectArr);
    setExpandedTaskIDs(emplArr);
  }

  function handleCollapseAll() {
    setExpandAll(false);
    setExpanded(false);
    setExpandedIDs([]);
    setExpandedTaskIDs([]);
  }

  function Row(props) {
    const { row, collapsible, parent, parentName, key } = props;
    const { employees } = row;

    return (
      <React.Fragment>
        {row?.task == true ? (
          <TableRow>
            <TableCell size="small" sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : expanded && expandedIDs.includes(row?.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row?.id)}
                    />
                  )}
                </IconButton>
              )}
              {!collapsible && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ visibility: "hidden" }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              )}
            </TableCell>
            {/* <TableCell sx={{ width: "5%" }}>{row?.id}</TableCell> */}
            <TableCell
              sx={{ width: "40%", padding: "0 16px 0 26px" }}
              component={Link}
              align="left"
              className="link-cell task"
              to={generateTaskRoute(row, parentName, parent)}
              onClick={(event) => {
                event.preventDefault();
                handleOpenModal(event, row, parentName, parent);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "50px",
                    }}
                  >
                    <TaskIcon />
                  </span>
                )}
                <div style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "20vw",
                }}>   {`${row?.name}`}</div>
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
                verticalAlign: "bottom",
              }}
            >
              {row?.billable !== null && row?.billable !== undefined ? <IconBadge success={row?.billable} /> : <></>}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 47px 0 16px",
              }}
            >
              {row?.totalOfHours}
            </TableCell>
           {!timeByContractor && <TableCell
              align="center"
              sx={{
                width: "15%",
              }}
            />}
          </TableRow>
        ) : (
          // row?.task === false &&
          <TableRow>
            <TableCell sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : expanded && expandedIDs.includes(row?.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row?.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row?.id)}
                    />
                  )}
                </IconButton>
              )}
            </TableCell>
            {/* <TableCell sx={{ width: "5%" }}>{row?.id}</TableCell> */}
            <TableCell
              sx={{ width: "40%", padding: "0 16px", fontWeight: "500" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                    }}
                  >
                    <TaskIcon />
                  </span>
                )}
<div style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "20vw",
                }}>   {`${row?.name}`}</div>              </div>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "20%",
              }}
            />
            <TableCell
              align="right"
              sx={{
                width: "20%",
                padding: "0 50px 0 16px",
                fontWeight: "bold",
              }}
            >
              {row?.totalOfHours}
            </TableCell>
            {!timeByContractor && (
              <TableCell
                align="center"
                sx={{
                  width: "15%",
                }}
              />
            )}
          </TableRow>
        )}

        {collapsible &&
          expanded &&
          expandedIDs.length != 0 &&
          expandedIDs.includes(row?.id) && (
            <>
              {employees &&
                employees.map((employee) => {
                  return (
                    <React.Fragment key={employee?.id}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "5%",
                            textAlign: "end",
                            padding: "0 16px",
                          }}
                        >
                          {collapsible && (
                            <IconButton aria-label="expand row" size="small">
                              {expandedTask &&
                              expandedTaskIDs.includes(employee?.selectedId) ? (
                                <KeyboardArrowUpIcon
                                  onClick={() =>
                                    handleCollapseTask(employee?.selectedId)
                                  }
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  onClick={() =>
                                    handleExpandTask(employee?.selectedId)
                                  }
                                />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ width: "40%", padding: "0 16px 0 26px" }}
                        >
                          {employee?.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "20%",
                          }}
                        />
                        <TableCell
                          align="right"
                          sx={{
                            width: "20%",
                            padding: "0 50px 0 16px",
                          }}
                        >
                          {employee?.totalOfHours}
                        </TableCell>
                        {!timeByContractor && (
                          <TableCell
                            align="center"
                            sx={{
                              width: "15%",
                            }}
                          >
                            {employee?.percentageOfTotal} {" %"}
                          </TableCell>
                        )}
                      </TableRow>
                      {collapsible && (
                        <TableRow
                          sx={{
                            display:
                              expandedIDs.length != 0 &&
                              expandedIDs.includes(row?.id) &&
                              expandedTask &&
                              expandedTaskIDs.length != 0 &&
                              expandedTaskIDs.includes(employee?.selectedId)
                                ? "table-row"
                                : "none",
                          }}
                        >
                          <TableCell
                            colSpan={!timeByContractor ? 5 : 4}
                            sx={{
                              padding: 0,
                              borderBottom: "none",
                            }}
                          >
                            <Collapse
                              in={expandedTask}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table>
                                <TableBody>
                                  {employee?.tasks &&
                                    employee?.tasks.map((task) => {
                                      return (
                                        <Row
                                          key={task?.id}
                                          ancestor={row?.id}
                                          parent={employee?.id}
                                          parentName={employee?.name}
                                          row={task}
                                          collapsible={false}
                                        />
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            </>
          )}
      </React.Fragment>
    );
  }

  return (
    <>
      {rows.length > 0 ? (
        <TableContainer component={Paper} className={"table-timesheet-height"}>
          <Table size="small" sx={{ overflowX: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "5%",
                    padding: "0 30px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {!expandAll ? (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t("table.ExpandAll")}>
                        <IconButton
                          size="small"
                          onClick={() => handleExpandAll()}
                        >
                          <TableExpandIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t("table.CollapseAll")}>
                        <IconButton
                          size="small"
                          onClick={() => handleCollapseAll()}
                        >
                          <TableCollapseIcon color="#000000" />
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  )}
                </TableCell>
                {/* <TableCell sx={{ width: "5%" }}>ID</TableCell> */}
                <TableCell
                  sx={{
                    width: "40%",
                    padding: "0",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {t("timeByEmployee.name")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "20%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff", 
                  }}                >
                  {t("timeByEmployee.billable")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    width: "20%",
                    padding: "0 50px 0 16px",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "#fff",
                  }}
                >
                  {t("timeByEmployee.total")}
                </TableCell>
                {!timeByContractor && (
                  <TableCell
                    align="center"
                    sx={{
                      width: "15%",
                      padding: "0 16px",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      backgroundColor: "#fff",
                    }}
                  >
                    {"%"}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row?.id} row={row} collapsible={true} />
              ))}
              {timeByContractor && (
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: "bold !important" }}>
                    {t("timeByEmployee.total")}
                  </TableCell>
                  <TableCell />
                  <TableCell
                    align="right"
                    sx={{
                      width: "20%",
                      padding: "0 50px 0 16px",
                      fontWeight: "bold !important",
                    }}
                  >
                    {totalProjectsTime}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
