import { gql } from "@apollo/client";

export const GET_ALL_PROJECT_PERMISSIONS=gql`
query ($model: GetProjectPermissionsRequestModelInput!) {
    GetProjectPermissions(model: $model) {
      projectPermissionsList {
        projectPermissions {
          projectPermissionGuid
          resource {
            employeeGuid
            firstName
            lastName
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`

  export const CREATE_PROJECT_PERMISSION=gql`
  mutation($model:ProjectPermissionCreateRequestModelInput!){
    CreateProjectPermission(model:$model){
      crudProjectPermission
      errorCode
      errorMessage
      errorVariables
    }
  }
  `

  export const UPDATE_PROJECT_PERMISSION=gql`
  mutation($model:ProjectPermissionUpdateRequestModelInput!){
    UpdateProjectPermission(model:$model){
      crudProjectPermission
      errorCode
      errorMessage
      errorVariables
    }
  }
  `

  export const DELETE_PROJECT_PERMISSIONS_LIST=gql`
  mutation($model:DeleteProjectPermissionListRequestModelInput!){
    DeleteProjectPermissionsList (model : $model){
        crudProjectPermission
      errorCode
      errorMessage
      errorVariables
    }
  }
  `

  export const DELETE_PROJECT_PERMISSIONS=gql`
  mutation($id:UUID!){
    DeleteProjectPermission (id : $id){
        crudProjectPermission
      errorCode
      errorMessage
      errorVariables
    }
  }
  `

  export const ASSIGN_RESOURCES_TO_PROJECT=gql`
  mutation($model:AssignResourcesToProjectRequestModelInput!){
    AssignResourcesToProject(model:$model){
      crudProjectPermission
      errorCode
      errorMessage
      errorVariables
    }
  }
  `