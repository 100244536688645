import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import colors from "../../../../assets/theme/light/colors";
import { Field } from "formik";

export function TimesheetTable({
  t,
  re,
  formData,
  list,
  timeErrors,
  setTimeErrors,
  setTaskID,
  setTaskName,
  setProjectID,
  setProjectName,
  setOpenModal,
  tasksLoading,
  isTimeEntryDescriptionMandatory,
}) {
  const [tasks, setTasks] = useState(list);
  const [focused, setFocused] = useState(null);
  const [rowID, setRowID] = useState(null);
  const { values } = formData;

  useEffect(() => {
    setTasks(list);
  }, [re, list]);


  const handleChangeDescription = (taskGuid, newDescription) => {
    const updatedTasks = tasks.map((task) => {
      if (task.taskGuid === taskGuid) {
        // Update the description of the matching task
        return { ...task, description: newDescription };
      }
      return task;
    });

    setTasks(updatedTasks);
    values.tasks = updatedTasks;
  };

  const handleChangeTime = (taskGuid, newTime) => {
    const isValidNumber = !isNaN(Number(newTime)) && isFinite(newTime);

    // Update the timeEntries of the matching task and set the error state
    const updatedTasks = tasks.map((task) => {
      if (task.taskGuid === taskGuid) {
        const updatedTask = {
          ...task,
          duration: newTime === null || newTime === "" ? "0" : newTime.startsWith('0') ? newTime.slice(1) : newTime,
        };

        // Set or clear error state based on the validity of the input
        setTimeErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };

          if (isValidNumber) {
            // Remove the taskGuid from errors if it is valid
            delete updatedErrors[taskGuid];
          } else {
            // Set error state if the input is invalid
            updatedErrors[taskGuid] = "Invalid time format";
          }

          return updatedErrors;
        });

        return updatedTask;
      }
      return task;
    });

    setTasks(updatedTasks);
    values.tasks = updatedTasks;
  };

  const handleChangeBillable = (taskGuid, billable) => {

    // Update the timeEntries of the matching task and set the error state
    const updatedTasks = tasks.map((task) => {
      if (task.taskGuid === taskGuid) {
        const updatedTask = {
          ...task,
          billable: billable,
        };

        return updatedTask;
      }
      return task;
    });

    setTasks(updatedTasks);
    values.tasks = updatedTasks;
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className={"table-timesheet-height"}
        sx={{ padding: "0 0 15px", maxHeight:"60vh" }}
      >
        <Table size="small" sx={{ overflowX: "auto" }}>
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "1px solid var(--color--light-divider-color)",
                height: "42px !important",
              }}
            >
              <TableCell
                sx={{
                  width: "15%",
                  maxWidth: "15%",
                  padding: "5px 0",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none !important",
                  paddingLeft: "10px",
                }}
              >
                {t("myTimesheet.column.project")}
              </TableCell>
              <TableCell
                sx={{
                  width: "35%",
                  maxWidth: "35%",
                  padding: "5px 0",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none !important",
                  paddingLeft: "10px",
                }}
              >
                {t("myTimesheet.column.task")}
              </TableCell>
              <TableCell
                sx={{
                  width: "35%",
                  maxWidth: "35%",
                  padding: "0 16px 0 16px",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none !important",
                }}
              >
                {t("myTimesheet.column.description")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  maxWidth: "15%",
                  padding: "0 16px 0 16px",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none !important",
                  textAlign: "center",
                }}
              >
                {t("myTimesheet.column.time")}
              </TableCell>
              <TableCell
                sx={{
                  width: "15%",
                  maxWidth: "15%",
                  padding: "0 16px 0 16px",
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none !important",
                  textAlign: "center",
                }}
              >
                {t("myTimesheet.column.billable")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasksLoading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tasks?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No records to display
                </TableCell>
              </TableRow>
            ) : (
              tasks?.map((task, index) => (
                <TableRow
                  key={task.taskGuid} // Adding a unique key for each row
                  sx={{
                    maxHeight: "40px !important",
                    backgroundColor: rowID === index ? colors.light_table_background_color : "white",
                  }}
                >
                  <TableCell
                    sx={{
                      maxWidth: "150px",
                      width: "15%",
                      padding: "0 0 0 10px",
                      verticalAlign:
                        timeErrors[task.taskGuid] ||
                          // focused === task.taskGuid ||
                          (isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === "")
                          ? "top !important"
                          : "middle !important",
                      borderBottomWidth: "0 !important",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "30px",
                      }}
                      align="left"
                    >
                      {task.project}
                    </div>
                  </TableCell>
                  <TableCell
                    // className="link-cell"
                    // onClick={() => {
                    //   setTaskID(task.taskGuid);
                    //   setTaskName(task.title);
                    //   setProjectID(task.projectGuid);
                    //   setProjectName(task.project);
                    //   setOpenModal(true);
                    // }}
                    sx={{
                      maxWidth: "450px",
                      width: "35%",
                      padding: "0 0 0 10px",
                      verticalAlign:
                        timeErrors[task.taskGuid] ||
                          // focused === task.taskGuid ||
                          (isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === "")
                          ? "top !important"
                          : "middle !important",
                      borderBottomWidth: "0 !important",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        height: "30px",
                      }}
                      align="left"
                    >
                      {task.title}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "35%",
                      width: "35%",
                      padding: "0 16px",
                      verticalAlign:
                        timeErrors[task.taskGuid] ||
                          // focused === task.taskGuid ||
                          (isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === "")
                          ? "top !important"
                          : "middle !important",
                      borderBottomWidth: "0 !important",
                      "& .MuiFormControl-root": {
                        width: "80% !important",
                        margin: "5px 0 !important",
                      },
                    }}
                  >
                    <TextField
                      multiline
                      value={task.description}
                      sx={{
                        "& .MuiInputBase-input": {
                          paddingTop: "0 !important",
                          paddingBottom: "0 !important",
                          textAlign: "left",
                          fontSize: "13px !important",
                          minHeight: "32px !important",
                        },
                        "& .MuiInputBase-root": {
                          paddingTop: "0 !important",
                          paddingBottom: "0 !important",
                        },
                      }}
                      onBlur={(e) => {
                        setFocused(null);
                        setRowID(null);
                      }}
                      onFocus={(e) => {
                        setFocused(task.taskGuid);
                        setRowID(index);
                      }}
                      onChange={(e) => {
                        handleChangeDescription(task.taskGuid, e.target.value);
                      }}
                      error={isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === ""}
                      required={isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === ""}
                      helperText={
                        isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === "" &&
                        t("myTimesheet.error.description")
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "15%",
                      width: "15%",
                      padding: "0 16px",
                      verticalAlign:
                        timeErrors[task.taskGuid] ||
                          // focused === task.taskGuid ||
                          (isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === "")
                          ? "top !important"
                          : "middle !important",
                      borderBottomWidth: "0 !important",
                      "& .MuiFormControl-root": {
                        height: "auto",
                        margin: "5px 0 !important",
                      },
                    }}
                  >
                    <TextField
                      value={task.duration}
                      error={Boolean(timeErrors[task.taskGuid])}
                      helperText={timeErrors[task.taskGuid]}
                      sx={{
                        ".MuiInputBase-input": {
                          paddingTop: "0 !important",
                          paddingBottom: "0 !important",
                          textAlign: "center",
                          fontSize: "13px !important",
                          minHeight: "32px !important",
                        },
                        ".MuiFormHelperText-root": {
                          marginTop: "0 !important",
                        },
                      }}
                      onChange={(e) => handleChangeTime(task.taskGuid, e.target.value)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "15%",
                      width: "15%",
                      padding: "0 16px",
                      verticalAlign:
                        timeErrors[task.taskGuid] ||
                          // focused === task.taskGuid ||
                          (isTimeEntryDescriptionMandatory && task.duration > 0 && task.description === "")
                          ? "top !important"
                          : "middle !important",
                      borderBottomWidth: "0 !important",
                      "& .MuiFormControl-root": {
                        height: "auto",
                        margin: "5px 0 !important",
                      },
                    }}
                  >
                    <Field
                      as={FormControlLabel}
                      name={"billable"}
                      control={
                        <Switch
                          size="small"
                          checked={task?.billable}
                          onChange={(e) => {
                            handleChangeBillable(task?.taskGuid, e.target.checked);
                          }}
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>

        </Table>
      </TableContainer>
    </>
  );
}
