import * as React from 'react';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';

export default function LinearProgressCountUp({ progress }) {
  

  return (
    <div
      style={{
        width: '80%',
        height: '25px',
        backgroundColor: 'rgba(240, 240, 240, 0.7)', // Lighter grey background with opacity
        borderRadius: '5px',
        marginBottom: '1px',
        overflow: 'hidden',
        display: 'flex',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: `${parseFloat(progress).toFixed(0)}%`,
          height: '100%',
          backgroundColor: 'rgba(24, 117, 214, 0.5)',
          borderRadius: '5px',
          transition: 'width 0.3s ease',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#333',
          fontSize: '12px',
          fontWeight: 'bold', // Make the font weight bolder
          marginTop: '1px',
        }}
      >
        {progress > 200 ? '200%+' : `${parseFloat(progress).toFixed(0)}%`}
      </div>
    </div>
  );
}
