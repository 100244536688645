import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    description,
    paid,
    accrued,
    accumulated,
    numberOfDays,
    employmentType,
    assignDate,
    seniorities,
    dateUnit,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [description.name]: Yup.string().required(description.requiredMessage),
    [paid.name]: Yup.bool(),
    [dateUnit.name]: Yup.object().required(
      dateUnit.requiredMessage
    ),
    [accrued.name]: Yup.bool(),
    [accumulated.name]: Yup.bool(),
    [employmentType.name]: Yup.object().required(
      employmentType.requiredMessage
    ),
    [assignDate.name]: Yup.object().required(assignDate.requiredMessage),
    [seniorities.name]: Yup.array(),
    [numberOfDays.name]: Yup.number()
      .nullable()
      .when([paid.name, seniorities.name], {
        is: (paid, seniorities) =>
           (seniorities && seniorities.length !== 0) && paid,
        then: () => Yup.number().required(numberOfDays.requiredMessage),
      }),
  }),
];

export default validation;