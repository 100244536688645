import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { newPassword, confirmPassword },
} = checkout;

export default [
  Yup.object().shape({
    [newPassword.name]: Yup.string().required(newPassword.requiredMessage)
    .min(8, newPassword.requiredMessage)
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,}$/, newPassword.matches),
    [confirmPassword.name]: Yup.string().required(confirmPassword.requiredMessage)
    .min(8, confirmPassword.requiredMessage)
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,}$/, confirmPassword.matches),
  }),
];
