import componentDisplay from "../../../../componentDisplay";
import UTC2Local, { formatDateTimeToString } from "../../../../UTC2Local";

export const getTable = (actions) => {
    return {
      columns: [
        {
          id: "resourceName",
          label: "exportExcel.list.column.resourceName",
          align: "left",
          visible: true,
          renderCell: (row) => {
            return row?.resourceName;
          },
        },
        {
          id: "taskTitle",
          label: "exportExcel.list.column.taskTitle",
          align: "left",
          width: "15vw",
          visible: true,
          renderCell: (row) => {
            return row?.taskTitle;
          },
        },
        {
          id: "description",
          label: "exportExcel.list.column.description",
          align: "left",
          width: "10vw",
          visible: true,
          renderCell: (row) => {
            return row?.description;
          },
        },
        {
          id: "date",
          label: "exportExcel.list.column.date",
          align: "center",
          visible: true,
          renderCell: (row) => {
            return row?.date;
            formatDateTimeToString(row?.date, false); ;
          },
        },
        {
          id: "duration",
          label: "exportExcel.list.column.duration",
          align: "center",
          visible: true,
          renderCell: (row) => {
            return row?.duration;
          },
        },
        {
          id: "billable",
          label: "exportExcel.list.column.billable",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            let bool = row?.billable === "TRUE" || row?.billable === "True" || row?.billable === "true" || row?.billable === true ? true : false;
            return componentDisplay({compName: "IconBadge", compProps: {success: bool}});
          },
        },
       {id:"actions",
       align:"center",
       label:"exportExcel.list.column.action",
       visible:true,
       sticky:true,
       renderCell:(row) =>  { 
        return actions({compName:'EditRecord', compProps:row}); 
       },
      } 
      ],
      defaultSort: "employee",
      defaultSortDirection: "asc",
    };
  };
