import React, { useState, useEffect } from "react";

import {
  Paper,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  IconButton,
  Tooltip,
} from "@mui/material";

import colors from "../../../../assets/theme/light/colors";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Link } from "react-router-dom";

//uuid generator
import { v4 as uuidv4 } from "uuid";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TaskIcon } from "../../../../components/Icon";

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "10px !important",
          borderRadius: "2px !important",
          backgroundColor: "white !important",
          boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
          color: "black",
        },
      },
    },
  },
});

export function TimeByExternalReferenceTable({
  t,
  data,
  handleOpenModal,
  timeByContractor,
  generateTaskRoute,
}) {
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedIDs, setExpandedIDs] = useState([]);
  const [expandedTask, setExpandedTask] = useState(false);
  const [expandedTaskIDs, setExpandedTaskIDs] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    function addAnotherIdToProjects(dataArray) {
      dataArray.forEach((row) => {
        row.tasks.forEach((task) => {
          task.selectedId = uuidv4();
        });
      });

      return dataArray;
    }

    // Example usage
    setRows(addAnotherIdToProjects(data));
  }, []);

  function handleExpand(id) {
    setExpanded(true);
    if (!expandedIDs.includes(id)) setExpandedIDs([...expandedIDs, id]);
  }

  function handleCollapse(id) {
    const updatedExpandedIDs = expandedIDs.filter(
      (expandedID) => expandedID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpanded(false);
    setExpandedIDs(updatedExpandedIDs);
  }

  function handleExpandTask(id) {
    setExpandedTask(true);
    if (!expandedTaskIDs.includes(id))
      setExpandedTaskIDs([...expandedTaskIDs, id]);
  }

  function handleCollapseTask(id) {
    const updatedExpandedIDs = expandedTaskIDs.filter(
      (expandedTaskID) => expandedTaskID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpandedTask(false);
    setExpandedTaskIDs(updatedExpandedIDs);
  }

  function handleExpandAll() {
    const emplArr = [];
    const projectArr = [];
    rows.forEach((row) => {
      const { tasks: employees } = row;
      setExpanded(true);
      projectArr.push(row.id);
      employees.forEach((empl) => {
        setExpandedTask(true);
        emplArr.push(empl.selectedId);
      });
    });
    setExpandAll(true);
    setExpandedIDs(projectArr);
    setExpandedTaskIDs(emplArr);
  }

  function handleCollapseAll() {
    setExpandAll(false);
    setExpanded(false);
    setExpandedIDs([]);
    setExpandedTaskIDs([]);
  }

  function Row(props) {
    const { row, collapsible, parent, parentName, key } = props;
    const { tasks: employees } = row;

    return (
      <React.Fragment>
        {row.task == true ? (
          <TableRow>
            <TableCell size="small" sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row.id)}
                    />
                  ) : expanded && expandedIDs.includes(row.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row.id)}
                    />
                  )}
                </IconButton>
              )}
              {!collapsible && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ visibility: "hidden" }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              )}
            </TableCell>
            {/* <TableCell sx={{ width: "5%" }}>{row.id}</TableCell> */}
            <TableCell
              sx={{ width: "50%", padding: "0 16px 0 26px" }}
              component={Link}
              align="left"
              className="link-cell task"
              to={generateTaskRoute(row, parentName, parent)}
              onClick={(event) => {
                event.preventDefault();
                handleOpenModal(event, row, parentName, parent);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "50px",
                    }}
                  >
                    <TaskIcon />
                  </span>
                )}
                {`${row.name}`}
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "30%",
                padding: "0 47px 0 16px",
              }}
            >
              {row.totalOfHours}
            </TableCell>
           {!timeByContractor && <TableCell
              align="center"
              sx={{
                width: "30%",
              }}
            />}
          </TableRow>
        ) : (
          // row.task === false &&
          <TableRow>
            <TableCell sx={{ width: "5%", padding: "0 16px" }}>
              {collapsible && (
                <IconButton aria-label="expand row" size="small">
                  {expanded && expandedIDs.length == 0 ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row.id)}
                    />
                  ) : expanded && expandedIDs.includes(row.id) ? (
                    <KeyboardArrowUpIcon
                      onClick={() => handleCollapse(row.id)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      onClick={() => handleExpand(row.id)}
                    />
                  )}
                </IconButton>
              )}
            </TableCell>
            <TableCell
              sx={{ width: "50%", padding: "0 16px", fontWeight: "500" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                {!collapsible && (
                  <span
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                    }}
                  >
                    <TaskIcon />
                  </span>
                )}
                {`${row.name}`}
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "30%",
                padding: "0 50px 0 16px",
                fontWeight: "bold",
              }}
            >
              {row.totalOfHours}
            </TableCell>
          </TableRow>
        )}

        {collapsible &&
          expanded &&
          expandedIDs.length != 0 &&
          expandedIDs.includes(row.id) && (
            <>
              {employees &&
                employees.map((employee) => {
                  return (
                    <React.Fragment key={employee?.id}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "5%",
                            textAlign: "end",
                            padding: "0 16px",
                          }}
                        >
                          {collapsible && (
                            <IconButton aria-label="expand row" size="small">
                              {expandedTask &&
                              expandedTaskIDs.includes(employee?.selectedId) ? (
                                <KeyboardArrowUpIcon
                                  onClick={() =>
                                    handleCollapseTask(employee?.selectedId)
                                  }
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  onClick={() =>
                                    handleExpandTask(employee?.selectedId)
                                  }
                                />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ width: "50%", padding: "0 16px 0 26px" }}
                        >
                          {employee?.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            width: "30%",
                            padding: "0 50px 0 16px",
                          }}
                        >
                          {employee?.totalOfHours}
                        </TableCell>
                      </TableRow>
                      {/* {collapsible && (
                        <TableRow
                          sx={{
                            display:
                              expandedIDs.length != 0 &&
                              expandedIDs.includes(row.id) &&
                              expandedTask &&
                              expandedTaskIDs.length != 0 &&
                              expandedTaskIDs.includes(employee.selectedId)
                                ? "table-row"
                                : "none",
                          }}
                        >
                          <TableCell
                            colSpan={!timeByContractor ? 4 : 3}
                            sx={{
                              padding: 0,
                              borderBottom: "none",
                            }}
                          >
                            <Collapse
                              in={expandedTask}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Table>
                                <TableBody>
                                  {employee.tasks &&
                                    employee.tasks.map((task) => {
                                      return (
                                        <Row
                                          key={task.id}
                                          ancestor={row.id}
                                          parent={employee.id}
                                          parentName={employee.name}
                                          row={task}
                                          collapsible={false}
                                        />
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )} */}
                    </React.Fragment>
                  );
                })}
            </>
          )}
      </React.Fragment>
    );
  }
  const MyTableRow = ({ project, task, employee }) => (
    <TableRow>
      <TableCell>{project?.name}</TableCell>
      <TableCell>{task?.name}</TableCell>
      <TableCell>{employee?.name}</TableCell>
      <TableCell>{parseFloat(employee?.totalOfHours)?.toFixed(2)}</TableCell>
    </TableRow>
  );

  return (
    <>
      {rows.length > 0 ? (
        <TableContainer component={Paper} className={"table-timesheet-height"}>
          <Table size="small" sx={{ overflowX: "auto" }}>
            <TableHead>
          <TableRow>
            <TableCell>{t("timeByEmployee.project")}</TableCell>
            <TableCell>{t("timeByEmployee.task")}</TableCell>
            <TableCell>{t("timeByEmployee.employee")}</TableCell>
            <TableCell>{t("timeByEmployee.total")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((project, index) =>
            project?.tasks.map((task, taskIndex) =>
              task.employees.map((employee, employeeIndex) => (
                <MyTableRow
                  key={`${index}-${taskIndex}-${employeeIndex}`}
                  project={project}
                  task={task}
                  employee={employee}
                />
              ))
            )
          )}
        </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
}
