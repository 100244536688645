import { gql } from "@apollo/client";

export const GET_ALL_LOGS_LIST=gql`
query ($model: GetLogsListRequestInput!) {
    GetLogsList(model: $model) {
      logsListView {
        logs {
          log {
            logGuid
            logID
            tenant{
              name
            }
            middlewareJob {
              name
            }
            status
            logDate
            logEndDate
          }
          hasDetails
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`

export const GET_LOG_DETAILS=gql`
query ($model: GetLogDetailsRequestInput!) {
  GetLogDetailsList(model: $model) {
    logDetailsListView {
      logDetails {
        logDetailGuid
        logDetailID
        description
        additionalInfo
        status
        logDetailTimeStamp
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const DELETE_LOGS = gql`
mutation ($model: DeleteLogsRequestInput!) {
  DeleteLogsList(model: $model) {
    deleteLogsListResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`