import React from "react";

//components
import { Grid, Box, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";
import TextField from "../../../../../components/TextFields/TextField";

//translation
import { useTranslation } from "react-i18next";

import "./style.css";

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    fromTenant,
    lincenseTypeList,
  } = formData;

  const { licenseType, numberOfLicenses, numberOfUsedLicenses } = formField;

  const {
    licenseType: licenseTypeV,
    numberOfLicenses: numberLicensesV,
    numberOfUsedLicenses: numberUsedLicensesV,
  } = values;
  
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("tenantForm.licensesInformation")}
          </Title>
          <Grid container spacing={2} display="flex" alignItems="end">
            <Grid item sm={12} md={4} lg={4} xl={4}>
              {lincenseTypeList && lincenseTypeList.length > 0 && (
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": { fontSize: "14px !important" },
                  }}
                >
                  <InputLabel id="type" sx={{fontSize: "14px !important"}}>{t(licenseType.label)}</InputLabel>
                  <Select
                    labelId="type"
                    id={licenseType.name}
                    value={licenseTypeV}
                    disabled={!fromTenant}
                    onChange={(event, value) => {
                      setFieldValue(licenseType.name, value?.props?.value);
                    }}
                  >
                    {lincenseTypeList.map((type, index) =>
                      type === licenseTypeV ? (
                        <MenuItem key={type.value} value={type.value} selected>
                          {type.description}
                        </MenuItem>
                      ) : (
                        <MenuItem key={type.value} value={type.value}>
                          {type.description}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                disabled={!fromTenant}
                label={t(numberOfLicenses.label)}
                value={numberLicensesV || 0}
                onBlur={(e) => {
                  setTouched({ ...touched, [numberOfLicenses.name]: true });
                }}
                onChange={(e) => {
                  setFieldValue(
                    numberOfLicenses.name,
                    parseInt(e.target.value) || 0
                  );
                }}
                error={
                  touched[numberOfLicenses.name] && errors[numberOfLicenses.name]
                }
                helperText={
                  touched[numberOfLicenses.name] &&
                  errors[numberOfLicenses.name] &&
                  t(errors[numberOfLicenses.name])
                }
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <TextField
                fullWidth
                disabled
                label={t(numberOfUsedLicenses.label)}
                value={numberUsedLicensesV || 0}
                onBlur={(e) => {
                  setTouched({ ...touched, [numberOfUsedLicenses.name]: true });
                }}
                onChange={(e) => {
                  setFieldValue(
                    numberOfUsedLicenses.name,
                    parseInt(e.target.value) || 0
                  );
                }}
                error={
                  touched[numberOfUsedLicenses.name] &&
                  errors[numberOfUsedLicenses.name]
                }
                helperText={
                  touched[numberOfUsedLicenses.name] &&
                  errors[numberOfUsedLicenses.name] &&
                  t(errors[numberOfUsedLicenses.name])
                }
                formData={formData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
