import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import { GET_UNASSIGNED_EMPLOYEES } from "../../../../graphql/team";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import { getTable } from "./_table";
import { GET_EMPLOYEES_BY_ROLE } from "../../../../graphql/employee";
import { useParams } from "react-router-dom";

const UnassignedEmployeesList = ({ roleGuid = null, fromProjectPermission = false, pageSize = 20, handleClick = {} }) => {

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();
  const { rID } = useParams();
  const { manageUsers } = userRoles;
  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (columns.length === 0) {
      const col = getTable();
      
      if (col) {
        setOrderBy(col.defaultSort);
        setOrder(col.defaultSortDirection);
        setColumns(col.columns);
      }
    }
  }, [columns.length]);

  const {
    mfunction: getAllEmployees,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_EMPLOYEES_BY_ROLE, "GetCompanyUsersList");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployees(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: "FirstName",
              orderDirection: order,
              keyWord: search,
              roleGuid: rID || roleGuid,
              tenantID: storeUserTenant,
              userID: storeUserGuid,
              assignNewRole: true,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if(columns.length > 0) fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columns.length]);

  const rows =
    data &&
      data.GetCompanyUsersList &&
      data.GetCompanyUsersList &&
      data.GetCompanyUsersList.usersList.employees
      ? data.GetCompanyUsersList.usersList.employees
      : [];
  const numberOfRecords =
    data &&
      data.GetCompanyUsersList &&
      data.GetCompanyUsersList.usersList.totalNumberOfRecords
      ? data.GetCompanyUsersList.usersList.totalNumberOfRecords
      : [];

  const customButtons = [
    {
      label: "employeeForm.addButton",
      handleClick: () => handleClick(selected),
      condition: selected && selected.length > 0 && manageUsers === "M",
    }
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText:  "employee.list.search.label",
    readOnly: manageUsers !== "M",
    removeFilters: true,
    hideEditColumns: true,
    customButtons,
    heightClassName: fromProjectPermission ? "table-pop-up-height" : "",
  };

  return (
    <div>
      {loading  && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) :  (
        <DataTable {...pagination} identifier={"employeeGuid"} minWidth={500} hasCheckbox />
      )}
    </div>
  );
};

export default UnassignedEmployeesList;
