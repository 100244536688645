// ReleaseCard.js
import React, { useState, useEffect } from "react";
import { Box, Stack, Pagination } from "@mui/material";
import ReactQuill from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css';
import "./card.css";

const ReleaseCard = ({ rows: data, selectedReleaseIndex, setSelectedReleaseIndex }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 1;
  const totalNumOfPages = data?.length;

  useEffect(() => {
    // Update page when selectedReleaseIndex changes
    if (selectedReleaseIndex >= 0 && selectedReleaseIndex < totalNumOfPages) {
      setPage(Math.floor(selectedReleaseIndex / rowsPerPage));
    }
  }, [selectedReleaseIndex, totalNumOfPages, rowsPerPage]);

  const handlePaginationChange = (event, value) => {
    setPage(value - 1);
  };


  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row, index) => (
          <div
          key={index}
          className="square-card"
        >
          <ReactQuill
            name="body"
            value={row.description}
            readOnly={true}
          />
        </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <Stack spacing={2}>
          <Pagination
            count={totalNumOfPages}
            onChange={handlePaginationChange}
            page={page + 1}
            defaultPage={1}
            hideNextButton={page === totalNumOfPages}
          />
        </Stack>
      </div>
    </Box>
  );
};

export default ReleaseCard;
