import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {  
    company,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [company.name]:Yup.object().required(company.requiredMessage)
  }),
];

export default validation;