import colors from "../../../../assets/theme/light/colors";

export const getDataTableData = (t) => {
    const {red_color}= colors;
    return {
      columns: [
        {
            id: "firstName",
            label: "users.list.column.firstName",
            align: "left",
            visible: true,
            renderCell: (row) => {
              return (
                (row?.firstName || "") +
                " " +
                (row?.lastName || "")
              );
            },
          },
          {
            id: "email",
            label: "users.list.column.email",
            align: "left",
            visible: true,
            renderCell: (row) => {
              return row?.email;
            },
          },
          {
            id: "isLocked",
            label: "users.list.column.isLocked",
            align: "center",
            visible: true,
            renderCell: (row) => {
              return (row?.locked === true ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke={red_color} fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z" />
              <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
              <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
            </svg>) : null);
            },
          },
      ],
    };
  };
  