import React, { useEffect, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";

//Exterior Components
import { Typography, Grid } from "@mui/material";

// formik components
import { Formik, Form } from "formik";

//colors
import colorLight from "../../assets/theme/light/colors";

//links
import urls from "../../urls";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useMutationAuth from "../../hooks/useMutationAuth";
import useQueryAuth from "../../hooks/useQueryAuth";

//graphql
import { SUBSCRIBE_TO_ACTIV } from "../../graphql/user";
import { GET_ALL_TIMEZONES } from "../../graphql/timezone";
import { GET_ALL_COUNTRIES } from "../../graphql/location";

//variables
import IALoadingButton from "../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../components/Cards/ContainerCard";
import AutoComplete from "../../components/AutoComplete";

import HorizontalLogo from "../../assets/images/activ_horizontal.png";

import * as yup from "yup";

import RoundedTextField from "../../components/TextFields/RoundedTextField";
import colors from "../../assets/theme/light/colors";
import { useSnackbar } from "notistack";

import { v4 as uuidv4 } from "uuid";

const Subscription = ({ registrationCompleted }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse the search string to get the search parameters
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the 'subscribeFrom' parameter
  const subscribeFromValue = searchParams.get('subscribedFrom');

  const {enqueueSnackbar} = useSnackbar();

  const { t } = useTranslation();

  const validationSchema = yup.object({
    firstName: yup.string().required(t("subscription.firstName.required")),
    lastName: yup.string().required(t("subscription.lastName.required")),
    tenantDescription: yup
      .string()
      .required(t("subscription.tenantDescription.required")),
    timezone: yup.object().required(t("subscription.timezone.required")),
    email: yup.string().email(t("subscription.email.invalidFormat")).required(t("subscription.email.required")),
    phone: yup
      .string()
      .matches(/^\+\d{1,4}\s?\d{1,}$/g, { message: t("phoneNumber.format") })
      .required(t("subscription.phone.required")),
    country: yup.object().required(t("subscription.country.required")),
    position: yup.string().required(t("subscription.position.required")),
    subscribedFrom: yup.string().nullable(),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    tenantDescription: "",
    timezone: null,
    email: "",
    phone: "",
    position: "",
    country: null,
    subscribedFrom: ""
  };

  const { light_logo_blue } = colorLight; //colors

  const { login, subscription_success } = urls; //urls

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById("close-btn");
    element?.addEventListener("click", myFunction);

    function myFunction() {
      window.open('', '_parent', '').close();
    }
  }, [])

  const {
    data: countries,
    loading: countriesLoading,
    tokenChange: countriesWait,
  } = useQueryAuth(GET_ALL_COUNTRIES, "GetAllCountries");

  const countriesList = countries?.GetAllCountries?.countries || [];

  const { data: timezonesData, loading: timezonesLoading } = useQueryAuth(
    GET_ALL_TIMEZONES,
    "GetTimezones",
    {
      skip: countriesWait,
      isWait: true,
    }
  );

  const timezonesList = timezonesData?.GetTimezones?.timezones || [];

  const { cfunction: subscribeToActiv, loading } = useMutationAuth(
    SUBSCRIBE_TO_ACTIV,
    "SubscribeToActiv"
  );

  // const [registrationCompleted, setRegistrationCompleted] = useState(true);

  const handleSubmit = (values, actions) => {
    subscribeToActiv(
      {
        variables: {
          model: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phone,
            timezoneGuid: values.timezone?.timezoneGuid,
            tenantDescription: values.tenantDescription,
            position: values.position,
            countryGuid: values.country?.countryGuid,
            subscribedFrom: subscribeFromValue
          },
        },
      },
      (response) => {
        if (response.SubscribeToActiv.subscribeToActivResponse === true) {
          actions.setSubmitting(false);
          navigate(subscription_success);
        } else {
            enqueueSnackbar(t(response["SubscribeToActiv"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            actions.setSubmitting(false);
          }
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  };

  return (
    <div className="subscription">
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
          <img
            src={HorizontalLogo}
            alt="Activ365"
            width={120}
            style={{ margin: "10px 0 0 10px" }}
          />
        </Grid>
        {registrationCompleted === false && (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              flexDirection: "row",
              paddingRight: "10px",
            }}
          >
            <Typography
              variant="caption"
              component="caption"
              sx={{ color: "#fff", width: "40%", fontSize: "14px" }}
            >
              {t("subscription.login")}
            </Typography>
            <IALoadingButton
              sx={{
                borderRadius: "50px !important",
                backgroundColor: light_logo_blue,
                boxShadow: "none !important",
                fontWeight: "bold !important",
                width: "20%",
              }}
              fullWidth
              type="submit"
              loading={loading}
              label={t("login.button")}
              onClick={() => {
                navigate(login);
              }}
            />
          </Grid>
        )}
      </Grid>
      <ContainerCard
        sx={{
          // position: "absolute",
          // top: 80,
          // bottom: "2%",
          // left: "30%",
          // right: "30%",
          position: "relative",
          height: "auto",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px"
        }}
      >
        <p
          style={{
            textAlign: "center",
            paddingTop: "30px",
            fontSize: "22px",
            fontWeight: "bold",
          }}
        >
          {registrationCompleted
            ? t("setup.completed")
            : t("subscription.setup")}
        </p>
        {registrationCompleted === false ? (
          <Formik
            innerRef={formikRef}
            key={`subscription`}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setSubmitting,
              isSubmitting,
              setFieldValue,
              setTouched,
            }) =>  {
              formikValuesRef.current = values;
              return (
              <Form id="subscription-form" autoComplete="off">
                <RoundedTextField
                  label={t("subscription.tenantDescription")}
                  fullWidth
                  name={"tenantDescription"}
                  size="small"
                  value={values.tenantDescription}
                  onChange={(e) => {
                    setFieldValue("tenantDescription", e.target.value);
                  }}
                  sx={{
                    marginTop: "30px !important",
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={(e) =>
                    setTouched({ ...touched, ["tenantDescription"]: true })
                  }
                  error={
                    touched["tenantDescription"] && errors["tenantDescription"]
                  }
                  helperText={
                    touched["tenantDescription"] &&
                    errors["tenantDescription"] &&
                    t(errors["tenantDescription"])
                  }
                  required
                />
                <RoundedTextField
                  label={t("subscription.firstName")}
                  fullWidth
                  name={"firstName"}
                  size="small"
                  value={values.firstName}
                  onChange={(e) => {
                    setFieldValue("firstName", e.target.value);
                  }}
                  sx={{
                    marginTop: "25px !important",
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={(e) =>
                    setTouched({ ...touched, ["firstName"]: true })
                  }
                  error={touched["firstName"] && errors["firstName"]}
                  helperText={
                    touched["firstName"] &&
                    errors["firstName"] &&
                    t(errors["firstName"])
                  }
                  required
                />
                <RoundedTextField
                  label={t("subscription.lastName")}
                  fullWidth
                  name={"lastName"}
                  size="small"
                  value={values.lastName}
                  onChange={(e) => {
                    setFieldValue("lastName", e.target.value);
                  }}
                  sx={{
                    marginTop: "25px !important",
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={(e) => setTouched({ ...touched, ["lastName"]: true })}
                  error={touched["lastName"] && errors["lastName"]}
                  helperText={
                    touched["lastName"] &&
                    errors["lastName"] &&
                    t(errors["lastName"])
                  }
                  required
                />
                <RoundedTextField
                  label={t("subscription.email")}
                  fullWidth
                  name={"email"}
                  size="small"
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                  }}
                  sx={{
                    marginTop: "25px !important",
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={(e) => setTouched({ ...touched, ["email"]: true })}
                  error={touched["email"] && errors["email"]}
                  helperText={
                    touched["email"] && errors["email"] && t(errors["email"])
                  }
                  required
                />
                <AutoComplete
                  options={countriesList}
                  fullWidth
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"countryGuid"}
                  value={values.country}
                  size="small"
                  onChange={(e, value) => {
                    setFieldValue("country", value);
                    if(value) {
                      setFieldValue("timezone", value?.timezone);
                      setFieldValue("phone", value?.phoneCode);
                    }
                    else {
                      setFieldValue("timezone", null);
                      setFieldValue("phone", "");
                    }
                  }}
                  loading={countriesLoading}
                  label={t("subscription.country")}
                  name={"country"}
                  formData={{
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    setTouched,
                  }}
                  variant="outlined"
                  sx={{
                    marginTop: "20px !important",
                    "& .MuiInputBase-root ": {
                      borderRadius: "50px !important",
                    },
                  }}
                  required
                />
                <AutoComplete
                  options={timezonesList}
                  fullWidth
                  getOptionLabel={(option) => (option ? option.value : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.value}
                    </li>
                  )}
                  identifier={"timezoneGuid"}
                  value={values.timezone}
                  size="small"
                  onChange={(e, value) => {
                    setFieldValue("timezone", value);
                  }}
                  loading={timezonesLoading}
                  label={t("subscription.timezone")}
                  name={"timezone"}
                  formData={{
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    setTouched,
                  }}
                  variant="outlined"
                  sx={{
                    marginTop: "20px !important",
                    "& .MuiInputBase-root ": {
                      borderRadius: "50px !important",
                    },
                  }}
                  required
                />
                <RoundedTextField
                  label={t("subscription.phone")}
                  fullWidth
                  name={"phone"}
                  size="small"
                  value={values.phone}
                  onChange={(e) => {
                    setFieldValue("phone", e.target.value);
                  }}
                  sx={{
                    marginTop: "25px !important",
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={(e) => setTouched({ ...touched, ["phone"]: true })}
                  error={touched["phone"] && errors["phone"]}
                  helperText={
                    touched["phone"] && errors["phone"] && t(errors["phone"])
                  }
                  required
                />
                <RoundedTextField
                  label={t("subscription.position")}
                  fullWidth
                  name={"position"}
                  size="small"
                  value={values.position}
                  onChange={(e) => {
                    setFieldValue("position", e.target.value);
                  }}
                  sx={{
                    marginTop: "25px !important",
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={(e) => setTouched({ ...touched, ["position"]: true })}
                  error={touched["position"] && errors["position"]}
                  helperText={
                    touched["position"] && errors["position"] && t(errors["position"])
                  }
                  required
                />
                <IALoadingButton
                  sx={{
                    borderRadius: "50px !important",
                    backgroundColor: light_logo_blue,
                    boxShadow: "none !important",
                    fontWeight: "bold !important",
                    marginTop: "50px !important",
                  }}
                  dark
                  fullWidth
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  label={t("subscription.start")}
                />
                <Typography
                  sx={{
                    fontSize: "11px",
                    margin: "10px 0 20px 0 !important",
                    textAlign: "center",
                  }}
                >
                  {t("susbscription.agreement.one")}
                  <span
                    className="link-text"
                    onClick={() =>
                      window
                        .open("https://www.activ365.cloud/termsandpolicies/")
                        .focus()
                    }
                  >
                    {t("susbscription.agreement.two")}
                  </span>
                  {t("susbscription.agreement.three")}
                  <span
                    className="link-text"
                    onClick={() =>
                      window
                        .open("https://www.activ365.cloud/termsandpolicies/")
                        .focus()
                    }
                  >
                    {t("susbscription.agreement.four")}
                  </span>
                </Typography>
              </Form>
            )}}
          </Formik>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "40px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-circle-check-filled"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                  strokeWidth="0"
                  fill={colors.light_logo_blue}
                />
              </svg>
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {t("setup.activate.one")}
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "10px",
                paddingTop: "30px",
                fontSize: "14px",
                fontWeight: "bold",
                margin: "0 10px",
              }}
            >
              {t("setup.activate.two")}
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "10px",
                paddingTop: "30px",
                fontSize: "14px",
                fontWeight: "bold",
                margin: "0 10px",
              }}
            >
              {t("setup.use.email")}
            </div>
            <div
              style={{
                textAlign: "left",
                paddingLeft: "10px",
                fontSize: "14px",
                fontWeight: "bold",
                margin: "0 10px",
                marginBottom: "50px",
              }}
            >
              {t("setup.close.tab")}
            </div>
            {/* <div style={{ marginTop: "30px", textAlign: "center" }}>
              <IALoadingButton
                sx={{
                  borderRadius: "50px !important",
                  backgroundColor: light_logo_blue,
                  boxShadow: "none !important",
                  fontWeight: "bold !important",
                  marginBottom: "20px",
                  width: "60%",
                }}
                fullWidth
                id="close-btn"
                loading={loading}
                label={t("setup.login.now")}
              />
            </div> */}
          </>
        )}
      </ContainerCard>
    </div>
  );
};

export default Subscription;
