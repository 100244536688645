import React from "react";

import ReactDOM from "react-dom";

import PropTypes from "prop-types";

import { Drawer } from "antd";


const InfoDrawer = ({
  side,
  open,
  setOpen,
  title,
  bodyStyle,
  width,
  children,
  onCloseFct,
}) => {

  const onClose = () => {
    setOpen(false);
    if (onCloseFct != undefined) onCloseFct();
  };

  return ReactDOM.createPortal(
    <Drawer
      placement={side}
      width={width ? width : "auto"}
      onClose={onClose}
      visible={open} 
      zIndex={1500}
      title={title}
      bodyStyle={bodyStyle}
    >
      {children}
    </Drawer>,
    document.getElementById("info-drawer-root")
  );
};
export default InfoDrawer;

InfoDrawer.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string,
  side: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.node,
};

InfoDrawer.defaultProps = {
  side: "right",
  open: false,
  setOpen: () => {},
  width: 350,
  title: "",
  children: <></>,
};
