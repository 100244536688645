import React, { useEffect, useRef, useState } from "react";

//external components
import { Grid, CircularProgress, Backdrop } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

//components
import QuickInfoCard from "../../../components/Cards/QuickInfoCard";
import AutoComplete from "../../../components/AutoComplete";
import Tooltip from "../../../components/Tooltip";

//quill
import ReactQuill, { Quill } from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css';
import { loginUser as userLogin } from "../../../app/reducers/userSlice";

//hooks
import { useSubscription } from "@apollo/client";
import useAuth from "../../../hooks/useAuth";
import useQueryAuth from "../../../hooks/useQueryAuth";
import useMutationAuth from "../../../hooks/useMutationAuth";

import { useTranslation } from "react-i18next";

//graphql
import {
  GET_ATTENDANCE_ANALYTICS,
  GET_ATTENDANCE_ANALYTICS_SUBSCRIPTION,
} from "../../../graphql/dayLogs";
import {
  GET_ALL_TASKS,
  GET_ALL_TASKS_SUBSCRIPTION,
  GET_OPEN_TASK,
  GET_OPEN_TASK_SUBSCRIPTION,
} from "../../../graphql/task";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../graphql/employee";

import DataClockInOut from "../Main/ClockInOut/index";
import DataTasks from "../Main/my-tasks";
import DataPending from "../Main/pending-requests";
import Timer from "../Main/Timer/index";
import DayLogs from "./day-logs";
import EarlyClockOut from "./early-clock-out";
import LateClockIn from "./late-clock-in";
import MissedClockIn from "./missed-clock-in";
import PendingRequests from "./pending-requests";
import TimeOffs from "./time-offs";
import UpcomingOfficialHolidays from "./upcoming-official-holidays";
import UpcomingTimeoffs from "./upcoming-timeoffs";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeTimer } from "../../../app/reducers/timerSlice";
import ContainerCard from "../../../components/Cards/ContainerCard";
import { CloseIcon } from "../../../components/Icon";

//urls
import urls from "../../../urls";
import colors from "../../../assets/theme/light/colors";
import Mnemonic from "../../../Mnemonics.json";
import { SubtaskIcon } from "../../../components/Icon";
import { STOP_SHOWING_RELEASE_NOTE } from "../../../graphql/releaseNotes";
import IALoadingButton from "../../../components/IAButtons/IALoadingButton";

import { v4 as uuidv4 } from "uuid";

import "../style.css";

const DailyDayLogUpdated = ({
  isEntelligence = false,
  tenant,
  setTenant,
  wait = false,
  tenantList = [],
  childRef,
  date,
}) => {
  const {
    storeUserGuid,
    storeUserTenant,
    userRoles,
    isAttendance,
    isTimeEntry,
    isManager,
    isEmployee,
    userReleaseNotes,
    timeEntryEnabled,
    user,
  } = useAuth();

  const { isAppUser, manageTimeEntry, manageAttendance, isAdmin } = userRoles;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dark_logo_blue } = colors;
  var ref1 = useRef();
  var ref2 = useRef();
  var ref3 = useRef();

  const { project_backlog, running_tasks, running_attendances } = urls;
  const dispatch = useDispatch();
  const quillRef = React.useRef();

  const [timeSpent, setTimeSpent] = useState(null);
  const [openTask, setOpenTask] = useState(null);
  const [myTasks, setMyTasks] = useState([]);
  const [missedCount, setMissedCount] = useState([]);
  const [offCount, setOffCount] = useState([]);
  const [lateCount, setLateCount] = useState([]);
  const [earlyLeaveCount, setEarlyLeaveCount] = useState([]);
  const [clockInCount, setClockInCount] = useState([]);
  const [upcomingHolidaysCount, setUpcomingHolidaysCount] = useState([]);
  const [upcomingPTOsCount, setUpcomingPTOsCount] = useState([]);
  const [requests, setRequests] = useState([]);
  const [refetchApis, setRefetchApis] = useState(false);
  const [newTask, setNewTask] = useState(false);
  const [taskType, setTaskType] = useState("task");
  const [backdrop, setBackdrop] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setOpen(false);
    const userWithoutReleaseNotes = { ...user, releaseNotes: null };
    dispatch(userLogin(userWithoutReleaseNotes));
  };
  const { mfunction: stopRelease, loading: stopLoading } = useMutationAuth(
    STOP_SHOWING_RELEASE_NOTE,
    "StopShowingThisReleaseNote"
  );

  const stopReleaseFunction = () => {
    stopRelease(
      {
        variables: {
          id: storeUserGuid,
        },
      },
      (response) => {
        handleClose();
      }
    );
  };

  const {
    data: countAmounts,
    tokenChange: attendanceAnalyticsWait,
    loading,
    refetch,
  } = useQueryAuth(GET_ATTENDANCE_ANALYTICS, "GetAttendanceAnalytics", {
    variables: {
      model: {
        tenantID: isEntelligence
          ? tenant
            ? tenant.tenantGuid
            : null
          : storeUserTenant,
        userID: storeUserGuid,
        date: new Date(date).toISOString(),
      },
    },
    skip: wait,
    isWait: true,
  });

  const {
    data: tasksList,
    loading: tasksLoading,
    tokenChange: tasksWait,
    refetch: refetchTasks,
  } = useQueryAuth(GET_ALL_TASKS, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        enableFilters: false,
        onGoingTasks: true,
        myTasks: true,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: wait || attendanceAnalyticsWait,
    isWait: true,
  });

  const {
    data: getTask,
    refetch: refetchOpenTask,
    tokenChange: taskWait,
  } = useQueryAuth(GET_OPEN_TASK, "GetOpenTask", {
    variables: {
      model: { employeeID: storeUserGuid, open: true },
    },
    skip: wait || attendanceAnalyticsWait || tasksWait,
    isWait: true,
  });

  useEffect(() => {
    if (userReleaseNotes !== null && userReleaseNotes !== undefined) {
      setOpen(true);
    }
  }, [userReleaseNotes]);

  const { data: employeesData, tokenChange: employeeWait } = useQueryAuth(
    GET_ALL_EMPLOYEES_DROPDOWN,
    "GetCompanyUsersList",
    {
      variables: {
        model: {
          enableFilters: false,
          tenantID: storeUserTenant,
          userID: storeUserGuid,
          timeEntryDisabled: true,
        },
      },
      skip: wait || attendanceAnalyticsWait || tasksWait || taskWait,
      isWait: true,
    }
  );

  const employeeList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  useSubscription(GET_ATTENDANCE_ANALYTICS_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      model: {
        tenantID: isEntelligence
          ? tenant
            ? tenant.tenantGuid
            : null
          : storeUserTenant,
        date: new Date(date).toISOString(),
        userID: storeUserGuid,
      },
    },
    onData: ({ data }) => {
      setMissedCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.missedClockinsCount || 0
      );
      setOffCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.whosOffTodayCount || 0
      );
      setLateCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.whosLateToday || 0
      );
      setEarlyLeaveCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.whoLeftEarlyToday || 0
      );
      setClockInCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.todaysClockinsCount || 0
      );
      setUpcomingHolidaysCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.upcomingHolidaysCount || 0
      );
      setUpcomingPTOsCount(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.upcomingPTOsCount || 0
      );
      setRequests(
        data?.data?.attendanceAnalyticsSubscription?.attendanceAnalytics
          ?.requests || 0
      );
    },
  });

  useSubscription(GET_OPEN_TASK_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      model: { employeeID: storeUserGuid, open: true },
    },
    onData: ({ data }) => {
      dispatch(changeTimer(0));
      if (data == null) {
        setTimeSpent(null);
      } else {
        setOpenTask(data?.data?.openTaskSubscription?.openTask);
        setTimeSpent(
          data?.data?.openTaskSubscription?.openTask?.task?.timeEntries[0]
            ?.startTime || null
        );
      }
    },
  });

  useSubscription(GET_ALL_TASKS_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      model: {
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        enableFilters: false,
        onGoingTasks: true,
        myTasks: true,
        timeEntriesCreationAllowed: true,
      },
    },
    onData: ({ data }) => {
      setMyTasks(data?.data?.allTasksSubscription?.tasks);
    },
  });

  useEffect(() => {
    if (getTask) {
      dispatch(changeTimer(0));
      setOpenTask(getTask?.GetOpenTask?.openTask);
      setTimeSpent(
        getTask?.GetOpenTask?.openTask?.task?.timeEntries[0]?.startTime || null
      );
    } else {
      setTimeSpent(null);
    }
  }, [getTask]);

  useEffect(() => {
    if (countAmounts) {
      setMissedCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.missedClockinsCount || 0
      );
      setOffCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.whosOffTodayCount || 0
      );
      setLateCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.whosLateToday || 0
      );
      setEarlyLeaveCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.whoLeftEarlyToday || 0
      );
      setClockInCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.todaysClockinsCount || 0
      );
      setUpcomingHolidaysCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.upcomingHolidaysCount || 0
      );
      setUpcomingPTOsCount(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics
          ?.upcomingPTOsCount || 0
      );
      setRequests(
        countAmounts?.GetAttendanceAnalytics?.attendanceAnalytics?.requests || 0
      );
    }
  }, [countAmounts]);

  useEffect(() => {
    if (tasksList) {
      setMyTasks(tasksList?.GetTasks?.tasks);
    }
  }, [tasksList]);

  const displayUpcoming = () => {
    return (
      <ContainerCard
        sx={{
          padding: "15px 15px",
          borderRadius: "10px",
          "& .MuiPaper-root": {
            border: "1px solid #dcd9d9ba !important",
          },
          height: { sm: "100%" },
        }}
      >
        <div>
          <p style={{ fontSize: "14px", fontWeight: "600" }}>
            {t("dashboard.upcoming")}
          </p>
        </div>

        <QuickInfoCard
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-calendar-star"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              strokeWidth="1.3"
              stroke="var(--color--light-logo-blue)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M11 21h-5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3.5" />
              <path d="M16 3v4" />
              <path d="M8 3v4" />
              <path d="M4 11h11" />
              <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
            </svg>
          }
          icon2={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-calendar-star"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              strokeWidth="1.3"
              stroke="var(--color--dark-logo-blue)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M11 21h-5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3.5" />
              <path d="M16 3v4" />
              <path d="M8 3v4" />
              <path d="M4 11h11" />
              <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
            </svg>
          }
          label={t("dashboard.upcomingHolidays")}
          title={t("dashboard.upcomingHolidays.title")}
          amount={upcomingHolidaysCount}
          color={dark_logo_blue}
          loading={loading}
          table={<UpcomingOfficialHolidays tenant={tenant} date={date} />}
        />
        {!isAppUser && (
          <QuickInfoCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-clock-pause"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M20.942 13.018a9 9 0 1 0 -7.909 7.922" />
                <path d="M12 7v5l2 2" />
                <path d="M17 17v5" />
                <path d="M21 17v5" />
              </svg>
            }
            icon2={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-clock-pause"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--dark-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M20.942 13.018a9 9 0 1 0 -7.909 7.922" />
                <path d="M12 7v5l2 2" />
                <path d="M17 17v5" />
                <path d="M21 17v5" />
              </svg>
            }
            label={t("dashboard.timeOff")}
            title={t("dashboard.timeOff.title")}
            amount={upcomingPTOsCount}
            color={dark_logo_blue}
            loading={loading}
            upcoming
            table={<UpcomingTimeoffs tenant={tenant} date={date} />}
          />
        )}
      </ContainerCard>
    );
  };

  const displayAttendance = () => {
    return (
      <div
        style={{
          ".MuiPaper-root": {
            border: "1px solid #dcd9d9ba !important",
          },
          height: "100%",
        }}
      >
        <ContainerCard
          sx={{ padding: "15px", borderRadius: "10px", height: { sm: "100%" } }}
        >
          <div>
            <p style={{ fontSize: "14px", fontWeight: "600" }}>
              {t("dashboard.attendance")}
            </p>
          </div>
          <QuickInfoCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-check"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                <path d="M15 19l2 2l4 -4" />
              </svg>
            }
            icon2={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-check"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--dark-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                <path d="M15 19l2 2l4 -4" />
              </svg>
            }
            label={t("dashboard.todaysClockins")}
            title={t("dashboard.todaysClockins.title")}
            amount={clockInCount}
            color={dark_logo_blue}
            loading={loading}
            table={<DayLogs tenant={tenant} date={date} />}
          />
          <QuickInfoCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-x"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                <path d="M22 22l-5 -5" />
                <path d="M17 22l5 -5" />
              </svg>
            }
            icon2={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-x"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--dark-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                <path d="M22 22l-5 -5" />
                <path d="M17 22l5 -5" />
              </svg>
            }
            label={t("dashboard.missedClockIns")}
            title={t("dashboard.missedClockIns.title")}
            amount={missedCount}
            color={dark_logo_blue}
            upcoming
            table={<MissedClockIn tenant={tenant} date={date} />}
            loading={loading}
          />
          <QuickInfoCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-cancel"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                <path d="M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M17 21l4 -4" />
              </svg>
            }
            icon2={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-cancel"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--dark-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                <path d="M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M17 21l4 -4" />
              </svg>
            }
            label={t("dashboard.whosOffToday")}
            title={t("dashboard.whosOffToday.title")}
            amount={offCount}
            color={dark_logo_blue}
            loading={loading}
            upcoming
            table={<TimeOffs tenant={tenant} date={date} />}
          />
          <QuickInfoCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-exclamation"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4c.348 0 .686 .045 1.008 .128" />
                <path d="M19 16v3" />
                <path d="M19 22v.01" />
              </svg>
            }
            icon2={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-exclamation"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--dark-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4c.348 0 .686 .045 1.008 .128" />
                <path d="M19 16v3" />
                <path d="M19 22v.01" />
              </svg>
            }
            label={t("dashboard.whosLate")}
            title={t("dashboard.whosLate.title")}
            amount={lateCount}
            color={dark_logo_blue}
            loading={loading}
            upcoming
            table={<LateClockIn tenant={tenant} date={date} />}
          />

          <QuickInfoCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-share"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--light-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3" />
                <path d="M16 22l5 -5" />
                <path d="M21 21.5v-4.5h-4.5" />
              </svg>
            }
            icon2={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user-share"
                width="22"
                height="22"
                viewBox="0 0 24 24"
                strokeWidth="1.3"
                stroke="var(--color--dark-logo-blue)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h3" />
                <path d="M16 22l5 -5" />
                <path d="M21 21.5v-4.5h-4.5" />
              </svg>
            }
            label={t("dashboard.whoLeftEarly")}
            title={t("dashboard.whoLeftEarly.title")}
            amount={earlyLeaveCount}
            color={dark_logo_blue}
            loading={loading}
            upcoming
            table={<EarlyClockOut tenant={tenant} date={date} />}
          />
        </ContainerCard>
      </div>
    );
  };

  const displayPendingRequests = () => {
    return (
      <ContainerCard
        sx={{
          padding: "15px",
          borderRadius: "10px",
          height: "205px",
          marginTop: "0.5rem",
          "& .MuiPaper-root": {
            border: "1px solid #dcd9d9ba !important",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <p style={{ fontSize: "14px", fontWeight: "600" }}>
            {t("dashboard.requests")}
          </p>
        </div>
        <QuickInfoCard
          isData
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-message-2-question"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.3"
              stroke="var(--color--light-logo-blue)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M8 9h8" />
              <path d="M8 13h6" />
              <path d="M14.5 18.5l-2.5 2.5l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" />
              <path d="M19 22v.01" />
              <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
            </svg>
          }
          buttonRef={ref2}
          color="#FDB64E"
          label={t("dashboard.pendingRequests")}
          title={t("dashboard.pendingRequests.title")}
          loading={false}
          skeleton={4}
          table={<PendingRequests tenant={tenant} date={date} />}
          component={
            <DataPending
              removeBorders
              data={requests}
              initialLoad={true}
              waitBatch={true}
            />
          }
        />
      </ContainerCard>
    );
  };

  const displayMyTasks = () => {
    return (
      <ContainerCard
        sx={{ padding: "15px", borderRadius: "10px", height: "100%" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            paddingBottom: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginRight: manageTimeEntry === "M" ? "5px" : "8px",
              }}
            >
              {t("dashboard.myTasks")}
            </p>
            {/* {isEmployee !== Mnemonic.ResourceTypes.External && (
              <>
                {manageTimeEntry === "M" && (
                  <Tooltip title={t("dashboard.task.addNew")}>
                    <AddCircleRoundedIcon
                      onClick={() => {
                        setTaskType("task");
                        setNewTask(true);
                      }}
                      sx={{
                        color: "var(--color--light-logo-blue)",
                        fontSize: "28px !important",
                        marginRight: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
                <SubtaskIcon
                  t={t}
                  Tooltip={Tooltip}
                  color={colors.light_logo_blue}
                  handleClick={() => {
                    setTaskType("subtask");
                    setNewTask(true);
                  }}
                />
              </>
            )} */}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Timer
              loading={false}
              show={timeSpent != null ? true : false}
              task={openTask}
              refetch={refetchOpenTask}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            {(isAdmin || isManager) && manageTimeEntry !== "N" && (
              <>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    // width: "100%",
                    textAlign: "right",
                    color: "var(--color--light-logo-blue)",
                    cursor: "pointer",
                    paddingRight: "10px",
                  }}
                  onClick={() => navigate(running_tasks)}
                >
                  {t("dashboard.runningTasks")}
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    // width: "100%",
                    textAlign: "right",
                    color: "var(--color--light-logo-blue)",
                    cursor: "pointer",
                    paddingRight: "10px",
                  }}
                >
                  |
                </span>
              </>
            )}
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                // width: "100%",
                textAlign: "right",
                color: "var(--color--light-logo-blue)",
                cursor: "pointer",
                paddingRight: "20px",
              }}
              onClick={() => navigate(project_backlog, running_tasks)}
            >
              {t("dashboard.viewAll")}
            </span>
          </Grid>
        </div>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ display: "flex" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <QuickInfoCard
              isData
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-message-2-question"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.3"
                  stroke="var(--color--light-logo-blue)"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 9h8" />
                  <path d="M8 13h6" />
                  <path d="M14.5 18.5l-2.5 2.5l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" />
                  <path d="M19 22v.01" />
                  <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
                </svg>
              }
              buttonRef={ref3}
              color="#FDB64E"
              label={t("dashboard.pendingRequests")}
              title={t("dashboard.pendingRequests.title")}
              loading={tasksLoading}
              skeleton={9}
              component={
                <DataTasks
                  removeBorders
                  data={myTasks}
                  openTask={openTask}
                  initialLoad={true}
                  waitBatch={true}
                  refetch={refetchTasks}
                  refetchOpenTask={refetchOpenTask}
                  getTask={getTask}
                  employeeList={employeeList}
                  setNewTask={setNewTask}
                  newTask={newTask}
                  type={taskType}
                  setType={(v) => setTaskType(v)}
                  wait={
                    (wait ||
                      tasksWait ||
                      taskWait ||
                      employeeWait ||
                      attendanceAnalyticsWait) &&
                    true
                  }
                />
              }
            />
          </Grid>
        </Grid>
      </ContainerCard>
    );
  };

  const displayClockInClockOut = () => {
    return (
      <ContainerCard
        sx={{
          padding: "15px",
          borderRadius: "10px",
          height: {
            xs: "240px",
            sm: "100%",
            md: "240px",
            lg: "240px",
            xl: "240px",
          },
          "&.MuiPaper-root": {
            border: "1px solid #dcd9d9ba !important",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "14px", fontWeight: "600" }}>
            {t("dashboard.clockinout")}
          </p>
          {/* <span className="data-dashboard-card" style={{paddingRight: "10px"}}>Show More</span> */}
          {(isAdmin || isManager) && manageAttendance !== "N" && (
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                // width: "100%",
                textAlign: "right",
                color: "var(--color--light-logo-blue)",
                cursor: "pointer",
                paddingRight: "10px",
              }}
              onClick={() => navigate(running_attendances)}
            >
              {t("dashboard.runningAttendances")}
            </span>
          )}
        </div>
        <QuickInfoCard
          isData
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-news"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.3"
              stroke="var(--color--light-logo-blue)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
              <path d="M8 8l4 0" />
              <path d="M8 12l4 0" />
              <path d="M8 16l4 0" />
            </svg>
          }
          label={t("dashboard.news")}
          title={t("dashboard.news.title")}
          buttonRef={ref1}
          color="#F6ED60"
          loading={false}
          skeleton={3}
          component={
            <DataClockInOut
              data={[]}
              removeBorders
              initialLoad={true}
              setRefetchApis={(v) => setRefetchApis(v)}
              refetchApis={refetchApis}
              refetchOpenTask={refetchOpenTask}
              wait={
                wait ||
                attendanceAnalyticsWait ||
                tasksWait ||
                taskWait ||
                employeeWait
              }
            />
          }
        />
      </ContainerCard>
    );
  };

  const refetch_apis = async () => {
    await refetch();
    await refetchTasks();
    await refetchOpenTask();
    setRefetchApis(true);
  };

  useEffect(() => {
    if (
      wait ||
      attendanceAnalyticsWait ||
      tasksWait ||
      taskWait ||
      employeeWait
    )
      setBackdrop(true);
    else setBackdrop(false);
  }, [wait, attendanceAnalyticsWait, tasksWait, taskWait, employeeWait]);

  function stripHtmlTags(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }
  const handleButtonClick = () => {
    if (checked) {
      stopReleaseFunction();
    }
    setOpen(false);
    const userWithoutReleaseNotes = { ...user, releaseNotes: null };
    dispatch(userLogin(userWithoutReleaseNotes));
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {open && !isEntelligence && (
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            ".MuiDialog-paper": {
              minWidth: "1150px !important",
              maxHeight: "800px !important",
            },
          }}
        >
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-gift"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1"
                  stroke="#000000"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 8m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
                  <path d="M12 8l0 13" />
                  <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
                  <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
                </svg>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}
                >
                  {t("NewReleaseNotes")}
                </Typography>
              </div>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon color={"#000000"} width={"20"} stroke={"1"} />
              </IconButton>
            </div>
            <div className="quillNotif">
              <ReactQuill
                ref={quillRef}
                name="body"
                value={userReleaseNotes?.description}
                readOnly={true}
                style={{ marginTop: "20px", marginBottom: "15px" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "auto",
              }}
            >
              <FormControlLabel
                sx={{ marginRight: "1rem", fontSize: "12px" }}
                control={
                  <Checkbox
                    sx={{ fontSize: "12px" }}
                    checked={checked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Do not show this again"
              />
              {/* Button with fit-content width */}
              <IALoadingButton
                loading={stopLoading}
                type="submit"
                onClick={handleButtonClick}
                label={t("release.close")}
              />{" "}
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Grid
        container
        spacing={1}
        mt={0}
        className="dashboard"
        justifyContent={"space-between"}
      >
        {/* <button
          ref={childRef}
          style={{ display: "none" }}
          onClick={refetch_apis}
        ></button> */}
        <Grid
          // item
          // xs={12}
          // sm={12}
          // md={9.5}
          // lg={9.5}
          // xl={9.5}
          mt={1}
          sx={{
            paddingTop: "0 !important",
            maxHeight: "calc(100vh - 177px) !important",
            "&.MuiPaper-root": {
              border: "1px solid #dcd9d9ba !important",
            },
            width: {
              md: "calc(100% - 260px)",
              xs: "100%",
              sm: "100%",
              lg: "calc(100% - 260px)",
              xl: "calc(100% - 260px)",
            },
          }}
        >
          {/* {displayNews()} */}
          {isTimeEntry && timeEntryEnabled && displayMyTasks()}
          {/* {!isAppUser &&
            isAttendance &&
            manageAttendance === "M" &&
            displayPendingRequests()} */}
        </Grid>
        <Grid
          // item
          // xs={12}
          // sm={12}
          // md={2.5}
          // lg={2.5}
          // xl={2.5}
          order={{ xl: 0, lg: 0, md: 0, sm: -1, xs: -1 }}
          sx={{
            paddingTop: "0 !important",
            width: {
              md: "250px",
              xs: "100%",
              sm: "100%",
              lg: "250px",
              xl: "250px",
            },
          }}
        >
          <Grid container spacing={1} mt={0}>
            {isEntelligence && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <AutoComplete
                  options={tenantList}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"tenantGuid"}
                  size="small"
                  value={tenant}
                  onChange={(event, value) => {
                    setTenant(value);
                  }}
                  roundedTextField
                  removeLabel
                />
              </Grid>
            )}
            {isAttendance && (
              <>
                <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
                  {displayClockInClockOut()}
                </Grid>
                <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
                  {displayUpcoming()}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4} md={12} lg={12} xl={12}>
              {isAttendance && manageAttendance === "M" && displayAttendance()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DailyDayLogUpdated;
