import React from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, Grid } from "@mui/material";
import { Title } from "../../../../components/Titles/FormTitles";

import { _help } from "../../../../helpers/help.content";
import { HelpHexIcon } from "../../../../components/Icon";
const Help = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box lineHeight={0}>
        <Title>
          <span>
            <HelpHexIcon color="var(--color--light-logo-blue)" />
          </span>
          <h5
            style={{
              color: "var(--color--light-logo-blue)",
              marginTop: "0.2rem",
            }}
          >
            {t(_help(`Profile-Pleasefollowthisguideforastrongpassword`))}:
          </h5>
        </Title>
      </Box>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <Grid container spacing={2} style={{ fontSize: "0.8rem" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {t("changePassword.first")}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {t("changePassword.second")}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {t("changePassword.third")}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {t("changePassword.fourth")}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Help;
