import checkout from "./form";

const {
  formField: {
    name,
    task,
    timesheetBy,
    hoursOfWork,
    job,
    company,
    syncTimeEntries,
    taskStatuses,
    projectRadius,
    phaseStatuses,
    attendance,
    timeEntry,
    LogCleanUpRuntime,
    LogRetentionDays,
    connector,
    middlewareJob,
    allowTimesheetReopening,
    mandatoryTimeEntryDescription,
    allowAttendanceSheetReopening,
    monthlyAttendanceSheet,
    postJournal,
    postJournalDescription,
    postUnderAndOverUsageToNonBillableJob,
    trial,
    trialExpirationDate,
    subscribedFrom,
  },
} = checkout;

export default {
  [name.name]: "",
  [projectRadius.name]: 0,
  [attendance.name]: false,
  [timeEntry.name]: false,
  [syncTimeEntries.name]: false,
  [taskStatuses.name]: null,
  [phaseStatuses.name]: null,
  [LogCleanUpRuntime.name]: null,
  [LogRetentionDays.name]: "",
  [connector.name]: null,
  [middlewareJob.name]: [],
  [timesheetBy.name]: null,
  [hoursOfWork.name]: 0,
  [job.name]: "",
  [task.name]: "",
  [allowTimesheetReopening.name]: false,
  [mandatoryTimeEntryDescription.name]: false,
  [allowAttendanceSheetReopening.name]: false,
  [monthlyAttendanceSheet.name]: null,
  [postJournal.name]: false,
  [postJournalDescription.name]: "",
  [postUnderAndOverUsageToNonBillableJob.name]: false,
  [company.name]: null,
  [trial.name]: false,
  [trialExpirationDate.name]: null,
  [subscribedFrom.name]: "",
};
