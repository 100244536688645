import React, { useEffect, useState } from "react";

//routing
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, Typography, Grid } from "@mui/material";
import styled from "styled-components";
import ReactFileReader from "react-file-reader";

import axios from "axios";

import { LOGOUT_USER } from "../../../../../graphql/user";
import { GET_ALL_EMPLOYEE_PROFILE_PICTURE } from "../../../../../graphql/employee";

//hooks
import useAuth from "../../../../../hooks/useAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";

//images
import NoUserImage from "../../../../../assets/images/no-user-image.png";
import "./style.css";
import { TailSpin } from "react-loader-spinner";

import { useSnackbar } from "notistack";
import { getEmployeeFromStore } from "../../../../../app/reducers/employeeSlice";
import { base64StringtoFile } from "../../../../../utils/base64StringtoFile";
import { parseJwt } from "../../../../../utils/parseJwt";
import apiCall from "../../../../../hooks/useRest";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

const backendURL = process.env.REACT_APP_REST_URL;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  div {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 30px;
      height: 30px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }
  }
`;
export const AvatarInputTab = styled.div`
  margin-bottom: 10px;
  position: relative;
  align-self: center;
  img {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 50%;
  }
  div {
    width: 130px;
    height: 130px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 30px;
      height: 30px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }
  }
`;

const ProfilePhoto = ({
  profileData,
  fromProfile,
  setData,
  refetchData,
  fromTab,
  wait
}) => {
  const { eID, profileID, eName } = useParams();
  const { t } = useTranslation();
  const employeePicture = useSelector(getEmployeeFromStore);

  const { jwtToken, logout } = useAuth();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(NoUserImage);
  const { enqueueSnackbar } = useSnackbar();

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  
  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEE_PROFILE_PICTURE, "GetUserByID", {
    variables: { model: {userId: eID ? eID : profileID} },
    skip: wait,
    isWait: true
  });

  useEffect(() => {
    if(employeeData) setImage(employeeData?.GetUserByID?.userView?.user?.profilePictureURL)
  }, [employeeData])

  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
  }  

  async function saveProfilePicture(data, token = null) {
    setLoading(true);
    const response = await apiCall(
      backendURL + `/User/UpdateUserProfilePicture`,
      "POST",
      data,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (response) {
      setData(response?.data?.profilePictureURL);
      refetchData();
    }
    setLoading(false);
  }

  const handleFiles = (files) => {
    setLoading(true);
    setImage(files.base64);
    const img = base64StringtoFile(files.base64, files.fileList[0].name);
    var formData = new FormData();
    formData.append("UserID", fromProfile ? profileID : eID);
    formData.append("Image", img);
    saveProfilePicture(formData);
  };
  const profileImage = employeePicture?.profilePictureURL;
  return (
    <Box>
      <Box mt={fromTab ? -1 : 3}>
        <Grid
          className="gridPhoto"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          {!fromTab ? (
            <AvatarInput className="avatarInput">
              <img
                src={image ? image : NoUserImage}
                className="userImage"
                alt="Not Found"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = NoUserImage;
                }}
              />
              {loading ? (
                <div className="loading-profile-pic">
                  <TailSpin
                    height="80"
                    width="80"
                    color="var(--color-pure-white)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : null}
            </AvatarInput>
          ) : (
            <AvatarInputTab className="avatarInput">
              <img
                className="userImage"
                src={profileImage ? profileImage : NoUserImage}
                alt="Not Found"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = NoUserImage;
                }}
              />
              {loading ? (
                <div className="loading-profile-pic">
                  <TailSpin
                    height="80"
                    width="80"
                    color="var(--color-pure-white)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : null}
            </AvatarInputTab>
          )}

          <ReactFileReader
            fileTypes={[".png", ".jpg"]}
            base64={true}
            handleFiles={handleFiles}
          >
            {!fromTab ? (
              <Typography className="uploadPhoto">
                {t("profileForm.profilPhoto.label")}
              </Typography>
            ) : (
              <Typography className="employeesName">{eName}</Typography>
            )}
          </ReactFileReader>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfilePhoto;
