import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    title,
    description,
    status,
    area,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
    externalReference,
    synced,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [synced.name]: Yup.bool().nullable(),
    [description.name]: Yup.string().nullable(),
    [externalReference.name]: Yup.string().nullable(),
    [title.name]: Yup.string().required(title.requiredMessage),
    [status.name]: Yup.object().required(status.requiredMessage),
    [EstimatedEndDate.name]: Yup.string().nullable(),
    [EstimatedStartDate.name]: Yup.string().nullable(),
    [EstimatedTime.name]: Yup.string().nullable(),
    [ActualEndDate.name]: Yup.string().nullable(),
    [ActualStartDate.name]: Yup.string().nullable(),
    [ActualTime.name]: Yup.string().nullable(),
    [area.name]: Yup.object().nullable(),
  }),
];

export default validation;