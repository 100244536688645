import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function PTOTabs({ fromTenant = false }) {
  const { ptoName, ptoID, tName, tID } = useParams();

  const { tenant_ptos_form , my_ptos_form} = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !ptoName ||
    !ptoID ||
    ptoName === "undefined" ||
    ptoName === "null" ||
    ptoName === "" ||
    ptoID === "null" ||
    ptoID === "undefined" ||
    ptoID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(ptoName);
  const encodedID = encodeURIComponent(ptoID);

  const TabLinks = [
    {
      key: 1,
      Header: t("ptoTabs.form"),
      Link: fromTenant
        ? tenant_ptos_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:ptoName/:ptoID", `/${encodedName}/${encodedID}`)
        : my_ptos_form.replace("/:ptoName/:ptoID", `/${encodedName}/${encodedID}`),
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default PTOTabs;
