import React, { useEffect, useMemo, useState } from "react";

import { useParams, useLocation, matchPath, Link, useNavigate } from "react-router-dom";

//external components
import {
  Breadcrumbs as BreadcrumbComponent,
  Typography,
} from "@mui/material";


//urls
import urls from "../../urls";

//breadcrumb arrays
import getBreadcrumbs from "../../breadcrumbs";

//hooks
import { useTranslation } from "react-i18next";

import LongText from "../../components/Text/LongText";

import ConfirmationPopup from "../../components/Popup";
import colors from "../../assets/theme/light/colors";
import { HomeIcon } from "../Icon";
import useAuth from "../../hooks/useAuth";

export default function Breadcrumbs() {
  const [unsavedChanges, setUnsavedChanges] = useState(JSON.parse(sessionStorage.getItem('unsavedChanges')));
  const [openDialog, setOpenDialog] = useState(false);
  const [routeToNavigate, setRouteToNavigate] = useState(null);
  const { isManager, userRoles } = useAuth();
  const { isAdmin } = userRoles;


  const brcrs = getBreadcrumbs(isManager, isAdmin);

  const { t } = useTranslation();

  const url_params = useParams();

  const location = useLocation();

  const navigate = useNavigate();

  const { pathname } = location;

  const { light_logo_blue } = colors;

  const [route] = useMemo(() => {
    return Object.entries(urls)
      .map(([key, value], index) => {
        if (matchPath({ path: value }, pathname)) {
          return key;
        } else {
          return null;
        }
      })
      .filter((v) => v !== null);
  }, [urls, pathname]);

  const bcs = brcrs[route];

  useEffect(() => {
    setUnsavedChanges(JSON.parse(sessionStorage.getItem('unsavedChanges')))
  }, [])
  
  const handleAgree = () => {
    sessionStorage.removeItem('unsavedChanges')
    setOpenDialog(false);
    navigate(routeToNavigate);
  };
  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <div className="breadcrumb-text">
        <BreadcrumbComponent
          maxItems={5}
          aria-label="breadcrumb"
          className="breadcrumb-text"
        >
          <Link
            to={""}
            className="breadcrumb-link"
            style={{ fontSize: "15px", color: "var(--color--light-logo-blue)" }}
            onClick={(e) => {
              if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
                setOpenDialog(true);
                e.preventDefault();
                setRouteToNavigate(urls.default);
              }
              else navigate(urls.default);
            }}
          >
            <HomeIcon
              color={light_logo_blue}
            />
          </Link>
          {bcs &&
            bcs.map((record, index) => {
              if (record.route) {
                return (
                  <Link
                    key={index}
                    to={record.route(url_params)}
                    onClick={(e) => {
                      if (JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
                        setOpenDialog(true);
                        e.preventDefault();
                        setRouteToNavigate(record.route(url_params));
                      }
                      record.route(url_params);
                    }}
                    style={{
                      fontSize: "15px",
                      color: "var(--color--light-logo-blue)",
                      fontFamily: "poppins !important",
                    }}
                    className="breadcrumbs-link breadcrumbs-text"
                  >
                    {LongText({
                      text: t(record.label, url_params),
                      length: 20,
                    })}
                  </Link>
                );
              } else {
                return (
                  <Typography
                    key={index + 1}
                    color="var(--color--light-logo-blue)"
                    sx={{ fontSize: "15px", fontFamily: "poppins !important" }}
                    className="breadcrumbs-text"
                  >
                    {LongText({
                      text: t(record.label, url_params),
                      length: 20,
                    })}
                  </Typography>
                );
              }
            })}
        </BreadcrumbComponent>
      </div>
    </>
  );
}
