import componentDisplay from "../../../../../componentDisplay";
import UTC2Local from "../../../../../UTC2Local";

export const getTable = () => {
    return {
      columns: [
        {
          id: "Resource Name",
          label: "exportExcel.list.column.resourceName",
          align: "left",
          visible: true,
          width:"20vw",
          renderCell: (row) => {
            return row?.resourceName;
          },
        },
        {
          id: "Project Name",
          label: "exportExcel.list.column.projectName",
          align: "left",
          visible: true,
          width:"20vw",
          renderCell: (row) => {
            return row?.projectName;
          },
        },
        {
          id: "taskTitle",
          label: "exportExcel.list.column.taskTitle",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            return row?.task;
          },
        },
        {
          id: "date",
          label: "exportExcel.list.column.date",
          align: "center",
          visible: true,
          renderCell: (row) => {
            return row?.date ? UTC2Local(new Date(row?.date), true) : null;
          },
        },
        {
          id: "description",
          label: "exportExcel.list.column.description",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            return row?.description;
          },
        },
       
        {
          id: "time",
          label: "exportExcel.list.column.time",
          align: "right",
          visible: true,
          renderCell: (row) => {
            return row?.time;
          },
        },
        {
          id: "billable",
          label: "exportExcel.list.column.billable",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            let bool = row?.billable === "TRUE" || row?.billable === "True" || row?.billable === "true" || row?.billable === true ? true : false;
            return componentDisplay({compName: "IconBadge", compProps: {success: bool}});
          },
        },
        {
          id: "error",
          label: "exportExcel.list.column.error",
          align: "left",
          width:"20vw",
          visible: true,
          renderCell: (row) => {
            return row?.error;
          },
        },
      ],
      defaultSort: "projectName",
      defaultSortDirection: "asc",
    };
  };
