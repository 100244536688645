import React, { useState, useEffect,useRef } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";
import { useReactToPrint } from "react-to-print";

import TimeByProjectTreeComponent from './printableComponent';
import { Grid, CircularProgress, Autocomplete } from "@mui/material";

import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import AutoComplete from "../../../../components/AutoComplete";

import { GET_TIME_BY_PROJECT } from "../../../../graphql/reports";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_CONTRACTORS } from "../../../../graphql/contractor";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import urls from "../../../../urls";

import { useSnackbar } from "notistack";

import { TimeByProjectTable } from "./_table";

import colors from "../../../../assets/theme/light/colors";

import { handleValidateDates } from "../../../../utils/functions";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import Tooltip from "../../../../components/Tooltip";
import { NextIcon, PreviousIcon } from "../../../../components/Icon";
import { DATE_FILTER_DATA, handleDateBackward, handleDateForward, handleTimeBetween } from "../../../../utils/period";

import { v4 as uuidv4 } from "uuid";

function TimeByProject({ timeByContractor = false }) {
  const { storeUserTenant, storeUserGuid, userRoles } = useAuth();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    
  });
  const { dark_logo_blue } = colors;

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const { enqueueSnackbar } = useSnackbar();

  const { my_projects_form, my_project_direct_tasks_form } = urls;

  const { manageTimeEntry } = userRoles;

  const [re, setRe] = useState(false);

  const nowDate = new Date();

  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [open, setOpen] = useState(false);

  const [projectIDs, setProjectIDs] = useState([]);
  const [employeeIDs, setEmployeeIDs] = useState([]);

  const [employeeValue, setEmployeeValue] = useState([]);
  const [projectsValue, setProjectsValue] = useState([]);
  const [contractorValue, setContractorValue] = useState(null);

  const [fetchEmployees, setFetchEmployees] = useState();
  const [fetchProjects, setFetchProjects] = useState();
  const [fetchContractors, setFetchContractors] = useState();

  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const [list, setList] = useState(null);
  const [totalProjectsTime, setTotalProjectsTime] = useState("00:00");
  
  const type = useRef("task");

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );

  const includeOptions = [
    {
      id:1,
      title:t("reports.BillableAndNonBillable.label")
    },
    {
      id:2,
      title:t("reports.onlyBillable.label")
    },
    {
      id:3,
      title:t("reports.onlyNonBillable.label")
    },
  ];

  const [includeOption, setIncludeOption] = useState({
    id:1,
    title:t("reports.BillableAndNonBillable.label")
  });

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        timeEntryDisabled: true,
      },
    },
    skip: !fetchEmployees,
    isWait: fetchEmployees,
  });

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !fetchProjects,
    isWait: fetchProjects,
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const {
    data: contractorsData,
    loading: contractorsLoading,
    tokenChange: contractorsWait,
  } = useQueryAuth(GET_ALL_CONTRACTORS, "GetContractorsList", {
    variables: {
      model: {
        tenantGuid: storeUserTenant,
      },
    },
    skip: !fetchContractors,
    isWait: fetchContractors,
  });

  const contractorsList =
    contractorsData?.GetContractorsList?.contractorsList?.contractors || [];

  const {
    mfunction: getData,
    refetch,
    loading: dataLoading,
  } = useLazyQueryAuth(GET_TIME_BY_PROJECT, "GetTimeByProject");

  // const list = projectData?.GetTimeByProject?.timeByProject?.projects || [];
  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  useEffect(() => {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    let onlyBillable = includeOption?.id == 2;
    let onlyNotBillable = includeOption?.id == 3;
    if (
      timeByContractor &&
      contractorValue !== null &&
      contractorValue !== undefined &&
      !projectsWait
    ) {
      getData(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              projectGuid: projectIDs.length > 0 ? projectIDs[0] : null,
              contractorGuid: contractorValue?.contractorGuid,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              onlyBillable: onlyBillable,
              onlyNotBillable: onlyNotBillable
            },
          },
        },
        (response) => {
          if (response["GetTimeByProject"].timeByProject != null) {
            setList(response?.GetTimeByProject?.timeByProject?.projects);
            setTotalProjectsTime(
              response?.GetTimeByProject?.timeByProject?.totalProjectsTime
            );
          } else
            enqueueSnackbar(t(response["GetTimeByProject"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
        }
      );
    }
    if (
      timeByContractor === false &&
      !employeeWait &&
      !projectsWait &&
      oneDayBefore <= dateTo
    ) {
      getData(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              projectGuids: projectIDs.length > 0 ? projectIDs : null,
              employeeGuids: employeeIDs.length > 0 ? employeeIDs : null,
              contractorGuid: timeByContractor
                ? contractorValue?.contractorGuid
                : null,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              onlyBillable: onlyBillable,
              onlyNotBillable: onlyNotBillable
            },
          },
        },
        (response) => {
          if (response["GetTimeByProject"].timeByProject != null)
            setList(response?.GetTimeByProject?.timeByProject?.projects);
          else
            enqueueSnackbar(t(response["GetTimeByProject"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
        }
      );
    }
  }, [projectsWait, employeeWait, re]);

  const handleOpenModal = (event, row, parentName, parent) => {
    event.preventDefault();
    setTaskID(row?.id);
    setProjectID(parent);
    setTaskName(row?.name);
    setProjectName(parentName);
    setOpenModal(true);
  };

  // const handleValidateDates = (value) => {
  //   if (timer) {
  //     //if timer is set, we empty it so it's not called and wait another delay
  //     clearTimeout(timer);
  //     setTimer(null);
  //   }

  //   //set timer for after 2 seconds of wait
  //   setTimer(
  //     setTimeout(() => {
  //       const oneDayBefore = new Date(dateFrom);
  //       oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
  //       if (value < oneDayBefore)
  //         enqueueSnackbar(t("employeeTimesheet.invalid.date"), {
  //           variant: "error",
  //           autoHideDuration: 5000
  //         });
  //       else setDateTo(value);
  //     }, 500)
  //   );
  // };

  return (
    <>
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={dataLoading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        taskName={taskName}
        refetch={refetch}
        fromProject={true}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            gap: "20px",

            backgroundColor: "transparent !important",
            boxShadow: "none !important",
          }}
        >
          <Grid container spacing={1}>
          <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                sx={{ marginBottom: "5px !important" }}
              >
                <AutoComplete
                  multiple={!timeByContractor}
                  loading={projectLoading}
                  options={projectsList}
                  getOptionLabel={(option) => (option ? option.title : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.title}
                    </li>
                  )}
                  identifier={"projectGuid"}
                  size="small"
                  value={timeByContractor ? projectsValue[0] : projectsValue}
                  onOpen={() => setFetchProjects(true)}
                  onClose={() => setFetchProjects(false)}
                  onChange={(event, value) => {
                    if (!value) setProjectsValue([]);
                    else {
                      setProjectsValue(value);
                      let tmp = [];
                      !timeByContractor
                        ? value.map((ids) => {
                            tmp.push(ids.projectGuid);
                          })
                        : tmp.push(value?.projectGuid);
                      setProjectIDs(tmp);
                    }

                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                    "& .MuiChip-root": {
                      marginLeft: "15px !important",
                    },
                  }}
                  label={t("reports.label.projects")}
                  roundedTextField
                />
              </Grid>
            {timeByContractor && (
              <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
                <AutoComplete
                  loading={contractorsLoading}
                  options={contractorsList}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"contractorGuid"}
                  size="small"
                  value={contractorValue}
                  onOpen={() => setFetchContractors(true)}
                  onClose={() => setFetchContractors(false)}
                  onChange={(event, value) => {
                    setContractorValue(value);
                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                    "& .MuiChip-root": {
                      marginLeft: "15px !important",
                    },
                  }}
                  roundedTextField
                  label={t("reports.label.contractor")}
                  required={timeByContractor}
                />
              </Grid>
            )}
            {/* {!timeByContractor && ( */}
            <>
            {manageTimeEntry !== "N" && !timeByContractor && (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                sx={!timeByContractor ? { marginBottom: "5px !important" } : {}}
              >
                <AutoComplete
                  multiple
                  loading={employeeLoading}
                  options={employeeList}
                  getOptionLabel={(option) =>
                    option ? option.firstName + " " + option.lastName : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.firstName + " " + option.lastName}
                    </li>
                  )}
                  identifier={"employeeGuid"}
                  size="small"
                  value={employeeValue}
                  onOpen={() => setFetchEmployees(true)}
                  onClose={() => setFetchEmployees(false)}
                  onChange={(event, value) => {
                    setEmployeeValue(value);
                    let tmp = [];
                    value.map((ids) => {
                      tmp.push(ids.employeeGuid);
                    });
                    setEmployeeIDs(tmp);
                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                    "& .MuiChip-root": {
                      marginLeft: "15px !important",
                    },
                  }}
                  roundedTextField
                  label={t("reports.label.employee")}
                />
              </Grid>
            )}
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <AutoComplete
                  options={includeOptions}
                  getOptionLabel={(option) => (option ? option.title : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.title}
                    </li>
                  )}
                  identifier={"id"}
                  size="small"
                  value={includeOption}
                  required
                  onChange={(event, value) => {
                    setIncludeOption(value);
                    
                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                    "& .MuiChip-root": {
                      marginLeft: "15px !important",
                    },
                  }}
                  roundedTextField
                  label={t("reports.label.includeOption")}
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1} lg={3} xl={3}></Grid>
            </>
            {/* )} */}
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateFrom")}
                value={dateFrom}
                // disableFuture
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                onClose={() => setOpen(true)}
                // renderInput={(params) => (
                //   <RoundedTextField removeMinWidth {...params} />
                // )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateTo")}
                value={dateTo}
                // disableFuturek
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <PreviousIcon
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={4} md={1.5} lg={1.5} xl={1.5}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{".MuiInputBase-input": {fontSize: "14px !important"}}}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <NextIcon 
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>

           
            <Tooltip
              title={
                timeByContractor ?
                manageTimeEntry !== "N" && t("reports.tooltip.contractor.search") : ""
              }
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={manageTimeEntry === "N" ? 6 : 2}
                lg={manageTimeEntry === "N" ? 5 : 2}
                xl={manageTimeEntry === "N" ? 5 : 2}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <IALoadingButton
                  onClick={() => setRe((val) => !val)}
                  // disabled={!initialLoad}
                  style={{
                    width: "150px",
                    backgroundColor: dark_logo_blue,
                    borderRadius: "50px",
                    height: "36px",
                  }}
                  // loading={initialLoad === true}
                  label={t("projectDashboard.Search")}
                />
              </Grid>
            </Tooltip>
          </Grid>
         {/* {list.length>0 &&( <><TimeByProjectTreeComponent data={list} componentRef={componentRef} />
         <Grid
                item
                xs={12}
                sm={12}
                md={manageTimeEntry === "N" ? 6 : 2}
                lg={manageTimeEntry === "N" ? 5 : 2}
                xl={manageTimeEntry === "N" ? 5 : 2}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <IALoadingButton
                 onClick={handlePrint}
                  // disabled={!initialLoad}
                  style={{
                    width: "150px",
                    backgroundColor: dark_logo_blue,
                    borderRadius: "50px",
                    height: "36px",
                  }}
                  // loading={initialLoad === true}
                  label={t("breadcrumbs.exportReport")}
                />
              </Grid></>)} */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ overflowX: "auto" }}
          >
            {dataLoading && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!dataLoading && list && (
              <TimeByProjectTable
                data={list}
                totalProjectsTime={totalProjectsTime}
                t={t}
                handleOpenModal={handleOpenModal}
                timeByContractor={timeByContractor}
                generateProjectRoute={(row) =>
                  my_projects_form.replace(
                    "/:pName/:pID",
                    `/${encodeURIComponent(
                      row?.projectTitle
                    )}/${encodeURIComponent(row?.projectGuid)}`
                  )
                }
                generateTaskRoute={(row, parentName, parent) =>
                  my_project_direct_tasks_form
                    .replace(
                      "/:pName/:pID",
                      `/${encodeURIComponent(parentName)}/${encodeURIComponent(
                        parent
                      )}`
                    )
                    .replace(
                      "/:tskName/:tskID",
                      `/${encodeURIComponent(row?.name)}/${encodeURIComponent(
                        row?.id
                      )}`
                    )
                }
              />
            )}
            {!dataLoading && (!list || list.length == 0) && (
              <div
              
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                {t("employeeTimesheet.no.timeLog")}
              </div>
            )}
          </Grid>
        </ContainerCard>
      </Grid>
    </>
  );
}

export default TimeByProject;
