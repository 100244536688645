import { gql } from "@apollo/client";

export const GET_ALL_TEAMS = gql`
query ($model: GetTeamsListRequestModelInput!) {
    GetTeamsList(model: $model) {
      teamsList {
        teams {
          description
          teamGuid
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
}
`;

export const DELETE_TEAMS_LIST = gql`
  mutation ($model: TeamsDeleteRequestInput!) {
    DeleteTeams(model: $model) {
      deleteTeamResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_TEAM = gql`
  mutation ($model: TeamCreateRequestInput!) {
    CreateTeam(model: $model) {
      team {
        teamGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation ($model: TeamUpdateRequestInput!) {
    UpdateTeam(model: $model) {
      team {
        teamGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const REMOVE_TEAM = gql`
  mutation ($model: RemoveAssignedTeamsForEmployeeRequestInput!) {
    RemoveAssignedTeamsForEmployee(model: $model) {
      removeAssignedTeamsForEmployeeResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const REMOVE_EMPLOYEES = gql`
  mutation ($model: RemoveAssignedEmployeesForTeamRequestInput!) {
    RemoveAssignedEmployeesForTeam(model: $model) {
      removeAssignedEmployeesForTeamResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_EMPLOYEE_TEAMS = gql`
query($model:GetAllEmployeeTeamsRequestInput!){
  GetAllEmployeeTeams (model: $model) {
    teamEmployeesList {
      teams{
        team {
          teamGuid
          description
        }
        createdBy {
          email
        }
        creationDate
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
  }
}
`;

export const GET_TEAM_EMPLOYEES = gql`
query($model:GetAllTeamEmployeesRequestInput!){
  GetAllTeamEmployees (model: $model) {
    teamEmployeesList {
      employees{
        employee {
          employeeGuid
          firstName
          lastName
        }
        createdBy {
          email
        }
        creationDate
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
  }
}
`;

export const ASSIGN_TEAMS_TO_EMPLOYEE = gql`
mutation ($model: AssignEmployeeToTeamsRequestInput!) {
  AssignEmployeeToTeams(model: $model) {
    assignEmployeeToTeamsResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const ASSIGN_EMPLOYEES_TO_TEAM = gql`
mutation ($model: AssignTeamToEmployeesInput!) {
  AssignTeamToEmployees(model: $model) {
    assignTeamToEmployeesResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_UNASSIGNED_EMPLOYEES = gql`
query($model: GetCompanyUsersRequestInput!) {
  GetUnassignedEmployees(model: $model) {
    employeesList {
      employees {
        employeeGuid
        firstName
        lastName
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;