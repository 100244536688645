import { gql } from "@apollo/client";

export const GET_ALL_WORKSHIFTS = gql`
  query ($model: GetAllWorkShiftsRequestModelInput!) {
    GetAllWorkShifts(model: $model) {
      workShiftsList {
        workShifts {
          workShiftGuid
          totalWeeklyHours
          description
          systemGenerated
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_WORKSHIFTS = gql`
  mutation ($model: DeleteWorkShiftsRequestModelInput!) {
    DeleteWorkShiftList(model: $model) {
      crudWorkShift
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_WORKSHIFT = gql`
  mutation ($model: CreateWorkShiftInput!) {
    CreateWorkShift(model: $model) {
      crudWorkShift
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_WORKSHIFT_BY_ID = gql`
  query ($id: UUID!) {
    GetWorkShiftById(workShiftId: $id) {
      workShiftView {
        workShift {
          workShiftGuid
          description
          workShiftDays {
            workShiftDaysGuid
            dayName
            startHour
            endHour
            breakTime
            isWorkDay
            changeTime
            startOfWeek
          }
          totalWeeklyHours
        }
        editable
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_WORK_SHIFT = gql`
  mutation ($model: EditWorkShiftInput!) {
    EditWorkShift(model: $model) {
      workShift {
        workShiftGuid
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
