const form = {
    formID: "dayLog",
    formField: {
      employee: {
        name: "employee",
        label: "dayLogForm.employee.label",
        requiredMessage: "dayLogForm.employee.requiredMessage",
      },
      description: {
        name: "description",
        label: "dayLogForm.description.label",
        requiredMessage: "dayLogForm.description.requiredMessage",
      },
      date:{
        name:"date",
        label:"dayLogForm.date.label",
        requiredMessage:"dayLogForm.date.requiredMessage"
      },
      checkInTime:{
        name:"checkInTime",
        label:"dayLogForm.checkInTime.label",
        requiredMessage:"dayLogForm.checkInTime.requiredMessage",
      },
      checkOutTime:{
        name:"checkOutTime",
        label:"dayLogForm.checkOutTime.label",
        requiredMessage:"dayLogForm.checkOutTime.requiredMessage"
      },
      workType:{
        name:"workType",
        label:"dayLogForm.workType.label",
        requiredMessage:"dayLogForm.workType.requiredMessage"
      },
      location:{
        name:"location",
        label:"dayLogForm.location.label",
        requiredMessage:"dayLogForm.location.requiredMessage"
      },
    },
  };
  
  export default form;
  