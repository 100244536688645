import React from 'react';
import UTC2Local from "../../../UTC2Local";

const data = (t) => {
  return {
  title: (
   <></>
  ),
  headers: [t("dashboard.card.date"), t("dashboard.card.description")],

  render: [
    (row) => {
      return <p>{UTC2Local(row?.date, true)}</p>;
    },
    (row) => {
      return (
        <p>{row?.description} [
        {row?.officialHoliday?.description}]</p>);
    },
   
  ],
}
};

export default data;
