import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import { Grid, CircularProgress, Autocomplete } from "@mui/material";

import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import Tooltip from "../../../../components/Tooltip";

import { GET_TIME_BY_EXTERNAL_REFERENCE } from "../../../../graphql/reports";

import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import { useSnackbar } from "notistack";

import { TimeByExternalReferenceTable } from "./_table";

import colors from "../../../../assets/theme/light/colors";
import { handleValidateDates } from "../../../../utils/functions";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import { NextIcon, PreviousIcon } from "../../../../components/Icon";
import { DATE_FILTER_DATA, handleDateBackward, handleDateForward, handleTimeBetween } from "../../../../utils/period";

import { v4 as uuidv4 } from "uuid";

function TimeByExternalReference() {
  const { storeUserGuid, userRoles } = useAuth();

  const { dark_logo_blue } = colors;

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const { enqueueSnackbar } = useSnackbar();

  const { manageTimeEntry } = userRoles;

  const [re, setRe] = useState(false);

  const nowDate = new Date();

  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [open, setOpen] = useState(false);

  const [timer, setTimer] = useState(null);
  const [list, setList] = useState(null);

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );

  const {
    mfunction: getData,
    refetch,
    loading: dataLoading,
  } = useLazyQueryAuth(
    GET_TIME_BY_EXTERNAL_REFERENCE,
    "GetTimeByExternalReferences"
  );

  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  useEffect(() => {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    if (oneDayBefore <= dateTo) {
      getData(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
            },
          },
        },
        (response) => {
          if (
            response["GetTimeByExternalReferences"].timeByExternalReference !=
            null
          )
            setList(
              response?.GetTimeByExternalReferences?.timeByExternalReference
                ?.projects
            );
          else
            enqueueSnackbar(
              t(response["GetTimeByExternalReferences"].errorMessage),
              {
                variant: "error",
                autoHideDuration: 5000,
              }
            );
        }
      );
    }
  }, [re]);

  // const handleValidateDates = (value) => {
  //   if (timer) {
  //     //if timer is set, we empty it so it's not called and wait another delay
  //     clearTimeout(timer);
  //     setTimer(null);
  //   }

  //   //set timer for after 2 seconds of wait
  //   setTimer(
  //     setTimeout(() => {
  //       const oneDayBefore = new Date(dateFrom);
  //       oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
  //       if (value < oneDayBefore)
  //         enqueueSnackbar(t("employeeTimesheet.invalid.date"), {
  //           variant: "error",
  //           autoHideDuration: 5000
  //         });
  //       else setDateTo(value);
  //     }, 500)
  //   );
  // };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            gap: "20px",

            backgroundColor: "transparent !important",
            boxShadow: "none !important",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateFrom")}
                value={dateFrom}
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                onClose={() => setOpen(true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateTo")}
                value={dateTo}
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <PreviousIcon
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{".MuiInputBase-input": {fontSize: "14px !important"}}}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <NextIcon 
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Tooltip
              title={""}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={manageTimeEntry === "N" ? 6 : 2}
                lg={manageTimeEntry === "N" ? 5 : 2}
                xl={manageTimeEntry === "N" ? 5 : 2}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <IALoadingButton
                  onClick={() => setRe((val) => !val)}
                  style={{
                    width: "150px",
                    backgroundColor: dark_logo_blue,
                    borderRadius: "50px",
                    height: "36px",
                  }}
                  label={t("projectDashboard.Search")}
                />
              </Grid>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ overflowX: "auto" }}
          >
            {dataLoading && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!dataLoading && list && (
              <TimeByExternalReferenceTable data={list} t={t} />
            )}
            {!dataLoading && (!list || list.length == 0) && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                {t("employeeTimesheet.no.timeLog")}
              </div>
            )}
          </Grid>
        </ContainerCard>
      </Grid>
    </>
  );
}

export default TimeByExternalReference;
