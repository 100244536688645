import { gql } from "@apollo/client";

export const GET_ALL_DEPARTMENTS = gql`
  query ($model: GetAllDepartmentsRequestModelInput!) {
    GetAllDepartments(model: $model) {
      depList {
        departments {
          name
          departmentGuid
          tenant {
            tenantGuid
            name
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_DEPARTMENTS_DROPDOWN = gql`
  query ($model: GetAllDepartmentsRequestModelInput!) {
    GetAllDepartments(model: $model) {
      depList {
        departments {
          name
          departmentGuid
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_DEPARTMENT_BY_ID = gql`
  query ($departmentID: UUID!) {
    GetDepartment(id: $departmentID) {
      departmentOutput {
        departmentGuid
        name
        tenant {
          tenantGuid
          name
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation ($model: EditDepartmentRequestInput!) {
    EditDepartment(model: $model) {
      crudDepartment
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_DEPARTMENT = gql`
  mutation ($model: CreateDepartmentRequestInput!) {
    CreateDepartment(model: $model) {
      crudDepartment
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_DEPARTMENTS = gql`
  mutation ($model: DeleteDepartmentsRequestModelInput!) {
    DeleteDepartmentsList(model: $model) {
      crudDepartment
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
