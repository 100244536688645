import checkout from "./form";

const {
  formField: {
    employee,
    receiver,
    requestDate,
    requestType,
    requestStatus,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    attachment,
    description,
    rejectionReason,
    approvedBy,
    approvalDate,
    rejectedBy,
    rejectionDate,
    cancelledBy,
    cancellationDate,
    workType,
    location,
  },
} = checkout;

const initialValues = {
  [employee.name]: null,
  [receiver.name]: null,
  [requestDate.name]: null,
  [requestType.name]: null,
  [requestStatus.name]: null,
  [dateFrom.name]: null,
  [dateTo.name]: null,
  [timeFrom.name]: null,
  [timeTo.name]: null,
  [attachment.name]: "",
  [description.name]: "",
  [rejectionReason.name]: "",
  [approvedBy.name]: null,
  [approvalDate.name]: null,
  [rejectedBy.name]: null,
  [rejectionDate.name]: null,
  [cancelledBy.name]: null,
  [cancellationDate.name]: null,
  [workType.name]: null,
  [location.name]: null,
};

export default initialValues;
