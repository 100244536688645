import { gql } from "@apollo/client";

export const GET_ALL_ROLES = gql`
  query ($model: GetAllRolesRequestModelInput!) {
    GetAllRoles(model: $model) {
      rolesList {
        roles {
          roleGuid
          name
          description
          tenant {
            name
          }
          isSystemGenerated
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_ROLES_DROPDOWN = gql`
  query ($model: GetAllRolesRequestModelInput!) {
    GetAllRoles(model: $model) {
      rolesList {
        roles {
          roleGuid
          name
          isSystemGenerated
          isAdmin
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation ($model: DeleteRolesRequestModelInput!) {
    DeleteRolesList(model: $model) {
      crudRole
      errorCode
      errorMessage

      errorVariables
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation ($model: CreateRoleRequestInput!) {
    CreateRole(model: $model) {
      crudRole
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation ($model: EditRoleRequestInput!) {
    EditRole(model: $model) {
      crudRole
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ROLE_BY_ID = gql`
  query ($id: UUID!) {
    GetRole(id: $id) {
      role {
        roleObject {
          roleGuid
          description
          name
          manageAttendance
          manageTimeEntry
          manageParameters
          manageSystemConfigurations
          manageUsers
          isSystemGenerated
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
