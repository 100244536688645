import React from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";

import { _help } from "../../../../../helpers/help.content";
const Help = ({ data, title }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box lineHeight={0} className="flex_space-between_center">
        <Title style={{padding: "10px 0"}}>{t(title)}</Title>
      </Box>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <h4>{t(_help(`${data}-title`))}</h4>
        <p>{t(_help(`${data}-text`))}</p>
      </Box>
    </Box>
  );
};

export default Help;
