import { gql } from "@apollo/client";

export const GET_USER_TABLE_PREFERENCES = gql`
  query ($model: TableColumnsRequestModelInput!) {
    GetUserTablePreferences(model: $model) {
      columns
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const SAVE_USER_TABLE_PREFERENCES = gql`
  mutation ($model: UserPreferencesSaveRequestModelInput!) {
    SaveUserTablePreferences(model: $model) {
      tablePreferences {
        userTablePreferencesGuid
        columns
        defaultSort
        defaultSortDirection
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const REMOVE_USER_TABLE_PREFERENCES = gql`
  mutation ($model: UserPreferencesSaveRequestModelInput!) {
    RemoveUserTablePreferences(model: $model) {
      removedTablePreferences
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
