import React from "react";

//styles
import "./auth-style.css";

//components
import AuthCard from "../../components/AuthCard";

//pages
import Login from "../../pages/authentication/login";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import OTP from "./otp";
import Subscription from "./subscription";

const Authentication = ({ page = "" }) => {
  const display = () => {
    switch (page) {
      case "subscription":
        return <Subscription registrationCompleted={false} />;
      case "subscription-success":
        return <Subscription registrationCompleted={true} />;
      case "login":
        return <Login />;
      case "forgot-password":
        return <ForgotPassword />;
      case "reset-password":
        return <ResetPassword />;
      case "otp":
        return <OTP />;
      default:
        return <></>;
    }
  };

  return (
    <div>
      {page === "subscription" || page === "subscription-success" ? (
        <>{display()}</>
      ) : (
        <AuthCard>{display()}</AuthCard>
      )}
    </div>
  );
};

export default Authentication;
