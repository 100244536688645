import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYMENT_STATUSES = gql`
  query {
    GetAllEmploymentStatuses {
      employmentStatusesOutput {
        employementStatusGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
