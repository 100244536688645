export const DATE_FILTER_DATA = (t) => {
  return [
  {
    name: t("dateFilter.today"),
    mnemonic: "today",
  },
  {
    name: t("dateFilter.last7Days"),
    mnemonic: "week",
  },
  {
    name: t("dateFilter.last30Days"),
    mnemonic: "month",
  },
  {
    name: t("dateFilter.custom"),
    mnemonic: "custom",
  },
]
};

export const handleDateBackward = (dateFrom, dateTo, dateVal, setDateFrom, setDateTo) => {
    let newDateFrom = new Date(dateFrom);
    let newDateTo = new Date(dateTo);

    switch (dateVal.mnemonic) {
      case "today":
        newDateFrom.setDate(newDateFrom.getDate() - 1);
        newDateTo.setDate(newDateTo.getDate() - 1);
        break;
      case "week":
        const currentDayOfWeek = newDateFrom.getDay();
        const diff = (currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek) - 7;
        const monday = new Date(newDateFrom);
        monday.setDate(monday.getDate() + diff);
        const sunday = new Date(monday);
        sunday.setDate(sunday.getDate() + 6);
        newDateFrom = monday;
        newDateTo = sunday;
        break;
      case "month":
        const currentMonth = newDateFrom.getMonth();
        if (currentMonth === 0) {
          newDateFrom.setFullYear(newDateFrom.getFullYear() - 1);
          newDateTo.setFullYear(newDateTo.getFullYear() - 1);
          newDateFrom.setMonth(11);
          newDateTo.setMonth(12);
        } else {
          newDateFrom.setMonth(currentMonth - 1);
          newDateTo.setMonth(currentMonth);
        }

        newDateFrom.setDate(1);
        newDateTo.setDate(0);
        break;
      default:
        break;
    }

    setDateFrom(newDateFrom);
    setDateTo(newDateTo);
  };

  
export const handleDateForward = (dateFrom, dateTo, dateVal, setDateFrom, setDateTo) => {
    switch (dateVal.mnemonic) {
      case "today":
        let tomorrow = new Date(dateTo);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setDateFrom(tomorrow);
        setDateTo(tomorrow);
        break;
      case "week":
        const currentMonday = new Date(dateFrom);
        const nextMonday = new Date(currentMonday);
        nextMonday.setDate(nextMonday.getDate() + 7);
        const nextSunday = new Date(nextMonday);
        nextSunday.setDate(nextSunday.getDate() + 6);
        setDateFrom(nextMonday);
        setDateTo(nextSunday);
        break;
      case "month":
        const currentMonth = dateFrom.getMonth();
        const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
        const nextMonthYear =
          currentMonth === 11
            ? dateFrom.getFullYear() + 1
            : dateFrom.getFullYear();
        const nextMonthStart = new Date(nextMonthYear, nextMonth, 1);
        const nextMonthEnd = new Date(nextMonthYear, nextMonth + 1, 0);
        setDateFrom(nextMonthStart);
        setDateTo(nextMonthEnd);
        break;
      default:
        break;
    }
  };

  export function handleTimeBetween(val, setDateFrom, setDateTo) {
    switch (val) {
      case "today":
        let todayDate = new Date();
        setDateFrom(todayDate);
        setDateTo(todayDate);
        break;
      case "week":
        let todayWeek = new Date();
        let dayOfWeek = todayWeek.getDay();
        let startOfWeek = new Date(todayWeek);
        startOfWeek.setDate(
          todayWeek.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
        );
        setDateFrom(startOfWeek);

        // Set dateTo to the last day (Sunday) of this week
        let endOfWeek = new Date(todayWeek);
        endOfWeek.setDate(
          todayWeek.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 7)
        );
        setDateTo(endOfWeek);
        break;
      case "month":
        let todayMonth = new Date();
        let firstDayOfMonth = new Date(
          todayMonth.getFullYear(),
          todayMonth.getMonth(),
          1
        );
        setDateFrom(firstDayOfMonth);

        // Set dateTo to the last day of this month
        let lastDayOfMonth = new Date(
          todayMonth.getFullYear(),
          todayMonth.getMonth() + 1,
          0
        );
        setDateTo(lastDayOfMonth);
        break;
      default:
        break;
    }
  }