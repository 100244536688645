import { gql } from "@apollo/client"

export const LOCKED_USERS_LIST=gql`
query ($model: LockedUsersGetRequestInput!) {
  GetLockedUsers(model: $model) {
    lockedUsersList {
      lockedUsers {
        locked
        userGuid
        firstName
        lastName
        email
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}`

  export const UNLOCK_USERS=gql`
  mutation($model:UnlockUsersRequestInput!){
    UnlockUsers (model: $model) {
      unlockUsersResponse
      errorCode
      errorMessage
      errorVariables
    }
}`