import { gql } from "@apollo/client";

export const GET_ALL_RESOURCES = gql`
  query($model: GetAllResourcesRequestInput!) {
    GetAllResources(model: $model) {
      resourcesList {
        resourceGuid
        code
        name
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_RESOURCE_BY_CODE = gql`
  query ($code: String!) {
    GetResourceByCode(code: $code) {
      resources {
        resourceGuid
        code
        name
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
