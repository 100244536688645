import { gql } from "@apollo/client";

export const GET_ALL_MIDDLEWARE_JOBS_LIST=gql`
query ($model: TenantMiddlewareJobsGetRequestInput!) {
    GetTenantMiddlewareJobs(model: $model) {
      tenantMiddlewareJobsView {
        middlewareJobs {
          tenantMiddlewareJobGuid
          jobEnabled
          middlewareJob {
            name
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`
export const GET_MIDDLEWARE_JOBS=gql`
query{
  GetMiddlewareJobsList {
    middlewareJobsList{
      middlewareJobGuid
      name
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`