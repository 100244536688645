import React from "react";

//components
import {
  Grid,
  Box,
  IconButton,
} from "@mui/material";

import { Subtitle, Title } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";

import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tooltip from "../../../../../components/Tooltip"
//graphql
import {
  GET_ALL_COUNTRIES,
  GET_ALL_UNITS_OF_MEASURE,
} from "../../../../../graphql/location";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";

//translation
import { useTranslation } from "react-i18next";
import TextField from "../../../../../components/TextFields/TextField";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    wait,
    readOnly,
    handleClose,
    fromProject,
  } = formData;

  const {
    name,
    address,
    latitude,
    longitude,
    radius,
    UnitOfMeasure,
    country,
    isOnSite,
  } = formField;

  const {
    name: nameV,
    address: addressV,
    latitude: latitudeV,
    longitude: longitudeV,
    radius: radiusV,
    UnitOfMeasure: UnitOfMeasureV,
    Country: countryV,
    isOnSite: isOnSiteV,
  } = values;

  const {
    data: unitsOfMeasure,
    loading: unitsOfMeasureLoading,
    tokenChange: unitsOfMeasureWait,
  } = useQueryAuth(GET_ALL_UNITS_OF_MEASURE, "GetAllUnitsOfMeasure", {
    skip: readOnly || wait,
    isWait: true,
  });

  const unitsOfMeasureList =
    unitsOfMeasure?.GetAllUnitsOfMeasure?.unitsOfMeasure || [];

  const {
    data: countries,
    loading: countriesLoading,
  } = useQueryAuth(GET_ALL_COUNTRIES, "GetAllCountries", {
    skip: readOnly || unitsOfMeasureWait,
    isWait: true,
  });

  const countriesList = countries?.GetAllCountries?.countries || [];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title style={{padding: "10px 0"}}>
          {t("locationForm.locationInformation.title")}
          </Title>
          {handleClose !== null && handleClose !== undefined && (
            <IconButton onClick={() => handleClose()}>
              <ClearOutlinedIcon />
            </IconButton>
          )}
        </Grid>
        

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TextField
            autoFocus
            fullWidth
            value={nameV}
            onChange={(e) => {
              setFieldValue(name.name, e.target.value);
            }}
            label={t(name.label)}
            name={name.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>
        {!fromProject && (
          <Tooltip title={t("location.tooltip.officebranch")}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3} mt={3}>
            <FormControlLabel
            sx={{ '.MuiInputBase-input': { fontSize: '14px !important' } }}
              control={<Switch size="small" checked={isOnSiteV} disabled={readOnly} />}
              label={t(isOnSite.label)}
              onChange={() => setFieldValue(isOnSite.name, !isOnSiteV)}
            />
          </Grid></Tooltip>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Subtitle>{t("locationForm.locationAddress.title")}</Subtitle>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TextField
            fullWidth
            value={addressV}
            onChange={(e) => {
              setFieldValue(address.name, e.target.value);
            }}
            label={t(address.label)}
            name={address.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <AutoComplete
            options={countriesList}
            getOptionLabel={(option) => (option ? option.name : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.name}
              </li>
            )}
            identifier={"countryGuid"}
            value={countryV}
            disabled={readOnly}
            onChange={(e, value) => {
              setFieldValue(country.name, value);
            }}
            loading={countriesLoading}
            label={t(country.label)}
            name={country.name}
            formData={formData}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TextField
            fullWidth
            value={latitudeV}
            type="number"
            onChange={(e) => {
              setFieldValue(latitude.name, e.target.value);
            }}
            label={t(latitude.label)}
            name={latitude.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TextField
            fullWidth
            value={longitudeV}
            type="number"
            onChange={(e) => {
              setFieldValue(longitude.name, e.target.value);
            }}
            label={t(longitude.label)}
            name={longitude.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Subtitle>{t("locationForm.applicationGeolocationInformation.title")}</Subtitle>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <TextField
            fullWidth
            value={radiusV}
            type="number"
            onChange={(e) => {
              setFieldValue(
                radius.name,
                e.target.value ? parseFloat(e.target.value) : null
              );
            }}
            label={t(radius.label)}
            name={radius.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <AutoComplete
            options={unitsOfMeasureList}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"unitOfMeasureGuid"}
            value={UnitOfMeasureV}
            onChange={(e, value) => {
              setFieldValue(UnitOfMeasure.name, value);
            }}
            disabled={readOnly}
            loading={unitsOfMeasureLoading}
            label={t(UnitOfMeasure.label)}
            name={UnitOfMeasure.name}
            formData={formData}
            required
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
