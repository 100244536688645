import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import { useParams } from "react-router-dom";
//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import Tooltip from "../../../../components/Tooltip";
import UTC2Local, { UTC2Time } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { GET_LOG_DETAILS } from "../../../../graphql/logs";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "../../../../components/Icon";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

const LogsList = ({ refetchTable = false }) => {
  const { lID } = useParams();
  const { storeUserGuid } = useAuth();
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(t("copy.clipboard.success"), {
      variant: "success",
    });
  }

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.LogDetails,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;
            
            const actions = ({compProps}) => {
              let props = compProps;
              return (
                <Tooltip title={t("tooltip.copy.additionalInfo")}>
                  <span onClick={() => copyText(props)} style={{cursor: "pointer !important"}}>
                    <CopyIcon color="#000000" />
                  </span>
                </Tooltip>
              );
            };
    
            if (col) {
              col = decodeUnicodeString(col);

              //we parse the json string to a json object
              const parsed = JSON.parse(col, (key, value) =>
                Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions)
              );
              // if (parsed.columns)
              if (parsed.defaultSort) setOrderBy(parsed.defaultSort);
    
              if (parsed.defaultSortDirection)
                setOrder(parsed.defaultSortDirection);
              if (parsed.columns) {
                let cols = parsed.columns;

                cols.map(c => {
                  c = removeNullProps(c);
                  return c;
                })
                setColumns(cols);
              }
            }
      },
    }
  );
  const filtersObject = [
    {
      label: "logDetails.filters.status",
      name: "status",
      type: "text",
      
    },]

  const { mfunction: GetLogDetailsList, loading, refetch: refetchData } = useLazyQueryAuth(
    GET_LOG_DETAILS,
    "GetLogDetailsList");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      GetLogDetailsList(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              filters: filters,
              orderColumn: orderBy,
              orderDirection: order,
              logID: lID,
            }

            
          }
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData]);

  useEffect(() => {
    if (!columnsWait)
      refetchData();
  }, [refetchTable]);


  const rows =
    data &&
      data.GetLogDetailsList &&
      data.GetLogDetailsList.logDetailsListView &&
      data.GetLogDetailsList.logDetailsListView.logDetails
      ? data.GetLogDetailsList.logDetailsListView.logDetails
      : [];
  const numberOfRecords =
    data &&
      data.GetLogDetailsList &&
      data.GetLogDetailsList.logDetailsListView &&
      data.GetLogDetailsList.logDetailsListView.totalNumberOfRecords
      ? data.GetLogDetailsList.logDetailsListView.totalNumberOfRecords
      : 0;

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    filtersObject,
    loading: loading || columnsWait,
    refetch,
      hideSearchBar: true,
    tableMnemonic: Mnemonic.Tables.LogDetails,
    removePagination: true
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"logDetailGuid"}
          hasCheckbox
        />
      )}
    </div>
  );
};

export default LogsList;



