import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import {
  GET_EMPLOYEE_REFERENCE_BY_ID,
  CREATE_EMPLOYEE_REFERENCE,
  EDIT_EMPLOYEE_REFERENCE,
} from "../../../../graphql/employeeReference";

const EmployeeReferencesForm = ({ fromTenant = false }) => {
  const { tName, tID, rID, eID, eName } = useParams();

  const createMode = !rID;

  const { userRoles, storeUserGuid } = useAuth();

  const { manageUsers } = userRoles;

  const { my_employee_reference_list, employee_reference } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const { company, overtimeExternalResource, regularPayExternalResource, weekendOvertimeExternalResource } =
    formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_EMPLOYEE_REFERENCE_BY_ID,
    "GetEmployeeReference",
    {
      variables: { id: rID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data =
          response?.GetEmployeeReference?.employeeReferenceOutput || {};

        setFormData({
          company: data?.company || null,
          overtimeExternalResource: data?.overtimeExternalResource || null,
          regularPayExternalResource: data?.regularPayExternalResource || null,
          weekendOvertimeExternalResource: data?.weekendOvertimeExternalResource || null,
        });
      },
    }
  );

  const { cfunction: editReferenceData } = useMutationAuth(
    EDIT_EMPLOYEE_REFERENCE,
    "EditEmployeeReference"
  );

  const { cfunction: createReferenceData } = useMutationAuth(
    CREATE_EMPLOYEE_REFERENCE,
    "CreateEmployeeReference"
  );

  function createReference(values, actions) {
    createReferenceData(
      {
        variables: {
          model: {
            employeeGuid: eID,
            userID: storeUserGuid,
            companyGuid: values[company.name].companyGuid,
            overtimeRefCode:
              values[overtimeExternalResource.name]?.resourceGuid,
            regularPayRefCode:
              values[regularPayExternalResource.name]?.resourceGuid,
              weekendOvertimeExternalReferenceGuid: values[weekendOvertimeExternalResource.name]?.resourceGuid,
          },
        },
      },
      (response) => {
        if (
          response["CreateEmployeeReference"].errorCode !== null &&
          response["CreateEmployeeReference"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateEmployeeReference"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("employeeReference.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(
            fromTenant
              ? employee_reference
                  .replace(
                    "/:tName/:tID",
                    `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                  )
                  .replace(
                    "/:eName/:eID",
                    `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
                  )
              : my_employee_reference_list.replace(
                  "/:eName/:eID",
                  `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
                )
          );
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editReference(values, actions) {
    editReferenceData(
      {
        variables: {
          model: {
            employeeGuid: eID,
            userID: storeUserGuid,
            employeeReferenceGuid: rID,
            companyGuid: values[company.name].companyGuid,
            overtimeRefCode:
              values[overtimeExternalResource.name]?.resourceGuid,
            regularPayRefCode:
              values[regularPayExternalResource.name]?.resourceGuid,
              weekendOvertimeExternalReferenceGuid: values[weekendOvertimeExternalResource.name]?.resourceGuid,
          },
        },
      },
      (response) => {
        if (
          response["EditEmployeeReference"].errorCode !== null &&
          response["EditEmployeeReference"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditEmployeeReference"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("employeeReference.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createReference(values, actions);
    } else {
      editReference(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={createMode ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched)
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              readOnly: manageUsers === "R",
                              fromTenant,
                            }}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={isSubmitting || manageUsers !== "M"}
                            loading={isSubmitting}
                            type="submit"
                            label={t("roleForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default EmployeeReferencesForm;
