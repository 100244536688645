import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import "./style.css";
import { formatDateTimeZone } from "../../../../UTC2Local";
import { getTimerFromStore, changeTimer } from "../../../../app/reducers/timerSlice";

const Timer = ({ loading, task, show }) => {
  const timer = useSelector(getTimerFromStore);
  const dispatch = useDispatch();
  const [timeSpent, setTimeSpent] = useState("00:00:00");
  const percentage = React.useRef(0);
  const valueToAdd = React.useRef(timer);
  const startTime = task?.task?.timeEntries[0]?.startTime;
  const currentDate = task?.currentDate;

  const handleTimer = (date) => {
    let startDate = new Date(formatDateTimeZone(startTime)); 
    
    let timeDifference = currentDate != undefined ? (new Date(currentDate) - startDate) : -startDate;

    if(timer === 0) valueToAdd.current = 0;

    if(timeDifference < 0){ 
      timeDifference = valueToAdd.current;
    }
    else { 
      timeDifference = timeDifference + valueToAdd.current;
    }
    valueToAdd.current += 1000;
    dispatch(changeTimer(valueToAdd.current))

    // Calculate hours and minutes
    const hours = timeDifference < 0 ? Math.ceil(timeDifference / 3600000) : Math.floor(timeDifference / 3600000);
    const minutes = timeDifference < 0 ? Math.ceil((timeDifference % 3600000) / 60000) : Math.floor((timeDifference % 3600000) / 60000);
    const seconds = timeDifference < 0 ? Math.ceil((timeDifference % 60000) / 1000) : Math.floor((timeDifference % 60000) / 1000);

    // Format hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    percentage.current = formattedSeconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  useEffect(() => {
    if(startTime != null) setTimeSpent(handleTimer(startTime));
    if(startTime == null) setTimeSpent(null)
  }, [startTime, task]);
  
  useEffect(() => {
    const startTimer = setInterval(() => {
      if (startTime != null) {
        setTimeSpent(handleTimer(startTime));
      } else {
        clearInterval(startTimer);
        setTimeSpent(null);
      }
    }, 1000);
  
    // Clear the interval when the component unmounts
    return () => {
      clearInterval(startTimer);
    }
    
  }, [valueToAdd.current]);

  return (
    <Grid container style={{display: "flex", justifyContent: "center", padding: "0 !important"}}>
      <div>
        {show  && !loading && timeSpent && (
          <div
            className="circular-progress"
            data-inner-circle-color="white"
            data-percentage={"100"}
            data-progress-color="var(--color--light-logo-blue)"
            data-bg-color="white"
          >
            <div className="inner-circle"></div>
            <p className="percentage">{timeSpent}</p>
          </div>
        )}
      </div>
    </Grid>
  );
};

export default Timer;
