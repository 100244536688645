import { gql } from "@apollo/client";

export const GET_ALL_SUGGESTIONS_LIST = gql`
query ($model: SuggestionsListGetRequestInput!) {
  GetAllSuggestionsList(model: $model) {
    suggestionsListResponse {
      totalNumberOfRecords
      suggestionsList {
        
        suggestion {
          suggestionGuid
          suggestionID
          
          description
          suggestionStatus {
            suggestionStatusGuid
            description
            mnemonic
          }
          createdByGuid
          creationDate
          createdBy {
            employeeGuid
            email
            firstName
            lastName
            tenantGuid
          }
          tenantGuid
          tenant {
            tenantGuid
            name
          }
        }
        totalVotes
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const CREATE_SUGGESTION = gql`
mutation($model:SuggestionCreateRequestInput!){
    CreateSuggestion (model: $model) {
      suggestion {
        
        suggestionGuid
        description
        creationDate
        createdBy {
          employeeGuid
          firstName
          lastName
        }
        suggestionStatus {
          suggestionStatusGuid
          mnemonic
          description
        }
        tenant{
            tenantGuid
            name
          }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`

export const GET_SUGGESTION_BY_ID = gql`
query ($model: GetSuggestionByIDRequestInput!) {
  GetSuggestionByID(model: $model) {
    suggestionView {
      suggestion {
        suggestionGuid
        description
        creationDate
       
        createdBy {
          employeeGuid
          firstName
          lastName
          profilePictureURL
          tenantGuid
        }
        suggestionUpvotes{
          reference
          creationDate 
          employee{
            tenant{name}
            firstName
            lastName
            email
            employeeGuid
            profilePictureURL
          }
        }
        suggestionStatus {
          suggestionStatusGuid
          mnemonic
          description
        }
        tenant {
          tenantGuid
          name
        }
      }
      totalVotes
      thisUserUpvoted
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const GET_SUGGESTIONS_STATUSES = gql`
query{
    GetAllSuggestionStatuses {
      suggestionStatuses{
         suggestionStatusGuid
         description
         mnemonic
      }
      errorMessage
      errorCode
      errorVariables
    }
   
  }
`

export const UPDATE_SUGGESTION = gql`
mutation ($model:SuggestionUpdateRequestInput!){
  UpdateSuggestion (model: $model) {
    suggestion {
      suggestionGuid
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const DELETE_SUGGESTIONS = gql`
mutation($model:SuggestionsDeleteRequestInput!){
  DeleteSuggestions (model: $model) {
    deleteSuggestionsResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`
export const VOTE_FOR_SUGGESTION = gql`
mutation($model:UpvoteSuggestionRequestInput!){
  UpvoteSuggestion (model: $model) {
    upvoteSuggestionResponse
    errorCode
    errorMessage
    errorVariables
  }}
`
export const UNVOTE_FOR_SUGGESTION = gql`
mutation($model:UpvoteSuggestionRequestInput!){
  UndoUpvoteSuggestion (model: $model) {
    undoUpvoteSuggestionResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`