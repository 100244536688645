import * as Yup from "yup";
import checkout from "./form";

import Mnemonics from "../../../../../Mnemonics.json";

const {
  formField: { name, tenant, version, externalReference, connectionString, clientId, secretId, tenantId, userName, password},
} = checkout;

const validation = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.requiredMessage),
    [tenant.name]: Yup.object().required(tenant.requiredMessage),
    [version.name]: Yup.object().required(version.requiredMessage),   
    [externalReference.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic !== Mnemonics.BusinessCentralVersions.NA,
        then: () => Yup.string().required(externalReference.requiredMessage),
      }), 
    [connectionString.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic !== Mnemonics.BusinessCentralVersions.NA,
        then: () => Yup.string().required(connectionString.requiredMessage),
      }), 
    [userName.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnPrem,
        then: () => Yup.string().required(userName.requiredMessage),
    }), 
    [password.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnPrem,
        then: () => Yup.string().required(password.requiredMessage),
    }), 
    [clientId.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud,
        then: () => Yup.string().required(clientId.requiredMessage),
      }), 
    [secretId.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud,
        then: () => Yup.string().required(secretId.requiredMessage),
      }),
    [tenantId.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud,
        then: () => Yup.string().required(tenantId.requiredMessage),
      }),
  }),
    Yup.object().shape({
    [name.name]: Yup.string().required(name.requiredMessage),
    [version.name]: Yup.object().required(version.requiredMessage),     
    [externalReference.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic !== Mnemonics.BusinessCentralVersions.NA,
        then: () => Yup.string().required(externalReference.requiredMessage),
      }), 
    [connectionString.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic !== Mnemonics.BusinessCentralVersions.NA,
        then: () => Yup.string().required(connectionString.requiredMessage),
      }), 
      [userName.name]: Yup.string()
        .nullable()
        .when(version.name, {
          is: (value) =>
            value?.mnemonic === Mnemonics.BusinessCentralVersions.OnPrem,
          then: () => Yup.string().required(userName.requiredMessage),
      }), 
      [password.name]: Yup.string()
        .nullable()
        .when(version.name, {
          is: (value) =>
            value?.mnemonic === Mnemonics.BusinessCentralVersions.OnPrem,
          then: () => Yup.string().required(password.requiredMessage),
      }),
    [clientId.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud,
        then: () => Yup.string().required(clientId.requiredMessage),
      }), 
    [secretId.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud,
        then: () => Yup.string().required(secretId.requiredMessage),
      }),
    [tenantId.name]: Yup.string()
      .nullable()
      .when(version.name, {
        is: (value) =>
          value?.mnemonic === Mnemonics.BusinessCentralVersions.OnCloud,
        then: () => Yup.string().required(tenantId.requiredMessage),
      }),
  }),
];

export default validation;