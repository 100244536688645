import React, { useState, useEffect, useMemo } from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import DataCard from "../../../components/Cards/DataCard";

//data
import getTableData from "./_late-clock-in";

import useQueryAuth from "../../../hooks/useQueryAuth";

import useAuth from "../../../hooks/useAuth";

import {GET_LATE_CLOCKINS} from "../../../graphql/dayLogs"
import { useTranslation } from "react-i18next";

const LateClockIn = ({ tenant = null, date }) => {
  const [loading, setLoading] = useState(false); //set true when we wish to start lazy loading

const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const { t } = useTranslation();

  const {isEntelligence} = userRoles;

   /**States for Late Clock Isn */
   const [_lateClockIn, _setLateClockIn] = useState([]);
   const [_lateClockInsNumb, _setLateClockInsNumb] = useState(0);


   const {
    loading: lateClockInLoading,
  } = useQueryAuth(
    GET_LATE_CLOCKINS,
    "GetLateEmployees",
    {
      variables: {
        model: {
          tenantID: isEntelligence
            ? tenant
              ? tenant.tenantGuid
              : null
            : storeUserTenant,
          date: new Date(date).toISOString(),
          userID: storeUserGuid,
        },
      }
    },
    {
      onCompleted: (data) => {
        var array =
          data?.GetLateEmployees?.employeesDayLogs?.employeesDayLogs || [];

        _setLateClockIn((records) => [...records, ...array]);
        _setLateClockInsNumb(
          data?.GetLateEmployees?.employeesDayLogs?.totalNumberOfRecords || 0
        );
      },
    }
  );


  const _records = useMemo(()=>_lateClockIn || [], [_lateClockIn]); //holds data
  const _maxRecords = _lateClockInsNumb || 0; //holds total number of records in database that satisfy the query

  useEffect(() => {
    if (_records && _records.length > 0) setLoading(false);
  }, [_records]);

  function fetchData() {
    //function that gets records on lazy load

    if (lateClockInLoading) return; //data is already being fetched, we wait for it to at least be fetched

    if (_records.length >= _maxRecords) return; //if data has reached its max

  }

  const _tableData = getTableData(t);

  const { title, headers, render } = _tableData; //holds card title, table headers and the display of data

  const stopLazyLoad = _records.length >= _maxRecords; //if all records have been fetched we no longer wish to lazy load

  return (
    <DataCard
      id="dashboard-late-modal"
      title={title}
      stopLazyLoad={stopLazyLoad}
      onLazyLoad={fetchData}
      tableHeaders={headers}
      render={render}
      data={_records}
      initialLoad={!(lateClockInLoading && _lateClockInsNumb === 0)}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

LateClockIn.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch: PropTypes.bool,
};

LateClockIn.defaultProps = {
  initialLoad: false,
  data: {},
  next: () => {},
  waitBatch: false,
};

export default LateClockIn;
