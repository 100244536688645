const form = {
  formID: "employee",
  formField: {
    firstName: {
      name: "firstName",
      label: "employeeForm.firstName.label",
      requiredMessage: "employeeForm.firstName.requiredMessage",
    },
    lastName: {
      name: "lastName",
      label: "employeeForm.lastName.label",
      requiredMessage: "employeeForm.lastName.requiredMessage",
    },
    email: {
      name: "email",
      label: "employeeForm.email.label",
      requiredMessage: "employeeForm.email.requiredMessage",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "employeeForm.phoneNumber.label",
      requiredMessage: "employeeForm.phoneNumber.requiredMessage",
    },
    role: {
      name: "role",
      label: "employeeForm.role.label",
      requiredMessage: "employeeForm.role.requiredMessage",
    },
    employmentType: {
      name: "employmentType",
      label: "employeeForm.employmentType.label",
      requiredMessage: "employeeForm.employmentType.requiredMessage",
    },
    workType: {
      name: "workType",
      label: "employeeForm.workType.label",
      requiredMessage: "employeeForm.workType.requiredMessage",
    },
    workShift: {
      name: "workShift",
      label: "employeeForm.workShift.label",
      requiredMessage: "employeeForm.workShift.requiredMessage",
    },
    isOffice365User: {
      name: "isOffice365User",
      label: "employeeForm.isOffice365User.label",
      requiredMessage: "employeeForm.isOffice365User.requiredMessage",
    },
    preferEmail: {
      name: "preferEmail",
      label: "employeeForm.preferEmail.label",
      requiredMessage: "employeeForm.preferEmail.requiredMessage",
    },
    preferSMS: {
      name: "preferSMS",
      label: "employeeForm.preferSMS.label",
      requiredMessage: "employeeForm.preferSMS.requiredMessage",
    },
    isAppUser: {
      name: "isAppUser",
      label: "employeeForm.isAppUser.label",
      requiredMessage: "employeeForm.isAppUser.requiredMessage",
    },
    directManager: {
      name: "directManager",
      label: "employeeForm.directManager.label",
      requiredMessage: "employeeForm.directManager.requiredMessage",
    },
    defaultWorkCompany: {
      name: "defaultWorkCompany",
      label: "employeeForm.defaultWorkCompany.label",
      requiredMessage: "employeeForm.defaultWorkCompany.requiredMessage",
    },
    department: {
      name: "department",
      label: "employeeForm.department.label",
      requiredMessage: "employeeForm.department.requiredMessage",
    },
    isAutoClockIn: {
      name: "isAutoClockIn",
      label: "employeeForm.isAutoClockIn.label",
      requiredMessage: "employeeForm.isAutoClockIn.requiredMessage",
    },
    homeLocation: {
      name: "homeLocation",
      label: "employeeForm.homeLocation.label",
    },
    tenant: {
      name: "tenant",
      label: "employeeForm.tenant.label",
    },
    employmentDate: {
      name: "employmentDate",
      label: "employeeForm.employmentDate.label",
      requiredMessage: "employeeForm.employmentDate.requiredMessage",
      invalidMessage: "employeeForm.employmentDate.invalidMessage",
    },
    position: {
      name: "position",
      label: "employeeForm.position.label",
      requiredMessage: "employeeForm.position.requiredMessage",
    },
    isManager: {
      name: "isManager",
      label: "employeeForm.isManager.label",
      requiredMessage: "employeeForm.isManager.requiredMessage",
    },
    profilePicture: {
      name: "profilePicture",
      label: "employeeForm.profilePicture.label",
      requiredMessage: "employeeForm.profilePicture.requiredMessage",
    },
    employeeStatuses: {
      name: "employeeStatuses",
      label: "employeeForm.employeeStatus.label",
      requiredMessage: "employeeForm.employeeStatus.requiredMessage",
    },
    probation: {
      name: "probation",
      label: "employeeForm.probation.label",
      requiredMessage: "employeeForm.probation.requiredMessage",
    },
    attendance: {
      name:"attendance",
      label: "employeeForm.attendance.label",
      requiredMessage: "employeeForm.attendance.requiredMessage"
    },
    timeEntry: {
      name:"timeEntry",
      label: "employeeForm.timeEntry.label",
      requiredMessage: "employeeForm.timeEntry.requiredMessage"
    },
    officialHoliday: {
      name: "officialHoliday",
      label: "employeeForm.officialHoliday.label",
      requiredMessage: "employeeForm.officialHoliday.requiredMessage",
    },

    resignationDate: {
      name: "resignationDate",
      label: "employeeForm.resignationDate.label",
      requiredMessage: "employeeForm.resignationDate.requiredMessage",
    },
    inactiveDate: {
      name: "inactiveDate",
      label: "employeeForm.inactiveDate.label",
    },
    timeZone: {
      name: "timeZone",
      label: "employeeForm.timeZone.label",
      requiredMessage: "employeeForm.timeZone.requiredMessage",
    },
    resourceType: {
      name: "resourceType",
      label: "employeeForm.resourceType.label",
      requiredMessage: "employeeForm.resourceType.requiredMessage",
    },
    contractor: {
      name: "contractor",
      label: "employeeForm.contractor.label",
      requiredMessage: "employeeForm.contractor.requiredMessage",
    },
    canCreateKiosk: {
      name: "canCreateKiosk",
      label: "employeeForm.canCreateKiosk.label",
    },
  },
};

export default form;