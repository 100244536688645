import { gql } from "@apollo/client";

export const CLOCK_IN_OUT = gql`
  mutation ($model: DayLogFillRequestInput!) {
    ManageDayLog(model: $model) {
      dayLog {
        currentTime
        dayLogObject {
          dayLogGuid
          checkInTime
          checkOutTime
          workType {
            workTypeGuid
            description
            mnemonic
          }
          location {
            locationGuid
            name
          }
        }
        taskObject {
          taskGuid
          title
          description
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_LOCATIONS = gql`
  query {
    GetAllWorkTypes {
      workTypes {
        workTypeGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_LOCATION_OPTIONS = gql`
  query ($model: GetLocationByWorkTypeRequestInput!) {
    GetLocationsByWorkType(model: $model) {
      locations {
        locationGuid
        name
        address
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_PROJECTS_TASKS_FOR_USER = gql`
  query ($model: GetProjectForUserRequestInput!) {
    GetProjectsAndTasksForUser(model: $model) {
      projectsAndTasks {
        projects {
          title
          location {
            locationGuid
            name
            address
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_USER_DAY_LOG = gql`
  query ($userGuid: UUID!) {
    GetUserDayLog(userId: $userGuid) {
      dayLogResponse {
        currentTime
        dayLog {
          dayLogGuid
          checkInTime
          checkOutTime
          workType {
            workTypeGuid
            description
            mnemonic
          }
          location {
            locationGuid
            name
          }
          employee {
            employeeGuid
            firstName
            lastName
          }
        }
        project {
          projectGuid
          title
          description
          location {
            locationGuid
            name
          }
        }
        task {
          taskGuid
          title
          description
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_DAY_LOG_SUBSCRIPTION = gql`
  subscription ($userID: UUID!) {
    userDayLog(userID: $userID) {
      dayLogResponse {
        currentTime
        dayLog {
          dayLogGuid
          employeeGuid
          checkInTime
          checkOutTime
          location {
            locationGuid
            name
            address
          }
          workType {
            workTypeGuid
            description
            mnemonic
          }
        }
        project {
          projectGuid
          title
        }
        task {
          taskGuid
          title
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
