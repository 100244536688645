import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { description, holidays },
} = checkout;

const validation = [
  Yup.object().shape({
    [description.name]: Yup.string().required(description.requiredMessage),
    [holidays.name]: Yup.array(),
  }),    
];

export default  validation;