import React , {useState, useEffect, useMemo} from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import DataCard from "../../../components/Cards/DataCard";

//data
import getTableData from "./_upcoming-timeoffs";

import useQueryAuth from "../../../hooks/useQueryAuth";

import useAuth from "../../../hooks/useAuth";

import {  GET_UPCOMING_TIME_OFFS} from "../../../graphql/officialHolidays"
import { useTranslation } from "react-i18next";

const UpcomingTimeoffs = ({ tenant = null, date }) => {

 
  const [loading, setLoading] = useState(false); //set true when we wish to start lazy loading

  const { t } = useTranslation();


  const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const {isEntelligence} = userRoles;

  
  /**States for Upcoming PTO */
  const [_upcomingPTO, _setUpcomingPTO] = useState([]);
  const [_upcomingPTONumb, _setUpcomingPTONumb] = useState(0);



  const {
    loading: upcomingPTOsLoading,
  } = useQueryAuth(
    GET_UPCOMING_TIME_OFFS,
    "GetUpcomingPTOsForTenant",
    {
      variables: {
        model: {
          tenantID: isEntelligence
            ? tenant != null
              ? tenant.tenantGuid
              : undefined
            : storeUserTenant,
          date: new Date(date).toISOString(),
          userID: storeUserGuid,
        },
      }
    },
    {
      onCompleted: (response) => {
        var array =
          response?.GetUpcomingPTOsForTenant?.toEntitlementListResponse?.toEntitlements || [];

        _setUpcomingPTO((records) => [...records, ...array]);
        _setUpcomingPTONumb(
          response?.GetUpcomingPTOsForTenant?.toEntitlementListResponse
            ?.totalNumberOfRecords || 0
        );
      },
    }
  );



  const _records = useMemo(()=>_upcomingPTO || [], [_upcomingPTO]); //holds data
  const _maxRecords = _upcomingPTONumb || 0; //holds total number of records in database that satisfy the query

  useEffect(() => {
    if (_records && _records.length > 0) setLoading(false);
  }, [_records]);

  function fetchData() {
    //function that gets records on lazy load

    if (upcomingPTOsLoading) return; //data is already being fetched, we wait for it to at least be fetched

    if (_records.length >= _maxRecords) return; //if data has reached its max

  }

  const _tableData = getTableData(t);

  const { title, headers, render } = _tableData; //holds card title, table headers and the display of data

  const stopLazyLoad = _records.length >= _maxRecords; //if all records have been fetched we no longer wish to lazy load

 
  return (
    <DataCard
      id="dashboard-time-offs-modal"
      title={title}
      stopLazyLoad={stopLazyLoad}
      onLazyLoad={fetchData}
      tableHeaders={headers}
      render={render}
      data={_records}
      initialLoad={!(upcomingPTOsLoading && _upcomingPTONumb === 0)}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

UpcomingTimeoffs.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch:PropTypes.bool
};

UpcomingTimeoffs.defaultProps = {
  initialLoad: false,
  data: {},
  next: ()=>{},
  waitBatch:false
};

export default UpcomingTimeoffs;
