import React from 'react';
import useAuth from '../../hooks/useAuth';

const AnonymousEmail = (props) => {
  const { createdBy } = props;
  const { storeUserTenant, userRoles } = useAuth();

  const {isEntelligence} = userRoles;
  const userTenant = createdBy?.tenantGuid;

  if (!userTenant) return null;

  return (
    <span>
      {(storeUserTenant == userTenant)  || isEntelligence  ?   `${createdBy?.email}` : 'Anonymous'}
    </span>
  );
};

export default AnonymousEmail;
