import { gql } from "@apollo/client";

export const GET_ALL_TIMEZONES = gql`
query {
    GetTimezones {
      timezones {
        timezoneGuid
        value
        description
      }
      errorCode
      errorMessage
      errorCode
    }
  }
`;