import React, { useState, useEffect, useCallback, useRef } from "react";

//routing
import { Link, useParams } from "react-router-dom";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
//components
import DataTable from "../../../../components/Tables/DataTable";
import PaperComponent from "../../../../components/Modal/PaperComponent";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import {
  GET_TIME_ENTRIES,
  DELETE_TIME_ENTRY_LIST,
} from "../../../../graphql/timeEntry";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TASKS_WITH_PROJECT } from "../../../../graphql/task";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components

import UTC2Local, { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import EditRecord from "../../Timesheets/Timesheets-Form/components/EditRecord";
import TimeEntryDrawer from "../../Timesheets/Timesheets-Form/components/TimeEntryDrawer";
import { useNavigate } from "react-router-dom";
import urls from "../../../../urls";

import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../../../../components/Popup";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import {
  ListItemIcon,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@mui/material";
import { AddTimeIcon, SubtaskIconOutlined } from "../../../../components/Icon";
import colors from "../../../../assets/theme/light/colors";
import AttendanceByEmployee from "../../Timesheets/MyTimesheet";
import { Title } from "../../../../components/Titles/FormTitles";
import { NestCamWiredStandTwoTone } from "@mui/icons-material";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

function TimeEntriesList({
  fromTenant = false,
  myTime = false,
  removeHeader = false,
  removeCheckbox = false,
  taskID,
  taskName,
  projectName,
  modal,
  refetchTasks,
  selectedTask,
  handleFormChanged,
  refetchTask,
  handleFetchTree,
  changeLoadingState,
  billable,
}) {
  const { my_timesheet } = urls;
  const { pName, tskID, tskName } = useParams();

  const { storeUserGuid, storeUserTenant, isManager, userRoles } = useAuth();

  const { isAdmin, manageTimeEntry } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [filterState, setFilterState] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState(
    filterState && filterState.length > 0 ? filterState : {}
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [timesheet, setTimesheet] = useState(false);

  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [timeEntry, setTimeEntry] = React.useState(null);
  const [timeEntryGuid, setTimeEntryGuid] = React.useState(null);
  const [employeeGuid, setEmployeeGuid] = React.useState(null);
  const [task, setTask] = React.useState(selectedTask);
  const [resetFields, setResetFields] = React.useState(false);
  const [synced, setSynced] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [fetchProjects, setFetchProjects] = React.useState(false);
  const [task_list, setTasksList] = React.useState([]);
  const [projectsList, setProjectsList] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [expandedSideInfo, setExpandedSideInfo] = useState(true);
  const type = useRef("task");

  const nowDate = new Date();

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [sideInfoData, setSideInfoData] = useState({
    "Duration.todaysTotal": "0",
    "Duration.totalHours": "0",
    "Duration.weeksTotal": "0",
  });
  // const [task, setTask] = React.useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState(null);

  const openMenuItems = Boolean(menuItems);
  const handleClickMenu = (event) => {
    setMenuItems(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuItems(null);
  };

  useEffect(() => {
    if (tskID != null && pName != null && tskName != null)
      setTask({ taskGuid: tskID, title: pName + " - " + tskName });
    if (taskID != null && taskName != null && projectName != null)
      setTask({ taskGuid: taskID, title: projectName + " - " + taskName });
  }, [tskID, tskName, taskID, taskName]);

  useEffect(() => {
    if (timeEntry) setTimeEntryGuid(timeEntry.timeEntryGuid);
  }, [timeEntry]);

  useEffect(() => {
    if (selectedTask) setTask(selectedTask);
  }, [selectedTask]);

  useEffect(() => {
    if (myTime) {
      const objectIndexEmployeeIDs = filterState.findIndex(
        (obj) => obj.property === "employee"
      );

      const objectIndexDate = filterState.findIndex(
        (obj) => obj.property === "date"
      );

      const objectIndexBillableIDs = filterState.findIndex(
        (obj) => obj.property === "billable"
      );
      const objectIndexNonBillableIDs = filterState.findIndex(
        (obj) => obj.property === "nonBillable"
      );

      const billableValue = {
        value: true,
        property: "billable",
      };
      const nonBillableValue = {
        value: true,
        property: "nonBillable",
      };

      const dateValue = {
        value: nowDate,
        property: "date",
      };

      const employeeIDsObj = {
        value: [storeUserGuid],
        property: "employee",
      };

      if (
        objectIndexEmployeeIDs < 0 &&
        objectIndexDate < 0 &&
        objectIndexBillableIDs < 0 &&
        objectIndexNonBillableIDs < 0
      ) {
        setDefaultFilters({
          ...defaultFilters,
          employee: [storeUserGuid],
          date: UTC2Local(nowDate, true),
          billable: true,
          nonBillable: true,
        });
        setFilterState([
          ...filterState,
          employeeIDsObj,
          dateValue,
          billableValue,
          nonBillableValue,
        ]);
      }

      // else if (objectIndexEmployeeIDs < 0) {
      //   setDefaultFilters({ ...defaultFilters, employee: [storeUserGuid] });
      //   setFilterState([...filterState, employeeIDsObj]);
      // }

      // else
      //   setDefaultFilters({ ...defaultFilters, date: UTC2Local(selectedDate, true) });
      //   setFilterState([...filterState, dateValue]);
    }
  }, [myTime]);

  useEffect(() => {
    if (selectedDate) {
      const dateValue = {
        value: nowDate,
        property: "date",
      };

      setDefaultFilters({
        ...defaultFilters,
        date: UTC2Local(selectedDate, true),
      });
      setFilterState([...filterState, dateValue]);
    }
  }, [selectedDate]);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TIME_ENTRY_LIST,
    "DeleteTimeEntries"
  );

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table:
            myTime === true
              ? Mnemonic.Tables.OpenTimeEntries
              : Mnemonic.Tables.TimeEntries,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        const actions = ({ compProps }) => {
          let props = compProps;
          return (
            <EditRecord
              {...props}
              handleClick={(v) => setClicked(v)}
              handleChangeTask={(v) => setTask(v)}
              handleRowSelect={(t) => setTimeEntry(t)}
              handleEditMode={(v) => setEditMode(v)}
              handleSetSynced={(v) => setSynced(v)}
            />
          );
        };

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, null, formatDateTimeToString)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? null : storeUserTenant,
        userID: storeUserGuid,
        isManagerUsersList: isManager === true ? true : null,
        timeEntryDisabled: true,
      },
    },
    skip: columnsWait,
    isWait: true,
  });

  const employeeList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: projectsData,
    mfunction: getProjects,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useLazyQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects");

  useEffect(() => {
    if (fetchProjects) {
      getProjects(
        {
          variables: {
            model: {
              enableFilters: false,
              tenantID: storeUserTenant,
              userID: storeUserGuid,
            },
          },
          skip: !fetchProjects,
          isWait: fetchProjects,
        },
        (response) => {
          setProjectsList(
            response?.GetAllProjects?.projectsList?.projectViews || []
          );
        }
      );
    }
  }, [fetchProjects]);

  // const {
  //   data: projectsData,
  //   loading: projectLoading,
  //   tokenChange: projectsWait,
  // } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
  //   variables: {
  //     model: {
  //       enableFilters: false,
  //       tenantID: storeUserTenant,
  //       userID: storeUserGuid,
  //     },
  //   },
  //   skip: !fetchProjects,
  //   isWait: fetchProjects,
  // });

  // useEffect(() => {
  //   if (projectsData) setProjectsList(projectsData?.GetAllProjects?.projectsList?.projectViews || [])
  // }, [projectsData])
  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        userID: employeeGuid,
        projectID: selectedProject?.projectGuid,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: !selectedProject,
    isWait: true,
  });

  // const { data: tasksList, loading: taskLoading, tokenChange: tasksWait } = useQueryAuth(
  //   GET_ALL_TASKS_WITH_PROJECT,
  //   "GetTasks",
  //   {
  //     variables: {
  //       model: {
  //         tenantID: storeUserTenant,
  //         enableFilters: false,
  //         onGoingTasks: true,
  //         userID: employeeGuid,
  //         projectID: selectedProject?.projectGuid
  //       },
  //     },
  //     skip: !selectedProject,
  //     isWait: true,
  //   }
  // );

  // const task_list =
  //   tasksList &&
  //     tasksList.GetTasks &&
  //     tasksList.GetTasks.tasks
  //     ? tasksList.GetTasks.tasks
  //     : [];

  const {
    mfunction: getTasksWithProject,
    loading: tasksLoading,
    tokenChange: taskWait,
  } = useLazyQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks");

  useEffect(() => {
    if (selectedProject) {
      getTasksWithProject(
        {
          variables: {
            model: {
              tenantID: storeUserTenant,
              enableFilters: false,
              onGoingTasks: true,
              userID: employeeGuid,
              projectID: selectedProject?.projectGuid,
              timeEntriesCreationAllowed: true,
            },
          },
        },
        (response) => {
          setTasksList(response?.GetTasks?.tasks || []);
        }
      );
    }
  }, [selectedProject]);

  const filtersObject = myTime
    ? [
        {
          label: "timesheet.list.filters.employees",
          name: "employee",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeeList,
          access: "employeeGuid",
          loading: employeeLoading,
          disabled: myTime === true && !isManager && !isAdmin ? true : false,
          disableClear:
            myTime === true && !isManager && !isAdmin ? true : false,
        },
        {
          label: "timesheet.list.filters.projects",
          name: "project",
          type: "autocomplete",
          getOptionLabel: (option) => (option ? option.title : ""),
          options: projectsList,
          access: "projectGuid",
          loading: projectLoading,
          fetchData: setFetchProjects,
          selectedData: selectedProject,
          setSelectedData: setSelectedProject,
          fieldsToClear: ["task"],
        },
        {
          label: "timesheet.list.filters.tasks",
          name: "task",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.title : ""),
          options: task_list,
          access: "taskGuid",
          loading: taskLoading,
          disabled: !selectedProject,
        },
        {
          type: "date",
          label: "timesheet.list.filters.date",
          name: "date",
        },
        {
          type: "check",
          label: "timeEntry.filters.label.billable",
          name: "billable",
          access: "billable",
          disabledProperty: "nonBillable",
          disabledValue: false,
          disableClear: true,
        },
        {
          type: "check",
          label: "timeEntry.filters.label.nonBillable",
          name: "nonBillable",
          access: "nonBillable",
          disabledProperty: "billable",
          disabledValue: false,
          disableClear: true,
        },
      ]
    : [
        {
          label: "timeEntry.filters.label.employee",
          name: "employee",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeeList,
          access: "employeeGuid",
          loading: employeeLoading,
        },
        {
          type: "date",
          label: "timeEntry.filters.label.date",
          name: "date",
        },
        {
          type: "check",
          label: "timeEntry.filters.label.open",
          name: "open",
          access: "open",
        },
        {
          type: "check",
          label: "timeEntry.filters.label.synced",
          name: "synced",
          access: "synced",
        },
        {
          type: "check",
          label: "timeEntry.filters.label.billable",
          name: "billable",
          access: "billable",
          disabledProperty: "nonBillable",
          disabledValue: false,
          disableClear: true,
        },
        {
          type: "check",
          label: "timeEntry.filters.label.nonBillable",
          name: "nonBillable",
          access: "nonBillable",
          disabledProperty: "billable",
          disabledValue: false,
          disableClear: true,
        },
      ];
  const {
    mfunction: getAllTimeEntries,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_TIME_ENTRIES, "GetTimeEntries");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTimeEntries(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              taskGuid: tskID ? tskID : taskID,
              userID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait && !employeeWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, employeeWait, fetchTableData, re, defaultFilters]);

  const rows =
    data &&
    data.GetTimeEntries &&
    data.GetTimeEntries.timeEntriesList &&
    data.GetTimeEntries.timeEntriesList.timeEntries
      ? data.GetTimeEntries.timeEntriesList.timeEntries
      : [];

  const numberOfRecords =
    data &&
    data.GetTimeEntries &&
    data.GetTimeEntries.timeEntriesList &&
    data.GetTimeEntries.timeEntriesList.totalNumberOfRecords
      ? data.GetTimeEntries.timeEntriesList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (rows) {
      setSideInfoData({
        "Duration.todaysTotal":
          data?.GetTimeEntries?.timeEntriesList?.todaysTotal === "0"
            ? "0"
            : data?.GetTimeEntries?.timeEntriesList?.todaysTotal || "0",
        "Duration.weeksTotal":
          data?.GetTimeEntries?.timeEntriesList?.weeklyTotal === "0"
            ? "0"
            : data?.GetTimeEntries?.timeEntriesList?.weeklyTotal || "0",
        "Duration.totalHours":
          data?.GetTimeEntries?.timeEntriesList?.totalHours === "0"
            ? "0"
            : data?.GetTimeEntries?.timeEntriesList?.totalHours || "0",
      });
    }
  }, [rows]);

  const handleDeselect = () => {
    sessionStorage.removeItem("unsavedChanges");
    if (handleFormChanged !== undefined) handleFormChanged(false);
  };

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            timeEntryGuids: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("timeEntry.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
        if (refetchTask) refetchTask();
        if (handleFetchTree) handleFetchTree();
      }
    );
  };

  useEffect(() => {
    if (selected.length > 0) handleDeselect();
  }, [selected.length]);

  const customButtons = [
    {
      label: "timesheet.btn.add.",
      handleClick: (e) => {
        if (myTime) {
          handleClickMenu(e);
        } else {
          if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
            setShowConfirmationPopup(true);
          } else {
            setShowConfirmationPopup(false);
            setResetFields(!resetFields);
            setEditMode(false);
            setSynced(false);
            setClicked(true);
          }
        }
      },

      // condition: (!selected || selected.length === 0) && (taskID != null || tskID != null),
      condition: (!selected || selected.length === 0) && !removeHeader,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const renderAddTime = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        // className="account-menu-profile"
        MenuListProps={{ sx: { py: 0, width: "230px" } }}
        open={openMenuItems}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        style={{ textAlign: "left" }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: "white !important",
              top: "9rem !important",
              boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            },
          },
        }}
      >
        <div
          // className="suggestionsMenu"
          style={{
            textAlign: "left",
            backgroundColor: `${colors.grey_background} !important`,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MenuItem
              className="user-menu-item-hover"
              style={{ paddingTop: "20px" }}
              onClick={() => {
                handleAddTimeEntry();
              }}
            >
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-alarm"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 13m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                  <path d="M12 10l0 3l2 0" />
                  <path d="M7 4l-2.75 2" />
                  <path d="M17 4l2.75 2" />
                </svg>
              </ListItemIcon>
              <span style={{ fontSize: "13px", fontFamily: "Poppins" }}>
                {t("ts.AddtimeEntry")}
              </span>
            </MenuItem>
            <MenuItem
              className="user-menu-item-hover"
              onClick={handleAddTimesheet}
            >
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chart-infographic"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="#000000"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                  <path d="M7 3v4h4" />
                  <path d="M9 17l0 4" />
                  <path d="M17 14l0 7" />
                  <path d="M13 13l0 8" />
                  <path d="M21 12l0 9" />
                </svg>
              </ListItemIcon>
              <span
                style={{
                  fontSize: "13px",
                  fontFamily: "Poppins",
                  marginTop: "5px",
                }}
              >
                {t("ts.addtimesheet")}
              </span>
            </MenuItem>
          </div>
        </div>
      </Menu>
    );
  };
  
  const handleAddTimesheet = () => {
    // navigate(my_timesheet);
    setTimesheet(true);
  };
  const handleCloseTimesheet = () => {
    setTimesheet(false);
    refetchData();
  };

  const handleAddTimeEntry = () => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true)
      setShowConfirmationPopup(true);
    else {
      setShowConfirmationPopup(false);
      setResetFields(!resetFields);
      setEditMode(false);
      setSynced(false);
      setClicked(true);
    }
  };

  const handleOpenModal = (row) => {
    setTask(row?.task);
    // setProjectID(row?.ticket?.projectGuid);
    // setTaskName(row?.title);
    // setProjectName(row?.ticket?.project?.title);
    setShowModal(true);
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    handleDeselect,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait || employeeWait || projectsWait,
    searchText: "timeEntry.list.search.label",
    refetch,
    tableMnemonic:
      myTime === true
        ? Mnemonic.Tables.OpenTimeEntries
        : Mnemonic.Tables.TimeEntries,
    filtersObject,
    deleteText: "timeEntry.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: removeHeader,
    customButtons,
    checkboxDisable: (row) =>
      row.timeSheet?.status === Mnemonic.TimesheetStatuses.Approved ||
      row.synced ||
      (!isAdmin &&
        !isManager &&
        row.employee?.employeeGuid !== storeUserGuid) ||
      (row.employee?.employeeGuid !== storeUserGuid &&
        row.employee?.directManagerGuid !== storeUserGuid),
    handleOpenModal: myTime ? handleOpenModal : null,
    linkCell: myTime ? true : false,
    isFixed: true,
    removeRefresh: removeHeader,
    hideEditColumns: removeHeader,
    removeFilters: removeHeader,
    hideSearchBar: removeHeader,
  };

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setShowConfirmationPopup(false);
    setResetFields(!resetFields);
    setEditMode(false);
    setSynced(false);
    setClicked(true);
  };

  return (
    <div>
      <>
        <Dialog
          open={timesheet}
          onClose={handleCloseTimesheet}
          sx={{
            ".MuiDialog-paper": {
              maxHeight: "100% !important",
              minWidth: "1350px !important",
              padding: "6px 30px !important",
              backgroundColor: `${colors.grey_background}`,
            },
          }}
        >
          <Grid container alignItems="center">
            <Grid
              item
              sm={10}
              lg={10}
              md={10}
              xs={10}
              xl={10}
              p={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item mr={1} sx={{ display: "flex", alignItems: "center" }}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chart-infographic"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  strokeWidth="1.8"
                  stroke="#344767"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                  <path d="M7 3v4h4" />
                  <path d="M9 17l0 4" />
                  <path d="M17 14l0 7" />
                  <path d="M13 13l0 8" />
                  <path d="M21 12l0 9" />
                </svg>
              </Grid>
              <Title style={{ marginTop: "5px" }}>{t("dialog.ts.title")}</Title>
            </Grid>
            <Grid
              item
              sm={2}
              lg={2}
              md={2}
              xs={2}
              xl={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => handleCloseTimesheet()}
                sx={{ fontSize: "14px !important" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-x"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="#9e9e9e"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </IconButton>
            </Grid>
          </Grid>
          <Grid mt={-1} item pb={2}>
            <AttendanceByEmployee
              fromNav
              handleCloseTimesheet={handleCloseTimesheet}
              refetchData={refetchData}
              handleDate={(d) => setSelectedDate(d)}
            />
          </Grid>
        </Dialog>

        {myTime && renderAddTime()}
        <TaskTabsModal
          showModal={showModal}
          setShowModal={(v) => setShowModal(v)}
          loading={loading}
          projectID={task?.ticket?.project?.projectGuid}
          projectName={task?.ticket?.project?.title}
          taskID={task?.taskGuid}
          taskName={task?.title}
          refetch={refetchData}
          fromProject={true}
          setType={(v) => type.current=v}
          type={task ? task.parentTaskGuid : type.current}
          open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
          modifyFormFlag={() =>
            sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
          }
        />
        <ConfirmationPopup
          title={t("dialog.title.second")}
          openDialog={showConfirmationPopup}
          handleOpenDialog={(v) => setShowConfirmationPopup(v)}
          handleAgree={handleAgree}
        />
        <TimeEntryDrawer
          PaperComponent={PaperComponent}
          openTimeEntryModal={clicked}
          setOpenTimeEntryModal={(v) => setClicked(v)}
          timeSheetGuid={null}
          timeEntryGuid={timeEntryGuid}
          description={timeEntry?.description}
          synced={synced}
          fromTimeEntriesList={true}
          refetchData={refetchData}
          refetchTasks={refetchTasks}
          refetchTask={refetchTask}
          handleFetchTree={handleFetchTree}
          handleDeselect={handleDeselect}
          editMode={editMode}
          handleEditMode={(v) => setEditMode(v)}
          handleChangeTimeEntryGuid={(v) => setTimeEntryGuid(v)}
          handleSetEmployeeGuid={(v) => setEmployeeGuid(v)}
          employees={employeeList}
          resetFields={resetFields}
          myTime={myTime}
          selectedTask={task}
          tasks={task_list}
          group={true}
          t={t}
          billable={billable}
        />
        {!getColumnsLoading && (
          <DataTable
            {...pagination}
            withSideInfo={myTime}
            sideInfoData={sideInfoData}
            expandCollapse={true}
            identifier={"timeEntryGuid"}
            hasCheckbox={!removeCheckbox}
            hasTansparentBackground={modal === true}
            heightClassName={!myTime ? "table-task-time-entry-height" : ""}
            // checkForCheckbox={(row) => {
            //   return (
            //     ((row?.employee?.directManagerGuid === storeUserGuid
            //        &&
            //       isManager &&
            //       manageTimeEntry === "M") || row?.employee?.employeeGuid === storeUserGuid ||
            //       isAdmin)
            //   );
            // }}
          />
        )}
      </>
      {/* )} */}
    </div>
  );
}

export default TimeEntriesList;
