import React from "react";

//redux
import { useDispatch } from "react-redux";
import { modifyUserDeviceToken } from "../app/reducers/userSlice";

//AlertDialog
import AlertDialog from "../components/AlertDialog";

//hooks
import useAuth from "../hooks/useAuth";
import useMutationAuth from "../hooks/useMutationAuth";

//cookies
import { useCookies } from "react-cookie";

//variables
import variables from "../variables";
import { SAVE_USER_DEVICE } from "../graphql/user";

const DeviceToken = () => {
  //To handle cookies
  const [cookies, setCookie, removeCookie] = useCookies();

  const dispatch = useDispatch();
  const { jwtToken, storeRequestOTPCode, storeUserEmail, deviceToken } =
    useAuth();

  const { mfunction: saveUserDevice, loading } = useMutationAuth(
    SAVE_USER_DEVICE,
    "SaveUserDevice"
  );

  const displayDeviceAlert = jwtToken && !storeRequestOTPCode && deviceToken;

  const DontSaveDevice = () => {
    removeCookie(variables.COOKIE_NAME + storeUserEmail.toLowerCase(), { path: "/" });
    dispatch(modifyUserDeviceToken(""));
  };

  const SaveDevice = () => {
    saveUserDevice(
      {
        variables: {
          model: {
            deviceToken: deviceToken,
            email: storeUserEmail,
          },
        },
      },
      async (data) => {
        //if user device saved successfuly, we save the device in a cookie
        const today = new Date();
        const threeyears = new Date();

        await threeyears.setFullYear(today.getFullYear() + 3);

        var cookieObject = {
          path: "/",
          expires: threeyears,
        };

        //set the cookie to last 3 years
        setCookie(
          variables.COOKIE_NAME + storeUserEmail.toLowerCase(),
          deviceToken,
          cookieObject
        );
        dispatch(modifyUserDeviceToken(""));
      },
      (error) => {
        dispatch(modifyUserDeviceToken(""));
      }
    );
  };

  return (
    <AlertDialog
      open={displayDeviceAlert}
      title="deviceToken.title"
      message="deviceToken.message"
      onNotOK={DontSaveDevice}
      onOK={SaveDevice}
      OKtext="deviceToken.OK"
      NotOKtext="deviceToken.notOK"
      OKloading={loading}
    />
  );
};

export default DeviceToken;
