import * as React from "react";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";


import { useTranslation } from "react-i18next";

//colors
import colorLight from "../../assets/theme/light/colors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  open,
  title = "",
  message = "",
  onOK = () => {},
  onNotOK = () => {},
  OKloading = false,
  NotOKloading = false,
  OKtext = "",
  NotOKtext = "",
}) {

  const { t } = useTranslation();

  const { pure_white, standard_blue } = colorLight; //colors

  //css stylings
  const buttonStyle = {
    backgroundColor: `${standard_blue}`,
    color: `${pure_white}`,
    fontFamily: 'poppins !important',

    "&:hover": {
      backgroundColor: `var(--color--light-logo-blue)`,
    },
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText >
          {t(message)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          sx={buttonStyle}
          loading={NotOKloading}
          loadingPosition="start"
          onClick={()=>onNotOK()}
          disabled={OKloading}
        >
          {t(NotOKtext)}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          sx={buttonStyle}
          loading={OKloading}
          loadingPosition="start"
          onClick={()=>onOK()}
          disabled={NotOKloading}
        >
          {t(OKtext)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
