import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const EmployeeStatus = (props) => {
  const { state } = props;
  const { taskStateColors } = colorsLight;

  if (!state) return null;
  return (
    <span
      style={{
        padding: "5px 20px",
        borderRadius: "5px",
        fontFamily: "poppins  !important",
        margin: "auto",
        ...taskStateColors[state],
      }}
    >
      {state}
    </span>
  );
 
};

export default EmployeeStatus;
