import React, { useEffect } from "react";

import PropTypes, { string } from "prop-types";
import { useDispatch } from "react-redux";

//routing
import { Outlet, Navigate, useLocation } from "react-router-dom";

import urls from "./urls";

import useAuth from "./hooks/useAuth";
import { logoutUser } from "./app/reducers/userSlice";

const Authorization = ({ type, roles }) => {
  const {
    login,
    verify_login,
    subscription,
    default: default_page,
    entelligence_dashboard,
    my_dashboard,
  } = urls;

  const location = useLocation();
  const dispatch = useDispatch();

  const {
    jwtToken,
    storeRequestOTPCode: requestOtpCode,
    userRoles,
  } = useAuth();

  const { isEntelligence } = userRoles;

  useEffect(() => {
    // Remove the sessionStorage item after component mounts
    if (type.includes("default")) {
      sessionStorage.removeItem("url");
    }
  }, [type]);

  //we get query params if needed
  const urlParams = new URLSearchParams(window.location.search);

  if(!jwtToken && (!location?.pathname.includes("login") || !location?.pathname.includes("reset"))) sessionStorage.setItem("url", location?.pathname + location?.search);

  if (type.includes("subscription") && jwtToken && location?.pathname == "/subscription" && !location.state) {
    // Log out the user if they are already logged in
    dispatch(logoutUser());
    return <Navigate to={subscription} state={{ from: location }} replace />;
  }

  if (type.includes("loggedIn")) {
    if (jwtToken && requestOtpCode === false)
      return <Navigate to={default_page} state={{ from: location }} replace />;
  }

  if (type.includes("default")) {
    //if user goes to "/" in the website redirect them to login or to home page
    return jwtToken ? (
      requestOtpCode ? (
        <Navigate to={verify_login} replace state={{ from: location }} />
      ) : (
        <Navigate
          to={sessionStorage.getItem("url") ? sessionStorage.getItem("url") : isEntelligence ? entelligence_dashboard : my_dashboard}
          state={{ from: location }}
          replace
        />
      )
    ) : (
      <Navigate to={login} replace />
    );
  }

  if (type.includes("authorize")) {
    //if user not logged in then can't have otp code
    if (!jwtToken)
      return <Navigate to={login} replace state={{ from: location }} />;
  }

  if (type.includes("otp")) {
    if (requestOtpCode)
      return <Navigate to={verify_login} replace state={{ from: location }} />;
  }

  if (type.includes("reset-password")) {
    //for reset password, if token query para not found, we shouldn't allow the user to visit the page
    const token = urlParams.get("token");

    if (!token)
      return <Navigate to={login} replace state={{ from: location }} />;
  }

  if (type.includes("not-entelligence")) {
    if (!userRoles)
      return <Navigate to={login} replace state={{ from: location }} />;

    if (isEntelligence)
      return <Navigate to={default_page} replace state={{ from: location }} />;
  }

  if (type.includes("entelligence")) {
    if (!userRoles)
      return <Navigate to={login} replace state={{ from: location }} />;

    if (!isEntelligence)
      return <Navigate to={default_page} replace state={{ from: location }} />;
  }

  if (type.includes("roles")) {
    if (!userRoles)
      return <Navigate to={login} replace state={{ from: location }} />;

    if (roles.length !== 0) {
      let authorize = true;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].checkValid(userRoles)) {
          authorize = false;
          break;
        }
      }
      if (!authorize)
        return (
          <Navigate to={default_page} replace state={{ from: location }} />
        );
    }
  }

  return <Outlet />;
};

Authorization.propTypes = {
  type: PropTypes.arrayOf(string),
};

Authorization.defaultProps = {
  roles: [],
  type: [],
};

export default Authorization;
