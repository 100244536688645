import { Navigate } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function TimesheetsList() {
    const { t } = useTranslation();

  const {
    my_attendance_sheets_list_pending, my_attendance_sheets_list_approved
  } = urls;

  if(window.location.href.split("/")[6] === undefined || (window.location.href.split("/")[6] === null)) return <Navigate to={my_attendance_sheets_list_pending} replace />;


  const TabLinks = [
    {
      key: 1,
      Header: t("attendanceSheetsTabs.pending"),
      Link: my_attendance_sheets_list_pending,
    },
    {
      key: 2,
      Header: t("attendanceSheetsTabs.approved"),
      Link: my_attendance_sheets_list_approved,
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default TimesheetsList;
