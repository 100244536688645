import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYMENT_TYPES = gql`
  query {
    GetAllEmploymentTypes {
      employmentTypeList {
        employmentTypes {
          name
          employmentTypeGuid
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;
