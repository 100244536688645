import React from "react";
import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

//components
import {
  Grid,
  Box,
  Switch,
  FormControlLabel,
  Chip,
  Stack,
} from "@mui/material";

import { Title } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";

import CircleIcon from "@mui/icons-material/AddCircle";
import { DeleteIcon } from "../../../../../components/Icon";

import { Field } from "formik";
import useAuth from "../../../../../hooks/useAuth";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import Tooltip from "../../../../../components/CustomTooltip";
import { Subtitle } from "../../../../../components/Titles/FormTitles";

//translation
import { useTranslation } from "react-i18next";

import "./style.css";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";

//graphql
import {
  CHANGE_TENANT_STATUS,
  GET_CONNECTOR_LIST,
} from "../../../../../graphql/tenant";
import { GET_ALL_TASK_STATUSES } from "../../../../../graphql/taskStatus";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import { GET_ALL_TICKET_STATUSES } from "../../../../../graphql/ticketStatus";
import MiddlewareJobTable from "../components/middlewareJobsList";
import { GET_ALL_COMPANIES_DROPDOWN } from "../../../../../graphql/company";

import colors from "../../../../../assets/theme/light/colors";

import { day_month_year } from "../../../../../utils/timezones";
import TextField from "../../../../../components/TextFields/TextField";
import Tooltip from "../../../../../components/Tooltip";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const { tID } = useParams();
  const { storeUserGuid, storeUserTenant } = useAuth();
  const [jobsList, setJobsList] = useState([]);
  const [resourceName, setResourceName] = useState("");
  const [dateOption, setDateOption] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [fetchTaskStatuses, setFetchTaskStatuses] = useState(false);
  const [fetchPhaseStatuses, setFetchPhaseStatuses] = useState(false);
  const [fetchCompanies, setFetchCompanies] = useState(false);
  const [fetchConnectors, setFetchConnectors] = useState(false);

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    isActive,
    setIsActive,
    fromTenant,
    myTenant,
    setWhatField,
    wait,
    tenantID,
    setMiddlewareArray,
    refetchTable,
  } = formData;

  const {
    name,
    task,
    timesheetBy,
    hoursOfWork,
    projectRadius,
    attendance,
    timeEntry,
    syncTimeEntries,
    taskStatuses,
    phaseStatuses,
    LogCleanUpRuntime,
    LogRetentionDays,
    connector,
    job,
    company,
    allowTimesheetReopening,
    mandatoryTimeEntryDescription,
    allowAttendanceSheetReopening,
    monthlyAttendanceSheet,
    postJournal,
    postJournalDescription,
    postUnderAndOverUsageToNonBillableJob,
    trialExpirationDate,
    subscribedFrom,
    trial,
  } = formField;

  const {
    name: nameV,
    timesheetBy: timesheetByV,
    hoursOfWork: hoursOfWorkV,
    projectRadius: projectRadiusV,
    attendance: attendanceV,
    timeEntry: timeEntryV,
    syncTimeEntries: syncTimeEntriesV,
    taskStatuses: taskStatusesV,
    phaseStatuses: phaseStatusesV,
    task: taskV,
    LogCleanUpRuntime: LogCleanUpRuntimeV,
    LogRetentionDays: LogRetentionDaysV,
    connector: connectorV,
    job: jobV,
    company: companyV,
    allowTimesheetReopening: allowTimesheetReopeningV,
    mandatoryTimeEntryDescription: mandatoryTimeEntryDescriptionV,
    allowAttendanceSheetReopening: allowAttendanceSheetReopeningV,
    monthlyAttendanceSheet: monthlyAttendanceSheetV,
    postJournal: postJournalV,
    postJournalDescription: postJournalDescriptionV,
    trial: trialV,
    trialExpirationDate: trialExpirationDateV,
    subscribedFrom: subscribedFromV,
    postUnderAndOverUsageToNonBillableJob:
      postUnderAndOverUsageToNonBillableJobV,
  } = values;

  useEffect(() => {
    setMiddlewareArray(jobsList);
  });

  const { mfunction: changeTenantStatus, loading: statusLoading } =
    useMutationAuth(CHANGE_TENANT_STATUS, "EditTenantActiveStatus");

  const handleStatusChange = () => {
    changeTenantStatus(
      {
        variables: {
          model: {
            isActive: !isActive,
            tenantID: tID,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("tenantForm.statusChange.success"), {
          variant: "success",
        });
        setIsActive((val) => !val);
      }
    );
  };

  const {
    data: GetTaskStatusesList,
    loading: taskLoading,
    tokenChange: taskWait,
  } = useQueryAuth(GET_ALL_TASK_STATUSES, "GetTaskStatuses", {
    variables: {
      model: {
        tenantID: fromTenant ? tID : storeUserTenant,
        projectID: null,
      },
    },
    skip: !fetchTaskStatuses,
    isWait: fetchTaskStatuses,
  });

  const TaskStatusesList =
    GetTaskStatusesList &&
    GetTaskStatusesList.GetTaskStatuses &&
    GetTaskStatusesList.GetTaskStatuses.tasksStatus
      ? GetTaskStatusesList.GetTaskStatuses.tasksStatus
      : [];

  const {
    data: phaseStatusesData,
    loading: phaseLoading,
    tokenChange: phaseWait,
  } = useQueryAuth(GET_ALL_TICKET_STATUSES, "GetTicketsStatus", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: fromTenant ? tID : storeUserTenant,
        projectID: null,
      },
    },
    skip: !fetchPhaseStatuses,
    isWait: fetchPhaseStatuses,
  });

  const phaseStatusesList =
    phaseStatusesData &&
    phaseStatusesData.GetTicketsStatus &&
    phaseStatusesData.GetTicketsStatus.ticketStatusesList &&
    phaseStatusesData.GetTicketsStatus.ticketStatusesList.ticketStatuses
      ? phaseStatusesData.GetTicketsStatus.ticketStatusesList.ticketStatuses
      : [];

  const {
    data: connectorData,
    loading: connectorLoading,
    tokenChange: connectorWait,
  } = useQueryAuth(GET_CONNECTOR_LIST, "GetConnectorsList", {
    skip: !fetchConnectors,
    isWait: fetchConnectors,
  });

  const connectorList =
    connectorData &&
    connectorData.GetConnectorsList &&
    connectorData.GetConnectorsList.connectorsList
      ? connectorData.GetConnectorsList.connectorsList
      : [];

  const {
    data: companyData,
    loading: companyLoading,
    tokenChange: companyWait,
  } = useQueryAuth(GET_ALL_COMPANIES_DROPDOWN, "GetAllCompanies", {
    skip: !fetchCompanies,
    isWat: fetchCompanies,
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: fromTenant ? tID : storeUserTenant,
      },
    },
  });

  const companyList =
    companyData &&
    companyData.GetAllCompanies &&
    companyData.GetAllCompanies.compList &&
    companyData.GetAllCompanies.compList.companies
      ? companyData.GetAllCompanies.compList.companies
      : [];

  const timesheetByList = [
    { description: "Monthly", value: true, id: 1 },
    { description: "Weekly", value: false, id: 2 },
  ];

  const attendanceList = [
    { description: "Monthly", value: true, id: 1 },
    { description: "Weekly", value: false, id: 2 },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{ padding: "10px 0" }}>
            {t("tenantForm.tenantInformation")}
          </Title>
          {!createMode && !myTenant && (
            <IALoadingButton
              small
              label={
                isActive
                  ? t("tenantForm.button.deactivate")
                  : t("tenantForm.button.activate")
              }
              error={isActive}
              loading={statusLoading}
              onClick={handleStatusChange}
              center
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            autoFocus
            variant="standard"
            fullWidth
            label={t(name.label)}
            value={nameV}
            required
            onFocus={() => {
              setWhatField(`Tenant-Form-${name.name}`);
            }}
            onBlur={(e) => {
              setTouched({ ...touched, [name.name]: true });
              setWhatField(null);
            }}
            onChange={(e) => {
              setFieldValue(name.name, e.target.value);
            }}
            disabled={myTenant}
            error={touched[name.name] && errors[name.name]}
            helperText={
              touched[name.name] && errors[name.name] && t(errors[name.name])
            }
            formData={formData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            fullWidth
            onFocus={() => {
              setWhatField(`Tenant-Form-${projectRadius.name}`);
            }}
            disabled={myTenant}
            label={t(projectRadius.label)}
            value={projectRadiusV || 0}
            onBlur={(e) => {
              setTouched({ ...touched, [projectRadius.name]: true });
              setWhatField(null);
            }}
            onChange={(e) => {
              setFieldValue(projectRadius.name, parseInt(e.target.value) || 0);
            }}
            error={touched[projectRadius.name] && errors[projectRadius.name]}
            helperText={
              touched[projectRadius.name] &&
              errors[projectRadius.name] &&
              t(errors[projectRadius.name])
            }
            formData={formData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            fullWidth
            label={t(subscribedFrom.label)}
            value={subscribedFromV}
            disabled={!createMode}
            onFocus={() => {
              setWhatField(`Tenant-Form-${subscribedFrom.name}`);
            }}
            onBlur={(e) => {
              setTouched({ ...touched, [subscribedFrom.name]: true });
              setWhatField(null);
            }}
            onChange={(e) => {
              setFieldValue(subscribedFrom.name, e.target.value);
            }}
            error={touched[subscribedFrom.name] && errors[subscribedFrom.name]}
            helperText={
              touched[subscribedFrom.name] &&
              errors[subscribedFrom.name] &&
              t(errors[subscribedFrom.name])
            }
            formData={formData}
          />
        </Grid>
      </Grid>
      {((trialV === true && myTenant) || !myTenant) && (
        <Grid
          container
          spacing={2}
          mt={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="flex_space-between_center"
          >
            <Title style={{ padding: "10px 0" }}>
              {t("tenantForm.trial.license")}
            </Title>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Field
              as={FormControlLabel}
              type={trial.type}
              name={trial.name}
              control={
                <Switch
                  sx={{
                    ".MuiTypography-root": {
                      fontSize: "1rem !important",
                    },
                  }}
                  size="small"
                  checked={trialV}
                  disabled={myTenant}
                  onBlur={(e) => setTouched({ ...touched, [trial.name]: true })}
                  onChange={(e) => {
                    setFieldValue(trial.name, e.target.checked);
                  }}
                  error={touched[trial.name] && errors[trial.name]}
                />
              }
              label={t(trial.label)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                sx={{
                  width: "100%",
                  ".MuiInputBase-input": { fontSize: "14px !important" },
                  "& .MuiFormLabel-root": { fontSize: "14px !important" },
                }}
                format={
                  day_month_year.includes(userTimezone) === true
                    ? "dd-MM-yyyy"
                    : "MM-dd-yyyy"
                }
                label={t(trialExpirationDate.label)}
                fullWidth
                value={trialExpirationDateV}
                disabled={myTenant}
                onChange={(newValue) => {
                  setFieldValue(trialExpirationDate.name, newValue);
                }}
                onBlur={(e) =>
                  setTouched({
                    ...touched,
                    [trialExpirationDate.name]: true,
                  })
                }
                slotProps={{
                  desktopPaper: {
                    sx: {
                      boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                    },
                  },
                  textField: {
                    size: "small",
                    variant: "standard",

                    error:
                      touched[trialExpirationDate.name] &&
                      errors[trialExpirationDate.name],
                    helperText:
                      touched[trialExpirationDate.name] &&
                      errors[trialExpirationDate.name] &&
                      t(errors[trialExpirationDate.name]),

                    onBlur: (e) =>
                      setTouched({
                        ...touched,
                        [trialExpirationDate.name]: true,
                      }),
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          mt={3}
          className="flex_space-between_center"
          spacing={2}
        >
          <Title style={{ padding: "10px 0" }}>
            {t("tenantForm.systemConfiguration")}
          </Title>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mb={2}
            mt={3}
            ml={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Subtitle>{t("tenantForm.attendance.settings")}</Subtitle>
            </Grid>

            <Grid container spacing={2} mt={0.5}>
              <Tooltip title={t("tenant.tooltip.attendance")}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Field
                    as={FormControlLabel}
                    type={attendance.type}
                    name={attendance.name}
                    control={
                      <Switch
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "1rem !important",
                          },
                        }}
                        size="small"
                        checked={attendanceV}
                        disabled={myTenant}
                        onBlur={(e) =>
                          setTouched({ ...touched, [attendance.name]: true })
                        }
                        onChange={(e) => {
                          setFieldValue(attendance.name, e.target.checked);
                        }}
                        error={
                          touched[attendance.name] && errors[attendance.name]
                        }
                      />
                    }
                    label={t(attendance.label)}
                  />
                </Grid>
              </Tooltip>

              <Tooltip
                title={t("tenant.tooltip.allowAttendanceSheetReopening")}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Field
                    as={FormControlLabel}
                    type={allowAttendanceSheetReopening.type}
                    name={allowAttendanceSheetReopening.name}
                    control={
                      <Switch
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "1rem !important",
                          },
                        }}
                        size="small"
                        checked={allowAttendanceSheetReopeningV}
                        disabled={myTenant}
                        onBlur={(e) =>
                          setTouched({
                            ...touched,
                            [allowAttendanceSheetReopening.name]: true,
                          })
                        }
                        onChange={(e) => {
                          setFieldValue(
                            allowAttendanceSheetReopening.name,
                            e.target.checked
                          );
                        }}
                        error={
                          touched[allowAttendanceSheetReopening.name] &&
                          errors[allowAttendanceSheetReopening.name]
                        }
                      />
                    }
                    label={t(allowAttendanceSheetReopening.label)}
                  />
                </Grid>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoComplete
                  options={attendanceList}
                  getOptionLabel={(option) =>
                    option ? option.description : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.description}
                    </li>
                  )}
                  identifier={"id"}
                  size={"small"}
                  required={attendanceV}
                  onChange={(e, value) => {
                    setFieldValue(monthlyAttendanceSheet.name, value);
                  }}
                  disabled={myTenant}
                  value={monthlyAttendanceSheetV}
                  name={monthlyAttendanceSheet.name}
                  label={t(monthlyAttendanceSheet.label)}
                  formData={formData}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mb={2}
            mt={3}
            ml={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Subtitle>{t("tenantForm.timeEntry.settings")}</Subtitle>
            </Grid>

            <Grid container spacing={2} mt={0.5}>
              <Tooltip title={t("tenant.tooltip.timeEntry")}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <Field
                    as={FormControlLabel}
                    type={timeEntry.type}
                    name={timeEntry.name}
                    control={
                      <Switch
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "1rem !important",
                          },
                        }}
                        size="small"
                        checked={timeEntryV}
                        disabled={myTenant}
                        onBlur={(e) =>
                          setTouched({ ...touched, [timeEntry.name]: true })
                        }
                        onChange={(e) => {
                          setFieldValue(timeEntry.name, e.target.checked);
                          setFieldValue(syncTimeEntries.name, false);
                        }}
                        error={
                          touched[timeEntry.name] && errors[timeEntry.name]
                        }
                      />
                    }
                    label={t(timeEntry.label)}
                  />
                </Grid>
              </Tooltip>
              <Tooltip title={t("tenant.tooltip.allowTimesheetReopening")}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Field
                    as={FormControlLabel}
                    type={allowTimesheetReopening.type}
                    name={allowTimesheetReopening.name}
                    control={
                      <Switch
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "1rem !important",
                          },
                        }}
                        size="small"
                        checked={allowTimesheetReopeningV}
                        disabled={myTenant}
                        onBlur={(e) =>
                          setTouched({
                            ...touched,
                            [allowTimesheetReopening.name]: true,
                          })
                        }
                        onChange={(e) => {
                          setFieldValue(
                            allowTimesheetReopening.name,
                            e.target.checked
                          );
                        }}
                        error={
                          touched[allowTimesheetReopening.name] &&
                          errors[allowTimesheetReopening.name]
                        }
                      />
                    }
                    label={t(allowTimesheetReopening.label)}
                  />
                </Grid>
              </Tooltip>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Field
                  as={FormControlLabel}
                  type={mandatoryTimeEntryDescription.type}
                  name={mandatoryTimeEntryDescription.name}
                  control={
                    <Switch
                      sx={{
                        ".MuiTypography-root": { fontSize: "1rem !important" },
                      }}
                      size="small"
                      checked={mandatoryTimeEntryDescriptionV}
                      //disabled={myTenant}
                      onBlur={(e) =>
                        setTouched({
                          ...touched,
                          [mandatoryTimeEntryDescription.name]: true,
                        })
                      }
                      onChange={(e) => {
                        setFieldValue(
                          mandatoryTimeEntryDescription.name,
                          e.target.checked
                        );
                      }}
                      error={
                        touched[mandatoryTimeEntryDescription.name] &&
                        errors[mandatoryTimeEntryDescription.name]
                      }
                    />
                  }
                  label={t(mandatoryTimeEntryDescription.label)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", marginTop: "5px" }}
            >
              <Tooltip title={t("tooltip.timesheetBy")}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ display: "flex", alignItems: "end" }}
              >
                
                <AutoComplete
                  options={timesheetByList}
                  getOptionLabel={(option) =>
                    option ? option.description : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.description}
                    </li>
                  )}
                  identifier={"id"}
                  size={"small"}
                  required={timeEntryV}
                  disabled={myTenant}
                  value={timesheetByV}
                  onBlur={(e) =>
                    setTouched({ ...touched, [timesheetBy.name]: true })
                  }
                  onChange={(e, value) => {
                    setFieldValue(timesheetBy.name, value);
                  }}
                  name={timesheetBy.name}
                  label={t(timesheetBy.label)}
                  formData={formData}
                />
              </Grid></Tooltip>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  required={timeEntryV}
                  fullWidth
                  label={t(hoursOfWork.label)}
                  value={hoursOfWorkV || 0}
                  onBlur={(e) => {
                    setTouched({ ...touched, [hoursOfWork.name]: true });
                  }}
                  onChange={(e) => {
                    setFieldValue(
                      hoursOfWork.name,
                      parseInt(e.target.value) || 0
                    );
                  }}
                  error={touched[hoursOfWork.name] && errors[hoursOfWork.name]}
                  helperText={
                    touched[hoursOfWork.name] &&
                    errors[hoursOfWork.name] &&
                    t(errors[hoursOfWork.name])
                  }
                  formData={formData}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mb={2}
              mt={5}
              ml={1}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Subtitle>{t("tenantForm.timeEntry.sync")}</Subtitle>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                <Tooltip title={t("tenant.tooltip.syncTimeEntry")}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      as={FormControlLabel}
                      type={syncTimeEntries.type}
                      name={syncTimeEntries.name}
                      control={
                        <Switch
                          sx={{
                            ".MuiTypography-root": {
                              fontSize: "1rem !important",
                            },
                          }}
                          size="small"
                          checked={timeEntryV ? syncTimeEntriesV : false}
                          disabled={(myTenant && trialV === true) || createMode}
                          onBlur={(e) =>
                            setTouched({
                              ...touched,
                              [syncTimeEntries.name]: true,
                            })
                          }
                          onChange={(e, value) => {
                            setFieldValue(
                              syncTimeEntries.name,
                              e.target.checked
                            );
                            if (value === true) {
                              setFieldValue(
                                allowTimesheetReopening.name,
                                false
                              );
                            }
                          }}
                          error={
                            touched[syncTimeEntries.name] &&
                            errors[syncTimeEntries.name]
                          }
                        />
                      }
                      label={t(syncTimeEntries.label)}
                    />
                  </Grid>
                </Tooltip>
                <Tooltip title={t("tenant.tooltip.postJournal")}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Field
                      as={FormControlLabel}
                      type={postJournal.type}
                      name={postJournal.name}
                      control={
                        <Switch
                          sx={{
                            ".MuiTypography-root": {
                              fontSize: "1rem !important",
                            },
                          }}
                          size="small"
                          checked={postJournalV}
                          disabled={createMode}
                          onBlur={(e) =>
                            setTouched({ ...touched, [postJournal.name]: true })
                          }
                          onChange={(e) => {
                            setFieldValue(postJournal.name, e.target.checked);
                          }}
                          error={
                            touched[postJournal.name] &&
                            errors[postJournal.name]
                          }
                        />
                      }
                      label={t(postJournal.label)}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} my={2}>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <Tooltip title={t("tenant.tooltip.stack.pills")}>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={t("BCName")}
                          color="primary"
                          variant="outlined"
                          icon={<CircleIcon />}
                          disabled={createMode}
                          onClick={() => {
                            setResourceName("{BCName}");
                            setFieldValue(
                              postJournalDescription.name,
                              "{BCName}"
                            );
                          }}
                        />
                        <Chip
                          label={t("activName")}
                          color="primary"
                          variant="outlined"
                          icon={<CircleIcon />}
                          disabled={createMode}
                          onClick={() => {
                            setResourceName("{activName}");
                            setFieldValue(
                              postJournalDescription.name,
                              "{activName}"
                            );
                          }}
                        />
                        <Chip
                          label={t("dateFrom")}
                          color="primary"
                          variant="outlined"
                          disabled={resourceName === "" || createMode}
                          icon={<CircleIcon />}
                          onClick={() => {
                            setDateOption("{dateFrom}");
                            setFieldValue(
                              postJournalDescription.name,
                              resourceName + ";{dateFrom}"
                            );
                          }}
                        />
                        <Chip
                          label={t("dateFromTo")}
                          color="primary"
                          variant="outlined"
                          disabled={resourceName === "" || createMode}
                          icon={<CircleIcon />}
                          onClick={() => {
                            setDateOption("{dateFromTo}");
                            setFieldValue(
                              postJournalDescription.name,
                              resourceName + ";{dateFromTo}"
                            );
                          }}
                        />
                        <Chip
                          label={t("clear")}
                          color="default"
                          variant="outlined"
                          sx={{
                            ".MuiButtonBase-root": {
                              border: `1px solid ${colors.red_color} !important`,
                            },
                            "&.MuiChip-root": {
                              border: `1px solid ${colors.red_color} !important`,
                            },
                            "& .MuiChip-label": {
                              color: `${colors.red_color} !important`,
                              paddingLeft: 0,
                            },
                          }}
                          icon={<DeleteIcon color={colors.red_color} />}
                          disabled={createMode}
                          onClick={() => {
                            setResourceName("");
                            setDateOption("");
                            setFieldValue(postJournalDescription.name, "");
                          }}
                        />
                      </Stack>
                    </Tooltip>
                  </div>
                  <Tooltip title={t("tenant.tooltip.postJournalDescription")}>
                    <TextField
                      disabled
                      required={syncTimeEntriesV === true}
                      fullWidth
                      label={t(postJournalDescription.label)}
                      value={postJournalDescriptionV}
                      onBlur={(e) => {
                        setTouched({
                          ...touched,
                          [postJournalDescription.name]: true,
                        });
                      }}
                      onChange={(e) => {
                        setFieldValue(
                          postJournalDescription.name,
                          parseInt(e.target.value) || 0
                        );
                      }}
                      error={
                        touched[postJournalDescription.name] &&
                        errors[postJournalDescription.name]
                      }
                      helperText={
                        touched[postJournalDescription.name] &&
                        errors[postJournalDescription.name] &&
                        t(errors[postJournalDescription.name])
                      }
                      formData={formData}
                    />
                  </Tooltip>
                </Grid>
                <Tooltip title={t("tenant.tooltip.connector")}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <AutoComplete
                      options={connectorList}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      renderOption={(props, option) => (
                        <li {...props} key={uuidv4()}>
                          {option.name}
                        </li>
                      )}
                      identifier={"connectorGuid"}
                      value={connectorV}
                      disabled={((!timeEntryV || !syncTimeEntriesV) && myTenant) || createMode}
                      onOpen={() => setFetchConnectors(true)}
                      onClose={() => setFetchConnectors(false)}
                      onChange={(e, value) => {
                        if (value !== null) {
                          setFieldValue(allowTimesheetReopening.name, false);
                        }
                        if (value?.name === "Connector-1") {
                          setFieldValue(
                            timesheetBy.name,
                            timesheetByList.find(
                              (item) => item.description === "Weekly"
                            )
                          );
                        }
                        if (value?.name === "Connector-2") {
                          setFieldValue(
                            timesheetBy.name,
                            timesheetByList.find(
                              (item) => item.description === "Monthly"
                            )
                          );
                        }
                        setFieldValue(connector.name, value);
                        setFieldValue(company.name, null);
                        setFieldValue(job.name, "");
                        setFieldValue(task.name, "");
                      }}
                      loading={connectorLoading}
                      name={connector.name}
                      label={t(connector.label)}
                      formData={formData}
                    />
                  </Grid>
                </Tooltip>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  my={2}
                  sx={{ display: "flex", alignItems: "end" }}
                >
                  <Field
                    as={FormControlLabel}
                    type={postUnderAndOverUsageToNonBillableJob.type}
                    name={postUnderAndOverUsageToNonBillableJob.name}
                    control={
                      <Switch
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "1rem !important",
                          },
                        }}
                        size="small"
                        checked={postUnderAndOverUsageToNonBillableJobV}
                        disabled={(connectorV?.name !== "Connector-2") || createMode}
                        onBlur={(e) =>
                          setTouched({
                            ...touched,
                            [postUnderAndOverUsageToNonBillableJob.name]: true,
                          })
                        }
                        onChange={(e) => {
                          setFieldValue(
                            postUnderAndOverUsageToNonBillableJob.name,
                            e.target.checked
                          );
                        }}
                        error={
                          touched[postUnderAndOverUsageToNonBillableJob.name] &&
                          errors[postUnderAndOverUsageToNonBillableJob.name]
                        }
                      />
                    }
                    label={t(postUnderAndOverUsageToNonBillableJob.label)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0.1}>
                <Tooltip title={t("tenant.tooltip.phase")}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <AutoComplete
                      options={phaseStatusesList}
                      getOptionLabel={(option) =>
                        option ? option.description : ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={uuidv4()}>
                          {option.description}
                        </li>
                      )}
                      identifier={"ticketStatusGuid"}
                      value={phaseStatusesV}
                      disabled={createMode}
                      onOpen={() => setFetchPhaseStatuses(true)}
                      onClose={() => setFetchPhaseStatuses(false)}
                      onChange={(e, value) => {
                        setFieldValue(phaseStatuses.name, value);
                      }}
                      loading={phaseLoading}
                      required={
                        connectorV?.name === "Connector-1" ||
                        connectorV?.name === "Connector-2"
                      }
                      name={phaseStatuses.name}
                      label={t(phaseStatuses.label)}
                      formData={formData}
                    />
                  </Grid>
                </Tooltip>

                <Tooltip title={t("tenant.tooltip.task")}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <AutoComplete
                      options={TaskStatusesList}
                      getOptionLabel={(option) =>
                        option ? option.description : ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={uuidv4()}>
                          {option.description}
                        </li>
                      )}
                      identifier={"taskStatusGuid"}
                      value={taskStatusesV}
                      disabled={createMode}
                      onOpen={() => setFetchTaskStatuses(true)}
                      onClose={() => setFetchTaskStatuses(false)}
                      onChange={(e, value) => {
                        setFieldValue(taskStatuses.name, value);
                      }}
                      loading={taskLoading}
                      required={
                        connectorV?.name === "Connector-1" ||
                        connectorV?.name === "Connector-2"
                      }
                      name={taskStatuses.name}
                      label={t(taskStatuses.label)}
                      formData={formData}
                    />
                  </Grid>
                </Tooltip>
              </Grid>

              <Grid container spacing={2} mt={0.1}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <AutoComplete
                    options={companyList}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.name}
                      </li>
                    )}
                    identifier={"companyGuid"}
                    value={companyV}
                    disabled={(connectorV?.name !== "Connector-2") || createMode}
                    onOpen={() => setFetchCompanies(true)}
                    onClose={() => setFetchCompanies(false)}
                    onBlur={(e) =>
                      setTouched({ ...touched, [company.name]: true })
                    }
                    onChange={(e, value) => {
                      setFieldValue(company.name, value);
                    }}
                    loading={companyLoading}
                    required={
                      connectorV?.name === "Connector-2" &&
                      postUnderAndOverUsageToNonBillableJobV === true
                    }
                    name={company.name}
                    label={t(company.label)}
                    formData={formData}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    required={
                      connectorV?.name === "Connector-2" &&
                      postUnderAndOverUsageToNonBillableJobV === true
                    }
                    fullWidth
                    disabled={(connectorV?.name !== "Connector-2") || createMode}
                    label={t(job.label)}
                    value={jobV}
                    onBlur={(e) => {
                      setTouched({ ...touched, [job.name]: true });
                      // setWhatField(null)
                    }}
                    onChange={(e) => {
                      setFieldValue(job.name, e.target.value);
                    }}
                    error={touched[job.name] && errors[job.name]}
                    helperText={
                      touched[job.name] &&
                      errors[job.name] &&
                      t(errors[job.name])
                    }
                    formData={formData}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    // onFocus={() => {
                    //   setWhatField(`Tenant-Form-${projectRadius.name}`);
                    // }}
                    disabled={(connectorV?.name !== "Connector-2") || createMode}
                    required={
                      connectorV?.name === "Connector-2" &&
                      postUnderAndOverUsageToNonBillableJobV === true
                    }
                    label={t(task.label)}
                    value={taskV}
                    onBlur={(e) => {
                      setTouched({ ...touched, [task.name]: true });
                      // setWhatField(null)
                    }}
                    onChange={(e) => {
                      setFieldValue(task.name, e.target.value);
                    }}
                    error={touched[task.name] && errors[task.name]}
                    helperText={
                      touched[task.name] &&
                      errors[task.name] &&
                      t(errors[task.name])
                    }
                    formData={formData}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={5} alignItems={"end"}>
                <Tooltip title={t("tenant.tooltip.logretention")}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField
                      fullWidth
                      label={t(LogRetentionDays.label)}
                      value={LogRetentionDaysV || 0}
                      onBlur={(e) => {
                        setTouched({
                          ...touched,
                          [LogRetentionDays.name]: true,
                        });

                        setWhatField(null);
                      }}
                      onChange={(e) => {
                        setFieldValue(
                          LogRetentionDays.name,
                          parseInt(e.target.value) || 0
                        );
                      }}
                      error={
                        touched[LogRetentionDays.name] &&
                        errors[LogRetentionDays.name]
                      }
                      helperText={
                        touched[LogRetentionDays.name] &&
                        errors[LogRetentionDays.name] &&
                        t(errors[LogRetentionDays.name])
                      }
                      formData={formData}
                    />
                  </Grid>
                </Tooltip>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{
                    ".MuiInputBase-input": { fontSize: "14px !important" },
                    "& .MuiFormLabel-root": { fontSize: "14px !important" },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      label={t(LogCleanUpRuntime.label)}
                      value={LogCleanUpRuntimeV}
                      className="date_picker_styles"
                      ampm={false}
                      slotProps={{
                        desktopPaper: {
                          sx: {
                            boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                          },
                        },
                        textField: {
                          variant: "standard",
                          sx: { fontSize: "14px !important" },
                          size: "small",
                          fullWidth: true,
                          onBlur: (e) => {
                            setTouched({
                              ...touched,
                              [LogCleanUpRuntime.name]: true,
                            });
                          },
                          error:
                            touched[LogCleanUpRuntime.name] &&
                            errors[LogCleanUpRuntime.name],
                          helperText:
                            touched[LogCleanUpRuntime.name] &&
                            errors[LogCleanUpRuntime.name] &&
                            t(errors[LogCleanUpRuntime.name]),
                        },
                      }}
                      onChange={(newValue) =>
                        setFieldValue(LogCleanUpRuntime.name, newValue)
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!createMode ? (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            mt={4}
            className="flex_space-between_center"
            spacing={2}
          >
            <Title>{t("tenantForm.MiddlewareJobs")}</Title>

            <div style={{ width: "100%" }}>
              <MiddlewareJobTable
                jobsList={jobsList}
                refetchTable={refetchTable}
                handleSetJobsList={(jb) => setJobsList(jb)}
                wait={
                  wait || taskWait || phaseWait || connectorWait || companyWait
                }
                fromTenant
              />
            </div>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default Main;
