// @flow
import { Divider, Grid, Typography } from "@mui/material";
import React, { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import activ from "../../../../assets/images/dark-LOGO.png";
import colors from "../../../../assets/theme/light/colors";
import "./style.css";

export const PrintView = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    projectTitle,
    projectExternalReference,
    invoices,
    timeEntriesToInvoiceByMonth,
    totalTimeEntriesToInvoice,
  } = props?.data;

  const handlePrint = () => {
    const componentHtml =
      document.getElementsByClassName("container")[0].innerHTML;
    const windowHtml = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            .container {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                padding-left: 10px;
                padding-right: 10px;
            }

            h2 {
                font-size: 26px;
                margin: 20px 0;
                text-align: center;
            }

            h2 small {
                font-size: 0.5em;
            }

            .responsive-table li {
                border-radius: 3px;
                padding: 25px 30px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 25px;
            }

            .responsive-table .table-header {
                background-color: #95A5A6;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 0.03em;
            }

            .responsive-table .table-row {
                background-color: #ffffff;
                box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            }

            .responsive-table .col-1 {
                flex-basis: 10%;
            }

            .responsive-table .col-2 {
                flex-basis: 40%;
            }

            .responsive-table .col-3 {
                flex-basis: 25%;
            }

            .responsive-table .col-4 {
                flex-basis: 25%;
            }

            @media all and (max-width: 767px) {
                .responsive-table .table-header {
                    display: none;
                }

                .responsive-table li {
                    display: block;
                }

                .responsive-table .col {
                    flex-basis: 100%;
                }

                .responsive-table .col {
                    display: flex;
                    padding: 10px 0;
                }

                .responsive-table .col:before {
                    color: #6C7A89;
                    padding-right: 10px;
                    content: attr(data-label);
                    flex-basis: 50%;
                    text-align: right;
                }
            }
          </style>
        </head>
        <body>
          <div id="my-component">
            ${componentHtml}
          </div>
        </body>
      </html>
    `;
    const printWindow = window.open("", "");
    printWindow.document.write(windowHtml);
    printWindow.document.close();

    // Wait for the new tab to load before triggering the print dialog
    setTimeout(() => {
      printWindow.print();
    }, 1000);
  };

  const displayInvoice = (invoice, key) => {
    const {
      invoiceDocNo,
      invoicePostingDate,
      invoiceQuantity,
      appliedQuantity,
      remainingQuantity,
      appliedItems,
    } = invoice;
    return (
      <Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ fontSize: "14px" }}
        >
          <strong>{t("invoice.invoiceNo")}:</strong> {invoiceDocNo}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ fontSize: "14px" }}
        >
          <strong>{t("invoice.date")}:</strong>{" "}
          {new Date(invoicePostingDate).toDateString()}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ fontSize: "14px" }}
          mb={1}
        >
          <strong>{t("invoice.qty")}:</strong> {invoiceQuantity}
        </Grid>
        <ul class="responsive-table">
          <li class="table-header">
            <div class="col col-1">{t("invoice.type")}</div>
            <div class="col col-2">{t("invoice.description")}</div>
            <div class="col col-3">{t("invoice.qty")}</div>
          </li>
          {appliedItems?.map((item, key) => {
            return (
              <li class="table-row" key={key}>
                <div class="col col-1">{item.type}</div>
                <div class="col col-2">{item.documentNo}</div>
                <div class="col col-3">{item.quantity}</div>
              </li>
            );
          })}
          {appliedQuantity !== 0 && (
            <li class="table-row">
              <div class="col col-2 col-grey col-bold">
                {t("invoice.total")}
              </div>
              <div class="col col-1"></div>
              <div class="col col-3 col-grey col-bold">{appliedQuantity}</div>
            </li>
          )}
          {remainingQuantity !== 0 && (
            <li class="table-row">
              <div class="col col-2 col-grey col-bold">
                {t("invoice.remaning")}
              </div>
              <div class="col col-1"></div>
              <div class="col col-3 col-grey col-bold">{remainingQuantity}</div>
            </li>
          )}
        </ul>
      </Grid>
    );
  };

  const displayTimeEntry = (invoice, index) => {
    const { key, value } = invoice;
    return (
      <>
        <li class="table-row" key={index}>
          <div class="col col-2">{key}</div>
          <div class="col col-3">{value}</div>
        </li>
      </>
    );
  };

  return (
    <div>
      <div class="container" ref={ref}>
        <h2>
          {projectTitle} <small>[{projectExternalReference}]</small>
        </h2>
        <Grid container>
          {!props?.toInvoiceOnly && (
            <Typography variant="h5" sx={{ textDecoration: "underline" }}>
              {t("invoice.appliedEntries")}
            </Typography>
          )}
          {invoices?.map((invoice, key) => displayInvoice(invoice, key))}
          {!invoices || invoices.length === 0 ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              mt={4}
              display="flex"
              justifyContent="center"
              sx={{ color: colors.icon_button_grey }}
            >
              {t("invoice.noData")}
            </Grid>
          ) : (
            <></>
          )}
          {timeEntriesToInvoiceByMonth &&
            timeEntriesToInvoiceByMonth.length > 0 && (
              <>
                <Typography
                  mt={4}
                  variant="h5"
                  sx={{ textDecoration: "underline" }}
                >
                  {t("invoice.uninvoicedEntries")}
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
                  <ul class="responsive-table">
                    <li class="table-header">
                      <div class="col col-2">{t("invoice.description")}</div>
                      <div class="col col-3">{t("invoice.qty")}</div>
                    </li>
                    {timeEntriesToInvoiceByMonth?.map(
                      (invoiceByMonth, index) => {
                        const { month, monthlyTotal, timeEntriesToInvoice } = invoiceByMonth;

                        return (
                          <>
                            <li class="table-row" key={index}>
                              <div class="col col-2 col-italic">{month}</div>
                              <div class="col col-3"></div>
                            </li>
                            {timeEntriesToInvoice?.map((invoice, key) =>
                              displayTimeEntry(invoice, month, key)
                            )}
                            <li class="table-row" key={index}>
                              <div class="col col-2 col-grey col-bold">{t("invoice.monthlyTotal")}</div>
                        <div class="col col-1"></div>
                              <div class="col col-3 col-grey col-bold">{monthlyTotal}</div>
                            </li>
                          </>
                        );
                      }
                    )}
                    {totalTimeEntriesToInvoice !== 0 && (
                      <li class="table-row">
                        <div class="col col-2 col-grey col-bold">
                          {t("invoice.total")}
                        </div>
                        <div class="col col-1"></div>
                        <div class="col col-3 col-grey col-bold">
                          {totalTimeEntriesToInvoice}
                        </div>
                      </li>
                    )}
                  </ul>
                </Grid>
              </>
            )}
        </Grid>
        {/* <div id="footer">
          <Divider />
          <img
            src={activ}
            alt="IXIA-ACTIV-LOGO"
            style={{ width: "60px", float: "right", marginRight: "10px" }}
          />
        </div> */}
      </div>
    </div>
  );
});
