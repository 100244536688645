import ProjectList from "./index";

import { useParams } from "react-router-dom";

import { Modal, Grid, IconButton, Typography } from "@mui/material";

import { ClearOutlined as ClearOutlinedIcon } from "@mui/icons-material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import { useTranslation } from "react-i18next";

const ProjectsTable = ({
    display = false,
    setDisplay = () => { },
    tenant = null,
    handleClick = () => { },
    initialVal = [],
    refetchProjects = () =>{}
}) => {


    const { pID } = useParams();
    const { t } = useTranslation();

    return (
        <Modal
            open={display}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <ContainerCard sx={{ padding: "10px 20px", width: "70%", maxHeight: "97vh" }}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{
                                fontFamily: "poppins",
                            }}
                        >
                            {t("projectTable.AssignProject")}
                        </Typography>
                        <IconButton onClick={() => setDisplay(false)}>
                            <ClearOutlinedIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ProjectList
                            fromResource
                            initialVal={initialVal}
                            tenant={tenant}
                            handleClick={handleClick}
                            projectGuid={pID}
                            refetchProjects={refetchProjects}
                            setDisplay={setDisplay}
                        />
                    </Grid>
                </Grid>
            </ContainerCard>
        </Modal>
    );
};

export default ProjectsTable;
