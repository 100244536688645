import React, { useState, useEffect, useMemo } from "react";

//PropTypes
import PropTypes from "prop-types";

//components
import { Grid } from "@mui/material";

//components
import DataCard from "../../../components/Cards/DataCard";
import UserInfo from "./user-info";

//data
import getTableData from "./_day-logs";
import InfoDrawer from "../../../components/InfoDrawer";

import useQueryAuth from "../../../hooks/useQueryAuth";
import useAuth from "../../../hooks/useAuth";

import { GET_DAY_LOGS_FOR_DATE } from "../../../graphql/dayLogs";
import { useTranslation } from "react-i18next";

const DayLogs = ({ tenant = null, date }) => {
  const [loading, setLoading] = useState(false); //set true when we wish to start lazy loading

  const [userData, setUserData] = useState(null);

  const { t } = useTranslation();


  const { userRoles, storeUserGuid, storeUserTenant } = useAuth();

  const { isEntelligence } = userRoles;

  /**States for Late Clocks In */
  const [_dayLogs, _setDayLogs] = useState([]);
  const [_dayLogNumb, _setDayLogsNumb] = useState(0);

  const {
    loading: dayLogsLoading,
  } = useQueryAuth(
    GET_DAY_LOGS_FOR_DATE,
    "GetDayLogsForDate",
    {
      variables: {
        model: {
          tenantID: isEntelligence
            ? tenant
              ? tenant.tenantGuid
              : null
            : storeUserTenant,
          date: new Date(date).toISOString(),
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (data) => {
        var array = data?.GetDayLogsForDate?.dayLogs?.dayLogs || [];

        _setDayLogs((records) => [...records, ...array]);
        _setDayLogsNumb(
          data?.GetDayLogsForDate?.dayLogs?.totalNumberOfRecords || 0
        );
      },
    }
  );

  const _records = useMemo(() => _dayLogs || [], [_dayLogs]); //holds data
  const _maxRecords = _dayLogNumb || 0; //holds total number of records in database that satisfy the query

  useEffect(() => {
    if (_records && _records.length > 0) setLoading(false);
  }, [_records]);

  function fetchData() {
    //function that gets records on lazy load

    if (dayLogsLoading) return; //data is already being fetched, we wait for it to at least be fetched

    if (_records.length >= _maxRecords) return; //if data has reached its max

  }

  const _tableData = getTableData(t);
  
  const { title, headers, render } = _tableData; //holds card title, table headers and the display of data

  const stopLazyLoad = _records.length >= _maxRecords; //if all records have been fetched we no longer wish to lazy load

  function extraFunction(data) {
    if (!data) return;

    if (
      userData &&
      userData.employee.employeeGuid === data.employee.employeeGuid
    )
      setUserData(null);
    else setUserData(data);
  }

  useEffect(() => {
    if (dayLogsLoading === true) setUserData(null);
  }, [dayLogsLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DataCard
          id="dashboard-clockins-modal"
          title={title}
          stopLazyLoad={stopLazyLoad}
          onLazyLoad={fetchData}
          tableHeaders={headers}
          render={render}
          data={_records}
          initialLoad={!(dayLogsLoading && _dayLogNumb === 0)}
          loading={loading}
          setLoading={setLoading}
          additionalFunctionality={extraFunction}
          clickable
          giveMax
        />
      </Grid>
      <InfoDrawer
        width="fit-content"
        open={userData !== null}
        setOpen={() => setUserData(null)}
        // title="dailyDayLog.UserInfo"
      >
        <UserInfo data={userData} t={t} />
      </InfoDrawer>
    </Grid>
  );
};

DayLogs.propTypes = {
  initialLoad: PropTypes.bool,
  data: PropTypes.object,
  next: PropTypes.func,
  waitBatch: PropTypes.bool,
};

DayLogs.defaultProps = {
  initialLoad: false,
  data: {},
  next: () => {},
  waitBatch: false,
};

export default DayLogs;
