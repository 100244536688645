import React, { useState, useEffect, useCallback, useRef } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import {
  GET_OPEN_TIME_ENTRIES,
  DELETE_TIME_ENTRY_LIST,
} from "../../../../graphql/timeEntry";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TASKS_DROPDOWN } from "../../../../graphql/task";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";
import { useSnackbar } from "notistack";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import TimeEntryDrawer from "../../Timesheets/Timesheets-Form/components/TimeEntryDrawer";
import UTC2Local, { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useTranslation } from "react-i18next";

import EditRecord from "../../Timesheets/Timesheets-Form/components/EditRecord";
import { decodeUnicodeString } from "../../../../utils/decodeUnicodeString";
import { removeNullProps } from "../../../../utils/removeNullProps";

function RunningTasks() {
  const { storeUserGuid, storeUserTenant, userRoles, isManager } = useAuth();

  const { manageTimeEntry, isAdmin } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filterState, setFilterState] = useState([]);

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [timeEntry, setTimeEntry] = React.useState(null);
  const [timeEntryGuid, setTimeEntryGuid] = React.useState(null);
  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [synced, setSynced] = React.useState(false);
  const [re, setRe] = React.useState(false);
  const type = useRef("task");

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TIME_ENTRY_LIST,
    "DeleteTimeEntries"
  );

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: storeUserTenant,
        isManagerUsersList: isManager === true ? true : null,
        timeEntryDisabled: true,
      },
    },
  });

  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: projectData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: employeeWait,
    isWait: true,
  });

  const projects_list =
    projectData?.GetAllProjects?.projectsList?.projectViews || [];

  const { data: tasksList, tokenChange: tasksWait } = useQueryAuth(
    GET_ALL_TASKS_DROPDOWN,
    "GetTasks",
    {
      variables: {
        model: {
          tenantID: storeUserTenant,
          enableFilters: false,
          onGoingTasks: false,
          timeEntriesCreationAllowed: true,
        },
      },
      skip: employeeWait || projectWait,
      isWait: true,
    }
  );

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.OpenTimeEntries,
          userID: storeUserGuid,
        },
      },
      skip: employeeWait || projectWait || tasksWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        let col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        const actions = ({ compProps }) => {
          let props = compProps;
          return (
            <EditRecord
              {...props}
              handleClick={(v) => setClicked(v)}
              handleRowSelect={(t) => setTimeEntry(t)}
              handleEditMode={(v) => setEditMode(v)}
              handleSetSynced={(v) => setSynced(v)}
            />
          );
        };

        if (col) {
          col = decodeUnicodeString(col);
          
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, null, formatDateTimeToString)
          );

          if (parsed.columns) {
            let cols = parsed.columns;

            cols.map(c => {
              c = removeNullProps(c);
              return c;
            })
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  useEffect(() => {

    const objectIndexEmployeeIDs = filterState.findIndex(
      (obj) => obj.property === "employeeIDs"
    );


    const employeeIDsObj = {
      value: [storeUserGuid],
      property: "employeeIDs",
    };

    if (
      objectIndexEmployeeIDs < 0) {
      setDefaultFilters({
        ...defaultFilters,
        employeeIDs: [storeUserGuid],
      });
      setFilterState([
        ...filterState,
        employeeIDsObj,
      ]);
    }


  }, []);

  const filtersObject = [

    {
      label: "task.filters.label.employee",
      name: "employeeIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeesList,
      access: "employeeGuid",
      loading: employeeLoading,
      disabled: !isManager && !isAdmin ? true : false,
      disableClear: !isManager && !isAdmin ? true : false,
    },
    {
      label: "task.filters.label.project",
      name: "projectIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: projects_list,
      access: "projectGuid",
      loading: projectLoading,
    },
    {
      type: "date",
      label: "attendanceSheet.list.filters.date",
      name: "date",
    },
  ];

  const {
    mfunction: getOpenTimeEntries,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_OPEN_TIME_ENTRIES, "GetOpenTimeEntries");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getOpenTimeEntries(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantGuid: storeUserTenant,
              userID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
      data.GetOpenTimeEntries &&
      data.GetOpenTimeEntries.timeEntriesList &&
      data.GetOpenTimeEntries.timeEntriesList.timeEntries
      ? data.GetOpenTimeEntries.timeEntriesList.timeEntries
      : [];
  const numberOfRecords =
    data &&
      data.GetOpenTimeEntries &&
      data.GetOpenTimeEntries.timeEntriesList &&
      data.GetOpenTimeEntries.timeEntriesList.totalNumberOfRecords
      ? data.GetOpenTimeEntries.timeEntriesList.totalNumberOfRecords
      : 0;

  const handleOpenModal = (row) => {
    setTaskID(row?.task?.taskGuid);
    setProjectID(row?.task?.ticket?.projectGuid);
    setTaskName(row?.task?.title);
    setProjectName(row?.task?.ticket?.project?.title);
    setOpenModal(true);
  };

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            timeEntryGuids: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("timeEntry.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "openTimeEntries.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.OpenTimeEntries,
    filtersObject,
    readOnly: manageTimeEntry !== "M",
    deleteText: "timeEntry.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    linkCell: true,
    handleOpenModal,
    isFixed: true,
  };

  return (
    <div>
      <TimeEntryDrawer
        PaperComponent={PaperComponent}
        openTimeEntryModal={clicked}
        setOpenTimeEntryModal={(v) => setClicked(setClicked)}
        timeSheetGuid={null}
        timeEntryGuid={timeEntry?.timeEntryGuid}
        description={timeEntry?.description}
        synced={false}
        refetchData={refetchData}
        refetchTasks={null}
        editMode={true}
        handleEditMode={(v) => setEditMode(v)}
        handleChangeTimeEntryGuid={(v) => setTimeEntryGuid(v)}
        employees={employeesList}
        resetFields={null}
        selectedTask={null}
        tasks={task_list}
        t={t}
      />
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeTask={(v) => setTaskID(v)}
        handleChangeProject={(v) => setProjectID(v)}
        taskName={taskName}
        refetch={refetchData}
        fromProject={true}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"timeEntryGuid"}
          hasCheckbox={isAdmin? true : false}
        // checkForCheckbox={(row) => {
        //   return (
        //     (((row?.employee?.directManagerGuid === storeUserGuid ||
        //       row?.employee?.employeeGuid === storeUserGuid) &&
        //       isManager &&
        //       manageTimeEntry === "M") ||
        //       isAdmin)
        //   );
        // }}
        />
      )}
    </div>
  );
}

export default RunningTasks;
