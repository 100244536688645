import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";

import colors from "../../../../../assets/theme/light/colors";
import { MoveIcon, CloseIcon } from "../../../../../components/Icon";
import PaperComponent from "../../../../../components/Modal/PaperComponent";

import { Form, Formik } from "formik";
import * as yup from "yup";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../../graphql/project";
import { GET_ALL_TICKETS_DROPDOWN } from "../../../../../graphql/ticket";
import { GET_ALL_PARENT_TASKS, MOVE_TASK } from "../../../../../graphql/task";
import { useEffect, useRef, useState } from "react";
import AutoComplete from "../../../../../components/AutoComplete";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import { enqueueSnackbar } from "notistack";
//uuid generator
import { v4 as uuidv4 } from "uuid";

const MoveTaskModal = ({
  openDialog,
  handleClose,
  t,
  parentTask,
  selectedTask,
  refetechTask,
  changeNewlyOpen,
  handleFetchTree,
  handleSelectFirstTask,
}) => {
  const wait = false;
  const formField = {
    project: {
      name: "project",
      label: "taskModal.project.label",
      requiredMessage: "taskModal.project.requiredMessage",
    },
    ticket: {
      name: "ticket",
      label: "taskModal.phase.label",
      requiredMessage: "taskModal.phase.requiredMessage",
    },
    task: {
      name: "task",
      label: "taskModal.task.label",
      requiredMessage: "taskModal.task.requiredMessage",
    },
  };

  const { project, ticket, task } = formField;

  const validationSchema = yup.object({
    project: yup.object().required(project.requiredMessage),
    ticket: yup.object().nullable(),
    task: yup.object().nullable(),
  });

  const initialValues = {
    project: null,
    ticket: null,
    task: null,
  };

  const { storeUserTenant, storeUserGuid } = useAuth();
  const newlyOpened = useRef(true);
  const [changed, setChanged] = useState(false);
  const [projectGuid, setProjectGuid] = useState(
    selectedTask?.ticket?.project?.projectGuid
  );
  const [ticketGuid, setTicketGuid] = useState(
    selectedTask?.ticket?.ticketGuid
  );

  const [fetchProjects, setFetchProjects] = useState(false);
  const [fetchTickets, setFetchTickets] = useState(false);
  const [fetchTasks, setFetchTasks] = useState(false);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    newlyOpened.current = true;
  }, [changeNewlyOpen])

  const { cfunction: moveTaskData } = useMutationAuth(MOVE_TASK, "MoveTask");

  const {
    data: projectData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !openDialog || wait || !fetchProjects,
    isWait: fetchProjects,
  });

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_PARENT_TASKS, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        userID: storeUserGuid,
        projectID: projectGuid || selectedTask?.ticket?.project?.projectGuid,
        ticketID: ticketGuid
      },
    },
    skip: projectWait || !openDialog || wait || !fetchTasks,
    isWait: fetchTasks,
  });

  const { data: ticketData, loading: ticketLoading } = useQueryAuth(
    GET_ALL_TICKETS_DROPDOWN,
    "GetAllTickets",
    {
      variables: {
        model: {
          enableFilters: false,
          tenantID: storeUserTenant,
          projectID: projectGuid || selectedTask?.ticket?.project?.projectGuid,
        },
      },
      skip: projectWait || tasksWait || !openDialog || wait || !fetchTickets,
      isWait: fetchTickets,
    }
  );
  
  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const projects_list =
    projectData?.GetAllProjects?.projectsList?.projectViews || [];

  const tickets_list = ticketData?.GetAllTickets?.ticketsList?.tickets || [];

  const handleSubmit = (values, actions) => {
    moveTaskData(
      {
        variables: {
          model: {
            taskGuid: selectedTask?.taskGuid,
            parentGuid: values[task.name]?.taskGuid,
            ticketGuid: values[ticket.name]?.ticketGuid,
            projectGuid: values[project.name]?.projectGuid,
            tenantGuid: storeUserTenant,
          },
        },
      },
      (response) => {
        if (
          response["MoveTask"].errorCode !== null &&
          response["MoveTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["MoveTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          actions.setSubmitting(false);
        } else {
          const data = response.CreateTask?.task;
          enqueueSnackbar(t("taskForm.movedSuccessfully"), {
            variant: "success",
          });
          actions.setSubmitting(false);
          // newlyOpened.current = true;
          sessionStorage.removeItem("unsavedChanges");
          if (handleClose) handleClose();
          if (refetechTask) refetechTask();
          if (handleSelectFirstTask && values[project.name].projectGuid !== selectedTask?.ticket?.project?.projectGuid) handleSelectFirstTask();
          if (handleFetchTree) handleFetchTree();
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  };
  
  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setChanged(false);
        // newlyOpened.current = true;
        handleClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        style={{
          cursor: "move",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        id="draggable-dialog-title"
      >
        <div>
          <MoveIcon color={colors.icon_button_grey} />
          <span style={{ marginLeft: "5px" }}>
            {t("taskModal.label.moveTo")}
          </span>
        </div>
        <IconButton
          onClick={() => {
            setChanged(false);
            // newlyOpened.current = true;
            handleClose();
          }}
        >
          <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          key={`move-task`}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setTouched,
          }) => {
            if (newlyOpened.current === true) {
              setFieldValue(
                project.name,
                selectedTask?.ticket?.project
              );
              setFieldValue(ticket.name, selectedTask?.ticket);
              setFieldValue(task.name, parentTask?.taskGuid ? parentTask : null);
              newlyOpened.current = false;
            }
            const formData = {
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setTouched,
            };
            formikValuesRef.current = values;
            return (
              <Form
                id={`move-task-${uuidv4()}`}
                autoComplete="off"
                // onChange={() => {
                //   if (touched) {
                //     sessionStorage.setItem(
                //       "unsavedChanges",
                //       JSON.stringify(true)
                //     );
                //   }
                // }}
              >
                <Box p={2}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AutoComplete
                          options={projects_list}
                          getOptionLabel={(option) =>
                            option ? option.title : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={uuidv4()}>
                              {option.title}
                            </li>
                          )}
                          identifier={"projectGuid"}
                          value={
                            values[project.name]
                          }
                          onChange={(e, value) => {
                            setFieldValue(project.name, value);
                            setProjectGuid(value?.projectGuid);
                            setFieldValue(ticket.name, null);
                            setFieldValue(task.name, null);
                            setChanged(true);
                          }}
                          onOpen={() => setFetchProjects(true)}
                          onClose={() => setFetchProjects(false)}
                          required
                          loading={projectLoading}
                          label={t(project.label)}
                          name={project.name}
                          formData={formData}
                          disableFormChanged
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AutoComplete
                          options={tickets_list}
                          getOptionLabel={(option) =>
                            option ? option.title : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={uuidv4()}>
                              {option.title}
                            </li>
                          )}
                          identifier={"ticketGuid"}
                          value={
                            values[ticket.name]
                          }
                          onChange={(e, value) => {
                            setFieldValue(ticket.name, value);
                            setTicketGuid(value?.ticketGuid);
                            setFieldValue(task.name, null);
                            setChanged(true);
                          }}
                          onOpen={() => setFetchTickets(true)}
                          onClose={() => setFetchTickets(false)}
                          loading={ticketLoading}
                          label={t(ticket.label)}
                          name={ticket.name}
                          formData={formData}
                          disableFormChanged
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AutoComplete
                          options={task_list}
                          getOptionLabel={(option) =>
                            option ? option.title : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={uuidv4()}>
                              {option.title}
                            </li>
                          )}
                          identifier={"taskGuid"}
                          value={
                            values[task.name]
                          }
                          onChange={(e, value) => {
                            setFieldValue(task.name, value);
                            setFieldValue(ticket.name, value?.ticket);
                          }}
                          onOpen={() => setFetchTasks(true)}
                          onClose={() => setFetchTasks(false)}
                          loading={taskLoading}
                          label={t(task.label)}
                          name={task.name}
                          formData={formData}
                          disableFormChanged
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <IALoadingButton
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="submit"
                      label={t("taskForm.save")}
                    />
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default MoveTaskModal;
