import React, { useEffect, useRef, useState } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//graphql
import { GET_ALL_HELP, UPDATE_HELP } from "../../../../graphql/help";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import UTC2Local from "../../../../UTC2Local";

const Help = ({ fromModal = false, handleCloseModal, resetFields = false }) => {
  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageParameters } = userRoles;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const { title, description } = formField;

  const [sideInfoData, setSideInfoData] = useState({});

  const [unTouchedForm, setUnTouchedForm] = useState(true);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);
  const [helpGuid, setHelpGuid] = useState(null);

  const { cfunction: editHelpData } = useMutationAuth(
    UPDATE_HELP,
    "EditHelp"
  );
  function editHelp(values, actions) {
    editHelpData(
      {
        variables: {
          model: {
            helpGuid: helpGuid,
            description: values[description.name],
          },
        },
      },
      (response) => {
        if (
          response["EditHelp"].errorCode !== null &&
          response["EditHelp"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditHelp"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("companyForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if (
      performance.navigation.type === performance.navigation.TYPE_NAVIGATE &&
      unTouchedForm &&
      !fromModal
    ) {
      sessionStorage.clear();
    }
  }, []);

  const handleSubmit = (values, actions) => {
    editHelp(values, actions);
  };

  return (
    <>
      <Box mb={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-edit`}
                  initialValues={formData}
                  validationSchema={validation[1]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    dirty,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                      <Form
                        id={form.formID}
                        autoComplete="off"
                        onChange={() => {
                          if (touched) {
                            sessionStorage.setItem(
                              "unsavedChanges",
                              JSON.stringify(true)
                            );
                            setUnTouchedForm(false);
                          }
                        }}
                      >
                        <Box p={2}>
                          <Box>
                            <Main
                              formData={{
                                values,
                                touched,
                                formField: form.formField,
                                errors,
                                setFieldValue,
                                setTouched,
                                readOnly: manageParameters === "R",
                              }}
                              setHelpGuid={setHelpGuid}
                            />
                          </Box>
                          <Box
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              disabled={
                                isSubmitting || manageParameters !== "M"
                              }
                              loading={isSubmitting}
                              type="submit"
                              label={t("companyForm.save")}
                            />
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
    </>
  );
};

export default Help;
