import UTC2Local from "../../../../UTC2Local";

export const getTable = () => {
    return {
      columns: [
        {
          id: "employee",
          label: "attendance.list.column.employeeName",
          align: "left",
          visible: true,
          renderCell: (row) => {
            return (
              (row?.employee?.firstName || "") +
              " " +
              (row?.employee?.lastName || "")
            );
          },
        },
        {
          id: "createdBy",
          label: "employeeTeam.list.column.createdBy",
          align: "left",
          visible: true,
          renderCell: (row) => {
            return row?.createdBy?.email;
          },
        },
        {
          id: "creationDate",
          label: "employeeTeam.list.column.creationDate",
          align: "left",
          visible: true,
          renderCell: (row) => {
            return UTC2Local(row?.creationDate, true); ;
          },
        },
      ],
      defaultSort: "employee",
      defaultSortDirection: "asc",
    };
  };
  