import { React } from "react";

//components
import { Grid, Box } from "@mui/material";
import TextField from "../../../../../components/TextFields/TextField";
import AutoComplete from "../../../../../components/AutoComplete";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";

//gql
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../../graphql/location";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

//translation
import { useTranslation } from "react-i18next";

import Mnemonics from "../../../../../Mnemonics.json";
import { day_month_year } from "../../../../../utils/timezones";
import { useEffect } from "react";

import colors from "../../../../../assets/theme/light/colors";

import { v4 as uuidv4 } from "uuid";

const Main = ({
  formData,
  employee_list,
  workType_list,
  employeeLoading,
  workTypeLoading,
  manager,
  userID,
  tenant,
}) => {
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const { t } = useTranslation();

  const {
    storeUserGuid,
    storeUserFirstName,
    storeUserLastName,
    isManager,
    userRoles,
    storeUserTenant,
  } = useAuth();
  const { isAdmin, isEntelligence, isAppUser, manageAttendance } = userRoles;

  const isTenantUser = !isAdmin && !isEntelligence && !isAppUser;
  const user = {
    employeeGuid: storeUserGuid,
    firstName: storeUserFirstName,
    lastName: storeUserLastName,
  };

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    editMode,
  } = formData;

  const {
    employee,
    description,
    date,
    checkInTime,
    checkOutTime,
    workType,
    location,
  } = formField;

  const {
    employee: employeeV,
    description: descriptionV,
    date: dateV,
    checkInTime: checkInTimeV,
    checkOutTime: checkOutTimeV,
    workType: workTypeV,
    location: locationV,
  } = values;

  useEffect(() => {
    if (
      ((isTenantUser && (editMode === false || editMode === undefined)) ||
        editMode === false ||
        editMode === undefined) &&
      employee_list.some((x) => x.employeeGuid === storeUserGuid)
    ) {
      setFieldValue(employee.name, user);
      setFieldValue("date", new Date());
    }
  }, []);

  const { data: locationsList, loading: locationLoading } = useQueryAuth(
    GET_ALL_LOCATIONS_DROPDOWN,
    "GetAllLocations",
    {
      variables: {
        model: {
          enableFilters: false,
          userID: storeUserGuid,
          tenantID: storeUserTenant,
          isNotOnsite:
            workTypeV?.mnemonic === Mnemonics.WorkTypes.workfromsite
              ? false
              : true,
        },
      },
      skip:
        !workTypeV || workTypeV?.mnemonic === Mnemonics.WorkTypes.workfromhome,
      isWait: true,
    }
  );

  const location_list =
    locationsList &&
    locationsList.GetAllLocations &&
    locationsList.GetAllLocations.locationsList &&
    locationsList.GetAllLocations.locationsList.locations
      ? locationsList.GetAllLocations.locationsList.locations
      : [];
      
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AutoComplete
            autoFocus={!(editMode ||
              (!isAdmin && !isManager && manageAttendance !== "M") ||
              readOnly ||
              (manager !== null &&
                manager !== undefined &&
                manager !== storeUserGuid &&
                isManager === true &&
                userID !== undefined &&
                userID !== null &&
                userID !== storeUserGuid) ||
              (tenant !== null &&
                tenant !== undefined &&
                tenant !== storeUserTenant))}
            options={employee_list}
            getOptionLabel={(option) =>
              option ? option.firstName + " " + option.lastName : ""
            }
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.firstName + " " + option.lastName}
              </li>
            )}
            identifier={"employeeGuid"}
            disabled={
              editMode ||
              (!isAdmin && !isManager && manageAttendance !== "M") ||
              readOnly ||
              (manager !== null &&
                manager !== undefined &&
                manager !== storeUserGuid &&
                isManager === true &&
                userID !== undefined &&
                userID !== null &&
                userID !== storeUserGuid) ||
              (tenant !== null &&
                tenant !== undefined &&
                tenant !== storeUserTenant)
            }
            value={employeeV}
            onChange={(e, value) => {
              setFieldValue(employee.name, value);
            }}
            loading={employeeLoading}
            required
            label={t(employee.label)}
            name={employee.name}
            formData={formData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            autoFocus={
              (!isAdmin && !isManager && manageAttendance !== "M") ||
              readOnly ||
              (manager !== null &&
                manager !== undefined &&
                manager !== storeUserGuid &&
                isManager === true &&
                userID !== undefined &&
                userID !== null &&
                userID !== storeUserGuid) ||
              (tenant !== null &&
                tenant !== undefined &&
                tenant !== storeUserTenant)}
            fullWidth
            label={t(description.label)}
            value={descriptionV}
            onBlur={(e) =>
              setTouched({ ...touched, [description.description]: true })
            }
            onChange={(e) => {
              setFieldValue(description.name, e.target.value);
            }}
            disabled={
              readOnly ||
              ((isAdmin ? tenant && tenant !== storeUserTenant : true) &&
                isManager === true &&
                manager &&
                manager !== storeUserGuid &&
                userID &&
                userID !== storeUserGuid)
            }
            error={touched[description.name] && errors[description.name]}
            helperText={
              touched[description.name] &&
              errors[description.name] &&
              t(errors[description.name])
            }
            formData={formData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AutoComplete
            options={workType_list}
            getOptionLabel={(option) => (option ? option.description : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.description}
              </li>
            )}
            identifier={"workTypeGuid"}
            disabled={
              createMode === false ||
              readOnly ||
              ((isAdmin ? tenant && tenant !== storeUserTenant : true) &&
                isManager === true &&
                manager &&
                manager !== storeUserGuid &&
                userID &&
                userID !== storeUserGuid)
            }
            value={workTypeV}
            onChange={(e, value) => {
              setFieldValue(workType.name, value);
            }}
            loading={workTypeLoading}
            required
            name={workType.name}
            label={t(workType.label)}
            formData={formData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AutoComplete
            options={location_list}
            getOptionLabel={(option) => (option ? option.name : "")}
            renderOption={(props, option) => (
              <li {...props} key={uuidv4()}>
                {option.name}
              </li>
            )}
            isOptionEqualToValue={(option, value) =>
              value === "" || option.locationGuid === value.locationGuid
            }
            disabled={
              createMode === false ||
              readOnly ||
              ((isAdmin ? tenant && tenant !== storeUserTenant : true) &&
                isManager === true &&
                manager &&
                manager !== storeUserGuid &&
                userID &&
                userID !== storeUserGuid)
            }
            value={locationV}
            onChange={(e, value) => {
              setFieldValue(location.name, value);
            }}
            loading={locationLoading}
            label={t(location.label)}
            name={location.name}
            required={
              workTypeV &&
              workTypeV.mnemonic !== Mnemonics.WorkTypes.workfromhome
            }
            formData={formData}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={12} xl={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              format={
                day_month_year.includes(userTimezone) === true
                  ? "dd-MM-yyyy"
                  : "MM-dd-yyyy"
              }
              disabled={
                readOnly ||
                editMode ||
                ((isAdmin ? tenant && tenant !== storeUserTenant : true) &&
                  isManager === true &&
                  manager &&
                  manager !== storeUserGuid &&
                  userID &&
                  userID !== storeUserGuid)
              }
              label={t(date.label)}
              value={dateV}
              onChange={(newValue) => {
                setFieldValue(date.name, newValue);
                if(checkInTimeV) {
                  var checkInHours = new Date(checkInTimeV)?.getHours();
                  var checkInMinutes = new Date(checkInTimeV)?.getMinutes();
                  var formattedDate = newValue?.toJSON();
                  var d = new Date(formattedDate);
                  d.setHours(checkInHours);
                  d.setMinutes(checkInMinutes);
                  setFieldValue(checkInTime.name, d);
                }
                
                if(checkOutTimeV) {
                  var checkOutHours = new Date(checkOutTimeV)?.getHours();
                  var checkOutMinutes = new Date(checkOutTimeV)?.getMinutes();
                  var formattedDate = newValue?.toJSON();
                  var d = new Date(formattedDate);
                  d.setHours(checkOutHours);
                  d.setMinutes(checkOutMinutes);
                  setFieldValue(checkOutTime.name, d);
                }
              }}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  size: "small",
                },
              }}
              sx={{
                width: "100%",
                fontFamily: "poppins",
                "& .MuiInputBase-root": { height: "40px !important" },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t(checkInTime.label)}
              value={checkInTimeV}
              className="date_picker_styles"
              ampm={false}
              disabled={
                readOnly ||
                !dateV ||
                ((isAdmin ? tenant && tenant !== storeUserTenant : true) &&
                  isManager === true &&
                  manager &&
                  manager !== storeUserGuid &&
                  userID &&
                  userID !== storeUserGuid)
              }
              maxTime={checkOutTimeV}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  required: true,
                  size: "small",
                  fullWidth: true,
                  onBlur: (e) => {
                    setTouched({
                      ...touched,
                      [checkInTime.name]: true,
                    });
                  },
                  error: touched[checkInTime.name] && errors[checkInTime.name],
                  helperText:
                    touched[checkInTime.name] &&
                    errors[checkInTime.name] &&
                    t(errors[checkInTime.name]),
                },
              }}
              onChange={(newValue) => {
                var checkInHours = new Date(newValue)?.getHours();
                var checkInMinutes = new Date(newValue)?.getMinutes();
                var formattedDate = dateV?.toJSON();
                var d = new Date(formattedDate);
                d.setHours(checkInHours);
                d.setMinutes(checkInMinutes);
                setFieldValue(checkInTime.name, d);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t(checkOutTime.label)}
              value={checkOutTimeV}
              minTime={checkInTimeV}
              disabled={
                readOnly ||
                !dateV ||
                !checkInTimeV ||
                ((isAdmin ? tenant && tenant !== storeUserTenant : true) &&
                  isManager === true &&
                  manager &&
                  manager !== storeUserGuid &&
                  userID &&
                  userID !== storeUserGuid)
              }
              className="date_picker_styles"
              ampm={false}
              slotProps={{
                desktopPaper: {
                  sx: {
                    boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                  },
                },
                textField: {
                  required: true,
                  size: "small",
                  fullWidth: true,
                  onBlur: (e) => {
                    setTouched({
                      ...touched,
                      [checkOutTime.name]: true,
                    });
                  },
                  error:
                    touched[checkOutTime.name] && errors[checkOutTime.name],
                  helperText:
                    touched[checkOutTime.name] &&
                    errors[checkOutTime.name] &&
                    t(errors[checkOutTime.name]),
                },
              }}
              onChange={(newValue) => {
                var checkOutHours = new Date(newValue)?.getHours();
                var checkOutMinutes = new Date(newValue)?.getMinutes();
                var formattedDate = dateV?.toJSON();
                var d = new Date(formattedDate);
                d.setHours(checkOutHours);
                d.setMinutes(checkOutMinutes);
                setFieldValue(checkOutTime.name, d);
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
