import { gql } from "@apollo/client";

export const GET_ALL_OFFICIAL_HOLIDAYS = gql`
  query ($model: OfficialHolidayGetListModelInput!) {
    GetOfficialHolidaysList(model: $model) {
      officialHolidayListModel {
        officialHolidays {
          officialHolidayGuid
          description
        }

        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_OFFICIAL_HOLIDAYS = gql`
  mutation ($model: DeleteOfficialHolidayListRequestModelInput!) {
    DeleteOfficialHolidayList(model: $model) {
      officialHolidayDeleteResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_OFFICIAL_HOLIDAY_BY_ID = gql`
  query ($model: GetOfficialHolidayByIDRequestModelInput!) {
    GetOfficialHolidayByID(model: $model) {
      officialHoliday {
        officialHolidayGuid
        officialHolidayID
        description
        officialHolidayDays {
          officialHolidayDayGuid
          officialHolidayDayID
          description
          date
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_OFFICIAL_HOLIDAY = gql`
  mutation ($model: OfficialHolidayUpdateRequestInput!) {
    UpdateOfficialHoliday(model: $model) {
      officialHoliday {
        officialHolidayGuid
        description
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_OFFICIAL_HOLIDAY = gql`
  mutation ($model: OfficialHolidayCreateRequestInput!) {
    CreateOfficialHoliday(model: $model) {
      officialHoliday {
        officialHolidayGuid
        description
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_UPCOMING_OFFICIAL_HOLIDAYS = gql`
  query ($model: GetUpcomingOfficialHolidaysRequestModelInput!) {
    GetUpcomingOfficialHolidayDays(model: $model) {
      upcomingOfficialHolidays {
        officialHolidayDays {
          officialHolidayDayGuid
          description
          date
          officialHoliday {
            officialHolidayGuid
            description
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_UPCOMING_TIME_OFFS = gql`
  query ($model: UpcomingPTORequestModelInput!) {
    GetUpcomingPTOsForTenant(model: $model) {
      toEntitlementListResponse {
        toEntitlements {
          toEntitlementGuid
          description
          numberOfDays
          date
          employeePTO {
            employee {
              firstName
              lastName
            }
          }
          toRequest {
            toRequestGuid
            requestDate
            dateFrom
            dateTo
            timeFrom
            timeTo
          }
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const COPY_TO_NEXT_YEAR = gql`
mutation($model:OfficialHolidayCopyRequestInput!){
  CopyOfficialHolidayToNextYear (model: $model){
    officialHoliday {
      officialHolidayGuid
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;
