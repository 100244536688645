import React, { useEffect, useState, useRef } from "react";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

import { formatDateTimeZone } from "../../../../UTC2Local";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import {
  GET_DAY_LOG_BY_ID,
  EDIT_DAY_LOG,
  CREATE_DAY_LOG,
} from "../../../../graphql/dayLogs";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

const AttendanceSheetForm = ({
  attendanceSheetGuid,
  dayLogGuid,
  status,
  synced,
  refetchData = {},
  handleEditMode,
  editMode = false,
  employee_list = [],
  workType_list = [],
  employeeLoading = false,
  workTypeLoading = false,
  resetFields,
  handleChangeDayLogGuid,
}) => {

  const {
    storeUserTenant,
    storeUserGuid,
    userRoles,
  } = useAuth();
  const { isAdmin } = userRoles;

  const { t } = useTranslation();

  const { formField } = form;

  const {
    employee,
    description,
    date,
    checkInTime,
    checkOutTime,
    workType,
    location,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);
  // const [status, setStatus] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [manager, setManager] = useState(null);
  const [userID, setUserID] = useState(null);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (editMode === false) {
      setFormData(initialValues);
      // setStatus(null);
      setManager(null);
      setTenant(null);
      setUserID(null);
    }
  }, [editMode]);

  const { loading: getDataByIDLoading } = useQueryAuth(
    GET_DAY_LOG_BY_ID,
    "GetDayLogByID",
    {
      variables: { id: dayLogGuid },
      skip: dayLogGuid == null,
      isWait: false,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetDayLogByID?.dayLogObject || {};

        setFormData({
          [employee.name]: data.employee || null,
          [description.name]: data.description || null,
          [location.name]: data.location || null,
          [workType.name]: data.workType || "",
          [date.name]:
            data.checkInTime == null
              ? null
              : new Date(formatDateTimeZone(data.checkInTime)),
          [checkOutTime.name]:
            data.checkOutTime == null
              ? null
              : new Date(formatDateTimeZone(data.checkOutTime)),
          [checkInTime.name]:
            data.checkInTime == null
              ? null
              : new Date(formatDateTimeZone(data.checkInTime)),
        });
        // setStatus(data.timeSheet?.status);
        setTenant(data.employee?.tenantGuid);
        setManager(data.employee?.directManagerGuid);
        setUserID(data.employee?.employeeGuid);
      },
    }
  );

  const { cfunction: editDayLogData } = useMutationAuth(
    EDIT_DAY_LOG,
    "EditDayLog"
  );

  function EditDayLogData(values, actions) {
    if(new Date(values[checkInTime.name]) >  new Date(values[checkOutTime.name]))
    {
      enqueueSnackbar( t("dayLogForm.checkin.earlier.checkout"),
        {
          variant: "error",
          autoHideDuration: 5000
        }
      );
      actions.setSubmitting(false);
    }
    else editDayLogData(
      {
        variables: {
          model: {
            dayLogGuid: dayLogGuid,
            checkInTime: new Date(values[checkInTime.name]).toISOString(),
            checkOutTime: new Date(values[checkOutTime.name]).toISOString(),
            description: values[description.name],
            workTypeGuid: values[workType.name]?.workTypeGuid,
            locationGuid: values[location.name]?.locationGuid,
            modifiedByGuid: storeUserGuid,
          },
        },
      },
      (response) => {
        if (
          response["EditDayLog"].errorCode !== null &&
          response["EditDayLog"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditDayLog"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("timeEntryForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false));
          if (refetchData) refetchData();
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }
  const { cfunction: createDayLogData } = useMutationAuth(
    CREATE_DAY_LOG,
    "CreateDayLog"
  );

  function CreateDayLogData(values, actions) {
    if(new Date(values[checkInTime.name]) >  new Date(values[checkOutTime.name]))
    {
      enqueueSnackbar( t("dayLogForm.checkin.earlier.checkout"),
        {
          variant: "error",
          autoHideDuration: 5000
        }
      );
      actions.setSubmitting(false);
    }
    else createDayLogData(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            employeeGuid: values[employee.name]?.employeeGuid,
            attendanceSheetID: attendanceSheetGuid,
            checkInTime: new Date(values[checkInTime.name]).toISOString(),
            checkOutTime: new Date(values[checkOutTime.name]).toISOString(),
            description: values[description.name],
            workTypeGuid: values[workType.name]?.workTypeGuid,
            locationGuid: values[location.name]?.locationGuid,
            webClockin: true,
            isAutomaticRecord: false,
          },
        },
      },
      (response) => {
        if (
          response["CreateDayLog"].errorCode !== null &&
          response["CreateDayLog"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateDayLog"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("timeEntryForm.createdSuccessfully"), {
            variant: "success",
          });
          handleEditMode(true);
          if(handleChangeDayLogGuid) handleChangeDayLogGuid(response["CreateDayLog"]?.dayLogObject?.dayLogGuid)
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false));
          if (refetchData) refetchData();
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }
  
  const handleSubmit = (values, actions) => {
    if (editMode) EditDayLogData(values, actions);
    else CreateDayLogData(values, actions);
  };
  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard
          sx={{ height: "50vh", width: "600px", border: "none", boxShadow: "none" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%" }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/* <ContainerCard> */}
              <Formik
                innerRef={formikRef}
                key={`${form.formID}-form`}
                initialValues={editMode === true ? formData : initialValues}
                validationSchema={validation[0]}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  setTouched,
                }) => {
                formikValuesRef.current = values;
                return (
                  <Form id={form.formID} autoComplete="off">
                    <Box p={2}>
                      <Box>
                        <Main
                          formData={{
                            values,
                            touched,
                            formField: form.formField,
                            errors,
                            setFieldValue,
                            setTouched,
                            editMode,
                            synced,
                            readOnly:
                              status !==
                              Mnemonic.AttendanceSheetStatuses.Pending,
                          }}
                          resetFields={resetFields}
                          status={status}
                          tenant={tenant}
                          manager={manager}
                          userID={userID}
                          employee_list={employee_list}
                          workType_list={workType_list}
                          employeeLoading={employeeLoading}
                          workTypeLoading={workTypeLoading}
                        />
                      </Box>
                      {/* {!synced &&
                        status === Mnemonic.AttendanceSheetStatuses.Pending &&
                        ((manager ? manager === storeUserGuid : true) ||
                          (userID ? userID === storeUserGuid : true) ||
                          (isAdmin &&
                            (tenant ? tenant === storeUserTenant : true))) && ( */}
                      {status === Mnemonic.AttendanceSheetStatuses.Pending &&
                        // () && 
                        (
                          <Box
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <IALoadingButton
                              disabled={isSubmitting || ((isAdmin ? tenant && tenant !== storeUserTenant : true) && (manager && manager !== storeUserGuid && userID && userID !== storeUserGuid)) }                                
                              loading={isSubmitting}
                              type="submit"
                              label={t("timeEntryForm.save")}
                            />
                          </Box>
                        )}
                      {/* )} */}
                    </Box>
                  </Form>
                )}}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AttendanceSheetForm;
