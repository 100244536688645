export default {
  formID: "tenant",
  formField: {
    name: {
      name: "name",
      label: "tenantForm.name.label",
      requiredMessage: "tenantForm.name.requiredMessage",
    },
    projectRadius:{
      name:"projectRadius",
      label:"tenantForm.projectRadius.label",
      requiredMessage:"TenantForm.projectRadius.requiredMessage"
    },
    attendance:{
      name:"attendance",
      label:"tenantForm.attendance.label",
      requiredMessage:"TenantForm.attendance.requiredMessage"
    },
    timeEntry:{
      name:"timeEntry",
      label:"tenantForm.timeEntry.label",
      requiredMessage:"TenantForm.timeEntry.requiredMessage"
    },
    syncTimeEntries:{
      name:"syncTimeEntries",
      label:"tenantForm.syncTimeEntries.label",
      requiredMessage:"TenantForm.syncTimeEntries.requiredMessage"
    },
    taskStatuses:{
      name:"taskStatuses",
      label:"tenantForm.taskStatuses.label",
      requiredMessage:"TenantForm.taskStatuses.requiredMessage"
    },
    phaseStatuses:{
      name:"phaseStatuses",
      label:"tenantForm.phaseStatuses.label",
      requiredMessage:"TenantForm.phaseStatuses.requiredMessage"
    },
    
    LogCleanUpRuntime:{
      name:"LogCleanUpRuntime",
      label:"tenantForm.LogCleanUpRuntime.label",
      requiredMessage:"TenantForm.LogCleanUpRuntime.requiredMessage"
    },
    LogRetentionDays:{
      name:"LogRetentionDays",
      label:"tenantForm.LogRetentionDays.label",
      requiredMessage:"TenantForm.LogRetentionDays.requiredMessage"
    },
    connector:{
      name:"connector",
      label:"tenantForm.connector.label",
      requiredMessage:"TenantForm.connector.requiredMessage"
    },
    middlewareJob:{
      name:"middlewareJob",
      label:"tenantForm.middlewareJob.label",
      requiredMessage:"TenantForm.middlewareJob.requiredMessage"
    },
    timesheetBy:{
      name:"timesheetBy",
      label:"tenantForm.timesheetBy.label",
      requiredMessage:"TenantForm.timesheetBy.requiredMessage" 
    },
    hoursOfWork:{
      name:"hoursOfWork",
      label:"tenantForm.hoursOfWork.label",
      requiredMessage:"TenantForm.hoursOfWork.requiredMessage" 
    },
    job:{
      name:"job",
      label:"tenantForm.job.label",
      requiredMessage:"TenantForm.job.requiredMessage" 
    },
    company:{
      name:"company",
      label:"tenantForm.company.label",
      requiredMessage:"TenantForm.company.requiredMessage" 
    },
    task:{
      name:"task",
      label:"tenantForm.task.label",
      requiredMessage:"TenantForm.task.requiredMessage" 
    },
    allowTimesheetReopening:{
      name:"allowTimesheetReopening",
      label:"tenantForm.allowTimesheetReopening.label",
      requiredMessage:"TenantForm.allowTimesheetReopening.requiredMessage" 
    },
    mandatoryTimeEntryDescription:{
      name:"mandatoryTimeEntryDescription",
      label:"tenantForm.mandatoryTimeEntryDescription.label",
      requiredMessage:"TenantForm.mandatoryTimeEntryDescription.requiredMessage" 
    },
    allowAttendanceSheetReopening:{
      name:"allowAttendanceSheetReopening",
      label:"tenantForm.allowAttendanceSheetReopening.label",
      requiredMessage:"TenantForm.allowAttendanceSheetReopening.requiredMessage" 
    },
    monthlyAttendanceSheet:{
      name:"monthlyAttendanceSheet",
      label:"tenantForm.monthlyAttendanceSheet.label",
      requiredMessage:"TenantForm.monthlyAttendanceSheet.requiredMessage" 
    },
    postJournal:{
      name:"postJournal",
      label:"tenantForm.postJournal.label",
      requiredMessage:"tenantForm.postJournal.requiredMessage" 
    },
    postJournalDescription:{
      name:"postJournalDescription",
      label:"tenantForm.postJournalDescription.label",
      requiredMessage:"tenantForm.postJournalDescription.requiredMessage" 
    },
    postUnderAndOverUsageToNonBillableJob:{
      name:"postUnderAndOverUsageToNonBillableJob",
      label:"tenantForm.postUnderAndOverUsageToNonBillableJob.label",
      requiredMessage:"tenantForm.postUnderAndOverUsageToNonBillableJob.requiredMessage" 
    },
    trial:{
      name:"trial",
      label:"tenantForm.trial.label",
      requiredMessage:"tenantForm.trial.requiredMessage" 
    },
    trialExpirationDate:{
      name:"trialExpirationDate",
      label:"tenantForm.trialExpirationDate.label",
      requiredMessage:"tenantForm.trialExpirationDate.requiredMessage" 
    },
    subscribedFrom:{
      name:"subscribedFrom",
      label:"tenantForm.subscribedFrom.label",
      requiredMessage:"tenantForm.subscribedFrom.requiredMessage" 
    },
  },
};
