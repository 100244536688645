import { Tooltip } from "@mui/material";
import colors from "../../assets/theme/light/colors";

const CustomTooltip = (props, {fromDelete}) => { 
  return (
    <Tooltip
      sx={{ ...props.sx, backgroundColor: "transparent" }}
      slotProps={{
        tooltip: {
          sx: { 
            fontSize:"12px",
            fontFamily:"poppins",
            lineHeight:"21px", 
          backgroundColor: "#ffffff",
          color: "#000",
          borderRadius: "2px",
          boxShadow: `${colors.box_shadow_color}  0px 1px 4px !important`,
          padding: "10px !important"},
          bottom: 0,
          marginTop: 0,
          marginLeft: fromDelete? 3: 0,
          top: 0,
          marginBottom: 0,
          
        },
      }}
      title={
        <span style={{ whiteSpace: 'pre-line' }}>{props.title}</span>
      }
      {...props}
    />
  );
};

export default CustomTooltip;