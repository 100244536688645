import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function WorkShiftTabs({ fromTenant = false, myWorkShifts = false }) {
  const { wsName, wsID, tName, tID } = useParams();

  const { tenant_workshift_form, my_workshifts_form } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !wsName ||
    !wsID ||
    wsName === "undefined" ||
    wsName === "null" ||
    wsName === "" ||
    wsID === "null" ||
    wsID === "undefined" ||
    wsID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(wsName);
  const encodedID = encodeURIComponent(wsID);

  const TabLinks = [
    {
      key: 1,
      Header: t("workshift.form"),
      Link: myWorkShifts
        ? my_workshifts_form.replace(
            "/:wsName/:wsID",
            `/${encodedName}/${encodedID}`
          )
        : tenant_workshift_form
            .replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
            .replace("/:wsName/:wsID", `/${encodedName}/${encodedID}`),
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default WorkShiftTabs;
