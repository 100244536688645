import React from "react";

import colorsLight from "../../assets/theme/light/colors";

const TimesheetStatus = (props) => {

  const { status } = props;
  const { timesheetStatusColors } = colorsLight;

  if (!status) return null;
  return (
    <span
    style={{
      padding: "5px 10px",
      borderRadius: "5px",
      fontFamily: "poppins",
      margin: "auto",
      fontSize: "13px",
      width: "90px", 
      textAlign: "center", 
      display: "inline-block", 
      ...timesheetStatusColors[status],
    }}
  >
    {status}
  </span>
  );
 
};

export default TimesheetStatus;