import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";
import SideInfo from "./components/sideInfo";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";

//graphql
import {
  GET_TENANT_BY_ID,
  UPDATE_TENANT,
  CREATE_TENANT,
} from "../../../../graphql/tenant";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import Help from "./components/_help";

import UTC2Local, { formatDateTimeZone } from "../../../../UTC2Local";

const TenantForm = ({ myTenant = false, fromTenant = false }) => {
  const { tName, tID } = useParams();

  const createMode = !tName && !tID;

  const { tenant_form } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [sideInfoData, setSideInfoData] = useState({});

  const [whatField, setWhatField] = useState(null);

  const [isActive, setIsActive] = useState(false);
  const [refetchTable, setRefetchTable] = useState(false);
  const [middlewareArray, setMiddlewareArray] = useState([]);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const {
    name,
    task,
    timesheetBy,
    hoursOfWork,
    job,
    company,
    syncTimeEntries,
    projectRadius,
    attendance,
    timeEntry,
    phaseStatuses,
    taskStatuses,
    LogCleanUpRuntime,
    LogRetentionDays,
    connector,
    middlewareJob,
    allowTimesheetReopening,
    mandatoryTimeEntryDescription,
    allowAttendanceSheetReopening,
    monthlyAttendanceSheet,
    postJournal,
    postJournalDescription,
    postUnderAndOverUsageToNonBillableJob,
    trialExpirationDate,
    subscribedFrom,
    trial,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);
  const timesheetByList = [
    { description: "Monthly", value: true, id: 1 },
    { description: "Weekly", value: false, id: 2 },
  ];

  const attendanceList = [
    { description: "Monthly", value: true, id: 1 },
    { description: "Weekly", value: false, id: 2 },
  ]
  const { loading: getDataByIDLoading, tokenChange: wait } = useQueryAuth(
    GET_TENANT_BY_ID,
    "GetTenant",
    {
      variables: { tenantID: tID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response.GetTenant.tenantView.tenant || {};
        const middle = data?.tenantMiddlewareInfo?.tenantMiddlewareJobs.map(
          (middleware) => middleware.middlewareJob
        );

        setFormData({
          [name.name]: data.name || "",
          [job.name]: data?.tenantMiddlewareInfo?.job || "",
          [task.name]: data?.tenantMiddlewareInfo?.task || "",
          [company.name]: data?.tenantMiddlewareInfo?.company || "",
          [hoursOfWork.name]: data?.numberOfMonthlyWorkingHours || 0,
          [projectRadius.name]: data.projectRadiusInMobileApp || 0,
          [LogRetentionDays.name]:
            data?.tenantMiddlewareInfo?.retentionDays || "",
          [LogCleanUpRuntime.name]:
            data?.tenantMiddlewareInfo?.logCleanRuntime != null
              ? new Date(
                  formatDateTimeZone(
                    data?.tenantMiddlewareInfo?.logCleanRuntime
                  )
                )
              : null || null,
          [phaseStatuses.name]:
            data?.tenantMiddlewareInfo?.ticketStatus || null,
          [taskStatuses.name]: data?.tenantMiddlewareInfo?.taskStatus || null,
          [attendance.name]: data?.tenantMiddlewareInfo?.attendance || false,
          [timeEntry.name]: data?.tenantMiddlewareInfo?.timeEntry || false,
          [syncTimeEntries.name]:
            data?.tenantMiddlewareInfo?.syncTimeEntry || false,
          [connector.name]: data?.tenantMiddlewareInfo?.connector || null,
          [middlewareJob.name]: middle || [],
          [timesheetBy.name]:
            data?.monthlyTimesheet === true
              ? timesheetByList[0]
              : timesheetByList[1],
          [allowTimesheetReopening.name]:
            data?.allowTimesheetReopening || false,
            [allowAttendanceSheetReopening.name]: data?.allowAttendanceSheetReopening || false,
            [monthlyAttendanceSheet.name]: data?.monthlyAttendanceSheet === true
            ? attendanceList[0]
            : attendanceList[1],
          [mandatoryTimeEntryDescription.name]:
            data?.mandatoryTimeEntryDescription || false,
          [postJournal.name]:
            data?.postJournal || false,
          [postUnderAndOverUsageToNonBillableJob.name]: data.postUnderAndOverUsageToNonBillableJob || false,
          [postJournalDescription.name]: data.postJournalDescription || "",
          [trial.name]: data.trial || false,
          [subscribedFrom.name]: data.subscribedFrom || "",
          [trialExpirationDate.name]: data?.trialExpirationDate != null ? new Date(
            formatDateTimeZone(
              data?.trialExpirationDate
            )
          ) : null || null,
        });

        setIsActive(data?.isActive || false);

        setSideInfoData({
          "tenantForm.creationDate": UTC2Local(data?.creationDate) || "",
        });
      },
    }
  );

  const { cfunction: editTenantData } = useMutationAuth(
    UPDATE_TENANT,
    "EditTenant"
  );

  const { cfunction: createTenantData } = useMutationAuth(
    CREATE_TENANT,
    "CreateTenant"
  );

  function createTenant(values, actions) {
    createTenantData(
      {
        variables: {
          model: {
            name: values[name.name],
            numberOfMonthlyWorkingHours: values[hoursOfWork.name],
            monthlyTimesheet: values[timesheetBy.name]?.value || false,
            allowTimesheetReopening: values[allowTimesheetReopening.name],
            allowAttendanceSheetReopening: values[allowAttendanceSheetReopening.name],
            monthlyAttendanceSheet: values[monthlyAttendanceSheet.name]?.value || false,
            mandatoryTimeEntryDescription: values[mandatoryTimeEntryDescription.name],
            projectRadiusInMobileApp: values[projectRadius.name],
            postUnderAndOverUsageToNonBillableJob: values[postUnderAndOverUsageToNonBillableJob.name],
            postJournalDescription: values[postJournalDescription.name],
            postJournal: values[postJournal.name],
            subscribedFrom: values[subscribedFrom.name],
            tenantMiddlewareInfoCreateRequest: {
              job: values[job.name],
              task: values[task.name],
              companyGuid: values[company.name]?.companyGuid,
              attendance: values[attendance.name],
              timeEntry: values[timeEntry.name],
              syncTimeEntry: values[syncTimeEntries.name],
              connectorGuid: values[connector.name]?.connectorGuid || null,
              logCleanRuntime: new Date(values[LogCleanUpRuntime.name]),
              retentionDays: parseInt(values[LogRetentionDays.name]),
              ticketStatusGuid: [phaseStatuses.name]?.ticketStatusGuid,
              taskStatusGuid: [taskStatuses.name]?.taskStatusGuid,
            },
          },
        },
      },
      (response) => {
        if (
          response["CreateTenant"].errorCode !== null &&
          response["CreateTenant"].errorCode !== "" &&
          response["CreateTenant"].errorMessage !== ""
        ) {
          enqueueSnackbar(t(response["CreateTenant"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          const tenantData = response?.CreateTenant?.tenantView?.tenant || {};

          enqueueSnackbar(t("tenantForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
          navigate(
            tenant_form.replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tenantData.name)}/${encodeURIComponent(
                tenantData.tenantGuid
              )}`
            )
          );
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editTenant(values, actions) {
    editTenantData(
      {
        variables: {
          model: {
            name: values[name.name],
            monthlyTimesheet: values[timesheetBy.name]?.value || false,
            allowTimesheetReopening: values[allowTimesheetReopening.name],
            allowAttendanceSheetReopening: values[allowAttendanceSheetReopening.name],
            monthlyAttendanceSheet: values[monthlyAttendanceSheet.name]?.value || false,
            mandatoryTimeEntryDescription: values[mandatoryTimeEntryDescription.name],
            numberOfMonthlyWorkingHours: values[hoursOfWork.name],
            tenantGuid: tID,
            projectRadiusInMobileApp: values[projectRadius.name],
            postUnderAndOverUsageToNonBillableJob: values[postUnderAndOverUsageToNonBillableJob.name],
            postJournalDescription: values[postJournalDescription.name],
            postJournal: values[postJournal.name],
            trial: values[trial.name],
            trialExpirationDate: values[trialExpirationDate.name],
            subscribedFrom: values[subscribedFrom.name],
            tenantMiddlewareInfoUpdateRequest: {
              job: values[job.name],
              task: values[task.name],
              companyGuid: values[company.name]?.companyGuid,
              attendance: values[attendance.name],
              timeEntry: values[timeEntry.name],
              syncTimeEntry: values[syncTimeEntries.name],
              connectorGuid: values[connector.name]?.connectorGuid,
              logCleanRuntime: new Date(values[LogCleanUpRuntime.name]),
              retentionDays: parseInt(values[LogRetentionDays.name]),
              ticketStatusGuid: values[phaseStatuses.name]?.ticketStatusGuid,
              taskStatusGuid: values[taskStatuses.name]?.taskStatusGuid,
              tenantMiddlewareJobsEditRequest: middlewareArray,
            },
          },
        },
      },
      (response) => {
        if (
          response["EditTenant"].errorCode !== null &&
          response["EditTenant"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditTenant"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("tenantForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
          setRefetchTable(!refetchTable);
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createTenant(values, actions);
    } else {
      editTenant(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={createMode ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched) sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              isActive,
                              setIsActive,
                              myTenant,
                              fromTenant,
                              whatField,
                              setWhatField,
                              wait,
                              setMiddlewareArray,
                              refetchTable,
                            }}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            // disabled={values[connector.name]? false: true}
                            loading={isSubmitting}
                            type="submit"
                            label={t("tenantForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
            {!createMode ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={3}
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                {whatField && (
                  <ContainerCard>
                    <Box p={2}>
                      <Help data={whatField} title={"tenantForm.whatField"} />
                    </Box>
                  </ContainerCard>
                )}
                <ContainerCard
                  sx={{ boxShadow: "0px 0px 10px rgba(24,117,214,255)" }}
                >
                  <Box p={3}>
                    <SideInfo
                      data={Object.entries(sideInfoData)}
                      status={{
                        text: isActive
                          ? "tenantForm.status.active"
                          : "tenantForm.status.inactive",
                        color: isActive ? "success" : "error",
                      }}
                    />
                  </Box>
                </ContainerCard>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TenantForm;
