export const getTable = (t) => {
  return {
    columns: [
      {
        field: "ssid",
        label: "location.access.points.list.column.sSID",
        headerName: t("location.access.points.list.column.sSID"),
        flex: 1,
        editable: true,
        align: "left",
        visible: true,
        renderCell: (row) => {
          return row?.ssid;
        },
      },
      {
        field: "subnet",
        label: "location.access.points.list.column.subnet",
        headerName: t("location.access.points.list.column.subnet"),
        flex: 1,
        editable: true,
        align: "left",
        visible: true,
        renderCell: (row) => {
          return row?.subnet;
        },
      },
    ],
    defaultSort: "employee",
    defaultSortDirection: "asc",
  };
};
