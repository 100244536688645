import React from "react";

//translation
import { useTranslation } from "react-i18next";

//external components
import { Box, Typography, Grid } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";

import { _help } from "../../../../../helpers/help.content";
import { HelpHexIcon } from "../../../../../components/Icon";
const Help = ({ data, title }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box lineHeight={0}>
        <Title>
          <span>
            <HelpHexIcon color="var(--color--light-logo-blue)" />
          </span>
          <h5
            style={{
              color: "var(--color--light-logo-blue)",
              marginTop: "0.2rem",
            }}
          >
            {t(_help(`${data}-title`))}
          </h5>
        </Title>
      </Box>
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <p>{t(_help(`${data}-text`))}</p>
      </Box>
    </Box>
  );
};

export default Help;
