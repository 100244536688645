import React, { useEffect, useRef, useState } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";
import SideInfo from "./components/sideInfo";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  GET_COMPANY_BY_ID,
  UPDATE_COMPANY,
  CREATE_COMPANY,
} from "../../../../graphql/company";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import UTC2Local from "../../../../UTC2Local";

const CompanyForm = ({
  fromModal = false,
  handleCloseModal,
  resetFields = false,
}) => {
  const { cName, cID, tID, tName } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageParameters } = userRoles;

  const createMode = !cName && !cID;

  const { tenant_companies, my_companies_list } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [sideInfoData, setSideInfoData] = useState({});
  
  const [ unTouchedForm, setUnTouchedForm ] = useState(true);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  const {
    name,
    tenant,
    externalReference,
    connectionString,
    userName,
    password,
    version,
    clientId,
    secretId,
    tenantId,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const { loading: getDataByIDLoading, tokenChange: wait } = useQueryAuth(
    GET_COMPANY_BY_ID,
    "GetCompany",
    {
      variables: { companyID: cID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetCompany?.companyOutput || {};

        setFormData({
          [userName.name]: data.username || "",
          [password.name]: data.password || "",
          [name.name]: data.name || "",
          [externalReference.name]: data.externalReference || "",
          [tenant.name]: data.tenant || "",
          [connectionString.name]: data.connectionString || "",
          [clientId.name]: data.clientId || "",
          [secretId.name]: data.secretId || "",
          [tenantId.name]: data.tenantId || "",
          [version.name]: data.businessCentralVersion || "",
        });

        setSideInfoData({
          "companyForm.createdBy": data.creatingUsers
            ? data.creatingUsers.email
            : "",
          "companyForm.creationDate": UTC2Local(data.creationDate) || "",
          "companyForm.modifiedBy": data.modifiedUsers
            ? data.modifiedUsers.email
            : "",
          "companyForm.modificationDate":
            UTC2Local(data.modificationDate) || "",
        });
      },
    }
  );

  const { cfunction: editCompanyData } = useMutationAuth(
    UPDATE_COMPANY,
    "EditCompany"
  );

  const { cfunction: createCompanyData } = useMutationAuth(
    CREATE_COMPANY,
    "CreateCompany"
  );

  function createCompany(values, actions) {
    createCompanyData(
      {
        variables: {
          model: {
            createdBy: storeUserGuid,
            name: values[name.name],
            tenantGuid: tID ? tID : storeUserTenant,
            // tenantGuid: myCompany
            //   ? storeUserTenant
            //   : tID
            //   ? tID
            //   : values[tenant.name]
            //   ? values[tenant.name].tenantGuid
            //   : undefined,
            username:
              values[userName.name] !== "" ||
              values[userName.name] !== null ||
              values[userName.name] !== null
                ? values[userName.name]
                : null,
            password:
              values[password.name] !== "" ||
              values[password.name] !== null ||
              values[password.name] !== null
                ? values[password.name]
                : null,
            externalReference:
              values[externalReference.name] !== "" ||
              values[externalReference.name] !== null ||
              values[externalReference.name] !== null
                ? values[externalReference.name]
                : null,
            connectionString:
              values[connectionString.name] !== "" ||
              values[connectionString.name] !== null ||
              values[connectionString.name] !== null
                ? values[connectionString.name]
                : null,
            clientId:
              values[clientId.name] !== "" ||
              values[clientId.name] !== null ||
              values[clientId.name] !== null
                ? values[clientId.name]
                : null,
            secretId:
              values[secretId.name] !== "" ||
              values[secretId.name] !== null ||
              values[secretId.name] !== null
                ? values[secretId.name]
                : null,
            tenantId:
              values[tenantId.name] !== "" ||
              values[tenantId.name] !== null ||
              values[tenantId.name] !== null
                ? values[tenantId.name]
                : null,
            businessCentralVersionGuid:
              values[version.name] !== "" ||
              values[version.name] !== null ||
              values[version.name] !== null
                ? values[version.name].businessCentralVersionGuid
                : null,
          },
        },
      },
      (response) => {
        if (
          response["CreateCompany"].errorCode !== null &&
          response["CreateCompany"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateCompany"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("companyForm.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");

          if (fromModal === true && handleCloseModal !== undefined)
            handleCloseModal(false);
          else
            navigate(
              tID
                ? tenant_companies.replace(
                    "/:tName/:tID",
                    `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                  )
                : my_companies_list
            );
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editCompany(values, actions) {
    editCompanyData(
      {
        variables: {
          model: {
            modifiedBy: storeUserGuid,
            companyGuid: cID,
            name: values[name.name],
            username:
              values[userName.name] !== "" ||
              values[userName.name] !== null ||
              values[userName.name] !== null
                ? values[userName.name]
                : null,
            password:
              values[password.name] !== "" ||
              values[password.name] !== null ||
              values[password.name] !== null
                ? values[password.name]
                : null,
            externalReference:
              values[externalReference.name] !== "" ||
              values[externalReference.name] !== null ||
              values[externalReference.name] !== null
                ? values[externalReference.name]
                : null,
            connectionString:
              values[connectionString.name] !== "" ||
              values[connectionString.name] !== null ||
              values[connectionString.name] !== null
                ? values[connectionString.name]
                : null,
            clientId:
              values[clientId.name] !== "" ||
              values[clientId.name] !== null ||
              values[clientId.name] !== null
                ? values[clientId.name]
                : null,
            secretId:
              values[secretId.name] !== "" ||
              values[secretId.name] !== null ||
              values[secretId.name] !== null
                ? values[secretId.name]
                : null,
            tenantId:
              values[tenantId.name] !== "" ||
              values[tenantId.name] !== null ||
              values[tenantId.name] !== null
                ? values[tenantId.name]
                : null,
            businessCentralVersionGuid:
              values[version.name] !== "" ||
              values[version.name] !== null ||
              values[version.name] !== null
                ? values[version.name].businessCentralVersionGuid
                : null,
          },
        },
      },
      (response) => {
        if (
          response["EditCompany"].errorCode !== null &&
          response["EditCompany"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditCompany"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("companyForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");

          const data = response?.EditCompany?.companyOutput || {};
          setSideInfoData((oldValue) => ({
            ...oldValue,
            "companyForm.modifiedBy": data.modifiedUsers
              ? data.modifiedUsers.email
              : "",
            "companyForm.modificationDate":
              UTC2Local(data.modificationDate) || "",
          }));
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if(performance.navigation.type === performance.navigation.TYPE_NAVIGATE && unTouchedForm && !fromModal) {
      sessionStorage.clear();
    }
  }, [])

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createCompany(values, actions);
    } else {
      editCompany(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={createMode ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[1]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    dirty,
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) => {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched && fromModal !== true)
                          {sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                          setUnTouchedForm(false)}
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              wait,
                              readOnly: manageParameters === "R",
                            }}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={isSubmitting || manageParameters !== "M"}
                            loading={isSubmitting}
                            type="submit"
                            label={t("companyForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
            {!createMode ? (
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <ContainerCard
                  sx={{ boxShadow: "0px 0px 10px rgba(24,117,214,255)" }}
                >
                  <Box p={3}>
                    <SideInfo data={Object.entries(sideInfoData)} />
                  </Box>
                </ContainerCard>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CompanyForm;
