import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton } from "@mui/material";

import PaperComponent from "../../../../../components/Modal/PaperComponent";
import { Subtitle } from "../../../../../components/Titles/FormTitles";
import { CloseIcon } from "../../../../../components/Icon";
import ConfirmationPopup from "../../../../../components/Popup";
import RequestForm from "../../../Requests/Requests-form";

const AttendanceRequestDialog = ({
  open,
  setOpen,
  title,
  dayLog,
  dayLogGuid,
  fullScreen = false,
}) => {
  const [openDialog, setOpenDialog] = useState(open);

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    setOpen(false);
  };

  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <Dialog
        onClose={() => {
          if (JSON.parse(sessionStorage.getItem("unsavedChanges"))) {
            setOpenDialog(true);
          } else {
            setOpen(false);
          }
        }}
        PaperComponent={PaperComponent}
        open={open}
      >
        <DialogTitle
          style={{
            cursor: "move",
            borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
          }}
          id="draggable-dialog-title"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Subtitle
              style={{
                textAlign: "left",
                fontFamily: "poppins !important",
                fontSize: "16px",
                paddingLeft: "35px",
              }}
            >
              {title}
            </Subtitle>
            <IconButton
              onClick={() => {
                if (JSON.parse(sessionStorage.getItem("unsavedChanges"))) {
                  setOpenDialog(true);
                } else {
                  setOpen(false);
                }
              }}
            >
              <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
            </IconButton>
          </div>
        </DialogTitle>
        <RequestForm
          newRequest={true}
          attendance={true}
          dayLog={dayLog}
          dayLogGuid={dayLogGuid}
          fullScreen={fullScreen}
        />
      </Dialog>
    </>
  );
};

export default AttendanceRequestDialog;
