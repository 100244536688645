const unicodeMap = {
    '\\\\u0027': '\'',
    '\\\\u003E': '>',
    '\\\\u002B': '+',
    '\r\n': ''
    // Add more mappings if needed
};

export const decodeUnicodeString = (encodedString) => {
    // return encodedString.replace(/\\u[\dA-F]{4}/gi, (match) => unicodeMap[match] || match);    // First, decode the unicode escapes
    let decoded = encodedString.replace(/\\\\u[\dA-F]{4}/gi, (match) => unicodeMap[match] || match);
    
    return decoded;
}