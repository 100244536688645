import * as Yup from "yup";
import checkout from "./form";

import Mnemonics from "../../../../../Mnemonics.json";

const {
  formField: {
    employee,
    receiver,
    requestDate,
    requestType,
    requestStatus,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    attachment,
    description,
    rejectionReason,
    approvedBy,
    approvalDate,
    rejectedBy,
    rejectionDate,
    cancelledBy,
    cancellationDate,
    workType,
    location,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [employee.name]: Yup.object().nullable(),
    [receiver.name]: Yup.object().nullable(),
    [requestDate.name]: Yup.date().nullable(),
    [requestType.name]: Yup.object().required(requestType.requiredMessage),
    [requestStatus.name]: Yup.object().nullable(),
    [dateFrom.name]: Yup.date().required(dateFrom.requiredMessage),
    [dateTo.name]: Yup.date().nullable(),
    [timeFrom.name]: Yup.date().nullable(),
    [timeTo.name]: Yup.date().nullable(),
    [description.name]: Yup.string().required(description.requiredMessage),
    [rejectionReason.name]: Yup.string().nullable(),
    [attachment.name]: Yup.string().nullable(),
    [approvedBy.name]: Yup.object().nullable(),
    [approvalDate.name]: Yup.date().nullable(),
    [rejectedBy.name]: Yup.object().nullable(),
    [rejectionDate.name]: Yup.date().nullable(),
    [cancelledBy.name]: Yup.object().nullable(),
    [cancellationDate.name]: Yup.date().nullable(),
    [workType.name]: Yup.object().nullable(),
    [location.name]: Yup.object().nullable(),
  }),
  Yup.object().shape({
    [employee.name]: Yup.object().nullable(),
    [receiver.name]: Yup.object().nullable(),
    [requestDate.name]: Yup.date().nullable(),
    [requestType.name]: Yup.object().nullable(),
    [requestStatus.name]: Yup.object().nullable(),
    [dateFrom.name]: Yup.date().nullable(),
    [dateTo.name]: Yup.date().nullable(),
    [timeFrom.name]: Yup.date().nullable(),
    [timeTo.name]: Yup.date().nullable(),
    [description.name]: Yup.string().required(description.requiredMessage),
    [rejectionReason.name]: Yup.string().nullable(),
    [attachment.name]: Yup.string().nullable(),
    [approvedBy.name]: Yup.object().nullable(),
    [approvalDate.name]: Yup.date().nullable(),
    [rejectedBy.name]: Yup.object().nullable(),
    [rejectionDate.name]: Yup.date().nullable(),
    [cancelledBy.name]: Yup.object().nullable(),
    [cancellationDate.name]: Yup.date().nullable(),
    [workType.name]: Yup.object().required(workType.requiredMessage),
    [location.name]: Yup.object()
      .nullable()
      .when(workType.name, {
        is: (type) =>
          type && type.mnemonic !== Mnemonics.WorkTypes.workfromhome,
        then: () => Yup.object().required(location.requiredMessage),
      }),
  }),
];

export default validation;
