import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {  name,
    description,
    manageSystemConfigurations,
    manageTimeEntry,
    manageUsers,
    manageAttendance,
    manageParameters 
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [name.name]:Yup.string().required(name.requiredMessage),
    [description.name]:Yup.string(),
    [manageSystemConfigurations.name]:Yup.object().required(manageSystemConfigurations.requiredMessage),
    [manageTimeEntry.name]:Yup.object().required(manageTimeEntry.requiredMessage),
    [manageUsers.name]:Yup.object().required(manageUsers.requiredMessage),
    [manageAttendance.name]:Yup.object().required(manageAttendance.requiredMessage),
    [manageParameters.name]:Yup.object().required(manageParameters.requiredMessage),
  }),
];

export default validation;