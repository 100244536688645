import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";

import { getUserFromStore } from "../app/reducers/userSlice";

import { logoutUser, updateJwtToken } from "../app/reducers/userSlice";

function useAuth() {
  const dispatch = useDispatch();
  const user = useSelector(getUserFromStore);

  const jwtToken = !isEmpty(user) ? user.jwtToken : "";
  const version = !isEmpty(user) ? user.version : "";
  const isConnected = !isEmpty(user) && user.jwtToken;
  const storeRequestOTPCode = isConnected ? user.requestOTPCode : true;
  const storeUserGuid = isConnected ? user.user.employeeGuid : "";
  const storeUserMicroID = isConnected ? user.user.employeeMicroID : "";
  const storeUserEmail = isConnected ? user.user.email : "";
  const storeUserTenant = isConnected ? user.user.tenant?.tenantGuid : "";
  const storeUserTenantName = isConnected ? user.user.tenant?.name : "";
  const storeUserManagerGuid = isConnected ? user.user.manager?.employeeGuid : "";
  const storeUserWorkshiftDays = isConnected ? user.user.workShift?.workShiftDays : [];

  const userReleaseNotes = isConnected ? user?.releaseNotes :"";
  const isAttendance = isConnected
    ? user.user?.tenant?.tenantMiddlewareInfo?.attendance
    : false;
  const isTimeEntry = isConnected
    ? user.user?.tenant?.tenantMiddlewareInfo?.timeEntry
    : false;
  const deviceToken = isConnected ? user.deviceToken : "";
  const storeUserFirstName = isConnected ? user.user.firstName : "";
  const storeUserLastName = isConnected ? user.user.lastName : "";
  const storeUserProfilePicture = isConnected
    ? user.user.profilePictureURL
    : "";
  const storeUserTeams = isConnected ? user.user.employeesTeams : [];
  const storeUserRoleDescription = isConnected
    ? user?.user?.role?.name || {}
    : {};
  const userRoles = isConnected ? user?.user?.role || {} : {};
  const timeEntryEnabled = isConnected ? user.user?.timeEntry : false;
  const attendanceEnabled = isConnected ? user.user?.attendance : false;
  const isManager = isConnected ? user.user?.isManager : false;
  const isTimeEntryDescriptionMandatory = isConnected
    ? user.user?.tenant?.mandatoryTimeEntryDescription
    : false;
  const isEmployee = isConnected ? user?.user?.resourceType : "";
  const syncTimeEntry = isConnected ? user.user.tenant?.tenantMiddlewareInfo?.syncTimeEntry : false;
  const allowTimesheetReopening = isConnected
    ? user.user.tenant?.allowTimesheetReopening
    : false;
  const allowAttendanceSheetReopening = isConnected
    ? user.user.tenant?.allowAttendanceSheetReopening
    : false;
  const isTrial = isConnected ? user.user.tenant?.trial : false;
  const trialExpirationDate = isConnected ? user.user.tenant?.trialExpirationDate : null;
  const projectPermissions = isConnected ? user.user.projectPermissions : [];
  const tenantMonthlyTimesheet = isConnected ? user.user.tenant?.monthlyTimesheet : "";

  //logout mutation
  const logout = () => {
    localStorage.removeItem("miniSidenav");
    localStorage.removeItem("task_filter");
    localStorage.removeItem("task_filter_guid");
    localStorage.removeItem("project_task_filter");
    localStorage.removeItem("project_task_filter_guid");
    dispatch(logoutUser());
    sessionStorage.setItem("unsavedChanges", JSON.stringify(false));
  };

  function updateToken(newToken) {
    dispatch(updateJwtToken(newToken));
  }
  
  return {
    jwtToken,
    storeRequestOTPCode,
    storeUserGuid,
    storeUserMicroID,
    logout,
    version,
    storeUserEmail,
    storeUserTenant,
    deviceToken,
    userRoles,
    storeUserTenantName,
    storeUserFirstName,
    storeUserLastName,
    storeUserManagerGuid,
    storeUserWorkshiftDays,
    isTimeEntryDescriptionMandatory,
    storeUserRoleDescription,
    storeUserProfilePicture,
    syncTimeEntry,
    allowTimesheetReopening,
    allowAttendanceSheetReopening,
    storeUserTeams,
    timeEntryEnabled,
    attendanceEnabled,
    isAttendance,
    isTimeEntry,
    isEmployee,
    isTrial,
    userReleaseNotes,
    trialExpirationDate,
    projectPermissions,
    updateToken,
    isManager,
    user,
    tenantMonthlyTimesheet,
  };
}

export default useAuth;
