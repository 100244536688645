import { createSlice } from "@reduxjs/toolkit";

const initialState = { user: {} };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      let { user } = state;
      user = { user: payload };
     return user;
    },
    updateUser: (state, { payload }) => {
      let { user } = state;
      let { firstName, lastName, phoneNumber} = payload;
      user = { user: {
                ...user, 
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
              } 
            };
     return user;
    },
    updateUserView: (state, { payload }) => {
      let { user } = state;
      let { userView, version } = payload;
      user = { user: {
                ...user, 
                user: userView,
                version: version,
              } 
            };
     return user;
    },
    logoutUser: (state) => {
      let { user } = state;
      user = { user: {} };
     return user;
    },
    modifyOTPLink: (state, { payload }) => {
      // let { user } = state;
      // user = {
      //   user: {
      //     ...user,
      //     requestOTPLink: false,
      //     secretCodeForTOTP: payload,
      //   },
      // };
      //return user;
    },
    updateJwtToken: (state, { payload }) => {
      let { user } = state;
      user = {
        user: {
          ...user,
          jwtToken: payload,
        },
      };
      return user;
    },
    fetchingToken: (state, {payload}) => {
      // let { user } = state;
      // user = {
      //   user:{
      //     ...user,
      //     tokenFetching: payload,
      //   }
      // }

      // return user;
    },
    modifyOTPCode: (state) => {
      let { user } = state;
      user = { user: { ...user, requestOTPCode: false } };
      return user;
    },
    modifyUserDeviceToken: (state, payload) => {
      let { user } = state;
      user = { user: { ...user, deviceToken: payload.payload } };
      return user;
    },
  },
});

export const {
  loginUser,
  updateUser,
  updateUserView,
  logoutUser,
  modifyOTPLink,
  modifyOTPCode,
  modifyUserDeviceToken,
  updateJwtToken,
  fetchingToken
} = userSlice.actions;

export const getUserFromStore = (state) => state.user.user;

export default userSlice.reducer;
