import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Tooltip } from "@mui/material";

import colors from "../../../../assets/theme/light/colors";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./style.css";

function Calendar({ events, t, handleChangeDates, startDate }) {
  const calendarRef = React.createRef();

  const theme = createTheme({
    components: {
     
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: "10px !important",
            borderRadius: "2px !important",
            backgroundColor: "white !important",
            boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            color: "black"
          },
        },
      },
    },
  });

  function renderEventContent(eventInfo) {
    const iconClasses = Array.isArray(eventInfo.event.extendedProps.icons)
      ? eventInfo.event.extendedProps.icons
      : [eventInfo.event.extendedProps.icons];

    return (
      <div className="icons-container">
        {iconClasses.map((iconClass, index) => {
          return (
            <ThemeProvider theme={theme}>
              <Tooltip title={t(iconClass)}>
                <div key={index} className={`status-icon ${iconClass}`}></div>
              </Tooltip>
            </ThemeProvider>
          );
        })}
      </div>
    );
  }

  const dayRender = (info) => {
    const event = events.find((e) => e.start === info.dateStr);

    if (event) {
      info.el.style.backgroundColor = "blue";
    }
  };

  const handlePrevButtonClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    // const startDate = calendarApi.view.activeStart;
    const date = new Date(startDate);
  
    const adjustedStartDate = new Date(date);
    adjustedStartDate.setMonth(date.getMonth() - 1);
    adjustedStartDate.setDate(1); // Set the day to 1
  
    const adjustedEndDate = new Date(date);
    adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);
  
    handleChangeDates(adjustedStartDate, adjustedEndDate);
    calendarApi.prev();
  };
  
  const handleNextButtonClick = () => {
    const calendarApi = calendarRef.current?.getApi();
    // const startDate = calendarApi.view.activeStart;
    const date = new Date(startDate);
  
    const adjustedStartDate = new Date(date);
    adjustedStartDate.setDate(1); // Set the day to 1
    adjustedStartDate.setMonth(date.getMonth() + 1);
  
    let adjustedEndDate = new Date(date);
    adjustedEndDate.setMonth(date.getMonth() + 2); // Set to the 1st day of the next month
    adjustedEndDate.setDate(0); // Set to the last day of the current month
  
    if (adjustedEndDate > new Date()) {
      adjustedEndDate = new Date();
    }
  
    handleChangeDates(adjustedStartDate, adjustedEndDate);
    calendarApi.next();
  };
  

  return (
    <div>
      <FullCalendar
        key={JSON.stringify(events)}
        ref={calendarRef}
        contentHeight={450}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        initialDate={new Date(startDate)}
        weekends={true}
        initialEvents={events}
        dayRender={dayRender}
        eventContent={renderEventContent}
        timeZone={"UTC"}
        customButtons={{
          prev: {
            text: "Previous",
            click: handlePrevButtonClick,
          },
          next: {
            text: "Next",
            click: handleNextButtonClick,
          },
        }}
        headerToolbar={{
          right: "prev next",
        }}
      />
    </div>
  );
}

export default Calendar;
