export const getDataTableData = (t) => {
    return {
      columns: [
        {
          id: "e1ebde41-c7e5-41a6-b969-7cdd916cb643",
          field: "description",
          align: "left",
          headerName: t("teams.list.column.description"),
          label: "teams.list.column.description",
          flex: 1,
          editable: true,
        },
      ],
    };
  };
  