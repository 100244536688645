import checkout from "./form";

const {
  formField: { 
    employee,
    description,
    date,
    checkInTime,
    checkOutTime,
    workType,
    location, 
    },
} = checkout;

 const initialValues = {
  [employee.name]: null,
  [description.name]:"",
  [date.name]:null,
  [checkInTime.name]:null,
  [checkOutTime.name]:null,
  [workType.name]:null,
  [location.name]:null,
};

export default initialValues;