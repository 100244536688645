import { Box } from "@mui/material";

import BasicTabs from "../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../urls";

import "./style.css";

function Dashboard({ isEntelligence = false }) {

  const {
    my_dashboard,
    entelligence_dashboard,

  } = urls;

  const { t } = useTranslation();

  const TabLinks = [
    {
      key: 1,
      Header:  t("dashboardTabs.dashboard"),
      Link: isEntelligence ? entelligence_dashboard : my_dashboard,
      display: "none"
    },
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} dashboard={true} t={t}/>
    </Box>
  );
}

export default Dashboard;
