import { gql } from "@apollo/client";

export const GET_ALL_ACCESS_POINTS = gql`
  query ($model: LocationAccessPointsListGetRequestInput!){
    GetAllLocationAccessPoints(model: $model) {
        accessPointsList {
            accessPoints {
              locationAccessPointGuid
                ssid
                subnet
            }       
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_ACCESS_POINT = gql`
mutation ($model: LocationAccessPointCreateRequestInput!) {
    createAccessPoint(model: $model) {
      accessPoint {
        locationAccessPointGuid
        ssid
        subnet
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_ACCESS_POINT = gql`
mutation ($model: LocationAccessPointEditRequestInput!) {
    updateAccessPoint(model: $model) {
      accessPoint {
        locationAccessPointGuid
        ssid
        subnet
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_ACCESS_POINTS = gql`
mutation ($model: LocationAccessPointsDeleteRequestInput!) {
deleteLocationAccessPoints(model: $model) {
    accessPointDeleteResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`;
