import React, { useState, useCallback, useEffect } from "react";

//routing
import { useNavigate } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";

//component
import GridTable from "../../../../components/Tables/GridTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//gql
import {
  GET_ALL_TEAMS,
  DELETE_TEAMS_LIST,
  CREATE_TEAM,
  UPDATE_TEAM,
} from "../../../../graphql/team";

//editors
import EmployeeTable from "./EmployeeTable";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";
import { getDataTableData } from "./_table";

import colors from "../../../../assets/theme/light/colors";

import urls from "../../../../urls";

function Teams() {
  const { storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const { disabled_text_grey, light_logo_blue } = colors;

  const DEFAULT_TEAM = {
    teamGuid: "",
    description: "",
    tenantGuid: storeUserTenant,
  };

  const { my_teams_employees } = urls;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [re, setRe] = useState(false);
  const [newObj, setNewObj] = useState({
    teamGuid: "",
    description: "",
    tenantGuid: storeUserTenant,
  });
  const requiredFields=["teamGuid", "description"];

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_TEAMS_LIST,
    "DeleteTeams"
  );

  const { mfunction: getAllTeams, loading } = useLazyQueryAuth(
    GET_ALL_TEAMS,
    "GetTeamsList"
  );

  useEffect(() => {
    const col = getDataTableData(t);
    if (col) {
      setColumns(col.columns);
    }
  }, [columns.length]);

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    getAllTeams(
      {
        variables: {
          model: {
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            orderColumn: "Description",
            orderDirection: "asc",
            keyword: search,
            tenantGuid: storeUserTenant,
          },
        },
      },
      (response) => {
        setData(response);
        setRows(response?.GetTeamsList?.teamsList?.teams);
      }
    );
  }, []);

  const records =
    data &&
    data.GetTeamsList &&
    data.GetTeamsList.teamsList &&
    data.GetTeamsList.teamsList.teams
      ? data.GetTeamsList.teamsList.teams
      : [];

  const numberRecords =
    data &&
    data.GetTeamsList &&
    data.GetTeamsList.teamsList &&
    data.GetTeamsList.teamsList.teams &&
    data.GetTeamsList.teamsList.totalNumberOfRecords
      ? data.GetTeamsList.teamsList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (setCreate.current === false) fetchTableData(page, rowsPerPage, search);
  }, [ re]);

  const handleDelete = (selectedItems) => {
    deleteRecords(
      {
        variables: {
          model: {
            teamsIDs: selectedItems,
          },
        },
      },
      (response) => {
        if (response["DeleteTeams"].deleteTeamResponse === true) {
          enqueueSnackbar(t("team.list.delete.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        } else {
        }
      }
    );
  };

  const { cfunction: createTeamData } = useMutationAuth(
    CREATE_TEAM,
    "CreateTeam"
  );

  const { cfunction: updateTeamData } = useMutationAuth(
    UPDATE_TEAM,
    "UpdateTeam"
  );

  function CreateTeamData(newRow) {
    setIsSubmitting(true);
    if (newRow != null && newRow.teamGuid != null) {
      createTeamData(
        {
          variables: {
            model: {
              description: newRow.description,
              tenantGuid: newRow.tenantGuid,
            },
          },
        },
        (response) => {
          if (
            response["CreateTeam"].errorCode !== null &&
            response["CreateTeam"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateTeam"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            setRows(rows.filter((r) => r.teamGuid !== newObj.teamGuid));
            setNewObj(DEFAULT_TEAM);
          } else {
            enqueueSnackbar(t("teamForm.createdSuccessfully"), {
              variant: "success",
            });
            fetchTableData(page, rowsPerPage, search);
            setNewObj(DEFAULT_TEAM);
          }
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        },
        (error) => {
          setRows(rows.filter((r) => r.teamGuid !== newObj.teamGuid));
          setNewObj(DEFAULT_TEAM);
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        }
      );
    }
  }

  function UpdateTeamData(updatedRow) {
    updateTeamData(
      {
        variables: {
          model: {
            teamGuid: updatedRow.teamGuid,
            description: updatedRow.description,
            tenantGuid: updatedRow.tenantGuid,
          },
        },
      },
      (response) => {
        if (
          response["UpdateTeam"].errorCode !== null &&
          response["UpdateTeam"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateTeam"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_TEAM);
        } else {
          enqueueSnackbar(t("teamForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_TEAM);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_TEAM);
      }
    );
  }

  const customAction = {
    title: t("team.list.assign.view"),
    icon: (disabled) =>
         <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-users-plus"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke={disabled? disabled_text_grey : light_logo_blue}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
        <path d="M3 21v-2a4 4 0 0 1 4 -4h4c.96 0 1.84 .338 2.53 .901" />
        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
        <path d="M16 19h6" />
        <path d="M19 16v6" />
      </svg>
      
    ,
    identifier: "description",
    disabled: false,
    handleClick: (id, name) => {
      navigate(
        my_teams_employees.replace(
          "/:tName/:tID",
          `/${encodeURIComponent(name)}/${encodeURIComponent(id)}`
        )
      );
    },
  };

  const pagination = {
    rows: rows.length === 0 ? records : rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    removeFilters:true,
    numberRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading,
    searchText: "team.list.search.label",
    // refetch,
    tableMnemonic: Mnemonic.Tables["Teams"],
    deleteText: "team.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== "M",
    // fetchedBool: true,
    customAction,
    rowClick: (id, name) => {
      navigate(
        my_teams_employees.replace(
          "/:tName/:tID",
          `/${encodeURIComponent(name)}/${encodeURIComponent(id)}`
        )
      );
    },
    isSubmitting,
    requiredFields,
    enqueueSnackbar
  };

  return (
    <div>
      {displayTable ? (
        <EmployeeTable
          display={displayTable}
          initialVal={[]}
          setDisplay={setDisplayTable}
        />
      ) : null}
      {loading && columns.length === 0 ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns &&
        columns.length > 0 && (
          <GridTable
            {...pagination}
            identifier="teamGuid"
            hasCheckbox={false}
            handleCreate={(team) => CreateTeamData(team)}
            handleUpdate={(team) => UpdateTeamData(team)}
            handleDelete={(teams) => handleDelete(teams)}
          />
        )
      )}
    </div>
  );
}

export default Teams;
