import { gql } from "@apollo/client";

export const GET_EMPLOYEE_TIMESHEET = gql`
  query ($model: GetEmployeeTimesheetRequestModelInput!) {
    GetEmployeeTimesheet(model: $model) {
      employeeTimesheet {
        totalDuration
        dateSummaries {
          date
          totalDurationByDate
        }
        taskSummaries {
          projectGuid
          projectTitle
          taskGuid
          taskName
          totalTaskDuration
          dateDetails {
            date
            taskDuration
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TIME_BY_EMPLOYEE = gql`
  query ($model: GetTimeByEmployeeRequestModelInput!) {
    GetTimeByEmployee(model: $model) {
      timeByEmployee {
        employees {
          id
          name
          totalOfHours
          projects {
            id
            name
            totalOfHours
            percentageOfTotal
            tasks {
              id
              name
              billable
              totalOfHours
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TIME_BY_PROJECT = gql`
  query ($model: GetTimeByEmployeeRequestModelInput!) {
    GetTimeByProject(model: $model) {
      timeByProject {
        totalProjectsTime
        projects {
          id
          name
          totalOfHours
          employees {
            id
            name
            totalOfHours
            percentageOfTotal
            tasks {
              id
              name
              billable
              totalOfHours
            }
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ATTENDANCE_BY_EMPLOYEE = gql`
query ($model: EmployeeAttendanceByDateRequestInput!) {
  GetAttendanceByEmployee(model: $model) {
    attendanceByEmployee {
      employeeGuid
      employeeName
      totalTime
      totalWorkHours
      attendanceGroups {
        date
        workshiftStartHour
        workshiftEndHour
        clockInTime
        clockOutTime
        totalTime
        isLate
        leftEarly
        automaticallyClockedOut
        dayLogs {
          dayLogGuid
          checkInTime
          checkOutTime
          workTimeHour
          workTimeMinute
          workType {
            mnemonic
            description
          }
          location {
            name
          }
          toEntitlement {
            employeePTO {
              pto {
                ptoGuid
                description
              }
            }
          }
        }
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_TIME_BY_EXTERNAL_REFERENCE = gql`
query($model: GetTimeByExternalReferenceRequestModelInput!) {
  GetTimeByExternalReferences(model: $model) {
      timeByExternalReference {
        projects {
          name
          totalOfHours
          tasks {
            name
            totalOfHours
            employees {
              name
              totalOfHours
            }
          }
        }
      }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const EXPORT_TO_EXCEL= gql`
query($model:ProjectTimeEntriesForExcelFiltersRequestInput!){
  ProjectExportTimeEntries (model: $model) {
    projectTimeEntriesListForExcel {
      timeEntries{
        timeEntryGuid
        taskTitle
        resourceName
        description
        date
        duration
        billable
      }
      totalNumberOfRecords
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;