export default {
  formID: "tenant",
  formField: {
    licenseType:{
      name:"licenseType",
      label:"tenantForm.licenseType.label",
      requiredMessage:"TenantForm.licenseType.requiredMessage"
    },
    numberOfLicenses:{
      name:"numberOfLicenses",
      label:"tenantForm.numberOfLicenses.label",
      requiredMessage:"TenantForm.numberOfLicenses.requiredMessage"
    },
    numberOfUsedLicenses:{
      name:"numberOfUsedLicenses",
      label:"tenantForm.numberOfUsedLicenses.label",
      requiredMessage:"TenantForm.numberOfUsedLicenses.requiredMessage"
    },
  },
};
