import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {
    name,
    address,
    latitude,
    longitude,
    radius,
    UnitOfMeasure,
    country,
    isOnSite,
  },
} = checkout;

const validation = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.requiredMessage),
    [address.name]: Yup.string().required(address.requiredMessage),
    [latitude.name]: Yup.string().required(address.requiredMessage),
    [longitude.name]: Yup.string().required(longitude.requiredMessage),
    [radius.name]: Yup.number().required(radius.requiredMessage),
    [UnitOfMeasure.name]: Yup.object().required(UnitOfMeasure.requiredMessage),
    [country.name]: Yup.object().required(country.requiredMessage),
    [isOnSite.name]: Yup.bool(),
  }),
];

export default validation;