export default function hasEmptyTags(htmlString) {
    // Regular expression to match opening and closing tags with their content
    // const tagPattern = /<(\w+)(\s[^>]*)?>\s*<\/\1>/g;
  
    // // Find all matches in the input string
    // const matches = htmlString.match(tagPattern);
  
    // if (matches) {
    //   for (let match of matches) {
    //     // Extract the content between the tags
    //     const tagContentPattern = /<\w+(\s[^>]*)?>\s*<\/\w+>/;
    //     const content = match.replace(tagContentPattern, '').trim();
  
    //     // Check if content is empty
    //     if (content === '') {
    //       return true; // An empty tag found
    //     }
    //   }
    // }
    
    // return false; // No empty tags found

    //---------------------------------------------//
    // Regular expression to match opening and closing tags with their content
    const tagPattern = /<(\w+)(\s[^>]*)?>.*?<\/\1>/g;
  
    // Remove all tags from the input string
    const strippedString = htmlString.replace(tagPattern, '').trim();
  
    // If strippedString is empty, it means all content is within tags
    if (strippedString === '') {
        // Now check if there is any non-empty tag content
        const emptyTagPattern = /<(\w+)(\s[^>]*)?>\s*<\/\1>/g;
        return !htmlString.replace(emptyTagPattern, '').trim();
    }
    
    return false; // If there's any content outside tags
  }