import React, { useEffect } from "react";

//components
import {
  Grid,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Slide,
} from "@mui/material";

import Tooltip from "../../../../../components/Tooltip";
import PaperComponent from "../../../../../components/Modal/PaperComponent";
import { Title } from "../../../../../components/Titles/FormTitles";
import TextField from "../../../../../components/TextFields/TextField";

import colors from "../../../../../assets/theme/light/colors";

//hooks
import useMutationAuth from "../../../../../hooks/useMutationAuth";

//translation
import { useTranslation } from "react-i18next";

import { COPY_TO_NEXT_YEAR } from "../../../../../graphql/officialHolidays";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState } from "react";

import { day_month_year } from "../../../../../utils/timezones";
import { AddIconOutlined, CopyIcon, DeleteIcon } from "../../../../../components/Icon";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Main = ({
  formData,
  selectedYear,
  setSelectedYear,
  holidayGuid,
  enqueueSnackbar,
  disableCopy,
  handleDisableCopyToNextYear,
}) => {
  const { t } = useTranslation();
  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const [years, setYears] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorDates, setErrorDates] = useState([]);

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
  } = formData;

  const { description, holidays } = formField;

  const { description: descriptionV, holidays: holidaysV } = values;

  const { mfunction: copyToNextYearFunction } = useMutationAuth(
    COPY_TO_NEXT_YEAR,
    "CopyOfficialHolidayToNextYear"
  );

  useEffect(() => {
    const yearArr = [];

    const currentYear = new Date().getFullYear();
    const numberOfYears = 10;

    for (let i = 0; i < numberOfYears; i++) {
      yearArr.push(currentYear + i);
    }

    setYears(yearArr);
  }, [selectedYear]);

  function handleDaysDescriptionChange(index, description) {
    const newArray = structuredClone(holidaysV); //we clone a new array

    newArray[index].description = description; //we change the value to the new value

    setFieldValue(holidays.name, newArray); //we set the value to the original array
  }

  function handleDaysDateChange(index, date) {
    const newArray = structuredClone(holidaysV); //we clone a new array

    newArray[index].date = new Date(date); //we change the value to the new value

    setFieldValue(holidays.name, newArray); //we set the value to the original array
  }

  function handleDayAdd() {
    if (!holidaysV) return; //if null we return

    const newArray = structuredClone(holidaysV); //we clone a new array from the original one

    newArray.push({
      officialHolidayDayGuid: null,
      description: "",
      date: getCurrentYearDate(),
    }); //we push the object to the new array

    setFieldValue(holidays.name, newArray); //we set the value to the original array
    handleDisableCopyToNextYear(true);
  }

  function handleDayDelete(index) {
    const newArray = structuredClone(holidaysV); //we clone a new array from the original one

    newArray.splice(index, 1); //we remove the object from the new array

    setFieldValue(holidays.name, newArray); //we set the value to the original array
  }

  function handleCopyToNextYearClick() {
    copyToNextYearFunction(
      {
        variables: {
          model: {
            officialHolidayGuid: holidayGuid,
            year: selectedYear,
          },
        },
      },
      (response) => {
        if (
          response["CopyOfficialHolidayToNextYear"].errorCode !== null &&
          response["CopyOfficialHolidayToNextYear"].errorCode !== ""
        ) {
          enqueueSnackbar(
            t(response["CopyOfficialHolidayToNextYear"].errorMessage),
            {
              variant: "error",
              autoHideDuration: 5000
            }
          );
        } else {
          enqueueSnackbar(
            t("officialHolidays.officialHolidayDays.copyDays.success"),
            {
              variant: "success",
            }
          );
          handleClose();
        }
      }
    );
  }

  const disableDates = (date, index, disabled = false) => {
    if (disabled) return false;
    let sentDate = new Date(date);

    if (sentDate.getFullYear() < new Date().getFullYear()) return true;
    const result = holidaysV.findIndex(
      (x) =>
        new Date(x.date).getDate() === sentDate.getDate() &&
        new Date(x.date).getMonth() === sentDate.getMonth() &&
        new Date(x.date).getFullYear() === sentDate.getFullYear()
    );
    if (result !== -1 && result !== index) return true;
    else return false;
  };

  const getCurrentYearDate = () => {
    // Find the maximum date from the array
    if(holidaysV.length === 0) {
      if (createMode) {
            const tomorrow = new Date();
            return tomorrow.setDate(tomorrow.getDate() + 1);
          }
          const nextYear = new Date();
          nextYear.setFullYear(selectedYear);
          nextYear.setMonth(new Date().getMonth());
          nextYear.setDate(new Date().getDate() + 1);
      
          return nextYear;
    }
    else {
      const maxDate = holidaysV.reduce((max, holiday) => {
        const holidayDate = new Date(holiday.date);
        return holidayDate > max ? holidayDate : max;
      }, new Date(0));
    
      // Set the date to the next day
      const nextDay = new Date(maxDate);
      nextDay.setDate(maxDate.getDate() + 1);
    
      return nextDay;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const isPastDate = (selectedDate) => {
  //   const today = new Date();
  //   const theDate = new Date(selectedDate);
  //   return (
  //     theDate.getDate() <= today.getDate() - 1 && 
  //     theDate.getMonth() <= today.getMonth() &&
  //     theDate.getFullYear() === today.getFullYear()
  //   );
  // };

  const isErrorDate = (selectedDate, index) => {
    let sentDate = new Date(selectedDate);
    if (sentDate.getFullYear() < new Date().getFullYear()) return true;
    const result = holidaysV.findIndex(
      (x) =>
        new Date(x.date).getDate() === sentDate.getDate() &&
        new Date(x.date).getMonth() === sentDate.getMonth() &&
        new Date(x.date).getFullYear() === sentDate.getFullYear()
    );

    return (
      errorDates.includes(index) ||
      (result >= 0 && result !== index && index === holidaysV.length - 1)
    );
  };

  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("dialog.official.holiday.content")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={handleCopyToNextYearClick}>{t("yes")}</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title style={{ padding: "10px 0" }}>
            {t("officialHolidays.officialHolidayInformation.title")}
          </Title>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          sx={{ paddingBottom: "20px" }}
        >
          <TextField
            autoFocus
            fullWidth
            value={descriptionV}
            onChange={(e) => {
              setFieldValue(description.name, e.target.value);
            }}
            label={t(description.label)}
            name={description.name}
            formData={formData}
            required
            disabled={readOnly}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          sx={{ display: "flex", paddingBottom: "20px" }}
        >
          <Grid
            container
            flex
            alignItems="flex-end"
            justifyContent={createMode ? "flex-start" : "flex-end"}
          >
            {!createMode && (
              <Grid item sm={12} md={9} lg={9} xl={9}>
                {years && years.length > 0 && (
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{
                      ".MuiInputBase-input": { fontSize: "14px !important" },
                    }}
                  >
                    <Select
                      labelId="year"
                      id="yearDropdown"
                      value={selectedYear}
                      label={"officialHolidays.year"}
                      onChange={(event, value) => {
                        setSelectedYear(value.props.children);
                      }}
                    >
                      {years.map((year, index) =>
                        year === selectedYear ? (
                          <MenuItem key={index} value={year} selected>
                            {year}
                          </MenuItem>
                        ) : (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            )}
            {!createMode && (
              <Grid
                item
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Tooltip
                  title={t("officialHolidays.officialHolidayDays.copyDays")}
                >
                  <IconButton
                    onClick={() => setOpen(true)}
                    style={{ paddingBottom: 0 }}
                    disabled={disableCopy || createMode || readOnly}
                  >
                    <CopyIcon width="20" height="20" color={disableCopy ? "grey" : "#000000"} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>

        {holidaysV &&
          holidaysV.map((holiday, index) => {
            const disable =
              readOnly ||
              (holiday.date !== null &&
                new Date(holiday.date) < new Date() + 1);

            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="official-holiday-line"
              >
                <Grid item sm={12} md={3} lg={3} xl={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      format={
                        day_month_year.includes(userTimezone) === true
                          ? "dd-MM-yyyy"
                          : "MM-dd-yyyy"
                      }
                      label={t("officialHolidays.officialHolidayDays.date")}
                      value={holiday.date}
                      disabled={disable}
                      timezone="UTC"
                      onChange={(newValue) => {
                        handleDaysDateChange(index, newValue);
                        handleDisableCopyToNextYear(true);
                        if (disableDates(newValue, index))
                          setErrorDates([...errorDates, index]);
                        else
                          setErrorDates(
                            errorDates.filter((date) => date !== index)
                          );
                      }}
                      defaultDate={getCurrentYearDate()}
                      // disablePast={!disable}
                      shouldDisableDate={(e) => disableDates(e, index, disable)}
                      slotProps={{
                        desktopPaper: {
                          sx: {
                            boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                          },
                        },
                        textField: {
                          size: "small",
                          required: true,
                          error: isErrorDate(holiday.date, index),
                        },
                      }}
                      sx={{
                        flex: 1,
                        fontFamily: "poppins",
                      }}
                      className="date_picker_styles"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid container sm={12} md={9} lg={9} xl={9}>
                  <Grid item sm={12} md={11} lg={11} xl={11}>
                    <TextField
                      fullWidth
                      value={holiday.description}
                      onChange={(e) => {
                        handleDaysDescriptionChange(index, e.target.value);
                        handleDisableCopyToNextYear(true);
                      }}
                      label={t("officialHolidays.officialHolidayDays.description")}
                      name={holiday.name}
                      formData={formData}
                      required
                      disabled={disable}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={1}
                    lg={1}
                    xl={1}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    {!disable && (
                      <Tooltip
                        title={t(
                          "officialHolidays.officialHolidayDays.deleteDay",
                          {
                            dayDescription: holiday.description,
                          }
                        )}
                      >
                        <IconButton
                          onClick={() => handleDayDelete(index)}
                          style={{ paddingBottom: 0 }}
                        >
                          <DeleteIcon width="20" height="20" color="#000000" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!readOnly && (
            <Tooltip title={t("officialHolidays.officialHolidayDays.addDay")}>
              <IconButton onClick={handleDayAdd}>
                <AddIconOutlined width="20" height="20" color="#000000" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
