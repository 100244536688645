import React from "react";

import { IconButton } from "@mui/material";
import Tooltip from "../../../../../components/Tooltip";

//translation
import { useTranslation } from "react-i18next";
import { ActionsIcon } from "../../../../../components/Icon";

const EditRecord = (props) => {
  const {
    dayLogGuid,
    handleSetDayLogGuid,
    handleOpenModal,
    disabled,
    row,
  } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    // handleSetDayLogGuid(dayLogGuid);
    handleOpenModal(dayLogGuid, row);
  };

  if (!dayLogGuid) return null;
  return (
    <>
      <Tooltip title={t("dayLog.info")}>
        <IconButton
          onClick={handleClick}
          className="timeSheetCol"
          disabled={disabled(props)}
        >
          <ActionsIcon width="24" height="24" color="#000000" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default EditRecord;
